import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocation, push } from "connected-react-router";
import { getUser, userIsLoading, getLoggedInUserOrganization } from "../store/iam/selectors";
import { logout } from "../store/iam/creators";
import { getLoggedInUser, fetchOrganization } from "../store/iam/operations";
import AuthTopNav from "../components/layout/AuthTopNav/AuthTopNav";
import { getPageTitle } from "../components/layout/SideMenu/menuItems";
import { fetchSubscription, fetchSubscriptionPlans } from "../store/sales/operations";
import { getSubscription } from "../store/sales/selectors";

class AuthHeader extends Component {
  componentDidMount() {
    this.props.fetchSubscriptionPlans();

    if (!this.props.user) {
      this.props.getLoggedInUser();
      this.props.fetchSubscription();
    }

    this.props.fetchOrganization();

    // if (!this.props.organization) {
    //   this.props.fetchOrganization();
    // }
  }

  render() {
    return (
      <AuthTopNav
        isLoading={this.props.isLoadingUser}
        logout={this.props.logout}
        user={this.props.user}
        push={this.props.push}
        pageTitle={this.props.pageTitle}
        selectedMenuItem={this.props.current_path}
        showPageHeader={this.props.showPageHeader}
        subscription={this.props.subscription}
        orgRole={this.props.orgRole}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const organization = getLoggedInUserOrganization(state);
  return {
    user: getUser(state),
    organization: organization,
    isLoadingUser: userIsLoading(state),
    pageTitle: getPageTitle(state.router.location.pathname),
    current_path: getLocation(state).pathname,
    subscription: getSubscription(state),
    orgRole: organization ? organization["role"] : null
  };
};

export default connect(mapStateToProps, {
  push,
  logout,
  getLoggedInUser,
  fetchSubscription,
  fetchSubscriptionPlans,
  fetchOrganization
})(AuthHeader);
