import {
  GET_TRACKED_WEBSITES_IN_PROGRESS,
  GET_TRACKED_WEBSITES_SUCCESS,
  GET_TRACKED_WEBSITES_FAILED,
  ADD_TRACKED_WEBSITE_IN_PROGRESS,
  ADD_TRACKED_WEBSITE_FAILED,
  ADD_TRACKED_WEBSITE_SUCCESS,
  CHANGE_CURRENT_WEBSITE,
  CHANGE_TRACKED_WEBSITE_SETTINGS_IN_PROGRESS,
  CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL,
  CHANGE_TRACKED_WEBSITE_SETTINGS_FAILED,
  DELETE_TRACKED_WEBSITE_IN_PROGRESS,
  DELETE_TRACKED_WEBSITE_SUCCESSFUL,
  DELETE_TRACKED_WEBSITE_FAILED,
  GET_TRACKED_WEBSITES_AND_OVERVIEWS_IN_PROGRESS,
  GET_TRACKED_WEBSITES_AND_OVERVIEWS_SUCCESSFUL,
  GET_TRACKED_WEBSITES_AND_OVERVIEWS_FAILED
} from "./types";

export const getTrackedWebsitesInProgress = _ => ({
  type: GET_TRACKED_WEBSITES_IN_PROGRESS
});

export const getTrackedWebsitesSuccess = tracked_websites => ({
  type: GET_TRACKED_WEBSITES_SUCCESS,
  tracked_websites: tracked_websites
});

export const getTrackedWebsitesFailed = _ => ({
  type: GET_TRACKED_WEBSITES_FAILED
});

export const addTrackedWebsiteInProgress = _ => ({
  type: ADD_TRACKED_WEBSITE_IN_PROGRESS
});

export const addTrackedWebsiteFailed = _ => ({
  type: ADD_TRACKED_WEBSITE_FAILED
});

export const addTrackedWebsiteSuccess = trackedWebsite => ({
  type: ADD_TRACKED_WEBSITE_SUCCESS,
  trackedWebsite: trackedWebsite
});

export const changeCurrentWebsite = website_guid => ({
  type: CHANGE_CURRENT_WEBSITE,
  selected_website: website_guid
});

export const changeTrackedWebsiteSettingsInProgress = (guid, actionName) => ({
  type: CHANGE_TRACKED_WEBSITE_SETTINGS_IN_PROGRESS,
  guid,
  actionName
});

export const changeTrackedWebsiteSettingsSuccessful = (guid, actionName, updatedSettings) => ({
  type: CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL,
  guid,
  actionName,
  updatedSettings
});

export const changeTrackedWebsiteSettingsFailed = (guid, actionName) => ({
  type: CHANGE_TRACKED_WEBSITE_SETTINGS_FAILED,
  guid,
  actionName
});

export const deleteTrackedWebsiteInProgress = url => ({
  type: DELETE_TRACKED_WEBSITE_IN_PROGRESS,
  url
});

export const deleteTrackedWebsiteSuccessful = (url, guid) => ({
  type: DELETE_TRACKED_WEBSITE_SUCCESSFUL,
  url,
  guid
});

export const deleteTrackedWebsiteFailed = url => ({
  type: DELETE_TRACKED_WEBSITE_FAILED,
  url
});

export const getTrackedWebsitesAndOverviewsInProgress = _ => ({
  type: GET_TRACKED_WEBSITES_AND_OVERVIEWS_IN_PROGRESS
});

export const getTrackedWebsitesAndOverviewsSuccessful = _ => ({
  type: GET_TRACKED_WEBSITES_AND_OVERVIEWS_SUCCESSFUL
});

export const getTrackedWebsitesAndOverviewsFailed = _ => ({
  type: GET_TRACKED_WEBSITES_AND_OVERVIEWS_FAILED
});
