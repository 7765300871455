import React, { useMemo } from 'react';
import { SeoLocationData } from '../../types';
import { Form, Select } from 'antd';
import { getAvailableLanguagesForLocation } from '../../utils';

const { Option } = Select;

type Props = {
    locationsAndLanguages: SeoLocationData[];
    selectedLocationName: string;
    selectedLanguageCode: string;
    onLocationChange: (newLocation: string) => void;
    onLanguageChange: (newLanguage: string) => void;
};

export default function LocationAndLanguageFormItem({
    locationsAndLanguages,
    selectedLocationName,
    selectedLanguageCode,
    onLocationChange,
    onLanguageChange,
}: Props) {
    const availableLanguages = useMemo(
        () => (locationsAndLanguages ? getAvailableLanguagesForLocation(selectedLocationName, locationsAndLanguages) : []),
        [locationsAndLanguages, selectedLocationName]
    );

    return (
        <Form.Item>
            <div>
                <h3>Target audience</h3>
                <p>
                    The location and language of your primary target audience - our keyword tracking tool will retrieve region specific data from the
                    appropriate keyword database
                </p>
            </div>

            <p style={{ marginBottom: '6px' }}>
                <strong>Country:</strong>
            </p>
            <Select
                value={selectedLocationName}
                onChange={onLocationChange}
                size="large"
                style={{ marginBottom: '24px' }}
            >
                {locationsAndLanguages &&
                    locationsAndLanguages.map((location) => (
                        <Option
                            key={location.location_name}
                            data-cy="change-db-select-option"
                            value={location.location_name}
                        >
                            {location.location_name}
                        </Option>
                    ))}
            </Select>

            <p style={{ marginBottom: '6px' }}>
                <strong>Language:</strong>
            </p>
            <Select
                disabled={!selectedLocationName}
                value={selectedLanguageCode}
                onChange={onLanguageChange}
                size="large"
                style={{ marginBottom: '24px' }}
            >
                {availableLanguages.map((language) => (
                    <Option
                        key={language.language_code}
                        value={language.language_code}
                    >
                        {language.language_name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
}

/*              <Select
                    value={selectedRegionalDatabase}
                    onChange={onRegionalDatabaseChange}
                    placeholder="Select target audience"
                    showSearch
                    onInputKeyDown={(e) => e.stopPropagation()}
                >
                    {/* {regionalDatabases &&
                        Object.keys(regionalDatabases).map((regionCode) => (
                            <Option
                                key={regionCode}
                                data-cy="change-db-select-option"
                                value={regionCode}
                            >
                                {regionCode} - {regionalDatabases[regionCode]}
                            </Option>
                        ))} 
                </Select> */
