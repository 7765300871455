import './DashboardSettings.less';
import React, { useContext } from 'react';
import LoadingIndicator from '../../components/shared/LoadingIndicator/LoadingIndicator';
import { SelectedWebsiteContext } from '../../trackedWebsites/ContextWrapper';
import TrackedWebsitesSelector from './TrackedWebsitesSelector';
import DashboardSettingsTabs from './tabs/DashboardSettingsTabs';
import { useFetchTrackedWebsitesQuery } from '../../trackedWebsites/service';

type Props = {
    selectedOption: string;
};

export default function DashboardSettings({ selectedOption }: Props) {
    const { selectedWebsite, setSelectedWebsite } = useContext(SelectedWebsiteContext);
    const { data, isLoading: isLoadingTrackedWebsites } = useFetchTrackedWebsitesQuery();
    const trackedWebsites = data?.tracked_websites;

    if (isLoadingTrackedWebsites) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className="dashboard-settings-header">
                <TrackedWebsitesSelector
                    trackedWebsites={trackedWebsites ? trackedWebsites : []}
                    selectedWebsite={selectedWebsite}
                    onSelectedWebsiteChange={setSelectedWebsite}
                />
            </div>
            <DashboardSettingsTabs
                selectedWebsite={selectedWebsite}
                setSelectedWebsite={setSelectedWebsite}
                selectedOption={selectedOption}
            />
        </>
    );
}
