import { logout as logoutActionCreator } from '../store/iam/creators';
import { push } from 'connected-react-router';
import { IAM_COOKIE_NAME } from '../settings/config';
import { removeCookie, defaultIamCookieAttributes } from '../utils/cookie';
import { dispatch } from '../store';
// APIs
import { backlinksApi } from '../backlinks/service';
import { trackedWebsitesApi } from '../trackedWebsites/service';
import { locationsAndLanguagesApi } from '../trackedWebsites/service';
import { topKeywordsApi } from '../topKeywords/service';
import { keywordResearchApi } from '../keywordResearch/service';
import { trackedKeywordsApi } from '../trackedKeywords/service';
import { brokenLinksApi } from '../brokenLinks/service';
import { sitemapApi } from '../sitemap/service';
import { siteSpeedApi } from '../siteSpeed/service';
import { siteOutageApi } from '../outage/service';


export function logout(redirectTo = '/auth/login') {
    dispatch(logoutActionCreator());
    dispatch(backlinksApi.util.resetApiState());
    dispatch(trackedWebsitesApi.util.resetApiState());
    dispatch(locationsAndLanguagesApi.util.resetApiState());
    dispatch(topKeywordsApi.util.resetApiState());
    dispatch(keywordResearchApi.util.resetApiState());
    dispatch(trackedKeywordsApi.util.resetApiState());
    dispatch(brokenLinksApi.util.resetApiState());
    dispatch(sitemapApi.util.resetApiState());
    dispatch(siteSpeedApi.util.resetApiState());
    dispatch(siteOutageApi.util.resetApiState());

    removeCookie(IAM_COOKIE_NAME, defaultIamCookieAttributes);
    dispatch(push(redirectTo));
}
