import React, { Component } from "react";
import { Menu } from "antd";
import { getMenuItems, getSelectedSubmenuKey, getSelectedMenuItemKey, rootSubmenuKeys } from "./menuItems";
import "./SideMenu.less";
import CustomIcon from "../../CustomIcon";
import { IS_MENU_COLLAPSED } from "../../../settings/config";
const SubMenu = Menu.SubMenu;

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: getSelectedSubmenuKey(getMenuItems(this.props.selectedMenuItem))
    };
    this.rootSubmenuKeys = rootSubmenuKeys();
    this.onMenuItemClick = this.props.onMenuItemClick;
    this.displayMenuItem = this.displayMenuItem.bind(this);
    this.displaySubmenuItem = this.displaySubmenuItem.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  handleMenuItemClick(menu_item) {
    if (menu_item["key"] === "contact") {
      return;
    }
    this.onMenuItemClick(menu_item["path"]);
  }

  displayMenuItem = (menu_item) => (
    <Menu.Item
      key={menu_item.key}
      className={menu_item.type}
      onClick={(_) => this.handleMenuItemClick(menu_item)}
      data-cy={menu_item.label}>
      <span className="item-wrapper">
        <CustomIcon name={menu_item.icon} className="icon" size="24" />
        <span className="menu-label">{menu_item.label}</span>
      </span>
    </Menu.Item>
  );

  displaySubmenuItem = (submenu_item) => (
    <SubMenu
      key={submenu_item.key}
      title={
        <span className="item-wrapper">
          <CustomIcon name={submenu_item.icon} className="icon" size="24" />
          <span className="menu-label">{submenu_item.title}</span>
        </span>
      }
      className={submenu_item.type}>
      {submenu_item.sub_items.map((item) => this.displayMenuItem(item))}
    </SubMenu>
  );

  onSubmenuOpenChange = (openKeys) => {
    this.setState({ openKeys });
  };

  componentDidUpdate(prevProps) {
    if (this.props.selectedMenuItem !== prevProps.selectedMenuItem && IS_MENU_COLLAPSED) {
      this.setState({
        openKeys: getSelectedSubmenuKey(getMenuItems(this.props.selectedMenuItem))
      });
    }
  }

  render() {
    const selectedMenuItem = this.props.selectedMenuItem;
    const items = getMenuItems(selectedMenuItem);
    return (
      <Menu
        theme="dark"
        mode="inline"
        className="sidebar-menu custom-scrollbar"
        multiple="false"
        selectedKeys={getSelectedMenuItemKey(items, selectedMenuItem)}
        defaultOpenKeys={getSelectedSubmenuKey(items)}
        openKeys={this.state.openKeys}
        onOpenChange={(key) => this.onSubmenuOpenChange(key)}
        style={{ background: "#282D2D" }}>
        {items.map((item) => (item.type === "menu-item" ? this.displayMenuItem(item) : this.displaySubmenuItem(item)))}
      </Menu>
    );
  }
}
export default SideMenu;
