import { createApi } from '@reduxjs/toolkit/query/react';
import { SSC_SEM_API_URL } from '../settings/config';
import { authRequest } from '../store/authorizedRequests';
import { FetchTopKeywordsOverviewQueryParams, FetchTopKeywordsQueryParams, TopKeyword, TopKeywordsOverview } from './types';

const topKeywordsBaseUrl = `${SSC_SEM_API_URL}/dfs/top-keywords`;
const TOP_KEYWORDS_REFETCH_TIME = 7200;

export const topKeywordsApi = createApi({
    reducerPath: 'api.topKeywords',
    keepUnusedDataFor: TOP_KEYWORDS_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;

        try {
            const data = await authRequest(topKeywordsBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchTopKeywords: builder.query<TopKeyword[], FetchTopKeywordsQueryParams>({
            query: (args) => {
                const { domain, locationName, languageCode, orderBy, orderDir } = args;

                return {
                    url: `?domain=${domain}&location=${encodeURIComponent(locationName)}&language=${languageCode}&order_by=${orderBy}&order_dir=${orderDir}`,
                    method: 'GET',
                };
            },
        }),

        fetchTopKeywordsOverview: builder.query<TopKeywordsOverview, FetchTopKeywordsOverviewQueryParams>({
            query: (args) => {
                const { domain, locationName, languageCode } = args;

                return {
                    url: `/overview?domain=${domain}&location=${encodeURIComponent(locationName)}&language=${languageCode}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useFetchTopKeywordsQuery, useFetchTopKeywordsOverviewQuery } = topKeywordsApi;
