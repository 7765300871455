import React from "react";
import TrendIcon from "./TrendIcon";
import SummaryTooltip from "./SummaryTooltip";

function BacklinksSummary({totalBacklinks}) {
    let firstValue = totalBacklinks.length > 0 ? parseInt(totalBacklinks[0]["value"]).toLocaleString() : undefined;
    let lastValue = totalBacklinks.length > 0 ? parseInt(totalBacklinks[totalBacklinks.length - 1]["value"]).toLocaleString() : undefined;

    return (
        <div className="dashboard-summary-section">
            <div className="dashboard-summary-section-title">
                Backlinks <SummaryTooltip resourceType="backlinks" first={firstValue} last={lastValue}/>
            </div>
            <div className="dashboard-summary-section-result">
                {   totalBacklinks.length > 0
                    ?
                        <React.Fragment>
                            <TrendIcon resourceType="backlinks" first={firstValue} last={lastValue} />
                            {' '}{lastValue}
                        </React.Fragment>
                        :
                        <span>No data</span>
                }
            </div>
        </div>
    )
}

export default BacklinksSummary
