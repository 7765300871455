import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SeoReportScore from "../../components/SeoReport/SeoReportScore";
import {
  getCompetitorsUrls,
  getSeoScoreForMainUrl,
  getSectionsStatusProgress,
  getSeoScoreForCompetitors,
  getSectionsStatusCounter,
  getMaxRelevancePoints,
  getCompetitorsSectionsStatusCounter,
  getAnalysisProgress,
  getReportCreatedAt
} from "../../store/analysis/selectors";
import { downloadPdfReport } from "../../store/white_label/operations";
import { getSelectedTemplateGuid, isDownloadingPdfReport } from "../../store/white_label/selectors";
import { fetchTemplates } from "../../store/white_label/operations";

export class SeoReportScoreWrapper extends Component {
  constructor(props) {
    super(props);

    this.handleWhiteLabelRedirect = this.handleWhiteLabelRedirect.bind(this);
    this.handlePdfDownload = this.handlePdfDownload.bind(this);
  }

  componentDidMount() {
    this.props.fetchTemplates();
  }

  handleWhiteLabelRedirect() {
    let encodedReportUrl = encodeURIComponent(this.props.mainUrl);
    this.props.push(
      `/white-label/reports?report_guid=${this.props.reportGuid}&report_url=${encodedReportUrl}&template_guid=${this.props.selectedTemplateGuid}`
    );
  }

  handlePdfDownload() {
    this.props.downloadPdfReport(this.props.mainUrl, this.props.reportGuid, "default");
  }

  render() {
    return (
      <SeoReportScore
        url={this.props.mainUrl}
        sectionsStatusCounter={this.props.sectionsStatusCounter}
        seoScore={this.props.seoScore}
        sectionsStatusProgress={this.props.sectionsStatusProgress}
        competitorsUrls={this.props.competitorsUrls}
        competitorsSectionsStatusCounter={this.props.competitorsSectionsStatusCounter}
        competitorsSeoScore={this.props.competitorsSeoScore}
        analysisProgress={this.props.analysisProgress}
        handlePdfDownload={this.handlePdfDownload}
        handleWhiteLabelRedirect={this.handleWhiteLabelRedirect}
        isDownloadingPdfReport={this.props.isDownloadingPdfReport}
        isFullReport={this.props.isFullReport}
        createdAt={this.props.createdAt}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { mainUrl, reportGuid } = ownProps;

  return {
    sectionsStatusCounter: getSectionsStatusCounter(state, reportGuid),
    seoScore: getSeoScoreForMainUrl(state, reportGuid, getMaxRelevancePoints(state, reportGuid)),
    sectionsStatusProgress: getSectionsStatusProgress(state, reportGuid),
    competitorsUrls: getCompetitorsUrls(state, reportGuid),
    competitorsSectionsStatusCounter: getCompetitorsSectionsStatusCounter(state, reportGuid),
    competitorsSeoScore: getSeoScoreForCompetitors(state, reportGuid),
    analysisProgress: getAnalysisProgress(state, reportGuid),
    isDownloadingPdfReport: isDownloadingPdfReport(state, mainUrl, reportGuid, "default"),
    selectedTemplateGuid: getSelectedTemplateGuid(state),
    createdAt: getReportCreatedAt(state, reportGuid)
  };
};

export default connect(mapStateToProps, { fetchTemplates, downloadPdfReport, push })(SeoReportScoreWrapper);
