import React, { Component } from "react";
import { Select } from "antd";
import "./URLSchemaSelector.less";

const { Option } = Select;
const urlSchemaOptions = ["https://", "http://"];

class URLSchemaSelector extends Component {
  state = {
    selectedUrlSchema: "https://",
  };

  handleChange = (selectedUrlSchema) => {
    this.setState({ selectedUrlSchema });
    this.props.onChange(selectedUrlSchema, this.props.urlId);
  };

  render() {
    const { selectedUrlSchema } = this.state;
    const filteredOptions = urlSchemaOptions.filter((opt) => selectedUrlSchema !== opt);

    return (
      <Select className="url-schema-select" defaultValue="https://" onChange={this.handleChange}>
        {filteredOptions.map((opt) => (
          <Option key={opt} value={opt}>
            {opt}
          </Option>
        ))}
      </Select>
    );
  }
}

export default URLSchemaSelector;
