import React from "react";
import { Tooltip } from "antd";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import "./SpellCheckerSection.less";

const SpellCheckerResult = ({ context, tokens }) => {
  let result = [];
  let pos = 0;
  let typo;
  let typoNo = 0;

  for (typo of tokens) {
    typoNo += 1;
    result.push(context.substring(pos, typo["offset"]));

    const suggestions = (
      <div>
        {typo["suggestions"].map((suggestion, idx) => (
          <p key={idx}>{suggestion["suggestion"]}</p>
        ))}
      </div>
    );
    result.push(
      <Tooltip title={suggestions}>
        <span className={`misspelled ${typo["label"] === "hard" ? "hard" : "soft"}`}>{typo["token"]}</span>
      </Tooltip>
    );

    pos = typo["offset"] + typo["token"].length;

    if (typoNo === tokens.length) {
      result.push(context.substring(pos));
    }
  }

  return (
    <li>
      {result.map((item, idx) => (
        <span key={idx}>{item}</span>
      ))}
    </li>
  );
};

const displayResult = result => {
  return result["spelling_issues"] ? (
    <div className="description-meta">
      <InlineModal openLabel="See results list" modalTitle="Full list of spelling issues" closeLabel="Close">
        <ul>
          {result["spelling_issues"].map((issue, idx) => {
            return <SpellCheckerResult context={issue["context"]} tokens={issue["tokens"]} key={idx} idx={idx} />;
          })}
        </ul>
      </InlineModal>
    </div>
  ) : null;
};

const SpellCheckerSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default SpellCheckerSection;
