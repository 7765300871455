import { getSelectedWebsiteDomain } from "../tracked_websites/selectors";

export const isFetchingSitemap = state => {
  const domain = getSelectedWebsiteDomain(state);
  return state["sitemap"]["network_activity"].includes(`fetch_sitemap_${domain}`);
};

export const isLoadingMoreSitemapUrls = state => {
  const domain = getSelectedWebsiteDomain(state);
  return state["sitemap"]["network_activity"].includes(`load_more_sitemap_${domain}`);
};

export const getSitemapReport = state => {
  const domain = getSelectedWebsiteDomain(state);

  if (state["sitemap"]["data"] && state["sitemap"]["data"][domain]) {
    return state["sitemap"]["data"][domain];
  }else{
    return null;
  }
}; 

export const hasMoreUrls=(state)=>{
  const domain = getSelectedWebsiteDomain(state);
  if (state["sitemap"]["data"] && state["sitemap"]["data"][domain]) {
    return state["sitemap"]["data"][domain]['last_url_hash'];
  }else{
    return null;
  }
}

export const isGeneratingSitemap=(state)=>{
  const domain = getSelectedWebsiteDomain(state);
  return state["sitemap"]["network_activity"].includes(`generate_sitemap_${domain}`);
}

export const hasGeneratedSitemaps=(state)=>{   
  return state["sitemap"]['generated_sitemaps'];
}