import React from "react";
import { Modal } from "antd";

function WelcomeScreen(props) {
  return (
    <Modal
      className="welcome-screen-modal"
      title={props.title}
      visible={props.isVisible}
      onCancel={props.closeModal}
      footer={null}
      width={512}
      maskClosable={false}
      destroyOnClose>
      <div className="welcome-screen-alert" dangerouslySetInnerHTML={{__html: props.message}} style={{padding: "24px"}}></div>
    </Modal>
  );
}

export default WelcomeScreen;
