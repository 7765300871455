import { getLoggedInUserSubscription, getLoggedInUserOrganization } from "../store/iam/selectors";
import { trackedWebsites } from "../store/tracked_websites/selectors";
import { navigate } from "../store/navigation/operations";

const allowedRoutes = [
    "/subscription/plans",
    "/user/account/profile",
    "/user/account/billing",
    "/auth/logout",
    "/auth/login",
    "/free-trial-setup"
];

const routeIsForbidden = (pathname) => {
    return !allowedRoutes.includes(pathname) && !pathname.startsWith("/subscription/checkout/");
};

const shouldRedirectToPlans = (organization) => {
    if (!organization) {
        return true;
    }

    if (organization && organization["role"] === "owner") {
        return true;
    }

    if (organization && (!organization["role"] || !organization["o_guid"])) {
        return true;
    }

    return false;
};

const shouldRedirectToProfile = (organization) => {
    return organization && organization["o_guid"] && organization["role"] === "member";
};

const hasExpiredSubscription = (subscription) => {
    return subscription && subscription["status"] === "expired";
};

const accessControlMiddleware = (store) => (next) => (action) => {
    if (action.type === "@@router/LOCATION_CHANGE") {
        const pathname = action.payload.location.pathname;
        const subscription = getLoggedInUserSubscription(store.getState());
        const organization = getLoggedInUserOrganization(store.getState());

        if (
            typeof subscription === "object" &&
            subscription !== null &&
            Object.keys(subscription).length === 0 &&
            trackedWebsites(store.getState()).length === 0
        ) {
            if (pathname !== "/free-trial-setup") {
                action.payload.location.pathname = "/free-trial-setup";
                store.dispatch(navigate("free-trial-setup"));
                return next(action);
            }
        }

        if (hasExpiredSubscription(subscription) && routeIsForbidden(pathname)) {
            if (shouldRedirectToPlans(organization)) {
                action.payload.location.pathname = "/subscription/plans";
                store.dispatch(navigate("subscription/plans"));
            }

            if (shouldRedirectToProfile(organization)) {
                action.payload.location.pathname = "/user/account/profile";
                store.dispatch(navigate("user/account/profile"));
            }
        }
    }

    return next(action);
};

export default accessControlMiddleware;
