import { BacklinksOverviewSections, BacklinksTabsSections } from './types';

export const backlinksOverviewMainPageDisplayOrder = ['domainRank', 'totalBacklinks', 'referringDomains', 'followLinks', 'noFollowLinks'];
export const backlinksOverviewDashboardDisplayOrder = ['domainRank', 'totalBacklinks', 'referringDomains'];

export const backlinksOverviewSections: BacklinksOverviewSections = {
    domainRank: {
        key: 'domain_rank',
        title: 'Domain Rank',
        tooltip:
            'This metric is measuring the importance of the domain based on the volume and quality of links that are pointing to it. The rank is on a scale from 0 to 1000, with 1000 meaning the domain is highly important and its links communicate a strong amount of popularity.',
    },
    totalBacklinks: {
        key: 'total_backlinks',
        title: 'Total Backlinks',
        tooltip: 'Total number of incoming links to your domain.',
    },
    referringDomains: {
        key: 'referring_domains',
        title: 'Referring Domains',
        tooltip: 'The number of domains that are linking back to your domain.',
    },
    followLinks: {
        key: 'follow_links',
        title: 'Follow Links',
        tooltip:
            'Follow links are links that instruct search crawlers to follow and therefore boost the SEO of the linked-to site by communicating trust and impacting organic rankings.',
    },
    noFollowLinks: {
        key: 'nofollow_links',
        title: 'Nofollow Links',
        tooltip:
            'A Nofollow link is a link that will send users to another site if clicked on, but tells search bots not to crawl, therefore conveying less of a trust signal that would help the SEO of the linked-to site. Nofollow attributes are commonly applied by webmasters on any paid links, user-generated content, or in comments sections of websites.',
    },
};

export const backlinksTabsDisplayOrder = ['mostRecentlyFound', 'mostImportant', 'referringDomains', 'topAnchors', 'tldDistribution', 'topCountries'];

export const backlinksTabsSections: BacklinksTabsSections = {
    mostRecentlyFound: {
        label: 'Most recently found',
        resource_type: 'backlinks',
        order_by: 'first_seen',
        order_dir: 'desc',
    },
    mostImportant: {
        label: 'Most important',
        resource_type: 'backlinks',
        order_by: 'page_rank',
        order_dir: 'desc',
    },
    referringDomains: {
        label: 'Referring domains',
        resource_type: 'referring_domains',
        order_by: 'backlinks_num',
        order_dir: 'desc',
    },
    topAnchors: {
        label: 'Top anchors',
        resource_type: 'top_anchors',
        order_by: 'domains_num',
        order_dir: 'desc',
    },
    tldDistribution: {
        label: 'TLD distribution',
        resource_type: 'tld_distribution',
        order_by: 'domains_num',
        order_dir: 'desc',
    },
    topCountries: {
        label: 'Top countries',
        resource_type: 'top_countries',
        order_by: 'domains_num',
        order_dir: 'desc',
    },
};

export const overviewChartDataTooltips = {
    total_backlinks: 'Total Backlinks',
    referring_domains: 'Referring Domains',
    domain_rank: 'Domain Rank',
    follow_links: 'Follow Links',
    nofollow_links: 'Nofollow links',
};
