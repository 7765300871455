import React from 'react';
import { Button } from 'antd';

type Props = {
	onSkipClicked: () => void;
	onNextClicked: () => void;
	isLoading?: boolean;
	showSkip?: boolean;
};

export default function FirstStepButtons({ onSkipClicked, onNextClicked, isLoading, showSkip }: Props) {
	return (
		<>
			{showSkip && (
				<div className="button-wrapper">
					<span
						className="fake-link"
						onClick={onSkipClicked}
					>
						Skip for now
					</span>
				</div>
			)}

			<div className="button-wrapper next-button">
				<Button
					className="next-btn right"
					type="primary"
					loading={isLoading}
					onClick={onNextClicked}
				>
					Next
				</Button>
			</div>
		</>
	);
}
