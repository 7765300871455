import React from "react";
import { Icon } from "antd";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";
import "./HttpsEncryption.less";
import moment from "moment";

const formatDateFromTimestamp = (ts, formatStr = "ddd, MMMM Do YYYY, h:mm:ss a (z)") => {
  try {
    return moment
      .unix(ts)
      .utc()
      .format(formatStr);
  } catch (e) {}
  return "";
};

const iconForStatus = status => {
  if (status === "passed") return "check-circle";
  if (status === "warning") return "exclamation-circle";
  return "close-circle";
};

const getCertificateLabel = (chainLength, index) => {
  let label = "";
  if (index === chainLength - 1) {
    label = "Root certificate";
  } else if (index < chainLength - 1 && index !== 0) {
    label = "Intermediate certificate";
  } else {
    label = "Server certificate";
  }
  return label;
};

const CertificateChain = ({ chain, checkTimestamp }) => {
  return (
    <div className="certificate-chain">
      <span className="title">Certificate Chain:</span>

      {chain.map((cert, idx) => {
        const label = getCertificateLabel(chain.length, idx);

        return (
          <div className="stats-table-container" key={idx}>
            <header>
              <div className="col">{label}</div>
              <div className="col"></div>
            </header>

            <div className="row">
              <div className="col capitalize">Common name</div>
              <div className="col">{cert["common_name"]}</div>
            </div>

            {cert["organization"] && (
              <div className="row">
                <div className="col capitalize">Organization</div>
                <div className="col">{cert["organization"]}</div>
              </div>
            )}

            {cert["location"] && (
              <div className="row">
                <div className="col capitalize">Location</div>
                <div className="col">{cert["location"]}</div>
              </div>
            )}

            {cert["sans"] && cert["sans"].length > 0 && (
              <div className="row">
                <div className="col capitalize">Subject Alternative Names (SANs)</div>
                <div className="col">{cert["sans"].join(", ")}</div>
              </div>
            )}

            <div className={`row ${cert["valid_from"] > checkTimestamp ? "failed" : ""}`}>
              <div className="col capitalize">Not valid before</div>
              <div className="col">{formatDateFromTimestamp(cert["valid_from"])}</div>
            </div>

            <div className={`row ${cert["valid_to"] < checkTimestamp ? "failed" : ""}`}>
              <div className="col capitalize">Not valid after</div>
              <div className="col">{formatDateFromTimestamp(cert["valid_to"])}</div>
            </div>

            {cert["signature_algorithm"] && (
              <div className="row">
                <div className="col capitalize">Signature algorithm</div>
                <div className="col">{cert["signature_algorithm"]}</div>
              </div>
            )}

            <div className="row">
              <div className="col capitalize">Issuer</div>
              <div className="col">{cert["issuer"]}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const displayResult = (title, result) => {
  return (
    <>
      <div className="description-meta">
        {result["sub_results"] && (
          <div className="sub-results">
            {result["sub_results"].map((subResult, idx) => {
              return (
                <div className={`sub-result ${subResult["status"]}`} key={idx}>
                  <Icon type={iconForStatus(subResult["status"])} theme="filled" className="status-icon" />
                  <p>{subResult["message"]}</p>
                </div>
              );
            })}
          </div>
        )}

        {result["certificate_chain"] && result["certificate_chain"].length > 0 && (
          <CertificateChain chain={result["certificate_chain"]} checkTimestamp={result["check_timestamp"]} />
        )}
      </div>
      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    </>
  );
};

const HttpsEncryptionSection = ({
  url,
  sectionKey,
  title,
  result,
  tooltip,
  relevance,
  passRate,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />
      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default HttpsEncryptionSection;
