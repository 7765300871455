import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { registerOrganizationMember } from "../../store/iam/operations";
import { userIsLoggingIn } from "../../store/iam/selectors";
import { RegisterOrganizationMemberForm } from "../../components/auth";

class RegisterOrganizationMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldResetCaptcha: false
        };
    }

    onError = () => {
        this.setState({ shouldResetCaptcha: true });
    };

    submitForm = (firstName, lastName, password, recaptchaToken) => {
        this.setState({ shouldResetCaptcha: false });
        const invitationToken = this.props.token;
        //console.log(`submit form with invitationToken: ${invitationToken}`);
        this.props.registerOrganizationMember(
            invitationToken,
            firstName,
            lastName,
            password,
            recaptchaToken,
            this.onError
        );
    };

    render() {
        return (
            <RegisterOrganizationMemberForm
                onSubmit={this.submitForm}
                isLoading={this.props.isLoading}
                push={this.props.push}
                shouldResetCaptcha={this.state.shouldResetCaptcha}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: userIsLoggingIn(state)
});

export default connect(mapStateToProps, { registerOrganizationMember, push })(RegisterOrganizationMember);
