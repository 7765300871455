import React from "react";
import { Layout, Result } from "antd";
const { Content } = Layout;

const NotFoundPage = () => {
  return (
    <Content className="general-layout-content">
      <Result
        status="404"
        title="404"
        subTitle="Sorry! We could not find the page you are looking for. The page may be missing, removed or you have mistyped the URL."
        extra={null}
      />
    </Content>
  );
};

export default NotFoundPage;
