import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import AnchorLabeledList from "../../../shared/List/AnchorLabeledList";
import HowToFix from "../../shared/HowToFix/HowToFix";
import { anchorAttributesForUrls } from "../../utils";

const displayResult = (title, result) => {
  if (result.status === "passed") {
    return null;
  }

  return (
    <React.Fragment>
      <div className="description-meta">
        <InlineModal openLabel="See results list" modalTitle="Full list of uncached CSS resources" closeLabel="Close">
          <AnchorLabeledList list={anchorAttributesForUrls(result.not_cached)} />
        </InlineModal>
      </div>
      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    </React.Fragment>
  );
};

const CssCachingSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default CssCachingSection;
