import React from "react";
import { List, Icon } from "antd";
import "./PaymentMethodPromo.less";

const details = {
  credit_card: [
    {
      title: "100% SSL Secured Transaction with Stripe",
      icon: "safety-certificate",
      content:
        "Stripe's payment infrastructure guarantees a no-hassle, smooth & most important, a totally secured transaction. Stripe is certified to PCI Service Provider Level 1, the most stringent level of certification."
    },
    {
      title: "Recurrent Payment - automated transactions",
      icon: "dollar-circle",
      content:
        "There's no need to update your credit card every month to make a payment in order to keep your subscription. An automated payment system is included, that will notify you by e-mail each time the payment has been made."
    },
    {
      title: "You can cancel your subscription at any time",
      icon: "close-circle",
      content:
        "If you want to stop your plan subscription you can do it at any given time. The recurrent payment will be stopped, but you won't be able to use our tools and features."
    },
    {
      title: "Refund policy",
      icon: "backward",
      content:
        "We have a 24-hour refund policy for monthly subscriptions and a 7-day for yearly subscriptions. Only requests in this time-frame will be evaluated for refunding."
    }
  ],
  paypal: [
    {
      title: "100% SSL Secured Transaction with PayPal",
      icon: "safety-certificate",
      content:
        "PayPal's payment infrastructure guarantees a no-hassle, smooth & most important, a totally secured transaction. PayPal is certified to PCI Service Provider Level 1, the most stringent level of certification."
    },
    {
      title: "Recurrent Payment - automated transactions",
      icon: "dollar-circle",
      content:
        "There's no need to make a payment every year in order to keep your subscription. An automated payment system is included, that will notify you by e-mail each time the payment has been made."
    },
    {
      title: "You can cancel your subscription at any time",
      icon: "close-circle",
      content:
        "If you want to stop your plan subscription you can do it at any given time. The recurrent payment will be stopped, but you won't be able to use our tools and features."
    },
    {
      title: "Refund policy",
      icon: "backward",
      content:
        "We have a 24-hour refund policy for monthly subscriptions and a 7-day for yearly subscriptions. Only requests in this time-frame will be evaluated for refunding."
    }
  ]
};

function DetailsTitle(props) {
  return (
    <React.Fragment>
      <Icon type={props.icon} theme="filled" style={{ fontSize: "16px", color: "#fe6100", marginRight: "5px" }} />
      {props.title}
    </React.Fragment>
  );
}

const getDetails = (details, includeRefundDetails) => {
  if (!includeRefundDetails) {
    const result = details.filter(item => item.title !== "Refund policy");
    return result;
  }
  return details;
};

function PaymentMethodPromo(props) {
  return (
    <List
      className="payment-method-promo"
      itemLayout="vertical"
      size="small"
      bordered
      dataSource={getDetails(details[props.paymentMethod], props.includeRefundDetails)}
      renderItem={item => (
        <List.Item key={item.title}>
          <List.Item.Meta title={<DetailsTitle title={item.title} icon={item.icon} />} />
          {item.content}
        </List.Item>
      )}
    />
  );
}
export default PaymentMethodPromo;
