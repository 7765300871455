import React, { useState } from 'react';
import { Modal, Table, Button, Alert, Spin, Icon } from 'antd';
import { useLazyFetchAffectedPagesQuery } from './service';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';
import { AffectedPage, AffectedPagesTableRecord } from './types';
import moment from 'moment';

const getAffectedPagesDataSource = (affectedPages: AffectedPage[]) => {
    return affectedPages.map((page, index) => ({
        key: index + 1,
        updatedAt: moment(page.updated_at, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD, YYYY, HH:mm'),
        url: page.url,
    }));
};

const columns = [
    {
        title: '#',
        dataIndex: 'key',
        width: '45px',
        className: 'no-wrap',
    },
    {
        title: 'Last checked',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        className: 'affected-pages-last-update-column no-wrap',
        width: '180px',
    },
    {
        title: 'Referring pages',
        dataIndex: 'url',
        key: 'url',
        className: 'affected-pages-column no-wrap',
        render: (_: any, record: AffectedPagesTableRecord) => (
            <span>
                <a
                    target="_blank"
                    href={record.url}
                    rel="noopener noreferrer"
                >
                    {record.url}
                </a>
            </span>
        ),
    },
];

type Props = {
    domain: string;
    url: string;
};

export default function AffectedPages({ domain, url }: Props) {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [fetchAffectedPages, { data: affectedPages, isLoading: isLoadingAffectedPages, isFetching: isLoadingMorePages }] = useLazyFetchAffectedPagesQuery();

    const handleOpenModal = () => {
        fetchAffectedPages({ domain, url }, true)
            .unwrap()
            .then(() => {
                setIsModalVisible(true);
            });
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleLoadMore = () => {
        if (affectedPages && affectedPages.last_uat) {
            fetchAffectedPages({ domain, url, lastUat: affectedPages.last_uat });
        }
    };

    const hasMoreAffectedPages = affectedPages && affectedPages.last_uat ? true : false;

    return (
        <>
            {isLoadingAffectedPages ? (
                <Spin
                    style={{ lineHeight: '12px' }}
                    indicator={
                        <Icon
                            type="loading"
                            spin
                            style={{ fontSize: 12 }}
                        />
                    }
                />
            ) : (
                <span
                    className="view-affected-pages-modal"
                    onClick={handleOpenModal}
                >
                    View URLs
                </span>
            )}
            <Modal
                className="affected-pages-modal"
                title={
                    <ModalTitle
                        title={
                            affectedPages && affectedPages.pages.length > 0
                                ? 'Pages where this broken link is present:'
                                : 'No pages contain this broken link currently'
                        }
                        meta={url}
                    />
                }
                visible={isModalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={1024}
                destroyOnClose
            >
                {affectedPages && affectedPages.pages.length > 0 ? (
                    <div>
                        <Table
                            className="affected-pages-table custom-scrollbar"
                            dataSource={getAffectedPagesDataSource(affectedPages.pages)}
                            columns={columns}
                            pagination={false}
                            size="small"
                        />
                        {hasMoreAffectedPages && (
                            <Button
                                className="load-more"
                                type="primary"
                                loading={isLoadingMorePages}
                                onClick={handleLoadMore}
                            >
                                Load more
                            </Button>
                        )}
                    </div>
                ) : (
                    <Alert message="No pages are affected at the moment" />
                )}
            </Modal>
        </>
    );
}
