import React from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";
import "./SparklineChart.less";

function SparklineChart({ data }) {
  return (
    <div className="sparkline-chart">
      <Sparklines data={data} height={38} preserveAspectRatio="xMidYMid meet">
        <SparklinesLine style={{ stroke: "#2b94e1", fill: "#40c0f5", fillOpacity: 0.3 }} />
      </Sparklines>
    </div>
  );
}

export default SparklineChart;
