import React from "react";
import { Row, Col, DatePicker } from "antd";
import moment from "moment";
import SeoScoreProgressForArchivedOverview from "./SeoScoreProgressForArchivedReports";
import Reports from "./Reports";

const { MonthPicker } = DatePicker;

function ArchivedReportsOverview(props) {
  function disabledDate(current) {
    return (current && current < moment("2019-01-01T00:00:00")) || (current && current > moment().endOf("day"));
  }

  const formattedInterval = moment(props.startDate).format("MMMM YYYY");

  return (
    <div className="archived-reports-overview">
      <MonthPicker
        allowClear={false}
        disabledDate={disabledDate}
        value={moment(props.startDate)}
        placeholder="Select month"
        format={"MMMM YYYY"}
        onChange={props.handleIntervalChange}
      />
      <div className="dashboard-skeleton-card-container analysis-page">
        <Row gutter={20} className="analysis-overview-container">
          <Col span={12}>
            <SeoScoreProgressForArchivedOverview
              isLoading={props.isLoading}
              url={props.url}
              customInterval={formattedInterval}
              push={props.push}
              chartData={props.chartData}
              competitors={props.competitors}
              lastSavedReportGuid={props.lastSavedReportGuid}
            />
          </Col>
          <Col span={12}>
            <Reports isLoading={props.isLoading} url={props.url} date={formattedInterval} overviews={props.overviews} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ArchivedReportsOverview;
