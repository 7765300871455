import React from "react";
import { Layout } from "antd";
import { Analysis } from "../containers";
const { Content } = Layout;

const AnalysisPage = () => {
  return (
    <Content className="general-layout-content">
      <Analysis />
    </Content>
  );
};

export default AnalysisPage;
