import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { v4 as uuidv4 } from "uuid";
import AnalysisForm from "../../components/Dashboard/AnalysisForm/AnalysisForm";
import { initAnalysis } from "../../store/analysis/operations";

export class AnalysisFormContainer extends Component {
  constructor(props) {
    super(props);

    this.generateNewReport = this.generateNewReport.bind(this);
  }

  generateNewReport(url) {
    const tempGuid = uuidv4();
    this.props.push("/analysis/report");
    const competitors = [];
    const sections = [];
    this.props.initAnalysis(tempGuid, url, competitors, sections);
  }

  render() {
    return <AnalysisForm generateNewReport={this.generateNewReport} />;
  }
}

export default connect(null, { initAnalysis, push })(AnalysisFormContainer);
