import { LOADING_TIME_API_URL } from "../../settings/config";
import {
  fetchLoadingTimeInProgress,
  fetchLoadingTimeSuccessful,
  fetchLoadingTimeFailed,
  fetchLoadingTimeForDashboardInProgress,
  fetchLoadingTimeForDashboardFinished,
  sendLoadingTimeRequestInProgress,
  sendLoadingTimeRequestSuccessful,
  sendLoadingTimeRequestFailed
} from "./creators";
import { authRequest } from "../authorizedRequests";
import { getSelectedIntervalLoadingTimeData } from "./selectors";
import { getSelectedWebsiteRegions } from "../tracked_websites/selectors";

export const fetchLoadingTime = (url, region, interval, startDate, endDate, groupBy) => {
  return (dispatch, getState) => {
    if (getSelectedIntervalLoadingTimeData(getState()).length > 0 && interval !== "custom_interval") {
      return Promise.resolve();
    }

    const apiUrl = `${LOADING_TIME_API_URL}/loading-time?url=${url}&region=${region}&start_date=${startDate}&end_date=${endDate}&group_by=${groupBy}`;

    dispatch(fetchLoadingTimeInProgress(url, region, interval));

    return authRequest(apiUrl, dispatch, getState)
      .then(response => {
        dispatch(fetchLoadingTimeSuccessful(url, region, interval, response.reports));
      })
      .catch(_ => {
        dispatch(fetchLoadingTimeFailed(url, region, interval));
      });
  };
};

export const fetchLoadingTimeForDashboard = (url, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch(fetchLoadingTimeForDashboardInProgress(url));
    let regions = getSelectedWebsiteRegions(getState());
    let loadingTimePromises = regions.map(region =>
      dispatch(fetchLoadingTime(url, region, "custom_interval", startDate, endDate, "day"))
    );
    Promise.all(loadingTimePromises).finally(_ => dispatch(fetchLoadingTimeForDashboardFinished(url)));
  };
};

export const sendLoadingTimeRequest = (url, regions) => {
  return (dispatch, getState) => {
    const apiUrl = `${LOADING_TIME_API_URL}/loading-time`;
    const options = { method: "POST", body: JSON.stringify({ url, regions }) };
    dispatch(sendLoadingTimeRequestInProgress());
    return authRequest(apiUrl, dispatch, getState, options)
      .then(response => {
        return dispatch(sendLoadingTimeRequestSuccessful());
      })
      .catch(_ => {
        return dispatch(sendLoadingTimeRequestFailed());
      });
  };
};
