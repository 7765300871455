import React, { Component } from "react";
import { connect } from "react-redux";
import SideMenuLayout from "../components/layout/SideMenu";
import { getLocation, push } from "connected-react-router";
import { userSubscription } from "../store/sales/selectors";
import { getUser, getOrganization } from "../store/iam/selectors";
import { fetchOrganization } from "../store/iam/operations";

class SideBar extends Component {
  componentDidMount = (_) => {
    this.props.fetchOrganization();
  };

  render() {
    return (
      <SideMenuLayout
        selectedMenuItem={this.props.currentPath}
        onMenuItemClick={this.props.push}
        user={this.props.user}
        subscription={this.props.userSubscription}
        organization={this.props.organization}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentPath: getLocation(state).pathname,
  userSubscription: userSubscription(state),
  user: getUser(state),
  organization: getOrganization(state)
});

export default connect(mapStateToProps, { push, fetchOrganization })(SideBar);
