import React, { Component } from "react";
import { Form, Input, Button, Upload, Divider, Icon, Switch, PageHeader } from "antd";

const { Dragger } = Upload;
const { TextArea } = Input;

class TemplateForm extends Component {
  constructor(props) {
    super(props);

    this.originalTemplate = this.getInitialTemplateState(this.props.selectedTemplate);

    this.state = {
      templateName: this.props.selectedTemplate["name"],
      customHeader: this.props.selectedTemplate["content_blocks"]["header"],
      customFooter: this.props.selectedTemplate["content_blocks"]["footer"],
      logo: this.props.selectedTemplate["logo"],
      logoType: undefined,
      includeHowToFix: this.props.selectedTemplate["show_how_to_fix_in_pdf"],
      validationErrors: {}
    };

    this.dragAndDropImg = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.dummyRequest = this.dummyRequest.bind(this);
    this.validateLogoFile = this.validateLogoFile.bind(this);
    this.getInitialTemplateState = this.getInitialTemplateState.bind(this);
    this.isUrl = this.isUrl.bind(this);
  }

  getInitialTemplateState(selectedTemplate) {
    return {
      templateName: selectedTemplate["name"],
      customHeader: selectedTemplate["content_blocks"]["header"],
      customFooter: selectedTemplate["content_blocks"]["footer"],
      logo: selectedTemplate["logo"],
      includeHowToFix: selectedTemplate["show_how_to_fix_in_pdf"]
    };
  }

  componentDidMount() {
    if (this.props.idUrlParam === "new") {
      this.setState({
        templateName: "",
        customHeader: "",
        customFooter: "",
        logo: "",
        includeHowToFix: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idUrlParam !== this.props.idUrlParam && this.props.idUrlParam === "new") {
      let newState = {
        templateName: "",
        customHeader: "",
        customFooter: "",
        logo: "",
        includeHowToFix: true,
        validationErrors: {}
      };
      this.setState(newState);
      this.originalTemplate = newState;
    }
    if (
      prevProps.selectedTemplate !== this.props.selectedTemplate ||
      (prevProps.idUrlParam === undefined && this.props.idUrlParam === this.props.selectedTemplate["guid"])
    ) {
      this.setState({
        templateName: this.props.selectedTemplate["name"],
        logo: this.props.selectedTemplate["logo"],
        customHeader: this.props.selectedTemplate["content_blocks"]["header"],
        customFooter: this.props.selectedTemplate["content_blocks"]["footer"],
        includeHowToFix: this.props.selectedTemplate["show_how_to_fix_in_pdf"]
      });
      this.props.sendTemplateData(
        this.props.selectedTemplate["logo"],
        this.props.selectedTemplate["content_blocks"]["header"],
        this.props.selectedTemplate["content_blocks"]["footer"]
      );
      this.originalTemplate = this.getInitialTemplateState(this.props.selectedTemplate);
    }
  }

  getUpdatedInputs(originalTemplate, currentTemplate) {
    let updatedInputs = {};
    Object.keys(originalTemplate).forEach(input => {
      if (currentTemplate[input] !== originalTemplate[input]) {
        updatedInputs[input] = currentTemplate[input];
      }
    });
    return updatedInputs;
  }

  isUrl(string) {
    if (string) {
      return string.split(":")[0] === "https";
    }
    return false;
  }

  handleSubmit(event) {
    event.preventDefault();
    event.currentTarget.getElementsByTagName("button")[1].blur();

    if (!this.state.templateName) {
      this.setState({
        validationErrors: {
          templateName: "This field is requested!"
        }
      });
      return;
    }
    if (this.state.validationErrors.logo && this.state.validationErrors.logo.length) {
      return;
    }

    let updatedInputs = this.getUpdatedInputs(this.originalTemplate, this.state);
    let logoWasUpdated = "logo" in updatedInputs;

    let payload = {
      name: this.state["templateName"],
      engine_version: "1.0.0",
      theme: "default",
      show_how_to_fix_in_pdf: this.state["includeHowToFix"],
      base64_logo: logoWasUpdated ? this.state["logo"].replace(/^data:.+;base64,/, "") : this.state["logo"],
      content_blocks: {
        header: this.state["customHeader"],
        footer: this.state["customFooter"]
      }
    };

    if (this.props.idUrlParam === "new") {
      this.props.addTemplate(payload);
    } else {
      let patchLogo = !logoWasUpdated ? undefined : payload["base64_logo"];
      payload["base64_logo"] = patchLogo;
      this.props.editTemplate(this.props.selectedTemplateGuid, payload);
    }
  }

  handleInputChange(event) {
    this.setState({ templateName: event.target.value });
  }

  handleInputFocus(event) {
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        templateName: ""
      }
    });
  }

  handleTextareaChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "customHeader") {
      this.props.sendTemplateData(this.state.logo, event.target.value, this.state.customFooter);
    }
    if (event.target.name === "customFooter") {
      this.props.sendTemplateData(this.state.logo, this.state.customHeader, event.target.value);
    }
  }

  handleSwitchChange(checked, event) {
    const newState = {};
    newState[event.target.name] = checked;
    this.setState(newState);
  }

  validateLogoFile(logo) {
    let errorMessages = [];
    const allowedTypes = ["png", "gif", "jpg", "jpeg"];

    // file.type has the same format as a MIME type: type/subtype
    const type = logo.type.split("/")[1];
    if (!allowedTypes.includes(type)) {
      errorMessages = [...errorMessages, "This file type is not accepted"];
    }
    if (logo.size > 1024 * 1024) {
      errorMessages = [...errorMessages, "Logo must be smaller than 1Mb!"];
    }
    return errorMessages;
  }

  // uploaded image is resized and its aspect ratio is maintained
  beforeUpload(file, fileList) {
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        logo: []
      }
    });

    let logoValidationErrors = this.validateLogoFile(file);
    if (logoValidationErrors.length > 0) {
      this.setState({
        logo: "",
        validationErrors: {
          ...this.state.validationErrors,
          logo: logoValidationErrors
        }
      });
      return false;
    }

    const height = 70;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        const scaleFactor = height / img.height;
        elem.width = img.width * scaleFactor;
        elem.height = height;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, img.width * scaleFactor, height);
        const data = ctx.canvas.toDataURL(img);
        //const b64 = data.replace(/^data:.+;base64,/, "");
        this.setState({ logo: data, logoType: file.type.split("/")[1] });
        this.props.sendTemplateData(data, this.state.customHeader, this.state.customFooter);
      };
    };
  }

  dummyRequest({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  render() {
    const errors = this.state.validationErrors;

    return (
      <div className="branded-pdf-template">
        <PageHeader onBack={() => this.props.push("/white-label/templates")} title="PDF Brand Template" />
        <div className="form-description">Apply your brand basic template for all future Branded PDFs</div>

        <Divider />
        <Form className="template-form" onSubmit={this.handleSubmit}>
          <Form.Item
            validateStatus={errors.templateName ? "error" : "success"}
            help={errors.templateName ? errors.templateName : ""}>
            <div className="form-label">Template name</div>
            <Input
              placeholder="Template name goes here"
              name="templateName"
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              value={this.state.templateName}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.logo && errors.logo.length ? "error" : "success"}
            help={
              errors.logo && errors.logo.length ? (
                <React.Fragment>
                  <div>{errors.logo[0]}</div>
                  <div>{errors.logo[1]}</div>
                </React.Fragment>
              ) : (
                ""
              )
            }>
            <div className="form-label flexbox align-center">
              <div className="step-counter flexbox align-center justify-center">1</div>
              Company logo
            </div>
            <div className="form-description">Allowed types (max 1Mb): png, gif, jpg or jpeg</div>
            <div></div>
            <Dragger
              name="file"
              customRequest={this.dummyRequest}
              beforeUpload={this.beforeUpload}
              multiple={false}
              showUploadList={false}>
              <div className="ant-upload-text flexbox align-center justify-center flex-wrap">
                {this.state.logo ? (
                  <img
                    ref={this.dragAndDropImg}
                    src={this.isUrl(this.state.logo) ? `${this.state.logo}?${new Date().getTime()}` : this.state.logo}
                    alt="logo-preview"
                    crossOrigin="anonymous"
                  />
                ) : (
                  <Icon type="picture" />
                )}
                <div>Drag and drop or select your logo here</div>
              </div>
            </Dragger>
          </Form.Item>
          <Divider />
          <Form.Item>
            <div className="form-label flexbox align-center">
              <div className="step-counter flexbox align-center justify-center">2</div>
              Report header
            </div>
            <TextArea
              value={this.state.customHeader}
              name="customHeader"
              onChange={this.handleTextareaChange}
              placeholder="Header message goes here."
              maxLength={144}
              style={{ resize: "none" }}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <div className="form-label flexbox align-center">
              <div className="step-counter flexbox align-center justify-center">3</div>
              Report footer
            </div>
            <TextArea
              value={this.state.customFooter}
              name="customFooter"
              onChange={this.handleTextareaChange}
              placeholder="Footer message goes here."
              maxLength={144}
              style={{ resize: "none" }}
            />
          </Form.Item>
          <Form.Item>
            <div>
              <Switch checked={this.state.includeHowToFix} name="includeHowToFix" onChange={this.handleSwitchChange} />{" "}
              Include "How to Fix" when Factor is not Passed
            </div>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={this.props.isLoading}>
              Save template
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default TemplateForm;
