import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedWebsiteDomain } from "../../store/tracked_websites/selectors";
import { getSitemapReport, 
  isFetchingSitemap, 
  hasMoreUrls, 
  isGeneratingSitemap, 
  hasGeneratedSitemaps, 
  isLoadingMoreSitemapUrls
} from "../../store/sitemap/selectors";
import { fetchSitemap, generateSitemap } from "../../store/sitemap/operations";
import SitemapLayout from "../../components/Sitemap/SitemapLayout";

class Sitemap extends Component {
  state={
    data:{},
    showInfoModal: false
  }

  componentDidMount = _ => {     
    this.fetchUrlsForDomain(this.props.domain);
  };

  fetchMoreUrls=()=>{
    if(this.props.nextUrlHash){       
      this.fetchUrlsForDomain(this.props.domain, this.props.nextUrlHash);      
    }
  }

  fetchUrlsForDomain=(domain, nextUrlHash=null)=>{
    this.props.fetchSitemap(domain, nextUrlHash).then(response=>{
      this.setState((state) => ({
        data: {...state.data, [domain]:[...state.data[domain]?state.data[domain]:[], ...response.urls]}
      }));
    })
  }

  downloadXml=()=>{   
    let showInfoModal = false;
    if(!this.props.hasGeneratedSitemaps){
      showInfoModal = true;
    } 
    this.props.generateSitemap(this.props.domain).then(()=>{
      if(showInfoModal){
        this.setState({showInfoModal:true});
      }
    })      
  }

  closeModal=()=>{
    this.setState({showInfoModal:false});
  }

  render() {
    let report = null;
    if(this.state.data[this.props.domain] && this.state.data[this.props.domain].length){
      report = this.state.data[this.props.domain]
    }
    return <SitemapLayout 
      domain={this.props.domain} 
      isLoading={this.props.isLoading} 
      isLoadingMoreSitemapUrls={this.props.isLoadingMoreSitemapUrls} 
      report={report}
      fetchMoreUrls={this.fetchMoreUrls} 
      downloadXml={this.downloadXml}
      generatingSitemap={this.props.generatingSitemap}
      showModal={this.state.showInfoModal && !this.props.generatingSitemap}
      closeModal={this.closeModal}
    />;
  }
}

const mapStateToProps = state => ({
  domain: getSelectedWebsiteDomain(state),
  isLoading: isFetchingSitemap(state),
  generatingSitemap: isGeneratingSitemap(state),
  report: getSitemapReport(state),
  nextUrlHash: hasMoreUrls(state),
  hasGeneratedSitemaps: hasGeneratedSitemaps(state),
  isLoadingMoreSitemapUrls: isLoadingMoreSitemapUrls(state)
});

export default connect(mapStateToProps, { fetchSitemap, generateSitemap })(Sitemap);
