import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import SiteOutageLayout from "../../components/SiteOutage/SiteOutageLayout";
import { fetchOutageList } from "../../store/outage/operations";
import { getSelectedWebsiteUrl } from "../../store/tracked_websites/selectors";
import { isLoadingList, isLoadingContext, getContext } from "../../store/outage/selectors";
import { fetchMonitorLocations } from "../../store/monitor_locations/operations";

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const startDate = moment
  .utc()
  .subtract(30, "d")
  .format(DATE_FORMAT);
const endDate = moment.utc().format(DATE_FORMAT);

class SiteOutage extends Component {
  componentDidMount() {
    this.props.fetchMonitorLocations();
    this.props.fetchOutageList(this.props.url, startDate, endDate);
  }
  render() {
    return (
      <SiteOutageLayout
        startDate={startDate}
        endDate={endDate}
        url={this.props.url}
        isLoadingList={this.props.isLoadingList}
        isLoadingContext={this.props.isLoadingContext}
        currentContext={this.props.currentContext}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoadingList: isLoadingList(state),
  isLoadingContext: isLoadingContext(state, getSelectedWebsiteUrl(state)),
  url: getSelectedWebsiteUrl(state),
  currentContext: getContext(state, getSelectedWebsiteUrl(state))
});

export default connect(mapStateToProps, {
  fetchMonitorLocations,
  fetchOutageList
})(SiteOutage);
