import {
  getSelectedWebsiteUrl,
  getSelectedWebsiteRegions,
  getSelectedWebsiteMainLocation
} from "../tracked_websites/selectors";
import { getMonitorLocationValue } from "../monitor_locations/selectors";

export const getDefaultInterval = (state) => state["loading_time"]["default_interval"];

export const getIntervals = (state) => state["loading_time"]["intervals"];

export const getSelectedRegion = (state) => {
  let selectedUrl = getSelectedWebsiteUrl(state);
  if (state["loading_time"] && state["loading_time"]["data"][selectedUrl]) {
    return state["loading_time"]["data"][selectedUrl]["selected_region"];
  }
};

export const getSelectedInterval = (state) => {
  let selectedUrl = getSelectedWebsiteUrl(state);
  if (state["loading_time"] && state["loading_time"]["data"][selectedUrl]) {
    return state["loading_time"]["data"][selectedUrl]["selected_interval"];
  }
};

export const isFetchingLoadingData = (state) => {
  let selectedInterval = getSelectedInterval(state);
  let selectedUrl = getSelectedWebsiteUrl(state);
  let selectedRegion = getSelectedRegion(state);
  return state["loading_time"]["network_activity"].includes(
    `fetch_${selectedInterval}_${selectedUrl}_${selectedRegion}`
  );
};

export const isFetchingLoadingTimeForAllRegions = (state) => {
  let selectedUrl = getSelectedWebsiteUrl(state);
  return state["loading_time"]["network_activity"].includes(`fetch_loading_time_for_dashboard_${selectedUrl}`);
};

// loading time data for selected url, region & current interval
export const getSelectedIntervalLoadingTimeData = (state) => {
  let selectedInterval = getSelectedInterval(state);
  let selectedUrl = getSelectedWebsiteUrl(state);
  let selectedRegion = getSelectedRegion(state);
  let data = [];
  if (
    state["loading_time"]["data"][selectedUrl] &&
    state["loading_time"]["data"][selectedUrl][selectedRegion] &&
    state["loading_time"]["data"][selectedUrl][selectedRegion][selectedInterval]
  ) {
    state["loading_time"]["data"][selectedUrl][selectedRegion][selectedInterval].forEach((item) => {
      data = [
        ...data,
        {
          type: "loading time",
          c_at: item["c_at"],
          value: item["loading_time"],
          min: item["min_loading_time"],
          max: item["max_loading_time"]
        },
        { type: "page size", c_at: item["c_at"], value: item["page_size"] },
        { type: "loading speed", c_at: item["c_at"], value: item["loading_speed"] }
      ];
    });
  }
  return data;
};

// TODO: find a better name for selector bellow
//returns a list of objects with type "loading time" - for Dashboard chart
export const getLoadingTimePerRegion = (state, region) => {
  let selectedInterval = getSelectedInterval(state);
  let selectedUrl = getSelectedWebsiteUrl(state);

  let data = [];
  if (
    state["loading_time"]["data"][selectedUrl] &&
    state["loading_time"]["data"][selectedUrl][region] &&
    state["loading_time"]["data"][selectedUrl][region][selectedInterval]
  ) {
    state["loading_time"]["data"][selectedUrl][region][selectedInterval].forEach((item) => {
      data = [
        ...data,
        {
          region: getMonitorLocationValue(state, region),
          c_at: item["c_at"],
          value: item["loading_time"],
          min: item["min_loading_time"],
          max: item["max_loading_time"]
        }
      ];
    });
  }
  return data;
};

export const getLoadingTimeForUrl = (state) => {
  let regions = getSelectedWebsiteRegions(state);
  let data = [];
  if (regions.length > 0) {
    regions.forEach((region) => {
      data = [...data, ...getLoadingTimePerRegion(state, region)];
    });
  }

  return data;
};

export const hasLoadingTimeDataForDashboard = (state) => {
  let hasData = false;
  const selectedUrl = getSelectedWebsiteUrl(state);
  const mainRegion = getSelectedWebsiteMainLocation(state);
  const loadingTimeData = state["loading_time"]["data"];

  try {
    if (loadingTimeData[selectedUrl] && loadingTimeData[selectedUrl][mainRegion]) {
      hasData = loadingTimeData[selectedUrl][mainRegion]["custom_interval"].length > 0 ? true : false;
    }
  } catch (e) {
    return false;
  }

  return hasData;
};

export const isSendingLoadingTimeRequest = (state) => {
  return state["loading_time"]["network_activity"].includes(`loading_time_request`);
};
