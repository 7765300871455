import React, { Component } from "react";
import { Modal, Table, Button, Alert, Spin, Icon } from "antd";
import moment from "moment";
import ModalTitle from "../shared/ModalTitle/ModalTitle";

const formatDate = dateStr => {
  return moment(dateStr, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY, HH:mm");
};

const getAffectedPagesDataSource = affectedPages => {
  return affectedPages.map((page, idx) => ({
    key: idx + 1,
    updatedAt: formatDate(page["updated_at"]),
    url: page["url"]
  }));
};

const columns = [
  {
    title: "#",
    dataIndex: "key",
    width: "45px",
    className: "no-wrap"
  },
  {
    title: "Last checked",
    dataIndex: "updatedAt",
    key: "updatedAt",
    className: "affected-pages-last-update-column no-wrap",
    width: "180px"
  },
  {
    title: "Referring pages",
    dataIndex: "url",
    key: "url",
    className: "affected-pages-column no-wrap",
    render: (_, record) => (
      <span>
        <a target="_blank" href={record["url"]} rel="noopener noreferrer">
          {record["url"]}
        </a>
      </span>
    )
  }
];

class AffectedPagesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  handleOpenModal() {
    this.props.fetchAffectedPages(this.props.domain, this.props.url).then(_ => this.toggleModal());
  }

  handleCloseModal() {
    this.toggleModal();
    this.props.resetAffectedPages(this.props.domain, this.props.url);
  }

  handleLoadMore() {
    this.props.fetchAffectedPages(this.props.domain, this.props.url, this.props.lastUat);
  }

  render() {
    const isMainLoaderVisible = this.props.isLoading && !this.props.affectedPages.length;
    return (
      <React.Fragment>
        {isMainLoaderVisible ? (
          <Spin style={{ lineHeight: "12px" }} indicator={<Icon type="loading" spin style={{ fontSize: 12 }} />} />
        ) : (
          <span className="view-affected-pages-modal" onClick={this.handleOpenModal}>
            View URLs
          </span>
        )}
        <Modal
          className="affected-pages-modal"
          title={
            <ModalTitle
              title={
                this.props.affectedPages.length > 0
                  ? "Pages where this broken link is present:"
                  : "No pages contain this broken link currently"
              }
              meta={this.props.url}
            />
          }
          visible={this.state.visible}
          onCancel={this.handleCloseModal}
          footer={null}
          width={1024}
          destroyOnClose>
          {this.props.affectedPages.length ? (
            <div>
              <Table
                className="affected-pages-table custom-scrollbar"
                dataSource={getAffectedPagesDataSource(this.props.affectedPages)}
                columns={columns}
                pagination={false}
                size="small"
              />
              {this.props.hasMore && (
                <Button
                  className="load-more"
                  type="primary"
                  loading={this.props.isLoading}
                  onClick={this.handleLoadMore}>
                  Load more
                </Button>
              )}
            </div>
          ) : (
            <Alert message="No pages are affected at the moment" />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default AffectedPagesModal;
