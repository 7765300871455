import React, { Component } from "react";
import { Form, Input, Button, Alert } from "antd";
import { validate } from "validate.js";
import "./BillingInfo.less";

const userNameValidationRules = {
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      message: "^This field is required"
    }
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      message: "^This field is required"
    }
  }
};

export default class BillingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      company: "",
      vatNumber: "",
      validationErrors: {}
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    if (this.props.billingInfo) {
      this.setState({
        firstName: this.props.billingInfo["first_name"],
        lastName: this.props.billingInfo["last_name"],
        company: this.props.billingInfo["company"],
        vatNumber: this.props.billingInfo["vat_number"]
      });
    }
  }

  validateForm() {
    return validate(
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName
      },
      userNameValidationRules
    );
  }

  onFormSubmit = event => {
    event.preventDefault();

    let validationErrors = this.validateForm();

    if (!validationErrors) {
      this.setState({ validationErrors: {} });
      const firstName = this.state.firstName;
      const lastName = this.state.lastName;
      const company = this.state.company;
      const vatNumber = this.state.vatNumber;

      this.props.onUpdate(firstName, lastName, company, vatNumber);
    } else {
      this.setState({ validationErrors });
    }
  };

  onInputChange(event) {
    const newState = {};
    const value = event.target.value;
    newState[event.target.name] = value;
    this.setState({ validationErrors: {}, ...newState });
  }

  render() {
    const errors = this.state.validationErrors;

    return (
      <div className="billing-info-container">
        <Alert
          className="message"
          message="Changes to your billing information will take effect starting with next scheduled payment and will be
          reflected on your next invoice"
          type="info"
          showIcon
        />
        <Form className="billing-info-form" onSubmit={this.onFormSubmit}>
          <Form.Item
            label="First name"
            validateStatus={errors.firstName && errors.firstName[0] ? "error" : "validating"}
            help={errors.firstName && errors.firstName[0]}>
            <Input name="firstName" type="text" value={this.state.firstName} onChange={this.onInputChange} />
          </Form.Item>
          <Form.Item
            label="Last name"
            validateStatus={errors.lastName && errors.lastName[0] ? "error" : "validating"}
            help={errors.lastName && errors.lastName[0]}>
            <Input name="lastName" type="text" value={this.state.lastName} onChange={this.onInputChange} />
          </Form.Item>
          <Form.Item label="Company name">
            <Input name="company" type="text" value={this.state.company} onChange={this.onInputChange} />
          </Form.Item>
          <Form.Item label="VAT number">
            <Input name="vatNumber" type="text" value={this.state.vatNumber} onChange={this.onInputChange} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={this.props.isUpdatingBillingInfo}>
              {this.props.isUpdatingBillingInfo ? "Saving..." : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
