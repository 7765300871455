import React from "react";
import { Layout } from "antd";
import SubscriptionCheckout from "../containers/Sales/SubscriptionCheckout";
const { Content } = Layout;

const SubscriptionCheckoutPage = () => {
  return (
    <Content className="general-layout-content">
      <SubscriptionCheckout />
    </Content>
  );
};

export default SubscriptionCheckoutPage;
