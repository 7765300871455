import React, { Component } from "react";
import { connect } from "react-redux";
import { TreeSelect } from "antd";
import { getCategorizedSections, getSectionsTitlesList } from "../store/analysis/selectors";

class SectionsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: this.props.sectionsTitles
    };

    this.getTreeData = this.getTreeData.bind(this);
    this.getSectionsKeys = this.getSectionsKeys.bind(this);
    this.updateSelectedSections = this.updateSelectedSections.bind(this);
  }

  /**
   * populates state.sections after the API call for general structure of seo report is finished
   * and sends sections keys array to AnalysisForm parent
   */
  componentDidUpdate(prevProps) {
    if (prevProps.categorizedSections !== this.props.categorizedSections) {
      this.updateSelectedSections(this.props.sectionsTitles);
    }
  }

  // children value is set to section.title to allow filtering based on the sections title
  getTreeData(categorizedSections) {
    return [
      {
        title: "All sections",
        value: "All sections",
        key: "All sections",
        selectable: true,
        children: Object.keys(categorizedSections).map((category) => ({
          title: category,
          value: category,
          key: category,
          selectable: true,
          children: categorizedSections[category].map((section) => ({
            title: section.title,
            value: section.title,
            key: section.key,
            selectable: false
          }))
        }))
      }
    ];
  }

  // AnalysisForm receives the section keys, not section titles
  getSectionsKeys(sectionsTitles, categorizedSections) {
    return sectionsTitles.reduce((acc, title) => {
      let all_sections = Object.values(categorizedSections).flat();
      all_sections.forEach((section) => {
        if (section.title === title) {
          acc.push(section.key);
        }
      });
      return acc;
    }, []);
  }

  updateSelectedSections(selectedSectionsTitles) {
    this.setState({ sections: selectedSectionsTitles });
    let selectedSectionsKeys = this.getSectionsKeys(selectedSectionsTitles, this.props.categorizedSections);
    this.props.sendSelectedSections(selectedSectionsKeys);
  }

  render() {
    const tProps = {
      allowClear: true,
      treeData: this.getTreeData(this.props.categorizedSections),
      value: this.state.sections,
      onChange: this.updateSelectedSections,
      treeCheckable: true,
      multiple: true,
      treeDefaultExpandAll: true,
      searchPlaceholder: "Edit Checkup Factors",
      dropdownStyle: {
        maxHeight: 400,
        overflow: "auto"
      },
      maxTagCount: 1
    };
    return <TreeSelect className="tree-select" {...tProps} />;
  }
}

const mapStateToProps = (state) => ({
  categorizedSections: getCategorizedSections(state),
  sectionsTitles: getSectionsTitlesList(state)
});

export default connect(mapStateToProps, {})(SectionsSelector);
