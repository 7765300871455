import React from 'react';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import atomDark from './atom-dark';

type Props = {
    className: string;
    sitemap: string;
    onLoadMoreUrls: () => void;
};

export default function HighlighterComponent({ className, sitemap, onLoadMoreUrls }: Props) {
    const handleScroll = (event: React.MouseEvent) => {
        if (!(event.currentTarget.scrollHeight > event.currentTarget.clientHeight)) return; //prevent horizontal scrolling

        const bottom = event.currentTarget.scrollHeight - event.currentTarget.scrollTop === event.currentTarget.clientHeight;
        if (bottom) {
            onLoadMoreUrls();
        }
    };

    return (
        <SyntaxHighlighter
            customStyle={{ fontSize: '12px' }}
            wrapLines={true}
            lineProps={{ className: 'line-wrapper' }}
            language="xml"
            style={atomDark}
            className={className}
            onScroll={handleScroll}
            showLineNumbers={true}
            linenumberprops={{
                className: 'line-number',
            }}
            linenumbercontainerprops={{ className: 'line-number-container', style: { paddingRight: '20px', float: 'left' } }}
        >
            {sitemap}
        </SyntaxHighlighter>
    );
}
