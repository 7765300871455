import { ResearchedKeyword } from './types';

const numberFormatter = new Intl.NumberFormat('en-US');

export function getKeywordResearchTableData(keywords: ResearchedKeyword[]) {
    return keywords.map((keyword, idx) => ({
        key: idx + 1,
        keyword: keyword.phrase,
        searchVolume: keyword.vol !== null ? numberFormatter.format(keyword.vol) : '-',
        cpc: keyword.cpc !== null ? numberFormatter.format(keyword.cpc) : '-',
        cmp: keyword.cmp !== null ? numberFormatter.format(keyword.cmp) : '-',
        resultsNo: keyword.r_no !== null ? numberFormatter.format(keyword.r_no) : '-',
        trends: keyword.trends,
    }));
}
