import React from "react";
import SeoReportSummaryLayout from "./SeoReportSummaryLayout";
import SeoReportCategories from "../../containers/SeoReport/SeoReportCategories";
import SeoReportSidebarWrapper from "../../containers/SeoReport/SeoReportSidebarWrapper";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
import Analysis from "../../containers/Analysis";

import "./ReportCard.less";
import "./ReportElement.less";

function SeoReportLayout(props) {
  if (props.isLoading) {
    return <LoadingIndicator detailed text="Loading results" />;
  }

  if (props.savedReportFetchedError) {
    return <Analysis error={props.savedReportFetchedError} />;
  }
  //console.log(props.isFullReport);
  return (
    <div>
      <div data-cy="analysis-report" className="report-wrapper">
        <div className="report-summary">
          <SeoReportSummaryLayout
            id="header-seo-summary"
            mainUrl={props.mainUrl}
            reportGuid={props.reportGuid}
            isFullReport={props.isFullReport}
          />

          <SeoReportCategories mainUrl={props.mainUrl} reportGuid={props.reportGuid} />

          {props.selectedSectionsCount >= 5 && (
            <SeoReportSummaryLayout
              id="footer-seo-summary"
              mainUrl={props.mainUrl}
              reportGuid={props.reportGuid}
              isFullReport={props.isFullReport}
            />
          )}
        </div>

        <SeoReportSidebarWrapper reportGuid={props.reportGuid} />
      </div>
    </div>
  );
}

export default SeoReportLayout;
