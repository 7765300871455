import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";

const displayResult = (title, result) => {
  if (result.non_http2_resources && Object.entries(result.non_http2_resources).length > 0) {
    return (
      <React.Fragment>
        <div className="description-meta">
          <InlineModal openLabel="See results list" modalTitle="Full list of non-http2 resources" closeLabel="Close">
            {Object.entries(result.non_http2_resources).map(([host, items], idx) => {
              return (
                <div key={idx}>
                  <div className="list-title">From {host}:</div>
                  <ul className="bulleted-list">
                    {items.map((item, itemIdx) => {
                      return (
                        <li key={itemIdx}>
                          <a href={item["url"]} target="_blank" rel="noopener noreferrer">
                            {item["url"]}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </InlineModal>
        </div>
        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </React.Fragment>
    );
  }
  return null;
};

const Http2Section = ({ url, sectionKey, title, tooltip, relevance, result, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default Http2Section;
