import React from "react";
import { Row, Col, Alert } from "antd";
import moment from "moment";
import {
  SummaryContainer,
  SeoScoreProgressContainer,
  ImportantFixesBoxContainer,
  SiteSpeedBoxContainer,
  SiteOutageBoxContainer
} from "../../containers/Dashboard";
import WelcomeScreenContainer from "../../containers/WelcomeScreenContainer";
import DashboardBacklinksBox from '../../dashboard/DashboardBacklinksBox';
import DashboardTopKeywordsBox from '../../dashboard/DashboardTopKeywordsBox';

const DashboardTabContent = props => {
  let endDate = moment()
    .utc()
    .add(1, "days")
    .format("YYYY-MM-DDT00:00:00");
  let startDate = moment(endDate)
    .subtract(7, "days")
    .format("YYYY-MM-DDT00:00:00");

  return (
    <div className="dashboard-skeleton-card-container">
      {props.showAlert && (
        <div className="add-new-website-alert">
          <Alert message="Your website was successfully added and we've started the monitoring process." type="info" />
        </div>
      )}

      <WelcomeScreenContainer />

      <Row gutter={20} className="top-triple-grid">
        <Col span={6} className="split-item">
          <SummaryContainer startDate={startDate} endDate={endDate} />
        </Col>
        <Col span={12} className="split-item">
          <SeoScoreProgressContainer
            startDate={startDate}
            endDate={endDate}
            shouldLongPolling={props.shouldLongPolling}
          />
        </Col>
        <Col span={6} className="split-item">
          <ImportantFixesBoxContainer
            startDate={startDate}
            endDate={endDate}
            shouldLongPolling={props.shouldLongPolling}
          />
        </Col>
      </Row>
      <Row gutter={20} className="mid-split-grid">
        <Col span={12} className="split-item">
          <DashboardBacklinksBox startDate={startDate} endDate={endDate} />
        </Col>
        <Col span={12} className="split-item">
          <DashboardTopKeywordsBox />
        </Col>
      </Row>
      <Row gutter={20} className="bottom-split-grid">
        <Col span={12} className="split-item">
          <SiteSpeedBoxContainer startDate={startDate} endDate={endDate} shouldLongPolling={props.shouldLongPolling} />
        </Col>
        <Col span={12} className="split-item">
          <SiteOutageBoxContainer startDate={startDate} endDate={endDate} shouldLongPolling={props.shouldLongPolling} />
        </Col>
      </Row>
    </div>
  );
};

export default DashboardTabContent;
