import React, { Component } from "react";
import { Card, Form, Icon, Input, Checkbox, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { SSC_TOS_URL, RECAPTCHA_API_KEY } from "../../../settings/config";
import { validate } from "validate.js";
import registerValidationRules from "./validators";

const { Meta } = Card;

export class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      repeatPassword: "",
      agreementChecked: false,
      validationErrors: {},
      isRecaptchaVerified: false,
      recaptchaToken: ""
    };

    this.captcha = null;
    this.checkboxRef = React.createRef();
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldResetCaptcha && this.props.shouldResetCaptcha) {
      this.captcha.reset();
    }
  }

  validateForm() {
    return validate(
      {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
        repeatPassword: this.state.repeatPassword,
        agreementChecked: this.state.agreementChecked
      },
      registerValidationRules
    );
  }

  onFormSubmit(event) {
    event.preventDefault();

    const validationErrors = this.validateForm();
    if (!validationErrors && this.state.isRecaptchaVerified) {
      this.setState({ validationErrors: {} });

      this.props.onSubmit(
        this.state.email,
        this.state.firstName,
        this.state.lastName,
        this.state.password,
        this.state.recaptchaToken
      );

      this.setState({ isRecaptchaVerified: false, recaptchaToken: "" });
    } else {
      this.setState({ validationErrors: validationErrors });
    }
  }

  onInputChange(event) {
    const newState = {};
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    newState[event.target.name] = value;
    this.setState(newState);

    if (event.target.type === "checkbox") {
      this.checkboxRef.current.blur();
    }
  }

  onInputFocus(event) {
    if (this.state.validationErrors[event.target.name]) {
      const newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
      this.setState(newState);
    }
  }

  onInputBlur(event) {
    if (event.target.type === "checkbox") {
      return;
    }
    const validationErrors = this.validateForm();
    let newState = {};

    if (!validationErrors) {
      newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
    } else {
      newState = {
        ...this.state,
        validationErrors: {
          ...this.state.validationErrors,
          [event.target.name]: validationErrors[event.target.name]
        }
      };
    }
    this.setState(newState);
  }

  onRecaptchaSuccess(token) {
    if (token) {
      this.setState({ recaptchaToken: token });
      this.setState({ isRecaptchaVerified: true });
    }
  }

  onRecaptchaError(error) {
    this.setState({ isRecaptchaVerified: false });
  }

  render() {
    const errors = this.state.validationErrors;

    return (
      <div className="RegisterForm">
        <div className="authentication-form-wrapper">
          <div className="auth-logo" />

          <Card className="auth-card" bordered={false}>
            <div className="card-header">
              <Meta title="Register" />
            </div>

            <Form className="register-form" onSubmit={this.onFormSubmit}>
              <Form.Item
                validateStatus={errors.email && errors.email[0] ? "error" : "validating"}
                help={errors.email && errors.email[0]}>
                <Input
                  type="text"
                  name="email"
                  prefix={<Icon type="mail" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  value={this.state.email}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="Email address"
                  autoComplete="new-text"
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.firstName && errors.firstName[0] ? "error" : "validating"}
                help={errors.firstName && errors.firstName[0]}>
                <Input
                  name="firstName"
                  prefix={<Icon type="user" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="text"
                  value={this.state.firstName}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="First name"
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.lastName && errors.lastName[0] ? "error" : "validating"}
                help={errors.lastName && errors.lastName[0]}>
                <Input
                  name="lastName"
                  prefix={<Icon type="user" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="text"
                  value={this.state.lastName}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="Last name"
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.password && errors.password[0] ? "error" : "validating"}
                help={errors.password && errors.password[0]}>
                <Input
                  name="password"
                  prefix={<Icon type="lock" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.repeatPassword && errors.repeatPassword[0] ? "error" : "validating"}
                help={errors.repeatPassword && errors.repeatPassword[0]}>
                <Input
                  name="repeatPassword"
                  prefix={<Icon type="lock" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="password"
                  value={this.state.repeatPassword}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="Repeat password"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Form.Item
                className="checkbox-wrapper"
                validateStatus={errors.agreementChecked && errors.agreementChecked[0] ? "error" : "validating"}
                help={errors.agreementChecked && errors.agreementChecked[0]}>
                <Checkbox
                  name="agreementChecked"
                  ref={this.checkboxRef}
                  defaultChecked={this.state.agreementChecked}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}>
                  I agree to the{" "}
                  <a href={SSC_TOS_URL} className="fake-link" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                </Checkbox>
              </Form.Item>

              <ReCAPTCHA
                sitekey={RECAPTCHA_API_KEY}
                onChange={(token) => this.onRecaptchaSuccess(token)}
                ref={(el) => {
                  if (el) {
                    this.captcha = el;
                  }
                }}
                //onErrored={this.onRecaptchaError}
              />

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="register-form-button"
                  loading={this.props.isLoading}
                  disabled={!this.state.isRecaptchaVerified}
                  style={{ marginTop: "20px" }}>
                  {this.props.isLoading ? "Loading" : "Register"}
                </Button>
              </Form.Item>
            </Form>
          </Card>

          <div className="under-card-info">
            Already have an account?{" "}
            <span className="fake-link" onClick={() => this.props.push("/auth/login")}>
              <strong>Sign in</strong>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterForm;
