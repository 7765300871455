import React from "react";
import { Layout } from "antd";
import BillingInfo from "../containers/Sales/BillingInfo";
const { Content } = Layout;

const BillingInfoPage = () => {
  return (
    <Content className="general-layout-content">
      <BillingInfo />
    </Content>
  );
};

export default BillingInfoPage;
