import React from "react";
import { Icon, Tooltip } from "antd";

function SummaryTooltip({ resourceType, first, last }) {
  let getMessages = (first, last) => ({
    "SEO score": {
      noData: "There is no data for SEO Score in this interval",
      increased: `Your website's SEO Score has increased from  ${first} to ${last} out of 100.`,
      decreased: `Your website's SEO Score has decreased from ${first} to ${last} out of 100.`,
      equal: `Your website's SEO Score is ${first} out of 100.`
    },
    backlinks: {
      noData: "There is no data for Backlinks Overview metrics in this interval.",
      increased: `The total number of incoming links to your domain has increased from ${first} to ${last}.`,
      decreased: `The total number of incoming links to your domain has decreased from ${first} to ${last}.`,
      equal: `The total number of incoming links to your domain is ${first}.`
    },
    loadingTime: {
      noData: "There is no data for Site Speed metrics in this interval.",
      increased: `Your website's loading time has increased from ${first}s to ${last}s.`,
      decreased: `Your website's loading time has decreased from ${first}s to ${last}s.`,
      equal: `Your website was loaded in ${first}s`
    }
  });

  let condition = (first, last) => {
    if (first === undefined && last === undefined) {
      return "noData";
    }
    if (last > first) {
      return "increased";
    }
    if (last < first) {
      return "decreased";
    }
    if (last === first) {
      return "equal";
    }
  };

  return (
    <Tooltip title={getMessages(first, last)[resourceType][condition(first, last)]}>
      <Icon type="question-circle" />
    </Tooltip>
  );
}

export default SummaryTooltip;
