import React, { Component } from "react";
import { Card, Icon } from "antd";
import DashboardBox from "../DashboardBox";
import DashboardCardTitle from "../DashboardCardTitle";
import WorldMap from "../../SiteOutage/WorldMap";

export class SiteOutageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const firstFetchIsInProgress =
      !prevProps.isFetching && this.props.isFetching && prevState.loading === null && this.state.loading === null;
    const fetchIsFinished = prevProps.isFetching && !this.props.isFetching;

    if (firstFetchIsInProgress && !this.props.hasData) {
      this.setState({ loading: true });
    }
    if ((firstFetchIsInProgress && this.props.hasData) || fetchIsFinished) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading === null) {
      return null;
    }
    return (
      <div className="dashboard-site-outage-box">
        {this.state.loading === true && <DashboardBox />}
        {this.state.loading === false && (
          <Card
            data-cy="site-outage-box"
            title={<DashboardCardTitle title="Site outage" description={"Over the last 7 days"} />}
            extra={
              <div className="card-link flexbox align-center" onClick={() => this.props.push("/site-outage")}>
                <span>More outage data</span>
                <Icon type="right" />
              </div>
            }>
            <div className="outage-map-container custom-scrollbar">
              <WorldMap
                width={280}
                height={128}
                scale={100}
                events={this.props.currentContext["events"]}
                url={this.props.url}
              />
            </div>
          </Card>
        )}
      </div>
    );
  }
}

export default SiteOutageBox;
