import { useCallback, useEffect, useMemo, useState } from 'react';
import { HoursInterval, Outage } from '../types';
import moment from 'moment';
import { getIntervalsForDay, getLastIntervalIndex, getOutagesGroupedByDays } from '../utils';
import { useFetchOutageListQuery, useLazyFetchOutageContextQuery } from '../service';
import { TrackedWebsite } from '../../trackedWebsites/types';

export default function useOutageIntervalState(outageDay: string, selectedWebsite: TrackedWebsite, outagesStartDate: string, outagesEndDate: string) {
    const {
        data: outageList,
        isLoading: isLoadingOutageList,
        isSuccess: isSuccesOutageList,
    } = useFetchOutageListQuery({
        url: selectedWebsite.url,
        startDate: outagesStartDate,
        endDate: outagesEndDate,
    });

    const [isFirstTimeSettingInterval, setIsFirstTimeSettingInterval] = useState<boolean>(true);

    const [fetchOutageContext, { isFetching: isLoadingOutageContext }] = useLazyFetchOutageContextQuery();

    const allOutagesGroupedByDay = useMemo(() => {
        const accumulatedOutages = outageList ? [...outageList.outages.archived] : [];
        if (outageList && Object.keys(outageList.outages.active).length) {
            const activeOutage = outageList.outages.active as Outage;
            const activeEvent = {
                start_at: moment.unix(activeOutage.created_at).utc().format('YYYY-MM-DDTHH:mm:ss'),
                end_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss'),
                guid: activeOutage.guid,
            };

            accumulatedOutages.push(activeEvent);
        }
        return getOutagesGroupedByDays(accumulatedOutages);
    }, [outageList]);

    const outageIntervals = useMemo(() => getIntervalsForDay(outageDay, allOutagesGroupedByDay[outageDay]), [allOutagesGroupedByDay, outageDay]);

    const [selectedOutageInterval, setSelectedOutageInterval] = useState<HoursInterval>(outageIntervals[getLastIntervalIndex(outageIntervals)]);

    const [outageContext, setOutageContext] = useState<Outage | null>(null);

    const handleOutageIntervalChange = useCallback(
        async (newOutageInterval: HoursInterval) => {
            setSelectedOutageInterval(newOutageInterval);

            if (newOutageInterval.guid) {
                if (outageList && Object.keys(outageList.outages.active).length) {
                    const activeOutage = outageList.outages.active as Outage;

                    if (activeOutage.guid.localeCompare(newOutageInterval.guid) === 0) {
                        setOutageContext(activeOutage);
                        return;
                    }
                }
                try {
                    const fetchedOutageContext = await fetchOutageContext({ guid: newOutageInterval.guid }, true).unwrap();
                    setOutageContext(fetchedOutageContext);
                } catch {}
            } else {
                setOutageContext(null);
            }
        },
        [outageList, fetchOutageContext]
    );

    useEffect(() => {
        if (isSuccesOutageList && isFirstTimeSettingInterval) {
            setIsFirstTimeSettingInterval(false);
            handleOutageIntervalChange(outageIntervals[getLastIntervalIndex(outageIntervals)]);
        }
    }, [handleOutageIntervalChange, isFirstTimeSettingInterval, isSuccesOutageList, outageIntervals]);

    return {
        outagesGroupedByDay: allOutagesGroupedByDay,
        selectedOutageInterval,
        outageIntervals,
        selectedOutageContext: outageContext,
        isLoadingOutageContext,
        isLoadingOutageList,
        handleOutageIntervalChange,
    };
}
