import React from 'react';

type Props = {
    url: string | undefined | null;
};

export default function UrlTableCell({ url }: Props) {
    return (
        <>
            {url && (
                <a
                    target="_blank"
                    href={url}
                    rel="noopener noreferrer"
                >
                    {url}
                </a>
            )}

            {!url && <>-</>}
        </>
    );
}
