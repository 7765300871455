import { useAppDispatch } from '../../useAppDispatch';
import { authRequest } from '../../store/authorizedRequests';
import { ANALYSIS_API_URL } from '../../settings/config';
import { useCallback } from 'react';

function createReport(url: string, sections: string[]) {
    return async (dispatch: any, getState: any) => {
        return authRequest(`${ANALYSIS_API_URL}/report`, dispatch, getState, {
            method: 'POST',
            body: JSON.stringify({ url: url, sections: sections }),
        })
            .then((response) => {
                return Promise.resolve(response);
            })
            .catch((_) => {
                return Promise.resolve({ error: 'Create report error' });
            });
    };
}

export function useCreateReportMutation() {
    const dispatch = useAppDispatch();

    const createReportTrigger = useCallback(
        (url: string, sections: string[]) => {
            return dispatch(createReport(url, sections));
        },
        [dispatch]
    );

    return {
        createReport: createReportTrigger,
    };
}
