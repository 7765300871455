import React, { Component } from "react";
import { Card, Row, Col, Tabs } from "antd";
import CreditCardPaymentMethod from "./CreditCardPaymentMethod";
import PayPalPaymentMethod from "./PayPalPaymentMethod";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
const { TabPane } = Tabs;

export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      company: "",
      vatNumber: "",
      validationErrors: {}
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handle3DSecureToken = this.handle3DSecureToken.bind(this);
    this.handle3DSecureError = this.handle3DSecureError(this);
  }

  onFormSubmit = params => {
    this.props.onUpdate(params.token);
  };

  handle3DSecureToken = token => {
    const tokenId = this.props.threeDSecure["token_id"];
    const three_d_secure_action_result_token_id = token.id;
    this.props.onUpdate(tokenId, three_d_secure_action_result_token_id);
  };

  handle3DSecureError = error => {
    const msg =
      error["message"] ||
      "We were unable to authenticate your payment method… choose a different payment method and try again.";
    this.props.on3DSecureError(msg);
  };

  render() {
    return this.props.billingInfo ? (
      <div className="payment-method-container">
        <Row gutter={24}>
          <Col sm={24} lg={12} xl={12}>
            <Card title="Attach a payment method">
              <p>
                Please enter your preferred payment method below. You can use a credit / debit card or pay through a
                PayPal account.
              </p>
              <Tabs>
                <TabPane tab="Credit card" key="credit-card">
                  <CreditCardPaymentMethod
                    actionTokenId={
                      this.props.threeDSecure && Object.keys(this.props.threeDSecure).includes("action_token_id")
                        ? this.props.threeDSecure["action_token_id"]
                        : null
                    }
                    buttonLabel={"Save"}
                    errorMessage={this.props.errorMessage}
                    isSubmitting={this.props.isUpdatingBillingInfo}
                    onSubmit={this.onFormSubmit}
                    on3DSecure={this.handle3DSecureToken}
                    on3DSecureError={this.handle3DSecureError}
                    showAvailableCards={true}
                  />
                </TabPane>
                <TabPane tab="PayPal" key="paypal">
                  <PayPalPaymentMethod onSubmit={this.onFormSubmit} buttonLabel={"Subscribe with PayPal"} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col sm={24} lg={12} xl={12}>
            <Card title="Current payment method">
              <div className="current-payment-method">
                {this.props.billingInfo["card_type"] && this.props.billingInfo["last_four"] && (
                  <div className="card">
                    <img
                      src={`/images/${this.props.billingInfo["card_type"].toLowerCase()}_light.png`}
                      alt={this.props.billingInfo["card_type"]}
                      title={this.props.billingInfo["card_type"]}
                    />
                    <span className="last4">&nbsp;****&nbsp;{this.props.billingInfo["last_four"]}</span>
                  </div>
                )}

                {this.props.billingInfo["paypal_billing_agreement_id"] && (
                  <div className="paypal">
                    <img src="/images/paypal.png" alt="PayPal" title="PayPal" />
                    <span className="last4">
                      &nbsp;Billing agreement: &nbsp;{this.props.billingInfo["paypal_billing_agreement_id"]}
                    </span>
                  </div>
                )}

                {!this.props.billingInfo["card_type"] &&
                  !this.props.billingInfo["last_four"] &&
                  !this.props.billingInfo["paypal_billing_agreement_id"] && (
                    <p>You don't have a payment method attached to your account!</p>
                  )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    ) : (
      <LoadingIndicator />
    );
  }
}
