import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";

const passedResult = (content, length, status) => (
  <div className={`result-meta ${status}`}>
    <div>
      <strong>Text: </strong>
      {content}
    </div>
    {length && (
      <div>
        <strong>Length: </strong>
        {length} characters
      </div>
    )}
  </div>
);

const displayResult = (title, result) => {
  if (["passed", "warning"].includes(result.status)) {
    return passedResult(result["content"], result["description_length"], result["status"]);
  }

  return <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />;
};

const displayCompetitorResult = result => {
  if (["passed", "warning"].includes(result.status)) {
    return passedResult(result["content"], result["description_length"], result["status"]);
  }

  return null;
};

const DescriptionTagSection = ({ url, sectionKey, title, tooltip, relevance, passRate, result, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayCompetitorResult} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default DescriptionTagSection;
