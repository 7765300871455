import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";

const displayResult = (title, result) => {
  if (result.status === "failed") {
    const firstError = result.js_errors[0];
    const errors = firstError.text ? result.js_errors.map(item => item.text) : result.js_errors;

    return (
      <React.Fragment>
        <div className="description-meta">
          <InlineModal openLabel="See results list" modalTitle="Full list of javascript errors" closeLabel="Close">
            {errors.map((error, idx) => (
              <div className="js-error" key={idx}>
                <code>{error}</code>
              </div>
            ))}
          </InlineModal>
        </div>
        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </React.Fragment>
    );
  }
  return null;
};

const JsErrorsSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default JsErrorsSection;
