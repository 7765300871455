import React from "react";
import { RecurlyProvider, Elements, ThreeDSecureAction } from "@recurly/react-recurly";
import { RECURLY_PUBLIC_API_KEY } from "../../settings/config";
import { Alert } from "antd";
import CreditCardForm from "./CreditCardForm";

function CreditCardPaymentMethod(props) {
  return props.actionTokenId ? (
    <RecurlyProvider publicKey={RECURLY_PUBLIC_API_KEY}>
      <div>
        <Alert
          message="Your bank requires authentication using 3D Secure"
          description="Authenticating your payment method..."
          type="info"
          showIcon
        />
        <ThreeDSecureAction
          actionTokenId={props.actionTokenId}
          onToken={props.on3DSecure}
          onError={props.on3DSecureError}
          className="recurly-three-d-secure-action"
        />
      </div>
    </RecurlyProvider>
  ) : (
    <RecurlyProvider publicKey={RECURLY_PUBLIC_API_KEY} required={["cvv", "postal_code"]}>
      <Elements>
        <CreditCardForm
          buttonLabel={props.buttonLabel}
          onSubmit={props.onSubmit}
          errorMessage={props.errorMessage}
          isSubmitting={props.isSubmitting}
          showAvailableCards={props.showAvailableCards}
        />
      </Elements>
    </RecurlyProvider>
  );
}

export default CreditCardPaymentMethod;
