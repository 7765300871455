import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import "./MobileSnapshots.less";

const displayResult = result =>
  result["snapshot_url"] && (
    <div className="mobile-snapshots" key="mobile-snapshots">
      <div className="mobile-view">
        <div className="image-container">
          <img src={result["snapshot_url"]} alt="Mobile view" />
        </div>
      </div>
    </div>
  );

const MobileSnapshotSection = ({ sectionKey, title, result, tooltip, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className="report-element default">
      <SectionTitle title={title} />

      <div className="report-element-description">
        <div className="description-content">
          {displayResult(result)}
          {hasCompetitors && competitorResults.map(competitor => displayResult(competitor.result))}
        </div>
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default MobileSnapshotSection;
