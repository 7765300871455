import React from "react";
import moment from "moment";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
//import { formatTimestamp } from "../../utils";

const formatDate = (dateStr) => {
    return moment(dateStr, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY");
};

const displayResult = (result) => {
    if (result && result.backlinks_list && result.backlinks_list.length > 0) {
        return (
            <div className="description-meta">
                {result.backlinks_list.map((backlinkUrl, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <div className="flexbox align-center">
                            <span className="item-label">{`${formatDate(backlinkUrl.first_seen)}`}</span>&nbsp;
                            <a href={backlinkUrl.source_url} target="_blank" rel="noopener noreferrer">
                                {backlinkUrl.source_url}
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return null;
};

// const BacklinksSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
//   const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

//   return (
//     <div id={sectionKey} className="report-element default backlinks">
//       <SectionTitle title={title} />

//       <div className="report-element-description">
//         <SectionMessage url={url} status={result.status} relevance={relevance} message={result.message} hasCompetitors={hasCompetitors} />
//         {displayResult(result)}
//         <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayResult} />
//       </div>

//       <SectionTooltip tooltip={tooltip} />
//     </div>
//   );
// };

const BacklinksSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
    const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

    return (
        <div id={sectionKey} className={`report-element ${result.status} backlinks`}>
            <SectionTitle title={title} status={result.status} passRate={passRate} />

            <div className="report-element-description">
                <SectionMessage
                    url={url}
                    status={result.status}
                    relevance={relevance}
                    message={result.message}
                    hasCompetitors={hasCompetitors}
                />
                {displayResult(result)}
                <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayResult} />
            </div>

            <SectionTooltip tooltip={tooltip} />
        </div>
    );
};

export default BacklinksSection;
