import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";

const displayResult = (title, result) => {
  if (result.canonical_tags && result.canonical_tags.length > 0) {
    return (
      <>
        <div className={`result-meta ${result["status"]}`}>
          {result.canonical_tags.map((tag, idx) => (
            <div key={idx}>
              <em>{tag}</em>
            </div>
          ))}
        </div>
        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </>
    );
  }
  return null;
};

const CanonicalTagsSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default CanonicalTagsSection;
