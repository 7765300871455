import moment from 'moment';
import React from 'react';
import { Tooltip } from 'antd';
import { OutageGroups } from '../types';
import { enumerateDaysBetweenDates } from '../utils';

type Props = {
    outagesGroupedByDay: OutageGroups;
    selectedDay: string;
    startDate: string;
    endDate: string;
    onChangeSelectedDay: (newSelectedDay: string) => void;
};

export default function DaysTimeline({ outagesGroupedByDay, selectedDay, startDate, endDate, onChangeSelectedDay }: Props) {
    const handleSelectedDayChange = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const day = event.currentTarget.getAttribute('data-value');
        onChangeSelectedDay(day!);
    };

    const timelineDays = enumerateDaysBetweenDates(startDate, endDate);

    return (
        <div className="outage-timeline custom-scrollbar">
            <div className="outage-days-container">
                <div
                    className="date-range"
                    style={{ marginBottom: '5px' }}
                >
                    <span>{moment(startDate).format('MMM DD, YYYY')}</span>
                    <span style={{ float: 'right' }}>{moment(endDate).format('MMM DD, YYYY')}</span>
                </div>
                <ul className="days">
                    {timelineDays.map((day) => (
                        <Tooltip
                            key={`day-${day}`}
                            title={moment(day).format('MMM DD, YYYY')}
                        >
                            <li
                                className={`day ${Object.keys(outagesGroupedByDay).includes(day) ? 'danger' : 'success'} ${
                                    selectedDay === day ? 'active' : ''
                                }`}
                                data-value={day}
                                onClick={handleSelectedDayChange}
                            ></li>
                        </Tooltip>
                    ))}
                </ul>
            </div>
        </div>
    );
}
