import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import { Tag } from "antd";
import "../../TagListing.less";

const displayResult = (result) => (
  <div className="description-meta">
      <div className="tag-listing">
        {result.keywords_frequency &&
          Object.entries(result.keywords_frequency)
            .sort((first, second) => second[1] - first[1])
            .map(pair => (
              <Tag className="default" key={pair[0]}>
                <span className="tag-count">{pair[1]}</span>
                <span className="tag-name">{pair[0]}</span>
              </Tag>
            ))
        }
      </div>
    </div>
)

const CommonKeywordsSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {  
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;   
  
  return (
    <div id={sectionKey} className="report-element default">
      <SectionTitle title={title} />

      <div className="report-element-description">
        <SectionMessage url={url} status={result.status} relevance={relevance} message={result.message} hasCompetitors={hasCompetitors} />        
        {displayResult(result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayResult} hideMessage={true} />                        
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default CommonKeywordsSection;
