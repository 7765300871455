import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';

type Props = {
    url: string;
    createdAt: number;
    location: string;
};

export default function OutageIssueDetails({ url, createdAt, location }: Props) {
    return (
        <div className="issue-details">
            <div className="row">
                <span className="title">URL</span>
                <span className="description">{url}</span>
            </div>
            <div className="row">
                <span className="title">Issue found on</span>
                <span className="description">
                    <DateTimeDisplay
                        utcTime={createdAt}
                        format="dateTime"
                    />
                </span>
            </div>
            <div className="row">
                <span className="title">Server location</span>
                <span className="description">{location}</span>
            </div>
        </div>
    );
}
