import { Icon } from 'antd';
import React from 'react';

export default function FinishedScreen() {
    return (
        <div className="onboarding-start-screen">
            <img
                src="/images/illustration-progress.svg"
                alt="finish onboarding"
            />
            <h2>All done! We're setting up your account now...</h2>
            <span className="description">This usually takes 30 seconds</span>
            <span>
                <Icon type="loading" />
            </span>
        </div>
    );
}
