import React from "react";
import { Tooltip, Icon } from "antd";

function SiteOutageSummary({ currentContext }) {
  const outageDetected = Object.keys(currentContext).length > 0 ? true : false;
  return (
    <div className="dashboard-summary-section">
      <div className="dashboard-summary-section-title">
        Site Outage{" "}
        <Tooltip
          title={
            outageDetected
              ? "We’ve detected some downtimes for this website"
              : "There are no downtimes reported for this website"
          }>
          <Icon type="question-circle" />
        </Tooltip>
      </div>
      <div className="dashboard-summary-section-result">
        {outageDetected ? (
          <span className="outage-message"> Outage detected</span>
        ) : (
          <span className="no-outage-message">No issues found</span>
        )}
      </div>
    </div>
  );
}

export default SiteOutageSummary;
