import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Tabs, Alert, Row, Col, Icon } from "antd";
import UserName from "../../components/UserAccount/UserName";
import Security from "../../components/UserAccount/Security";
import DeleteAccount from "../../components/UserAccount/DeleteAccount";
import { deleteUserAccount } from "../../store/iam/operations";
import { sendCancellationReason } from "../../store/sales/operations";
import { isDeletingUser, getLoggedInUserOrganization, getLoggedInUserSubscription } from "../../store/iam/selectors";

import {
  getUserEmail,
  getUserFirstName,
  getUserLastName,
  isChangingUserName,
  isChangingPassword,
  userIsLoading
} from "../../store/iam/selectors";
import { changeUserName, changePassword } from "../../store/iam/operations";

const { TabPane } = Tabs;

class UserAccount extends Component {
  constructor(props) {
    super(props);

    this.submitChangeUserNameForm = this.submitChangeUserNameForm.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
  }

  submitChangeUserNameForm(firstName, lastName) {
    this.props.changeUserName(firstName, lastName);
  }

  submitChangePassword(newPassword) {
    this.props.changePassword(newPassword);
  }

  render() {
    return (
      <div>
        <Card bordered={false}>
          <Row gutter={16}>
            <Col xs={24} xl={12}>
              {this.props.subscription &&
                this.props.subscription.status === "expired" &&
                this.props.organization &&
                this.props.organization.role === "member" && (
                  <Alert
                    type="error"
                    message="Your subscription has expired!"
                    description="Please contact your team owner to renew your team's subscription!"
                    style={{ marginTop: "8px", marginBottom: "24px" }}
                    showIcon
                    icon={<Icon type="warning" />}
                  />
                )}
              <Tabs className="user-account-tabs" onChange={this.onTabChange} type="card">
                <TabPane tab="Personal information" key="personal-information">
                  <UserName
                    email={this.props.email}
                    firstName={this.props.firstName}
                    lastName={this.props.lastName}
                    isLoading={this.props.isChangingUserName}
                    isLoadingUser={this.props.isLoadingUser}
                    onSubmit={this.submitChangeUserNameForm}
                  />
                </TabPane>
                <TabPane tab="Security" key="security">
                  <Security isLoading={this.props.isChangingPassword} onSubmit={this.submitChangePassword} />
                </TabPane>
                <TabPane tab="Delete account" key="delete-account">
                  <DeleteAccount
                    onSendCancellationReason={this.props.sendCancellationReason}
                    onDeleteUserAccount={this.props.deleteUserAccount}
                    isDeletingUserAccount={this.props.isDeletingUserAccount}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: getUserEmail(state),
  firstName: getUserFirstName(state),
  lastName: getUserLastName(state),
  isChangingUserName: isChangingUserName(state),
  isChangingPassword: isChangingPassword(state),
  isLoadingUser: userIsLoading(state),
  isDeletingUserAccount: isDeletingUser(state),
  subscription: getLoggedInUserSubscription(state),
  organization: getLoggedInUserOrganization(state)
});

export default connect(mapStateToProps, {
  changeUserName,
  changePassword,
  sendCancellationReason,
  deleteUserAccount
})(UserAccount);
