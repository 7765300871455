import React from 'react';
import { SiteSpeedChartData } from './types';
import { Chart, Geom, Axis, Legend, Tooltip } from 'bizcharts';
import moment from 'moment';

const colors = ['rgba(75, 178, 197, 0.5)', 'rgba(230, 126, 34, 0.5)', 'rgba(10, 144, 27, 0.5)'];

type Props = {
    chartData: SiteSpeedChartData[];
    selectedInterval: string;
};

export default function SiteSpeedChart({ chartData, selectedInterval }: Props) {
    const formatDate = (dateStr: string) => {
        return moment(dateStr, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
    };

    const getScale = (chartData: SiteSpeedChartData[]) => {
        let recordsNo = chartData.filter((item) => item.type === 'loading time').length;
        let scale = {
            value: {
                type: 'linear',
                min: 0,
                formatter: (val: number) =>
                    val.toLocaleString('en-Us', {
                        maximumFractionDigits: 2,
                    }),
            },
            c_at: {
                type: 'timeCat',
                mask: selectedInterval === 'last_24_hours' ? 'H A' : 'MMM DD',
                tickCount: recordsNo,
            },
        };
        if (recordsNo > 1) {
            //@ts-ignore
            scale['c_at']['range'] = [0, 1];
        }
        return scale;
    };

    const legendItemFormatter = (val: string) => {
        if (val === 'loading time') {
            return 'Loading time (ms)';
        }
        if (val === 'page size') {
            return 'Page size (Kb)';
        }
        if (val === 'loading speed') {
            return 'Loading speed (Kb/s)';
        }
        return val;
    };

    const getLoadingTimeTooltipItem = (type: any, item: any, interval: string) => {
        let value = item['point']['_origin'][type]['value'];
        let timestamp = item['point']['_origin'][type]['timestamp'];

        let valueWithUnitMeasurement = `${value.toLocaleString('en-Us', {
            maximumFractionDigits: 2,
        })}ms`;

        return {
            name: `${type}.`,
            value: interval === 'last_24_hours' ? valueWithUnitMeasurement : `${valueWithUnitMeasurement} @ ${formatDate(timestamp)}`,
        };
    };

    const handleTooltipChange = (event: any) => {
        let tooltipItems = [...event.items];

        tooltipItems.forEach((item) => {
            if (item.name === 'loading time') {
                item.name = 'loading time';
                item.value = '';

                let avgLoadingTime = {
                    name: 'avg.',
                    value: `${item['point']['_origin']['value'].toLocaleString('en-Us', {
                        maximumFractionDigits: 2,
                    })}ms`,
                };
                event.items.splice(1, 0, avgLoadingTime);

                let maxLoadingTime = getLoadingTimeTooltipItem('max', item, selectedInterval);
                event.items.splice(2, 0, maxLoadingTime);

                let minLoadingTime = getLoadingTimeTooltipItem('min', item, selectedInterval);
                event.items.splice(3, 0, minLoadingTime);
            }
            if (item.name === 'page size') {
                item.name = 'avg. page size';
                item.value += 'Kb';
            }
            if (item.name === 'loading speed') {
                item.name = 'avg. loading speed';
                item.value += 'Kb/s';
            }
        });
    };

    return (
        <div className="loading-time-chart custom-scrollbar">
            <Chart
                padding={{ top: 40, right: 40, bottom: 50, left: 50 }}
                height={400}
                data={chartData}
                scale={getScale(chartData)}
                animate={false}
                forceFit
                onTooltipChange={handleTooltipChange}
            >
                <Axis name="c_at" />
                <Axis name="value" />
                <Legend
                    position="top"
                    itemFormatter={(val) => legendItemFormatter(val)}
                />
                <Tooltip />
                <Geom
                    type="area"
                    position="c_at*value"
                    color={['type', colors]}
                />
                <Geom
                    type="line"
                    position="c_at*value"
                    size={2}
                    color={['type', colors]}
                />
                <Geom
                    type="point"
                    position="c_at*value"
                    size={[
                        'type',
                        () => {
                            let recordsNo = chartData.filter((item) => item.type === 'loading time').length;
                            if (recordsNo === 1) {
                                return 4;
                            } else return 2;
                        },
                    ]}
                    shape={'circle'}
                    color={['type', colors]}
                    style={{ stroke: '#fff', lineWidth: 1 }}
                />
            </Chart>
        </div>
    );
}
