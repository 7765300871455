import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import ImportantFixesBox from "../../components/Dashboard/ImportantFixes/ImportantFixesBox";
import { getSelectedWebsiteUrl, isWebsiteLastAddedAndHasNoSeoReportData } from "../../store/tracked_websites/selectors";
import { fetchLastSavedReport } from "../../store/analysis/operations";
import {
  isFetchingLastSavedReportForUrl,
  getLastSavedReportGuid,
  getLastSavedReportCreatedAt,
  isLastSavedReportFetched,
  getImportantFixes,
  getLastSavedReportScore,
  getLastSavedReportStatusCounter
} from "../../store/analysis/selectors";
import { DASHBOARD_LONG_POLLING_INTERVAL_IN_MS } from "../../settings/config";

export class ImportantFixesBoxContainer extends Component {
  constructor(props) {
    super(props);

    this.refreshInterval = null;
  }

  componentDidMount() {
    this.props.fetchLastSavedReport(this.props.url, this.props.startDate, this.props.endDate);
    if (this.props.shouldLongPolling) {
      this.refreshInterval = setInterval(_ => {
        if (this.props.isLastSavedReportFetched || !this.props.shouldLongPolling) {
          clearInterval(this.refreshInterval);
        } else {
          this.props.fetchLastSavedReport(this.props.url, this.props.startDate, this.props.endDate);
        }
      }, DASHBOARD_LONG_POLLING_INTERVAL_IN_MS);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  render() {
    return (
      <ImportantFixesBox
        isFetching={this.props.isFetching}
        url={this.props.url}
        lastUpdatedAt={this.props.lastUpdatedAt}
        importantFixes={this.props.importantFixes}
        seoScore={this.props.seoScore}
        lastSavedReportGuid={this.props.lastSavedReportGuid}
        sectionsStatusCounter={this.props.sectionsStatusCounter}
        push={this.props.push}
        isWebsiteLastAddedAndHasNoSeoReportData={this.props.isWebsiteLastAddedAndHasNoSeoReportData}
        hasData={this.props.lastSavedReportGuid && this.props.isLastSavedReportFetched}
      />
    );
  }
}

const mapStateToProps = state => {
  const url = getSelectedWebsiteUrl(state);

  return {
    url: url,
    isFetching: isFetchingLastSavedReportForUrl(state, url),
    lastSavedReportGuid: getLastSavedReportGuid(state, url),
    lastUpdatedAt: getLastSavedReportCreatedAt(state, url),
    isLastSavedReportFetched: isLastSavedReportFetched(state, url),
    importantFixes: getImportantFixes(state, getLastSavedReportGuid(state, url)),
    seoScore: getLastSavedReportScore(state, url),
    sectionsStatusCounter: getLastSavedReportStatusCounter(state, url),
    isWebsiteLastAddedAndHasNoSeoReportData: isWebsiteLastAddedAndHasNoSeoReportData(state)
  };
};

export default connect(mapStateToProps, { fetchLastSavedReport, push })(ImportantFixesBoxContainer);
