import React from "react";
import "./ModalTitle.less";

const ModalTitle = ({ title, meta }) => {
  return (
    <div className="modal-title-wrapper">
      <div className="modal-title">{title}</div>
      <div className="modal-meta">{meta}</div>
    </div>
  );
};

export default ModalTitle;
