import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { TopAnchor } from '../../types';

const { Column } = Table;

type Props = {
    topAnchors: TopAnchor[];
};

export default function TopAnchorsTable({ topAnchors }: Props) {
    const dataSource = topAnchors.map((topAnchor, index) => ({ ...topAnchor, key: index + 1 }));

    return (
        <Table
            dataSource={dataSource}
            pagination={false}
            size="small"
            data-cy="top-anchors-table"
        >
            <Column
                title="#"
                dataIndex="key"
                align="left"
                width={100}
            />
            <Column
                className="no-wrap"
                title="Anchor Text"
                dataIndex="anchor"
                render={(text, record: TopAnchor, index) => (record.anchor ? record.anchor : '<EmptyAnchor>')}
            />
            <Column
                className="no-wrap al-right"
                title="Domains"
                dataIndex="referring_domains"
                render={(text, record: TopAnchor, index) => record.referring_domains.toLocaleString()}
            />
            <Column
                className="no-wrap al-right"
                title="Backlinks"
                dataIndex="backlinks"
                render={(text, record: TopAnchor, index) => record.backlinks.toLocaleString()}
            />
            <Column
                className="no-wrap al-right"
                title="First Seen"
                dataIndex="first_seen"
                render={(text, record: TopAnchor, index) => moment(record.first_seen).format('MMM DD, YYYY')}
            />
        </Table>
    );
}
