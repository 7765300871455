import { createApi } from '@reduxjs/toolkit/query/react';
import { authRequest } from '../store/authorizedRequests';
import { SSC_SEM_API_URL } from '../settings/config';
import {
    AddTrackedKeywordsParams,
    AddTrackedKeywordsResponse,
    ChangeKeywordsLocationAndLanguageParams,
    ChangeKeywordsLocationAndLanguageResponse,
    DeleteTrackedKeywordParams,
    FetchKeywordOverviewsParams,
    FetchTopDomainsParams,
    FetchTrackedKeywordsCountResponse,
    FetchTrackedKeywordsParams,
    FetchTrackedKeywordsResponse,
    TopDomain,
    TrackedKeywordOverviewData,
} from './types';

const TRACKED_KEYWORDS_REFETCH_TIME = 7200;
const trackedKeywordsBaseUrl = `${SSC_SEM_API_URL}/dfs`;

export const trackedKeywordsApi = createApi({
    reducerPath: 'api.trackedKeywords',
    keepUnusedDataFor: TRACKED_KEYWORDS_REFETCH_TIME,
    tagTypes: ['keywordsCount', 'trackedKeywords'],

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;

        try {
            const data = await authRequest(trackedKeywordsBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchTrackedKeywordsCount: builder.query<FetchTrackedKeywordsCountResponse, void>({
            query: () => {
                return {
                    method: 'GET',
                    url: '/tracked-keywords/count',
                };
            },

            providesTags: ['keywordsCount'],
        }),

        fetchTrackedKeywords: builder.query<FetchTrackedKeywordsResponse, FetchTrackedKeywordsParams>({
            query: (args) => {
                const { domain } = args;

                return {
                    method: 'GET',
                    url: `/tracked-keywords?domain=${domain}`,
                };
            },

            providesTags: (result, error, arg) => [{ type: 'trackedKeywords', id: arg.domain }],
        }),

        fetchKeywordOverviews: builder.query<TrackedKeywordOverviewData[], FetchKeywordOverviewsParams>({
            query: (args) => {
                const { phrase, domain, location, languageCode, startDate, endDate } = args;

                return {
                    method: 'GET',
                    url: `/keyword-position-tracker?phrase=${encodeURIComponent(phrase)}&domain=${domain}&location=${encodeURIComponent(
                        location
                    )}&language=${languageCode}&start_date=${encodeURIComponent(startDate)}&end_date=${encodeURIComponent(endDate)}`,
                };
            },
        }),

        fetchTopDomains: builder.query<TopDomain[], FetchTopDomainsParams>({
            query: (args) => {
                const { phrase, location, languageCode } = args;

                return {
                    method: 'GET',
                    url: `/top-domains?phrase=${encodeURIComponent(phrase)}&location=${encodeURIComponent(location)}&language=${languageCode}`,
                };
            },
        }),

        addTrackedKeywords: builder.mutation<AddTrackedKeywordsResponse, AddTrackedKeywordsParams>({
            query: (args) => {
                const { domain, locationName, languageCode, keywords } = args;

                return {
                    method: 'POST',
                    url: '/tracked-keywords/batch',
                    body: {
                        domain: domain,
                        location_name: locationName,
                        language_code: languageCode,
                        keywords: keywords,
                    },
                };
            },

            invalidatesTags: (result, error, arg) => [{ type: 'trackedKeywords', id: arg.domain }, 'keywordsCount'],
        }),

        deleteTrackedKeyword: builder.mutation<void, DeleteTrackedKeywordParams>({
            query: (args) => {
                return {
                    method: 'DELETE',
                    url: '/tracked-keywords',
                    body: args,
                };
            },

            invalidatesTags: (result, error, arg) => [{ type: 'trackedKeywords', id: arg.domain }, 'keywordsCount'],
        }),

        changeKeywordsLocationAndLanguage: builder.mutation<ChangeKeywordsLocationAndLanguageResponse, ChangeKeywordsLocationAndLanguageParams>({
            query: (args) => {
                const { domain, locationName, languageCode } = args;

                return {
                    method: 'PATCH',
                    url: '/tracked-keywords/location',
                    body: {
                        domain: domain,
                        location_name: locationName,
                        language_code: languageCode,
                    },
                };
            },

            invalidatesTags: (result, error, arg) => [{ type: 'trackedKeywords', id: arg.domain }],
        }),
    }),
});

export const {
    useChangeKeywordsLocationAndLanguageMutation,
    useFetchTrackedKeywordsCountQuery,
    useAddTrackedKeywordsMutation,
    useDeleteTrackedKeywordMutation,
    useFetchKeywordOverviewsQuery,
    useFetchTopDomainsQuery,
    useFetchTrackedKeywordsQuery,
    useLazyFetchTrackedKeywordsCountQuery,
    useLazyFetchKeywordOverviewsQuery,
    useLazyFetchTopDomainsQuery,
} = trackedKeywordsApi;
