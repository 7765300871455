import React from "react";
import BasicSection from "../../shared/BasicSection/BasicSection";

const LoadingSpeedSection = ({ url, sectionKey, title, result, tooltip, competitorResults }) => {
  return (
    <BasicSection
      url={url}
      sectionKey={sectionKey}
      title={title}
      result={result}
      tooltip={tooltip}
      competitorResults={competitorResults}
    />
  );
};

LoadingSpeedSection.defaultProps = {
  tooltip: ""
};

export default LoadingSpeedSection;
