import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { userIsLoggingIn } from "../../store/iam/selectors";
import { authenticate } from "../../store/iam/operations";
import { LoginForm } from "../../components/auth";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldResetCaptcha: false
    };
  }

  onError = () => {
    this.setState({ shouldResetCaptcha: true });
  };

  submitForm = (email, password, rememberMe) => {
    this.setState({ shouldResetCaptcha: false });
    this.props.authenticate(email, password, rememberMe, this.onError);
  };

  render() {
    return (
      <div>
        <LoginForm
          onSubmit={this.submitForm}
          isLoading={this.props.isLoading}
          push={this.props.push}
          shouldResetCaptcha={this.state.shouldResetCaptcha}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: userIsLoggingIn(state)
});

export default connect(mapStateToProps, { authenticate, push })(Login);
