import fetch from "cross-fetch";
import { push } from "connected-react-router";
import { IAM_API_URL } from "../../settings/config";
import { sendUserRegisterEvent } from "../../utils/analytics";
import {
    authenticationInProgress,
    authenticationSuccessful,
    authenticationFailed,
    userIsLoading,
    getUser,
    userIsNotAvailable,
    registrationInProgress,
    registrationFailed,
    registrationSuccessful,
    registrationAndLoginIsFinished,
    deleteUserInProgress,
    deleteUserSuccessful,
    deleteUserFailed,
    passwordResetRequestInProgress,
    passwordResetRequestSuccess,
    passwordResetRequestFailed,
    changeUserNameInProgress,
    changeUserNameSuccessful,
    changeUserNameFailed,
    changePasswordInProgress,
    changePasswordSuccessful,
    changePasswordFailed,
    confirmPasswordInProgress,
    confirmPasswordSuccessful,
    confirmPasswordFailed,
    updateAccessTokenFailed,
    updateAccessTokenInProgress,
    updateAccessTokenSuccess,
    clearIamState,
    updateRequiredExperiencesInProgress,
    updateRequiredExperiencesSuccessful,
    updateRequiredExperiencesFailed,
    createOrganizationFailed,
    createOrganizationInProgress,
    createOrganizationSuccessful,
    getOrganizationInProgress,
    getOrganizationSuccessful,
    getOrganizationFailed,
    updateOrganizationInProgress,
    updateOrganizationSuccessful,
    updateOrganizationFailed,
    deleteTeamMemberFailed,
    deleteTeamMemberInProgress,
    deleteTeamMemberSuccessful
} from "./creators";

import { addNotificationWithTimeout } from "../notifications/operations";
import { authRequest } from "../authorizedRequests";
import { getRefreshToken, getLoggedInUserGuid, getLoggedInUserSubscription } from "./selectors";

import {logout as logoutUser} from "../../iam/logout";

export const authenticate = (email, password, rememberMe, onError) => {
    const userEmail = email.toLowerCase();

    return (dispatch, getState) => {
        dispatch(clearIamState());
        dispatch(authenticationInProgress());

        return fetch(`${IAM_API_URL}/auth`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user_name: userEmail, password: password, remember_me: rememberMe })
        })
            .then((response) => {
                if (response.status === 201) {
                    return response.json();
                } else {
                    const message =
                        response.status === 401
                            ? "Invalid email or password!"
                            : "Something went wrong during authentication";
                    throw new Error(message);
                }
            })
            .then((body) => {
                dispatch(authenticationSuccessful(body["access_token"], body["refresh_token"]));
                const subscription = getLoggedInUserSubscription(getState());
                if (subscription["plan_code"]) {
                    dispatch(push("/dashboard"));
                } else {
                    dispatch(push("/free-trial-setup"));
                }
            })
            .catch((error) => {
                dispatch(authenticationFailed());
                dispatch(addNotificationWithTimeout("error", error.message));
                onError();
            });
    };
};

export const getLoggedInUser = () => {
    return (dispatch, getState) => {
        dispatch(userIsLoading());

        const userGuid = getLoggedInUserGuid(getState());
        const apiUrl = `${IAM_API_URL}/user/${userGuid}`;

        authRequest(apiUrl, dispatch, getState)
            .then((response) => {
                dispatch(getUser(response));
            })
            .catch((_) => {
                dispatch(userIsNotAvailable());
                dispatch(addNotificationWithTimeout("error", "Cannot get user data!"));

                logoutUser();
            });
    };
};

// export const register = (email, firstName, lastName, password, token, onError) => {
//   const userEmail = email.toLowerCase();

//   return dispatch => {
//     dispatch(registrationInProgress());
//     fetch(`${IAM_API_URL}/user`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         u_name: userEmail,
//         f_name: firstName,
//         l_name: lastName,
//         pw: password,
//         email: userEmail,
//         token: token,
//         locale: "en_US",
//         timezone: "America/New_York"
//       })
//     })
//       .then(response => {
//         if (response.status === 201) {
//           dispatch(registrationSuccessful());
//           return response.json();
//         } else {
//           const message =
//             response.status === 409
//               ? "Cannot create 2 users with the same user name"
//               : "Something went wrong during registration";
//           throw Error(message);
//         }
//       })
//       .then(_ => {
//         const rememberMe = undefined;
//         dispatch(authenticate(userEmail, password, rememberMe, onError)).then(() =>
//           dispatch(registrationAndLoginIsFinished())
//         );
//       })
//       .catch(error => {
//         dispatch(registrationFailed());
//         dispatch(registrationAndLoginIsFinished());
//         dispatch(addNotificationWithTimeout("error", error.message));
//         onError();
//       });
//   };
// };

export const register = (email, firstName, lastName, password, token, onError) => {
    const userEmail = email.toLowerCase();

    return (dispatch) => {
        dispatch(registrationInProgress());
        fetch(`${IAM_API_URL}/user`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                u_name: userEmail,
                f_name: firstName,
                l_name: lastName,
                pw: password,
                email: userEmail,
                token: token,
                locale: "en_US",
                timezone: "America/New_York"
            })
        })
            .then((response) => {
                if (response.status === 201 || response.status === 200) {
                    dispatch(registrationSuccessful());

                    response.json().then((body) => {
                        //console.log(body["guid"]);
                        sendUserRegisterEvent(body["guid"]);
                    });

                    return response.status;
                } else {
                    const message =
                        response.status === 409
                            ? "Cannot register 2 users with the same email address!"
                            : "Something went wrong during registration";
                    throw Error(message);
                }
            })
            .then((status) => {
                if (status === 200) {
                    dispatch(registrationAndLoginIsFinished());
                    dispatch(push("/auth"));
                } else {
                    const rememberMe = undefined;
                    dispatch(authenticate(userEmail, password, rememberMe, onError)).then(() =>
                        dispatch(registrationAndLoginIsFinished())
                    );
                }
            })
            .catch((error) => {
                dispatch(registrationFailed());
                dispatch(registrationAndLoginIsFinished());
                dispatch(addNotificationWithTimeout("error", error.message));
                onError();
            });
    };
};

export const resetPasswordRequest = (email) => {
    const userEmail = email.toLowerCase();

    return (dispatch) => {
        dispatch(passwordResetRequestInProgress());
        fetch(`${IAM_API_URL}/user/reset-password`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                u_name: userEmail
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(passwordResetRequestSuccess());
                    dispatch(addNotificationWithTimeout("success", "A reset password email was sent to your address!"));
                } else {
                    throw new Error("We weren't able to identify you given the information provided");
                }
            })
            .catch((error) => {
                dispatch(passwordResetRequestFailed());
                dispatch(addNotificationWithTimeout("error", error.message));
            });
    };
};

export const resetPassword = (token, password) => {
    return (dispatch) => {
        dispatch(changePasswordInProgress());
        fetch(`${IAM_API_URL}/user/reset-password`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                token: token,
                pw: password
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(changePasswordSuccessful());
                    dispatch(addNotificationWithTimeout("success", "You successfully changed your password!"));
                    return response.json();
                } else {
                    throw new Error("This token was already used!");
                }
            })
            .then((body) => {
                dispatch(authenticationSuccessful(body.access_token, body.refresh_token));
                dispatch(push("/"));
            })
            .catch((error) => {
                dispatch(changePasswordFailed());
                dispatch(addNotificationWithTimeout("error", error.message));
            });
    };
};

export const changeUserName = (firstName, lastName) => {
    return (dispatch, getState) => {
        dispatch(changeUserNameInProgress());

        const userGuid = getLoggedInUserGuid(getState());
        const apiUrl = `${IAM_API_URL}/user/${userGuid}`;

        authRequest(apiUrl, dispatch, getState, {
            method: "PATCH",
            body: JSON.stringify({
                f_name: firstName,
                l_name: lastName
            })
        })
            .then((_) => {
                dispatch(changeUserNameSuccessful());
                dispatch(addNotificationWithTimeout("success", "Your account was successfully updated!"));
                dispatch(getLoggedInUser());
            })
            .catch((_) => {
                dispatch(changeUserNameFailed());
                dispatch(addNotificationWithTimeout("error", "Cannot update your account"));
            });
    };
};

export const changePassword = (newPassword) => {
    return (dispatch, getState) => {
        dispatch(changePasswordInProgress());

        const userGuid = getLoggedInUserGuid(getState());
        const apiUrl = `${IAM_API_URL}/user/${userGuid}`;

        authRequest(apiUrl, dispatch, getState, {
            method: "PATCH",
            body: JSON.stringify({
                pw: newPassword
            })
        })
            .then((_) => {
                dispatch(changePasswordSuccessful());
                dispatch(addNotificationWithTimeout("success", "Your account was successfully updated!"));
                dispatch(getLoggedInUser());
            })
            .catch((_) => {
                dispatch(changePasswordFailed());
                dispatch(addNotificationWithTimeout("error", "Cannot update your password!"));
            });
    };
};

export const confirmPassword = (email, currentPassword, onPasswordConfirmed) => {
    const userEmail = email.toLowerCase();

    return (dispatch) => {
        dispatch(confirmPasswordInProgress());

        fetch(`${IAM_API_URL}/auth`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ user_name: userEmail, password: currentPassword })
        })
            .then((response) => {
                if (response.status === 201) {
                    return response.json();
                } else {
                    throw new Error("Login failed: invalid email or password!");
                }
            })
            .then((_) => {
                dispatch(confirmPasswordSuccessful(true));
                if (onPasswordConfirmed) {
                    onPasswordConfirmed();
                }
            })
            .catch((_) => {
                dispatch(confirmPasswordFailed(false));
                dispatch(addNotificationWithTimeout("error", "Your current password is incorrect"));
            });
    };
};

export const refreshAccessToken = () => {
    return (dispatch, getState) => {
        const refreshToken = getRefreshToken(getState());

        if (!refreshToken) {
            logoutUser();
        }

        let requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${refreshToken}`
            }
        };

        //console.log("Refreshing access token...");
        dispatch(updateAccessTokenInProgress());

        return fetch(`${IAM_API_URL}/auth/refresh`, requestOptions)
            .then(
                (response) => {
                    if (response.status === 201 || response.status === 200) {
                        return response.json();
                    } else {
                        logoutUser();
                    }
                },
                (error) => {
                    logoutUser();
                }
            )
            .then((body) => {
                if (body) {
                    dispatch(updateAccessTokenSuccess(body["access_token"]));
                    //console.log("Access token refreshed successfully!");
                } else {
                    dispatch(updateAccessTokenFailed());
                    
                    logoutUser();
                }
            });
    };
};

export const updateRequiredExperiences = (value) => {
    return (dispatch, getState) => {
        dispatch(updateRequiredExperiencesInProgress());

        const userGuid = getLoggedInUserGuid(getState());
        const apiUrl = `${IAM_API_URL}/user/${userGuid}`;

        authRequest(apiUrl, dispatch, getState, {
            method: "PATCH",
            body: JSON.stringify({
                required_experiences: value
            })
        })
            .then((_) => {
                dispatch(updateRequiredExperiencesSuccessful(value));
            })
            .catch((_) => {
                dispatch(updateRequiredExperiencesFailed());
            });
    };
};

export const deleteUserAccount = () => {
    return (dispatch, getState) => {
        const apiUrl = `${IAM_API_URL}/user`;

        dispatch(deleteUserInProgress());

        authRequest(apiUrl, dispatch, getState, {
            method: "DELETE"
        })
            .then((_) => {
                dispatch(addNotificationWithTimeout("success", "Your account was successfully deleted!"));
                dispatch(deleteUserSuccessful());

                logoutUser();
            })
            .catch((_) => {
                dispatch(
                    addNotificationWithTimeout(
                        "error",
                        "Cannot delete your account. Please try again or contact support!"
                    )
                );
                dispatch(deleteUserFailed());
            });
    };
};

export const createOrganizationIfNotExists = (name = "Default") => {
    return (dispatch, getState) => {
        dispatch(createOrganizationInProgress());

        const apiUrl = `${IAM_API_URL}/organization`;

        return authRequest(apiUrl, dispatch, getState, {
            method: "POST",
            body: JSON.stringify({
                name: name
            })
        })
            .then((response) => {
                dispatch(createOrganizationSuccessful(response));
                //console.log("Default organization created successfully!");
                return Promise.resolve(response);
            })
            .catch((_) => {
                dispatch(createOrganizationFailed());
                console.log("Cannot create organization!");
                return Promise.resolve({ error: "Cannot create organization" });
            });
    };
};

export const fetchOrganization = (showLoader = true) => {
    return (dispatch, getState) => {
        if (showLoader) {
            dispatch(getOrganizationInProgress());
        }

        const apiUrl = `${IAM_API_URL}/organization`;

        authRequest(apiUrl, dispatch, getState)
            .then((response) => {
                dispatch(getOrganizationSuccessful(response));
            })
            .catch((_) => {
                dispatch(getOrganizationFailed());
            });
    };
};

export const sendTeamMemberInvitation = (email) => {
    return (dispatch, getState) => {
        const apiUrl = `${IAM_API_URL}/organization/invite`;

        return authRequest(apiUrl, dispatch, getState, {
            method: "POST",
            body: JSON.stringify({
                email: email
            })
        });
    };
};

export const cancelTeamMemberInvitation = (invitationToken) => {
    return (dispatch, getState) => {
        dispatch(deleteTeamMemberInProgress(invitationToken));
        const apiUrl = `${IAM_API_URL}/organization/invite`;

        return authRequest(apiUrl, dispatch, getState, {
            method: "DELETE",
            body: JSON.stringify({
                token: invitationToken
            })
        })
            .then((_) => {
                dispatch(fetchOrganization(false));
                dispatch(deleteTeamMemberSuccessful(invitationToken));
            })
            .catch((_) => {
                dispatch(deleteTeamMemberFailed(invitationToken));
                dispatch(addNotificationWithTimeout("error", "Error on removing team member!"));
            });
    };
};

export const removeTeamMember = (email) => {
    return (dispatch, getState) => {
        dispatch(deleteTeamMemberInProgress(email));
        const apiUrl = `${IAM_API_URL}/organization/member`;

        return authRequest(apiUrl, dispatch, getState, {
            method: "DELETE",
            body: JSON.stringify({
                email: email
            })
        })
            .then((_) => {
                dispatch(fetchOrganization(false));
                dispatch(deleteTeamMemberSuccessful(email));
            })
            .catch((_) => {
                dispatch(deleteTeamMemberFailed(email));
                dispatch(addNotificationWithTimeout("error", "Error on removing team member!"));
            });
    };
};

export const updateOrganization = (payload) => {
    return (dispatch, getState) => {
        dispatch(updateOrganizationInProgress());

        const apiUrl = `${IAM_API_URL}/organization`;

        authRequest(apiUrl, dispatch, getState, {
            method: "PATCH",
            body: JSON.stringify(payload)
        })
            .then((response) => {
                dispatch(updateOrganizationSuccessful(response));
            })
            .catch((_) => {
                dispatch(updateOrganizationFailed());
            });
    };
};

export const registerOrganizationMember = (invitationToken, firstName, lastName, password, recaptchaToken, onError) => {
    return (dispatch, getState) => {
        fetch(`${IAM_API_URL}/organization/member`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                f_name: firstName,
                l_name: lastName,
                pw: password,
                invitation_token: invitationToken,
                recaptcha_token: recaptchaToken,
                locale: "en_US",
                timezone: "America/New_York"
            })
        })
            .then((response) => {
                if (response.status === 201 || response.status === 409) {
                    return response.json();
                } else {
                    throw Error("Something went wrong during registration");
                }
            })
            .then((response) => {
                if (response["error"]) {
                    throw Error(response["error"]);
                }

                dispatch(authenticationSuccessful(response["access_token"], response["refresh_token"]));
                const subscription = getLoggedInUserSubscription(getState());

                if (subscription["plan_code"]) {
                    dispatch(push("/dashboard"));
                } else {
                    dispatch(push("/free-trial-setup"));
                }
            })
            .catch((error) => {
                dispatch(authenticationFailed());
                dispatch(addNotificationWithTimeout("error", error.message));
                onError();
            });
    };
};
