import React, { useContext } from 'react';
import { Layout } from 'antd';
import TrackedWebsites from '../../trackedWebsites/TrackedWebsites';
import BacklinksForm from '../BacklinksForm';
import Backlinks from '../Backlinks';
import { SelectedWebsiteContext } from '../../trackedWebsites/ContextWrapper';

export default function BacklinksPage() {
    const { selectedWebsite } = useContext(SelectedWebsiteContext);

    return (
        <Layout.Content className="general-layout-content">
            <BacklinksForm />
            <TrackedWebsites
                tabContent={selectedWebsite ? <Backlinks domain={selectedWebsite.domain} /> : <></>}
                showDomains
                destroyInactiveTabs={true}
            />
        </Layout.Content>
    );
}
