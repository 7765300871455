import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import "./AdsTxtSection.less";

const AdsTxtInvalidRecords = ({ title, records, type }) => {
  return (
    <div className="invalid-ads-records">
      {title && (
        <h3>
          {title}({records.length}):
        </h3>
      )}
      <ul>
        {records.map((record, idx) => {
          return (
            <li key={idx}>
              <span>{`line ${record["pos"]}: `}</span>

              <code className={type === "error" ? "error" : ""}>{record["content"]}</code>

              <div>{record["message"]}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const displayResult = (title, result) => {
  if (["warning", "failed"].includes(result.status)) {
    return (
      <React.Fragment>
        <div className="description-meta">
          {(result.errors || result.warnings) && (
            <InlineModal openLabel="See results list" modalTitle="Full list of invalid records" closeLabel="Close">
              {result.errors.length > 0 && <AdsTxtInvalidRecords type="error" title="Errors" records={result.errors} />}
              {result.warnings.length > 0 && (
                <AdsTxtInvalidRecords type="warning" title="Warnings" records={result.warnings} />
              )}
            </InlineModal>
          )}
        </div>
        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </React.Fragment>
    );
  }
  return null;
};

const AdsTxtSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default AdsTxtSection;
