import React, { useState } from 'react';
import { Outage } from './types';
import { groupEventsByType } from './utils';
import { MonitorRegions } from '../trackedWebsites/types';
import DateTimeDisplay from './DateTimeDisplay';

const initialState = {
    showMoreHttpIssues: false,
    showMorePingIssues: false,
};

type Props = {
    monitoringRegions: MonitorRegions;
    outageContext: Outage | null;
    outageStart: string;
    outageEnd: string;
};

export default function OutageIssues({ monitoringRegions, outageContext, outageStart, outageEnd }: Props) {
    const [state, setState] = useState(initialState);

    const issues = groupEventsByType(outageContext ? outageContext.events : []);

    const handleShowMoreChange = (issueType: 'ping' | 'http') => {
        if (issueType === 'ping') {
            setState({ ...state, showMorePingIssues: !state.showMorePingIssues });
        }
        if (issueType === 'http') {
            setState({ ...state, showMoreHttpIssues: !state.showMoreHttpIssues });
        }
    };

    return (
        <>
            <div className="outage-issues-section first">
                <div className="outage-issues-title">Ping issues</div>
                <div className="outage-issues-current-interval">{`${outageStart} - ${outageEnd} UTC`}</div>
                {issues.pingEvents.length > 0 ? (
                    <>
                        {issues.pingEvents.map((event, idx) => (
                            <div
                                className={`issue-container ${idx >= 3 && !state.showMorePingIssues ? 'hide' : ''}`}
                                key={`issue-container-${idx}`}
                            >
                                <span className="date-location">
                                    {monitoringRegions[event.region]} -{' '}
                                    <DateTimeDisplay
                                        utcTime={event.created_at}
                                        format="timeOnly"
                                    />
                                </span>
                            </div>
                        ))}
                        {issues.pingEvents.length > 3 && (
                            <span
                                className="show-more"
                                onClick={() => handleShowMoreChange('ping')}
                            >
                                {state.showMorePingIssues ? '-Show less' : '+More issues'}
                            </span>
                        )}
                    </>
                ) : (
                    <div className="issue-container success">
                        <span className="issue-type">No issues found</span>
                    </div>
                )}
            </div>

            <div className="outage-issues-section">
                <div className="outage-issues-title">HTTP issues</div>
                <div className="outage-issues-current-interval">{`${outageStart} - ${outageEnd} UTC`}</div>
                {issues.httpEvents.length > 0 ? (
                    <>
                        {issues.httpEvents.map((event, idx) => (
                            <div
                                className={`issue-container ${idx >= 3 && !state.showMoreHttpIssues ? 'hide' : ''}`}
                                key={`issue-container-${idx}`}
                            >
                                <span className="date-location">
                                    {monitoringRegions[event.region]} -{' '}
                                    <DateTimeDisplay
                                        utcTime={event.created_at}
                                        format="timeOnly"
                                    />
                                </span>
                                <span className="issue-type">{event.text}</span>
                            </div>
                        ))}
                        {issues.httpEvents.length > 3 && (
                            <span
                                className="show-more"
                                onClick={() => handleShowMoreChange('http')}
                            >
                                {state.showMoreHttpIssues ? '-Show less' : '+More issues'}
                            </span>
                        )}
                    </>
                ) : (
                    <div className="issue-container success">
                        <span className="issue-type">No issues found</span>
                    </div>
                )}
            </div>
        </>
    );
}
