import React, { Component } from "react";
import { Card, Tabs, Row, Col } from "antd";
import moment from "moment";
import BillingInfo from "./BillingInfo";
import PaymentMethod from "./PaymentMethod";
import SubscriptionPlansHeader from "./SubscriptionPlansHeader";
import InvoiceHistory from "../../containers/Sales/InvoiceHistory";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";

const { TabPane } = Tabs;

class BillingInfoLayout extends Component {
  render() {
    const {
      isFetchingBillingInfo,
      isUpdatingBillingInfo,
      isFetchingSubscription,
      billingInfo,
      onUpdateBillingInfo,
      onUpdatePaymentMethod,
      errorMessage,
      threeDSecure,
      subscription,
      isCancelingSubscription,
      onCancelSubscription,
      on3DSecureError
    } = this.props;

    return (
      <div>
        <Card bordered={false}>
          <Tabs className="billing-info-tabs" onChange={this.onTabChange} type="card">
            <TabPane tab="Billing info" key="billing-info">
              {isFetchingBillingInfo || isFetchingSubscription ? (
                <LoadingIndicator />
              ) : (
                <Row gutter={24}>
                  <Col sm={24} lg={12} xl={12}>
                    <BillingInfo
                      isUpdatingBillingInfo={isUpdatingBillingInfo}
                      billingInfo={billingInfo}
                      onUpdate={onUpdateBillingInfo}
                    />
                  </Col>
                  <Col sm={24} lg={12} xl={12}>
                    <div className="pricing-meta">
                      {subscription && subscription["plan_settings"] && (
                        <SubscriptionPlansHeader
                          isCancelingSubscription={isCancelingSubscription}
                          onCancelSubscription={onCancelSubscription}
                          subscriptionStatus={subscription["status"]}
                          planName={`${subscription["plan_settings"]["name"]} Plan`}
                          planPrice={`${subscription["plan_settings"]["price"] / 100}`}
                          planIntervalUnit={subscription["plan_settings"]["interval_unit"]}
                          planIntervalLength={subscription["plan_settings"]["interval_length"]}
                          currentPeriodEnd={moment(
                            subscription["current_period_ends_at"],
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("MMM DD, YYYY")}
                          nextPaymentDate={moment(subscription["current_period_ends_at"], "YYYY-MM-DDTHH:mm:ss").format(
                            "MMM DD, YYYY"
                          )}
                        />
                      )}
                    </div>

                    <div className="invoice-history">
                      <InvoiceHistory />
                    </div>
                  </Col>
                </Row>
              )}
            </TabPane>

            <TabPane tab="Payment method" key="payment-method">
              <PaymentMethod
                billingInfo={billingInfo}
                onUpdate={onUpdatePaymentMethod}
                on3DSecureError={on3DSecureError}
                isUpdatingBillingInfo={isUpdatingBillingInfo}
                errorMessage={errorMessage}
                threeDSecure={threeDSecure}
              />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

export default BillingInfoLayout;
