import React from 'react';
import { Table } from 'antd';
import TableColumnWithTooltip from '../../components/shared/TableColumnWithTooltip/TableColumnWithTooltip';
import { semTableColumns } from '../../components/shared/TableColumnWithTooltip/semTableColumns';
//import SparklineChart from '../../components/shared/SparklineChart/SparklineChart';
import { KeywordResearchTableRecord, ResearchedKeyword } from '../types';
import { getKeywordResearchTableData } from '../utils';

const { Column } = Table;

function getKeywordResearchTableCell(value: string) {
    return value !== '-' ? value : <span className="not-available">-</span>;
}

type Props = {
    keywords: ResearchedKeyword[];
};

export default function KeywordResearchSuggestionsTable({ keywords }: Props) {
    const tableData = getKeywordResearchTableData(keywords);

    return (
        <div className="keywords-table">
            <Table
                dataSource={tableData}
                pagination={false}
                size="small"
                data-cy="keyword-research-table-inside-modal"
            >
                <Column
                    title="#"
                    dataIndex="key"
                    align="left"
                    width="43"
                    className="no-wrap"
                />
                <Column
                    className="no-wrap"
                    title="Keyword"
                    dataIndex="keyword"
                />
                <Column
                    className="al-left"
                    title={
                        <TableColumnWithTooltip
                            noFill={false}
                            {...semTableColumns['search_volume']}
                        />
                    }
                    dataIndex="searchVolume"
                    render={(_, record: KeywordResearchTableRecord) => getKeywordResearchTableCell(record.searchVolume)}
                />
                <Column
                    className="al-left"
                    title={
                        <TableColumnWithTooltip
                            noFill={false}
                            {...semTableColumns['cpc']}
                        />
                    }
                    dataIndex="cpc"
                    render={(_, record: KeywordResearchTableRecord) => getKeywordResearchTableCell(record.cpc)}
                />
                <Column
                    className="al-left"
                    title={
                        <TableColumnWithTooltip
                            noFill={false}
                            {...semTableColumns['cmp']}
                        />
                    }
                    dataIndex="cmp"
                    render={(_, record: KeywordResearchTableRecord) => getKeywordResearchTableCell(record.cmp)}
                />
                <Column
                    className="al-left"
                    title={
                        <TableColumnWithTooltip
                            noFill={false}
                            {...semTableColumns['results_no']}
                        />
                    }
                    dataIndex="resultsNo"
                    render={(_, record: KeywordResearchTableRecord) => getKeywordResearchTableCell(record.resultsNo)}
                />
                {/* <Column
                    className="keyword-trends al-right"
                    title={
                        <TableColumnWithTooltip
                            noFill={false}
                            {...semTableColumns['trends']}
                        />
                    }
                    dataIndex="trends"
                    render={(_, record: KeywordResearchTableRecord) => <SparklineChart data={record.trends} />}
                /> */}
            </Table>
        </div>
    );
}
