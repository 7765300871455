import React from 'react';
import { TrackedWebsite } from '../trackedWebsites/types';
import TrackedKeywordsCount from './TrackedKeywordsCount';
import TrackedKeywordsOverview from './TrackedKeywordsOverview';

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite) => void;
};

export default function KeywordPositionTracker({ selectedWebsite, setSelectedWebsite }: Props) {
    return (
        <div>
            <TrackedKeywordsCount />
            <TrackedKeywordsOverview
                selectedWebsite={selectedWebsite}
                setSelectedWebsite={setSelectedWebsite}
            />
        </div>
    );
}
