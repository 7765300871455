import React from "react";
import { Layout } from "antd";
import TrackedWebsites from "../trackedWebsites/TrackedWebsites";
import SiteOutage from "../containers/SiteOutage/SiteOutage";

const { Content } = Layout;

const SiteOutagePage = () => {
  return (
    <Content className="general-layout-content">
      <TrackedWebsites tabContent={<SiteOutage />} />
    </Content>
  );
};

export default SiteOutagePage;
