import React from "react";
import { Icon } from "antd";
import "./IconLabel.less";

const IconLabel = ({ icon, label }) => {
  return (
    <span className="icon-label">
      <Icon type={icon} />
      {label}
    </span>
  );
};

export default IconLabel;
