import { useSelector } from 'react-redux';
import { getMaxCompetitorsNo, getMaxKeywordsNo, getMaxRegionsNo, getMaxTrackedWebsitesNo } from '../../store/sales/selectors';

export function useGetMaxValuesForPlan() {
	const maxCompetitors = useSelector((state) => getMaxCompetitorsNo(state));
	const maxKeywords = useSelector((state) => getMaxKeywordsNo(state));
	const maxRegions = useSelector((state) => getMaxRegionsNo(state));
	const maxTrackedWebsites = useSelector((state) => getMaxTrackedWebsitesNo(state));

	return {
		maxCompetitors,
		maxKeywords,
		maxRegions,
		maxTrackedWebsites,
	};
}
