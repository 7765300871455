import React from 'react';
import { Tabs, Card } from 'antd';
import BacklinksResults from './BacklinksResults';
import { backlinksTabsDisplayOrder, backlinksTabsSections } from '../settings';

type Props = {
    domain: string;
};

export default function BacklinksTabs({ domain }: Props) {
    return (
        <div className="backlinks-tabs">
            <Card>
                <Tabs
                    type="card"
                    tabBarGutter={10}
                    destroyInactiveTabPane={true}
                >
                    {backlinksTabsDisplayOrder.map((section) => (
                        <Tabs.TabPane
                            tab={backlinksTabsSections[section].label}
                            key={section}
                        >
                            <BacklinksResults
                                domain={domain}
                                resourceType={backlinksTabsSections[section].resource_type}
                                orderBy={backlinksTabsSections[section].order_by}
                                orderDir={backlinksTabsSections[section].order_dir}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Card>
        </div>
    );
}
