import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import { Icon } from "antd";

const displayResult = result => {
  if (["warning", "failed"].includes(result.status)) {
    return result.redirects_chain.map((url, index) => (
      <div key={index} className="description-meta inline-flex flex-wrap">
        <a href={url.from} target="_blank" rel="noopener noreferrer">
          {url.from}
        </a>

        <span className="gray-label top-margin">
          <Icon type="arrow-right" className="icon" />
        </span>

        <a href={url.to} target="_blank" rel="noopener noreferrer">
          {url.to}
        </a>
      </div>
    ));
  }
  return null;
};

const HttpRedirectsSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default HttpRedirectsSection;
