import React, { useState, createContext, useEffect, useRef } from 'react';
import { TrackedWebsite } from './types';
import { useSetSelectedWebsiteForOldRedux } from './hooksOldRedux/useSetSelectedWebsiteForOldRedux';
import { useFetchTrackedWebsitesWithOverviewsAndPreCrawling } from './hooks/useFetchTrackedWebsitesWithOverviewsAndPreCrawling';

type Props = {
    children: JSX.Element[];
};

const initialContext: {
    selectedWebsite: TrackedWebsite | null;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void;
} = {
    selectedWebsite: null,
    setSelectedWebsite: () => {},
};

export const SelectedWebsiteContext = createContext(initialContext);

export default function SelectedWebsiteContextWrapper({ children }: Props) {
    const isFirstTimeSettingSelectedWebsite = useRef<boolean>(true);
    // Setter for old redux logic
    const { setSelectedWebsiteForOldRedux } = useSetSelectedWebsiteForOldRedux();
    const [selectedWebsite, setSelectedWebsite] = useState<TrackedWebsite | null>(null);
    const { data } = useFetchTrackedWebsitesWithOverviewsAndPreCrawling();

    // Setter for both new and old
    const _setSelectedWebsiteForNewAndOldRedux = (newSelectedWebsite: TrackedWebsite | null) => {
        if (newSelectedWebsite !== null) {
            if (!selectedWebsite || newSelectedWebsite.guid !== selectedWebsite.guid) {
                setSelectedWebsiteForOldRedux(newSelectedWebsite.guid);
            }
            setSelectedWebsite(newSelectedWebsite);
        } else {
            setSelectedWebsiteForOldRedux(null);
            setSelectedWebsite(null);
        }
    };

    useEffect(() => {
        if (data && isFirstTimeSettingSelectedWebsite.current) {
            isFirstTimeSettingSelectedWebsite.current = false;

            if (data.tracked_websites.length > 0) {
                setSelectedWebsite(data.tracked_websites[0]);
                setSelectedWebsiteForOldRedux(data.tracked_websites[0].guid);
            }
        }
    }, [data, setSelectedWebsiteForOldRedux]);

    return (
        <SelectedWebsiteContext.Provider value={{ selectedWebsite, setSelectedWebsite: _setSelectedWebsiteForNewAndOldRedux }}>
            {children}
        </SelectedWebsiteContext.Provider>
    );
}
