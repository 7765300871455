import React, { useState } from 'react';
import { Divider, Checkbox } from 'antd';
import { TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type Props = {
    selectedWebsite: TrackedWebsite;
    onUpdateNotifications: (args: UpdateTrackedWebsitesParams) => void;
};

export default function NotificationsInput({ selectedWebsite, onUpdateNotifications }: Props) {
    const selectedWebsiteNotifications = selectedWebsite.settings.notifications
        ? selectedWebsite.settings.notifications
        : ['ping_uptime', 'http_uptime', 'page_load_timeout'];

    const [state, setState] = useState({ selectedWebsiteNotifications });

    const handleNotificationsChange = (checkedValues: CheckboxValueType[]) => {
        setState({ selectedWebsiteNotifications: checkedValues as string[] });
        onUpdateNotifications({
            guid: selectedWebsite.guid,
            url: selectedWebsite.url,
            notifications: checkedValues as string[],
        });
    };

    return (
        <Checkbox.Group
            onChange={handleNotificationsChange}
            value={state.selectedWebsiteNotifications}
        >
            <div className="notification-container">
                <h4>Ping Uptime</h4>
                <p className="notification-explanation">
                    If you'll enable this notification, you'll receive emails when our monitoring tool cannot access your domain or when your domain become
                    accessible again after a downtime period.
                </p>
                <Checkbox value={'ping_uptime'}>
                    <span className="notification-text">Notify me if your PING Uptime Monitor cannot access my domain</span>
                </Checkbox>
                <Divider />
            </div>

            <div className="notification-container">
                <h4>HTTP Uptime</h4>
                <p className="notification-explanation">
                    If you'll enable this notification, you'll receive emails when our monitoring tool doesn't receive an HTTP 200 status code from your
                    website.
                </p>
                <Checkbox value={'http_uptime'}>
                    <span className="notification-text">Notify me if your HTTP Uptime Monitor cannot access my website</span>
                </Checkbox>
                <Divider />
            </div>

            <div className="notification-container">
                <h4>Page Load Timeout</h4>
                <p className="notification-explanation">
                    If you'll enable this notification, you'll receive emails when our monitoring tool cannot load your website under 20 seconds.
                </p>
                <Checkbox value={'page_load_timeout'}>
                    <span className="notification-text">Notify me if your HTTP Loading Time Monitor cannot load my website</span>
                </Checkbox>
            </div>
        </Checkbox.Group>
    );
}
