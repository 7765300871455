import {
  FETCH_BROKEN_LINKS_IN_PROGRESS,
  FETCH_BROKEN_LINKS_SUCCESSFUL,
  FETCH_BROKEN_LINKS_FAILED,
  FETCH_AFFECTED_PAGES_IN_PROGRESS,
  FETCH_AFFECTED_PAGES_SUCCESSFUL,
  FETCH_AFFECTED_PAGES_FAILED,
  RESET_BROKEN_LINKS,
  RESET_AFFECTED_PAGES
} from "./types";

export const fetchBrokenLinksInProgress = (resourceType, domain, linkType) => {
  return { type: FETCH_BROKEN_LINKS_IN_PROGRESS, resourceType, domain, linkType };
};

export const fetchBrokenLinksSuccessful = (resourceType, domain, linkType, response) => {
  return { type: FETCH_BROKEN_LINKS_SUCCESSFUL, resourceType, domain, linkType, response };
};

export const fetchBrokenLinksFailed = (resourceType, domain, linkType) => {
  return { type: FETCH_BROKEN_LINKS_FAILED, resourceType, domain, linkType };
};

export const fetchAffectedPagesInProgress = (resourceType, domain, url) => {
  return { type: FETCH_AFFECTED_PAGES_IN_PROGRESS, resourceType, domain, url };
};

export const fetchAffectedPagesSuccessful = (resourceType, domain, url, response) => {
  return { type: FETCH_AFFECTED_PAGES_SUCCESSFUL, resourceType, domain, url, response };
};

export const fetchAffectedPagesFailed = (resourceType, domain, url) => {
  return { type: FETCH_AFFECTED_PAGES_FAILED, resourceType, domain, url };
};

export const resetBrokenLinks = () => {
  return { type: RESET_BROKEN_LINKS };
};

export const resetAffectedPages = (domain, url) => {
  return { type: RESET_AFFECTED_PAGES, domain, url };
};
