import React, { Component } from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { Checkbox, Empty } from "antd";
import moment from "moment";

export class SeoScoreProgressChart extends Component {
  constructor(props) {
    super(props);

    this.colors = ["#4BB2C5", "#0A901B", "#E67E22", "#C1380A", "#722D8E", "#33CCC8"];

    this.checkboxRefs = {};
    this.props.competitors.forEach(competitor => {
      this.checkboxRefs[competitor] = React.createRef();
    });

    this.state = {
      mainUrl: this.props.mainUrl,
      checkedCompetitors: this.props.competitors,
      colorsPairing: this.getColorsPairing(this.props.mainUrl, this.props.competitors, this.colors)
    };

    this.getChartColors = this.getChartColors.bind(this);
    this.getColorsPairing = this.getColorsPairing.bind(this);
    this.getScale = this.getScale.bind(this);
    this.getTickCount = this.getTickCount.bind(this);
    this.handleTooltipChange = this.handleTooltipChange.bind(this);
    this.handleCheckboxOnChange = this.handleCheckboxOnChange.bind(this);
    this.getFilteredChartData = this.getFilteredChartData.bind(this);
    this.getMaxAndMinValueFromChartData = this.getMaxAndMinValueFromChartData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.competitors !== prevProps.competitors && this.props.mainUrl !== prevProps.mainUrl) {
      this.checkboxRefs = {};
      this.props.competitors.forEach((competitor, idx) => {
        this.checkboxRefs[competitor] = React.createRef();
      });

      this.setState({
        mainUrl: this.props.mainUrl,
        checkedCompetitors: this.props.competitors,
        colorsPairing: this.getColorsPairing(this.props.mainUrl, this.props.competitors, this.colors)
      });
    }
  }

  handleCheckboxOnChange(e) {
    let checkedCompetitors = [...this.state.checkedCompetitors];
    let affectedCompetitor = e.target.name;
    if (e.target.checked) {
      checkedCompetitors = [...checkedCompetitors, affectedCompetitor];
    } else {
      checkedCompetitors = checkedCompetitors.filter(competitor => competitor !== affectedCompetitor);
    }
    this.setState({ checkedCompetitors: checkedCompetitors });
    this.checkboxRefs[affectedCompetitor].current.blur();
  }

  getColorsPairing(mainUrl, competitors, colors) {
    return Object.assign(
      {},
      { [mainUrl]: colors[0] },
      ...competitors.map((competitor, idx) => ({ [competitor]: colors[idx + 1] }))
    );
  }

  getChartColors(mainUrl, checkedCompetitors, competitors, colorsPairing) {
    const orderedCheckedCompetitors = competitors.filter(competitor => checkedCompetitors.includes(competitor));
    return [colorsPairing[mainUrl], ...orderedCheckedCompetitors.map(competitor => colorsPairing[competitor])];
  }

  getFilteredChartData(data, mainUrl, checkedCompetitors) {
    let urls = [mainUrl, ...checkedCompetitors];
    return data.filter(item => urls.includes(item["url"]));
  }

  getScale(dataSource) {
    let chartTickCount = this.getTickCount(dataSource);
    let maxScore = this.getMaxAndMinValueFromChartData(this.props.chartData).max;
    let minScore = this.getMaxAndMinValueFromChartData(this.props.chartData).min;
    let scale = {
      score: {
        type: "linear",
        max: maxScore > 98 ? maxScore : maxScore + 2,
        min: minScore < 2 ? minScore : minScore - 2,
        minTickInterval: 1
      },
      created_at: {
        type: "timeCat",
        mask: "MMM DD",
        nice: true
      }
    };

    if (chartTickCount > 1) {
      scale["created_at"]["range"] = [0, 1];
    }
    if (chartTickCount <= 7) {
      scale["created_at"]["tickCount"] = chartTickCount;
    }

    return scale;
  }

  getMaxAndMinValueFromChartData(dataSource) {
    let range = {
      min: 0,
      max: 0
    };

    if (dataSource.length > 0) {
      let values = dataSource.map(item => item.score);
      range.min = Math.min(...values);
      range.max = Math.max(...values);
    }
    return range;
  }

  getTickCount(dataSource) {
    let dataEntriesCountForUrls = {};
    dataSource.forEach(dataEntry => {
      if (!(dataEntry["url"] in dataEntriesCountForUrls)) {
        dataEntriesCountForUrls[dataEntry["url"]] = 1;
      } else {
        dataEntriesCountForUrls[dataEntry["url"]] += 1;
      }
    });
    let arrayOfEntriesCount = Object.values(dataEntriesCountForUrls);
    if (arrayOfEntriesCount.length === 0) {
      return 0;
    }
    return Math.max(...arrayOfEntriesCount);
  }

  handleTooltipChange(event) {
    let tooltipItems = [...event.items];

    tooltipItems.forEach(item => {
      item.title = moment(item.point._origin.created_at, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY");
      item.name = item.point._origin.url;
      item.url = item.point._origin.score;
    });
  }

  render() {
    let filteredChartData = this.getFilteredChartData(
      this.props.chartData,
      this.state.mainUrl,
      this.state.checkedCompetitors
    );
    let competitorsInLegend = this.props.competitors.filter(competitor =>
      this.props.chartData.some(dataEntry => dataEntry["url"] === competitor)
    );
    let chartDataForMainUrl = this.props.chartData.filter(dataEntry => dataEntry["url"] === this.props.mainUrl);

    return (
      <div className="seo-score-progress-chart">
        {chartDataForMainUrl.length > 0 ? (
          <React.Fragment>
            {competitorsInLegend.length > 0
              ? competitorsInLegend.map((competitor, idx) => (
                  <Checkbox
                    className="site-speed-checkbox"
                    key={competitor}
                    name={competitor}
                    style={{
                      "--background-color": this.colors[idx + 1],
                      "--border-color": this.colors[idx + 1]
                    }}
                    checked={this.state.checkedCompetitors.includes(competitor)}
                    defaultChecked={this.state.checkedCompetitors.includes(competitor)}
                    onChange={this.handleCheckboxOnChange}
                    ref={this.checkboxRefs[competitor]}>
                    {competitor}
                  </Checkbox>
                ))
              : null}
            <Chart
              padding={"auto"}
              height={400}
              data={filteredChartData}
              scale={this.getScale(filteredChartData)}
              animate={false}
              forceFit
              onTooltipChange={this.handleTooltipChange}>
              <Axis name="created_at" />
              <Axis name="score" />
              <Tooltip />
              <Geom
                type="line"
                position="created_at*score"
                size={2}
                shape={"smooth"}
                color={[
                  "url",
                  this.getChartColors(
                    this.props.mainUrl,
                    this.state.checkedCompetitors,
                    this.props.competitors,
                    this.state.colorsPairing
                  )
                ]}
              />
              <Geom
                type="point"
                position="created_at*score"
                size={4}
                shape={"circle"}
                style={{ lineWidth: 1 }}
                color={[
                  "url",
                  this.getChartColors(
                    this.props.mainUrl,
                    this.state.checkedCompetitors,
                    this.props.competitors,
                    this.state.colorsPairing
                  )
                ]}
              />
            </Chart>
          </React.Fragment>
        ) : (
          <Empty
            className="empty-state"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No saved reports for selected interval were found"
          />
        )}
      </div>
    );
  }
}

export default SeoScoreProgressChart;
