import React, { Component } from "react";
import moment from "moment";

class OutageIssues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      showMoreIssues: {
        ping: "hide",
        http: "hide"
      },
      showMoreIssuesText: {
        ping: "+More issues",
        http: "+More issues"
      }
    };

    this.setModalVisible = this.setModalVisible.bind(this);
    this.showMoreIssues = this.showMoreIssues.bind(this);
    this.groupEventsByType = this.groupEventsByType.bind(this);
  }

  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }

  showMoreIssues(issueType) {
    this.setState({
      showMoreIssues: {
        ...this.state.showMoreIssues,
        [issueType]: this.state.showMoreIssues[issueType] === "hide" ? "" : "hide"
      },
      showMoreIssuesText: {
        ...this.state.showMoreIssuesText,
        [issueType]: this.state.showMoreIssues[issueType] === "hide" ? "-Show Less" : "+More issues"
      }
    });
  }

  groupEventsByType(events) {
    const groups = {
      pingEvents: [],
      httpEvents: []
    };

    if (events) {
      Object.keys(events).forEach(region => {
        let event = events[region];

        if (event["ping"]["status"] === "down") {
          event["ping"]["region"] = event["name"];
          groups["pingEvents"].push(event["ping"]);
        }

        if (event["loading_time"]["status"] === "down") {
          event["loading_time"]["region"] = event["name"];
          groups["httpEvents"].push(event["loading_time"]);
        }
      });
    }

    return groups;
  }

  render() {
    const issues = this.groupEventsByType(this.props.currentContext["events"]);
    const start = moment(this.props.start).format("HH:mm A");
    const end = moment(this.props.end).format("HH:mm A");

    return (
      <React.Fragment>
        <div className="outage-issues-section first">
          <div className="outage-issues-title">Ping issues</div>
          <div className="outage-issues-current-interval">{`${start} - ${end}`}</div>
          {issues["pingEvents"].length > 0 ? (
            <React.Fragment>
              {issues["pingEvents"].map((event, idx) => (
                <div
                  className={`issue-container ${idx >= 3 ? this.state.showMoreIssues["ping"] : ""}`}
                  key={`issue-container-${idx}`}>
                  <span className="date-location">
                    {event["region"]} - {moment.unix(event["created_at"]).utc().format("HH:mm A")}
                  </span>
                  {/* <span className="issue-type">No packages received</span> */}
                </div>
              ))}
              <span className="show-more" onClick={() => this.showMoreIssues("ping")}>
                {this.state.showMoreIssuesText["ping"]}
              </span>
            </React.Fragment>
          ) : (
            <div className="issue-container success">
              {/* <span className="date-location">{`from ${start} to ${end}`}</span> */}
              <span className="issue-type">No issues found</span>
            </div>
          )}
        </div>

        <div className="outage-issues-section">
          <div className="outage-issues-title">HTTP issues</div>
          <div className="outage-issues-current-interval">{`${start} - ${end}`}</div>
          {issues["httpEvents"].length > 0 ? (
            <React.Fragment>
              {issues["httpEvents"].map((event, idx) => (
                <div
                  className={`issue-container ${idx >= 3 ? this.state.showMoreIssues["http"] : ""}`}
                  key={`issue-container-${idx}`}>
                  <span className="date-location">
                    {event["region"]} - {moment.unix(event["created_at"]).utc().format("HH:mm A")}
                  </span>
                  <span className="issue-type">Loading time &gt; 20s</span>
                </div>
              ))}
              <span className="show-more" onClick={() => this.showMoreIssues("http")}>
                {this.state.showMoreIssuesText["http"]}
              </span>
            </React.Fragment>
          ) : (
            <div className="issue-container success">
              {/* <span className="date-location">{`from ${start} to ${end}`}</span> */}
              <span className="issue-type">No issues found</span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default OutageIssues;
