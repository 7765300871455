//import React from "react";
// import { Experiment, Variant } from "@marvelapp/react-ab-test";
// import { AB_TEST_EXPERIMENT } from "../../../abTesting/experiment";
import PaymentLayoutControl from "./PaymentLayout";
//import PaymentLayoutVariantA from "./Experiment/PaymentLayoutVariantA";
//import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";

// const PaymentLayout = props => {
//   return (
//     <Experiment name={AB_TEST_EXPERIMENT["name"]}>
//       <Variant name="control">
//         <PaymentLayoutControl {...props} />
//       </Variant>

//       <Variant name="A">
//         {props.plans && "monthly" in props.plans ? (
//           <PaymentLayoutVariantA {...props} />
//         ) : (
//           <LoadingIndicator style={{ maxWidth: "1000px", margin: "auto" }} />
//         )}
//       </Variant>
//     </Experiment>
//   );
// };

export default PaymentLayoutControl;
