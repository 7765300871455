import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

const initialState = {
    validationError: '',
    keyword: '',
};

type Props = {
    onAddedKeyword: (keyword: string) => void;
    isAddingTrackedKeywords: boolean;
};

export default function KeywordsInput({ onAddedKeyword, isAddingTrackedKeywords }: Props) {
    const [state, setState] = useState(initialState);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ keyword: event.target.value, validationError: '' });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const newKeyword = state.keyword.toLowerCase().trim();
        if (!newKeyword) {
            setState({ ...state, validationError: 'This field is required!' });
        } else {
            setState(initialState);
            onAddedKeyword(state.keyword);
        }
    };

    return (
        <div className="dashboard-settings-add-keyword-form">
            <p>
                <strong>Custom keywords</strong>
            </p>
            <Form onSubmit={handleSubmit}>
                <Form.Item
                    validateStatus={state.validationError ? 'error' : 'success'}
                    help={state.validationError}
                >
                    <Input.Group
                        compact
                        size="large"
                    >
                        <div className="compact-input-group">
                            <Input
                                name="keyword"
                                size="large"
                                value={state.keyword}
                                onChange={handleInputChange}
                                placeholder="Add new keyword"
                            />

                            <Button
                                type="primary"
                                htmlType="submit"
                                icon="plus"
                                size="large"
                                loading={isAddingTrackedKeywords}
                            ></Button>
                        </div>
                    </Input.Group>
                </Form.Item>
            </Form>
        </div>
    );
}
