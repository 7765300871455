import './TrackedWebsites.less';
import React, { useContext } from 'react';
import { Tabs, Spin, Icon } from 'antd';
import DefaultLayout from './DefaultLayout';
import AddNewTrackedWebsiteButton from './AddNewTrackedWebsiteButton';
import TrackedWebsitesTab from './TrackedWebsitesTab';
import { useGetMaxValuesForPlan } from '../sales/hooks/useGetMaxValuesForPlan';
import { SelectedWebsiteContext } from './ContextWrapper';
import { useFetchTrackedWebsitesQuery } from './service';

const { TabPane } = Tabs;

type Props = {
    tabContent: JSX.Element;
    showDomains?: boolean;
    showStatus?: boolean;
    destroyInactiveTabs?: boolean;
};

export default function TrackedWebsites({ tabContent, showDomains, showStatus, destroyInactiveTabs }: Props) {
    const { selectedWebsite, setSelectedWebsite } = useContext(SelectedWebsiteContext);

    const { data, isLoading: isLoadingTrackedWebsites } = useFetchTrackedWebsitesQuery();

    const { maxTrackedWebsites } = useGetMaxValuesForPlan();
    const showAddNewWebsite = maxTrackedWebsites > (data ? data.tracked_websites.length : maxTrackedWebsites);

    const onTabChange = (selectedWebsiteGuid: string) => {
        if (data) {
            const newSelectedWebsite = data.tracked_websites.find((trackedWebsite) => trackedWebsite.guid === selectedWebsiteGuid);

            if (newSelectedWebsite) {
                setSelectedWebsite(newSelectedWebsite);
            }
        }
    };

    return (
        <div className="tracked-websites-tabs">
            {(isLoadingTrackedWebsites || !data) && (
                <Spin
                    indicator={
                        <Icon
                            data-cy="loader"
                            type="loading"
                            style={{ fontSize: 18 }}
                            spin
                        />
                    }
                />
            )}
            {!isLoadingTrackedWebsites && data && data.tracked_websites.length === 0 ? <DefaultLayout /> : null}
            {!isLoadingTrackedWebsites && data && data.tracked_websites.length ? (
                <Tabs
                    className="dashboard-tabs"
                    animated={false}
                    destroyInactiveTabPane={destroyInactiveTabs}
                    activeKey={selectedWebsite ? selectedWebsite.guid : ''}
                    onChange={onTabChange}
                    tabBarExtraContent={showAddNewWebsite ? <AddNewTrackedWebsiteButton /> : null}
                >
                    {data.tracked_websites.map((trackedWebsite) => {
                        return (
                            <TabPane
                                key={trackedWebsite.guid}
                                tab={
                                    <TrackedWebsitesTab
                                        status={showStatus ? 'passed' : ''}
                                        displayText={showDomains ? trackedWebsite.domain : trackedWebsite.url}
                                        url={trackedWebsite.url}
                                    />
                                }
                            >
                                {tabContent}
                            </TabPane>
                        );
                    })}
                </Tabs>
            ) : null}
        </div>
    );
}
