import fetch from "cross-fetch";
import { refreshAccessToken } from "./iam/operations";
import { getAccessToken } from "./iam/selectors";

const mergeAuthRequestOptions = (options, getState) => {
  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${getAccessToken(getState())}`,
      "Content-Type": "application/json"
    }
  };
};

export const authRequest = (url, dispatch, getState, options = {}) => {
  return new Promise((resolve, reject) => {
    fetch(url, mergeAuthRequestOptions(options, getState)).then(
      (response) => {
        if (![200, 201, 204, 401, 409].includes(response.status)) {
          return reject({ message: "ERROR: Cannot perform this request!" });
        }

        if ([200, 201].includes(response.status)) {
          return resolve(response.json());
        }

        if (response.status === 204) {
          return resolve();
        }

        if (response.status === 409) {
          return reject(response);
        }

        if (response.status === 401) {
          dispatch(refreshAccessToken()).then((_) => {
            fetch(url, mergeAuthRequestOptions(options, getState))
              .then((response) => resolve(response.json()))
              .catch((_) => reject());
          });
        }
      },
      (error) => {
        return reject({ message: `Network error: ${error.toString()}` });
      }
    );
  });
};
