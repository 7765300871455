import './TrackedWebsitesTab.less';
import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { useGetFaviconUrl } from '../analysis/hooks/useGetFaviconUrl';
import { useNavigate } from '../router/hooks/useNavigate';

type Props = {
	status: string;
	displayText: string;
	url: string;
};

const settingsItems = [
	{ key: 'competitors', path: '/dashboard/settings/competitors', title: 'Competitors' },
	{ key: 'keywords', path: '/dashboard/settings/keywords', title: 'Keywords' },
	{ key: 'monitor-locations', path: '/dashboard/settings/monitor-locations', title: 'Monitor Locations' },
	{ key: 'notifications', path: '/dashboard/settings/notifications', title: 'Notifications' },
	{ key: 'remove-url', path: '/dashboard/settings/remove-url', title: 'Remove URL' },
];

export default function TrackedWebsitesTab({ status, displayText, url }: Props) {
	const faviconUrl = useGetFaviconUrl(url);
	const navigate = useNavigate();

	const menu = (
		<Menu className="url-options">
			{settingsItems.map((item) => (
				<Menu.Item
					key={item.key}
					onClick={() => navigate(`/dashboard/settings/${item.key}`)}
				>
					{item.title}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<div
			className={`report-indicator ${status ? status : ''}`}
			data-cy="tracked-website-url"
		>
			<div className="tab-item-content flexbox align-center">
				<div className="favicon-container">
					<img
						src={faviconUrl || '/images/default_favicon.png'}
						alt=""
						className={faviconUrl ? '' : 'default'}
						onError={(e) => {
							e.currentTarget.className = 'default';
							if (e.currentTarget.src !== '/images/default_favicon.png') {
								e.currentTarget.src = '/images/default_favicon.png';
							}
						}}
					/>
				</div>
				{displayText}
				<Dropdown
					overlay={menu}
					placement="bottomRight"
				>
					<Button icon="ellipsis"></Button>
				</Dropdown>
			</div>
			{status && <span className="tab-title-indicator" />}
		</div>
	);
}
