import React, { Component } from "react";
import { Input, Button, Form, Collapse } from "antd";
import { Competitors } from "./index";
import SectionsSelector from "../../containers/SectionsSelector";
import URLSchemaSelector from "../shared/URLSchemaSelector/URLSchemaSelector";
//import DashboardCardTitle from "../Dashboard/DashboardCardTitle";
import "./AnalysisForm.less";

const { Panel } = Collapse;

class AnalysisForm extends Component {
  state = {
    website: {
      url: "",
      urlSchema: "https://"
    },
    competitors: [],
    sections: [],
    isSubmitted: false
  };

  onChangeMainUrl = event => {
    event.persist();
    this.setState(prevState => ({
      website: {
        ...prevState.website,
        url: event.target.value
      },
      isSubmitted: false
    }));
  };

  onChangeMainUrlSchema = urlSchema => {
    this.setState(prevState => ({
      website: {
        ...prevState.website,
        urlSchema: urlSchema
      }
    }));
  };

  getSelectedSections = selected_sections => {
    this.setState({ sections: selected_sections });
  };

  getCompetitors = competitors => {
    this.setState({ competitors: competitors });
  };

  submitForm = event => {
    event.preventDefault();
    this.setState({ isSubmitted: true });
    this.props.onSubmit(this.state.website, this.state.competitors, this.state.sections);
  };

  render() {
    return (
      <Collapse
        defaultActiveKey={"analysis-form-panel"}
        accordion
        bordered={false}
        className="new-checkup-collapse ant-card and-card-bordered">
        <Panel
          key="analysis-form-panel"
          className="main-header"
          header={
            <React.Fragment>
              <div className="panel-header">Perform a new checkup</div>
              <div className="panel-subheader">Get a full SEO Checkup for your website</div>
            </React.Fragment>
          }>
          <Form onSubmit={this.submitForm} className="seo-checkup-form">
            <div className="half-grid">
              <div className="grid-item">
                <Form.Item
                  validateStatus={
                    this.state.isSubmitted && this.props.mainUrlValidation !== "" ? "error" : "validating"
                  }
                  help={this.state.isSubmitted && this.props.mainUrlValidation}>
                  <label htmlFor="mainUrlInput">Your website</label>
                  <Input
                    id="mainUrlInput"
                    type="text"
                    name="mainUrl"
                    placeholder="example.com"
                    value={this.state.website.url}
                    addonBefore={<URLSchemaSelector onChange={this.onChangeMainUrlSchema} />}
                    onChange={event => this.onChangeMainUrl(event)}
                  />
                </Form.Item>
              </div>

              <div className="grid-item">
                <SectionsSelector sendSelectedSections={this.getSelectedSections} />
              </div>
            </div>

            <div className="competitors-selection">
              <Competitors
                maxCompetitors={this.props.maxCompetitors}
                sendCompetitors={this.getCompetitors}
                validationErrors={this.props.competitorsValidation}
              />
            </div>

            <Button type="primary" htmlType="submit" className="checkup-cta">
              Checkup your SEO
            </Button>
          </Form>
        </Panel>
      </Collapse>
      /* <Card
        title={
          <DashboardCardTitle title="Perform a new checkup" description={"Get a full SEO Checkup for your website"} />
        }>
      </Card> */
    );
  }
}

export default AnalysisForm;
