import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useAppDispatch } from '../../useAppDispatch';
import moment from 'moment';

import { getOverviewsForUrl } from '../../store/analysis/selectors';
import { fetchOverviews } from '../../store/analysis/operations';

import { TrackedWebsite } from '../../trackedWebsites/types';

const currentDate = moment().utc();
const endDate = currentDate.format('YYYY-MM-DDTHH:mm:ss');
const startDate = currentDate.subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ss');

export function useDispatchFetchTrackedWebsitesOverviews(trackedWebsites: TrackedWebsite[]) {
    const store = useStore();
    const dispatch = useAppDispatch();
    const [isLoadingOverviews, setIsLoadingOverviews] = useState<boolean>(true);

    useEffect(() => {
        let isMounted = true;

        if (trackedWebsites.length > 0) {
            Promise.all(
                trackedWebsites.map((trackedWebsite) => {
                    let overviews = getOverviewsForUrl(store.getState(), trackedWebsite.url);

                    if (overviews.length === 0) {
                        return dispatch(fetchOverviews(trackedWebsite.url, startDate, endDate));
                    }
                    return Promise.resolve();
                })
            )
                .catch(() => {})
                .finally(() => {
                    if (isMounted) {
                        setIsLoadingOverviews(false);
                    }
                });
        }

        return () => {
            isMounted = false;
        };
    }, [dispatch, store, trackedWebsites]);

    return {
        isLoadingOverviews,
    };
}
