import React from 'react';
import { Alert } from 'antd';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import { useNavigate } from '../router/hooks/useNavigate';
import { useGetMaxValuesForPlan } from '../sales/hooks/useGetMaxValuesForPlan';
import { useFetchTrackedKeywordsCountQuery } from './service';

export default function TrackedKeywordsCount() {
    const navigate = useNavigate();

    const { maxKeywords } = useGetMaxValuesForPlan();
    const { data: trackedKeywordsCount, isLoading: isLoadingTrackedKeywordsCount } = useFetchTrackedKeywordsCountQuery();

    const handleUpgradePlanClicked = () => {
        navigate('/subscription/plans');
    };

    const isTrackedKeywordsCountExistent = !isLoadingTrackedKeywordsCount && trackedKeywordsCount;

    return (
        <>
            {isLoadingTrackedKeywordsCount && <LoadingIndicator />}

            {isTrackedKeywordsCountExistent && (
                <div className="tracked-keywords-count">
                    <Alert
                        message={
                            <p style={{ marginTop: '1em' }}>
                                You are using {trackedKeywordsCount.keywords_no} out of {maxKeywords} maximum keywords limit for all your websites. If you need
                                more keywords, please consider to:{' '}
                                <strong>
                                    <span
                                        className="fake-link"
                                        onClick={handleUpgradePlanClicked}
                                    >
                                        Upgrade your plan
                                    </span>
                                </strong>
                            </p>
                        }
                    />
                </div>
            )}
        </>
    );
}
