import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";
import { Tooltip } from "antd";
import "../../TagListing.less";

const getFontSize = freq => {
  const minFontSize = 14;
  let fontSize = 42;

  if (freq <= 15) {
    fontSize = minFontSize + 2 * (freq - 1);
  }

  return fontSize;
};

const displayResult = (result, url, hasCompetitors) => {
  if (result.status === "passed") {
    return (
      <>
        {hasCompetitors && (
          <div className="description-content">
            <div className="flexbox align-center">
              <span className="section-result-url">{url}</span>
            </div>
          </div>
        )}
        <div className="description-content">
          <div className="cloud-tag-listing">
            {result.keywords_cloud.map((pair, idx) => (
              <Tooltip
                title={`Found ${pair[1]} time${pair[1] > 1 ? "s" : ""}`}
                key={idx}
                placement="bottom"
                overlayClassName="tooltip-container">
                <span style={{ fontSize: getFontSize(pair[1]) }}>{pair[0]}</span>
              </Tooltip>
            ))}
          </div>
        </div>
      </>
    );
  }
  return <HtmlContent content={result.message}></HtmlContent>;
};

const displayCompetitorResult = result => displayResult(result);

const KeywordsCloudSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className="report-element default">
      <SectionTitle title={title} />

      <div className="report-element-description">
        {displayResult(result, url, hasCompetitors)}
        <SectionCompetitorResults
          results={competitorResults}
          relevance={relevance}
          render={displayCompetitorResult}
          hideMessage={true}
        />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default KeywordsCloudSection;
