import React from "react";
import SectionMessage from "../SectionMessage/SectionMessage";

const SectionCompetitorResults = ({ results, relevance, render, hideMessage, hideUrl }) => {
    if(Array.isArray(results) && results.length > 0){
        return (
            results.map((competitor, idx) => (
                <div className={`competitor-report-element-description ${relevance !== 0 ? competitor.result.status : ''}`} key={idx}>
                    <SectionMessage 
                        url={competitor.url} 
                        status={competitor.result.status} 
                        relevance={relevance} 
                        message={competitor.result.message} 
                        hideMessage={hideMessage} 
                        hideUrl={hideUrl} 
                        hasCompetitors={true} 
                    />
                    {render && render(competitor.result)}                                   
                </div>
            ))
        )
    }
    return null;  
};

export default SectionCompetitorResults;
