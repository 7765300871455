import { authRequest } from "../authorizedRequests";
import { TRACKED_WEBSITES_API_URL } from "../../settings/config";
import { getMonitorLocationsInProgress, getMonitorLocationsSuccess, getMonitorLocationsFailed } from "./creators";

export const fetchMonitorLocations = _ => {
  return (dispatch, getState) => {
    const apiUrl = `${TRACKED_WEBSITES_API_URL}/regions`;

    if (getState()["monitor_locations"]["data"]) {
      return Promise.resolve();
    }

    dispatch(getMonitorLocationsInProgress());

    authRequest(apiUrl, dispatch, getState)
      .then(response => {
        dispatch(getMonitorLocationsSuccess(response["regions"], response["order"]));
      })
      .catch(_ => {
        dispatch(getMonitorLocationsFailed());
      });
  };
};
