import React from "react";
import "./AuthPageWrapper.less";
import SideBar from "../../../containers/SideBar";
import { Layout } from "antd";

export default function AuthPageWrapper(props) {
  return (
    <Layout>
      {props.showSideBar && (
        <React.Fragment>
          <SideBar />
          <Layout className="general-layout">{props.children}</Layout>
        </React.Fragment>
      )}

      {!props.showSideBar && props.children}
    </Layout>
  );
}
