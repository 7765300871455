export const APP_VERSION = "1825577";

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";
export const IAM_API_URL = process.env.REACT_APP_IAM_API_URL || "";
export const ANALYSIS_API_URL = process.env.REACT_APP_ANALYSIS_API_URL || "";
export const TRACKED_WEBSITES_API_URL = process.env.REACT_APP_TRACKED_WEBSITES_API_URL || "";
export const SSC_SEM_API_URL = process.env.REACT_APP_SSC_SEM_API_URL || "";
export const LOADING_TIME_API_URL = process.env.REACT_APP_LOADING_TIME_API_URL || "";
export const BROKEN_LINKS_API_URL = process.env.REACT_APP_BROKEN_LINKS_API_URL || "";
export const SITEMAP_API_URL = process.env.REACT_APP_SITEMAP_API_URL || "";
export const OUTAGE_API_URL = process.env.REACT_APP_OUTAGE_API_URL || "";
export const WHITE_LABEL_API_URL = process.env.REACT_APP_WHITE_LABEL_API_URL || "";
export const DOWNLOAD_PDF_API_URL = process.env.REACT_APP_DOWNLOAD_PDF_API_URL || "";
export const SALES_API_URL = process.env.REACT_APP_SALES_API_URL || "";
export const REPORT_RENDER_ENGINE_URL = process.env.REACT_APP_REPORT_RENDER_ENGINE_URL || "";
export const RECURLY_PUBLIC_API_KEY = process.env.REACT_APP_RECURLY_PUBLIC_API_KEY || "";
export const GOOGLE_ANALYTICS_4_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_ID || "";
export const RECAPTCHA_API_KEY = process.env.REACT_APP_RECAPTCHA_API_KEY || "";
export const IAM_COOKIE_DOMAIN = process.env.REACT_APP_IAM_COOKIE_DOMAIN || "";

export const SITEMAP_VIDEO_URL = "https://www.youtube.com/embed/w9yDjgSKJjk";
export const BROKEN_LINKS_VIDEO_URL = "https://www.youtube.com/embed/7ZmJbThFAp0";
export const SSC_TOS_URL = "https://seositecheckup.com/terms-of-service";
export const IS_MENU_COLLAPSED = false;
export const DASHBOARD_LONG_POLLING_INTERVAL_IN_MS = 30000;
export const BACKLINKS_OVERVIEWS_CHART_INTERVAL_LENGTH_IN_WEEKS = 3;
export const IAM_COOKIE_NAME = "ssc.iam";
export const AVERAGE_SEO_SCORE_OF_TOP_100_WEBSITES = 74;
