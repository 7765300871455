import {
  GET_TRACKED_WEBSITES_IN_PROGRESS,
  GET_TRACKED_WEBSITES_SUCCESS,
  GET_TRACKED_WEBSITES_FAILED,
  ADD_TRACKED_WEBSITE_SUCCESS,
  ADD_TRACKED_WEBSITE_IN_PROGRESS,
  ADD_TRACKED_WEBSITE_FAILED,
  CHANGE_CURRENT_WEBSITE,
  CHANGE_TRACKED_WEBSITE_SETTINGS_IN_PROGRESS,
  CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL,
  CHANGE_TRACKED_WEBSITE_SETTINGS_FAILED,
  DELETE_TRACKED_WEBSITE_IN_PROGRESS,
  DELETE_TRACKED_WEBSITE_SUCCESSFUL,
  DELETE_TRACKED_WEBSITE_FAILED,
  GET_TRACKED_WEBSITES_AND_OVERVIEWS_IN_PROGRESS,
  GET_TRACKED_WEBSITES_AND_OVERVIEWS_SUCCESSFUL,
  GET_TRACKED_WEBSITES_AND_OVERVIEWS_FAILED
} from "./types";

import { LOGOUT } from "../iam/types";

const initialState = {
  data: [],
  selected_website: null,
  network_activity: [],
  last_added_website: null
};

const networkActivityMessages = {
  change_settings: "change_tracked_website_settings_"
};

// const getNewSelectedTrackedWebsiteGuid = (oldGuid, trackedWebsites) => {
//   let remainingTrackedWebsites = trackedWebsites.filter(trackedWebsite => trackedWebsite["guid"] !== oldGuid);
//   if (remainingTrackedWebsites.length === 0) {
//     return null;
//   }
//   return remainingTrackedWebsites[0]["guid"];
// };

const trackedWebsitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRACKED_WEBSITES_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, "get_tracked_websites"]
      };
    case GET_TRACKED_WEBSITES_SUCCESS:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "get_tracked_websites"),
        data: action.tracked_websites,
        //selected_website: state.selected_website === null ? action.tracked_websites[0].guid : state.selected_website,
      };
    case GET_TRACKED_WEBSITES_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "get_tracked_websites")
      };
    case ADD_TRACKED_WEBSITE_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, "add_tracked_website"]
      };
    case ADD_TRACKED_WEBSITE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.trackedWebsite],
        network_activity: state.network_activity.filter(item => item !== "add_tracked_website"),
        //selected_website: action.trackedWebsite.guid,
        last_added_website: {
          ...state.last_added_website,
          guid: action.trackedWebsite.guid,
          added_at: Date.now()
        }
      };
    case ADD_TRACKED_WEBSITE_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "add_tracked_website")
      };
    case CHANGE_CURRENT_WEBSITE:
      return {
        ...state,
        selected_website: action.selected_website
      };
    case CHANGE_TRACKED_WEBSITE_SETTINGS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${networkActivityMessages["change_settings"]}${action.guid}_${action.actionName}`
        ]
      };
    case CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          item => item !== `${networkActivityMessages["change_settings"]}${action.guid}_${action.actionName}`
        ),
        data: state.data.map(tracked_website => {
          if (tracked_website.guid === action.guid) {
            return { ...tracked_website, settings: { ...tracked_website.settings, ...action.updatedSettings } };
          } else {
            return tracked_website;
          }
        })
      };
    case CHANGE_TRACKED_WEBSITE_SETTINGS_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          item => item !== `${networkActivityMessages["change_settings"]}${action.guid}_${action.actionName}`
        )
      };
    case DELETE_TRACKED_WEBSITE_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, `delete_website_${action.url}`]
      };
    case DELETE_TRACKED_WEBSITE_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== `delete_website_${action.url}`),
        data: state.data.filter(tracked_website => tracked_website.url !== action.url),
        // selected_website:
        //   state.selected_website === action["guid"]
        //     ? getNewSelectedTrackedWebsiteGuid(state.selected_website, state.data)
        //     : state.selected_website
      };
    case DELETE_TRACKED_WEBSITE_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== `delete_website_${action.url}`)
      };
    case GET_TRACKED_WEBSITES_AND_OVERVIEWS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, `get_tracked_websites_and_overviews`]
      };
    case GET_TRACKED_WEBSITES_AND_OVERVIEWS_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== `get_tracked_websites_and_overviews`)
      };
    case GET_TRACKED_WEBSITES_AND_OVERVIEWS_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== `get_tracked_websites_and_overviews`)
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default trackedWebsitesReducer;
