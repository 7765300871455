import React, { Component } from "react";
import { Register } from "../containers";

export default class RegisterPage extends Component {
  render() {
    return (
      <div>
        <Register />
      </div>
    );
  }
}
