const extractKeywords = txt => {
  let keywords = [];

  txt.split(",").forEach(item => {
    keywords = [...keywords, ...parseKeywords(item)];
  });

  return keywords;
};

const parseKeywords = txt => {
  return txt
    .split(/\r\n|\r|\n/)
    .map(keyword => keyword.trim())
    .filter(keyword => keyword !== "");
};

export { extractKeywords };
