import React from "react";
import { Tag, Icon } from "antd";
import HtmlContent from "./shared/HtmlContent/HtmlContent";

function ImprovementsList(props) {
  const getPriority = (relevance) => {
    let priority;
    if (relevance > 0 && relevance < 4) {
      priority = "low";
    }
    if (relevance > 3 && relevance < 8) {
      priority = "medium";
    }
    if (relevance > 7) {
      priority = "high";
    }
    return priority;
  };

  const getTagByPriority = (relevance) => {
    let tagsByPriority = {
      low: { color: "green", label: "LOW" },
      medium: { color: "orange", label: "MEDIUM" },
      high: { color: "red", label: "HIGH" }
    };
    const priority = getPriority(relevance);

    return tagsByPriority[priority];
  };

  return (
    <div className="to-do-list-container custom-scrollbar">
      {props.importantFixes.map((item) => (
        <div className="to-do-item" key={item["section_key"]}>
          <div className="priority">
            <Tag color={getTagByPriority(item["relevance"])["color"]}>
              {getTagByPriority(item["relevance"])["label"]}
            </Tag>
          </div>
          {props.addLinksToIssues ? (
            <React.Fragment>
              <a className="internal-link" href={`#${item["section_key"]}`}>
                <Icon type="link" />
              </a>
              <HtmlContent content={item["recommendation"]}></HtmlContent>
            </React.Fragment>
          ) : (
            <HtmlContent content={item["recommendation"]} />
          )}
        </div>
      ))}
    </div>
  );
}

export default ImprovementsList;
