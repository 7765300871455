import moment from "moment";
import { getSelectedWebsiteUrl } from "../tracked_websites/selectors";
import { getMonitorLocationValue } from "../monitor_locations/selectors";

export const getOutages = state => {
  let selectedUrl = getSelectedWebsiteUrl(state);

  if (state["outage"] && state["outage"]["data"][selectedUrl]) {
    // const outages = state["outage"]["data"][selectedUrl]["archived"];
    let outages = [];
    try {
        outages = [...state["outage"]["data"][selectedUrl]["archived"]];
    }catch{};
    
    const activeOutage = state["outage"]["data"][selectedUrl]["active"];

    if (activeOutage && Object.keys(activeOutage).length) {
      const now = moment().utc();

      const event = {
        start_at: moment
          .unix(activeOutage["created_at"])
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        end_at: now.format("YYYY-MM-DDTHH:mm:ss"),
        guid: activeOutage["guid"]
      };

      outages.push(event);
    }
    if (outages && outages.length) {
      return groupOutagesByDay(outages);
    }
    return {};
  }
  return {};
};

export const isLoadingList = state => {
  let selectedUrl = getSelectedWebsiteUrl(state);
  return state["outage"]["network_activity"].includes(`fetch_${selectedUrl}`);
};

export const getSelectedOutageGuid = (state, url) => {
  try {
    return state["outage"]["data"][url]["selected_outage"];
  } catch (e) {
    return null;
  }
};

export const isLoadingContext = (state, url) => {
  const guid = getSelectedOutageGuid(state, url);

  if (guid) {
    return state["outage"]["network_activity"].includes(`fetch_${guid}`);
  }
  return false;
};

export const getContext = (state, url) => {
  const guid = getSelectedOutageGuid(state, url);

  if (state["outage"]["by_guid"][guid]) {
    const context = state["outage"]["by_guid"][guid];

    return {
      guid: guid,
      created_at: context["created_at"],
      last_updated: context["last_updated"],
      events: groupEventsFromContext(state, context)
    };
  }
  return {};
};

export const hasOutageData = (state, url) => {
  let hasData = false;
  if (state["outage"]["data"][url]) {
    hasData = true;
  }
  return hasData;
};

export const getOutageContext = (state, guid) => {
  if (state["outage"]["by_guid"][guid]) {
    const context = state["outage"]["by_guid"][guid];

    return {
      guid: guid,
      created_at: context["created_at"],
      last_updated: context["last_updated"],
      events: context["events"],
      url: context["url"]
    };
  }
  return {};
};
function groupEventsFromContext(state, context) {
  let groups = {};
  const events = context["events"];

  if (events && events.length) {
    groups = events.reduce((groups, event) => {
      const region = event["region"];

      if (!Object.keys(groups).includes(region)) {
        groups[region] = {
          name: getMonitorLocationValue(state, region),
          ping: {},
          loading_time: {}
        };
      }
      groups[region]["ping"] = {
        created_at: event["created_at"],
        status: event["value"] ? "up" : "down"
      };
      groups[region]["loading_time"] = {
        created_at: event["created_at"],
        status: event["error"] ? "down" : "up"
      };
      return groups;
    }, {});
  }
  return groups;
}
function groupOutagesByDay(outages) {
  const groups = outages.reduce((groups, outage) => {
    const startDate = outage["start_at"].split("T")[0];
    const endDate = outage["end_at"].split("T")[0];

    if (!groups[startDate]) {
      groups[startDate] = [];
    }

    if (startDate === endDate) {
      groups[startDate].push(outage);
    } else {
      // this is the case when the outage is spreading on 2 diff days
      // we have to split the outage into 2 parts (on part in a diff day)

      const outageFirstPart = {
        start_at: outage["start_at"],
        end_at: `${startDate}T23:59:59`,
        guid: outage["guid"]
      };
      const outageSecondPart = {
        start_at: `${endDate}T00:00:00`,
        end_at: outage["end_at"],
        guid: outage["guid"]
      };

      if (!groups[endDate]) {
        groups[endDate] = [];
      }

      groups[startDate].push(outageFirstPart);
      groups[endDate].push(outageSecondPart);
    }

    return groups;
  }, {});
  return groups;
}
