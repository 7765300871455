import React, { Component } from "react";
import { Button, Modal, Icon } from "antd";
import PropTypes from "prop-types";

class InlineModal extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  render() {
    return (
      <div className={this.props.className ? this.props.className : ""}>
        <span className="inline-modal-trigger fake-link full-list" onClick={this.toggleModal}>
          {this.props.hideCaret ? "" : <Icon type="caret-right" />} {this.props.openLabel}
        </span>
        <Modal
          centered
          className="wide-modal"
          title={this.props.modalTitle}
          visible={this.state.visible}
          onCancel={this.toggleModal}
          footer={null}>
          <div className="modal-content">{this.props.children}</div>
          <div className="button-footer">
            <Button type="secondary" onClick={this.toggleModal}>
              {this.props.closeLabel}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

InlineModal.propTypes = {
  openLabel: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  closeLabel: PropTypes.string.isRequired
};

export default InlineModal;
