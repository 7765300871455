import { createApi } from '@reduxjs/toolkit/query/react';
import { authRequest } from '../store/authorizedRequests';
import { SITEMAP_API_URL } from '../settings/config';
import { FetchSitemapResponse, FetchSitemapQueryParams, GenerateSitemapParams, GenerateSitemapResponse } from './types';

const sitemapBaseUrl = `${SITEMAP_API_URL}`;

const SITEMAP_REFETCH_TIME = 7200;

export const sitemapApi = createApi({
    reducerPath: 'api.sitemap',

    keepUnusedDataFor: SITEMAP_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;
        try {
            const data = await authRequest(sitemapBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchSitemap: builder.query<FetchSitemapResponse, FetchSitemapQueryParams>({
            query: (args) => {
                const { domain, lastUrlHash } = args;

                return {
                    method: 'GET',
                    url: `?domain=${domain}${lastUrlHash ? `&url_hash=${lastUrlHash}` : ''}`,
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { domain } = queryArgs;
                return { domain };
            },

            forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
                // currentArg should always be defined
                if (typeof currentArg === 'undefined') {
                    return false;
                }

                // If there was not a previous fetch then fetch
                if (typeof previousArg === 'undefined') {
                    return true;
                }

                if (endpointState && (endpointState.data as FetchSitemapResponse).urls.length === 0) {
                    return true;
                }

                return currentArg.lastUrlHash && currentArg.lastUrlHash !== previousArg.lastUrlHash ? true : false;
            },

            merge: (currentCacheData, responseData, otherArgs) => {
                return {
                    urls: currentCacheData.urls.concat(responseData.urls),
                    last_url_hash: responseData.last_url_hash,
                };
            },
        }),

        generateSitemap: builder.mutation<GenerateSitemapResponse, GenerateSitemapParams>({
            query: (args) => {
                return {
                    method: 'POST',
                    url: '',
                    body: args,
                };
            },
        }),
    }),
});

export const { useFetchSitemapQuery, useGenerateSitemapMutation } = sitemapApi;
