import React from 'react';
import { Table } from 'antd';
import { TldDistribution } from '../../types';

const { Column } = Table;

type Props = {
    tldDistribution: TldDistribution[];
};

export default function TldDistributionTable({ tldDistribution }: Props) {
    const dataSource = tldDistribution.map((distributionElement, index) => ({ ...distributionElement, key: index + 1 }));

    return (
        <Table
            dataSource={dataSource}
            pagination={false}
            size="small"
            data-cy="tld-distribution-table"
        >
            <Column
                title="#"
                dataIndex="key"
                align="left"
                width={100}
            />
            <Column
                className="no-wrap"
                title="TLD Zone"
                dataIndex="tld"
            />
            <Column
                className="no-wrap al-right"
                title="Backlinks"
                dataIndex="backlinks_num"
                render={(text, record: TldDistribution, index) => record.backlinks_num.toLocaleString()}
            />
        </Table>
    );
}
