import React from "react";
import { Card, Table, Empty } from "antd";
import moment from "moment";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";

const { Column } = Table;

function Reports(props) {
  const tableDataSource = props.overviews.map((overview, idx) => ({
    key: idx + 1,
    createdAt: moment(overview["created_at"]).format("MMM DD, YYYY"),
    score: overview["score"],
    passed: overview["passed"],
    failed: overview["failed"],
    warning: overview["warning"],
    guid: overview["guid"]
  }));

  const isLoadingState = props.isLoading;
  const hasOverviews = props.overviews.length > 0 && !props.isLoading;
  const doesNotHaveOverviews = props.overviews.length === 0 && !props.isLoading;
  return (
    <div className="archived-reports-box">
      <Card
        title={
          <div className="ant-card-meta-detail">
            <div className="ant-card-meta-title">Archived reports</div>
            <div className="ant-card-meta-description">{props.date}</div>
          </div>
        }>
        {isLoadingState && <LoadingIndicator />}
        {hasOverviews && (
          <Table className="archived-reports-table" dataSource={tableDataSource} size="small" pagination={false}>
            <Column title="#" dataIndex="key" align="left" width="50px" />
            <Column title="Created at" dataIndex="createdAt" />
            <Column className="no-wrap" title="Score" dataIndex="score" align="right" />
            <Column className="no-wrap" title="Passed" dataIndex="passed" align="right" />
            <Column className="no-wrap" title="Failed" dataIndex="failed" align="right" />
            <Column className="no-wrap" title="Warnings" dataIndex="warning" align="right" />
            <Column
              className="no-wrap view-saved-report"
              title="Action"
              dataIndex="viewReport"
              align="right"
              render={(_, record) => (
                <a
                  href={`/analysis/report?guid=${record["guid"]}&url=${props.url}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  View report
                </a>
              )}
            />
          </Table>
        )}
        {doesNotHaveOverviews && (
          <Empty
            className="empty-state"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No saved reports for selected interval were found"
          />
        )}
      </Card>
    </div>
  );
}

export default Reports;
