import React from "react";
import TrendIcon from "./TrendIcon";
import SummaryTooltip from "./SummaryTooltip";

function SeoScoreSummary({seoScores}) {
    let firstSeoScore = seoScores[0];
    let lastSeoScore = seoScores[seoScores.length - 1];

    return (
        <div className="dashboard-summary-section">
            <div className="dashboard-summary-section-title">
                SEO Score <SummaryTooltip resourceType={"SEO score"} first={firstSeoScore} last={lastSeoScore} />
            </div>
            <div className="dashboard-summary-section-result">
                {   seoScores.length > 0
                    ?
                        <React.Fragment>
                            <TrendIcon resourceType={"SEO score"} first={firstSeoScore} last={lastSeoScore} />
                             {' '}{lastSeoScore}
                        </React.Fragment>
                        :
                        <span>No data</span>
                }
            </div>
        </div>
    )
}

export default SeoScoreSummary;
