import './BrokenLinks.less';
import React from 'react';
import { Tabs, Card, Row, Col } from 'antd';
import { TrackedWebsite } from '../trackedWebsites/types';
import { BROKEN_LINKS_VIDEO_URL } from '../settings/config';
import BrokenLinksTable from './BrokenLinksTable';

const { TabPane } = Tabs;

const linksTypes = [
    { title: 'Internal', key: 'internal' as const },
    { title: 'External', key: 'external' as const },
];

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function BrokenLinksTabs({ selectedWebsite }: Props) {
    return (
        <div className="broken-links">
            <Row gutter={20}>
                <Col span={16}>
                    <Card>
                        <Tabs
                            type="card"
                            tabBarGutter={10}
                            destroyInactiveTabPane={true}
                        >
                            {linksTypes.map((type) => (
                                <TabPane
                                    tab={type.title}
                                    key={type.key}
                                    data-cy={type.key}
                                    className="broken-links-container"
                                >
                                    <BrokenLinksTable
                                        domain={selectedWebsite.domain}
                                        type={type.key}
                                    />
                                </TabPane>
                            ))}
                        </Tabs>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        className="broken-links-how-to-card"
                        title="How to find and fix broken links"
                    >
                        <iframe
                            title="video"
                            width="100%"
                            height="320"
                            src={BROKEN_LINKS_VIDEO_URL}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
