import './Timeline.less';
import React from 'react';
import { HoursInterval, OutageGroups } from '../types';
import ReportCardTitle from '../../components/shared/ReportCardTitle/ReportCardTitle';
import moment from 'moment';
import DaysTimeline from './DaysTimeline';
import HoursTimeline from './HoursTimeline';

type Props = {
    outagesGroupedByDays: OutageGroups;
    selectedDay: string;
    selectedInterval: HoursInterval;
    outageIntervals: HoursInterval[];
    startDate: string;
    endDate: string;
    onOutageIntervalChange: (newInterval: HoursInterval) => void;
    onSelectedDayChange: (newSelectedDay: string) => void;
};

export default function OutageTimelines({
    outagesGroupedByDays,
    selectedDay,
    selectedInterval,
    outageIntervals,
    startDate,
    endDate,
    onOutageIntervalChange,
    onSelectedDayChange,
}: Props) {
    return (
        <div className="outage-timeline-container">
            <div className="flexbox space-between flex-wrap">
                <ReportCardTitle
                    title="Outage overview"
                    domain={moment(selectedDay).format('MMM DD, YYYY')}
                />

                <DaysTimeline
                    outagesGroupedByDay={outagesGroupedByDays}
                    selectedDay={selectedDay}
                    startDate={startDate}
                    endDate={endDate}
                    onChangeSelectedDay={onSelectedDayChange}
                />
            </div>

            <HoursTimeline
                outageIntervals={outageIntervals}
                selectedInterval={selectedInterval}
                onChangeSelectedInterval={onOutageIntervalChange}
            />
        </div>
    );
}
