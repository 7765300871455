import { useAppDispatch } from '../../useAppDispatch';
import { useCallback } from 'react';

/**
 * FILLER HOOK FOR OLD REDUX LOGIC.
 * @returns
 */
export function useSetSelectedWebsiteForOldRedux() {
    const dispatch = useAppDispatch();

    const setSelectedWebsite = useCallback(
        (guid: string | null) => {
            dispatch({ type: 'CHANGE_CURRENT_WEBSITE', selected_website: guid });
        },
        [dispatch]
    );

    return {
        setSelectedWebsiteForOldRedux: setSelectedWebsite,
    };
}
