import React from "react";
import { Layout } from "antd";
import SubscriptionPlans from "../containers/Sales/SubscriptionPlans";
const { Content } = Layout;

const SubscriptionPlansPage = () => {
  return (
    <Content className="general-layout-content">
      <SubscriptionPlans />
    </Content>
  );
};

export default SubscriptionPlansPage;
