import React, { Component } from "react";
import { Card, Icon } from "antd";
import DashboardBox from "../DashboardBox";
import DashboardCardTitle from "../DashboardCardTitle";
import SeoScoreProgressChart from "./SeoScoreProgressChart";
import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";

export class SeoScoreProgressBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const firstFetchIsInProgress =
      !prevProps.isFetching && this.props.isFetching && prevState.loading === null && this.state.loading === null;
    const fetchIsFinished = prevProps.isFetching && !this.props.isFetching;

    if (firstFetchIsInProgress && !this.props.hasData) {
      this.setState({ loading: true });
    }
    if ((firstFetchIsInProgress && this.props.hasData) || fetchIsFinished) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading === null) {
      return null;
    }

    return (
      <div className="dashboard-seo-score-progress-box">
        {this.state.loading === true && <DashboardBox />}

        {this.state.loading === false && (
          <Card
            data-cy="seo-score-progress-box"
            title={
              <DashboardCardTitle
                title="SEO Score Progress"
                description={this.props.customInterval || "Over the last 7 days"}
              />
            }
            extra={
              <div className="seo-score-progress-links flexbox">
                <div
                  className="card-link flexbox align-center"
                  onClick={() => this.props.push("/dashboard/settings/competitors")}>
                  <Icon type={this.props.competitors.length > 0 ? "edit" : "plus-circle"} />
                  <span>{this.props.competitors.length > 0 ? "Edit" : "Add"} competitors</span>
                </div>
                {this.props.lastSavedReportGuid && (
                  <div
                    className="card-link flexbox align-center"
                    onClick={() =>
                      this.props.push(`/analysis/report?guid=${this.props.lastSavedReportGuid}&url=${this.props.url}`)
                    }>
                    <span>View report</span>
                    <Icon type="right" />
                  </div>
                )}
              </div>
            }>
            {this.props.isWebsiteLastAddedAndHasNoSeoReportData ? (
              <LoadingIndicator
                detailed
                text="Currently running the report..."
                subTitle="Please check back for data later"
              />
            ) : (
              <SeoScoreProgressChart
                mainUrl={this.props.url}
                competitors={this.props.competitors}
                chartData={this.props.chartData}
              />
            )}
          </Card>
        )}
      </div>
    );
  }
}

export default SeoScoreProgressBox;
