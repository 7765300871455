import {
  CREATE_SUBSCRIPTION_IN_PROGRESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILED,
  START_3D_SECURE,
  FETCH_SUBSCRIPTION_PLANS_FAILED,
  FETCH_SUBSCRIPTION_PLANS_IN_PROGRESS,
  FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  FETCH_SUBSCRIPTION_FAILED,
  FETCH_SUBSCRIPTION_IN_PROGRESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_IN_PROGRESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  FETCH_BILLING_INFO_FAILED,
  FETCH_BILLING_INFO_IN_PROGRESS,
  FETCH_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_INFO_FAILED,
  UPDATE_BILLING_INFO_IN_PROGRESS,
  UPDATE_BILLING_INFO_SUCCESS,
  FETCH_INVOICES_FAILED,
  FETCH_INVOICES_IN_PROGRESS,
  FETCH_INVOICES_SUCCESS,
  FETCH_PDF_INVOICE_FAILED,
  FETCH_PDF_INVOICE_IN_PROGRESS,
  FETCH_PDF_INVOICE_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION_IN_PROGRESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_3DSECURE_ERROR_MESSAGE
} from "./types";

import { LOGOUT } from "../iam/types";

const initialState = {
  network_activity: [],
  subscription: {},
  three_d_secure: {},
  plans: {},
  billing_info: null,
  invoices: null
};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTION_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "create_subscription"],
        three_d_secure: {
          ...state.three_d_secure,
          error: null
        },
        subscription: {
          ...state.subscription,
          error: null
        }
      };
    }
    case CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "create_subscription"),
        subscription: action.subscription
      };
    }
    case START_3D_SECURE: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "create_subscription"),
        three_d_secure: action.subscription
      };
    }
    case CREATE_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "create_subscription"),
        subscription: action.error,
        three_d_secure: {}
      };
    }
    case UPDATE_SUBSCRIPTION_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "update_subscription"]
      };
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "update_subscription"),
        subscription: action.subscription
      };
    }
    case UPDATE_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "update_subscription"),
        subscription: action.error
      };
    }
    case CANCEL_SUBSCRIPTION_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "cancel_subscription"]
      };
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "cancel_subscription"),
        subscription: action.subscription
      };
    }
    case CANCEL_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "cancel_subscription")
      };
    }
    case FETCH_SUBSCRIPTION_PLANS_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "fetch_plans"]
      };
    }
    case FETCH_SUBSCRIPTION_PLANS_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_plans"),
        plans: action.plans
      };
    }
    case FETCH_SUBSCRIPTION_PLANS_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_plans")
      };
    }
    case FETCH_SUBSCRIPTION_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "fetch_subscription"]
      };
    }
    case FETCH_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_subscription"),
        subscription: action.subscription
      };
    }
    case FETCH_SUBSCRIPTION_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_subscription")
      };
    }
    case FETCH_BILLING_INFO_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "fetch_billing_info"]
      };
    }
    case FETCH_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_billing_info"),
        billing_info: action.billing_info
      };
    }
    case FETCH_BILLING_INFO_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_billing_info"),
        billing_info: {}
      };
    }
    case UPDATE_BILLING_INFO_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "update_billing_info"]
      };
    }
    case UPDATE_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "update_billing_info"),
        billing_info: action.billing_info,
        three_d_secure: {}
      };
    }
    case UPDATE_BILLING_INFO_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "update_billing_info")
      };
    }
    case FETCH_INVOICES_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "fetch_invoices"]
      };
    }
    case FETCH_INVOICES_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_invoices"),
        invoices: action.invoices
      };
    }
    case FETCH_INVOICES_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_invoices")
      };
    }
    case FETCH_PDF_INVOICE_IN_PROGRESS: {
      return {
        ...state,
        network_activity: [...state.network_activity, "fetch_pdf_invoice"]
      };
    }
    case FETCH_PDF_INVOICE_SUCCESS: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_pdf_invoice")
      };
    }
    case FETCH_PDF_INVOICE_FAILED: {
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== "fetch_pdf_invoice")
      };
    }
    case UPDATE_3DSECURE_ERROR_MESSAGE: {
      return {
        ...state,
        three_d_secure: { error: action.msg }
      };
    }
    case LOGOUT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default salesReducer;
