import './Sitemap.less';
import React, { useState } from 'react';
import { TrackedWebsite } from '../trackedWebsites/types';
import { useFetchSitemapQuery } from './service';
import useDownloadSitemap from './hooks/useDownloadSitemap';
import { createSitemapStructure } from './utils';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import { Button, Card, Col, Icon, Modal, Result, Row } from 'antd';
import SyntaxHighlighter from './syntaxHighlighter/SyntaxHighlighter';
import { SITEMAP_VIDEO_URL } from '../settings/config';

function CardTitle({ domain }: { domain: string }) {
    return (
        <React.Fragment>
            <h3>sitemap.xml</h3>
            <span>{domain}</span>
        </React.Fragment>
    );
}

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function Sitemap({ selectedWebsite }: Props) {
    const [lastUrlHash, setLastUrlHash] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const {
        data: sitemapData,
        isLoading,
        isFetching: isLoadingMoreUrls,
    } = useFetchSitemapQuery({ domain: selectedWebsite.domain, lastUrlHash: lastUrlHash ? lastUrlHash : '' });

    const { downloadSitemap, isDownloadingSitemap } = useDownloadSitemap();

    const handleLoadMore = () => {
        if (sitemapData && sitemapData.last_url_hash) {
            setLastUrlHash(sitemapData.last_url_hash);
        }
    };

    const handleDownloadSitemap = () => {
        downloadSitemap(selectedWebsite.domain).then(() => {
            setIsModalVisible(true);
        });
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const sitemapStructure = sitemapData ? createSitemapStructure(sitemapData.urls) : '';

    return (
        <div className="sitemap">
            {isLoading && <LoadingIndicator />}

            {!isLoading && sitemapData && sitemapData.urls.length === 0 && (
                <Result
                    icon={
                        <Icon
                            type="file-search"
                            theme="outlined"
                        />
                    }
                    title="We're crawling your website..."
                />
            )}

            {!isLoading && sitemapData && sitemapData.urls.length > 0 && (
                <>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Card
                                className="sitemap-syntax-highlighter-card"
                                title={<CardTitle domain={selectedWebsite.domain} />}
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={handleDownloadSitemap}
                                        loading={isDownloadingSitemap}
                                    >
                                        Download
                                    </Button>
                                }
                            >
                                <div className={`syntax-highlighter-overlay ${isLoadingMoreUrls ? 'loading' : ''}`}>
                                    <LoadingIndicator />
                                </div>
                                <SyntaxHighlighter
                                    className="syntax-highlighter-container custom-scrollbar"
                                    sitemap={sitemapStructure}
                                    onLoadMoreUrls={handleLoadMore}
                                />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card
                                className="sitemap-how-to-card"
                                title="Adding or updating your sitemap"
                                extra='Place the generated "sitemap.xml" file in the root folder of your website'
                            >
                                <iframe
                                    title="video"
                                    width="100%"
                                    height="320"
                                    src={SITEMAP_VIDEO_URL}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        centered
                        className="wide-modal sitemap-how-to-add-modal"
                        title="Congrats on generating your sitemap.xml"
                        visible={isModalVisible}
                        onCancel={handleCloseModal}
                        footer={null}
                    >
                        <div className="modal-content no-padding">
                            {isModalVisible && (
                                <iframe
                                    title="video"
                                    width="100%"
                                    height="320"
                                    src={SITEMAP_VIDEO_URL}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            )}
                            <div className="default-padding">
                                <h3>How to add your sitemap file to your website?</h3>
                                <ul>
                                    <li>
                                        It is strongly recommended that you place your sitemap at the root directory of your website:{' '}
                                        <strong>http://yourwebsite.com/sitemap.xml</strong>. But in some situations, you may want to produce different sitemaps
                                        for different paths on your site (e.g., security permission issues)
                                    </li>
                                    <li>
                                        Sitemaps should be no larger than <strong>10MB (10,485,760 bytes)</strong> and can contain a maximum of{' '}
                                        <strong>50,000 URLs</strong>. This means that if your site contains more than 50,000 URLs or your sitemap is bigger than
                                        10MB, you must create multiple sitemap files and use a Sitemap index file
                                    </li>
                                    <li>
                                        All URLs listed in the sitemap must reside on the same host as the sitemap. For instance, if the sitemap is located at{' '}
                                        <strong>http://www.yourwebsite.com/sitemap.xml</strong>, it can't include URLs from{' '}
                                        <strong>http://subdomain.yourwebsite.com</strong>
                                    </li>
                                    <li>
                                        Once you have created your sitemap, let search engines know about it by submitting directly to them, pinging them, or
                                        adding the sitemap location to your robots.txt file
                                    </li>
                                    <li>
                                        Sitemaps can be compressed using <strong>gzip</strong>, reducing bandwidth consumption
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="button-footer">
                            <Button
                                type="default"
                                onClick={handleCloseModal}
                            >
                                Close
                            </Button>
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
}
