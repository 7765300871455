import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Checkbox, Icon, Alert } from "antd";
import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  useRecurly
} from "@recurly/react-recurly";
import { SSC_TOS_URL } from "../../settings/config";
import "./CreditCardForm.less";

const InputGroup = Input.Group;

function CreditCardForm(props) {
  const recurly = useRecurly();
  const formRef = React.useRef();

  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  const [validationErrors, setValidationErrors] = useState({});
  const [cardNumberValidation, setCardNumberValidation] = useState({});
  const [cardMonthValidation, setCardMonthValidation] = useState({});
  const [cardYearValidation, setCardYearValidation] = useState({});
  const [cvcValidation, setCvcValidation] = useState({});
  const [acceptTos, setAcceptTos] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    setErrorMessage(props.errorMessage);
  }, [props.errorMessage]);

  const onAcceptTos = _ => {
    setAcceptTos(!acceptTos);
    setValidationErrors(st => ({ ...st, acceptTos: false }));
  };

  const handleCardNumberChange = change => {
    setCardNumberValidation(change);
  };

  const handleCardMonthChange = change => {
    setCardMonthValidation(change);
  };

  const handleCardYearChange = change => {
    setCardYearValidation(change);
  };

  const handleCvcChange = change => {
    setCvcValidation(change);
  };

  const handleChangeFirstName = e => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = e => {
    setLastName(e.target.value);
  };

  const handleChangeZipCode = e => {
    setZipCode(e.target.value);
  };

  const handleFocus = e => {
    if (e.type === "month" || e.type === "year") {
      setValidationErrors(st => ({ ...st, expirationDate: false }));
    }
    if (e.type === "number") {
      setValidationErrors(st => ({ ...st, number: false }));
    }
    if (e.type === "cvv") {
      setValidationErrors(st => ({ ...st, cvc: false }));
    }
  };

  const handleNameFocus = event => {
    const name = event.target.name;
    if (name === "first_name") {
      setValidationErrors(st => ({ ...st, firstName: false }));
    }

    if (name === "last_name") {
      setValidationErrors(st => ({ ...st, lastName: false }));
    }
  };

  const handleZipCodeFocus = event => {
    setValidationErrors(st => ({ ...st, zipCode: false }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    let hasErrors = false;

    if (!cardNumberValidation.valid) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, number: true }));
    }
    if (!cardMonthValidation.valid || !cardYearValidation.valid) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, expirationDate: true }));
    }
    if (!cvcValidation.valid) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, cvc: true }));
    }
    if (firstName.length === 0) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, firstName: true }));
    }
    if (lastName.length === 0) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, lastName: true }));
    }
    if (zipCode.length === 0) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, zipCode: true }));
    }
    if (!acceptTos) {
      hasErrors = true;
      setValidationErrors(st => ({ ...st, acceptTos: true }));
    }

    if (!hasErrors) {
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          let formSubmitError = "Credit card validation failed!";
          formSubmitError = err.message
            ? `${formSubmitError} ${err.message}`
            : " Please check your information and try again or contact our support team!";

          setErrorMessage(formSubmitError);
        } else {
          props.onSubmit({
            token: token.id,
            payment_method: token.type
          });
        }
      });
    }
  };

  return (
    <React.Fragment>
      {errorMessage && (
        <div className="card-validation">
          <Alert type="error" showIcon message={errorMessage} />
        </div>
      )}

      <form className="credit-card-form" onSubmit={handleSubmit} ref={formRef}>
        {props.showAvailableCards && (
          <Form.Item className="accepted-cards" label="Accepted cards">
            <img src="/images/visa_light.png" alt="Visa" title="Visa" />
            <img src="/images/mastercard_light.png" alt="MasterCard" title="MasterCard" />
            <img src="/images/american_express_light.png" alt="American Express" title="American Express" />
            <img src="/images/jcb_light.png" alt="JCB" title="JCB" />
            <img src="/images/discover_light.png" alt="Discover" title="Discover" />
            <img src="/images/dinners_club_light.png" alt="Diners Club" title="Diners Club" />
          </Form.Item>
        )}

        <Form.Item
          className="card-number"
          validateStatus={validationErrors["number"] ? "error" : "validating"}
          help={validationErrors["number"] ? "Invalid card number" : ""}>
          <CardNumberElement
            className="card-number-input"
            onFocus={handleFocus}
            onChange={handleCardNumberChange}
            onSubmit={handleSubmit}
            displayIcon={true}
            style={{
              placeholder: { content: "Card number", color: "rgba(66,71,71,.64)", fontWeight: "300" }
            }}
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <div className="card-expiration-date">
                <Form.Item
                  validateStatus={validationErrors["expirationDate"] ? "error" : "validating"}
                  help={validationErrors["expirationDate"] ? "Invalid expiration date" : ""}>
                  <InputGroup compact>
                    <Row>
                      <Col span={12}>
                        <CardMonthElement
                          className="card-month-input"
                          onFocus={handleFocus}
                          onSubmit={handleSubmit}
                          onChange={handleCardMonthChange}
                          inputType="select"
                          style={{
                            placeholder: { content: "MM", color: "rgba(66,71,71,.64)", fontWeight: "300" }
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <CardYearElement
                          className="card-year-input"
                          onFocus={handleFocus}
                          onSubmit={handleSubmit}
                          onChange={handleCardYearChange}
                          inputType="select"
                          style={{
                            placeholder: { content: "YY", color: "rgba(66,71,71,.64)", fontWeight: "300" }
                          }}
                        />
                      </Col>
                    </Row>
                  </InputGroup>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="card-verification-code">
                <Form.Item
                  validateStatus={validationErrors["cvc"] ? "error" : "validating"}
                  help={validationErrors["cvc"] ? "Invalid CVV" : ""}>
                  <CardCvvElement
                    className="card-cvv-input"
                    onFocus={handleFocus}
                    onSubmit={handleSubmit}
                    onChange={handleCvcChange}
                    style={{
                      placeholder: { content: "CVV", color: "rgba(66,71,71,.64)", fontWeight: "300" }
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              className="first-name-input"
              validateStatus={validationErrors["firstName"] ? "error" : "validating"}
              help={validationErrors["firstName"] ? "Invalid name" : ""}>
              <Input
                name="first_name"
                data-recurly="first_name"
                placeholder="First name"
                spellCheck="false"
                onChange={e => handleChangeFirstName(e)}
                onFocus={handleNameFocus}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="last-name-input"
              validateStatus={validationErrors["lastName"] ? "error" : "validating"}
              help={validationErrors["lastName"] ? "Invalid name" : ""}>
              <Input
                name="last_name"
                data-recurly="last_name"
                placeholder="Last name"
                spellCheck="false"
                onChange={e => handleChangeLastName(e)}
                onFocus={handleNameFocus}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="zip-code-input"
              validateStatus={validationErrors["zipCode"] ? "error" : "validating"}
              help={validationErrors["zipCode"] ? "Invalid ZIP code" : ""}>
              <Input
                name="postal_code"
                data-recurly="postal_code"
                placeholder="ZIP code"
                spellCheck="false"
                onChange={e => handleChangeZipCode(e)}
                onFocus={handleZipCodeFocus}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.Item
            className="tos-form-item"
            validateStatus={validationErrors["acceptTos"] ? "error" : "validating"}
            help={validationErrors["acceptTos"] ? "You must agree with our terms of service!" : ""}>
            <Checkbox className="accept-tos-checkbox" onChange={onAcceptTos}>
              I accept the&nbsp;
              <a href={SSC_TOS_URL} target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item className="place-order-form-item">
            <Button
              className={`place-order ${!acceptTos ? "disabled" : ""}`}
              type="primary"
              htmlType="submit"
              block
              loading={props.isSubmitting}>
              {props.buttonLabel}
              <Icon type="lock" theme="filled" />
            </Button>
          </Form.Item>
        </Row>
      </form>
    </React.Fragment>
  );
}
export default CreditCardForm;
