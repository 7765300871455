import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import AddNewTrackedWebsite from './AddNewTrackedWebsite';

type Props = {
    label?: string;
};

export default function AddNewTrackedWebsiteButton({ label }: Props) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    return (
        <div>
            <Tooltip
                placement="topRight"
                title="Track new website"
            >
                <Button
                    type="primary"
                    icon="plus"
                    onClick={toggleModal}
                    data-cy="add-new-tracked-website"
                >
                    {label ? label : null}
                </Button>
            </Tooltip>

            <Modal
                className="tracked-websites-wizard-modal"
                visible={isModalVisible}
                title="Add new website"
                footer={null}
                onCancel={toggleModal}
                destroyOnClose={true}
                maskClosable={false}
            >
                <AddNewTrackedWebsite
                    fromOnboarding={false}
                    onFinishAddedWebsite={toggleModal}
                />
            </Modal>
        </div>
    );
}
