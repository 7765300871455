import { Component } from "react";
import { connect } from "react-redux";
import { notification } from "antd";
import { getNotifications } from "../store/notifications/selectors";
import { removeNotification } from "../store/notifications/creators";

notification.config({
  placement: "bottomLeft",
  duration: 5
});

export class Notifications extends Component {
  constructor(props) {
    super(props);

    this.openNotification = this.openNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  closeNotification(id) {
    this.props.removeNotification(id);
  }

  openNotification(type, message, id, title) {
    notification[type]({
      className: "custom-notification",
      message: title,
      description: message,
      key: id,
      onClose: () => this.closeNotification(id)
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.notifications.length > prevProps.notifications.length) {
      let notification = this.props.notifications[this.props.notifications.length - 1];
      this.openNotification(notification["type"], notification["message"], notification["id"], notification["title"]);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

export default connect(mapStateToProps, { removeNotification })(Notifications);
