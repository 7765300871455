import fetch from "cross-fetch";
import pLimit from "p-limit";
import { push } from "connected-react-router";
import moment from "moment";
import { authRequest } from "../authorizedRequests";
import {
  initAnalysisInProgress,
  initAnalysisSuccessful,
  createReportSuccessful,
  createReportFailed,
  createCompetitorReportSuccessful,
  createCompetitorReportFailed,
  getAnalysisReportStructureInProgress,
  getAnalysisReportStructureSuccessful,
  getAnalysisReportStructureFailed,
  getReportSectionInProgress,
  getReportSectionSuccessful,
  getReportSectionFailed,
  getCompetitorReportSectionInProgress,
  getCompetitorReportSectionSuccessful,
  getCompetitorReportSectionFailed,
  saveReportInProgress,
  saveReportSuccess,
  saveReportFailed,
  fetchSavedReportInProgress,
  fetchSavedReportSuccessful,
  fetchSavedReportFailed,
  fetchOverviewsInProgress,
  fetchOverviewsSuccessful,
  fetchOverviewsFailed,
  fetchLastSavedReportInProgress,
  fetchLastSavedReportFinished,
  fetchDomainUrlsInProgress,
  fetchDomainUrlsSuccessful,
  fetchDomainUrlsFailed,
  clearReportInProgress,
  startBackgroundAnalysisFailed,
  startBackgroundAnalysisInProgress,
  startBackgroundAnalysisSuccess,
  fetchOverviewsForMainUrlAndCompetitorsInProgress,
  fetchOverviewsForMainUrlAndCompetitorsFinished
} from "./creators";
import { addNotificationWithTimeout } from "../notifications/operations";
import { ANALYSIS_API_URL } from "../../settings/config";
import {
  getFlatSectionsList,
  getSeoScoreForMainUrl,
  getMaxRelevancePoints,
  getSectionsStatusCounter,
  getSeoScoreForCompetitors,
  getCompetitorsSectionsStatusCounter,
  getOverviewsForUrl
} from "./selectors";
import { getTrackedWebsiteCompetitors } from "../tracked_websites/selectors";
import { getAccessToken } from "../iam/selectors";

let abortController;

const getAnalysisReportGeneralStructure = (_) => {
  return (dispatch, getState) => {
    const apiUrl = `${ANALYSIS_API_URL}/report/sections?fields=key,title,relevance`;
    const sections = getState().analysis.sections;

    if (Object.keys(sections).length) {
      return Promise.resolve();
    }

    dispatch(getAnalysisReportStructureInProgress());
    return authRequest(apiUrl, dispatch, getState)
      .then((response) => {
        dispatch(getAnalysisReportStructureSuccessful(response));
      })
      .catch((_) => {
        dispatch(getAnalysisReportStructureFailed());
      });
  };
};

const initAnalysis = (temp_guid, main_url, competitor_urls, sections) => {
  return (dispatch, getState) => {
    dispatch(initAnalysisInProgress(temp_guid, main_url, competitor_urls, sections));

    const mainUrlPromise = createMainUrlReportPromise(
      dispatch,
      getState,
      temp_guid,
      main_url,
      competitor_urls,
      sections
    );
    const competitorUrlsPromises = competitor_urls.map((competitor_url) =>
      createCompetitorUrlReportPromise(dispatch, getState, temp_guid, competitor_url, sections)
    );
    const createReportsPromises = [mainUrlPromise, ...competitorUrlsPromises];

    return Promise.all(createReportsPromises).then((responses) => {
      responses.forEach((response) => {
        if (response.url === main_url && !("error" in response)) {
          let created_at = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

          dispatch(initAnalysisSuccessful(temp_guid, response.guid, created_at));
          dispatch(push(`/analysis/report?guid=${response.guid}&url=${response.url}`));

          let competitor_guids = Object.keys(getState().analysis.reports[response.guid].competitors);
          let sections = getState().analysis.reports[response.guid].sections;

          dispatch(startAnalysis(response.guid, main_url, sections, competitor_guids));
        }
      });
    });
  };
};

// TODO: refactor/unify createMainUrlReportPromise and createCompetitorUrlReportPromise methods
const createMainUrlReportPromise = (dispatch, getState, temp_guid, url, competitor_urls, sections) => {
  const accessToken = getAccessToken(getState());
  return createReport(url, sections, accessToken).then((response) => {
    if (!("error" in response)) {
      dispatch(createReportSuccessful(response.url, temp_guid, response.sections, competitor_urls));
      //dispatch(addNotificationWithTimeout("success", `Report generated successfully for ${response.url}`));
    } else {
      dispatch(createReportFailed(temp_guid, url));
      dispatch(
        addNotificationWithTimeout(
          "error",
          `We cannot access ${url} in order to perform our test! Either the site is not online, or our tool is being blocked by your server.`
        )
      );
      dispatch(push("/analysis"));

      //abort createReport for competitors
      if (abortController) {
        abortController.abort();
      }
    }
    return response;
  });
};

const createCompetitorUrlReportPromise = (dispatch, getState, temp_guid, url, sections) => {
  const accessToken = getAccessToken(getState());
  return createReport(url, sections, accessToken).then((response) => {
    if (!("error" in response)) {
      dispatch(createCompetitorReportSuccessful(temp_guid, response.url, response.guid));
      //dispatch(addNotificationWithTimeout("success", `Competitor report generated successfully for ${response.url}`));
    } else {
      dispatch(createCompetitorReportFailed(temp_guid, url));
      dispatch(addNotificationWithTimeout("error", `Something went wrong when generating report for ${url}`));
    }
    return response;
  });
};

export const createReport = (url, sections, accessToken) => {
  abortController = new AbortController();

  const options = {
    signal: abortController.signal,
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ url: url, sections: sections })
  };

  return fetch(`${ANALYSIS_API_URL}/report`, options)
    .then(
      (response) => {
        if (response.status !== 201) {
          throw new Error(`Something went wrong, status code ${response.status}`);
        }
        return response.json();
      },
      (error) => {
        throw new Error(`Network error: `, error);
      }
    )
    .catch((err) => {
      if (err.name !== "AbortError") {
        return {
          url: url,
          message: `Create report error for ${url}`,
          error: err
        };
      }
    });
};

export const startAnalysis = (guid, url, sections, competitorGuids) => {
  return (dispatch, getState) => {
    const concurrency = competitorGuids ? competitorGuids.length + 5 : 5;
    const limit = pLimit(concurrency);

    const competitors = [];
    const requests = [];

    sections.forEach((section) => {
      requests.push(limit((_) => dispatch(getSectionResult(guid, url, section))));
      competitorGuids.forEach((competitorGuid) => {
        let competitorUrl = getState().analysis.reports[guid].competitors[competitorGuid].url;
        requests.push(limit((_) => dispatch(getSectionResult(competitorGuid, competitorUrl, section))));
      });
    });

    Promise.all(requests).then((responses) => {
      const sectionsList = getFlatSectionsList(getState());
      const hasAllSections = sectionsList.length === sections.length ? true : false; //TODO: check if sections.length === 0
      const seoScore = getSeoScoreForMainUrl(getState(), guid, getMaxRelevancePoints(getState(), guid));
      const competitorsScores = getSeoScoreForCompetitors(getState(), guid);
      const sectionsStatusCounter = getSectionsStatusCounter(getState(), guid);
      const competitorsSectionsStatusCounter = getCompetitorsSectionsStatusCounter(getState(), guid);

      competitorGuids.forEach((competitorGuid) => {
        let competitorUrl = getState().analysis.reports[guid].competitors[competitorGuid].url;
        competitors.push({
          url: competitorUrl,
          guid: competitorGuid,
          overview: {
            has_all_sections: hasAllSections,
            score: competitorsScores[competitorUrl],
            failed: competitorsSectionsStatusCounter[competitorUrl]["failed"],
            warning: competitorsSectionsStatusCounter[competitorUrl]["warning"],
            passed: competitorsSectionsStatusCounter[competitorUrl]["passed"]
          }
        });
      });

      dispatch(clearReportInProgress(guid));

      const overview = {
        has_all_sections: hasAllSections,
        score: seoScore,
        failed: sectionsStatusCounter["failed"],
        warning: sectionsStatusCounter["warning"],
        passed: sectionsStatusCounter["passed"]
      };

      dispatch(saveReport(guid, url, overview, competitors));
    });
  };
};

const saveReport = (guid, url, overview, competitors) => {
  return (dispatch, getState) => {
    dispatch(saveReportInProgress(guid));

    const payload = { url, guid, overview, competitors };
    const apiUrl = `${ANALYSIS_API_URL}/report/v2/save`;

    authRequest(apiUrl, dispatch, getState, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        dispatch(saveReportSuccess(response.guid));
      })
      .catch((_) => {
        dispatch(saveReportFailed(guid));
      });
  };
};

const getSectionResult = (guid, url, section) => {
  return (dispatch, getState) => {
    const apiUrl = `${ANALYSIS_API_URL}/report/${guid}/${section}`;
    const report_guid = getState().analysis.report_in_progress;

    if (guid === report_guid) {
      dispatch(getReportSectionInProgress(guid, url, section));
    } else {
      dispatch(getCompetitorReportSectionInProgress(report_guid, guid, url, section));
    }

    return authRequest(apiUrl, dispatch, getState)
      .then((section_result) => {
        if (guid === report_guid) {
          if (section === "mobile-snapshot" && !section_result["result"]["snapshot_url"]) {
            dispatch(getReportSectionFailed(guid, url, section));
          } else {
            dispatch(getReportSectionSuccessful(guid, url, section, section_result));
          }
        } else {
          dispatch(getCompetitorReportSectionSuccessful(report_guid, guid, url, section, section_result));
        }
      })
      .catch((_) => {
        if (guid === report_guid) {
          dispatch(getReportSectionFailed(guid, url, section));
        } else {
          dispatch(getCompetitorReportSectionFailed(report_guid, guid, url, section));
        }
      });
  };
};

const getSavedReportCompetitor = (dispatch, getState, guid, url) => {
  const apiUrl = `${ANALYSIS_API_URL}/report/v2?url=${url}&guid=${guid}`;

  return authRequest(apiUrl, dispatch, getState)
    .then((response) => {
      let section_results = {};
      Object.keys(response["sections"]).forEach((section) => {
        section_results[section] = { ...response["sections"][section], section: section };
      });

      let savedReport = {
        url: url,
        section_results: section_results,
        section_errors: [],
        network_activity: []
      };
      return Promise.resolve(savedReport);
    })
    .catch((error) => {
      return { error: error };
    });
};

const fetchSavedReport = (guid, url) => {
  return (dispatch, getState) => {
    const apiUrl = `${ANALYSIS_API_URL}/report/v2?url=${url}&guid=${guid}`;
    dispatch(fetchSavedReportInProgress(guid, url));
    return authRequest(apiUrl, dispatch, getState)
      .then((response) => {
        let section_results = {};
        let hasMobileSnapshotError = false;

        Object.keys(response["sections"]).forEach((section) => {
          if (section !== "mobile-snapshot") {
            section_results[section] = { ...response["sections"][section], section: section };
          } else {
            const result = response["sections"][section]["result"];
            if (result["snapshot_url"]) {
              section_results[section] = { ...response["sections"][section], section: section };
            } else {
              hasMobileSnapshotError = true;
            }
          }
        });

        let savedReport = {
          url: response["url"],
          status: "finished",
          sections: Object.keys(response["sections"]),
          section_results: section_results,
          section_errors: hasMobileSnapshotError ? ["mobile-snapshot"] : [],
          network_activity: [],
          competitor_urls: [],
          competitors: {},
          created_at: response["created_at"]
        };

        let competitor_urls = response["competitor_urls"];
        let competitor_guids = response["competitor_guids"];

        return Promise.all(
          competitor_urls.map((competitor_url, idx) => {
            return getSavedReportCompetitor(dispatch, getState, competitor_guids[idx], competitor_url).then(
              (response) => {
                if (!("error" in response)) {
                  savedReport["competitor_urls"] = [...savedReport["competitor_urls"], competitor_url];
                  savedReport["competitors"][competitor_guids[idx]] = response;
                }
                return Promise.resolve(true);
              }
            );
          })
        ).then((_) => {
          dispatch(fetchSavedReportSuccessful(guid, url, savedReport));
          return Promise.resolve(savedReport);
        });
      })
      .catch((_) => {
        dispatch(fetchSavedReportFailed(guid, url, "Something went wrong while fetching your report"));
        return Promise.resolve();
      });
  };
};

const fetchOverviews = (url, startDate, endDate) => {
  return (dispatch, getState) => {
    const encodedUrl = encodeURIComponent(url);
    const apiUrl = `${ANALYSIS_API_URL}/report/v2/overviews?url=${encodedUrl}&start_date=${startDate}&end_date=${endDate}`;

    dispatch(fetchOverviewsInProgress(url));
    return authRequest(apiUrl, dispatch, getState)
      .then((overviews) => {
        dispatch(fetchOverviewsSuccessful(url, overviews));
        return Promise.resolve();
      })
      .catch((_) => {
        dispatch(fetchOverviewsFailed(url));
        return Promise.resolve();
      });
  };
};

export const fetchOverviewsForUrlAndCompetitors = (mainUrl, startDate, endDate) => {
  return (dispatch, getState) => {
    const competitors = getTrackedWebsiteCompetitors(getState());
    dispatch(fetchOverviewsForMainUrlAndCompetitorsInProgress(mainUrl));
    let overviewsPromises = [mainUrl, ...competitors].map((url) => dispatch(fetchOverviews(url, startDate, endDate)));
    Promise.all(overviewsPromises).finally(() => dispatch(fetchOverviewsForMainUrlAndCompetitorsFinished(mainUrl)));
  };
};

const fetchLastSavedReport = (url, startDate, endDate) => {
  return (dispatch, getState) => {
    dispatch(fetchLastSavedReportInProgress(url));
    return new Promise((resolve, reject) => {
      dispatch(fetchOverviews(url, startDate, endDate))
        .then((_) => {
          let overviews = getOverviewsForUrl(getState(), url);

          if (overviews.length === 0) {
            throw new Error("Overviews not available");
          }

          let lastSavedReportGuid = overviews[overviews.length - 1]["guid"];
          dispatch(fetchSavedReport(lastSavedReportGuid, url))
            .then((_) => resolve())
            .catch((_) => reject())
            .finally((_) => dispatch(fetchLastSavedReportFinished(url)));
        })
        .catch((_) => dispatch(fetchLastSavedReportFinished(url)));
    });
  };
};

export const fetchSavedReportForAnalysisReport = (guid, url) => {
  return (dispatch) => {
    dispatch(fetchSavedReport(guid, url)).catch((_) => {
      dispatch(addNotificationWithTimeout("error", `Something went wrong when fetching saved report`));
    });
  };
};

export const fetchDomainUrls = (domain, nextToken) => {
  return (dispatch, getState) => {
    const apiUrl = `${ANALYSIS_API_URL}/report/v2/urls?domain=${domain}${
      nextToken ? `&next_token=${encodeURIComponent(nextToken)}` : ""
    }`;
    let urls = getState().analysis.urls;

    if (urls && urls[domain] && !nextToken) {
      return Promise.resolve();
    }

    dispatch(fetchDomainUrlsInProgress(domain));
    return authRequest(apiUrl, dispatch, getState)
      .then((response) => {
        dispatch(fetchDomainUrlsSuccessful(domain, response.next_token, response.urls));
        return Promise.resolve();
      })
      .catch((_) => {
        dispatch(fetchDomainUrlsFailed(domain));
        return Promise.resolve();
      });
  };
};

const startBackgroundAnalysis = (url, competitors, fromOnboardingWizard) => {
  return (dispatch, getState) => {
    const apiUrl = `${ANALYSIS_API_URL}/report/background`;
    const payload = {
      url: url,
      competitors: competitors
    };

    if (fromOnboardingWizard) {
      payload["source"] = "onboarding_wizard";
    } else {
      payload["source"] = "add_tracked_website_wizard";
    }

    const options = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(payload)
    };

    dispatch(startBackgroundAnalysisInProgress(url));
    return new Promise(function(resolve, reject) {
      authRequest(apiUrl, dispatch, getState, options)
        .then((body) => {
          dispatch(startBackgroundAnalysisSuccess(body.guid, url));
          resolve({ url: url, guid: body.guid });
        })
        .catch((err) => {
          dispatch(startBackgroundAnalysisFailed(url));
          resolve();
        });
    });
  };
};

// const startBackgroundAnalysis = (url, competitors) => {
//   return (dispatch, getState) => {
//     const apiUrl = `${ANALYSIS_API_URL}/report/background`;
//     const options = {
//       method: "POST",
//       headers: { "Content-type": "application/json" },
//       body: JSON.stringify({ url: url, competitors: competitors })
//     };

//     dispatch(startBackgroundAnalysisInProgress(url));
//     return authRequest(apiUrl, dispatch, getState, options)
//       .then(body => {
//         dispatch(startBackgroundAnalysisSuccess(body.guid, url));
//       })
//       .catch(_ => {
//         dispatch(startBackgroundAnalysisFailed(url));
//       });
//   };
// };

export {
  getAnalysisReportGeneralStructure,
  initAnalysis,
  fetchSavedReport,
  fetchOverviews,
  fetchLastSavedReport,
  startBackgroundAnalysis
};
