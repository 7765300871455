import React from 'react';
import { Chart, Geom, Axis, Tooltip } from 'bizcharts';
import { Empty } from 'antd';
import { ChartDataPoint } from '../types';
import { getYAxisMax, getYAxisMin, tickFormatter } from '../utils';

type Props = {
    chartData: ChartDataPoint[];
};

export default function BacklinksOverviewChart({ chartData }: Props) {
    const getScale = () => {
        let scale: { [key: string]: any } = {
            value: {
                type: 'linear',
                min: getYAxisMin(chartData),
                max: getYAxisMax(chartData),
                tickCount: 3,
                formatter: (val: number) => tickFormatter(val),
            },
            c_at: {
                type: 'timeCat',
                mask: 'MMM DD',
            },
        };
        if (chartData.length > 1) {
            scale.c_at.range = [0, 1];
        }
        return scale;
    };

    const handleTooltipChange = (event: any) => {
        let tooltipItems = [...event.items];

        tooltipItems.forEach((item, idx) => {
            item.name = item.point._origin.type;
            tooltipItems[idx].value = parseInt(tooltipItems[idx].point._origin.value).toLocaleString();
        });
    };

    return (
        <div className="backlinks-chart">
            {chartData.length > 0 ? (
                <Chart
                    padding={[20, 20, 30, 'auto']}
                    height={150}
                    data={chartData}
                    scale={getScale()}
                    onTooltipChange={handleTooltipChange}
                    animate={false}
                    forceFit
                >
                    <Axis
                        name="c_at"
                        label={{ textStyle: { fontSize: 11 } }}
                        grid={{ lineStyle: { lineDash: [4, 4] } }}
                        tickLine={null}
                    />
                    <Axis
                        name="value"
                        label={{ textStyle: { fontSize: 11 } }}
                        grid={{ lineStyle: { lineDash: [4, 4] } }}
                    />
                    <Tooltip />
                    <Geom
                        type="point"
                        position="c_at*value"
                        shape={'circle'}
                        size={2}
                    />
                    <Geom
                        type="line"
                        position="c_at*value"
                        size={2}
                    />
                </Chart>
            ) : (
                <Empty
                    className="empty-state"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data available yet"
                />
            )}
        </div>
    );
}
