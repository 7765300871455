import React from 'react';
import { Alert } from 'antd';
import { useFetchKeywordResearchQuery } from '../service';
import { ResearchType } from '../types';
import KeywordResearchSuggestionsTable from './KeywordResearchSuggestionsTable';
import LoadingIndicator from '../../components/shared/LoadingIndicator/LoadingIndicator';

type Props = {
    keyword: string;
    location: string;
    languageCode: string;
    researchType: ResearchType;
};

export default function KeywordResearchSuggestionsResult({ keyword, location, languageCode, researchType }: Props) {
    const { data: researchedKeywords, isLoading: isLoadingKeywords } = useFetchKeywordResearchQuery({
        research_type: researchType,
        phrase: keyword,
        location: location,
        language: languageCode,
    });

    const isKeywordsDataEmpty = !isLoadingKeywords && (!researchedKeywords || (researchedKeywords && researchedKeywords.length === 0));
    const isKeywordsDataExistent = !isLoadingKeywords && researchedKeywords && researchedKeywords.length > 0;

    return (
        <>
            {isLoadingKeywords && <LoadingIndicator />}

            {isKeywordsDataEmpty && <Alert message="No keywords found for your search!" />}

            {isKeywordsDataExistent && <KeywordResearchSuggestionsTable keywords={researchedKeywords} />}
        </>
    );
}
