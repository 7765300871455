import React, { Component } from "react";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { Checkbox, Empty } from "antd";
import "./SiteSpeed.less";

class SiteSpeedChart extends Component {
  constructor(props) {
    super(props);

    this.colors = ["#4BB2C5", "#0A901B", "#E67E22", "#C1380A", "#722D8E", "#33CCC8"];

    this.checkboxRefs = {};
    this.props.regions.forEach((region, idx) => {
      this.checkboxRefs[region] = React.createRef();
    });

    this.state = {
      checkedRegions: this.props.regions,
      colorsPairing: this.getColorsPairing(this.props.regions, this.colors)
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.getFilteredChartData = this.getFilteredChartData.bind(this);
    this.getChartColors = this.getChartColors.bind(this);
    this.getColorsPairing = this.getColorsPairing.bind(this);
    this.getScale = this.getScale.bind(this);
    this.getTickCount = this.getTickCount.bind(this);
    this.handleTooltipChange = this.handleTooltipChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.regions !== prevProps.regions) {
      this.checkboxRefs = {};
      this.props.regions.forEach((region, idx) => {
        this.checkboxRefs[region] = React.createRef();
      });

      this.setState({
        checkedRegions: this.props.regions,
        colorsPairing: this.getColorsPairing(this.props.regions, this.colors)
      });
    }
  }

  handleOnChange(e) {
    let checkedRegions = [...this.state.checkedRegions];
    let affectedRegion = e.target.name;
    if (e.target.checked) {
      checkedRegions = [...checkedRegions, affectedRegion];
    } else {
      checkedRegions = checkedRegions.filter(region => region !== affectedRegion);
    }
    this.setState({ checkedRegions: checkedRegions });
    this.checkboxRefs[affectedRegion].current.blur();
  }

  getColorsPairing(regions, colors) {
    return Object.assign({}, ...regions.map((region, idx) => ({ [region]: colors[idx] })));
  }

  getChartColors(checkedRegions, regions, colorsPairing) {
    const orderedCheckedRegions = regions.filter(region => checkedRegions.includes(region));
    return orderedCheckedRegions.map(region => colorsPairing[region]);
  }

  getFilteredChartData(data, checkedRegions) {
    let checkedRegionsValues = checkedRegions.map(
      region => this.props.monitorLocations && this.props.monitorLocations[region]
    );
    return data.filter(item => checkedRegionsValues.includes(item.region));
  }

  getScale(chartData) {
    let chartTickCount = this.getTickCount(chartData);
    let scale = {
      value: {
        type: "linear",
        formatter: val => (val / 1000).toFixed(2),
        tickCount: 5
      },
      c_at: {
        type: "timeCat",
        mask: "MMM DD",
        tickCount: chartTickCount
      }
    };
    if (chartTickCount > 1) {
      scale["c_at"]["range"] = [0, 1];
    }
    return scale;
  }

  getTickCount(dataSource) {
    let dataEntriesCountForRegions = {};
    dataSource.forEach(dataEntry => {
      if (!(dataEntry["region"] in dataEntriesCountForRegions)) {
        dataEntriesCountForRegions[dataEntry["region"]] = 1;
      } else {
        dataEntriesCountForRegions[dataEntry["region"]] += 1;
      }
    });
    let arrayOfEntriesCount = Object.values(dataEntriesCountForRegions);
    if (arrayOfEntriesCount.length === 0) {
      return 0;
    }
    return Math.max(...arrayOfEntriesCount);
  }

  handleTooltipChange(event) {
    let tooltipItems = [...event.items];

    tooltipItems.forEach(item => {
      item.value = `${(item.point._origin.value / 1000).toFixed(1)} s`;
    });
  }

  render() {
    let chartData = this.getFilteredChartData(this.props.loadingTimeData, this.state.checkedRegions);
    let regionsInLegend = this.props.regions.filter(region =>
      this.props.loadingTimeData.some(dataEntry => dataEntry["region"] === this.props.monitorLocations[region])
    );

    return (
      <div className="loading-time-chart custom-scrollbar">
        {regionsInLegend.length > 1
          ? regionsInLegend.map((region, idx) => (
              <Checkbox
                className="site-speed-checkbox"
                key={region}
                name={region}
                style={{
                  "--background-color": this.colors[idx],
                  "--border-color": this.colors[idx]
                }}
                checked={this.state.checkedRegions.includes(region)}
                defaultChecked={this.state.checkedRegions.includes(region)}
                onChange={this.handleOnChange}
                ref={this.checkboxRefs[region]}>
                {this.props.monitorLocations && this.props.monitorLocations[region]}
              </Checkbox>
            ))
          : null}
        {chartData.length > 0 ? (
          <Chart
            padding="auto"
            height={340}
            data={chartData}
            scale={this.getScale(chartData)}
            animate={false}
            forceFit
            onTooltipChange={this.handleTooltipChange}>
            <Axis name="c_at" />
            <Axis name="value" />
            <Tooltip />
            <Geom
              type="line"
              position="c_at*value"
              size={2}
              color={[
                "region",
                this.getChartColors(this.state.checkedRegions, this.props.regions, this.state.colorsPairing)
              ]}
              shape={"smooth"}
            />
            <Geom
              type="point"
              position="c_at*value"
              size={4}
              shape={"circle"}
              color={[
                "region",
                this.getChartColors(this.state.checkedRegions, this.props.regions, this.state.colorsPairing)
              ]}
              style={{ lineWidth: 1 }}
            />
          </Chart>
        ) : (
          <Empty className="empty-state" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data available yet" />
        )}
      </div>
    );
  }
}

export default SiteSpeedChart;
