import React from 'react';
import { Card } from 'antd';
import { TrackedWebsite } from '../trackedWebsites/types';
import AddTrackedKeywords from './AddTrackedKeywords';
import TrackedKeywordsTable from './TrackedKeywordsTable';
import ChangeLocationAndLanguage from './ChangeLocationAndLanguage';

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite) => void;
};

export default function TrackedKeywordsOverview({ selectedWebsite, setSelectedWebsite }: Props) {
    return (
        <Card
            className="keywords-data-overview"
            style={{ marginTop: 20 }}
            title={
                <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-title">Keywords Overview</div>
                    <div className="ant-card-meta-description">{selectedWebsite.domain}</div>
                </div>
            }
            extra={
                <div className="keywords-options card-actions">
                    <ChangeLocationAndLanguage
                        selectedWebsite={selectedWebsite}
                        setSelectedWebsite={setSelectedWebsite}
                    />
                    <AddTrackedKeywords selectedWebsite={selectedWebsite} />
                </div>
            }
            data-cy="tracked-keywords-overview"
        >
            <div className="keywords-table">
                <TrackedKeywordsTable selectedWebsite={selectedWebsite} />
            </div>
        </Card>
    );
}
