import React from "react";
import HtmlContent from "../HtmlContent/HtmlContent";
import { Icon } from 'antd';

const BasicSectionMessage = ({message}) => {
    return (        
        <ul>
            <li>
                <HtmlContent content={message} />
            </li>
        </ul>        
    )
}

const MultipleUrlsSectionMessage = ({url, message, status, relevance, hideMessage, hideUrl}) => {
    const getStatusIcon = (status) => {
        if(status === 'passed') return 'check-circle'
        if(status === 'warning') return 'exclamation-circle'
        return 'close-circle'
    };

    return (
        <>          
            <div className="flexbox align-center">          
                {(status && relevance !== 0) && <Icon type={getStatusIcon(status)} theme="filled" className="status-icon" />}          
                {!hideUrl && <span className="section-result-url">{url}</span>}
            </div>      
            {!hideMessage && <BasicSectionMessage message={message} />}
        </>
    );
}

const SectionMessage = ({ url, status, message, relevance, hasCompetitors, hideMessage, hideUrl }) => {
  return (
    <div className="description-content"> 
        {hasCompetitors ? 
        <MultipleUrlsSectionMessage url={url} status={status} message={message} relevance={relevance} hideMessage={hideMessage} hideUrl={hideUrl} /> : 
        <BasicSectionMessage message={message} />}
    </div>
  );
};

export default SectionMessage;
