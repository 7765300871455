import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import "../../SimpleTable.less";

const getNonEmptyHeadingTagsList = tagList => {
  if (tagList) {
    return tagList.filter(tag => tag !== "");
  }
  return [];
};

const getTagsTable = (tagList, tableTitle) => {
  return (
    <div className="simple-table">
      <div className="simple-table-row header">{tableTitle}</div>
      {tagList.map((item, idx) => (
        <div className="simple-table-row" key={idx}>
          {item}
        </div>
      ))}
    </div>
  );
};

const headingTagsTable = result => {
  const h1TagsList = getNonEmptyHeadingTagsList(result.h1_list);
  const h2TagsList = getNonEmptyHeadingTagsList(result.h2_list);

  return (
    <div className="table-wrapper">
      {h1TagsList.length > 0 && getTagsTable(h1TagsList, "H1 tags")}
      {h2TagsList.length > 0 && getTagsTable(h2TagsList, "H2 tags")}
    </div>
  );
};

const displayResult = (title, result) => (
  <>
    <div className="description-meta">{headingTagsTable(result)}</div>
    {result.how_to_fix && (
      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    )}
  </>
);

const HeadingTagsSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default HeadingTagsSection;
