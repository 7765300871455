import React from "react";
import { Layout } from "antd";

import UserAccount from "../containers/UserAccount/UserAccount";

const { Content } = Layout;

const UserAccountPage = () => {
  return (
    <Content className="general-layout-content">
      <UserAccount />
    </Content>
  );
};

export default UserAccountPage;
