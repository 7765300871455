import React, { useMemo } from 'react';
import BasicModal from '../components/shared/BasicModal/BasicModal';
import IconLabel from '../components/shared/IconLabel/IconLabel';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';
import { useFetchLocationsAndLanguagesQuery, useUpdateTrackedWebsiteSettingsMutation } from '../trackedWebsites/service';
import { TrackedWebsite } from '../trackedWebsites/types';
import ChangeLocationAndLanguageForm from './ChangeLocationAndLanguageForm';
import { getLanguageFromCode } from '../trackedWebsites/utils';
import { useChangeKeywordsLocationAndLanguageMutation } from './service';
type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite) => void;
};

export default function ChangeLocationAndLanguage({ selectedWebsite, setSelectedWebsite }: Props) {
    const { data: locationsAndLanguages, isLoading: isLoadingLocationsAndLanguages } = useFetchLocationsAndLanguagesQuery();

    const selectedWebsiteLocation = selectedWebsite.settings.location_name;
    const selectedWebsiteLanguage = useMemo(
        () => (locationsAndLanguages ? getLanguageFromCode(selectedWebsiteLocation, selectedWebsite.settings.language_code, locationsAndLanguages) : ''),
        [locationsAndLanguages, selectedWebsiteLocation, selectedWebsite.settings.language_code]
    );

    const [changeKeywordsLocationAndLanguage, { isLoading: isChangingLocationAndLanguageForKeywords }] = useChangeKeywordsLocationAndLanguageMutation();

    const [updateTrackedWebsiteSettings, { isLoading: isChangingLocationAndLanguageForWebsite }] = useUpdateTrackedWebsiteSettingsMutation();

    const handleLocationChange = async (newLocation: string, newLanguageCode: string) => {
        try {
            await changeKeywordsLocationAndLanguage({ domain: selectedWebsite.domain, locationName: newLocation, languageCode: newLanguageCode }).unwrap();

            const updatedSettings = await updateTrackedWebsiteSettings({
                guid: selectedWebsite.guid,
                url: selectedWebsite.url,
                location_name: newLocation,
                language_code: newLanguageCode,
            }).unwrap();

            setSelectedWebsite({ ...selectedWebsite, settings: { ...selectedWebsite.settings, ...updatedSettings } });
        } catch (error) {}
    };

    const isChangingLocationAndLanguage = isChangingLocationAndLanguageForKeywords || isChangingLocationAndLanguageForWebsite;

    return (
        <BasicModal
            openLabel={
                <IconLabel
                    icon="global"
                    label={isLoadingLocationsAndLanguages ? 'Loading...' : `${selectedWebsiteLocation} (${selectedWebsiteLanguage})`}
                />
            }
            modalTitle={
                <ModalTitle
                    title="Location & Language"
                    meta={selectedWebsite.domain}
                />
            }
            isPerformingAction={isChangingLocationAndLanguage}
            isButtonDisabled={isLoadingLocationsAndLanguages}
        >
            <ChangeLocationAndLanguageForm
                customClassName={'change-tracked-keywords-form'}
                initialLocation={selectedWebsite.settings.location_name}
                initialLanguageCode={selectedWebsite.settings.language_code}
                onSubmit={handleLocationChange}
                isChangingLanguageAndLocation={isChangingLocationAndLanguage}
            />
        </BasicModal>
    );
}
