export const FETCH_TEMPLATES_IN_PROGRESS = "FETCH_TEMPLATES_IN_PROGRESS";
export const FETCH_TEMPLATES_SUCCESSFUL = "FETCH_TEMPLATES_SUCCESSFUL";
export const FETCH_TEMPLATES_FAILED = "FETCH_TEMPLATES_FAILED";

export const ADD_TEMPLATE_IN_PROGRESS = "ADD_TEMPLATE_IN_PROGRESS";
export const ADD_TEMPLATE_SUCCESSFUL = "ADD_TEMPLATE_SUCCESSFUL";
export const ADD_TEMPLATE_FAILED = "ADD_TEMPLATE_FAILED";

export const EDIT_TEMPLATE_IN_PROGRESS = "EDIT_TEMPLATE_IN_PROGRESS";
export const EDIT_TEMPLATE_SUCCESSFUL = "EDIT_TEMPLATE_SUCCESSFUL";
export const EDIT_TEMPLATE_FAILED = "EDIT_TEMPLATE_FAILED";

export const DELETE_TEMPLATE_IN_PROGRESS = "DELETE_TEMPLATE_IN_PROGRESS";
export const DELETE_TEMPLATE_SUCCESSFUL = "DELETE_TEMPLATE_SUCCESSFUL";
export const DELETE_TEMPLATE_FAILED = "DELETE_TEMPLATE_FAILED";

export const CHANGE_SELECTED_REPORT_GUID = "CHANGE_SELECTED_REPORT_GUID";
export const CHANGE_SELECTED_TEMPLATE = "CHANGE_SELECTED_TEMPLATE";
export const CHANGE_SELECTED_REPORT_URL = "CHANGE_SELECTED_REPORT_URL";

export const DOWNLOAD_PDF_REPORT_IN_PROGRESS = "DOWNLOAD_PDF_REPORT_IN_PROGRESS";
export const DOWNLOAD_PDF_REPORT_SUCCESSFUL = "DOWNLOAD_PDF_REPORT_SUCCESSFUL";
export const DOWNLOAD_PDF_REPORT_FAILED = "DOWNLOAD_PDF_REPORT_FAILED";
