import { BROKEN_LINKS_API_URL } from "../../settings/config";
import { authRequest } from "../authorizedRequests";
import {
  fetchBrokenLinksInProgress,
  fetchBrokenLinksSuccessful,
  fetchBrokenLinksFailed,
  fetchAffectedPagesInProgress,
  fetchAffectedPagesSuccessful,
  fetchAffectedPagesFailed
} from "./creators";

export const fetchBrokenLinks = (domain, linkType, urlHash = null) => {
  return (dispatch, getState) => {
    const resourceType = "broken_links";
    const apiUrl = `${BROKEN_LINKS_API_URL}/broken-links?domain=${domain}&link_type=${linkType}${
      urlHash ? `&url_hash=${urlHash}` : ""
    }`;

    dispatch(fetchBrokenLinksInProgress(resourceType, domain, linkType));

    authRequest(apiUrl, dispatch, getState)
      .then(response => {
        dispatch(fetchBrokenLinksSuccessful(resourceType, domain, linkType, response));
      })
      .catch(_ => {
        dispatch(fetchBrokenLinksFailed(resourceType, domain, linkType));
      });
  };
};

export const fetchAffectedPages = (domain, url, lastUat = null) => {
  return (dispatch, getState) => {
    const resourceType = "affected_pages";
    const apiUrl = `${BROKEN_LINKS_API_URL}/broken-links/affected-pages?domain=${domain}&url=${url}${
      lastUat ? `&last_uat=${lastUat}` : ""
    }`;

    dispatch(fetchAffectedPagesInProgress(resourceType, domain, url));

    return authRequest(apiUrl, dispatch, getState)
      .then(response => {
        dispatch(fetchAffectedPagesSuccessful(resourceType, domain, url, response));
      })
      .catch(_ => {
        dispatch(fetchAffectedPagesFailed(resourceType, domain, url));
      });
  };
};
