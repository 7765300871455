import React, { Component } from "react";
import { Card, Icon } from "antd";
import DashboardBox from "../DashboardBox";
import DashboardCardTitle from "../DashboardCardTitle";
import SiteSpeedChart from "./SiteSpeedChart";

export class SiteSpeedBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const firstFetchIsInProgress =
      !prevProps.isFetching && this.props.isFetching && prevState.loading === null && this.state.loading === null;
    const fetchIsFinished = prevProps.isFetching && !this.props.isFetching;

    if (firstFetchIsInProgress && !this.props.hasData) {
      this.setState({ loading: true });
    }
    if ((firstFetchIsInProgress && this.props.hasData) || fetchIsFinished) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading === null) {
      return null;
    }
    return (
      <div className="dashboard-site-speed-box">
        {this.state.loading === true && <DashboardBox />}
        {this.state.loading === false && (
          <Card
            data-cy="site-speed-box"
            title={<DashboardCardTitle title="Loading time" description={"Over the last 7 days"} />}
            extra={
              <div className="card-link flexbox align-center" onClick={() => this.props.push("/site-speed")}>
                <span>More speed data</span>
                <Icon type="right" />
              </div>
            }>
            <SiteSpeedChart
              loadingTimeData={this.props.loadingTimeData}
              regions={this.props.regions}
              monitorLocations={this.props.monitorLocations}
            />
          </Card>
        )}
      </div>
    );
  }
}

export default SiteSpeedBox;
