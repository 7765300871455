export const FETCH_AFFECTED_PAGES_IN_PROGRESS = "FETCH_AFFECTED_PAGES_IN_PROGRESS";
export const FETCH_AFFECTED_PAGES_SUCCESSFUL = "FETCH_AFFECTED_PAGES_SUCCESSFUL";
export const FETCH_AFFECTED_PAGES_FAILED = "FETCH_AFFECTED_PAGES_FAILED";

export const FETCH_BROKEN_LINKS_IN_PROGRESS = "FETCH_BROKEN_LINKS_IN_PROGRESS";
export const FETCH_BROKEN_LINKS_SUCCESSFUL = "FETCH_BROKEN_LINKS_SUCCESSFUL";
export const FETCH_BROKEN_LINKS_FAILED = "FETCH_BROKEN_LINKS_FAILED";

export const RESET_BROKEN_LINKS = "RESET_BROKEN_LINKS";
export const RESET_AFFECTED_PAGES = "RESET_AFFECTED_PAGES";
