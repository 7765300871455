import React, { Component } from "react";
import { Layout } from "antd";
import CustomIcon from "../../CustomIcon";
import SideMenu from "./SideMenu";
import { APP_VERSION } from "../../../settings/config";

const { Sider } = Layout;

class SideMenuLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false
        };
    }

    toggleCollapsedMenu = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    seeAllPlans = () => {
        this.props.onMenuItemClick("/subscription/plans");
    };

    render() {
        return (
            <Sider className="general-layout-sidebar" collapsed={this.state.collapsed}>
                <div className="top-side-branding flexbox align-center space-between">
                    <div className="logo" />
                    <div className="menu-fold flexbox align-center justify-center" onClick={this.toggleCollapsedMenu}>
                        <CustomIcon name="menu-fold" className="icon" size="24" />
                    </div>
                </div>
                <SideMenu
                    selectedMenuItem={this.props.selectedMenuItem}
                    onMenuItemClick={this.props.onMenuItemClick}
                    collapsed={this.state.collapsed}
                />

                <span className="app-version">{APP_VERSION}</span>

                {
                    //TODO: Move the below code in a separate component
                }
                {this.props.subscription &&
                    this.props.subscription["plan_code"] &&
                    this.props.user &&
                    this.props.user.email &&
                    (!this.props.organization ||
                        (this.props.organization && this.props.organization["owner"] === this.props.user.email)) && (
                        <div className="package-container">
                            <span className="email">{this.props.user.email}</span>
                            <span className="plan">
                                {this.props.subscription["plan_settings"] &&
                                    this.props.subscription["plan_settings"]["name"]}
                            </span>

                            <span className="see-all-plans" onClick={this.seeAllPlans}>
                                See all plans &rsaquo;
                            </span>
                        </div>
                    )}

                {this.props.organization &&
                    this.props.user &&
                    this.props.organization["owner"] &&
                    this.props.organization["owner"] !== this.props.user.email && (
                        <div className="team-container">
                            <div className="team-name">
                                <strong>Team:</strong> {this.props.organization["name"]}
                            </div>
                            <div className="team-owner">
                                <strong>Owner:</strong> {this.props.organization["owner"]}
                            </div>
                        </div>
                    )}
            </Sider>
        );
    }
}
export default SideMenuLayout;
