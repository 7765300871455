import { createApi } from '@reduxjs/toolkit/query/react';
import { BROKEN_LINKS_API_URL } from '../settings/config';
import { authRequest } from '../store/authorizedRequests';
import { FetchBrokenLinksResponse, FetchBrokenLinksQueryParams, FetchAffectedPagesResponse, FetchAffectedPagesQueryParams } from './types';

const brokenLinksBaseUrl = `${BROKEN_LINKS_API_URL}/broken-links`;

const BROKEN_LINKS_REFETCH_TIME = 7200;

export const brokenLinksApi = createApi({
    reducerPath: 'api.brokenLinks',

    keepUnusedDataFor: BROKEN_LINKS_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;
        try {
            const data = await authRequest(brokenLinksBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchBrokenLinks: builder.query<FetchBrokenLinksResponse, FetchBrokenLinksQueryParams>({
            query: (args) => {
                const { domain, linkType, urlHash } = args;

                return {
                    url: `?domain=${domain}&link_type=${linkType}${urlHash ? `&url_hash=${urlHash}` : ''}`,
                    method: 'GET',
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { domain, linkType } = queryArgs;
                return { domain, linkType };
            },

            forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
                // currentArg should always be defined
                if (typeof currentArg === 'undefined') {
                    return false;
                }

                // If there was not a previous fetch then fetch
                if (typeof previousArg === 'undefined') {
                    return true;
                }

                return currentArg.urlHash && currentArg.urlHash !== previousArg.urlHash ? true : false;
            },

            merge: (currentCacheData, responseData, otherArgs) => {
                return {
                    broken_links: currentCacheData.broken_links.concat(responseData.broken_links),
                    last_url_hash: responseData.last_url_hash,
                };
            },
        }),

        fetchAffectedPages: builder.query<FetchAffectedPagesResponse, FetchAffectedPagesQueryParams>({
            query: (args) => {
                const { domain, url, lastUat } = args;

                return {
                    url: `/affected-pages?domain=${domain}&url=${url}${lastUat ? `&last_uat=${lastUat}` : ''}`,
                    method: 'GET',
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { domain, url } = queryArgs;
                return { domain, url };
            },

            forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
                // currentArg should always be defined
                if (typeof currentArg === 'undefined') {
                    return false;
                }

                // If there was not a previous fetch then fetch
                if (typeof previousArg === 'undefined') {
                    return true;
                }

                return currentArg.lastUat && currentArg.lastUat !== previousArg.lastUat ? true : false;
            },

            merge: (currentCacheData, responseData, otherArgs) => {
                return {
                    pages: currentCacheData.pages.concat(responseData.pages),
                    last_uat: responseData.last_uat,
                };
            },
        }),
    }),
});

export const { useLazyFetchAffectedPagesQuery, useFetchBrokenLinksQuery } = brokenLinksApi;
