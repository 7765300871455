import React, { Component } from "react";
import { Popover } from "antd";
import { geoPath } from "d3-geo";
import { geoMiller } from "d3-geo-projection";
import { feature } from "topojson-client";
import fetch from "cross-fetch";
import OutageTooltip from "./OutageTooltip";
import "./SiteOutage.less";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";

const regions = [
  { name: "US East", key: "us-east-1", coordinates: [-77.531717, 38.747686] },
  { name: "US West", key: "us-west-2", coordinates: [-120.618048, 43.799272] },
  { name: "Canada", key: "ca-central-1", coordinates: [-73.705356, 45.464899] },
  { name: "Brazil", key: "sa-east-1", coordinates: [-46.6333, -23.5505] },
  { name: "Ireland", key: "eu-west-1", coordinates: [-6.206855, 53.408871] },
  { name: "UK", key: "eu-west-2", coordinates: [-0.127839, 51.508638] },
  { name: "Germany", key: "eu-central-1", coordinates: [8.632249, 50.098835] },
  { name: "France", key: "eu-west-3", coordinates: [2.3522, 48.8566] },
  { name: "India", key: "ap-south-1", coordinates: [72.879118, 19.078309] },
  { name: "Singapore", key: "ap-southeast-1", coordinates: [103.858661, 1.353811] },
  { name: "Australia", key: "ap-southeast-2", coordinates: [151.192872, -33.870735] },
  { name: "Japan", key: "ap-northeast-1", coordinates: [139.772913, 35.847964] }
];

class WorldMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      geographies: []
    };
  }

  projection = geoMiller()
    .rotate([-10, 0, 0])
    .scale(this.props.width / 7)
    .translate([this.props.width / 2, this.props.height / 1.6]);

  componentDidMount = _ => {
    this._isMounted = true;
    fetch("/world-continents.json").then(response => {
      if (response.status !== 200) {
        return;
      }
      response.json().then(worldTopoData => {
        if (this._isMounted) {
          this.setState({
            geographies: feature(worldTopoData, worldTopoData.objects.continent).features,
            conus: feature(worldTopoData, worldTopoData.objects.continent)
          });
        }
      });
    });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  markerStatus = event => {
    try {
      if (event["ping"]["status"] === "down" || event["loading_time"]["status"] === "down") {
        return "danger";
      }
    } catch (e) {}
    return "success";
  };

  getRegionEvents = region => {
    const events = this.props.events && this.props.events[region];
    return events
      ? events
      : {
          ping: { status: "up" },
          loading_time: { status: "up" }
        };
  };

  render() {
    return (
      <div className="map-wrapper">
        <div className={`map-loading-overlay ${this.props.isLoading ? "loading" : ""}`}>
          <LoadingIndicator />
        </div>
        <svg className="world-map" viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
          <g className="continents">
            {this.state.geographies.map((d, i) => (
              <path className="continent" key={`path-${i}`} d={geoPath().projection(this.projection)(d)} />
            ))}
          </g>
          <g className="markers">
            {regions.map((region, regionIdx) => (
              <Popover
                content={
                  <OutageTooltip
                    url={this.props.url}
                    location={region["name"]}
                    events={this.getRegionEvents(region["key"])}
                  />
                }
                title={region.name}
                trigger="click"
                key={`marker-${regionIdx}`}
                overlayStyle={{ zIndex: 1000 }}
                overlayClassName="outage-tooltip">
                <circle
                  className={`marker ${this.markerStatus(this.getRegionEvents(region["key"]))} ${
                    this.props.isLoading ? "loading" : ""
                  }`}
                  r="4"
                  cx={this.projection(region.coordinates)[0]}
                  cy={this.projection(region.coordinates)[1]}
                />
              </Popover>
            ))}
          </g>
        </svg>
      </div>
    );
  }
}

export default WorldMap;
