import React from 'react';
import { Tooltip } from 'antd';

type Props = {
    pageTitle: string;
    backlinkUrl: string;
};

export default function BacklinkUrlTableCell({ backlinkUrl, pageTitle }: Props) {
    return (
        <div className="backlink-url">
            <Tooltip title={pageTitle}>
                <div className="truncate">{pageTitle}</div>
            </Tooltip>
            <div className="truncate">
                <a
                    href={backlinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {backlinkUrl}
                </a>
            </div>
        </div>
    );
}
