import React from "react";

const ReportCardTitle = ({ title, domain }) => {
  return (
    <div className="ant-card-meta-detail">
      <div className="ant-card-meta-title">{title}</div>
      <div className="ant-card-meta-description">{domain}</div>
    </div>
  );
};

export default ReportCardTitle;
