import React from "react";
import { Layout } from "antd";
import TrackedWebsites from "../trackedWebsites/TrackedWebsites";
import { BrokenLinksTabs } from "../components/BrokenLinks";
const { Content } = Layout;

const BrokenLinksPage = () => {
  return (
    <Content className="general-layout-content">
      <TrackedWebsites tabContent={<BrokenLinksTabs />} showDomains />
    </Content>
  );
};

export default BrokenLinksPage;
