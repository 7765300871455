import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Card } from "antd";
import TeamMembers from "../../components/TeamMembers/TeamMembers";
import {
    fetchOrganization,
    updateOrganization,
    sendTeamMemberInvitation,
    cancelTeamMemberInvitation,
    removeTeamMember
} from "../../store/iam/operations";
import { getMaxTeamMembers, getSubscription } from "../../store/sales/selectors";
import {
    isFetchingOrganization,
    getOrganization,
    getTeamMembers,
    getTeamInvitations,
    isSendingInvitation,
    isUpdatingOrganization
} from "../../store/iam/selectors";

class TeamMembersLayout extends Component {
    componentDidMount = (_) => {
        this.props.fetchOrganization();
    };

    refetchOrganization = () => this.props.fetchOrganization(false);

    render() {
        return (
            <div>
                <Card bordered={false}>
                    <TeamMembers
                        push={this.props.push}
                        isLoading={this.props.isFetchingOrganization}
                        maxTeamMembers={this.props.maxTeamMembers}
                        teamName={this.props.organization ? this.props.organization.name : ""}
                        onUpdateOrganization={this.props.updateOrganization}
                        isUpdatingOrganization={this.props.isUpdatingOrganization}
                        teamMembers={this.props.teamMembers}
                        teamInvitations={this.props.teamInvitations}
                        onSendInvitation={this.props.sendTeamMemberInvitation}
                        isSendingInvitation={this.props.isSendingInvitation}
                        refetchOrganization={this.refetchOrganization}
                        onRemoveInvitation={this.props.cancelTeamMemberInvitation}
                        onRemoveTeamMember={this.props.removeTeamMember}
                        userSubscription={this.props.userSubscription}
                    />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    maxTeamMembers: getMaxTeamMembers(state),
    teamMembers: getTeamMembers(state),
    teamInvitations: getTeamInvitations(state),
    isFetchingOrganization: isFetchingOrganization(state),
    organization: getOrganization(state),
    isSendingInvitation: isSendingInvitation(state),
    isUpdatingOrganization: isUpdatingOrganization(state),
    userSubscription: getSubscription(state)
});

export default connect(mapStateToProps, {
    push,
    updateOrganization,
    fetchOrganization,
    sendTeamMemberInvitation,
    cancelTeamMemberInvitation,
    removeTeamMember
})(TeamMembersLayout);
