import React from "react";
import { Icon } from "antd";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";

const resultContent = result => {
  return (
    <>
      {result["in_title"] && result["in_description"] && (
        <div>
          <div className="description-meta">{result.in_title}</div>
          <div className="description-meta">{result.in_description}</div>
        </div>
      )}
      {result["keywords_usage"] && (
        <div className="stats-table-container">
          <header>
            <div className="col">Keyword</div>
            <div className="col center">Title tag</div>
            <div className="col center">Meta description</div>
            <div className="col center">Headings</div>
          </header>

          {Object.keys(result["keywords_usage"]).map((keyword, idx) => {
            const item = result["keywords_usage"][keyword];

            return (
              <div className="row" key={idx}>
                <div className="col">{keyword}</div>
                <div className="col center">
                  <Icon type={item["title"] ? "check" : "close"} className={item["title"] ? "passed" : "failed"} />
                </div>
                <div className="col center">
                  <Icon
                    type={item["description"] ? "check" : "close"}
                    className={item["description"] ? "passed" : "failed"}
                  />
                </div>
                <div className="col center">
                  <Icon
                    type={item["headings"] ? "check" : "close"}
                    className={item["headings"] ? "passed" : "failed"}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const displayResult = (title, result) => {
  if (result.in_title || result.in_description || result["keywords_usage"]) {
    return resultContent(result);
  }
  return <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />;
};

const displayCompetitorResult = result => {
  if (result.in_title || result.in_description || result["keywords_usage"]) {
    return resultContent(result);
  }
};

const CommonKeywordsUsageSection = ({
  url,
  sectionKey,
  title,
  tooltip,
  relevance,
  passRate,
  result,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayCompetitorResult} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default CommonKeywordsUsageSection;
