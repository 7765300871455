import React, { Component } from "react";
import { Divider, Form, Select, Button } from "antd";
import SavedReportsSelectorContainer from "../../../containers/WhiteLabel/SavedReportsSelectorContainer";
import "./CustomReportForm.less";

const { Option } = Select;

class CustomReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReportGuid: "latest",
      selectedReportUrl: this.props.selectedReportUrl,
      selectedTemplateGuid: this.props.selectedTemplateGuid
    };

    this.templatesSelectRef = React.createRef();

    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.getSelectedUrl = this.getSelectedUrl.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTemplateGuid !== this.props.selectedTemplateGuid) {
      this.setState({ selectedTemplateGuid: this.props.selectedTemplateGuid });
    }
    if (prevProps.selectedReportUrl !== this.props.selectedReportUrl) {
      this.setState({ selectedReportUrl: this.props.selectedReportUrl });
    }
  }

  handleTemplateChange(selectedTemplateGuid) {
    this.setState({ selectedTemplateGuid });
    this.props.changeSelectedTemplate(selectedTemplateGuid);
    let encodedReportUrl = encodeURIComponent(this.props.selectedReportUrl);
    if (this.props.queryParams["template_guid"]) {
      this.props.push(
        `/white-label/reports?report_url=${encodedReportUrl}&report_guid=${this.props.selectedReportGuid}&template_guid=${selectedTemplateGuid}`
      );
    }

    this.templatesSelectRef.current.blur();
  }

  getSelectedUrl(selectedReportUrl) {
    this.setState({ selectedReportUrl });
  }

  downloadReport() {
    this.props.downloadPdfReport(this.state.selectedReportUrl, "latest", this.props.selectedTemplateGuid);
  }

  render() {
    return (
      <div className="white-label-report">
        <div>
          <h2>New Branded PDF Report</h2>
          <div className="form-description">Provide customized professional SEO reports for your clients</div>
        </div>
        <Divider />
        <Form>
          <Form.Item>
            <div className="form-label">Choose a template</div>
            <div className="form-description"></div>
            <Select
              ref={this.templatesSelectRef}
              size="large"
              placeholder="click to select a template"
              value={this.state.selectedTemplateGuid}
              onChange={this.handleTemplateChange}>
              {this.props.templates.map((template) => (
                <Option key={template["guid"]} value={template["guid"]}>
                  {template["name"]}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Divider />

          <Form.Item>
            <div className="form-label">Select one of your Saved Reports</div>
            <div className="form-description"></div>

            <SavedReportsSelectorContainer
              sendSelectedUrl={this.getSelectedUrl}
              selectedReportGuid={this.state.selectedReportGuid}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            onClick={this.downloadReport}
            disabled={!this.props.selectedReportUrl}
            loading={this.props.isDownloadingPdfReport}>
            Download
          </Button>
        </Form>
      </div>
    );
  }
}

export default CustomReportForm;
