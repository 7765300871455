import React from "react";
import moment from "moment";

function OutageIssueDetails({ url, createdAt, location }) {
  return (
    <div className="issue-details">
      <div className="row">
        <span className="title">URL</span>
        <span className="description">{url}</span>
      </div>
      <div className="row">
        <span className="title">Issue found on</span>
        <span className="description">
          {moment
            .unix(createdAt)
            .utc()
            .format("MMM DD, YYYY - HH:mm")}
        </span>
      </div>
      <div className="row">
        <span className="title">Server location</span>
        <span className="description">{location}</span>
      </div>
    </div>
  );
}
export default OutageIssueDetails;
