import React, { useState } from 'react';
import { Modal, Spin, Icon } from 'antd';
import KeywordPositionChart from './KeywordPositionChart';
import { useLazyFetchKeywordOverviewsQuery } from './service';
import moment from 'moment';
import { getChartDataFromKeywordsOverview } from './utils';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';

const endDate = moment();
const endDateStr = endDate.format('YYYY-MM-DDTHH:mm:ss');
const startDate = endDate.subtract(12, 'months');
const startDateStr = startDate.format('YYYY-MM-DDTHH:mm:ss');

type Props = {
    domain: string;
    keyword: string;
    position: number | null | undefined;
    location: string;
    languageCode: string;
};

export default function TrendChartModal({ domain, keyword, position, location, languageCode }: Props) {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [fetchKeywordOverviews, { data: trackedKeywordOverview, isLoading: isLoadingOverviews }] = useLazyFetchKeywordOverviewsQuery();

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleOpenModal = () => {
        fetchKeywordOverviews(
            { phrase: keyword, domain: domain, location: location, languageCode: languageCode, startDate: startDateStr, endDate: endDateStr },
            true
        );
        toggleModal();
    };

    const isKeywordDataExistent = !isLoadingOverviews && position;
    const isKeywordDataEmpty = !isLoadingOverviews && !position;
    const isTrackedKeywordOverviewExistent = !isLoadingOverviews && trackedKeywordOverview;

    return (
        <>
            {isLoadingOverviews && (
                <Spin
                    style={{ lineHeight: '12px' }}
                    indicator={
                        <Icon
                            type="loading"
                            spin
                            style={{ fontSize: 12 }}
                        />
                    }
                />
            )}

            {isKeywordDataExistent && (
                <span
                    className="tracked-keywords-trend"
                    onClick={handleOpenModal}
                >
                    <Icon type="bar-chart" />
                </span>
            )}

            {isKeywordDataEmpty && <>-</>}

            {isTrackedKeywordOverviewExistent && (
                <Modal
                    centered
                    className="trend-chart-modal"
                    title={
                        <ModalTitle
                            title="Trend chart for:"
                            meta={keyword}
                        />
                    }
                    visible={isModalVisible}
                    onCancel={toggleModal}
                    footer={null}
                >
                    <KeywordPositionChart
                        height={400}
                        chartData={getChartDataFromKeywordsOverview(trackedKeywordOverview)}
                    />
                </Modal>
            )}
        </>
    );
}
