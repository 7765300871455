import React from 'react';
import { Table } from 'antd';
import SparklineChart from '../components/shared/SparklineChart/SparklineChart';
import TableColumnWithTooltip from '../components/shared/TableColumnWithTooltip/TableColumnWithTooltip';
import { semTableColumns } from '../components/shared/TableColumnWithTooltip/semTableColumns';
import KeywordPosition from '../components/shared/KeywordPosition';
import { TopKeywordsTableRecord } from './types';

const { Column } = Table;

type Props = {
    tableData: TopKeywordsTableRecord[];
    extraColumn?: JSX.Element;
};

export default function TopKeywordsTable({ tableData, extraColumn }: Props) {
    return (
        <Table
            dataSource={tableData}
            pagination={false}
            size="small"
        >
            <Column
                title="#"
                dataIndex="key"
                align="left"
                width="43"
                className="no-wrap"
            />
            <Column
                className="no-wrap"
                title="Keyword"
                dataIndex="keyword"
            />
            {extraColumn ? extraColumn : null}
            <Column
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['position']}
                    />
                }
                dataIndex="position"
                render={(text, record: TopKeywordsTableRecord, index) => (
                    <KeywordPosition
                        status={false}
                        position={record.position}
                        previousPosition={record.previousPosition}
                    />
                )}
            />
            <Column
                className="al-right"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['traffic']}
                    />
                }
                dataIndex="traffic"
            />

            <Column
                className="al-right"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['difficulty']}
                    />
                }
                dataIndex="difficulty"
            />

            <Column
                className="al-right"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['search_volume']}
                    />
                }
                dataIndex="searchVolume"
            />

            <Column
                className="truncate"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['url']}
                    />
                }
                dataIndex="url"
                render={(text, record: TopKeywordsTableRecord, index) => {
                    if (record.url) {
                        return (
                            <a
                                target="_blank"
                                href={record.url}
                                rel="noopener noreferrer"
                            >
                                {record.url}
                            </a>
                        );
                    }
                    return '-';
                }}
            />

            <Column
                className="al-right"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['cpc']}
                    />
                }
                dataIndex="cpc"
            />

            <Column
                className="al-right"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['results_no']}
                    />
                }
                dataIndex="resultsNo"
            />

            <Column
                className="keyword-trends al-center"
                title={
                    <TableColumnWithTooltip
                        noFill={false}
                        {...semTableColumns['trends']}
                    />
                }
                dataIndex="trends"
                render={(text, record: TopKeywordsTableRecord, index) => {
                    if (record.trends) {
                        //const trend = record.trends.split(',').map((item) => parseFloat(item));
                        return <SparklineChart data={record.trends} />;
                    }
                    return '-';
                }}
            />
        </Table>
    );
}
