import { useAppDispatch } from '../../useAppDispatch';
import { startBackgroundAnalysis } from '../../store/analysis/operations';
import { useCallback } from 'react';

export function useStartBackroundAnalysisMutation() {
    const dispatch = useAppDispatch();

    const startBackgroundAnalysisTrigger = useCallback(
        (url: string, competitors: string[], fromOnboardingWizard: boolean) => {
            return dispatch(startBackgroundAnalysis(url, competitors, fromOnboardingWizard));
        },
        [dispatch]
    );

    return {
        startBackgroundAnalysis: startBackgroundAnalysisTrigger,
    };
}
