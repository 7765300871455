import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Tabs, Row, Col } from "antd";
//import ReactGA4 from "react-ga4";
import CreditCardPaymentMethod from "../CreditCardPaymentMethod";
import PayPalPaymentMethod from "../PayPalPaymentMethod";
import PaymentMethodPromo from "../PaymentMethodPromo";
import SelectedPlanDetails from "../SelectedPlanDetails";
import { send3DSecureErrorEvent, send3DSecureSuccessEvent } from "../../../utils/analytics";

import "./PaymentLayout.less";

const { TabPane } = Tabs;

class PaymentLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: "credit_card",
      selectedPlan: this.props.defaultPlan,
      selectedIntervalUnit: "monthly"
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.handle3DSecureToken = this.handle3DSecureToken.bind(this);
    this.handle3DSecureError = this.handle3DSecureError.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultPlan !== prevProps.defaultPlan) {
      this.setState({
        selectedPlan: this.props.defaultPlan
      });
    }
  }

  onFormSubmit = (payload) => {
    this.props.onSubmit(
      payload["token"],
      payload["payment_method"],
      this.state.selectedPlan["code"],
      this.props.redirectTo
    );
  };

  handle3DSecureToken = (token) => {
    const tokenId = this.props.threeDSecure["token_id"];
    //ReactGA4.event("3d_secure_success");
    send3DSecureSuccessEvent();
    this.props.onSubmit(tokenId, "credit_card", this.state.selectedPlan["code"], this.props.redirectTo, token.id);
  };

  handle3DSecureError = (error) => {
    let userEmail = "unknown";
    try {
      userEmail = error?.cause?.payment_method?.billing_details?.email;
    } catch (_) {}

    send3DSecureErrorEvent(userEmail, error.name, error.code);
    // ReactGA4.event("3d_secure_error", {
    //   user: userEmail,
    //   error_name: error.name,
    //   error_code: error.code
    // });

    const msg =
      error["message"] ||
      "We were unable to authenticate your payment method. Please choose a different payment method and try again.";
    this.props.on3DSecureError(msg);
  };

  onTabChange = (key) => {
    this.setState({
      paymentMethod: key
    });
  };

  onPlanChange = (planCode) => {
    this.setState({
      selectedPlan: this.props.plans[this.state.selectedIntervalUnit].find((plan) => plan["code"] === planCode)
    });
  };

  onChangeIntervalUnit = (intervalUnit) => {
    this.setState({
      selectedIntervalUnit: intervalUnit
    });
  };

  render() {
    const actionTokenId = this.props.threeDSecure["action_token_id"];

    return (
      <div className={this.props.className}>
        <Card title={this.props.title} className="payment-card" bordered={false}>
          <Row gutter={24} className="main-row">
            <Col span={12} className="main-column">
              <Tabs className="payment-tabs" onChange={this.onTabChange} type="card">
                <TabPane tab="Credit Card" key="credit_card">
                  <CreditCardPaymentMethod
                    actionTokenId={actionTokenId}
                    buttonLabel={this.props.buttonLabel}
                    errorMessage={this.props.errorMessage}
                    isSubmitting={this.props.isSubmitting}
                    onSubmit={this.onFormSubmit}
                    on3DSecure={this.handle3DSecureToken}
                    on3DSecureError={this.handle3DSecureError}
                    showAvailableCards={true}
                  />
                </TabPane>

                <TabPane tab="PayPal" key="paypal">
                  {this.state.paymentMethod === "paypal" && (
                    <SelectedPlanDetails
                      selectedPlan={this.state.selectedPlan}
                      plans={this.props.plans}
                      onSelectPlan={(plan) => this.onPlanChange(plan)}
                      onChangeIntervalUnit={(intervalUnit) => this.onChangeIntervalUnit(intervalUnit)}
                      allowChangePlan={this.props.allowChangePlan}
                      freeTrial={this.props.freeTrial}
                    />
                  )}
                  <PayPalPaymentMethod onSubmit={this.onFormSubmit} buttonLabel={this.props.buttonLabel} />
                </TabPane>
              </Tabs>
            </Col>

            <Col span={12} className="main-column">
              {this.state.paymentMethod === "credit_card" &&
                (this.props.showSelectedPlan ? (
                  <SelectedPlanDetails
                    selectedPlan={this.state.selectedPlan}
                    plans={this.props.plans}
                    onSelectPlan={(plan) => this.onPlanChange(plan)}
                    onChangeIntervalUnit={(intervalUnit) => this.onChangeIntervalUnit(intervalUnit)}
                    allowChangePlan={this.props.allowChangePlan}
                    freeTrial={this.props.freeTrial}
                  />
                ) : null)}
              <PaymentMethodPromo
                paymentMethod={this.state.paymentMethod}
                includeRefundDetails={this.props.includeRefundDetails}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

PaymentLayout.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  on3DSecureError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  subscriptionError: PropTypes.string,
  threeDSecure: PropTypes.any,
  defaultPlan: PropTypes.oneOfType([() => null, PropTypes.string]),
  plans: PropTypes.any
};

export default PaymentLayout;
