import { createApi } from '@reduxjs/toolkit/query/react';
import { SSC_SEM_API_URL } from '../settings/config';
import { authRequest } from '../store/authorizedRequests';
import {
    FetchBacklinksOverviewParams,
    BacklinksOverviewData,
    FetchBacklinksParams,
    FetchBacklinksResponse,
    FetchReferringDomainsParams,
    FetchReferringDomainsResponse,
    FetchTldDistributionParams,
    FetchTldDistributionResponse,
    FetchTopAnchorsParams,
    FetchTopAnchorsResponse,
    FetchTopCountriesParams,
    FetchTopCountriesResponse,
} from './types';

const backlinksBaseUrl = `${SSC_SEM_API_URL}/dfs/backlinks`;

const RESOURCE_REFETCH_TIME = 7200;

export const backlinksApi = createApi({
    reducerPath: 'api.backlinks',

    keepUnusedDataFor: RESOURCE_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;

        try {
            const data = await authRequest(backlinksBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchBacklinksOverview: builder.query<BacklinksOverviewData, FetchBacklinksOverviewParams>({
            query: (args) => {
                const { domain } = args;

                return {
                    method: 'GET',
                    url: `/overview?domain=${encodeURIComponent(domain)}`,
                };
            },
        }),

        fetchBacklinks: builder.query<FetchBacklinksResponse, FetchBacklinksParams>({
            query: (args) => {
                const { domain, order_by, order_dir, offset } = args;

                return {
                    method: 'GET',
                    url: `?domain=${encodeURIComponent(domain)}&order_by=${order_by}&order_dir=${order_dir}&offset=${offset}`,
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { domain, order_by, order_dir } = queryArgs;
                return { domain, order_by, order_dir };
            },

            forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
                if (typeof currentArg === 'undefined') {
                    return false;
                }

                if (typeof previousArg === 'undefined') {
                    return true;
                }

                return currentArg.offset > previousArg.offset;
            },

            merge: (currentCacheData, responseData, otherArgs) => {
                return {
                    data: currentCacheData.data.concat(responseData.data),
                    has_more: responseData.has_more,
                    from_cache: responseData.from_cache,
                };
            },
        }),

        fetchReferringDomains: builder.query<FetchReferringDomainsResponse, FetchReferringDomainsParams>({
            query: (args) => {
                const { domain, offset } = args;

                return {
                    method: 'GET',
                    url: `/referring-domains?domain=${encodeURIComponent(domain)}&offset=${offset}`,
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { domain } = queryArgs;
                return { domain };
            },

            forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
                if (typeof currentArg === 'undefined') {
                    return false;
                }

                if (typeof previousArg === 'undefined') {
                    return true;
                }

                return currentArg.offset > previousArg.offset;
            },

            merge: (currentCacheData, responseData, otherArgs) => {
                return {
                    data: currentCacheData.data.concat(responseData.data),
                    has_more: responseData.has_more,
                    from_cache: responseData.from_cache,
                };
            },
        }),

        fetchTldDistribution: builder.query<FetchTldDistributionResponse, FetchTldDistributionParams>({
            query: (args) => {
                const { domain } = args;

                return {
                    method: 'GET',
                    url: `/tld-distribution?domain=${encodeURIComponent(domain)}`,
                };
            },

            transformResponse: (response, meta, args) => {
                return {
                    data: response,
                    has_more: false,
                };
            },
        }),

        fetchTopAnchors: builder.query<FetchTopAnchorsResponse, FetchTopAnchorsParams>({
            query: (args) => {
                const { domain, offset } = args;

                return {
                    method: 'GET',
                    url: `/top-anchors?domain=${encodeURIComponent(domain)}&offset=${offset}`,
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { domain } = queryArgs;
                return { domain };
            },

            forceRefetch: ({ currentArg, previousArg, state, endpointState }) => {
                if (typeof currentArg === 'undefined') {
                    return false;
                }

                if (typeof previousArg === 'undefined') {
                    return true;
                }

                return currentArg.offset > previousArg.offset;
            },

            merge: (currentCacheData, responseData, otherArgs) => {
                return {
                    data: currentCacheData.data.concat(responseData.data),
                    has_more: responseData.has_more,
                    from_cache: responseData.from_cache,
                };
            },
        }),

        fetchTopCountries: builder.query<FetchTopCountriesResponse, FetchTopCountriesParams>({
            query: (args) => {
                const { domain } = args;

                return {
                    method: 'GET',
                    url: `/top-countries?domain=${encodeURIComponent(domain)}`,
                };
            },

            transformResponse: (response, meta, args) => {
                return {
                    data: response,
                    has_more: false,
                };
            },
        }),
    }),
});

export const {
    useFetchBacklinksQuery,
    useFetchBacklinksOverviewQuery,
    useFetchReferringDomainsQuery,
    useFetchTldDistributionQuery,
    useFetchTopAnchorsQuery,
    useFetchTopCountriesQuery,
} = backlinksApi;
