import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";
import WebVitalChart from "../../shared/WebVitalChart/WebVitalChart";

const displayResult = (title, result) => {
  return (
    <React.Fragment>
      {result["duration"] && (
        <WebVitalChart
          markerAt={parseFloat((result["duration"] / 1000).toFixed(2))}
          maxGoodVal={2.5}
          minPoorVal={4}
          unitsSuffix={" s"}
        />
      )}

      {(result["node"] || result["text"]) && (
        <div className={`result-meta ${result["status"]}`}>
          <div className="title">Largest Contentful Paint element within the viewport:</div>

          {result["text"] && result["node"] && (
            <div>
              <strong>Text: </strong>
              {result["text"]}
            </div>
          )}

          {result["text"] && !result["node"] && <em>{result["text"]}</em>}

          {result["text"] && result["node"] && (
            <div>
              <strong>Html: </strong>
              <em>
                <span dangerouslySetInnerHTML={{ __html: result["node"] }} />
              </em>
            </div>
          )}

          {!result["text"] && result["node"] && <em dangerouslySetInnerHTML={{ __html: result["node"] }} />}
        </div>
      )}
      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    </React.Fragment>
  );
};

const LargestContentfulPaintSection = ({
  url,
  sectionKey,
  title,
  result,
  tooltip,
  relevance,
  passRate,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default LargestContentfulPaintSection;
