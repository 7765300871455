import { useEffect } from 'react';
import { useAddUrlToPreCrawlingQueueMutation, useFetchTrackedWebsitesQuery } from '../service';
import { useDispatchFetchTrackedWebsitesOverviews } from '../../analysis/hooks/useDispatchFetchTrackedWebsitesOverviews';

export function useFetchTrackedWebsitesWithOverviewsAndPreCrawling() {
    const [addUrlToPreCrawlingQueue] = useAddUrlToPreCrawlingQueueMutation();

    const { data, isLoading: isLoadingTrackedWebsites, isSuccess } = useFetchTrackedWebsitesQuery();

    const { isLoadingOverviews } = useDispatchFetchTrackedWebsitesOverviews(data ? data.tracked_websites : []);

    useEffect(() => {
        if (data) {
            const trackedWebsites = data.tracked_websites;
            trackedWebsites.forEach((trackedWebsite) => {
                if (!trackedWebsite.last_crawled) {
                    addUrlToPreCrawlingQueue({ url: trackedWebsite.url });
                }
            });
        }
    }, [addUrlToPreCrawlingQueue, data]);

    return {
        data,
        isLoading: isLoadingTrackedWebsites || isLoadingOverviews,
        isFetchTrackedWebsitesSuccess: isSuccess,
    };
}
