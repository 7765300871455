import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import atomDark from "./atom-dark";

const HighlighterComponent = (props) => {
  const handleScroll = (e) => {
    if (!(e.target.scrollHeight > e.target.clientHeight)) return; //prevent horizontal scrolling

    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      props.getMore && props.getMore();
    }
  };

  return (
    <SyntaxHighlighter
      customStyle={{ fontSize: "12px" }}
      wrapLines={true}
      lineProps={{ className: "line-wrapper" }}
      language="xml"
      style={atomDark}
      className={props.className}
      onScroll={handleScroll}
      showLineNumbers={true}
      linenumberprops={{
        className: "line-number"
      }}
      linenumbercontainerprops={{ className: "line-number-container", style: { paddingRight: "20px", float: "left" } }}>
      {props.report}
    </SyntaxHighlighter>
  );
};

export default HighlighterComponent;
