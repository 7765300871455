import React from "react";
import TrendIcon from "./TrendIcon";
import SummaryTooltip from "./SummaryTooltip";

function SiteSpeedSummary({ loadingTimeData }) {
  let firstValue = loadingTimeData.length > 0 ? (loadingTimeData[0]["value"] / 1000).toFixed(1) : undefined;
  let lastValue =
    loadingTimeData.length > 0 ? (loadingTimeData[loadingTimeData.length - 1]["value"] / 1000).toFixed(1) : undefined;

  return (
    <div className="dashboard-summary-section">
      <div className="dashboard-summary-section-title">
        Loading Time <SummaryTooltip resourceType={"loadingTime"} first={firstValue} last={lastValue} />
      </div>
      <div className="dashboard-summary-section-result">
        {loadingTimeData.length > 0 ? (
          <React.Fragment>
            <TrendIcon resourceType={"loadingTime"} first={firstValue} last={lastValue} /> {lastValue} s
          </React.Fragment>
        ) : (
          <span>No data</span>
        )}
      </div>
    </div>
  );
}

export default SiteSpeedSummary;
