import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import ReportCardTitle from "../shared/ReportCardTitle/ReportCardTitle";
import TimelineTicks from "./TimelineTicks";
import "./Timeline.less";

function Timeline(props) {
  return (
    <div className="outage-timeline-container">
      <div className="flexbox space-between flex-wrap">
        <ReportCardTitle title="Outage overview" domain={moment(props.currentDay).format("MMM DD, YYYY")} />

        <div className="outage-timeline custom-scrollbar">
          <div className="outage-days-container">
            <div className="date-range" style={{ marginBottom: "5px" }}>
              <span>{moment(props.startDate).format("MMM DD, YYYY")}</span>
              <span style={{ float: "right" }}>{moment(props.endDate).format("MMM DD, YYYY")}</span>
            </div>
            <ul className="days">
              {props.timelineDays.map(day => (
                <Tooltip key={`day-${day}`} title={moment(day).format("MMM DD, YYYY")}>
                  <li
                    className={`day ${Object.keys(props.outages).includes(day) ? "danger" : "success"} ${
                      props.currentDay === day ? "active" : ""
                    }`}
                    data-value={day}
                    onClick={event => props.onSelectDay(event)}></li>
                </Tooltip>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="outage-timeline hours-interval custom-scrollbar">
        <ul className="outage-interval-container">
          {
            <React.Fragment>
              {props.currentIntervals.map((interval, idx) => {
                return (
                  <li
                    className={`outage-interval 
                    ${Object.keys(interval).includes("guid") ? "danger" : "success"} 
                    ${Object.keys(interval).includes("in_future") ? "future" : ""}
                    ${idx === props.currentIntervalIdx ? "active" : ""}
                    `}
                    key={`interval-${idx}`}
                    data-idx={idx}
                    style={{ flexGrow: props.durationInMinutes(interval["start_at"], interval["end_at"]) }}
                    data-outage-guid={interval["guid"]}
                    onClick={event => props.onSelectOutage(event)}></li>
                );
              })}
            </React.Fragment>
          }
        </ul>

        <TimelineTicks intervals={props.currentIntervals} />
      </div>
    </div>
  );
}

export default Timeline;
