import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import { fetchOverviewsForUrlAndCompetitors } from "../store/analysis/operations";
import { changeArchivedReportsInterval } from "../store/analysis/creators";
import {
  getArchivedReportsInterval,
  getOverviewsForUrl,
  isFetchingOverviewsForMainUrlAndCompetitors,
  getSeoScoreProgressChartData,
  getLastSavedReportGuid
} from "../store/analysis/selectors";
import { getSelectedWebsiteUrl, getTrackedWebsiteCompetitors } from "../store/tracked_websites/selectors";
import ArchivedReportsOverview from "../components/ArchivedReports/ArchivedReportsOverview";

class ArchivedReportsOverviewContainer extends Component {
  constructor(props) {
    super(props);

    this.handleIntervalChange = this.handleIntervalChange.bind(this);
  }
  componentDidMount() {
    this.props.fetchOverviewsForUrlAndCompetitors(
      this.props.url,
      this.props.interval["start_date"],
      this.props.interval["end_date"]
    );
  }

  handleIntervalChange = date => {
    let current = moment();
    let startDate = date.startOf("month").format("YYYY-MM-DDTHH:mm:ss");
    let endDate = date.endOf("month").format("YYYY-MM-DDTHH:mm:ss");

    if (date.isSame(current, "month")) {
      endDate = current.format("YYYY-MM-DDTHH:mm:ss");
    }

    this.props.changeArchivedReportsInterval(startDate, endDate);
    this.props.fetchOverviewsForUrlAndCompetitors(this.props.url, startDate, endDate);
  };

  render() {
    return (
      <ArchivedReportsOverview
        url={this.props.url}
        isLoading={this.props.isLoading}
        startDate={this.props.interval["start_date"]}
        endDate={this.props.interval["end_date"]}
        overviews={this.props.overviews}
        handleIntervalChange={date => this.handleIntervalChange(date)}
        push={this.props.push}
        chartData={this.props.chartData}
        competitors={this.props.competitors}
        lastSavedReportGuid={this.props.lastSavedReportGuid}
      />
    );
  }
}

const mapStateToProps = state => ({
  url: getSelectedWebsiteUrl(state),
  isLoading: isFetchingOverviewsForMainUrlAndCompetitors(state, getSelectedWebsiteUrl(state)),
  interval: getArchivedReportsInterval(state),
  overviews: getOverviewsForUrl(state, getSelectedWebsiteUrl(state)),
  chartData: getSeoScoreProgressChartData(state),
  competitors: getTrackedWebsiteCompetitors(state),
  lastSavedReportGuid: getLastSavedReportGuid(state, getSelectedWebsiteUrl(state))
});

export default connect(mapStateToProps, { fetchOverviewsForUrlAndCompetitors, changeArchivedReportsInterval, push })(
  ArchivedReportsOverviewContainer
);
