export const isProfitWellStarted = state => {
    return state.trackers['profitWell'];    
}

export const isGtagRegistrationEventSent = state => {
    return state.trackers['gtag']['registration'];    
}

export const isGtagPaymentMethodEventSent = state => {
    return state.trackers['gtag']['payment_method'];    
}