export const formatTimestamp = (timestamp) => {
    const options = {year: 'numeric', month: 'short', day: 'numeric'};
    let lastCheckedDate = new Date(timestamp * 1000);
    return lastCheckedDate.toLocaleDateString("en-US", options);
};

export const getGoogleSearchUrl = (searchKeywords) => {
    return `https://www.google.com/search?q=${encodeURIComponent(searchKeywords)}`;
};

export const countOfListElements = (obj) => {
    return Object.values(obj).reduce((acc, cur) => {return acc + cur.length}, 0)
};

export const isObject = (value) => {
    return value && typeof value === 'object' && value.constructor === Object;
};

export const getUrl = (domain) => {
    return `http://${domain}`;
};

export const anchorAttributesForUrls = (urlList) => {
    return urlList.map(url => ({
        anchorLabel: url,
        target: "_blank"
    }))
};

export const anchorAttributesAndLabelForUrls = (listOfUrlsObject) => {
    return listOfUrlsObject.map(item => ({
        anchorLabel: item.url,
        label: item.cdn,
        target: "_blank"
    }))
};