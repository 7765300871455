import React from "react";
import "./ConsoleMessageList.less";
import HtmlContent from "../../shared/HtmlContent/HtmlContent";

// if long urls are present in message they are replaced with short ones
const getConsoleMessage = text => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, result => {
    const url = result.length > 80 ? `${result.slice(0, 39)}...${result.slice(-38)}` : result;
    return `<a href="${result}" rel="noopener noreferrer">${url}</a>`;
  });
};

const ConsoleMessageList = ({ title, consoleMessages }) => {
  return (
    <div className="console-message-list">
      {title && (
        <h3>
          {title}({consoleMessages.length}):
        </h3>
      )}

      <ul>
        {consoleMessages.map((item, index) => (
          <li className="console-message" key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.url}
            </a>
            <code>
              <HtmlContent content={getConsoleMessage(item.text)} />
            </code>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConsoleMessageList;
