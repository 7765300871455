import React from 'react';
import { Select } from 'antd';
import { TrackedWebsite } from '../../trackedWebsites/types';

const { Option } = Select;

type Props = {
    trackedWebsites: TrackedWebsite[];
    selectedWebsite: TrackedWebsite | null;
    onSelectedWebsiteChange: (newSelectedWebsite: TrackedWebsite | null) => void;
};

export default function TrackedWebsitesSelector({ trackedWebsites, selectedWebsite, onSelectedWebsiteChange }: Props) {
    const handleSelectedWebsiteGuidChange = (newSelectedWebsiteGuid: string) => {
        const newSelectedWebsite = trackedWebsites.find((trackedWebsite) => trackedWebsite.guid === newSelectedWebsiteGuid);

        if (newSelectedWebsite) {
            onSelectedWebsiteChange(newSelectedWebsite);
        }
    };

    return (
        <Select
            className="tracked_websites_selector"
            size="large"
            value={selectedWebsite ? selectedWebsite.guid : ''}
            onChange={handleSelectedWebsiteGuidChange}
        >
            {trackedWebsites.map((trackedWebsite) => (
                <Option
                    value={trackedWebsite.guid}
                    key={trackedWebsite.guid}
                >
                    {trackedWebsite.url}
                </Option>
            ))}
        </Select>
    );
}
