const loginValidationRules = {
  email: {
    presence: { allowEmpty: false }
    // email: {
    //   message: "^Please enter a valid email"
    // }
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: "^Minimum 6 characters"
    }
  }
};
export default loginValidationRules;
