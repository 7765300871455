import React from 'react';
import { Card, Row, Col } from 'antd';
import AddNewTrackedWebsiteButton from './AddNewTrackedWebsiteButton';
import { useNavigate } from '../router/hooks/useNavigate';

const { Meta } = Card;

const layoutSettings = [
    {
        pathname: '/analysis',
        iconClassName: 'seo-check',
        title: 'SEO Checkup',
        description: 'Perform a full SEO Checkup for your site',
    },
    {
        pathname: '/white-label/reports',
        iconClassName: 'brand',
        title: 'White Label Reports',
        description: 'Get customized SEO reports',
    },
    {
        pathname: '/site-speed',
        iconClassName: 'speed',
        title: 'Site Loading Speed',
        description: "Check your website's performance",
    },
    {
        pathname: '/broken-links',
        iconClassName: 'broken-link',
        title: 'Broken Links',
        description: 'Nobody likes a 404 error page',
    },
    {
        pathname: '/site-outage',
        iconClassName: 'uptime',
        title: 'Site Outage',
        description: "Check your website's uptime status",
    },
    {
        pathname: '/sitemap-generator',
        iconClassName: 'sitemap',
        title: 'Sitemap Generator',
        description: 'Generate sitemap.xml file',
    },
];

export default function DefaultLayout() {
    const navigate = useNavigate();

    const handleClick = (event: any) => {
        const pathname = event.currentTarget.getAttribute('data-target');
        navigate(pathname);
    };

    const servicesColumns = layoutSettings.map((service, index) => (
        <Col
            key={index}
            sm={24}
            lg={12}
            xl={8}
        >
            <Meta
                data-target={service.pathname}
                className="empty-dashboard-box"
                avatar={
                    <span className="custom-icon icon">
                        <span className={`icon custom-icon-${service.iconClassName}`}></span>
                    </span>
                }
                title={service.title}
                description={service.description} // @ts-ignore no onClick prop for Meta
                onClick={handleClick}
            />
        </Col>
    ));

    return (
        <Card
            title={
                <div className="ant-card-meta-detail">
                    <div className="ant-card-meta-title">Welcome to SEO ToolBox!</div>
                    <div className="ant-card-meta-description">Let's start improving your SEO</div>
                </div>
            }
        >
            <p style={{ marginBottom: '5px' }}>Setup your account in order to have a better SEO Site Checkup Toolbox experience.</p>
            <p>
                After the setup process you will have access to your Toolbox Dashboard, where you could see detailed information regarding your website's SEO
                performances.
            </p>

            <AddNewTrackedWebsiteButton label="Setup your account" />
            <Row
                gutter={[
                    { xs: 8, sm: 16, md: 24, lg: 24 },
                    { xs: 8, sm: 16, md: 24, lg: 24 },
                ]}
                style={{ marginTop: '24px' }}
            >
                {servicesColumns}
            </Row>
        </Card>
    );
}
