import React from 'react';
import { Chart, Geom, Axis, Tooltip } from 'bizcharts';
import { Empty } from 'antd';
import { ChartDataPoint } from '../backlinks/types';
import { getYAxisMax, getYAxisMin, tickFormatter } from '../backlinks/utils';

type Props = {
    chartData: ChartDataPoint[];
};

export default function DashboardBacklinksChart({ chartData }: Props) {
    const getScale = () => {
        let scale: { [key: string]: any } = {
            value: {
                type: 'linear',
                min: getYAxisMin(chartData),
                max: getYAxisMax(chartData),
                formatter: (val: number) => tickFormatter(val),
                tickCount: 5,
            },
            c_at: {
                type: 'timeCat',
                mask: 'MMM DD',
                tickCount: chartData.length,
            },
        };

        if (chartData.length > 1) {
            scale['c_at']['range'] = [0, 1];
        }
        return scale;
    };

    const handleTooltipChange = (event: any) => {
        let tooltipItems = [...event.items];

        tooltipItems.forEach((item, idx) => {
            tooltipItems[idx].value = parseInt(tooltipItems[idx].point._origin.value).toLocaleString();
        });
    };

    return (
        <>
            {chartData.length === 0 && (
                <Empty
                    className="empty-state"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data for this interval"
                />
            )}

            {chartData.length > 0 && (
                <div
                    className="backlinks-overview-chart"
                    style={{ marginTop: '32px' }}
                >
                    <Chart
                        padding="auto"
                        height={250}
                        data={chartData}
                        scale={getScale()}
                        animate={false}
                        forceFit
                        onTooltipChange={handleTooltipChange}
                    >
                        <Axis name="c_at" />
                        <Axis
                            name="value"
                            //nice={false}
                        />
                        <Tooltip />
                        <Geom
                            type="line"
                            position="c_at*value"
                            size={2}
                            color={['type', '#4BB2C5']}
                        />
                        <Geom
                            type="point"
                            position="c_at*value"
                            size={4}
                            shape={'circle'}
                            color={['type', '#4BB2C5']}
                            style={{
                                lineWidth: 1,
                            }}
                        />
                    </Chart>
                </div>
            )}
        </>
    );
}
