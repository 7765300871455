import React from "react";
import { Progress, Badge } from "antd";

const CompetitorSeoScore = ({ competitorUrl, competitorsSectionsStatusCounter, competitorSeoScore }) => {
  const getColorByScore = score => {
    if (score < 50) return "#f63842";
    if (score >= 50 && score < 75) return "#ffab00";
    if (score >= 75) return "#34bc6e";
  };

  return (
    <div className="competitor-item">
      <div className="summary-graph">
        <div className="summary-graph-total">
          <Progress
            type="circle"
            percent={competitorSeoScore}
            strokeWidth={8}
            strokeColor={getColorByScore(competitorSeoScore)}
            format={percent => `${percent}/100`}
            data-cy="competitor-score"
          />
        </div>
      </div>
      <div className="meta-info">
        <div className="url">{competitorUrl}</div>
        <div>
          <span>
            {competitorsSectionsStatusCounter.failed} <Badge status="error" />
          </span>
          <span>
            {" "}
            {competitorsSectionsStatusCounter.warning} <Badge status="warning" />
          </span>
          <span>
            {" "}
            {competitorsSectionsStatusCounter.passed} <Badge status="success" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompetitorSeoScore;
