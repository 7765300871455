import {
  GET_MONITOR_LOCATIONS_IN_PROGRESS,
  GET_MONITOR_LOCATIONS_SUCCESS,
  GET_MONITOR_LOCATIONS_FAILED
} from "./types";

const initial_state = {
  data: null,
  network_activity: []
};

const networkActivityMessage = "fetch_monitor_locations";

const monitorLocationsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_MONITOR_LOCATIONS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, networkActivityMessage]
      };
    case GET_MONITOR_LOCATIONS_SUCCESS:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== networkActivityMessage),
        data: action.regions,
        order: action.order
      };
    case GET_MONITOR_LOCATIONS_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(item => item !== networkActivityMessage)
      };
    default:
      return state;
  }
};

export default monitorLocationsReducer;
