import React, { useContext } from 'react';
import AddTrackedWebsiteWizard from './wizard/AddTrackedWebsiteWizard';
import { useAddTrackedWebsiteWithBackgroundSync } from './hooks/useAddTrackedWebsiteWithBackgroundSync';
import { AddTrackedWebsitesParams } from './types';
import { SelectedWebsiteContext } from './ContextWrapper';

type Props = {
    fromOnboarding: boolean;
    onFinishAddedWebsite: () => void;
};

export default function AddNewTrackedWebsite({ fromOnboarding, onFinishAddedWebsite }: Props) {
    const { setSelectedWebsite } = useContext(SelectedWebsiteContext);

    const { addTrackedWebsite } = useAddTrackedWebsiteWithBackgroundSync();

    const handleAddTrackedWebsite = async (params: AddTrackedWebsitesParams) => {
        try {
            const addedWebsite = await addTrackedWebsite(params);

            setSelectedWebsite(addedWebsite);
        } catch (error) {
        } finally {
            onFinishAddedWebsite();
        }
    };

    return (
        <AddTrackedWebsiteWizard
            fromOnboarding={fromOnboarding}
            onAddTrackedWebsite={handleAddTrackedWebsite}
        />
    );
}
