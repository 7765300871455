import React from 'react';
import { Alert } from 'antd';
import { TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';
import { useUpdateTrackedWebsiteSettingsMutation } from '../../../trackedWebsites/service';
import NotificationsInput from './NotificationsInput';

type Props = { selectedWebsite: TrackedWebsite; setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void };

export default function NotificationsTab({ selectedWebsite, setSelectedWebsite }: Props) {
    const [updateTrackedWebsiteSettings] = useUpdateTrackedWebsiteSettingsMutation();

    const handleUpdateNotifications = async (args: UpdateTrackedWebsitesParams) => {
        try {
            const updatedSettings = await updateTrackedWebsiteSettings(args).unwrap();
            setSelectedWebsite({ ...selectedWebsite, settings: { ...selectedWebsite.settings, ...updatedSettings } });
        } catch (error) {}
    };

    return (
        <div className="notifications-tab">
            <Alert
                className="disclaimer"
                message="Please note!"
                description={
                    <>
                        By enabling the notifications below, you confirm that you want to receive email alerts when certain events happen on your website:{' '}
                        <a
                            href={selectedWebsite.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {selectedWebsite.url}
                        </a>
                    </>
                }
                type="info"
                showIcon
            />
            <NotificationsInput
                key={selectedWebsite.guid}
                selectedWebsite={selectedWebsite}
                onUpdateNotifications={handleUpdateNotifications}
            />
        </div>
    );
}
