import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SeoScoreProgressBox from "../../components/Dashboard/SeoScoreProgress/SeoScoreProgressBox";
import { getSelectedWebsiteUrl, getTrackedWebsiteCompetitors } from "../../store/tracked_websites/selectors";
import {
  getSeoScoreProgressChartData,
  getLastSavedReportGuid,
  isFetchingOverviewsForMainUrlAndCompetitors,
  hasOverviewsForMainUrl
} from "../../store/analysis/selectors";
import { fetchOverviewsForUrlAndCompetitors } from "../../store/analysis/operations";
import { DASHBOARD_LONG_POLLING_INTERVAL_IN_MS } from "../../settings/config";
import { isWebsiteLastAddedAndHasNoSeoReportData } from "../../store/tracked_websites/selectors";

class SeoScoreProgressContainer extends Component {
  constructor(props) {
    super(props);

    this.refreshInterval = null;
  }

  componentDidMount() {
    this.props.fetchOverviewsForUrlAndCompetitors(this.props.url, this.props.startDate, this.props.endDate);
    if (this.props.shouldLongPolling) {
      this.refreshInterval = setInterval(_ => {
        if (this.props.hasData || !this.props.shouldLongPolling) {
          clearInterval(this.refreshInterval);
        } else {
          this.props.fetchOverviewsForUrlAndCompetitors(this.props.url, this.props.startDate, this.props.endDate);
        }
      }, DASHBOARD_LONG_POLLING_INTERVAL_IN_MS);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
      this.props.fetchOverviewsForUrlAndCompetitors(this.props.url, this.props.startDate, this.props.endDate);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  render() {
    return (
      <SeoScoreProgressBox
        url={this.props.url}
        isFetching={this.props.isFetching}
        competitors={this.props.competitors}
        chartData={this.props.chartData}
        hasData={this.props.hasData}
        lastSavedReportGuid={this.props.lastSavedReportGuid}
        push={this.props.push}
        customInterval={this.props.customInterval}
        isWebsiteLastAddedAndHasNoSeoReportData={this.props.isWebsiteLastAddedAndHasNoSeoReportData}
      />
    );
  }
}

const mapStateToProps = state => ({
  url: getSelectedWebsiteUrl(state),
  isFetching: isFetchingOverviewsForMainUrlAndCompetitors(state),
  chartData: getSeoScoreProgressChartData(state),
  hasData: hasOverviewsForMainUrl(state),
  lastSavedReportGuid: getLastSavedReportGuid(state, getSelectedWebsiteUrl(state)),
  competitors: getTrackedWebsiteCompetitors(state),
  isWebsiteLastAddedAndHasNoSeoReportData: isWebsiteLastAddedAndHasNoSeoReportData(state)
});

export default connect(mapStateToProps, { fetchOverviewsForUrlAndCompetitors, push })(SeoScoreProgressContainer);
