import moment from "moment";
import {getBacklinksOverviewForInterval} from '../../backlinks/utils';

export const getTabsSettings = (state) => {
    return state.backlinks.tabs_settings;
};

export const getOverviewSettings = (state) => {
    return state.backlinks.overview_settings;
};

export const isFetchingOverview = (state, domain) => {
    return state["backlinks"]["network_activity"].includes(`fetch_overview_${domain}`);
};

export const getOverview = (state, domain, startDate, endDate) => {
    let overviewForInterval = {};
    const overview = state["backlinks"]["overview"][domain];

    if (!overview) {
        return overviewForInterval;
    }

    const desiredInterval = getDesiredInterval(startDate, endDate);
    const overviewSettingsKeys = getOverviewSettings(state).map((setting) => setting["key"]);
    overviewForInterval = overviewSettingsKeys.reduce((a, b) => Object.assign(a, { [b]: [] }), {});

    overviewSettingsKeys.forEach((key) => {
        overview[key].forEach((overviewObj) => {
            if (desiredInterval.includes(overviewObj["c_at"].split("T")[0])) {
                overviewForInterval[key].push(overviewObj);
            }
        });
    });

    return overviewForInterval;
};

export const hasOverviewForInterval = (state, domain, startDate, endDate) => {
    let hasOverview = false;
    const overview = state["backlinks"]["overview"][domain];

    if (!overview) {
        return hasOverview;
    }

    const totalBacklinks = overview["total_backlinks"];
    const desiredInterval = getDesiredInterval(startDate, endDate);

    hasOverview = desiredInterval.every((date) =>
        totalBacklinks.map((elem) => elem["c_at"].split("T")[0]).includes(date)
    );
    return hasOverview;
};

export const hasDataForDashboard = (state, domain, startDate, endDate) => {
    let hasData = false;
    const currentDate = moment().utc().format("YYYY-MM-DD");
    const overviews = getOverview(state, domain, startDate, endDate);
    if (overviews["total_backlinks"]) {
        hasData = overviews["total_backlinks"].some((overview) => overview["c_at"].split("T")[0] === currentDate);
    }
    return hasData;
};

const getDesiredInterval = (startDate, endDate) => {
    const desiredInterval = [];
    let currentDate = moment().utc().format("YYYY-MM-DDT00:00:00");
    let newEndDate = endDate;

    if (moment(endDate).isAfter(currentDate)) {
        newEndDate = currentDate;
    }

    while (moment(startDate) <= moment(newEndDate)) {
        desiredInterval.push(moment(startDate).format("YYYY-MM-DD"));
        startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
    }
    return desiredInterval;
};

export const getResults = (state, domain, resourceType, sortBy) => {
    try {
        let results = state["backlinks"][resourceType][domain][sortBy]["data"];
        return results.map((result, idx) => {
            result["key"] = idx + 1;
            return result;
        });
    } catch (e) {
        return [];
    }
};

export const getOffset = (state, domain, resourceType, sortBy) => {
    return getResults(state, domain, resourceType, sortBy).length;
};

export const isFetchingResults = (state, domain, resourceType, sortBy) => {
    return state["backlinks"]["network_activity"].includes(`fetch_${resourceType}_${domain}_${sortBy}`);
};

export const isFirstFetch = (state, domain, resourceType, sortBy) => {
    return (
        isFetchingResults(state, domain, resourceType, sortBy) && getOffset(state, domain, resourceType, sortBy) === 0
    );
};

export const getHasMore = (state, domain, resourceType, sortBy) => {
    try {
        return state["backlinks"][resourceType][domain][sortBy]["has_more"];
    } catch (e) {
        return false;
    }
};

export const getResultsToExportAsJson = (state, domain, resourceType, sortBy) => {
    let results = getResults(state, domain, resourceType, sortBy);
    results.forEach((result, idx) => {
        delete results[idx]["key"];
    });
    return JSON.stringify(results);
};

export const getResultsToExportAsCsv = (state, domain, resourceType, sortBy) => {
    let results = getResults(state, domain, resourceType, sortBy);
    let csv = "";
    try {
        let columns = Object.keys(results[0]);
        columns.forEach((column) => {
            csv = `${csv}${column};`;
        });
        csv = csv.replace(/.$/, "\n");

        results.forEach((result) => {
            columns.forEach((column) => {
                let value = result[column] || "";
                csv = `${csv}${value};`;
            });
            csv = csv.replace(/.$/, "\n");
        });
    } catch (e) {}
    return csv;
};

export const getTotalBacklinks = (state, domain, startDate, endDate) => {
    let overview = getOverview(state, domain, startDate, endDate);

    if (Object.keys(overview).length === 0) {
        return [];
    }

    return overview["total_backlinks"];
};


const getFetchBacklinksOverviewQuery = (state, domain) => {
    const queryKey = `fetchBacklinksOverview({"domain":"${domain}"})`;

    return state['api.backlinks']['queries'][queryKey];
}  

export const isFetchingBacklinksOverviewRTQ = (state, domain) => {
    try {
        const query = getFetchBacklinksOverviewQuery(state, domain);

        if(query) {
            return query['status'] === 'pending';
        }
    }
    catch(error) {}

    return false;
};

export const getTotalBacklinksRTQ = (state, domain, startDate, endDate) => {
    try {
        const query = getFetchBacklinksOverviewQuery(state, domain);

        if(query) {
            const overview = query['data'];

            return overview ? getBacklinksOverviewForInterval(overview, startDate, endDate).total_backlinks : [];
        }
    }   
    catch(error) {}

    return [];
};