import React from "react";
import { Progress, Tooltip, Icon } from "antd";
import { AVERAGE_SEO_SCORE_OF_TOP_100_WEBSITES } from "../../settings/config";

const SeoScore = ({ seoScore, sectionsStatusCounter, sectionsStatusProgress, inProgress, isFullReport }) => {
  const getColorByScore = (score) => {
    if (score < 50) return "#f63842";
    if (score >= 50 && score < 75) return "#ffab00";
    if (score >= 75) return "#34bc6e";
  };

  const getScoreOverview = (score, averageScore, issuesNo) => {
    if (score < 50) {
      return (
        <span className="description">
          Unfortunately, this website received an SEO score of <strong>{score} out of 100</strong>, which is
          significantly lower than the average score of <strong>{averageScore}</strong>. Our analysis has identified{" "}
          <strong>{issuesNo} important issues</strong> that need to be addressed urgently to improve your website's
          performance and avoid further damage to your search engine visibility.
        </span>
      );
    }
    if (score >= 50 && score < averageScore) {
      return (
        <span className="description">
          This website received an SEO score of <strong>{score} out of 100</strong>, which is below the average score of{" "}
          <strong>{averageScore}</strong>. However, there are <strong>{issuesNo} important issues</strong> that need to
          be fixed to improve your website's ranking on search engines and enhance its overall performance.
        </span>
      );
    }
    if (score === averageScore) {
      return (
        <span className="description">
          This website received an SEO score of <strong>{score} out of 100</strong>, which is the same as the average
          score of <strong>{averageScore}</strong>. Our analysis has identified{" "}
          <strong>{issuesNo} important issues</strong> that can be addressed to further enhance your website's
          performance and improve its search engine visibility.
        </span>
      );
    }
    if (score > averageScore) {
      return (
        <span className="description">
          This website received an SEO score of <strong>{score} out of 100</strong>, which is higher than the average
          score of <strong>{averageScore}</strong>. Our analysis has identified{" "}
          <strong>{issuesNo} important issues</strong> that can be addressed to further enhance your website's
          performance and improve its search engine visibility.
        </span>
      );
    }
  };

  return (
    <div className={`summary-graph ${inProgress ? "in-progress" : ""}`}>
      <div className="summary-graph-wrapper">
        <div className="summary-graph-total">
          <Progress
            type="circle"
            percent={seoScore}
            strokeWidth={8}
            strokeColor={getColorByScore(seoScore)}
            format={(percent) => `${percent}/100`}
            data-cy="main-url-score"
          />
          <span className="progress-label">SEO Score</span>
        </div>

        {isFullReport && (
          <div className="avg-score">
            <span>Average SEO score of top 100 sites: {AVERAGE_SEO_SCORE_OF_TOP_100_WEBSITES}%</span>
            <Tooltip
              placement="right"
              title={
                "This value indicates the average SEO score of the top 100 most visited websites in the US (in the past 12 months)"
              }>
              <Icon type="info-circle" theme="filled" className="info-icon" />
            </Tooltip>
          </div>
        )}
      </div>

      <div className="summary-graph-split">
        {isFullReport && (
          <div className="score-overview">
            {getScoreOverview(seoScore, AVERAGE_SEO_SCORE_OF_TOP_100_WEBSITES, sectionsStatusCounter.failed)}
          </div>
        )}

        <div className="progress-item failed" data-cy="main-url-failed-counter">
          <span className="progress-label">
            <strong>{sectionsStatusCounter.failed}</strong> Failed
          </span>
          <Progress percent={sectionsStatusProgress.failed} showInfo={false} />
        </div>

        <div className="progress-item warning">
          <span className="progress-label" data-cy="main-url-warning-counter">
            <strong>{sectionsStatusCounter.warning}</strong> Warnings
          </span>
          <Progress percent={sectionsStatusProgress.warning} showInfo={false} />
        </div>

        <div className="progress-item passed">
          <span className="progress-label" data-cy="main-url-passed-counter">
            <strong>{sectionsStatusCounter.passed}</strong> Passed
          </span>
          <Progress percent={sectionsStatusProgress.passed} showInfo={false} />
        </div>
      </div>
    </div>
  );
};

export default SeoScore;
