import React, { Component } from "react";
import { Card } from "antd";
import moment from "moment";
import ImprovementsList from "./ImprovementsList";

const { Meta } = Card;

class ImprovementsListLayout extends Component {
  render() {
    if(this.props.importantFixes.length === 0){
      return null;
    }
    return (
      <Card className="report-card to-do-card">
        <div className="card-header">
          <Meta
            title="Issues to fix"
            description={`Last found on ${moment(this.props.createdAt, "YYYY-MM-DDTHH:mm:ss").format(
              "MMM DD YYYY, hh:mm A"
            )}`}
          />{" "}
        </div>
        <ImprovementsList importantFixes={this.props.importantFixes} addLinksToIssues={true} />
      </Card>
    );
  }
}

export default ImprovementsListLayout;
