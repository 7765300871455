import React, { Component } from "react";
import moment from "moment";
import { Card, Divider, Icon } from "antd";
import DashboardBox from "../DashboardBox";
import DashboardCardTitle from "../DashboardCardTitle";
import SeoScoreSummary from "./SeoScoreSummary";
import BacklinksSummary from "./BacklinksSummary";
import TopKeywordsSummary from "./TopKeywordsSummary";
import SiteSpeedSummary from "./SiteSpeedSummary";
import SiteOutageSummary from "./SiteOutageSummary";
import "./Summary.less";

export class SummaryBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const firstFetchIsInProgress =
            this.props.isFetching && prevState.loading === null && this.state.loading === null;
        const fetchIsFinished = prevProps.isFetching && !this.props.isFetching;
        const hasData =
            this.props.hasSeoScores &&
            this.props.hasBacklinks &&
            this.props.hasTopKeywordsOverview &&
            this.props.hasLoadingTime &&
            this.props.hasOutageData;

        if (firstFetchIsInProgress && !hasData) {
            this.setState({ loading: true });
        }
        if ((firstFetchIsInProgress && hasData) || fetchIsFinished) {
            this.setState({ loading: false });
        }
    }

    render() {
        if (this.state.loading === null) {
            return null;
        }
        return (
            <div className="dashboard-seo-analysis-summary">
                {this.state.loading === true && <DashboardBox />}
                {this.state.loading === false && (
                    <Card
                        data-cy="summary-box"
                        title={<DashboardCardTitle title={this.props.url} description="Summary" />}
                        extra={
                            this.props.seoScores.length > 0 ? (
                                <div
                                    className="card-link flexbox align-center"
                                    onClick={() =>
                                        this.props.push(
                                            `/analysis/report?guid=${this.props.lastSavedReportGuid}&url=${this.props.url}`
                                        )
                                    }>
                                    <span>View report</span>
                                    <Icon type="right" />
                                </div>
                            ) : (
                                ""
                            )
                        }>
                        <SeoScoreSummary seoScores={this.props.seoScores} />
                        <Divider />
                        <BacklinksSummary totalBacklinks={this.props.totalBacklinks} />
                        <Divider />
                        <TopKeywordsSummary topKeywordsAvgPos={this.props.topKeywordsAvgPos} />
                        <Divider />
                        <SiteSpeedSummary loadingTimeData={this.props.loadingTimeData} />
                        <Divider />
                        <SiteOutageSummary currentContext={this.props.currentContext} />
                        <Divider />
                        <div className="last-update-copy">
                            {this.props.lastUpdatedAt
                                ? `Last updated: ${moment(this.props.lastUpdatedAt, "YYYY-MM-DDTHH:mm:ss").format(
                                      "MMM DD, YYYY"
                                  )}`
                                : ""}
                            &nbsp;
                            <span className="fake-link" onClick={this.props.generateNewReport}>
                                Run report now
                                <Icon type="right" />
                            </span>
                        </div>
                    </Card>
                )}
            </div>
        );
    }
}

export default SummaryBox;
