import React from 'react';

type Props = {
    url?: string;
    anchor: string;
};

export default function AnchorTableCell({ url, anchor }: Props) {
    return (
        <>
            {url && (
                <div className="truncate">
                    <a
                        target="_blank"
                        href={url}
                        rel="noopener noreferrer"
                    >
                        {anchor}
                    </a>
                </div>
            )}
            {!url && <>-</>}
        </>
    );
}
