import { createApi } from '@reduxjs/toolkit/query/react';
import { authRequest } from '../store/authorizedRequests';
import { LOADING_TIME_API_URL } from '../settings/config';
import {
    FetchLoadingTimeResult,
    FetchLoadingTimeQueryParams,
    SendLoadingTimeResult,
    SendLoadingTimeParams,
    FetchMultipleRegionsLoadingTimeResult,
    fetchMultipleRegionsLoadingTimeQueryParams,
} from './types';
import moment from 'moment';

const siteSpeedBaseUrl = `${LOADING_TIME_API_URL}/loading-time`;
const SITE_SPEED_REFETCH_TIME = 7200;

export const siteSpeedApi = createApi({
    reducerPath: 'api.siteSpeed',
    keepUnusedDataFor: SITE_SPEED_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;
        try {
            const data = await authRequest(siteSpeedBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchLoadingTime: builder.query<FetchLoadingTimeResult, FetchLoadingTimeQueryParams>({
            query: (args) => {
                const { url, region, startDate, endDate, groupBy } = args;
                return {
                    url: `?url=${url}&region=${region}&start_date=${startDate}&end_date=${endDate}&group_by=${groupBy}`,
                    method: 'GET',
                };
            },

            // If there is no speed data try to refetch after one minute
            forceRefetch: ({ endpointState, currentArg, previousArg }) => {
                if (endpointState && endpointState.data) {
                    const cachedResults = endpointState.data as FetchLoadingTimeResult;
                    if (cachedResults.reports.length === 0) {
                        const currentStartDate = moment();
                        const previousStartDate = moment(endpointState.fulfilledTimeStamp);

                        if (currentStartDate.isAfter(previousStartDate.add(1, 'minute'))) {
                            return true;
                        }
                    }
                }
                return false;
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { url, region, interval } = queryArgs;
                return { url, region, interval };
            },
        }),

        fetchMultipleRegionsLoadingTime: builder.query<FetchMultipleRegionsLoadingTimeResult, fetchMultipleRegionsLoadingTimeQueryParams>({
            queryFn: async (args, queryApi, extraOptions, baseQuery) => {
                const { url, regions, startDate, endDate } = args;

                const promises = regions.map((region) =>
                    baseQuery({
                        url: `?url=${url}&region=${region}&start_date=${startDate}&end_date=${endDate}&group_by=day`,
                        method: 'GET',
                    })
                );
                const results = await Promise.all(promises);

                let accumulatedResults: any[] = [];
                let accumulatedErrors: unknown[] = [];

                results.forEach((result) => {
                    if (result.data) {
                        accumulatedResults.push(result.data);
                    }
                    if (result.error) {
                        accumulatedErrors.push(result.error);
                    }
                });

                return accumulatedResults.length > 0 ? { data: { results: accumulatedResults } } : { error: accumulatedErrors };
            },
        }),

        sendLoadingTimeRequest: builder.mutation<SendLoadingTimeResult, SendLoadingTimeParams>({
            query: (args) => {
                return {
                    url: '',
                    method: 'POST',
                    body: args,
                };
            },
        }),
    }),
});

export const { useFetchLoadingTimeQuery, useFetchMultipleRegionsLoadingTimeQuery, useSendLoadingTimeRequestMutation } = siteSpeedApi;
