import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAffectedPages } from "../../store/broken_links/operations";
import { getSelectedWebsiteDomain } from "../../store/tracked_websites/selectors";
import {
  isFetchingAffectedPages,
  getAffectedPages,
  hasMoreAffectedPages,
  getAffectedPagesLastUap
} from "../../store/broken_links/selectors";
import { resetAffectedPages } from "../../store/broken_links/creators";
import { AffectedPagesModal } from "../../components/BrokenLinks";

class AffectedPages extends Component {
  render() {
    return (
      <AffectedPagesModal
        url={this.props.url}
        domain={this.props.domain}
        isLoading={this.props.isLoading}
        affectedPages={this.props.affectedPages}
        hasMore={this.props.hasMore}
        lastUat={this.props.lastUat}
        fetchAffectedPages={this.props.fetchAffectedPages}
        resetAffectedPages={this.props.resetAffectedPages}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { url } = ownProps;
  return {
    domain: getSelectedWebsiteDomain(state),
    isLoading: isFetchingAffectedPages(state, url),
    affectedPages: getAffectedPages(state, url),
    hasMore: hasMoreAffectedPages(state, url),
    lastUat: getAffectedPagesLastUap(state, url)
  };
};

export default connect(mapStateToProps, { fetchAffectedPages, resetAffectedPages })(AffectedPages);
