import React from "react";
import ImprovementsListWrapper from "../../containers/SeoReport/ImprovementsListWrapper";
import SeoReportScoreWrapper from "../../containers/SeoReport/SeoReportScoreWrapper";

const SeoReportSummaryLayout = (props) => {
  return (
    <div id={props.id}>
      <SeoReportScoreWrapper mainUrl={props.mainUrl} reportGuid={props.reportGuid} isFullReport={props.isFullReport} />
      <ImprovementsListWrapper reportGuid={props.reportGuid} />
    </div>
  );
};

export default SeoReportSummaryLayout;
