import React, { useState } from 'react';
import { Table, Button, Result, Icon, Alert } from 'antd';
import { useFetchBrokenLinksQuery } from './service';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import AffectedPages from './AffectedPages';
import moment from 'moment';
import { BrokenLink, BrokenLinksTableRecord } from './types';

const getBrokenLinksDataSource = (brokenLinks: BrokenLink[], domain: string) => {
    return brokenLinks.map((link, idx) => ({
        key: idx + 1,
        checkedAt: moment(link.updated_at, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD, YYYY, HH:mm'),
        brokenLink: link.url,
        domain: domain,
    }));
};

const columns = [
    {
        title: '#',
        dataIndex: 'key',
        width: '45px',
        className: 'no-wrap',
    },
    {
        title: 'Check date',
        className: 'broken-links-check-date-column no-wrap',
        dataIndex: 'checkedAt',
        key: 'checkedAt',
        width: '170px',
    },
    {
        title: 'Broken link',
        dataIndex: 'brokenLink',
        key: 'brokenLink',
        className: 'broken-links-column no-wrap',
        render: (_: any, record: BrokenLinksTableRecord) => (
            <a
                target="_blank"
                href={record.brokenLink}
                rel="noopener noreferrer"
            >
                {record.brokenLink}
            </a>
        ),
    },
    {
        title: 'Referring pages',
        key: 'affectedPages',
        className: 'al-right no-wrap',
        width: '138px',
        render: (_: any, record: BrokenLinksTableRecord) => (
            <AffectedPages
                domain={record.domain}
                url={record.brokenLink}
            />
        ),
    },
];

const alertMessages = {
    internal: (
        <>
            The internal URLs below were reported as <strong>broken links</strong>. These URLs are linked from other pages within the site, and should be fixed
            or removed.
        </>
    ),
    external: (
        <>
            The external URLs below (pointing outside the website) were reported as <strong>broken links</strong>. These URLs are linked from other pages within
            the site, and should be fixed or removed.
        </>
    ),
};

type Props = {
    domain: string;
    type: 'internal' | 'external';
};

export default function BrokenLinksTable({ domain, type }: Props) {
    const [lastUrlHash, setLastUrlHash] = useState<string>('');

    const {
        data: brokenLinks,
        isLoading,
        isFetching: isLoadingMoreLinks,
    } = useFetchBrokenLinksQuery({
        domain: domain,
        linkType: type,
        urlHash: lastUrlHash ? lastUrlHash : '',
    });

    const hasBrokenLinks = !isLoading && brokenLinks && brokenLinks.broken_links.length > 0;
    const hasNoBrokenLinks = !isLoading && brokenLinks && brokenLinks.broken_links.length === 0;
    const hasMore = brokenLinks?.last_url_hash ? true : false;

    const handleLoadMore = () => {
        if (brokenLinks && brokenLinks.last_url_hash) {
            setLastUrlHash(brokenLinks.last_url_hash);
        }
    };
    return (
        <>
            {isLoading && <LoadingIndicator />}
            {hasBrokenLinks && (
                <>
                    <Alert
                        type="info"
                        message={''}
                        description={<p style={{ marginBottom: 0 }}>{alertMessages[type]}</p>}
                        style={{ marginBottom: '2em' }}
                    />

                    <Table
                        className="broken-links-table custom-scrollbar"
                        dataSource={getBrokenLinksDataSource(brokenLinks.broken_links, domain)}
                        columns={columns}
                        pagination={false}
                    />
                    {hasMore && (
                        <Button
                            className="load-more"
                            type="primary"
                            loading={isLoadingMoreLinks}
                            onClick={handleLoadMore}
                        >
                            Load More
                        </Button>
                    )}
                </>
            )}
            {hasNoBrokenLinks && (
                <Result
                    icon={
                        <Icon
                            type="file-done"
                            theme="outlined"
                        />
                    }
                    title="Great job: everything looks perfect!"
                    subTitle={`We didn't find ${type} broken links in any crawled page!`}
                />
            )}
        </>
    );
}
