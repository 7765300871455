import React, { Component } from "react";
import { connect } from "react-redux";
import InvoiceHistoryLayout from "../../components/Sales/InvoiceHistoryLayout";
import { fetchInvoices, fetchPdfInvoice } from "../../store/sales/operations";
import { isFetchingInvoices, getInvoices, isDownloadingInvoice } from "../../store/sales/selectors";

export class InvoiceHistory extends Component {
  componentDidMount() {
    this.props.fetchInvoices();
  }
  render() {
    return (
      <InvoiceHistoryLayout
        isLoading={this.props.isFetchingInvoices}
        invoices={this.props.invoices}
        onDownloadInvoice={this.props.fetchPdfInvoice}
        isDownloadingInvoice={this.props.isDownloadingInvoice}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetchingInvoices: isFetchingInvoices(state),
  invoices: getInvoices(state),
  isDownloadingInvoice: isDownloadingInvoice(state)
});

export default connect(mapStateToProps, { fetchInvoices, fetchPdfInvoice })(InvoiceHistory);
