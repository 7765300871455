import React, { useState } from 'react';
import { Card, Icon, Input, Button, Modal, Form } from 'antd';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';
import Backlinks from './Backlinks';

const domainValidationRegex = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;

export default function BacklinksForm() {
    const [domain, setDomain] = useState('');
    const [error, setError] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDomain(event.target.value);
        setError('');
    };

    const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        let trimmedDomain = domain.toLowerCase().trim().replace('http://', '').replace('https://', '');

        if (domainValidationRegex.test(trimmedDomain)) {
            setDomain(trimmedDomain);
            setError('');
            setIsModalVisible(true);
        } else {
            setError('Invalid URL');
        }
    };

    const closeModal = () => {
        setDomain('');
        setError('');
        setIsModalVisible(false);
    };

    return (
        <Card className="analysis-form-card">
            <Form
                onSubmit={onSubmit}
                className="analysis-form"
            >
                <Form.Item
                    validateStatus={error ? 'error' : 'validating'}
                    help={error ? 'Provide a valid domain or url' : ''}
                >
                    <Input.Group
                        className="compact-input-group"
                        compact
                        size="large"
                    >
                        <Input
                            prefix={
                                <Icon
                                    type="global"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                            placeholder="Enter domain or url"
                            name="domain"
                            size="large"
                            value={domain}
                            onChange={handleInputChange}
                        />
                        <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                        >
                            Analyze URL
                        </Button>
                    </Input.Group>
                </Form.Item>
            </Form>
            <Modal
                className="backlinks-modal"
                title={
                    <ModalTitle
                        title="Backlinks for:"
                        meta={domain}
                    />
                }
                visible={isModalVisible}
                onCancel={closeModal}
                footer={null}
                centered
                destroyOnClose
            >
                <Backlinks domain={domain} />
            </Modal>
        </Card>
    );
}
