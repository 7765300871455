import { skipToken } from '@reduxjs/toolkit/query';
import {
    useFetchBacklinksQuery,
    useFetchReferringDomainsQuery,
    useFetchTopAnchorsQuery,
    useFetchTldDistributionQuery,
    useFetchTopCountriesQuery,
} from '../service';

export default function useFetchBacklinksResource(resourceType: string, domain: string, orderBy: string, orderDir: string, offset: number) {
    let result;

    const backlinks = useFetchBacklinksQuery(resourceType === 'backlinks' ? { domain, order_by: orderBy, order_dir: orderDir, offset } : skipToken);

    const referringDomains = useFetchReferringDomainsQuery(resourceType === 'referring_domains' ? { domain, offset } : skipToken);

    const topAnchors = useFetchTopAnchorsQuery(resourceType === 'top_anchors' ? { domain, offset } : skipToken);

    const tldDistribution = useFetchTldDistributionQuery(resourceType === 'tld_distribution' ? { domain } : skipToken);

    const topCountries = useFetchTopCountriesQuery(resourceType === 'top_countries' ? { domain } : skipToken);

    switch (resourceType) {
        case 'backlinks':
            result = backlinks;
            break;

        case 'referring_domains':
            result = referringDomains;
            break;

        case 'top_anchors':
            result = topAnchors;
            break;

        case 'tld_distribution':
            result = tldDistribution;
            break;

        default:
            result = topCountries;
            break;
    }

    return { data: result.data, isLoading: result.isLoading, isFetching: result.isFetching, isError: result.isError };
}
