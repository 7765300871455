import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";
import WebVitalChart from "../../shared/WebVitalChart/WebVitalChart";

const displayResult = (title, result) => {
  return (
    <React.Fragment>
      {result["fcp"] && (
        <WebVitalChart
          markerAt={parseFloat((result["fcp"] / 1000).toFixed(3))}
          maxGoodVal={1.8}
          minPoorVal={3}
          unitsSuffix={" s"}
        />
      )}

      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    </React.Fragment>
  );
};

const FirstContentfulPaintSection = ({
  url,
  sectionKey,
  title,
  result,
  tooltip,
  relevance,
  passRate,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default FirstContentfulPaintSection;
