import { HoursInterval, Issue, OutageEvent, OutageGroups, OutageSummary } from './types';
import moment from 'moment';

export function getOutagesGroupedByDays(outages: OutageSummary[]) {
    return outages.reduce((groups: OutageGroups, outage) => {
        const startDate = outage.start_at.split('T')[0];
        const endDate = outage.end_at.split('T')[0];

        if (!groups[startDate]) {
            groups[startDate] = [];
        }

        if (startDate === endDate) {
            groups[startDate].push(outage);
        } else {
            // this is the case when the outage is spreading on 2 diff days
            // we have to split the outage into 2 parts (on part in a diff day)

            const outageFirstPart = {
                start_at: outage.start_at,
                end_at: `${startDate}T23:59:59`,
                guid: outage.guid,
            };
            const outageSecondPart = {
                start_at: `${endDate}T00:00:00`,
                end_at: outage.end_at,
                guid: outage.guid,
            };

            if (!groups[endDate]) {
                groups[endDate] = [];
            }

            groups[startDate].push(outageFirstPart);
            groups[endDate].push(outageSecondPart);
        }

        return groups;
    }, {});
}

function hasBlankIntervalAtLeft(outage: OutageSummary, start: string) {
    return outage.start_at > start;
}

function hasBlankIntervalAtRight(outage: OutageSummary, end: string) {
    return outage.end_at < end;
}

function getLeftInterval(outage: OutageSummary, start: string) {
    return {
        start_at: start,
        end_at: moment(outage.start_at).format('YYYY-MM-DDTHH:mm:ss'),
    };
}

function getRightInterval(outage: OutageSummary, end: string) {
    let interval: HoursInterval = {
        start_at: moment(outage.end_at).format('YYYY-MM-DDTHH:mm:ss'),
        end_at: end,
    };
    if (moment().format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD')) {
        interval.in_future = true;
    }
    return interval;
}

export function getIntervalsForDay(day: string, outages: OutageSummary[]) {
    let intervals: HoursInterval[] = [];
    let startInterval = `${day}T00:00:00`;
    let endInterval = `${day}T23:59:59`;

    if (outages) {
        outages.forEach((outage, idx) => {
            if (hasBlankIntervalAtLeft(outage, startInterval)) {
                let interval = getLeftInterval(outage, startInterval);

                if (moment(interval.end_at).isAfter(moment(interval.start_at).add(5, 'minutes'))) {
                    intervals.push(interval);
                    intervals.push(outage);
                } else {
                    let extendedOutage: HoursInterval = { ...outage };
                    extendedOutage.fake_start_at = interval.start_at;
                    intervals.push(extendedOutage);
                }

                startInterval = outage.end_at;
            }
            if (idx === outages.length - 1) {
                if (hasBlankIntervalAtRight(outage, endInterval)) {
                    let interval = getRightInterval(outage, endInterval);
                    intervals.push(interval);
                }
            }
        });
    } else {
        intervals.push({ start_at: startInterval, end_at: endInterval });
    }
    return intervals;
}

export function getLastIntervalIndex(intervals: HoursInterval[]) {
    let currentIntervalIdx = 0;

    if (intervals[intervals.length - 1].in_future) {
        currentIntervalIdx = intervals.length - 2;
    } else {
        currentIntervalIdx = intervals.length - 1;
    }

    return currentIntervalIdx;
}

export function getDurationInMinutes(startDate: string, endDate: string) {
    var start = moment(startDate, 'YYYY-MM-DDTHH:mm:ss');
    var end = moment(endDate, 'YYYY-MM-DDTHH:mm:ss');
    var duration = moment.duration(end.diff(start));
    return duration.asMinutes();
}

export function enumerateDaysBetweenDates(startDate: string, endDate: string) {
    let days = [];
    while (moment(startDate) <= moment(endDate)) {
        days.push(moment(startDate).format('YYYY-MM-DD'));
        startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
    }
    return days;
}

export function groupEventsByType(events: OutageEvent[]) {
    const groups = {
        pingEvents: [] as Issue[],
        httpEvents: [] as Issue[],
    };

    if (events && events.length) {
        events.forEach((event) => {
            let issue: any = {};
            issue.created_at = event.created_at;
            issue.region = event.region;

            if (event.type === 'loading_time') {
                if (event.error || (typeof event.value === 'number' && event.value > 20000)) {
                    issue.text = 'Loading time > 20s';
                    groups.httpEvents.push(issue);
                }
            }

            if (event.type === 'ping') {
                if (typeof event.value === 'boolean' && !event.value) {
                    groups.pingEvents.push(issue);
                }
            }
        });
    }
    return groups;
}

export function groupEventsByRegion(events: OutageEvent[]) {
    let groups = {};

    if (events && events.length) {
        groups = events.reduce((groups, event) => {
            const region = event.region;

            if (!Object.keys(groups).includes(region)) {
                groups[region] = {
                    ping: {},
                    loading_time: {},
                };
            }
            if (event.type === 'ping') {
                const status = typeof event.value === 'boolean' && event.value === true ? 'up' : 'down';

                groups[region]['ping'] = {
                    created_at: event.created_at,
                    status: status,
                };
            }
            if (event.type === 'loading_time') {
                const status = event.error || (typeof event.value === 'number' && event.value > 20000) ? 'down' : 'up';

                groups[region]['loading_time'] = {
                    created_at: event.created_at,
                    status: status,
                };
            }

            return groups;
        }, {} as any);
    }
    return groups;
}
