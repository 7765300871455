import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { resetPassword } from "../../store/iam/operations";
import { isChangingPassword } from "../../store/iam/selectors";
import { ResetPasswordForm } from "../../components/auth";

class ResetPassword extends Component {
  submitForm = (token, password) => {
    this.props.resetPassword(token, password);
  };

  render() {
    return (
      <ResetPasswordForm
        token={this.props.token}
        onSubmit={this.submitForm}
        isLoading={this.props.isLoading}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: isChangingPassword(state)
});

export default connect(mapStateToProps, { resetPassword, push })(ResetPassword);
