import React, { useState } from 'react';
import { Button, Alert } from 'antd';
import { Backlink, ReferringDomain, TldDistribution, TopAnchor, TopCountry } from '../types';
import TopCountriesTable from './tables/TopCountriesTable';
import TldDistributionTable from './tables/TldDistributionTable';
import TopAnchorsTable from './tables/TopAnchorsTable';
import ReferringDomainsTable from './tables/ReferringDomainsTable';
import BacklinksTable from './tables/BacklinksTable';
import LoadingIndicator from '../../components/shared/LoadingIndicator/LoadingIndicator';
import ExportAsFile from '../../components/shared/ExportAsFile/ExportAsFile';
import IconLabel from '../../components/shared/IconLabel/IconLabel';
import { getResourceToExportAsJson, getResourceToExportAsCsv } from '../utils';
import useFetchBacklinksResource from '../hooks/useFetchBacklinksResource';

type Props = {
    domain: string;
    resourceType: string;
    orderBy: string;
    orderDir: string;
};

export default function BacklinksResults({ domain, resourceType, orderBy, orderDir }: Props) {
    const [offset, setOffset] = useState(0);

    const {
        data: backlinksResponse,
        isLoading,
        isFetching: isLoadingMore,
        isError: isBacklinksResourceError,
    } = useFetchBacklinksResource(resourceType, domain, orderBy, orderDir, offset);

    const loadMore = () => {
        if (backlinksResponse) {
            setOffset(backlinksResponse.data.length);
        }
    };

    const isBacklinksEmpty = !isLoading && (!backlinksResponse || backlinksResponse.data.length === 0);
    const isBacklinksExistent = !isLoading && backlinksResponse && backlinksResponse.data.length > 0;

    const hasMoreBacklinks = isBacklinksExistent && backlinksResponse.has_more && !isBacklinksResourceError;

    return (
        <>
            {isLoading && <LoadingIndicator />}

            {isBacklinksEmpty && <Alert message="Your domain has no indexed backlinks!" />}

            {isBacklinksExistent && (
                <div className="backlinks-results">
                    {resourceType === 'backlinks' && <BacklinksTable backlinks={backlinksResponse.data as Backlink[]} />}
                    {resourceType === 'referring_domains' && <ReferringDomainsTable referringDomains={backlinksResponse.data as ReferringDomain[]} />}
                    {resourceType === 'top_anchors' && <TopAnchorsTable topAnchors={backlinksResponse.data as TopAnchor[]} />}
                    {resourceType === 'tld_distribution' && <TldDistributionTable tldDistribution={backlinksResponse.data as TldDistribution[]} />}
                    {resourceType === 'top_countries' && <TopCountriesTable topCountries={backlinksResponse.data as TopCountry[]} />}

                    {hasMoreBacklinks && (
                        <Button
                            className="load-more"
                            type="primary"
                            loading={isLoadingMore}
                            onClick={loadMore}
                        >
                            Load more
                        </Button>
                    )}

                    <div className="export-buttons">
                        <ExportAsFile
                            fileContent={getResourceToExportAsCsv(backlinksResponse.data)}
                            fileName={`${resourceType}.csv`}
                            fileMime="text/csv"
                        >
                            <Button className="light-text">
                                <IconLabel
                                    icon="download"
                                    label="Export CSV"
                                />
                            </Button>
                        </ExportAsFile>

                        <ExportAsFile
                            fileContent={getResourceToExportAsJson(backlinksResponse.data)}
                            fileName={`${resourceType}.json`}
                            fileMime="application/json"
                        >
                            <Button className="light-text">
                                <IconLabel
                                    icon="download"
                                    label="Export JSON"
                                />
                            </Button>
                        </ExportAsFile>
                    </div>
                </div>
            )}
        </>
    );
}
