import React from 'react';
import { Input, Select } from 'antd';

const { Option } = Select;

const urlSchemaOptions = ['https://', 'http://'];

type Props = {
	domain: string;
	urlSchema: string;
	id: string;
	onUrlChange: (domain: string, urlSchema: string) => void;
	placeholder: string;
};

export default function UrlInput({ domain, urlSchema, id, placeholder, onUrlChange }: Props) {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDomain = event.target.value;
		onUrlChange(newDomain, urlSchema);
	};

	const handleUrlSchemaChange = (newUrlSchema: string) => {
		onUrlChange(domain, newUrlSchema);
	};

	return (
		<Input
			id={id}
			value={domain}
			placeholder={placeholder}
			onChange={handleInputChange}
			onKeyPress={(e) => e.stopPropagation()}
			onKeyDown={(e) => e.stopPropagation()}
			onKeyUp={(e) => e.stopPropagation()}
			addonBefore={
				<Select
					className="url-schema-select"
					value={urlSchema}
					onChange={handleUrlSchemaChange}
				>
					{urlSchemaOptions.map((urlSchemaOption) => (
						<Option
							key={urlSchemaOption}
							value={urlSchemaOption}
						>
							{urlSchemaOption}
						</Option>
					))}
				</Select>
			}
		/>
	);
}
