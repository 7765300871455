import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
// @ts-ignore
import { geoPath } from 'd3-geo';
// @ts-ignore
import { geoMiller } from 'd3-geo-projection';
// @ts-ignore
import { feature } from 'topojson-client';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import { Outage, RegionEventGroup } from './types';
import { groupEventsByRegion } from './utils';
import { MonitorRegions } from '../trackedWebsites/types';
import OutageTooltip from './OutageTooltip';
import moment from 'moment';

const regions = [
    { name: 'US East', key: 'us-east-1', coordinates: [-77.531717, 38.747686] },
    { name: 'US West', key: 'us-west-2', coordinates: [-120.618048, 43.799272] },
    { name: 'Canada', key: 'ca-central-1', coordinates: [-73.705356, 45.464899] },
    { name: 'Brazil', key: 'sa-east-1', coordinates: [-46.6333, -23.5505] },
    { name: 'Ireland', key: 'eu-west-1', coordinates: [-6.206855, 53.408871] },
    { name: 'UK', key: 'eu-west-2', coordinates: [-0.127839, 51.508638] },
    { name: 'Germany', key: 'eu-central-1', coordinates: [8.632249, 50.098835] },
    { name: 'France', key: 'eu-west-3', coordinates: [2.3522, 48.8566] },
    { name: 'India', key: 'ap-south-1', coordinates: [72.879118, 19.078309] },
    { name: 'Singapore', key: 'ap-southeast-1', coordinates: [103.858661, 1.353811] },
    { name: 'Australia', key: 'ap-southeast-2', coordinates: [151.192872, -33.870735] },
    { name: 'Japan', key: 'ap-northeast-1', coordinates: [139.772913, 35.847964] },
];

type State = {
    geographies: any[];
    conus?: any[];
};

type Props = {
    isLoading: boolean;
    monitoringRegions: MonitorRegions;
    width: number;
    height: number;
    scale: number;
    outageContext: Outage | null;
    url: string;
};

export default function OutageWorldMap({ url, outageContext, width, height, scale, isLoading }: Props) {
    const [state, setState] = useState<State>({ geographies: [] });

    const events: { [region: string]: RegionEventGroup } = outageContext ? groupEventsByRegion(outageContext.events) : {};

    const projection = geoMiller()
        .rotate([-10, 0, 0])
        .scale(width / 7)
        .translate([width / 2, height / 1.6]);

    const getMarkerStatus = (event: RegionEventGroup) => {
        if (event.ping.status === 'down' || event.loading_time.status === 'down') {
            return 'danger';
        } else return 'success';
    };

    const getRegionEvents = (region: string) => {
        const regionEvents = events && events[region];
        return regionEvents
            ? regionEvents
            : {
                  ping: { status: 'up', created_at: moment().utc().unix() },
                  loading_time: { status: 'up', created_at: moment().utc().unix() },
              };
    };

    useEffect(() => {
        let isMounted = true;
        fetch('/world-continents.json').then((response) => {
            if (response.status !== 200) {
                return;
            }
            response.json().then((worldTopoData) => {
                if (isMounted) {
                    setState({
                        geographies: feature(worldTopoData, worldTopoData.objects.continent).features,
                        conus: feature(worldTopoData, worldTopoData.objects.continent),
                    });
                }
            });
        });

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div className="map-wrapper">
            <div className={`map-loading-overlay ${isLoading ? 'loading' : ''}`}>
                <LoadingIndicator />
            </div>
            <svg
                className="world-map"
                viewBox={`0 0 ${width} ${height}`}
            >
                <g className="continents">
                    {state.geographies.map((d, i) => (
                        <path
                            className="continent"
                            key={`path-${i}`}
                            d={geoPath().projection(projection)(d)}
                        />
                    ))}
                </g>
                <g className="markers">
                    {regions.map((region, regionIdx) => (
                        <Popover
                            content={
                                <OutageTooltip
                                    url={url}
                                    location={region.name}
                                    events={getRegionEvents(region.key)}
                                />
                            }
                            title={region.name}
                            trigger="click"
                            key={`marker-${regionIdx}`}
                            overlayStyle={{ zIndex: 1000 }}
                            overlayClassName="outage-tooltip"
                        >
                            <circle
                                className={`marker ${getMarkerStatus(getRegionEvents(region.key))} ${isLoading ? 'loading' : ''}`}
                                r="4"
                                cx={projection(region.coordinates)[0]}
                                cy={projection(region.coordinates)[1]}
                            />
                        </Popover>
                    ))}
                </g>
            </svg>
        </div>
    );
}
