import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';

const { TextArea } = Input;

type Props = {
    domain: string;
    isAddingTrackedKeywords: boolean;
    onSubmit: (keywordsString: string) => void;
};

export default function AddTrackedKeywordsForm({ domain, isAddingTrackedKeywords, onSubmit }: Props) {
    const [keywords, setKeywords] = useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(keywords);
        setKeywords('');
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setKeywords(event.target.value);
    };

    return (
        <Form
            className="add-tracked-keywords-form"
            layout="vertical"
            onSubmit={handleSubmit}
            style={{ padding: 20 }}
        >
            <p>Add your desired keywords for {domain} here. You can add them one by one, or add multiple keywords separated by comma.</p>
            <Form.Item>
                <TextArea
                    value={keywords}
                    onChange={handleChange}
                    placeholder="keyword1, keyword2, keyword3, etc"
                    autoSize={{ minRows: 5, maxRows: 25 }}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAddingTrackedKeywords}
                >
                    Add keywords
                </Button>
            </Form.Item>
        </Form>
    );
}
