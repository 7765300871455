import React, { Component } from "react";
import { Card, Form, Input, Button, Icon } from "antd";
import { validate } from "validate.js";
import { urlValidationRule } from "./validators";

const InputGroup = Input.Group;

export class AnalysisForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      validationError: {}
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.normalizeUrl = this.normalizeUrl.bind(this);
  }

  handleInputChange(e) {
    let url = e.target.value;
    this.setState({ url, validationError: {} });
  }

  normalizeUrl(url) {
    let normalizedUrl = url.toLowerCase().trim();
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      normalizedUrl = `https://${normalizedUrl}`;
    }
    return normalizedUrl;
  }

  handleSubmit(e) {
    e.preventDefault();

    let normalizedUrl = this.normalizeUrl(this.state.url);
    const validationError = validate({ url: normalizedUrl }, urlValidationRule);

    if (!validationError) {
      this.setState({ validationErrors: {} });
      this.props.generateNewReport(normalizedUrl);
    } else {
      this.setState({ validationError });
    }
  }

  render() {
    const errors = this.state.validationError;
    return (
      <Card className="analysis-form-card">
        <Form onSubmit={this.handleSubmit} className="analysis-form">
          <Form.Item
            validateStatus={errors.url && errors.url[0] ? "error" : "validating"}
            help={errors.url && errors.url[0]}>
            <InputGroup compact size="large">
              <div className="compact-input-group">
                <Input
                  prefix={<Icon type="global" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Quick SEO Checkup — Enter URL"
                  name="url"
                  size="large"
                  value={this.state.url}
                  onChange={this.handleInputChange}
                />

                <Button type="primary" htmlType="submit" size="large">
                  Analyze URL
                </Button>
              </div>
            </InputGroup>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

export default AnalysisForm;
