import { Form, Input } from 'antd';
import React from 'react';
import LastStepButtons from '../LastStepButtons';

const { TextArea } = Input;

type Props = {
    domain: string;
    keywords: string;
    maxKeywords: number;
    trackedKeywordsCount: number;
    onKeywordsChange: (newKeywords: string) => void;
    onPreviousStep: () => void;
    onFinishThisStep: () => void;
};

export default function SetKeywordsStep({ domain, keywords, maxKeywords, trackedKeywordsCount, onKeywordsChange, onPreviousStep, onFinishThisStep }: Props) {
    const handleKeywordsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const keywords = event.target.value;
        onKeywordsChange(keywords);
    };

    const validateKeywords = () => {
        onFinishThisStep();
    };

    return (
        <div className="steps-content">
            <Form className="step-form">
                <Form.Item>
                    <div className="wizard-form-field">
                        <h3 className="field-title">Add your keywords for {domain}</h3>
                        <p className="field-description">
                            Add up to {maxKeywords - trackedKeywordsCount} keywords to monitor your site's placement in search engine results
                        </p>
                    </div>
                </Form.Item>
                <Form.Item>
                    <TextArea
                        value={keywords}
                        onChange={handleKeywordsChange}
                        placeholder="keyword1, keyword2, keyword3, etc"
                        autoSize={{ minRows: 5, maxRows: 25 }}
                        onKeyPress={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                        onKeyUp={(e) => e.stopPropagation()}
                    />
                </Form.Item>
            </Form>
            <div className="steps-action">
                <LastStepButtons
                    onPreviousClicked={onPreviousStep}
                    onSubmit={validateKeywords}
                />
            </div>
        </div>
    );
}
