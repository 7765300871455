import {
  CREATE_REPORT_SUCCESSFUL,
  CREATE_REPORT_FAILED,
  CREATE_COMPETITOR_REPORT_SUCCESSFUL,
  CREATE_COMPETITOR_REPORT_FAILED,
  GET_REPORT_SECTION_IN_PROGRESS,
  GET_REPORT_SECTION_SUCCESSFUL,
  GET_REPORT_SECTION_FAILED,
  GET_COMPETITOR_REPORT_SECTION_IN_PROGRESS,
  GET_COMPETITOR_REPORT_SECTION_SUCCESSFUL,
  GET_COMPETITOR_REPORT_SECTION_FAILED,
  GET_ANALYSIS_REPORT_STRUCTURE_IN_PROGRESS,
  GET_ANALYSIS_REPORT_STRUCTURE_SUCCESSFUL,
  GET_ANALYSIS_REPORT_STRUCTURE_FAILED,
  CLEAR_REPORT_IN_PROGRESS,
  INIT_ANALYSIS_IN_PROGRESS,
  INIT_ANALYSIS_SUCCESSFUL,
  SAVE_REPORT_IN_PROGRESS,
  SAVE_REPORT_SUCCESS,
  SAVE_REPORT_FAILED,
  FETCH_SAVED_REPORT_IN_PROGRESS,
  FETCH_SAVED_REPORT_SUCCESSFUL,
  FETCH_SAVED_REPORT_FAILED,
  FETCH_OVERVIEWS_IN_PROGRESS,
  FETCH_OVERVIEWS_SUCCESSFUL,
  FETCH_OVERVIEWS_FAILED,
  FETCH_LAST_SAVED_REPORT_IN_PROGRESS,
  FETCH_LAST_SAVED_REPORT_FINISHED,
  FETCH_DOMAIN_URLS_IN_PROGRESS,
  FETCH_DOMAIN_URLS_SUCCESSFUL,
  FETCH_DOMAIN_URLS_FAILED,
  CHANGE_SELECTED_DOMAIN,
  START_BACKGROUND_ANALYSIS_FAILED,
  START_BACKGROUND_ANALYSIS_IN_PROGRESS,
  START_BACKGROUND_ANALYSIS_SUCCESS,
  FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_IN_PROGRESS,
  FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_FINISHED,
  CHANGE_ARCHIVED_REPORTS_INTERVAL
} from "./types";

export const initAnalysisInProgress = (guid, url, competitor_urls, sections) => ({
  type: INIT_ANALYSIS_IN_PROGRESS,
  url: url,
  guid: guid,
  sections: sections,
  competitor_urls: competitor_urls
});

export const initAnalysisSuccessful = (temp_guid, main_url_guid, created_at) => ({
  type: INIT_ANALYSIS_SUCCESSFUL,
  temp_guid: temp_guid,
  guid: main_url_guid,
  created_at: created_at
});

export const createReportSuccessful = (url, guid, sections, competitor_urls) => ({
  type: CREATE_REPORT_SUCCESSFUL,
  url: url,
  guid: guid,
  sections: sections,
  competitor_urls: competitor_urls
});

export const createReportFailed = (guid, url) => ({
  type: CREATE_REPORT_FAILED,
  guid: guid,
  url: url
});

export const createCompetitorReportSuccessful = (main_url_guid, competitor_url, competitor_guid) => ({
  type: CREATE_COMPETITOR_REPORT_SUCCESSFUL,
  main_url_guid: main_url_guid,
  competitor_url: competitor_url,
  competitor_guid: competitor_guid
});

export const createCompetitorReportFailed = (main_url_guid, competitor_url) => ({
  type: CREATE_COMPETITOR_REPORT_FAILED,
  main_url_guid: main_url_guid,
  competitor_url: competitor_url
});

export const getReportSectionInProgress = (guid, url, section_key) => ({
  type: GET_REPORT_SECTION_IN_PROGRESS,
  guid: guid,
  url: url,
  section_key: section_key
});

export const getReportSectionSuccessful = (guid, url, section_key, section_result) => ({
  type: GET_REPORT_SECTION_SUCCESSFUL,
  guid: guid,
  url: url,
  section_result: section_result,
  section_key: section_key
});

export const getReportSectionFailed = (guid, url, section_key) => ({
  type: GET_REPORT_SECTION_FAILED,
  guid: guid,
  url: url,
  section_key: section_key
});

export const getCompetitorReportSectionInProgress = (main_url_guid, competitor_guid, competitor_url, section_key) => ({
  type: GET_COMPETITOR_REPORT_SECTION_IN_PROGRESS,
  main_url_guid: main_url_guid,
  competitor_guid: competitor_guid,
  competitor_url: competitor_url,
  section_key: section_key
});

export const getCompetitorReportSectionSuccessful = (
  main_url_guid,
  competitor_guid,
  competitor_url,
  section_key,
  section_result
) => ({
  type: GET_COMPETITOR_REPORT_SECTION_SUCCESSFUL,
  main_url_guid: main_url_guid,
  competitor_guid: competitor_guid,
  competitor_url: competitor_url,
  section_key: section_key,
  section_result: section_result
});

export const getCompetitorReportSectionFailed = (main_url_guid, competitor_guid, competitor_url, section_key) => ({
  type: GET_COMPETITOR_REPORT_SECTION_FAILED,
  main_url_guid: main_url_guid,
  competitor_guid: competitor_guid,
  competitor_url: competitor_url,
  section_key: section_key
});

export const getAnalysisReportStructureInProgress = (_) => ({
  type: GET_ANALYSIS_REPORT_STRUCTURE_IN_PROGRESS
});

export const getAnalysisReportStructureSuccessful = (analysis_general_structure) => ({
  type: GET_ANALYSIS_REPORT_STRUCTURE_SUCCESSFUL,
  analysis_general_structure: analysis_general_structure
});

export const getAnalysisReportStructureFailed = (_) => ({
  type: GET_ANALYSIS_REPORT_STRUCTURE_FAILED
});

export const clearReportInProgress = (guid) => ({
  type: CLEAR_REPORT_IN_PROGRESS,
  guid: guid
});

export const saveReportInProgress = (guid) => ({
  type: SAVE_REPORT_IN_PROGRESS,
  guid: guid
});

export const saveReportSuccess = (guid) => ({
  type: SAVE_REPORT_SUCCESS,
  guid: guid
});

export const saveReportFailed = (guid) => ({
  type: SAVE_REPORT_FAILED,
  guid: guid
});

export const fetchSavedReportInProgress = (guid, url) => ({
  type: FETCH_SAVED_REPORT_IN_PROGRESS,
  guid: guid,
  url: url
});

export const fetchSavedReportSuccessful = (guid, url, report) => ({
  type: FETCH_SAVED_REPORT_SUCCESSFUL,
  guid: guid,
  url: url,
  report: report
});

export const fetchSavedReportFailed = (guid, url, errorMessage) => ({
  type: FETCH_SAVED_REPORT_FAILED,
  guid: guid,
  url: url,
  errorMessage: errorMessage
});

export const fetchOverviewsInProgress = (url) => ({
  type: FETCH_OVERVIEWS_IN_PROGRESS,
  url: url
});

export const fetchOverviewsSuccessful = (url, overviews) => ({
  type: FETCH_OVERVIEWS_SUCCESSFUL,
  url: url,
  overviews: overviews
});

export const fetchOverviewsFailed = (url) => ({
  type: FETCH_OVERVIEWS_FAILED,
  url: url
});

export const fetchLastSavedReportInProgress = (url) => ({
  type: FETCH_LAST_SAVED_REPORT_IN_PROGRESS,
  url: url
});

export const fetchLastSavedReportFinished = (url) => ({
  type: FETCH_LAST_SAVED_REPORT_FINISHED,
  url: url
});

export const fetchDomainUrlsInProgress = (domain) => ({
  type: FETCH_DOMAIN_URLS_IN_PROGRESS,
  domain: domain
});

export const fetchDomainUrlsSuccessful = (domain, next_token, urls) => ({
  type: FETCH_DOMAIN_URLS_SUCCESSFUL,
  domain: domain,
  next_token: next_token,
  urls: urls
});

export const fetchDomainUrlsFailed = (domain) => ({
  type: FETCH_DOMAIN_URLS_FAILED,
  domain: domain
});

export const changeSelectedDomain = (domain) => ({
  type: CHANGE_SELECTED_DOMAIN,
  domain: domain
});

export const startBackgroundAnalysisInProgress = (url) => ({
  type: START_BACKGROUND_ANALYSIS_IN_PROGRESS,
  url: url
});

export const startBackgroundAnalysisSuccess = (guid, url) => ({
  type: START_BACKGROUND_ANALYSIS_SUCCESS,
  guid: guid,
  url: url
});

export const startBackgroundAnalysisFailed = (url) => ({
  type: START_BACKGROUND_ANALYSIS_FAILED,
  url: url
});

export const fetchOverviewsForMainUrlAndCompetitorsInProgress = (url) => ({
  type: FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_IN_PROGRESS,
  url
});

export const fetchOverviewsForMainUrlAndCompetitorsFinished = (url) => ({
  type: FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_FINISHED,
  url
});

export const changeArchivedReportsInterval = (startDate, endDate) => ({
  type: CHANGE_ARCHIVED_REPORTS_INTERVAL,
  startDate,
  endDate
});
