import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { push } from "connected-react-router";
import { isLoggedIn } from "../store/iam/selectors";
import { logout } from "../store/iam/creators";

const PublicTopNav = props => {
  const general_items = [{ path: "/", label: "Home" }];
  const auth_items = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/analysis", label: "Analysis" }
  ];
  const not_auth_items = [
    { path: "/auth/login", label: "Login" },
    { path: "/auth/register", label: "Register" },
    { path: "/auth/forgot-password", label: "Forgot Password" }
  ];

  let menu_items = general_items;

  if (props.is_logged_in) {
    menu_items = menu_items.concat(auth_items);
  } else {
    menu_items = menu_items.concat(not_auth_items);
  }

  const handleClick = () => {
    props.logout();
    props.push("/auth/login");
  };

  return (
    <div>
      {menu_items.map(item => (
        <NavLink
          className="navbar-element"
          exact
          to={item.path}
          key={item.path}
          activeStyle={{
            fontWeight: "bold",
            color: "red"
          }}>
          {item.label}
        </NavLink>
      ))}
      <span>{props.is_logged_in ? <span onClick={handleClick}>Logout</span> : null}</span>
    </div>
  );
};

const mapStateToProps = state => ({
  is_logged_in: isLoggedIn(state)
});

export default connect(mapStateToProps, { push, logout })(PublicTopNav);
