export const GET_TRACKED_WEBSITES_IN_PROGRESS = "GET_TRACKED_WEBSITES_IN_PROGRESS";
export const GET_TRACKED_WEBSITES_SUCCESS = "GET_TRACKED_WEBSITES_SUCCESS";
export const GET_TRACKED_WEBSITES_FAILED = "GET_TRACKED_WEBSITES_FAILED";

export const ADD_TRACKED_WEBSITE_IN_PROGRESS = "ADD_TRACKED_WEBSITE_IN_PROGRESS";
export const ADD_TRACKED_WEBSITE_SUCCESS = "ADD_TRACKED_WEBSITE_SUCCESS";
export const ADD_TRACKED_WEBSITE_FAILED = "ADD_TRACKED_WEBSITE_FAILED";

export const CHANGE_CURRENT_WEBSITE = "CHANGE_CURRENT_WEBSITE";

export const CHANGE_TRACKED_WEBSITE_SETTINGS_IN_PROGRESS = "CHANGE_TRACKED_WEBSITE_SETTINGS_IN_PROGRESS";
export const CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL = "CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL";
export const CHANGE_TRACKED_WEBSITE_SETTINGS_FAILED = "CHANGE_TRACKED_WEBSITE_SETTINGS_FAILED";

export const DELETE_TRACKED_WEBSITE_IN_PROGRESS = "DELETE_TRACKED_WEBSITE_IN_PROGRESS";
export const DELETE_TRACKED_WEBSITE_SUCCESSFUL = "DELETE_TRACKED_WEBSITE_SUCCESSFUL";
export const DELETE_TRACKED_WEBSITE_FAILED = "DELETE_TRACKED_WEBSITE_FAILED";

export const GET_TRACKED_WEBSITES_AND_OVERVIEWS_IN_PROGRESS = "GET_TRACKED_WEBSITES_AND_OVERVIEWS_IN_PROGRESS";
export const GET_TRACKED_WEBSITES_AND_OVERVIEWS_SUCCESSFUL = "GET_TRACKED_WEBSITES_AND_OVERVIEWS_SUCCESSFUL";
export const GET_TRACKED_WEBSITES_AND_OVERVIEWS_FAILED = "GET_TRACKED_WEBSITES_AND_OVERVIEWS_FAILED";
