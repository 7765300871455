import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Table, Icon } from "antd";

const { Column } = Table;
const TeamMembersTable = ({ members, invitations, onRemoveInvitation, onRemoveTeamMember }) => {
    const membersTableDataSource = [];
    members
        .filter((member) => member["role"] !== "owner")
        .forEach((member, idx) => {
            membersTableDataSource.push({
                key: idx + 1,
                email: member.email,
                role: member.role,
                f_name: member.f_name,
                l_name: member.l_name,
                c_at: member.c_at
            });
        });

    if (invitations && invitations.length > 0) {
        invitations.forEach((invitation, idx) => {
            membersTableDataSource.push({
                key: members.length + idx, //without owner
                email: invitation.invited_email,
                status: "pending",
                invitationToken: invitation.token
            });
        });
    }

    const networkActivity = useSelector((state) => state.iam.network_activity);

    const removeMember = (record) => {
        if (record.invitationToken) {
            onRemoveInvitation(record.invitationToken);
        } else {
            onRemoveTeamMember(record.email);
        }
    };

    return (
        <div className="team-members">
            <h3>Members</h3>

            <Table dataSource={membersTableDataSource} pagination={false} size="small" rowKey={"email"}>
                <Column title="#" dataIndex="key" align="left" height={"50px"} />
                <Column
                    className="no-wrap"
                    title="Email"
                    dataIndex="email"
                    render={(_, record) => <span>{record.email}</span>}
                />
                <Column className="no-wrap" title="First name" dataIndex="f_name" />
                <Column title="Last name" dataIndex="l_name" />
                <Column
                    className="no-wrap al-right"
                    title="Member since"
                    dataIndex="c_at"
                    render={(_, record) =>
                        record["c_at"] ? (
                            moment(record["c_at"]).format("MMM DD, YYYY")
                        ) : record.status && record.status === "pending" ? (
                            <span className="pending-invitation">pending</span>
                        ) : null
                    }
                />

                <Column
                    dataIndex=""
                    width="60px"
                    align="right"
                    render={(_, record) =>
                        record.role !== "owner" ? (
                            <span className="remove-member" onClick={() => removeMember(record)}>
                                {networkActivity.includes(`delete_team_member_${record.invitationToken}`) ||
                                networkActivity.includes(`delete_team_member_${record.email}`) ? (
                                    <Icon type="loading" />
                                ) : (
                                    <Icon type="delete" />
                                )}
                            </span>
                        ) : null
                    }
                />
            </Table>
        </div>
    );
};

export default TeamMembersTable;
