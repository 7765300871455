import React from 'react';
import { Chart, Geom, Axis, Coord, Tooltip } from 'bizcharts';
import { KeywordsOverviewChartDataRecord } from './types';

function tooltipRender(...args: any[]) {
    const tooltipPos: number = args[1];

    return {
        name: 'Position',
        value: tooltipPos <= 100 ? tooltipPos.toString() : '> 100',
    };
}

function getYAxisMin(chartData: KeywordsOverviewChartDataRecord[]) {
    const min = chartData.reduce((min, item) => {
        return item.graphPos < min ? item.graphPos : min;
    }, chartData[0].graphPos);

    if (chartData.length > 1) {
        return min - 5 > 0 ? min - 5 : min;
    }

    return 1;
}

function getYAxisMax(chartData: KeywordsOverviewChartDataRecord[]) {
    const max = chartData.reduce((max, item) => {
        return item.graphPos > max ? item.graphPos : max;
    }, chartData[0].graphPos);

    if (chartData.length > 1) {
        return max + 5 >= 100 ? 100 : max + 5;
    }

    return 100;
}

type Props = {
    chartData: KeywordsOverviewChartDataRecord[];
    height: number;
};

export default function KeywordPositionChart({ chartData, height }: Props) {
    let scale = null;

    if (chartData && chartData.length) {
        scale = {
            graphPos: {
                type: 'linear',
                min: getYAxisMin(chartData),
                max: getYAxisMax(chartData),
                formatter: (pos: number) => (pos === 0 ? 1 : pos),
            },
            crawledAt: {
                type: 'timeCat',
                mask: 'MMM DD',
                nice: true,
            },
        };
    }

    return (
        <div className="tracked-keywords-chart-container">
            <Chart
                padding="auto"
                height={height}
                data={chartData}
                scale={scale}
                forceFit
                animate={false}
            >
                <Axis
                    name="crawledAt"
                    position="top"
                />
                <Axis name="graphPos" />
                <Tooltip />
                <Coord reflect="y" />
                <Geom
                    type="line"
                    position="crawledAt*graphPos"
                    size={2}
                    tooltip={['crawledAt*tooltipPos', tooltipRender]}
                />
                <Geom
                    type="point"
                    position="crawledAt*graphPos"
                    tooltip={['crawledAt*tooltipPos', tooltipRender]}
                    size={4}
                    shape={'circle'}
                    style={{ stroke: '#fff', lineWidth: 1 }}
                />
            </Chart>
        </div>
    );
}
