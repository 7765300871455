import {START_PROFIT_WELL, SEND_GTAG_PAYMENT_METHOD, SEND_GTAG_REGISTRATION} from "./types"

export const startProfitWell = _ => ({
  type: START_PROFIT_WELL,    
});

export const sendGtagRegistration = _ => ({
  type: SEND_GTAG_REGISTRATION
})

export const sendGtagPaymentMethod = _ => ({
  type: SEND_GTAG_PAYMENT_METHOD
})