import React, { useEffect, useState } from "react";
import { Button, Form, Checkbox, Icon } from "antd";
import { useRecurly } from "@recurly/react-recurly";
import { SSC_TOS_URL } from "../../settings/config";

const PayPalButton = props => {
  const recurly = useRecurly();
  const payPal = recurly.PayPal({
    display: { displayName: "SEO Site Checkup subscription" }
  });

  const [acceptTos, setAcceptTos] = useState(false);
  const [loading, setLoading] = useState(false);

  const onAcceptTos = _ => {
    setAcceptTos(!acceptTos);
  };

  useEffect(() => {
    payPal.on("token", token => {
      setLoading(false);

      props.onSubmit({
        token: token.id,
        payment_method: token.type
      });
    });

    payPal.on("error", error => {
      setLoading(false);
    });

    payPal.on("cancel", () => {
      setLoading(false);
    });
  }, [payPal, props]);

  const handleClick = () => {
    setLoading(true);
    payPal.start();
  };

  return (
    <Form>
      <Form.Item className={"paypal-accept-terms"}>
        <Checkbox onChange={onAcceptTos}>
          I accept the&nbsp;
          <a href={SSC_TOS_URL} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          className={`place-order ${!acceptTos ? "disabled" : ""}`}
          type="primary"
          loading={loading}
          block
          onClick={handleClick}>
          {props.buttonLabel}
          <Icon type="lock" theme="filled" />
        </Button>
      </Form.Item>
    </Form>
  );
};
export default PayPalButton;
