import React, { useContext } from 'react';
import { SelectedWebsiteContext } from '../trackedWebsites/ContextWrapper';
import DashboardBox from '../components/Dashboard/DashboardBox';
import DashboardCardTitle from '../components/Dashboard/DashboardCardTitle';
import { useFetchTopKeywordsOverviewQuery, useFetchTopKeywordsQuery } from '../topKeywords/service';
import { skipToken } from '@reduxjs/toolkit/query';
import { defaultOrderBy, defaultOrderDir } from '../topKeywords/settings';
import { Card, Icon } from 'antd';
import { useNavigate } from '../router/hooks/useNavigate';
import DashboardTopKeywordsOverview from './DashboardTopKeywordsOverview';
import DashboardTopKeywordsTable from './DashboardTopKeywordsTable';

export default function DashboardTopKeywordsBox() {
    const navigate = useNavigate();
    const { selectedWebsite } = useContext(SelectedWebsiteContext);

    const { data: topKeywordsOverview, isLoading: isLoadingTopKeywordsOverview } = useFetchTopKeywordsOverviewQuery(
        selectedWebsite
            ? { domain: selectedWebsite.domain, locationName: selectedWebsite.settings.location_name, languageCode: selectedWebsite.settings.language_code }
            : skipToken
    );

    const { data: topKeywords, isLoading: isLoadingTopKeywords } = useFetchTopKeywordsQuery(
        selectedWebsite
            ? {
                  domain: selectedWebsite.domain,
                  locationName: selectedWebsite.settings.location_name,
                  languageCode: selectedWebsite.settings.language_code,
                  orderBy: defaultOrderBy,
                  orderDir: defaultOrderDir,
              }
            : skipToken
    );

    const handleViewAllTopKeywordsClicked = () => {
        navigate('/top-keywords');
    };

    const dashboardTopKeywords = topKeywords ? topKeywords.slice(0, 5) : [];

    const isLoadingKeywords = isLoadingTopKeywordsOverview || isLoadingTopKeywords;
    const isDataForKeywordsOverview = !isLoadingTopKeywordsOverview && topKeywordsOverview;

    return (
        <div className="dashboard-top-keywords-box">
            {isLoadingKeywords && <DashboardBox />}

            {!isLoadingKeywords && (
                <Card
                    data-cy="top-keywords-box"
                    title={
                        <DashboardCardTitle
                            title="Top keywords"
                            description="Over the last 7 days"
                        />
                    }
                    extra={
                        <div
                            className="card-link flexbox align-center"
                            onClick={handleViewAllTopKeywordsClicked}
                        >
                            <span>Check your keywords</span>
                            <Icon type="right" />
                        </div>
                    }
                >
                    {isDataForKeywordsOverview && <DashboardTopKeywordsOverview overview={topKeywordsOverview} />}

                    <DashboardTopKeywordsTable keywords={dashboardTopKeywords} />
                </Card>
            )}
        </div>
    );
}
