import React from "react";
import { Spin, Icon } from "antd";
import "./LoadingIndicator.less";

const LoadingIndicator = props => {
  return (
    <div className={props.detailed ? "detailed-loading-indicator" : "simple-loading-indicator"} style={props.style}>
      {props.detailed ? (
        <React.Fragment>
          <Spin indicator={<Icon type="loading-3-quarters" spin />} />
          <h2>{props.text}</h2>
          <div className="sub-title">{props.subTitle}</div>
        </React.Fragment>
      ) : (
        <Spin indicator={<Icon type="loading-3-quarters" spin />} />
      )}
    </div>
  );
};

export default LoadingIndicator;
