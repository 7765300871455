import React from "react";
import { Row, Col, Card, Button, Modal, Icon, Result } from "antd";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
import "./Sitemap.less";
import SyntaxHighlighter from "../../components/SyntaxHighlighter/SyntaxHighlighter";
import { SITEMAP_VIDEO_URL } from "../../settings/config";
import { createSitemapStructure } from "./utils";

const CardTitle = ({ domain }) => {
  return (
    <React.Fragment>
      <h3>sitemap.xml</h3>
      <span>{domain}</span>
    </React.Fragment>
  );
};

const SitemapLayout = props => {
  const urls = props.report && createSitemapStructure(props.report);
  return (
    <div className="sitemap">
      {props.isLoading && <LoadingIndicator />}
      {!props.isLoading && !props.report && (
        <Result icon={<Icon type="file-search" theme="outlined" />} title="We're crawling your website..." />
      )}
      {!props.isLoading && props.report && (
        <React.Fragment>
          <Row gutter={20}>
            <Col span={16}>
              <Card
                className="sitemap-syntax-highlighter-card"
                title={<CardTitle domain={props.domain} />}
                extra={
                  <Button type="primary" danger="true" onClick={props.downloadXml} loading={props.generatingSitemap}>
                    Download
                  </Button>
                }>
                <div className={`syntax-highlighter-overlay ${props.isLoadingMoreSitemapUrls ? "loading" : ""}`}>
                  <LoadingIndicator />
                </div>
                <SyntaxHighlighter
                  className="syntax-highlighter-container custom-scrollbar"
                  report={urls}
                  getMore={props.fetchMoreUrls}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                className="sitemap-how-to-card"
                title="Adding or updating your sitemap"
                extra='Place the generated "sitemap.xml" file in the root folder of your website'>
                <iframe
                  title="video"
                  width="100%"
                  height="320"
                  src={SITEMAP_VIDEO_URL}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Card>
            </Col>
          </Row>
          <Modal
            centered
            className="wide-modal sitemap-how-to-add-modal"
            title="Congrats on generating your sitemap.xml"
            visible={props.showModal}
            onCancel={props.closeModal}
            footer={null}>
            <div className="modal-content no-padding">
              {props.showModal && (
                <iframe
                  title="video"
                  width="100%"
                  height="320"
                  src={SITEMAP_VIDEO_URL}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
              <div className="default-padding">
                <h3>How to add your sitemap file to your website?</h3>
                <ul>
                  <li>
                    It is strongly recommended that you place your sitemap at the root directory of your website:{" "}
                    <strong>http://yourwebsite.com/sitemap.xml</strong>. But in some situations, you may want to produce
                    different sitemaps for different paths on your site (e.g., security permission issues)
                  </li>
                  <li>
                    Sitemaps should be no larger than <strong>10MB (10,485,760 bytes)</strong> and can contain a maximum
                    of <strong>50,000 URLs</strong>. This means that if your site contains more than 50,000 URLs or your
                    sitemap is bigger than 10MB, you must create multiple sitemap files and use a Sitemap index file
                  </li>
                  <li>
                    All URLs listed in the sitemap must reside on the same host as the sitemap. For instance, if the
                    sitemap is located at <strong>http://www.yourwebsite.com/sitemap.xml</strong>, it can't include URLs
                    from <strong>http://subdomain.yourwebsite.com</strong>
                  </li>
                  <li>
                    Once you have created your sitemap, let search engines know about it by submitting directly to them,
                    pinging them, or adding the sitemap location to your robots.txt file
                  </li>
                  <li>
                    Sitemaps can be compressed using <strong>gzip</strong>, reducing bandwidth consumption
                  </li>
                </ul>
              </div>
            </div>
            <div className="button-footer">
              <Button type="secondary" onClick={props.closeModal}>
                Close
              </Button>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default SitemapLayout;
