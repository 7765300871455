import React, { Component } from "react";
import { Card, Form, Icon, Input, Button } from "antd";
import { validate } from "validate.js";
import forgotPasswordValidationRules from "./validators";

const { Meta } = Card;

export default class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailValidationError: ""
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
  }

  validateForm() {
    return validate(
      {
        email: this.state.email
      },
      forgotPasswordValidationRules
    );
  }

  onFormSubmit = event => {
    event.preventDefault();
    const validationErrors = this.validateForm();

    if (!validationErrors) {
      this.setState({ emailValidationError: "" });
      this.props.onSubmit(this.state.email);
    } else {
      this.setState({ emailValidationError: validationErrors.email[0] });
    }
  };

  onInputChange(event) {
    const newState = {};
    newState[event.target.name] = event.target.value;
    this.setState({ emailValidationError: "", ...newState });
  }

  onInputFocus(event) {
    if (this.state.emailValidationError) {
      this.setState({ emailValidationError: "" });
    }
  }

  onInputBlur(event) {
    const validationErrors = this.validateForm();

    if (!validationErrors) {
      this.setState({ emailValidationError: "" });
    } else {
      this.setState({ emailValidationError: validationErrors.email[0] });
    }
  }

  render() {
    return (
      <div className="ForgotPasswordForm">
        <div className="authentication-form-wrapper">
          <div className="auth-logo" />

          <Card className="auth-card" bordered={false}>
            <div className="card-header">
              <Meta title="Forgot password" />
            </div>
            <Form className="forgot-password-form" onSubmit={this.onFormSubmit}>
              <Form.Item
                validateStatus={this.state.emailValidationError ? "error" : "validating"}
                help={this.state.emailValidationError || ""}>
                <Input
                  size="large"
                  name="email"
                  prefix={<Icon type="mail" style={{ fontSize: '16px', color: "rgba(66,71,71,.54)" }} />}
                  value={this.state.email}
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                  placeholder="Your email address"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="forgot-password-form-button"
                  loading={this.props.isLoading}>
                  {this.props.isLoading ? "Loading" : "Send recovery email"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <div className="under-card-info">
            Found your password?{" "}
            <span className="fake-link" onClick={() => this.props.push("/auth/login")}>
              <strong>Sign in</strong>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
