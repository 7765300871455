import { SITEMAP_API_URL } from "../../settings/config";
import {
  fetchSitemapInProgress,
  fetchSitemapSuccess,
  fetchSitemapFailed,
  generateSitemapInProgress,
  generateSitemapSuccess,
  generateSitemapFailed
} from "./creators";
import { addNotificationWithTimeout } from "../notifications/operations";
import createLinkAndTriggerDownload from "./utils";
import { authRequest } from "../authorizedRequests";

export const fetchSitemap = (domain, last_url_hash = null) => {
  return (dispatch, getState) => {
    dispatch(fetchSitemapInProgress(domain, last_url_hash));

    const apiUrl = last_url_hash
      ? `${SITEMAP_API_URL}?domain=${domain}&url_hash=${last_url_hash}`
      : `${SITEMAP_API_URL}?domain=${domain}`;

    return new Promise(function(resolve, reject) {
      authRequest(apiUrl, dispatch, getState)
        .then(response => {
          resolve(response);
          dispatch(fetchSitemapSuccess(domain, { last_url_hash: response["last_url_hash"] }, last_url_hash));
        })
        .catch(_ => {
          dispatch(fetchSitemapFailed(domain, last_url_hash));
          reject();
        });
    });
  };
};

export const generateSitemap = domain => {
  return (dispatch, getState) => {
    const apiUrl = `${SITEMAP_API_URL}`;

    dispatch(generateSitemapInProgress(domain));

    return new Promise(function(resolve, reject) {
      authRequest(apiUrl, dispatch, getState, {
        method: "POST",
        body: JSON.stringify({ domain })
      })
        .then(response => {
          dispatch(generateSitemapSuccess(domain, response));
          createLinkAndTriggerDownload(response.sitemaps[0]);
          resolve();
        })
        .catch(_ => {
          dispatch(generateSitemapFailed(domain));
          dispatch(
            addNotificationWithTimeout("error", `Something went wrong when generating sitemap.xml for ${domain}`)
          );
          reject();
        });
    });
  };
};
