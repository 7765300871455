import './Backlinks.less';
import React from 'react';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import BacklinksOverview from './overview/BacklinksOverview';
import { backlinksOverviewMainPageDisplayOrder } from './settings';
import BacklinksTabs from './results/BacklinksTabs';
import { useFetchBacklinksOverviewQuery } from './service';
import moment from 'moment';
import { getBacklinksOverviewForInterval } from './utils';

// Dashboard uses backlinks overview for the last 4 weeks
const endDateStr = moment().utc().add(1, 'day').format('YYYY-MM-DD');
const startDateStr = moment.utc(endDateStr).subtract(4, 'weeks').format('YYYY-MM-DD');

type Props = {
    domain: string;
};

export default function Backlinks({ domain }: Props) {
    const { data: backlinksOverview, isLoading: isLoadingOverview } = useFetchBacklinksOverviewQuery({ domain: domain });

    const isBacklinksOverviewExistent = !isLoadingOverview && backlinksOverview;

    return (
        <div className="backlinks">
            <div className="backlinks-overview">
                {isLoadingOverview && <LoadingIndicator />}

                {isBacklinksOverviewExistent && (
                    <BacklinksOverview
                        overview={getBacklinksOverviewForInterval(backlinksOverview, startDateStr, endDateStr)}
                        showChart={true}
                        displayOrder={backlinksOverviewMainPageDisplayOrder}
                    />
                )}
            </div>

            <BacklinksTabs domain={domain} />
        </div>
    );
}
