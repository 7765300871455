import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { Backlink } from '../../types';
import AnchorTableCell from './AnchorTableCell';
import BacklinkUrlTableCell from './BacklinkUrlTableCell';

const { Column } = Table;

type Props = {
    backlinks: Backlink[];
};

export default function BacklinksTable({ backlinks }: Props) {
    const dataSource = backlinks.map((backlink, index) => ({ ...backlink, key: index + 1 }));

    return (
        <Table
            dataSource={dataSource}
            pagination={false}
            size="small"
            data-cy="backlinks-table"
        >
            <Column
                className="al-left va-top"
                title="#"
                dataIndex="key"
                align="left"
            />

            <Column
                title="Backlink URL"
                dataIndex="url_from"
                render={(text, record: Backlink, index) => (
                    <BacklinkUrlTableCell
                        backlinkUrl={record.url_from}
                        pageTitle={record.page_from_title}
                    />
                )}
            />
            <Column
                className="no-wrap al-right"
                title="Page Rank"
                dataIndex="page_from_rank"
            />

            <Column
                className="no-wrap"
                title="Target URL"
                dataIndex="url_to"
                render={(text, record: Backlink, index) => (
                    <AnchorTableCell
                        url={record.url_to}
                        anchor={record.url_to}
                    />
                )}
            />
            <Column
                className="no-wrap"
                title="Link Type"
                dataIndex="dofollow"
                render={(text, record: Backlink, index) => (record.dofollow ? 'Follow' : 'Nofollow')}
            />

            <Column
                className="no-wrap al-right"
                title="External Links"
                dataIndex="page_from_external_links"
                render={(text, record: Backlink, index) => record.page_from_external_links.toLocaleString()}
            />

            <Column
                className="no-wrap al-right"
                title="Internal Links"
                dataIndex="page_from_internal_links"
                render={(text, record: Backlink, index) => record.page_from_internal_links.toLocaleString()}
            />

            <Column
                className="no-wrap al-right"
                title="First Seen"
                dataIndex="first_seen"
                render={(text, record: Backlink, index) => moment(record.first_seen).format('MMM DD, YYYY')}
            />
            <Column
                className="no-wrap al-right"
                title="Last Seen"
                dataIndex="last_seen"
                render={(text, record: Backlink, index) => moment(record.last_seen).format('MMM DD, YYYY')}
            />
        </Table>
    );
}
