export const isFetchingTemplates = (state) => state["white_label"]["network_activity"].includes("fetching_templates");

export const getSelectedReportGuid = (state) => {
  return state["white_label"]["selected_report_guid"];
};

export const getSelectedReportUrl = (state) => {
  return state["white_label"]["selected_report_url"];
};

export const getSelectedTemplateGuid = (state) => {
  return state["white_label"]["selected_template"];
};

export const getTemplates = (state) => {
  return state["white_label"]["templates"];
};

export const getTemplatesGuid = (state) => {
  let templates = getTemplates(state);
  return templates.map((template) => template["guid"]);
};

export const getSelectedTemplate = (state) => {
  const selectedTemplateGuid = getSelectedTemplateGuid(state);
  const templates = getTemplates(state);
  if (selectedTemplateGuid && templates.length > 0) {
    return templates.find((template) => template["guid"] === selectedTemplateGuid);
  }
  return {
    show_how_to_fix_in_pdf: true,
    logo: "",
    content_blocks: {
      footer: "",
      header: ""
    }
  };
};

export const getDefaultSeoScoreSection = (state) => {
  return state["white_label"]["default_seo_score_section"];
};

export const isAddingTemplate = (state) => state["white_label"]["network_activity"].includes("adding_template");

export const isEditingTemplate = (state) => {
  const guid = getSelectedTemplateGuid(state);
  return state["white_label"]["network_activity"].includes(`editing_template_${guid}`);
};

export const getSortedTemplates = (state, includeDefault = true) => {
  let templates = getTemplates(state);
  let sortedTemplates = templates.sort((a, b) => b["last_modified"] - a["last_modified"]);
  if (!includeDefault) {
    return sortedTemplates.filter((template) => template["guid"] !== "default");
  }
  return sortedTemplates;
};

export const isDownloadingPdfReport = (state, report_url, report_guid, template_guid) => {
  return state["white_label"]["network_activity"].includes(
    `download_pdf_report_${report_url}_${report_guid}_${template_guid}`
  );
};

export const getTemplatesBeingDeleted = (state) => {
  let templatesBeingDeleted = [];
  state["white_label"]["network_activity"].forEach((message) => {
    if (message.includes(`deleting_template_`)) {
      let guid = message.split("_")[2];
      templatesBeingDeleted.push(guid);
    }
  });

  return templatesBeingDeleted;
};
