import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./types";

export const addNotification = (notification_type, message, id, title) => ({
  type: ADD_NOTIFICATION,
  notification_type: notification_type,
  message: message,
  id: id,
  title: title
});

export const removeNotification = id => ({
  type: REMOVE_NOTIFICATION,
  id: id
});
