import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getOutages } from "../../store/outage/selectors";
import { fetchOutageContext } from "../../store/outage/operations";
import { getSelectedWebsiteUrl } from "../../store/tracked_websites/selectors";
import Timeline from "../../components/SiteOutage/Timeline";

const dateFormat = "YYYY-MM-DDTHH:mm:ss";

function hasBlankIntervalAtLeft(outage, start) {
  return outage["start_at"] > start;
}

function hasBlankIntervalAtRight(outage, end) {
  return outage["end_at"] < end;
}

function getLeftInterval(outage, start) {
  return {
    start_at: start,
    end_at: moment(outage["start_at"]).format(dateFormat)
  };
}

function getRightInterval(outage, end) {
  let interval = {
    start_at: moment(outage["end_at"]).format(dateFormat),
    end_at: end
  };
  if (moment().format("YYYY-MM-DD") === moment(end).format("YYYY-MM-DD")) {
    interval["in_future"] = true;
  }
  return interval;
}
export class TimelineContainer extends Component {
  constructor(props) {
    super(props);

    this.currentDay = moment(this.props.endDate).format("YYYY-MM-DD");

    let intervals = this.getIntervals(this.currentDay, this.props.outages[this.currentDay]);
    let currentIntervalIdx = this.getCurrentIntervalIdx(intervals);

    this.props.sendCurrentInterval(intervals[currentIntervalIdx]);
    this.onOutageClick(intervals[currentIntervalIdx]["guid"]);

    this.state = {
      currentDay: this.currentDay,
      currentIntervals: intervals,
      currentIntervalIdx: currentIntervalIdx
    };

    this.getCurrentIntervalIdx = this.getCurrentIntervalIdx.bind(this);
    this.onSelectDay = this.onSelectDay.bind(this);
    this.onSelectOutage = this.onSelectOutage.bind(this);
    this.onOutageClick = this.onOutageClick.bind(this);
    this.getIntervals = this.getIntervals.bind(this);
    this.getDurationInMinutes = this.getDurationInMinutes.bind(this);
    this.enumerateDaysBetweenDates = this.enumerateDaysBetweenDates.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      let intervals = this.getIntervals(this.currentDay, this.props.outages[this.currentDay]);
      let currentIntervalIdx = this.getCurrentIntervalIdx(intervals);

      this.props.sendCurrentInterval(intervals[currentIntervalIdx]);
      this.onOutageClick(intervals[currentIntervalIdx]["guid"]);

      this.setState({
        currentDay: this.currentDay,
        currentIntervals: intervals,
        currentIntervalIdx: currentIntervalIdx
      });
    }
  }

  onOutageClick(guid) {
    this.props.fetchOutageContext(guid);
  }

  getIntervals(day, outages) {
    let intervals = [];
    let startInterval = `${day}T00:00:00`;
    let endInterval = `${day}T23:59:59`;

    if (outages) {
      outages.forEach((outage, idx) => {
        if (hasBlankIntervalAtLeft(outage, startInterval)) {
          let interval = getLeftInterval(outage, startInterval);
          intervals.push(interval);
          intervals.push(outage);

          startInterval = moment(outage["end_at"]).format(dateFormat);
        }
        if (idx === outages.length - 1) {
          if (hasBlankIntervalAtRight(outage, endInterval)) {
            let interval = getRightInterval(outage, endInterval);
            intervals.push(interval);
          }
        }
      });
    } else {
      intervals.push({ start_at: startInterval, end_at: endInterval });
    }
    return intervals;
  }

  getDurationInMinutes(startDate, endDate) {
    var start = moment(startDate, "YYYY-MM-DDTHH:mm:ss");
    var end = moment(endDate, "YYYY-MM-DDTHH:mm:ss");
    var duration = moment.duration(end.diff(start));
    return duration.asMinutes();
  }

  enumerateDaysBetweenDates(startDate, endDate) {
    let days = [];
    while (moment(startDate) <= moment(endDate)) {
      days.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    return days;
  }

  getCurrentIntervalIdx(intervals) {
    let currentIntervalIdx = 0;

    if (intervals[intervals.length - 1]["in_future"]) {
      currentIntervalIdx = intervals.length - 2;
    } else {
      currentIntervalIdx = intervals.length - 1;
    }

    return currentIntervalIdx;
  }

  onSelectDay(event) {
    const day = event.target.getAttribute("data-value");

    const intervals = this.getIntervals(day, this.props.outages[day]);
    let currentIntervalIdx = this.getCurrentIntervalIdx(intervals);

    this.props.sendCurrentInterval(intervals[currentIntervalIdx]);
    this.onOutageClick(intervals[currentIntervalIdx]["guid"]);

    this.setState({
      currentDay: day,
      currentIntervals: intervals,
      currentIntervalIdx: currentIntervalIdx
    });
  }

  onSelectOutage(event) {
    const guid = event.target.getAttribute("data-outage-guid");
    const idx = parseInt(event.target.getAttribute("data-idx"));

    this.setState({
      currentIntervalIdx: idx
    });
    const intervals = this.state.currentIntervals;
    this.props.sendCurrentInterval(intervals[idx]);
    this.onOutageClick(guid);
  }

  render() {
    return (
      <Timeline
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        currentDay={this.state.currentDay}
        currentIntervals={this.state.currentIntervals}
        currentIntervalIdx={this.state.currentIntervalIdx}
        timelineDays={this.enumerateDaysBetweenDates(this.props.startDate, this.props.endDate)}
        durationInMinutes={this.getDurationInMinutes}
        outages={this.props.outages}
        onSelectDay={this.onSelectDay}
        onSelectOutage={this.onSelectOutage}
      />
    );
  }
}

const mapStateToProps = state => ({
  outages: getOutages(state),
  url: getSelectedWebsiteUrl(state)
});

export default connect(mapStateToProps, { fetchOutageContext })(TimelineContainer);
