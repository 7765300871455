import React, { Component } from "react";
import { Modal, Form, Input, Button } from "antd";

export default class PasswordConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      currentPassword: "",
      errorMessage: ""
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoading === true && this.props.isLoading === false && this.state.visible === true) {
      this.setState({ errorMessage: "Password provided is incorrect" });
    }
    // TODO: check why the error message is still visible for a second
    if (prevState.visible === false && this.state.visible === true) {
      this.setState({ errorMessage: "", currentPassword: "" });
    }
  }

  toggleModal() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }

  handleOpenModal(event) {
    this.toggleModal();
    event.currentTarget.blur();
  }

  handleCloseModal() {
    this.toggleModal();
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state.currentPassword);
    event.currentTarget.getElementsByTagName("button")[0].blur();
  }

  handleInputChange(event) {
    this.setState({ currentPassword: event.target.value });
  }

  handleInputFocus(event) {
    this.setState({ errorMessage: "" });
  }

  render() {
    return (
      <div className="confirm-password-modal">
        <Button type="primary" onClick={this.handleOpenModal}>
          {this.props.buttonLabel}
        </Button>
        <Modal
          className="confirm-password-modal"
          title={"Confirm your current password"}
          visible={this.state.visible}
          onCancel={this.handleCloseModal}
          footer={null}
          destroyOnClose={true}>
          <Form data-cy="confirm-password-form" onSubmit={this.handleFormSubmit}>
            <Form.Item
              validateStatus={this.state.errorMessage ? "error" : "success"}
              help={this.state.errorMessage || ""}>
              <Input
                placeholder="Current Password"
                name="currentPassword"
                value={this.state.currentPassword}
                type="password"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.props.isLoading}>
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
