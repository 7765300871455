import React, { useMemo, useState } from 'react';
import { Select, Form, Button, Tooltip, Icon } from 'antd';
import { useFetchLocationsAndLanguagesQuery } from '../trackedWebsites/service';
import { getAvailableLanguagesForLocation } from '../trackedWebsites/utils';

const { Option } = Select;

type Props = {
    customClassName: string;
    initialLocation: string;
    initialLanguageCode: string;
    isChangingLanguageAndLocation: boolean;
    onSubmit: (newLocation: string, newLanguageCode: string) => void;
};

export default function ChangeLocationAndLanguageForm({
    customClassName,
    initialLocation,
    initialLanguageCode,
    isChangingLanguageAndLocation,
    onSubmit,
}: Props) {
    const [selectedLocation, setSelectedLocation] = useState<string>(initialLocation);
    const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(initialLanguageCode);

    const { data: locationsAndLanguages } = useFetchLocationsAndLanguagesQuery();

    const availableLanguages = useMemo(
        () => (locationsAndLanguages ? getAvailableLanguagesForLocation(selectedLocation, locationsAndLanguages) : []),
        [locationsAndLanguages, selectedLocation]
    );

    const handleLocationChange = (newSelectedLocation: string) => {
        setSelectedLocation(newSelectedLocation);

        if (locationsAndLanguages) {
            setSelectedLanguageCode(getAvailableLanguagesForLocation(newSelectedLocation, locationsAndLanguages)[0].language_code);
        } else {
            setSelectedLanguageCode('');
        }
    };

    const handleSelectedLanguageCodeChange = (newSelectedLanguageCode: string) => {
        setSelectedLanguageCode(newSelectedLanguageCode);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit(selectedLocation, selectedLanguageCode);
    };
    const infoMsg = 'Please select the country and the language of your primary target audience - our keyword tracking tool will retrieve region specific data from the appropriate keyword database.';
    const isSaveButtonDisabled = (selectedLocation === initialLocation && selectedLanguageCode === initialLanguageCode) || selectedLanguageCode === '';

    return (
        <Form
            layout="vertical"
            onSubmit={handleSubmit}
            className={customClassName}
            style={{ padding: 20 }}
        >
            {customClassName === 'dashboard-settings-change-regional-db-form' ? (
                <p>
                    <strong>Location & Language </strong>
                    <Tooltip title={infoMsg}>
                        <Icon type="question-circle" />
                    </Tooltip>
                </p>
            ) : (
                <p>{infoMsg}</p>
            )}

            <Form.Item>
                <p style={{ marginBottom: "6px" }}><strong>Country:</strong></p>
                <Select
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    size="large"
                    style={{ marginBottom: "24px" }}
                >
                    {locationsAndLanguages &&
                        locationsAndLanguages.map((location) => (
                            <Option
                                key={location.location_name}
                                data-cy="change-db-select-option"
                                value={location.location_name}
                            >
                                {location.location_name}
                            </Option>
                        ))}
                </Select>

                <p style={{ marginBottom: "6px" }}><strong>Language:</strong></p>
                <Select
                    disabled={!selectedLocation}
                    value={selectedLanguageCode}
                    onChange={handleSelectedLanguageCodeChange}
                    size="large"
                    style={{ marginBottom: "24px" }}
                >
                    {availableLanguages.map((language) => (
                        <Option
                            key={language.language_code}
                            value={language.language_code}
                        >
                            {language.language_name}
                        </Option>
                    ))}
                </Select>

                <Button

                    type="primary"
                    htmlType="submit"
                    loading={isChangingLanguageAndLocation}
                    size="large"
                    disabled={isSaveButtonDisabled}
                >
                    {customClassName === 'dashboard-settings-change-regional-db-form' ? 'Apply' : 'Change'}
                </Button>
            </Form.Item>
        </Form>
    );
}
