import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import UserTemplates from "../../components/WhiteLabel/templates/UserTemplates";
import { getSortedTemplates, getTemplatesBeingDeleted } from "../../store/white_label/selectors";
import { changeSelectedTemplate } from "../../store/white_label/creators";
import { deleteTemplate } from "../../store/white_label/operations";

export class UserTemplatesContainer extends Component {
  render() {
    return (
      <UserTemplates
        templates={this.props.templates}
        changeSelectedTemplate={this.props.changeSelectedTemplate}
        deleteTemplate={this.props.deleteTemplate}
        templatesBeingDeleted={this.props.templatesBeingDeleted}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = state => {
  const includeDefault = false;
  return {
    templates: getSortedTemplates(state, includeDefault),
    templatesBeingDeleted: getTemplatesBeingDeleted(state)
  };
};

export default connect(mapStateToProps, { changeSelectedTemplate, deleteTemplate, push })(UserTemplatesContainer);
