import React, { useContext } from 'react';
import { Layout } from 'antd';
import TrackedWebsites from '../../trackedWebsites/TrackedWebsites';
import TopKeywords from '../TopKeywords';
import { SelectedWebsiteContext } from '../../trackedWebsites/ContextWrapper';

const { Content } = Layout;

export default function TopKeywordsPage() {
    const { selectedWebsite, setSelectedWebsite } = useContext(SelectedWebsiteContext);

    return (
        <Content className="general-layout-content">
            <TrackedWebsites
                tabContent={
                    selectedWebsite ? (
                        <TopKeywords
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    ) : (
                        <></>
                    )
                }
                destroyInactiveTabs
                showDomains
            />
        </Content>
    );
}
