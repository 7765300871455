import React from "react";
import { RegisterOrganizationMember } from "../containers";

const RegisterOrganizationMemberPage = (props) => {
  const token = props.match.params.token;

  return (
    <div>
      <RegisterOrganizationMember token={token} />
    </div>
  );
};

export default RegisterOrganizationMemberPage;
