import { 
  FETCH_SITEMAP_IN_PROGRESS, 
  FETCH_SITEMAP_SUCCESS, 
  FETCH_SITEMAP_FAILED, 
  GENERATE_SITEMAP_IN_PROGRESS,
  GENERATE_SITEMAP_SUCCESS,
  GENERATE_SITEMAP_FAILED 
} from "./types";

export const fetchSitemapInProgress = (domain, last_url_hash) => {
  return { type: FETCH_SITEMAP_IN_PROGRESS, domain, last_url_hash};
};

export const fetchSitemapSuccess = (domain, data, last_url_hash) => {
  return { type: FETCH_SITEMAP_SUCCESS, domain, data, last_url_hash };
};

export const fetchSitemapFailed = (domain, last_url_hash) => {
  return { type: FETCH_SITEMAP_FAILED, domain, last_url_hash };
};

export const generateSitemapInProgress = domain => {
  return { type: GENERATE_SITEMAP_IN_PROGRESS, domain };
};

export const generateSitemapSuccess = (domain, data) => {
  return { type: GENERATE_SITEMAP_SUCCESS, domain, data };
};

export const generateSitemapFailed = domain => {
  return { type: GENERATE_SITEMAP_FAILED, domain };
};