import React, { Component } from "react";
import { connect } from "react-redux";
import SeoReportSidebar from "../../components/SeoReport/SeoReportSidebar";
import {
  getSectionsResults,
  getSectionsStatusCounter,
  getCategorizedSelectedSections,
  getCategoriesSectionsStatusCounter
} from "../../store/analysis/selectors";

class SeoReportSidebarWrapper extends Component {
  render() {
    return (
      <SeoReportSidebar
        categorizedSelectedSections={this.props.categorizedSelectedSections}
        sectionsResults={this.props.sectionsResults}
        sectionsStatusCounter={this.props.sectionsStatusCounter}
        categoriesSectionsStatusCounter={this.props.categoriesSectionsStatusCounter}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { reportGuid } = ownProps;
  return {
    categorizedSelectedSections: getCategorizedSelectedSections(state, reportGuid),
    sectionsResults: getSectionsResults(state, reportGuid),
    sectionsStatusCounter: getSectionsStatusCounter(state, reportGuid),
    categoriesSectionsStatusCounter: getCategoriesSectionsStatusCounter(state, reportGuid)
  }
};

export default connect(
  mapStateToProps,
  {}
)(SeoReportSidebarWrapper);
