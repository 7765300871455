import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';
import { validate } from 'validate.js';
import { urlValidationRule } from '../../../trackedWebsites/utils';

const initialState = {
    newCompetitorUrl: '',
    validationError: '',
};

type Props = {
    selectedWebsite: TrackedWebsite;
    isDisabled: boolean;
    isAddingCompetitor: boolean;
    onAddedCompetitor: (args: UpdateTrackedWebsitesParams) => void;
};

export default function CompetitorsInput({ selectedWebsite, isDisabled, isAddingCompetitor, onAddedCompetitor }: Props) {
    const [state, setState] = useState(initialState);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const newCompetitorUrl = state.newCompetitorUrl.toLowerCase().trim();

        if (selectedWebsite.url === newCompetitorUrl) {
            setState({ ...state, validationError: 'The competitor URL must be different than the main URL!' });
            return;
        }

        if (selectedWebsite.settings.competitors.includes(newCompetitorUrl)) {
            setState({ ...state, validationError: 'This competitor is already added to your list!' });
            return;
        }

        const validationError = validate({ url: newCompetitorUrl }, urlValidationRule);

        if (!validationError) {
            const updatedCompetitors = [...selectedWebsite.settings.competitors, newCompetitorUrl];

            onAddedCompetitor({
                guid: selectedWebsite.guid,
                url: selectedWebsite.url,
                competitors: updatedCompetitors,
            });

            setState(initialState);
        } else {
            setState({ ...state, validationError: validationError.url });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ newCompetitorUrl: event.target.value, validationError: '' });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item
                validateStatus={state.validationError ? 'error' : 'success'}
                help={state.validationError}
            >
                <Input.Group
                    compact
                    size="large"
                >
                    <div className="compact-input-group">
                        <Input
                            name="competitorUrl"
                            value={state.newCompetitorUrl}
                            onChange={handleInputChange}
                            placeholder="Add new competitor url"
                            size="large"
                        />

                        <Button
                            type="primary"
                            htmlType="submit"
                            icon="plus"
                            size="large"
                            disabled={isDisabled}
                            loading={isAddingCompetitor}
                        ></Button>
                    </div>
                </Input.Group>
            </Form.Item>
        </Form>
    );
}
