import React from 'react';
import PropTypes from 'prop-types';

const SimpleList = ({list}) => {

    const htmlList = () => (
        <ul>
            {
                list.map((item, index) => (
                    <li key={index}>
                        {item}
                    </li>
                ))
            }
        </ul>
    );

    return (
        <div>
          {
              Array.isArray(list) && list.length ? htmlList() : null
          }
        </div>
    )
};


SimpleList.propTypes = {
    list: PropTypes.array.isRequired,
};

export default SimpleList;
