import React from 'react';
import HtmlContent from '../HtmlContent/HtmlContent';
import { Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const SectionTooltip = ({tooltip}) => {
    return (
        <div className="report-element-info">
            <Tooltip placement="left" title={<HtmlContent content={tooltip} />}>
                <Icon type="info-circle" theme="filled" className="info-icon" />
            </Tooltip>
        </div>
    )
};

SectionTooltip.propTypes = {
    tooltip: PropTypes.string,
};

export default SectionTooltip;
