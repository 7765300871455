import React, { Component } from "react";
import { Layout, Drawer, Icon } from "antd";
//import NotificationDropdown from "./NotificationDropdown";
import UserProfileDropdown from "./UserProfileDowndrop";
import CustomIcon from "../../CustomIcon";
import SideMenu from "../SideMenu/SideMenu";
import "./AuthTopNav.less";
import ProfitWell from "../../../containers/Trackers/ProfitWell";

const { Header } = Layout;

export default class AuthTopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    //this.onMenuItemClick = this.props.onMenuItemClick;
  }
  //state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };
  handleUserLogout = (_) => {
    this.props.logout();
    this.props.push("/auth/login");
  };

  showBillingPage = (_) => {
    const subscription = this.props.subscription;

    if (subscription && subscription["is_legacy_paypal"]) {
      return false;
    }

    if (this.props.orgRole !== "owner") {
      return false;
    }

    return true;
  };

  showTeamMembersPage = (_) => {
    if (this.props.orgRole !== "owner") {
      return false;
    }

    return true;
  };

  render() {
    return this.props.showPageHeader ? (
      <Header className="general-layout-header">
        <div className="layout-header-container">
          <div className="header-page-location flexbox align-center">
            <div onClick={this.showDrawer} className="mobile-menu-trigger">
              <CustomIcon name="menu-unfold" className="icon" size="24" />
            </div>
            {this.props.pageTitle}
          </div>
          <div className="header-account-triggers">
            <UserProfileDropdown
              isLoading={this.props.isLoading}
              user={this.props.user}
              logout={this.props.logout}
              push={this.props.push}
              showBillingPage={this.showBillingPage()}
              showTeamMembersPage={this.showTeamMembersPage()}
            />
            {/** Send data to ProfitWell only if the user is the team owner */}
            {this.props.user && !this.props.isLoading && this.props.orgRole === "owner" && (
              <ProfitWell userEmail={this.props.user.email} />
            )}
          </div>
        </div>

        <Drawer
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="mobile-menu-drawer">
          <div className="top-side">
            <div className="logo" />
          </div>
          <div className="menu-wrapper">
            <SideMenu
              selectedMenuItem={this.props.selectedMenuItem}
              onMenuItemClick={this.props.push}
              collapsed={false}
            />
          </div>
        </Drawer>
      </Header>
    ) : (
      <div className="no-subscription-wrapper">
        <div className="no-subscription-header">
          <div className="auth-logo" />
          <div className="logged-user">
            {this.props.user && !this.props.isLoading ? (
              <React.Fragment>
                <ProfitWell userEmail={this.props.user.email} />

                <span className="email">{this.props.user.email}</span>
                <span className="separator"> | </span>
              </React.Fragment>
            ) : (
              <span className="wait-for-user">
                <Icon type="loading" />
              </span>
            )}

            <span className="logout">
              <span onClick={this.handleUserLogout}>Logout</span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
