import React from "react";
import { Progress, Card, Button } from "antd";
import PropTypes from "prop-types";
import SeoScore from "./SeoScore";
import CompetitorSeoScore from "./CompetitorSeoScore";
import CustomIcon from "../CustomIcon/CustomIcon";
import moment from "moment";

const { Meta } = Card;

const SeoReportScore = ({
  url,
  sectionsStatusCounter,
  seoScore,
  sectionsStatusProgress,
  competitorsUrls,
  competitorsSectionsStatusCounter,
  competitorsSeoScore,
  analysisProgress,
  handleWhiteLabelRedirect,
  handlePdfDownload,
  isDownloadingPdfReport,
  isFullReport,
  createdAt
}) => {
  return (
    <Card className="report-card summary" id="seo-score">
      <div className="card-header">
        <Meta
          title={url}
          description={`Report generated on ${moment(createdAt, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY")}`}
        />

        <div className="report-summary-actions seo-report-header">
          <Button
            data-cy={"download-report-btn"}
            icon={isDownloadingPdfReport ? "loading" : "file-pdf"}
            onClick={handlePdfDownload}
            disabled={analysisProgress !== 100}>
            Download PDF
          </Button>

          <Button data-cy={"white-label-btn"} disabled={analysisProgress !== 100} onClick={handleWhiteLabelRedirect}>
            <CustomIcon className="icon" name="brand" size="18" />
            White Label
          </Button>
        </div>

        {analysisProgress !== 100 && (
          <Progress percent={analysisProgress} status="active" data-cy="analysis-progress" />
        )}
      </div>

      <SeoScore
        seoScore={seoScore}
        sectionsStatusCounter={sectionsStatusCounter}
        sectionsStatusProgress={sectionsStatusProgress}
        inProgress={analysisProgress !== 100}
        isFullReport={isFullReport}
      />
      {competitorsUrls.length && Object.keys(competitorsSectionsStatusCounter).length ? (
        <React.Fragment>
          <div className="competitors-divider">
            <span>Competitors</span>
          </div>
          <div className="competitor-wrapper">
            {competitorsUrls.map((url) => (
              <CompetitorSeoScore
                key={url}
                competitorUrl={url}
                competitorsSectionsStatusCounter={competitorsSectionsStatusCounter[url]}
                competitorSeoScore={competitorsSeoScore[url]}
              />
            ))}
          </div>
        </React.Fragment>
      ) : null}
    </Card>
  );
};

SeoReportScore.propTypes = {
  url: PropTypes.string.isRequired,
  statusCounter: PropTypes.shape({
    passed: PropTypes.number.isRequired,
    warning: PropTypes.number.isRequired,
    failed: PropTypes.number.isRequired
  })
};

export default SeoReportScore;
