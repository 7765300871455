import React, { Component } from "react";
import { connect } from "react-redux";
import { updateRequiredExperiences } from "../store/iam/operations";
import { getRequiredExperiences } from "../store/iam/selectors";
import WelcomeScreen from "../components/shared/WelcomeScreen";


const requiredExperiences = {
  should_see_welcome_screen: {
    title: "Welcome to the upgraded Toolbox version",
    message: `<p>You are now experiencing the new and improved version of your Toolbox account. The update brings not only a new design but also better performance and new features.</p>
    <p>We, the SEOSiteCheckup team, hope that you will enjoy the changes that we’ve made. </p>
    <p>If you encounter any difficulties or want to make any suggestions, please don’t hesitate to contact us at: <a href="mailto:information@seositecheckup.com">information@seositecheckup.com</a></p>`
  }
};

const getWelcomeScreenContent = (requiredExperience, requiredExperiences, contentType) => {
  let content = "";
  if (requiredExperience && requiredExperiences[requiredExperience]) {
    content = requiredExperiences[requiredExperience][contentType];
  }
  return content;
};

class WelcomeScreenContainer extends Component {
  constructor(props) {
    super(props);

    this.firstRequiredExperience = this.props.requiredExperiences[0];

    this.state = {
      isVisible: !!this.firstRequiredExperience
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    if (this.state.isVisible) {
      let updatedRequiredExperiences = this.props.requiredExperiences.filter(
        item => item !== this.firstRequiredExperience
      );
      this.props.updateRequiredExperiences(updatedRequiredExperiences);
    }
  }

  handleCloseModal() {
    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }));
  }

  render() {
    return (
      <WelcomeScreen
        isVisible={this.state.isVisible}
        closeModal={this.handleCloseModal}
        title={getWelcomeScreenContent(this.firstRequiredExperience, requiredExperiences, "title")}
        message={getWelcomeScreenContent(this.firstRequiredExperience, requiredExperiences, "message")}
      />
    );
  }
}

const mapStateToProps = state => ({
  requiredExperiences: getRequiredExperiences(state)
});

export default connect(mapStateToProps, { updateRequiredExperiences })(WelcomeScreenContainer);
