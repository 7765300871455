import React, { Component } from "react";
import { Card, Form, Icon, Input, Button, Checkbox } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_API_KEY } from "../../../settings/config";
import { validate } from "validate.js";
import loginValidationRules from "./validators";

const { Meta } = Card;

export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      rememberMe: false,
      validationErrors: {},
      isRecaptchaVerified: false,
      recaptchaToken: ""
    };

    this.captcha = null;
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldResetCaptcha && this.props.shouldResetCaptcha) {
      this.captcha.reset();
    }
  }

  validateForm() {
    return validate(
      {
        email: this.state.email,
        password: this.state.password
      },
      loginValidationRules
    );
  }

  onFormSubmit(event) {
    event.preventDefault();

    const validationErrors = this.validateForm();
    if (!validationErrors && this.state.isRecaptchaVerified) {
      this.setState({ validationErrors: {}, isRecaptchaVerified: false, recaptchaToken: "" });
      this.props.onSubmit(this.state.email, this.state.password, this.state.rememberMe, this.state.recaptchaToken);
    } else {
      this.setState({ validationErrors });
    }
  }

  onInputChange(event) {
    const newState = {};
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    newState[event.target.name] = value;
    this.setState(newState);
  }

  onInputFocus(event) {
    if (this.state.validationErrors[event.target.name]) {
      const newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
      this.setState(newState);
    }
  }

  onInputBlur(event) {
    const validationErrors = this.validateForm();

    let newState = {};
    if (!validationErrors) {
      newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
    } else {
      newState = {
        ...this.state,
        validationErrors: {
          ...this.state.validationErrors,
          [event.target.name]: validationErrors[event.target.name]
        }
      };
    }
    this.setState(newState);
  }

  onRecaptchaSuccess(token) {
    if (token) {
      //this.setState({ recaptchaToken: token });
      this.setState({ recaptchaToken: token });
      this.setState({ isRecaptchaVerified: true });
    }
  }

  render() {
    const errors = this.state.validationErrors;

    return (
      <div className="LoginForm">
        <div className="authentication-form-wrapper">
          <div className="auth-logo" />

          <Card className="auth-card" bordered={false}>
            <div className="card-header">
              <Meta title="Login" />
            </div>
            <Form className="login-form" onSubmit={this.onFormSubmit}>
              <Form.Item
                validateStatus={errors.email && errors.email[0] ? "error" : "validating"}
                help={errors.email && errors.email[0]}>
                <Input
                  type="text"
                  name="email"
                  prefix={<Icon type="mail" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  value={this.state.email}
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                  placeholder="Email address"
                  autoComplete="new-text"
                  spellCheck="false"
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.password && errors.password[0] ? "error" : "validating"}
                help={errors.password && errors.password[0]}>
                <Input
                  name="password"
                  prefix={<Icon type="lock" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Form.Item className="checkbox-wrapper">
                <Checkbox name="rememberMe" defaultChecked={this.state.rememberMe} onChange={this.onInputChange}>
                  Remember me
                </Checkbox>
              </Form.Item>

              <ReCAPTCHA
                ref={(el) => {
                  if (el) {
                    this.captcha = el;
                  }
                }}
                className="rec"
                sitekey={RECAPTCHA_API_KEY}
                onChange={(token) => this.onRecaptchaSuccess(token)}
              />

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.props.isLoading}
                  disabled={!this.state.isRecaptchaVerified}
                  style={{ marginTop: "20px" }}>
                  {this.props.isLoading ? "Loading" : "Login"}
                </Button>
              </Form.Item>
            </Form>
            <div className="fake-link forgot-pass" onClick={() => this.props.push("/auth/forgot-password")}>
              Forgot password?
            </div>
          </Card>
          <div className="under-card-info">
            Don't have an account?{" "}
            <span className="fake-link" onClick={() => this.props.push("/auth/register")}>
              <strong>Sign up for free</strong>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
