import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import Notifications from "./containers/Notifications";
import { RegisterPage } from "./pages";
import { LoginPage } from "./pages";
import { ForgotPasswordPage } from "./pages";
import { DashboardPage } from "./pages";
import { AnalysisPage } from "./pages";
import { AnalysisReportPage } from "./pages";
import BacklinksPage from "./backlinks/routes/BacklinksPage";
import KeywordPositionPage from './trackedKeywords/routes/KeywordPositionPage';
import BrokenLinksPage from './brokenLinks/routes/BrokenLinksPage';
import SiteSpeedPage from './siteSpeed/routes/SiteSpeedPage';
import SiteOutagePage from './outage/routes/SiteOutagePage';
import { WhiteLabelPage } from "./pages";
import SitemapGeneratorPage from './sitemap/routes/SitemapGeneratorPage';
import TopKeywordsPage from './topKeywords/routes/TopKeywordsPage';
import { SubscriptionPlansPage } from "./pages";
import { isLoggedIn } from "./store/iam/selectors";
import { AuthHeader } from "./containers";
import AuthPageWrapper from "./components/layout/AuthPageWrapper";
import KeywordResearchPage from './keywordResearch/routes/KeywordResearchPage';
import { UserAccountPage } from "./pages";
import DashboardSettingsPage from "./dashboard/routes/DashboardSettingsPage";
import { FreeTrialSetupPage } from "./pages";
import { SubscriptionCheckoutPage } from "./pages";
import { BillingInfoPage } from "./pages";
import { NotFoundPage } from "./pages";
import { AccountSetupPage } from "./pages";
import { ResetPasswordPage } from "./pages";
import { TeamMembersPage } from "./pages";
import { RegisterOrganizationMemberPage } from "./pages";
import { getLocation } from "connected-react-router";
import SelectedWebsiteContextWrapper from "./trackedWebsites/ContextWrapper";
import "./styles/theme.less";

const noSideBarPages = ["/free-trial-setup", "/user/account/setup"];

const showSideBar = (pathname) => {
    if (noSideBarPages.includes(pathname)) {
        return false;
    }
    return true;
};

const showPageHeader = (pathname) => {
    if (noSideBarPages.includes(pathname)) {
        return false;
    }
    return true;
};

function App({ history, isLoggedIn, pathname }) {
    return (
        <div className="app">
            <ConnectedRouter history={history}>
                <div className="app-container">
                    {!isLoggedIn && (
                        <React.Fragment>
                            <Notifications />
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/auth/login" />
                                </Route>
                                <Route path="/auth/register" component={RegisterPage} />
                                <Route path="/auth/login" component={LoginPage} />
                                <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
                                <Route path="/reset-password/:id" component={ResetPasswordPage} />
                                <Route path="/auth" component={LoginPage} />
                                <Route path="/join-team/:token" component={RegisterOrganizationMemberPage} />
                            </Switch>
                        </React.Fragment>
                    )}
                    {isLoggedIn && pathname === "/auth/login" && <Redirect to="/dashboard" />}
                    {isLoggedIn && pathname.startsWith("/reset-password/") && (
                        <React.Fragment>
                            <Notifications />
                            <Switch>
                                <Route path="/reset-password/:id" component={ResetPasswordPage} />
                            </Switch>
                        </React.Fragment>
                    )}
                    {isLoggedIn && !pathname.startsWith("/reset-password/") && (
                        <AuthPageWrapper showSideBar={showSideBar(pathname)}>
                            <AuthHeader showPageHeader={showPageHeader(pathname)} />
                            <Notifications />
                            <SelectedWebsiteContextWrapper>
                                <Switch>
                                    <Route exact path="/" component={DashboardPage} />
                                    <Route exact path="/dashboard" component={DashboardPage} />
                                    <Route exact path="/analysis" component={AnalysisPage} />
                                    <Route path="/analysis/report" component={AnalysisReportPage} />
                                    <Route path="/backlinks" component={BacklinksPage} />
                                    <Route path="/top-keywords" component={TopKeywordsPage} />
                                    <Route path="/keyword-position" component={KeywordPositionPage} />
                                    <Route path="/keyword-research" component={KeywordResearchPage} />
                                    <Route path="/broken-links" component={BrokenLinksPage} />
                                    <Route path="/site-speed" component={SiteSpeedPage} />
                                    <Route path="/site-outage" component={SiteOutagePage} />
                                    <Route
                                        path={[
                                            "/white-label/templates/:id",
                                            "/white-label/templates",
                                            "/white-label/reports"
                                        ]}
                                        component={WhiteLabelPage}
                                    />
                                    <Route path="/sitemap-generator" component={SitemapGeneratorPage} />
                                    <Route path="/user/account/profile" component={UserAccountPage} />                                  
                                    <Route path="/user/team-members" component={TeamMembersPage} />
                                    <Route
                                        path="/dashboard/settings/:selectedOption"
                                        component={DashboardSettingsPage}
                                    />
                                    <Route path="/subscription/plans" component={SubscriptionPlansPage} />
                                    <Route path="/subscription/checkout" component={SubscriptionCheckoutPage} />
                                    <Route path="/user/account/billing" component={BillingInfoPage} />
                                    <Route path="/user/account/setup" component={AccountSetupPage} />
                                    <Route path="/free-trial-setup" component={FreeTrialSetupPage} />
                                    <Route path="" component={NotFoundPage} />
                                </Switch>
                            </SelectedWebsiteContextWrapper>
                        </AuthPageWrapper>
                    )}
                </div>
            </ConnectedRouter>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoggedIn: isLoggedIn(state),
    pathname: getLocation(state).pathname
});

export default connect(mapStateToProps, null)(App);
