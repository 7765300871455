export const isLoggedIn = (state) => {
  try {
    if (state.iam.access_token) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const userIsLoggingIn = (state) => state.iam.network_activity.includes("authenticate");

export const userIsRegistering = (state) => state.iam.network_activity.includes("registration");

export const isRegistrationAndAuthenticationFinished = (state) =>
  state.iam.network_activity.includes(`registration_and_authentication_in_progress`);

export const isDeletingUser = (state) => state.iam.network_activity.includes("delete_user");

export const userIsLoading = (state) => state.iam.network_activity.includes("getCurrentUser");

export const loadingResetPasswordRequest = (state) => state.iam.network_activity.includes("forgot-password");

export const getAccessToken = (state) => state["iam"]["access_token"];
export const getRefreshToken = (state) => state["iam"]["refresh_token"];

export const getLoggedInUserGuid = (state) => {
  try {
    const access_token = state.iam.access_token;
    const token_body = JSON.parse(atob(access_token.split(".")[1]));
    return token_body.identity;
  } catch {
    return null;
  }
};

export const getLoggedInUserSubscription = (state) => {
  try {
    const accessToken = state.iam["access_token"];
    const tokenBody = JSON.parse(atob(accessToken.split(".")[1]));

    return tokenBody["user_claims"]["subscription"];
  } catch {
    return null;
  }
};

export const getLoggedInUserOrganization = (state) => {
  try {
    const accessToken = state.iam["access_token"];
    const tokenBody = JSON.parse(atob(accessToken.split(".")[1]));

    return {
      guid: tokenBody["user_claims"]["o_guid"],
      role: tokenBody["user_claims"]["org_role"]
    };
  } catch {
    return null;
  }
};

export const hasSubscription = (state) => {
  try {
    const accessToken = state.iam["access_token"];
    const tokenBody = JSON.parse(atob(accessToken.split(".")[1]));

    if (tokenBody["user_claims"]["subscription"]["plan_code"]) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

export const getUser = (state) => state.iam.user;

export const getUserEmail = (state) => {
  if (state["iam"] && state["iam"]["user"]) {
    return state["iam"]["user"]["email"];
  }
};

export const getUserFirstName = (state) => {
  if (state["iam"] && state["iam"]["user"]) {
    return state["iam"]["user"]["f_name"];
  }
};

export const getUserLastName = (state) => {
  if (state["iam"] && state["iam"]["user"]) {
    return state["iam"]["user"]["l_name"];
  }
};

export const isChangingUserName = (state) => state.iam.network_activity.includes("change_user_name");

export const isChangingPassword = (state) => state.iam.network_activity.includes("change_password");

export const isConfirmingPassword = (state) => state.iam.network_activity.includes("confirm_password");

export const isRefreshingAccessToken = (state) => state["iam"]["network_activity"].includes("update_access_token");
// export const isTokenExpired = token => {
//   try {
//     const { exp } = JWTDecode(token);
//     if (Date.now() / 1000 > exp) {
//       return true;
//     }
//   } catch {
//     return true;
//   }
//   return false;
// };

// export const isAccessTokenExpired = state => {
//   const accessToken = state.iam.accessToken;
//   return isTokenExpired(accessToken);
// };

export const getRequiredExperiences = (state) => {
  let requiredExperiences = [];
  let user = getUser(state);

  if (user && user["required_experiences"]) {
    requiredExperiences = user["required_experiences"];
  }

  return requiredExperiences;
};

export const getOrganization = (state) => state.iam.organization;
export const getTeamMembers = (state) => state.iam.team_members;
export const getTeamInvitations = (state) => state.iam.team_invitations;
export const isFetchingOrganization = (state) => state.iam.network_activity.includes("get_organization");
export const isSendingInvitation = (state) => state.iam.network_activity.includes("invite_team_member");
export const isUpdatingOrganization = (state) => state.iam.network_activity.includes("update_organization");
