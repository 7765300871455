import React from "react";
import { Progress } from "antd";
import PropTypes from "prop-types";

const CategoryStats = ({ passed_status_total, warning_status_total, failed_status_total, score }) => {
  const total = passed_status_total + warning_status_total + failed_status_total;
  const getPercentage = (total, value) => {
    return (value * 100) / total;
  };

  const getStatusByScore = score => {
    if (score < 50) return "failed";
    if (score >= 50 && score < 75) return "warning";
    if (score >= 75) return "passed";
  };

  return (
    <div className="category-stats">
      <div className={`stat-element ${getStatusByScore(score)}`}>
        <span className="stat-name">Score: </span>
        <span className="stat-value">{score}</span>
        <Progress percent={getPercentage(100, score)} showInfo={false} />
      </div>

      <div className="stat-element failed">
        <span className="stat-name">Failed: </span>
        <span className="stat-value">{failed_status_total}</span>
        <Progress percent={getPercentage(total, failed_status_total)} showInfo={false} />
      </div>

      <div className="stat-element warning">
        <span className="stat-name">Warnings: </span>
        <span className="stat-value">{warning_status_total}</span>
        <Progress percent={getPercentage(total, warning_status_total)} showInfo={false} />
      </div>

      <div className="stat-element passed">
        <span className="stat-name">Passed: </span>
        <span className="stat-value">{passed_status_total}</span>
        <Progress percent={getPercentage(total, passed_status_total)} showInfo={false} />
      </div>
    </div>
  );
};

CategoryStats.propTypes = {
  statusCounter: PropTypes.shape({
    passed: PropTypes.number.isRequired,
    warning: PropTypes.number.isRequired,
    failed: PropTypes.number.isRequired
  })
};

export default CategoryStats;
