import React from 'react';
import { Col, Row, Icon } from 'antd';
import { getMostRecentData, getTrendProperties, getVisibleOverviewData } from '../utils';
import { backlinksOverviewSections } from '../settings';
import TableColumnWithTooltip from '../../components/shared/TableColumnWithTooltip/TableColumnWithTooltip';
import BacklinksOverviewBox from './BacklinksOverviewBox';
import { BacklinksOverviewData } from '../types';

type Props = {
    overview: BacklinksOverviewData;
    displayOrder: string[];
    showChart: boolean;
};

export default function BacklinksOverview({ overview, displayOrder, showChart }: Props) {
    return (
        <Row
            gutter={20}
            className="backlinks-overview-wrapper"
        >
            {displayOrder.map((section) => (
                <Col
                    className="overview-box"
                    key={backlinksOverviewSections[section].key}
                >
                    <BacklinksOverviewBox
                        title={
                            <TableColumnWithTooltip
                                title={backlinksOverviewSections[section].title}
                                tooltip={backlinksOverviewSections[section].tooltip}
                                noFill
                            />
                        }
                        value={getMostRecentData(overview, backlinksOverviewSections[section].key)}
                        trend={<Icon {...getTrendProperties(overview, backlinksOverviewSections[section].key)} />}
                        chartData={getVisibleOverviewData(overview, backlinksOverviewSections[section].key)}
                        showChart={showChart}
                    />
                </Col>
            ))}
        </Row>
    );
}
