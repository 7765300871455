import { Alert, Form } from 'antd';
import React, { useState } from 'react';
import validate from 'validate.js';
import { useLazyCheckDomainsBlacklistQuery } from '../../service';
import { useCreateReportMutation } from '../../../analysis/hooks/useCreateReportMutation';
import { normalizeUrl, urlValidationRule } from '../../utils';
import UrlInput from '../UrlInput';
import FirstStepButtons from '../FirstStepButtons';

const initialState = {
    urlValidationError: '',
    urlExistenceError: '',
    isCheckingUrlExistence: false,
};

type Props = {
    domain: string;
    urlSchema: string;
    fromOnboarding: boolean;
    onUrlChange: (domain: string, urlSchema: string) => void;
    onSkipOnboarding: () => void;
    onFinishThisStep: () => void;
};

export default function SetWebsiteStep({ domain, urlSchema, fromOnboarding, onUrlChange, onSkipOnboarding, onFinishThisStep }: Props) {
    const [state, setState] = useState({ ...initialState });
    const [checkDomainsBlacklist] = useLazyCheckDomainsBlacklistQuery();
    const { createReport } = useCreateReportMutation();

    const handleUrlChange = (newDomain: string, newUrlSchema: string) => {
        setState({ urlValidationError: '', urlExistenceError: '', isCheckingUrlExistence: false });
        onUrlChange(newDomain, newUrlSchema);
    };

    const validateUrl = async () => {
        const normalizedUrl = normalizeUrl(domain, urlSchema);
        const validationError = validate({ url: normalizedUrl }, urlValidationRule);

        if (validationError) {
            setState({ ...state, urlValidationError: validationError.url[0] });
            return;
        }

        setState({ ...state, isCheckingUrlExistence: true });

        try {
            const blacklistData = await checkDomainsBlacklist({ url: normalizedUrl }).unwrap();

            if (blacklistData.status === 'error') {
                setState({ ...state, urlExistenceError: blacklistData.message, isCheckingUrlExistence: false });
                return;
            }

            // Replaced with custom hook because access token was not used
            const reportData = await createReport(normalizedUrl, []);

            if ('error' in reportData) {
                setState({
                    ...state,
                    urlExistenceError:
                        'We cannot access your url in order to perform our test! Either the URL is incorrect, your site is experiencing downtime, or our tool is being blocked by your server.',
                    isCheckingUrlExistence: false,
                });
                return;
            }

            setState({ ...state, isCheckingUrlExistence: false });
            onFinishThisStep();
        } catch (error) {
            setState({ ...state, urlExistenceError: 'Error. Try again.', isCheckingUrlExistence: false });
        }
    };

    return (
        <div className="steps-content">
            <Form className="step-form">
                <Form.Item>
                    <div>
                        <h3>Main Website</h3>
                        <p>Track new website in order to have access to dashboard reports at any time</p>
                    </div>
                </Form.Item>

                <Form.Item
                    validateStatus={state.urlValidationError ? 'error' : 'validating'}
                    help={state.urlValidationError ? state.urlValidationError : ''}
                >
                    <UrlInput
                        id="main_url"
                        placeholder={'example.com'}
                        domain={domain}
                        urlSchema={urlSchema}
                        onUrlChange={handleUrlChange}
                    />
                </Form.Item>

                {state.urlExistenceError && (
                    <Form.Item>
                        <Alert
                            message={state.urlExistenceError}
                            type="error"
                        />
                    </Form.Item>
                )}
            </Form>
            <div className="steps-action">
                <FirstStepButtons
                    showSkip={fromOnboarding}
                    isLoading={state.isCheckingUrlExistence}
                    onSkipClicked={onSkipOnboarding}
                    onNextClicked={validateUrl}
                />
            </div>
        </div>
    );
}
