import React, { Component } from "react";
import { Tabs, Card, Alert } from "antd";
import CustomReport from "../../../containers/WhiteLabel/CustomReport";
import CustomTemplate from "../../../containers/WhiteLabel/CustomTemplate";
import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";

const { TabPane } = Tabs;

class WhiteLabelTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeKey: this.props.activeKey
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeKey !== this.props.activeKey) {
            this.setState({ activeKey: this.props.activeKey });
        }
    }

    handleTabChange = (key) => {
        this.props.push(`/white-label/${key}`);
    };

    redirectToPricing = (e) => {
        this.props.push("/subscription/plans");
    };

    render() {
        return (
            <Card className="white-label-tabs">
                {(this.props.isLoading || this.props.isWhiteLabelAllowed === null) && <LoadingIndicator />}
                {!this.props.isLoading && this.props.isWhiteLabelAllowed === true && (
                    <Tabs activeKey={this.state.activeKey} onChange={this.handleTabChange}>
                        <TabPane tab="Generate report" key="reports">
                            <CustomReport />
                        </TabPane>

                        <TabPane tab="Template settings" key={"templates"}>
                            <CustomTemplate idUrlParam={this.props.idUrlParam} />
                        </TabPane>
                    </Tabs>
                )}
                {!this.props.isLoading && this.props.isWhiteLabelAllowed === false && (
                    <Alert
                        message="This feature is disabled for your current plan!"
                        description={
                            <>
                                In order to use this feature, you must{" "}
                                <span
                                    className="fake-link"
                                    onClick={(e) => this.redirectToPricing(e)}
                                    style={{ color: "#fe6100" }}>
                                    upgrade
                                </span>{" "}
                                your plan.
                            </>
                        }
                        type="warning"
                        showIcon
                    />
                )}
            </Card>
        );
    }
}

export default WhiteLabelTabs;
