import Cookies from "js-cookie";
import {IAM_COOKIE_DOMAIN} from "../settings/config"

const defaultIamCookieAttributes = {
    path: "/",
    domain: IAM_COOKIE_DOMAIN,
    sameSite: "strict"
  };
  

function setCookie(name, value, options = {}) {
  return Cookies.set(name, value, options);
}

function getCookie(name){
  return Cookies.get(name);
}

function getCookieJson(name){
  return Cookies.getJSON(name);
}

function removeCookie(name, options){
  Cookies.remove(name, options);
}

export { setCookie, getCookie, getCookieJson, removeCookie, defaultIamCookieAttributes };
