// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo-light.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".authentication-form-wrapper {\n  max-width: 352px;\n  margin: auto;\n  padding: 24px 0;\n}\n@media (max-width: 360px) {\n  .authentication-form-wrapper {\n    max-width: 340px;\n  }\n  .authentication-form-wrapper .ant-card-body {\n    padding: 16px;\n  }\n}\n@media (max-width: 340px) {\n  .authentication-form-wrapper {\n    max-width: 300px;\n  }\n  .authentication-form-wrapper .ant-card-body {\n    padding: 8px;\n  }\n}\n.authentication-form-wrapper a {\n  text-decoration: underline;\n}\n.authentication-form-wrapper a:hover {\n  text-decoration: none;\n}\n.authentication-form-wrapper .fake-link {\n  color: rgba(66, 71, 71, 0.54);\n  text-decoration: underline;\n  transition: all 120ms ease-in-out;\n  cursor: pointer;\n}\n.authentication-form-wrapper .fake-link:hover {\n  color: rgba(0, 0, 0, 0.54);\n  text-decoration: none;\n}\n.authentication-form-wrapper .ant-card-body {\n  padding-bottom: 24px;\n}\n.authentication-form-wrapper .ant-card-body .forgot-pass {\n  display: block;\n  text-align: center;\n  margin-top: 16px;\n}\n.auth-logo {\n  width: 174px;\n  height: 38px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center;\n  margin-bottom: 24px;\n}\n.ant-card.auth-card {\n  margin-bottom: 16px;\n  background: #fff;\n}\n.auth-card.ant-card .card-header {\n  padding: 0;\n  border: none;\n  margin-bottom: 16px;\n  background: #fff;\n}\n.auth-card.ant-card .ant-card-meta-title {\n  font-size: 24px;\n  font-weight: normal;\n  line-height: normal;\n  color: rgba(0, 0, 0, 0.72);\n}\n.auth-card .ant-form-item:last-of-type {\n  margin: 0;\n}\n.under-card-info {\n  color: rgba(0, 0, 0, 0.54);\n  font-size: 16px;\n}\n.under-card-info .fake-link {\n  color: #000;\n}\n.under-card-info .fake-link:hover {\n  color: #282d2d;\n}\n", ""]);
// Exports
module.exports = exports;
