import React from "react";
import { Button, Menu, Icon, Dropdown, Spin } from "antd";
import { logout as _logout } from "../../../iam/logout";

const UserProfileDowndrop = ({ logout, push, user, isLoading, showBillingPage, showTeamMembersPage }) => {
    const handleRedirectToMyAccount = (_) => {
        push("/user/account/profile");
    };

    const handleRedirectToTeams = (_) => {
        push("/user/team-members");
    };

    const handleRedirectToBilling = (_) => {
        push("/user/account/billing");
    };

    const handleUserLogout = (_) => {
        _logout();
    };

    const menu = (
        <Menu>
            <Menu.Item key="my-account" onClick={handleRedirectToMyAccount}>
                <Icon type="user" /> My account
            </Menu.Item>

            {showBillingPage && (
                <Menu.Item key="billing" onClick={handleRedirectToBilling}>
                    <Icon type="credit-card" /> Billing
                </Menu.Item>
            )}

            {showTeamMembersPage && (
                <Menu.Item key="team-members" onClick={handleRedirectToTeams}>
                    <Icon type="usergroup-add" /> Team Members
                </Menu.Item>
            )}

            <Menu.Divider />
            <Menu.Item key="logout" onClick={handleUserLogout}>
                <Icon type="logout" />
                <strong>Logout</strong>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <Button className="user-dropdown-button">
                <Icon type="user" style={{ fontSize: "20px" }} className="icon" />
                <div className="user-email">{user && !isLoading ? user.email : <Spin />}</div>
                <Icon type="down" className="down-icon" />
            </Button>
        </Dropdown>
    );
};

export default UserProfileDowndrop;
