import React, { useState } from "react";
import { Input, Button, Form } from "antd";

const InputGroup = Input.Group;

const EditTeamForm = ({ name, onUpdateOrganization, isUpdatingOrganization }) => {
    const [newTeamName, setNewTeamName] = useState(name);
    const [teamNameError, setTeamNameError] = useState(null);

    const handleTeamNameChange = (event) => {
        const { value } = event.target;
        setNewTeamName(value);
        teamNameError && setTeamNameError(null);
    };

    const saveTeamName = (event) => {
        event.preventDefault();

        if (newTeamName.trim().length === 0) {
            setTeamNameError("Team name is required");
            return;
        }

        onUpdateOrganization({ name: newTeamName });
    };

    return (
        <Form layout="vertical" className="analysis-form" onSubmit={saveTeamName}>
            <Form.Item
                label="Team name"
                validateStatus={teamNameError ? "error" : "validating"}
                help={teamNameError ? teamNameError : ""}
            >
                <InputGroup compact size="large">
                    <div className="compact-input-group">
                        <Input
                            placeholder="Enter team name"
                            name="name"
                            size="large"
                            value={newTeamName}
                            onChange={(event) => handleTeamNameChange(event)}
                        />

                        <Button htmlType="submit" type="primary" size="large" loading={isUpdatingOrganization}>
                            Save
                        </Button>
                    </div>
                </InputGroup>
            </Form.Item>
        </Form>
    );
};

export default EditTeamForm;
