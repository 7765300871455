import React from "react";
import { Alert } from "antd";
import { RecurlyProvider, Elements } from "@recurly/react-recurly";
import { RECURLY_PUBLIC_API_KEY } from "../../settings/config";
import PayPalForm from "./PayPalForm";

const PayPalPaymentMethod = props => {
  return (
    <React.Fragment>
      {!props.hideAlert && (
        <Alert
          className="paypal-notice"
          description={`After clicking the "${props.buttonLabel}" button, you will be taken to the PayPal website to authorize a billing agreement. Please make sure pop-ups aren't blocked!`}
          type="info"
          showIcon
        />
      )}

      <RecurlyProvider publicKey={RECURLY_PUBLIC_API_KEY}>
        <Elements>
          <PayPalForm onSubmit={props.onSubmit} buttonLabel={props.buttonLabel} />
        </Elements>
      </RecurlyProvider>
    </React.Fragment>
  );
};

export default PayPalPaymentMethod;
