import React from "react";
import { Icon, Tooltip } from "antd";

const TableColumnWithTooltip = ({ title, tooltip, noFill }) => {
  return (
    <span className="column-title">
      {title}
      <Tooltip title={tooltip}>
        <Icon type="info-circle" theme={noFill ? null : "filled"} />
      </Tooltip>
    </span>
  );
};

export default TableColumnWithTooltip;
