import React from "react";
import { v4 as uuidv4 } from "uuid";

function sendRunAnalysisEvent(url) {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  window.gtag("event", "run_analysis", {
    category: "acquisition",
    label: url
  });
}

function sendOneToolAnalysisEvent(url, toolKey) {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  window.gtag("event", "run_one_tool_analysis", {
    category: "seo_analysis",
    url: url,
    seo_test: toolKey
  });
}

function sendUserRegisterEvent(userGuid) {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  window.gtag("event", "user_registration", {
    category: "acquisition",
    label: userGuid
  });
}

function sendFreeTrialStartedEvent(planCode) {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  window.gtag("event", "free_trial_started", {
    category: "acquisition",
    label: planCode
  });
}

function sendPurchaseEvent(planId, planName, planPrice) {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  const transactionId = uuidv4();
  window.gtag("event", "purchase", {
    transaction_id: transactionId,
    currency: "USD",
    value: planPrice,
    items: [
      {
        item_id: planId,
        item_name: planName,
        price: planPrice,
        quantity: 1
      }
    ]
  });
}

function send3DSecureSuccessEvent() {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  window.gtag("event", "3d_secure_success");
}

function send3DSecureErrorEvent(userEmail, errorName, errorCode) {
  if (typeof window === "undefined" || !("gtag" in window)) {
    return;
  }

  window.gtag("event", "3d_secure_error", {
    user: userEmail,
    error_name: errorName,
    error_code: errorCode
  });
}

function GA4({ gtag_parent_id, ga4_id }) {
  return (
    <>
      <script
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag_parent_id}`}
        id="gtag-base"
        data-nscript="lazyOnload"></script>
      <script
        id="gtag-init"
        data-nscript="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag("js", new Date());
                    gtag("config", "${gtag_parent_id}"););                    
                    gtag("config", "${ga4_id}", {
                      transport_url: "https://ssgtm.ssc-app-services.com",
                      first_party_collection: true
                    });
                `
        }}
      />
    </>
  );
}

export default GA4;
export {
  sendRunAnalysisEvent,
  sendUserRegisterEvent,
  sendOneToolAnalysisEvent,
  sendFreeTrialStartedEvent,
  send3DSecureSuccessEvent,
  send3DSecureErrorEvent,
  sendPurchaseEvent
};
