import React, { useState } from "react";
import { Card, Row, Col, List, Button } from "antd";
import moment from "moment";
import SubscriptionPlansHeader from "./SubscriptionPlansHeader";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
import InvoiceHistory from "../../containers/Sales/InvoiceHistory";
import ConfirmCancellation from "./ConfirmCancellation";
import "./SubscriptionPlans.less";

const renderPlanHeader = (plan) => {
    return (
        <div className="plan-header">
            <div className="plan-name">{plan["name"]}</div>
            <div className="plan-price">
                <sup>$</sup>
                <span className="value">{getPlanPrice(plan)}</span>
                <span>/mo</span>
            </div>
            <div className="plan-discount-message">{getPlanDiscountMessage(plan)}</div>
        </div>
    );
};

const renderPlanSettings = (itemLabel, itemValue) => {
    return (
        <div className="pricing-item">
            <div className="label">{itemLabel}</div>
            {itemLabel.toLowerCase() === "white label reports" ? (
                <div className={`value ${itemValue.toLowerCase() === "included" ? "included" : "not-included"}`}>
                    {itemValue}
                </div>
            ) : itemLabel.toLowerCase() === "team members" && itemValue === "Not included" ? (
                <div className="value not-included">{itemValue}</div>
            ) : (
                <div className="value">{itemValue}</div>
            )}
        </div>
    );
};

const getPlanPrice = (plan) => {
    if (plan["billing_period"] === "yearly") {
        return (plan["price"] / 12 / 100).toFixed(2);
    }

    return (plan["price"] / 100).toFixed(2);
};

const getPlanDiscountMessage = (plan) => {
    if (plan["billing_period"] === "yearly") {
        return `billed yearly, 10% off, $${plan["price"] / 100}/year`;
    }
    return `$${((plan["price"] * 0.9) / 100).toFixed(2)}/mo billed yearly, save 10%`;
};

const packageDataSource = (plan) => {
    return [
        renderPlanSettings("SEO Analysis", plan["settings"]["seo_analysis_limit"]),
        renderPlanSettings("Monitored Websites", plan["settings"]["max_tracked_websites"]),
        renderPlanSettings("Monitored Keywords", plan["settings"]["max_keywords"]),
        renderPlanSettings("Monitoring Regions", plan["settings"]["max_monitored_regions"]),
        renderPlanSettings("Analyzed Competitors", plan["settings"]["max_competitors"]),
        renderPlanSettings(
            "Team members",
            plan["settings"]["max_team_members"] > 0 ? plan["settings"]["max_team_members"] : "Not included"
        ),
        renderPlanSettings(
            "White Label Reports",
            plan["settings"]["allow_white_label_reports"] ? "Included" : "Not included"
        )
    ];
};

export const SubscriptionPlans = (props) => {
    const [selectedIntervalUnit, setIntervalUnit] = useState(
        props.selectedPlanCode && props.selectedPlanCode.includes("yearly") ? "yearly" : "monthly"
    );
    const [updatedPlan, setUpdatedPlan] = useState(null);
    const [cancellationReasonModalVisible, setCancellationReasonModalVisible] = useState(false);

    const onSelectPlan = (event) => {
        const plan = event.target.getAttribute("data-plan-code");
        props.onSelectPlan(plan);
    };

    const toggleCancellationReasonModal = (_) => {
        setCancellationReasonModalVisible(!cancellationReasonModalVisible);
    };

    const onPlanClick = (event) => {
        const plan = event.target.getAttribute("data-plan-code");
        const action = event.target.getAttribute("data-plan-action");

        if (action === "cancel") {
            toggleCancellationReasonModal();
        }

        if (action === "update") {
            setUpdatedPlan(plan);
            props.onUpdateSubscription(plan);
        }

        if (action === "checkout") {
            props.push(`/subscription/checkout/${plan}`);
        }
    };

    const onChangePlanIntervalUnit = (event) => {
        const intervalUnit = event.target.getAttribute("data-interval-unit");

        props.onChangeIntervalUnit && props.onChangeIntervalUnit(intervalUnit);
        setIntervalUnit(intervalUnit);
    };

    const dispatchAction = (planCode, subscription) => {
        if (subscription && planCode === subscription["plan_code"] && subscription["status"] === "active") {
            return "cancel";
        }
        if (subscription && planCode !== subscription["plan_code"] && subscription["status"] === "active") {
            return "update";
        }

        return "checkout";
    };

    const dispatchButtonLabel = (planCode, subscription) => {
        if (subscription && planCode === subscription["plan_code"] && subscription["status"] === "active") {
            return "Cancel subscription";
        }
        if (subscription && planCode !== subscription["plan_code"] && subscription["status"] === "active") {
            return "Update subscription";
        }

        return "Checkout";
    };

    const cancelSubscription = (reason, comment) => {
        props.onCancelSubscription(reason, comment).then((_) => {
            setCancellationReasonModalVisible(false);
        });
    };

    return props.plans && props.plans[selectedIntervalUnit] ? (
        <React.Fragment>
            <ConfirmCancellation
                title="Confirm subscription cancellation"
                visible={cancellationReasonModalVisible}
                showInfo={true}
                onClose={(_) => toggleCancellationReasonModal()}
                onConfirmCancellation={(reason, comment) => cancelSubscription(reason, comment)}
                loading={props.isCancelingSubscription}
            />
            <Row gutter={16} className="subscription-plans">
                <Col span={props.freeTrial ? 24 : 18}>
                    <div className="plans-container">
                        <Card
                            title={props.freeTrial ? "" : "Select your plan"}
                            className="plans-card"
                            bordered={props.freeTrial ? false : true}>
                            <Row className="plan-switch-container">
                                <Col span={8} offset={8}>
                                    <Button.Group className="plan-switch-group" size="large">
                                        <Button
                                            className={`plan-switch ${
                                                selectedIntervalUnit === "monthly" ? "selected" : ""
                                            }`}
                                            data-interval-unit="monthly"
                                            onClick={(e) => onChangePlanIntervalUnit(e)}>
                                            Monthly
                                        </Button>
                                        <Button
                                            className={`plan-switch ${
                                                selectedIntervalUnit === "yearly" ? "selected" : ""
                                            }`}
                                            data-interval-unit="yearly"
                                            onClick={(e) => onChangePlanIntervalUnit(e)}>
                                            Yearly
                                        </Button>
                                    </Button.Group>
                                </Col>
                            </Row>

                            <Row className="packages flexbox custom-scrollbar" gutter={16}>
                                {props.plans &&
                                    props.plans[selectedIntervalUnit].map((plan, planIdx) => {
                                        if (!plan["settings"]["legacy"]) {
                                            return (
                                                <Col className="gutter-row plan-items" span={8} key={planIdx}>
                                                    <List
                                                        className="plan-item-list"
                                                        size="large"
                                                        header={renderPlanHeader(plan)}
                                                        footer={
                                                            props.freeTrial ? (
                                                                <Button
                                                                    className={
                                                                        plan["code"] === props.selectedPlanCode
                                                                            ? "disabled"
                                                                            : ""
                                                                    }
                                                                    type="primary"
                                                                    block
                                                                    onClick={(e) => onSelectPlan(e)}
                                                                    data-plan-code={plan["code"]}>
                                                                    {plan["code"] === props.selectedPlanCode
                                                                        ? "Selected plan"
                                                                        : "Free trial"}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className={
                                                                        dispatchAction(
                                                                            plan["code"],
                                                                            props.subscription
                                                                        ) === "cancel"
                                                                            ? "cancel"
                                                                            : ""
                                                                    }
                                                                    type="primary"
                                                                    block
                                                                    onClick={(e) => onPlanClick(e)}
                                                                    loading={
                                                                        props.isUpdatingSubscription &&
                                                                        plan["code"] === updatedPlan
                                                                    }
                                                                    data-plan-code={plan["code"]}
                                                                    data-plan-action={dispatchAction(
                                                                        plan["code"],
                                                                        props.subscription
                                                                    )}>
                                                                    {dispatchButtonLabel(
                                                                        plan["code"],
                                                                        props.subscription
                                                                    )}
                                                                </Button>
                                                            )
                                                        }
                                                        bordered={false}
                                                        dataSource={packageDataSource(plan)}
                                                        renderItem={(item) => <List.Item>{item}</List.Item>}
                                                    />
                                                </Col>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                            </Row>
                        </Card>
                    </div>
                </Col>
                {!props.freeTrial ? (
                    <Col span={6}>
                        <div className="pricing-meta">
                            {props.includeHeader && props.subscription && (
                                <SubscriptionPlansHeader
                                    isCancelingSubscription={props.isCancelingSubscription}
                                    onCancelSubscription={props.onCancelSubscription}
                                    subscriptionStatus={props.subscription["status"]}
                                    planName={`${props.subscription["plan_settings"]["name"]} Plan`}
                                    planPrice={`${props.subscription["plan_settings"]["price"] / 100}`}
                                    planIntervalUnit={props.subscription["plan_settings"]["interval_unit"]}
                                    planIntervalLength={props.subscription["plan_settings"]["interval_length"]}
                                    currentPeriodEnd={moment(
                                        props.subscription["current_period_ends_at"],
                                        "YYYY-MM-DDTHH:mm:ss"
                                    ).format("MMM DD, YYYY")}
                                    nextPaymentDate={moment(
                                        props.subscription["current_period_ends_at"],
                                        "YYYY-MM-DDTHH:mm:ss"
                                    ).format("MMM DD, YYYY")}
                                />
                            )}

                            <div className="invoice-history">
                                <InvoiceHistory />
                            </div>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </React.Fragment>
    ) : (
        <LoadingIndicator />
    );
};
