import { useDispatch } from 'react-redux';
import { useGenerateSitemapMutation } from '../service';
import { createLinkAndTriggerDownload } from '../utils';
import { addNotificationWithTimeout } from '../../store/notifications/operations';

export default function useDownloadSitemap() {
    const dispatch = useDispatch();
    const [generateSitemap, { isLoading }] = useGenerateSitemapMutation();

    const downloadSitemap = (domain: string) => {
        return generateSitemap({ domain })
            .unwrap()
            .then((response) => {
                createLinkAndTriggerDownload(response.sitemaps[0]);
            })
            .catch(() => {
                dispatch(addNotificationWithTimeout('error', `Something went wrong when generating sitemap.xml for ${domain}`));
            });
    };

    return {
        downloadSitemap,
        isDownloadingSitemap: isLoading,
    };
}
