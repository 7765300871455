import React, { useState } from 'react';
import { Card, Select } from 'antd';
import { TrackedWebsite } from '../trackedWebsites/types';
import { defaultOrderBy, defaultOrderDir } from './settings';
import ReportCardTitle from '../components/shared/ReportCardTitle/ReportCardTitle';
import TopKeywordsOverview from './TopKeywordsOverview';
import TopKeywordsResults from './TopKeywordsResults';
import ChangeLocationAndLanguage from '../trackedKeywords/ChangeLocationAndLanguage';

const { Option } = Select;

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newTrackedWebsite: TrackedWebsite) => void;
};

export default function TopKeywords({ selectedWebsite, setSelectedWebsite }: Props) {
    const [state, setState] = useState({ orderBy: defaultOrderBy, orderDir: defaultOrderDir });

    const handleOrderChange = (newOrder: string) => {
        const [newOrderBy, newOrderDir] = newOrder.split('_');
        setState({ orderBy: newOrderBy, orderDir: newOrderDir });
    };

    return (
        <>
            <TopKeywordsOverview
                key={`${selectedWebsite.guid}_${selectedWebsite.settings.location_name}_${selectedWebsite.settings.language_code}`}
                selectedWebsite={selectedWebsite}
            />

            <Card
                className="keywords-data-overview"
                title={
                    <ReportCardTitle
                        title="Top Keywords"
                        domain={selectedWebsite.domain}
                    />
                }
                extra={
                    <div className="keywords-options card-actions">
                        <div className="sort-by">
                            <Select
                                defaultValue={`${state.orderBy}_${state.orderDir}`}
                                onChange={handleOrderChange}
                                value={`${state.orderBy}_${state.orderDir}`}
                            >
                                <Option value={'traffic_desc'}>High traffic</Option>
                                <Option value={'position_asc'}>High position</Option>
                                <Option value={'traffic_asc'}>Low traffic</Option>
                                <Option value={'position_desc'}>Low position</Option>
                            </Select>
                        </div>
                        <ChangeLocationAndLanguage
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    </div>
                }
            >
                <TopKeywordsResults
                    key={`${selectedWebsite.guid}_${selectedWebsite.settings.location_name}_${selectedWebsite.settings.language_code}_${state.orderBy}_${state.orderDir}`}
                    selectedWebsite={selectedWebsite}
                    orderBy={state.orderBy}
                    orderDir={state.orderDir}
                />
            </Card>
        </>
    );
}
