import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import BasicSectionResult from "../BasicSectionResult/BasicSectionResult";
import SectionTooltip from "../SectionTooltip/SectionTooltip";

const BasicSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <BasicSectionResult
        url={url}
        title={title}
        result={result}
        relevance={relevance}
        competitorResults={competitorResults}
      />

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default BasicSection;
