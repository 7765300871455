import { LoadingTimeReport, SiteSpeedChartData } from './types';

export function getSiteSpeedChartData(siteSpeedData: LoadingTimeReport[]) {
    let data: SiteSpeedChartData[] = [];
    siteSpeedData.forEach((report) => {
        data = [
            ...data,
            {
                type: 'loading time',
                c_at: report.c_at,
                value: report.loading_time,
                min: report.min_loading_time,
                max: report.max_loading_time,
            },
            { type: 'page size', c_at: report.c_at, value: report.page_size },
            { type: 'loading speed', c_at: report.c_at, value: report.loading_speed },
        ];
    });

    return data;
}
