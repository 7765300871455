import React from 'react'
import { Layout } from "antd";

const { Content } = Layout;

const ContactPage=()=>{
    return (
        <Content className="general-layout-content">
            <h1>Contact page</h1>
        </Content>
    )
}

export default ContactPage;
