import React, { Component } from "react";
import { connect } from "react-redux";
import PaymentLayout from "../../components/Sales/PaymentLayout/PaymentLayout";
import { createSubscription, fetchSubscriptionPlans } from "../../store/sales/operations";
import {
  isCreatingSubscription,
  threeDSecure,
  getSubscriptionPlans,
  getSubscriptionError,
  getPlan
} from "../../store/sales/selectors";

//TODO: SubscriptionCheckout container looks very similar with FreeTrialSetup
class SubscriptionCheckout extends Component {
  componentDidMount() {
    this.props.fetchSubscriptionPlans();
  }

  render() {
    return (
      <PaymentLayout
        title="Review & Confirm your Plan"
        className="checkout"
        buttonLabel="Place order"
        onSubmit={this.props.createSubscription}
        isSubmitting={this.props.isCreatingSubscription}
        errorMessage={this.props.subscriptionError}
        threeDSecure={this.props.threeDSecure}
        showSelectedPlan={true}
        defaultPlan={this.props.defaultPlan}
        plans={this.props.plans}
        allowChangePlan={false}
        includeRefundDetails={false}
        freeTrial={false}
        redirectTo={"/subscription/plans"}
      />
    );
  }
}

const mapStateToProps = state => ({
  isCreatingSubscription: isCreatingSubscription(state),
  threeDSecure: threeDSecure(state),
  defaultPlan: getPlan(state, window.location.pathname.substring("/subscription/checkout/".length)),
  plans: getSubscriptionPlans(state),
  subscriptionError: getSubscriptionError(state)
});

export default connect(mapStateToProps, { createSubscription, fetchSubscriptionPlans })(SubscriptionCheckout);
