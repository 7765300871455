import React, { Component } from "react";
import { connect } from "react-redux";
import PaymentLayout from "../../components/Sales/PaymentLayout";
import { fetchSubscriptionPlans, createSubscription, set3DSecureError } from "../../store/sales/operations";
import { getCookie } from "../../utils/cookie";
import {
  isCreatingSubscription,
  threeDSecure,
  getDefaultPlan,
  getPlan,
  getSubscriptionPlans,
  getSubscriptionError
} from "../../store/sales/selectors";

class FreeTrialSetup extends Component {
  componentDidMount() {
    this.props.fetchSubscriptionPlans();
  }
  render() {
    return (
      <PaymentLayout
        title="7-Day Full Service Free Trial"
        className="free-trial-setup"
        buttonLabel="Start free trial"
        onSubmit={this.props.createSubscription}
        on3DSecureError={this.props.set3DSecureError}
        isSubmitting={this.props.isCreatingSubscription}
        errorMessage={this.props.errorMessage}
        threeDSecure={this.props.threeDSecure}
        showSelectedPlan={true}
        defaultPlan={this.props.defaultPlan}
        plans={this.props.plans}
        allowChangePlan={true}
        includeRefundDetails={true}
        freeTrial={true}
        redirectTo={"/user/account/setup"}
      />
    );
  }
}

const mapStateToProps = state => ({
  isCreatingSubscription: isCreatingSubscription(state),
  threeDSecure: threeDSecure(state),
  defaultPlan: getCookie("selectedPlan") ? getPlan(state, getCookie("selectedPlan")) : getDefaultPlan(state),
  plans: getSubscriptionPlans(state),
  errorMessage: getSubscriptionError(state)
});

export default connect(mapStateToProps, {
  fetchSubscriptionPlans,
  createSubscription,
  set3DSecureError
})(FreeTrialSetup);
