import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";

const displayResult = (title, result) => {
  if (["warning", "failed"].includes(result.status)) {
    return (
      <React.Fragment>
        <div className="description-meta">
          <InlineModal openLabel="See results list" modalTitle="Full list of oversized images" closeLabel="Close">
            <div className="image-table-container">
              <div className="image-table">
                <div className="image-table-row">
                  <div className="image-table-cell image">Image </div>
                  <div className="image-table-cell url">Url </div>
                  <div className="image-table-cell rendered-size">Displayed size </div>
                  <div className="image-table-cell natural-size">Natural size</div>
                  <div className="image-table-cell file-size">File size</div>
                  <div className="image-table-cell potential-savings">Potential savings</div>
                </div>

                {result.images.map((image, idx) => {
                  const url = "..." + image.src.slice(-20);
                  return (
                    <div className="image-table-row" key={idx}>
                      <div className="image-table-cell image">
                        <img src={image.src} alt="" />
                      </div>
                      <div className="image-table-cell url">
                        <a href={image.src} target="_blank" rel="noopener noreferrer">
                          {url}
                        </a>
                      </div>
                      <div className="image-table-cell rendered-size">
                        {image.displayed_width}x{image.displayed_height}
                      </div>
                      <div className="image-table-cell natural-size">
                        {image.natural_width}x{image.natural_height}
                      </div>
                      <div className="image-table-cell file-size">{(image.size / 1024).toFixed(1)} Kb</div>
                      <div className="image-table-cell potential-savings">
                        {(image.potential_savings / 1024).toFixed(1)} Kb
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </InlineModal>
        </div>

        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </React.Fragment>
    );
  }
  return null;
};

const ImageSizeSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default ImageSizeSection;
