import React from 'react';
import { HoursInterval } from '../types';

import HoursTimelineTicks from './HoursTimelineTicks';
import { getDurationInMinutes } from '../utils';

type Props = {
    outageIntervals: HoursInterval[];
    selectedInterval: HoursInterval;
    onChangeSelectedInterval: (newSelectedInterval: HoursInterval) => void;
};

export default function HoursTimeline({ outageIntervals, selectedInterval, onChangeSelectedInterval }: Props) {
    const handleChangeOutage = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const selectedIndex = parseInt(event.currentTarget.getAttribute('data-idx')!);
        onChangeSelectedInterval(outageIntervals[selectedIndex]);
    };

    return (
        <div className="outage-timeline hours-interval custom-scrollbar">
            <ul className="outage-interval-container">
                {outageIntervals.map((interval, idx) => {
                    return (
                        <li
                            className={`outage-interval 
                                    ${Object.keys(interval).includes('guid') ? 'danger' : 'success'} 
                                    ${Object.keys(interval).includes('in_future') ? 'future' : ''}
                                    ${interval.start_at.localeCompare(selectedInterval.start_at) === 0 ? 'active' : ''}`}
                            key={`interval-${idx}`}
                            data-idx={idx}
                            style={{ flexGrow: getDurationInMinutes(interval.fake_start_at ? interval.fake_start_at : interval.start_at, interval.end_at) }}
                            onClick={handleChangeOutage}
                        ></li>
                    );
                })}
            </ul>

            <HoursTimelineTicks />
        </div>
    );
}
