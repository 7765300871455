import { ResearchSections } from './types';

export const defaultResearchLocation = 'United States';

export const defaultResearchLanguageCode = 'en';

export const defaultResearchOrderBy = 'vol';

export const defaultResearchOrderDir = 'desc';

export const defaultSelectedResearchTab = 'broadMatch';

export const researchTabsDisplayOrder = ['broadMatch', 'related'];

export const researchSections: ResearchSections = {
    broadMatch: {
        researchType: 'broad_match',
        title: 'Broad Match Keywords',
    },

    related: {
        researchType: 'related',
        title: 'Related Keywords',
    },
};
