import React, { Component } from "react";
import { Input, Collapse, Form } from "antd";
import URLSchemaSelector from "../shared/URLSchemaSelector/URLSchemaSelector";

const { Panel } = Collapse;

class Competitors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      competitors: Array.from(Array(this.props.maxCompetitors)).map((_, idx) => ({
        id: `competitor${idx}`,
        url: "",
        urlSchema: "https://",
      })),
    };
  }

  onChangeUrlSchema = (urlSchema, urlId) => {
    let newCompetitors = [...this.state.competitors];
    newCompetitors = newCompetitors.reduce((competitors, competitor) => {
      if (competitor.id === urlId) {
        competitor.urlSchema = urlSchema;
        competitors.push(competitor);
      } else {
        competitors.push(competitor);
      }
      return competitors;
    }, []);

    this.setState({ competitors: newCompetitors });
    this.props.sendCompetitors(newCompetitors);
  };

  handleInputOnChange = (e) => {
    let newCompetitors = [...this.state.competitors];
    newCompetitors = newCompetitors.reduce((competitors, competitor) => {
      if (competitor.id === e.target.id) {
        competitor.url = e.target.value;
        competitors.push(competitor);
      } else {
        competitors.push(competitor);
      }
      return competitors;
    }, []);

    this.setState({ competitors: newCompetitors });
    this.props.sendCompetitors(newCompetitors);
  };

  getValidationMessage = (idx) => {
    let competitor = this.state.competitors.find((competitor) => competitor.id === `competitor${idx}`);
    let validation = this.props.validationErrors.find(
      (validation) => validation.url === `${competitor["urlSchema"]}${competitor["url"]}`
    );

    if (validation && "msg" in validation) {
      return validation.msg;
    }

    return "";
  };

  render() {
    return (
      <Collapse>
        <Panel header="Add competitors (optional)" key="1">
          {[...Array(this.props.maxCompetitors)].map((x, i) => (
            <div key={i} className="competitor-item">
              <span>{`Competitor ${i + 1}`}</span>
              <Form.Item
                validateStatus={this.getValidationMessage(i) !== "" ? "error" : "validating"}
                help={this.getValidationMessage(i)}>
                <Input
                  placeholder="Competitor URL"
                  id={`competitor${i}`}
                  addonBefore={<URLSchemaSelector urlId={`competitor${i}`} onChange={this.onChangeUrlSchema} />}
                  onChange={this.handleInputOnChange}
                />
              </Form.Item>
            </div>
          ))}
        </Panel>
      </Collapse>
    );
  }
}

export default Competitors;
