import React, { Component } from "react";
import { connect } from "react-redux";
import PasswordConfirmationModal from "../../components/UserAccount/PasswordConfirmationModal";
import { getUserEmail, isConfirmingPassword } from "../../store/iam/selectors";
import { confirmPassword } from "../../store/iam/operations";

export class PasswordConfirmation extends Component {
  handleSubmit = newPassword => {
    this.props.confirmPassword(this.props.email, newPassword, this.props.onPasswordConfirmed);
  };

  render() {
    return (
      <PasswordConfirmationModal
        isLoading={this.props.isLoading}
        buttonLabel={this.props.buttonLabel}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => ({
  email: getUserEmail(state),
  isLoading: isConfirmingPassword(state)
});
export default connect(mapStateToProps, { confirmPassword })(PasswordConfirmation);
