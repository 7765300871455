import { push } from "connected-react-router";

export function navigate(to) {
  return function(dispatch) {
    if (to === "/") {
      dispatch(push("/"));
    } else {
      dispatch(push(`/${to}`));
    }
  };
}
