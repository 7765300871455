import React, { Component } from "react";
import { Modal, Button } from "antd";

class BasicModal extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPerformingAction === false && prevProps.isPerformingAction === true) {
      this.setState(_ => ({ visible: false }));
    }
  }

  toggleModal(event) {
    event.currentTarget.blur();
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }
  render() {
    return (
      <React.Fragment>
        <Button disabled={this.props.isButtonDisabled} onClick={event => this.toggleModal(event)} style={{ marginLeft: 10 }} className="light-text">
          {this.props.openLabel}
        </Button>
        <Modal
          className="basic-modal"
          title={this.props.modalTitle}
          visible={this.state.visible}
          onCancel={this.toggleModal}
          footer={null}
          destroyOnClose
        >
          {this.props.children}
        </Modal>
      </React.Fragment>
    );
  }
}

export default BasicModal;
