import React, { useState } from 'react';
import { Select, Form, Button, Tooltip, Icon, TreeSelect } from 'antd';
import { MonitorRegions, TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';

const { Option } = Select;

type Props = {
    selectedWebsite: TrackedWebsite;
    monitorRegions: MonitorRegions;
    monitorRegionsOrder: string[];
    maxRegions: number;
    isLoading: boolean;
    onUpdateLocations: (args: UpdateTrackedWebsitesParams) => void;
};

export default function LocationsInput({ selectedWebsite, monitorRegions, monitorRegionsOrder, maxRegions, isLoading, onUpdateLocations }: Props) {
    const [state, setState] = useState({
        selectedMainRegion: selectedWebsite.settings.regions[0],
        selectedAdditionalRegions: selectedWebsite.settings.regions.slice(1),
    });

    const getAdditionalMonitorRegions = () => {
        const additionalRegionsOrder = monitorRegionsOrder.filter((region) => region !== state.selectedMainRegion);
        return additionalRegionsOrder.map((regionCode) => ({
            title: monitorRegions[regionCode],
            value: regionCode,
            key: regionCode,
            selectable: false,
            disabled: state.selectedAdditionalRegions.length === maxRegions - 1 && !state.selectedAdditionalRegions.includes(regionCode),
            isLeaf: true,
        }));
    };

    const changeMainRegion = (newMainRegion: string) => {
        setState({ selectedMainRegion: newMainRegion, selectedAdditionalRegions: [] });
    };

    const changeAdditionalRegions = (newAdditionalRegions: string[]) => {
        setState({ ...state, selectedAdditionalRegions: newAdditionalRegions });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const updatedRegions = [state.selectedMainRegion, ...state.selectedAdditionalRegions];

        onUpdateLocations({
            guid: selectedWebsite.guid,
            url: selectedWebsite.url,
            regions: updatedRegions,
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item
                colon={false}
                className="normal-line-height"
                label={
                    <React.Fragment>
                        <p>
                            <strong>Primary monitor location &nbsp;</strong>
                            <Tooltip
                                placement="right"
                                title={"Please select the primary location from where we'll monitor your site's loading speed."}
                            >
                                <Icon type="question-circle" />
                            </Tooltip>
                        </p>
                    </React.Fragment>
                }
            >
                <Select
                    value={state.selectedMainRegion}
                    onChange={changeMainRegion}
                >
                    {monitorRegionsOrder.map((regionCode) => (
                        <Option
                            value={regionCode}
                            key={regionCode}
                        >
                            {monitorRegions[regionCode]}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {maxRegions > 1 ? (
                <Form.Item
                    className="normal-line-height"
                    colon={false}
                    label={
                        <p>
                            <strong>Additional monitor locations &nbsp;</strong>
                            <Tooltip
                                placement="right"
                                title={`Please select ${maxRegions - 1} additional regions from where we'll monitor your site's loading speed`}
                            >
                                <Icon type="question-circle" />
                            </Tooltip>
                        </p>
                    }
                >
                    <TreeSelect
                        allowClear
                        treeData={getAdditionalMonitorRegions()}
                        value={state.selectedAdditionalRegions}
                        onChange={changeAdditionalRegions}
                        treeCheckable
                        multiple
                        treeDefaultExpandAll
                        searchPlaceholder="Select additional regions"
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        maxTagCount={maxRegions - 1}
                        filterTreeNode={true as any}
                        treeNodeFilterProp="title"
                    />
                </Form.Item>
            ) : null}

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >
                    Update
                </Button>
            </Form.Item>
        </Form>
    );
}
