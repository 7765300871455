import React, { useContext } from 'react';
import { Layout } from 'antd';
import TrackedWebsites from '../../trackedWebsites/TrackedWebsites';
import { SelectedWebsiteContext } from '../../trackedWebsites/ContextWrapper';
import KeywordPositionTracker from '../KeywordPositionTracker';

const { Content } = Layout;

export default function KeywordPositionPage() {
    const { selectedWebsite, setSelectedWebsite } = useContext(SelectedWebsiteContext);

    return (
        <Content className="general-layout-content">
            <TrackedWebsites
                tabContent={
                    selectedWebsite ? (
                        <KeywordPositionTracker
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    ) : (
                        <></>
                    )
                }
                showDomains={true}
            />
        </Content>
    );
}
