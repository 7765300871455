import React from 'react';
import { Button } from 'antd';

type Props = {
	onStartOnboarding: () => void;
};

export default function OnboardingStartScreen({ onStartOnboarding }: Props) {
	return (
		<div className="onboarding-start-screen">
			<img
				src="/images/illustration-robot-hand.svg"
				alt="onboarding start screen"
			/>
			<h2>Welcome on board!</h2>
			<h2>Let's finish your ToolBox setup.</h2>
			<span className="description">Complete 4 simple steps, usually takes about 2 minutes</span>
			<Button
				className="start-onboarding-btn"
				type="primary"
				onClick={onStartOnboarding}
			>
				Let's Start
			</Button>
		</div>
	);
}
