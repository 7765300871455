import './TopKeywordsOverview.less';
import React from 'react';
import { Col, Row, Card, Icon, Statistic } from 'antd';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import { TrackedWebsite } from '../trackedWebsites/types';
import { useFetchTopKeywordsOverviewQuery } from './service';

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function TopKeywordsOverview({ selectedWebsite }: Props) {
    const { data: topKeywordsOverview, isFetching: isLoadingOverview } = useFetchTopKeywordsOverviewQuery({
        domain: selectedWebsite.domain,
        locationName: selectedWebsite.settings.location_name,
        languageCode: selectedWebsite.settings.language_code,
    });

    const isTopKeywordsOverviewExistent = !isLoadingOverview && topKeywordsOverview;

    return (
        <>
            {isLoadingOverview && <LoadingIndicator />}

            {isTopKeywordsOverviewExistent && (
                <div className="top-keywords-overview split-grid-overview quarters">
                    <Row gutter={20}>
                        <Col
                            className="top-keywords-overview-box"
                            span={6}
                        >
                            <Card>
                                <Statistic
                                    title="Average position"
                                    value={topKeywordsOverview.avg_pos}
                                    precision={2}
                                    prefix={<Icon type="vertical-align-middle" />}
                                />
                            </Card>
                        </Col>
                        <Col
                            className="top-keywords-overview-box"
                            span={6}
                        >
                            <Card>
                                <Statistic
                                    title="Increased"
                                    value={topKeywordsOverview.increased}
                                    precision={0}
                                    valueStyle={{ color: '#3f8600' }}
                                    prefix={<Icon type="arrow-up" />}
                                />
                            </Card>
                        </Col>
                        <Col
                            className="top-keywords-overview-box"
                            span={6}
                        >
                            <Card>
                                <Statistic
                                    title="Unchanged"
                                    value={topKeywordsOverview.unchanged}
                                    precision={0}
                                    prefix={<Icon type="swap" />}
                                />
                            </Card>
                        </Col>
                        <Col
                            className="top-keywords-overview-box"
                            span={6}
                        >
                            <Card>
                                <Statistic
                                    title="Dropped"
                                    value={topKeywordsOverview.dropped}
                                    precision={0}
                                    valueStyle={{ color: '#cf1322' }}
                                    prefix={<Icon type="arrow-down" />}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
}
