import React, { useContext } from 'react';
import { Layout } from 'antd';
import TrackedWebsites from '../../trackedWebsites/TrackedWebsites';
import KeywordResearchForm from '../KeywordResearchForm';
import KeywordResearch from '../KeywordResearch';
import { SelectedWebsiteContext } from '../../trackedWebsites/ContextWrapper';

const { Content } = Layout;

export default function KeywordResearchPage() {
    const { selectedWebsite } = useContext(SelectedWebsiteContext);

    return (
        <Content className="general-layout-content">
            <KeywordResearchForm />
            <TrackedWebsites
                tabContent={selectedWebsite ? <KeywordResearch selectedWebsite={selectedWebsite} /> : <></>}
                showDomains
            />
        </Content>
    );
}
