import React, { useState } from "react";
import { Modal, Radio, Input, Button, Alert } from "antd";
import { useSelector } from "react-redux";
import "./ConfirmCancellation.less";

const { TextArea } = Input;

export const shuffleArray = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};

const reasons = [
  "It was too costly",
  "Needed more features",
  "It was too complicated to use",
  "I am using another search engine analyzer",
  "Customer support was low",
  "There were technical issues",
  "It is not serving my main purpose"
];

const ConfirmCancellation = (props) => {
  const [cancellationReason, setReason] = useState("");
  const [reasonsList, setReasonsList] = useState([...shuffleArray(reasons), "I have another reason"]);
  const [comment, setComment] = useState("");
  const [cancellationAlert, setCancellationAlert] = useState(null);

  let isInFreeTrial = false;
  const trialEndsAt = useSelector((state) => state?.sales?.subscription?.trial_ends_at);
  if (trialEndsAt) {
    const now = new Date();
    const trialEndsAtDate = new Date(trialEndsAt);
    isInFreeTrial = trialEndsAtDate > now;
  }

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px"
  };

  const onReasonChange = (e) => {
    setCancellationAlert(null);
    setReason(e.target.value);
  };

  const onCommentChange = (e) => {
    setCancellationAlert(null);
    setComment(e.target.value);
  };

  const cancelSubscription = (_) => {
    const cancellationComment = comment.trim();
    if (cancellationReason && cancellationComment) {
      props.onConfirmCancellation(cancellationReason, cancellationComment);
    } else {
      const notification = {
        message: "Please describe why are you cancelling your subscription!",
        type: "error"
      };
      setCancellationAlert(notification);
    }
  };

  const onClose = () => {
    props.onClose();
    setReason("");
    setComment("");
    setCancellationAlert(null);
    setReasonsList([...shuffleArray(reasons), "I have another reason"]);
  };

  return (
    <Modal
      width={756}
      title={props.title}
      visible={props.visible}
      onCancel={(_) => onClose()}
      footer={[
        <Button key="return" onClick={(_) => onClose()}>
          Not sure about this
        </Button>,
        <Button
          key="cancel-subscription"
          type="primary"
          loading={props.loading}
          disabled={!cancellationReason || !comment}
          onClick={(_) => cancelSubscription()}>
          Confirm
        </Button>
      ]}>
      <div className="cancellation-reasons">
        {cancellationAlert && <Alert message={cancellationAlert.message} type={cancellationAlert.type} />}
        {props.showInfo && (
          <div>
            {/* <h1>Hope to see you back!</h1> */}
            {isInFreeTrial && (
              <p>
                Need more time for your free trial? Please contact us for an extension:{" "}
                <a style={{ display: "inline" }} href="mailto:information@seositecheckup.com">
                  information@seositecheckup.com
                </a>
              </p>
            )}
            <p>
              We care about each step of our collaboration, that's why we have made the unsubscribe process also easy
              and secure. Despite your leaving, don't forget your account is still available until the subscription
              period ends without any extra charge of your card. You can still enjoy SEO Site Checkup features till
              then.
            </p>
          </div>
        )}
        <h2>Why are you leaving?</h2>
        <Radio.Group onChange={onReasonChange} value={cancellationReason}>
          {reasonsList.map((reason, idx) => {
            return (
              <Radio key={idx} style={radioStyle} value={reason}>
                {reason}
              </Radio>
            );
          })}
        </Radio.Group>
        <div className="textarea-label">Comment: </div>
        <TextArea name="comment" onChange={onCommentChange} value={comment} rows={4} />
      </div>
    </Modal>
  );
};

export default ConfirmCancellation;
