import React, { useEffect } from 'react';
import { Table, Icon, Alert } from 'antd';
import { TrackedWebsite } from '../trackedWebsites/types';
import { TrackedKeywordsTableRecord } from './types';
import { useDeleteTrackedKeywordMutation, useFetchTrackedKeywordsQuery } from './service';
import { getTableDataFromKeywords } from './utils';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import TableColumnWithTooltip from '../components/shared/TableColumnWithTooltip/TableColumnWithTooltip';
import KeywordPosition from '../components/shared/KeywordPosition';
import { semTableColumns } from '../components/shared/TableColumnWithTooltip/semTableColumns';
import TopDomainsModal from './TopDomainsModal';
import TrendChartModal from './TrendChartModal';
import UrlTableCell from './UrlTableCell';

const { Column } = Table;

const TRACKED_KEYWORDS_POLLING_TIME_MS = 10000;

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function TrackedKeywordsTable({ selectedWebsite }: Props) {
    const {
        data: trackedKeywords,
        isLoading: isLoadingTrackedKeywords,
        //isFetching: isReloadingTrackedKeywords,
        refetch,
    } = useFetchTrackedKeywordsQuery({ domain: selectedWebsite.domain });
    const [deleteKeyword, { isLoading, originalArgs }] = useDeleteTrackedKeywordMutation();

    const handleDelete = (keyword: string) => {
        deleteKeyword({ keyword: keyword, domain: selectedWebsite.domain });
    };

    // Polling if the keywords are not ready yet
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (trackedKeywords && trackedKeywords.tracked_keywords.some((keyword) => 'status' in keyword)) {
            timeoutId = setInterval(() => {
                refetch();
            }, TRACKED_KEYWORDS_POLLING_TIME_MS);
        }
        return () => {
            clearInterval(timeoutId);
        };
    }, [refetch, trackedKeywords]);

    const isTrackedKeywordsEmpty = !isLoadingTrackedKeywords && trackedKeywords && trackedKeywords.tracked_keywords.length === 0;
    const isTrackedKeywordsExistent = !isLoadingTrackedKeywords && trackedKeywords && trackedKeywords.tracked_keywords.length > 0;

    return (
        <>
            {isLoadingTrackedKeywords && <LoadingIndicator />}

            {isTrackedKeywordsEmpty && <Alert message="You haven't set any keywords to track for this domain!" />}

            {isTrackedKeywordsExistent && (
                <Table
                    //loading={isReloadingTrackedKeywords}
                    dataSource={getTableDataFromKeywords(trackedKeywords.tracked_keywords)}
                    pagination={false}
                    size="small"
                >
                    <Column
                        title="#"
                        dataIndex="key"
                    />
                    <Column
                        className="no-wrap"
                        title="Keyword"
                        dataIndex="keyword"
                    />
                    <Column
                        title={
                            <TableColumnWithTooltip
                                noFill={false}
                                {...semTableColumns['position']}
                            />
                        }
                        dataIndex="position"
                        render={(text, record: TrackedKeywordsTableRecord, index) => (
                            <KeywordPosition
                                status={record.status}
                                position={record.position}
                                previousPosition={record.previousPosition}
                            />
                        )}
                    />
                    <Column
                        className="al-center"
                        title="Trend"
                        render={(text, record: TrackedKeywordsTableRecord, index) => (
                            <TrendChartModal
                                keyword={record.keyword}
                                position={record.position}
                                domain={record.domain}
                                location={record.location}
                                languageCode={record.languageCode}
                            />
                        )}
                    />
                    <Column
                        className="al-center"
                        title={
                            <TableColumnWithTooltip
                                noFill={false}
                                {...semTableColumns['top_domains']}
                            />
                        }
                        render={(text, record: TrackedKeywordsTableRecord, index) => (
                            <TopDomainsModal
                                keyword={record.keyword}
                                position={record.position}
                                topDomainsCount={record.topDomainsCount}
                                location={record.location}
                                languageCode={record.languageCode}
                            />
                        )}
                    />
                    <Column
                        className="al-right"
                        title={
                            <TableColumnWithTooltip
                                noFill={false}
                                {...semTableColumns['search_volume']}
                            />
                        }
                        dataIndex="searchVolume"
                    />
                    <Column
                        className="al-right"
                        title={
                            <TableColumnWithTooltip
                                noFill={false}
                                {...semTableColumns['cpc']}
                            />
                        }
                        dataIndex="cpc"
                    />
                    <Column
                        className="al-right"
                        title={
                            <TableColumnWithTooltip
                                noFill={false}
                                {...semTableColumns['results_no']}
                            />
                        }
                        dataIndex="resultsNo"
                    />
                    <Column
                        className="truncate al-right"
                        title={
                            <TableColumnWithTooltip
                                noFill={false}
                                {...semTableColumns['url']}
                            />
                        }
                        dataIndex="url"
                        render={(text, record: TrackedKeywordsTableRecord, index) => <UrlTableCell url={record.url} />}
                    />
                    <Column
                        className="no-wrap al-right"
                        title="Action"
                        dataIndex="operation"
                        render={(text, record: TrackedKeywordsTableRecord, index) => {
                            return (
                                <span
                                    className={'remove-item' + (isLoading && record.keyword.localeCompare(originalArgs!.keyword) === 0 ? ' in-progress' : '')}
                                    onClick={() => handleDelete(record.keyword)}
                                >
                                    {isLoading && record.keyword.localeCompare(originalArgs!.keyword) === 0 ? <Icon type="loading" /> : <Icon type="delete" />}
                                </span>
                            );
                        }}
                    />
                </Table>
            )}
        </>
    );
}
