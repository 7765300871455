import {
  FETCH_LOADING_TIME_IN_PROGRESS,
  FETCH_LOADING_TIME_SUCCESSFUL,
  FETCH_LOADING_TIME_FAILED,
  CHANGE_SELECTED_INTERVAL,
  CHANGE_SELECTED_REGION,
  FETCH_LOADING_TIME_FOR_DASHBOARD_IN_PROGRESS,
  FETCH_LOADING_TIME_FOR_DASHBOARD_FINISHED,
  SEND_LOADING_TIME_REQUEST_IN_PROGRESS,
  SEND_LOADING_TIME_REQUEST_SUCCESSFUL,
  SEND_LOADING_TIME_REQUEST_FAILED
} from "./types";

export const fetchLoadingTimeInProgress = (url, region, interval) => ({
  type: FETCH_LOADING_TIME_IN_PROGRESS,
  url,
  region,
  interval
});

export const fetchLoadingTimeSuccessful = (url, region, interval, reports) => ({
  type: FETCH_LOADING_TIME_SUCCESSFUL,
  url,
  region,
  interval,
  reports
});

export const fetchLoadingTimeFailed = (url, region, interval) => ({
  type: FETCH_LOADING_TIME_FAILED,
  url,
  region,
  interval
});

export const changeSelectedInterval = (url, interval) => ({
  type: CHANGE_SELECTED_INTERVAL,
  url,
  interval
});

export const changeSelectedRegion = (url, region) => ({
  type: CHANGE_SELECTED_REGION,
  url,
  region
});

export const fetchLoadingTimeForDashboardInProgress = url => ({
  type: FETCH_LOADING_TIME_FOR_DASHBOARD_IN_PROGRESS,
  url
});

export const fetchLoadingTimeForDashboardFinished = url => ({
  type: FETCH_LOADING_TIME_FOR_DASHBOARD_FINISHED,
  url
});

export const sendLoadingTimeRequestInProgress = () => ({
  type: SEND_LOADING_TIME_REQUEST_IN_PROGRESS
});

export const sendLoadingTimeRequestSuccessful = () => ({
  type: SEND_LOADING_TIME_REQUEST_SUCCESSFUL
});

export const sendLoadingTimeRequestFailed = () => ({
  type: SEND_LOADING_TIME_REQUEST_FAILED
});
