import { useStartBackroundAnalysisMutation } from '../../analysis/hooks/useStartBackgroundAnalysisMutation';
import { useAddTrackedKeywordsMutation } from '../../trackedKeywords/service';
import { useAddTrackedWebsiteMutation, useSendOnboardingEventMutation } from '../service';
import { AddTrackedWebsitesParams } from '../types';

export function useAddTrackedWebsiteWithBackgroundSync() {
    const [addTrackedWebsiteTrigger, { data, isLoading, isSuccess, isError, error }] = useAddTrackedWebsiteMutation();
    const [addTrackedKeywords] = useAddTrackedKeywordsMutation();

    const { startBackgroundAnalysis } = useStartBackroundAnalysisMutation();

    const [sendOnboardingEvent] = useSendOnboardingEventMutation();

    const addTrackedWebsiteWithBackgroundSync = async (params: AddTrackedWebsitesParams) => {
        const { url, competitors, fromOnboardingWizard, locationName, languageCode, keywords } = params;

        const newTrackedWebsite = await addTrackedWebsiteTrigger(params).unwrap();

        const backgroundAnalysisReport = await startBackgroundAnalysis(url, competitors, fromOnboardingWizard);

        // send finish_onboarding event only if background analysis was initiated from the onboarding wizard
        if (fromOnboardingWizard) {
            const event_meta = {
                main_url: url,
                report_guid: backgroundAnalysisReport.guid,
                location_name: locationName,
                language_code: languageCode,
            };

            await sendOnboardingEvent({ event_type: 'finish_onboarding', meta: event_meta }).unwrap();
        }

        await addTrackedKeywords({
            domain: newTrackedWebsite.domain,
            locationName: locationName,
            languageCode: languageCode,
            keywords: keywords,
        }).unwrap();

        return newTrackedWebsite;
    };

    return {
        addTrackedWebsite: addTrackedWebsiteWithBackgroundSync,
        data,
        isLoading,
        isSuccess,
        isError,
        error,
    };
}
