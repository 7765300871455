import React, { Component } from "react";
import { Cascader, Button } from "antd";

export class SavedReportsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.getOptions()
    };

    this.getOptions = this.getOptions.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadMoreUrls = this.loadMoreUrls.bind(this);
    this.getQueryString = this.getQueryString.bind(this);
    this.displayRender = this.displayRender.bind(this);
    this.isValidUrl = this.isValidUrl.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.queryParams["report_url"]) {
      let encodedReportUrl = this.props.queryParams["report_url"];
      let decodedReportUrl = decodeURIComponent(encodedReportUrl);
      let domain = this.isValidUrl(decodedReportUrl) ? new URL(decodedReportUrl).hostname : null;

      if (!domain) {
        this.props.push("/white-label/reports");
        return;
      }

      let options = this.getOptions();
      this.props.fetchDomainUrls(domain).then(_ => {
        let defaultOption = {
          value: domain,
          label: domain,
          isLeaf: false,
          children: [{ value: decodedReportUrl, label: decodedReportUrl }]
        };

        let updatedOptions = [];
        options.forEach((option, idx) => {
          if (option["value"] === defaultOption["value"]) {
            updatedOptions[idx] = defaultOption;
          } else {
            updatedOptions[idx] = options[idx];
          }
        });
        if (this._isMounted) {
          this.setState({ options: updatedOptions });
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.changeSelectedReportUrl(null);
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedReportUrl !== this.props.selectedReportUrl) {
      this.props.sendSelectedUrl(this.props.selectedReportUrl);
    }
  }

  getOptions() {
    let options = this.props.domains.map(domain => ({
      value: domain,
      label: domain,
      isLeaf: false
    }));
    return options;
  }

  loadData(selectedOptions) {
    const targetOption = selectedOptions[0];
    targetOption.loading = true;

    this.props.changeSelectedDomain(targetOption.value);

    this.props.fetchDomainUrls(targetOption.value).finally(_ => {
      targetOption.loading = false;
      let children;

      if (this.props.domainUrls.length === 0) {
        children = [
          {
            label: "No urls found for this domain",
            value: "no_data",
            disabled: true
          }
        ];
      }

      if (this.props.domainUrls.length > 0) {
        children = this.props.domainUrls.map(url => ({
          label: url,
          value: url
        }));
      }

      if (this.props.domainUrls.length > 0 && this.props.hasMoreUrls) {
        children = [
          ...children,
          { label: <Button onClick={this.loadMoreUrls}>Load more</Button>, value: "load_more", isLeaf: false }
        ];
      }

      targetOption["children"] = children;

      let updatedOptions = this.state.options;
      this.state.options.forEach((option, idx) => {
        if (option["value"] === targetOption["value"]) {
          updatedOptions[idx] = targetOption;
        }
      });
      this.setState({ options: [...updatedOptions] });
    });
  }

  loadMoreUrls() {
    this.props.fetchDomainUrls(this.props.selectedDomain, this.props.hasMoreUrls).finally(_ => {
      let updatedChildren = [];
      if (this.props.domainUrls.length > 0) {
        updatedChildren = this.props.domainUrls.map(url => ({
          label: url,
          value: url,
          isLeaf: false
        }));
      }
      if (this.props.domainUrls.length > 0 && this.props.hasMoreUrls) {
        updatedChildren = [
          ...updatedChildren,
          { label: <Button onClick={this.loadMoreUrls}>Load more</Button>, value: "load_more", isLeaf: false }
        ];
      }

      let updatedOptions = this.state.options.map(option => {
        if (option.value === this.props.selectedDomain) {
          return { ...option, children: updatedChildren };
        } else {
          return option;
        }
      });
      this.setState({ options: updatedOptions });
    });
  }

  onChange(value, selectedOptions) {
    if (value.length === 1) {
      return;
    }
    if (value[1] === "no_data") {
      this.props.changeSelectedReportUrl(null);
      return;
    }
    if (value[1] === "load_more") {
      return;
    }
    this.props.changeSelectedReportUrl(value[1]);
    this.props.changeSelectedReportGuid(this.props.selectedReportGuid);
    this.props.sendSelectedUrl(value[1]);
    if (this.props.queryParams["report_url"]) {
      let updatedQueryParams = {
        ...this.props.queryParams,
        report_url: value[1],
        report_guid: this.props.selectedReportGuid
      };
      this.props.push(`/white-label/reports?${this.getQueryString(updatedQueryParams)}`);
    }
  }

  displayRender(label) {
    return label[label.length - 1];
  }

  getQueryString(obj) {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&");
  }

  isValidUrl(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }

  render() {
    let reportUrlQueryParam = this.props.queryParams["report_url"];
    let decodedReportUrl = reportUrlQueryParam ? decodeURIComponent(reportUrlQueryParam) : null;
    let reportDomain =
      decodedReportUrl && this.isValidUrl(decodedReportUrl) ? new URL(decodedReportUrl).hostname : null;

    return (
      <Cascader
        options={this.state.options}
        onChange={this.onChange}
        loadData={this.loadData}
        defaultValue={
          reportUrlQueryParam && this.isValidUrl(decodedReportUrl) ? [reportDomain, decodedReportUrl] : null
        }
        displayRender={this.displayRender}
        placeholder={"Please select one saved report"}
      />
    );
  }
}

export default SavedReportsSelector;
