import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SeoReportLayout from "../components/SeoReport/SeoReportLayout";
import {
    isGeneratingReport,
    getReportInProgress,
    isFetchingSavedReport,
    getSavedReportFetchError,
    getReport,
    isFullReport,
    getSelectedSectionsCount
} from "../store/analysis/selectors";

import { getAnalysisReportGeneralStructure, fetchSavedReportForAnalysisReport } from "../store/analysis/operations";

class AnalysisReport extends Component {
    componentDidMount() {
        this.props.getAnalysisReportGeneralStructure();

        const assertRequiredParams = ["guid", "url"].every((param) =>
            Object.keys(this.props.queryParams).includes(param)
        );
        const isAnalysisStarted = this.props.queryParams["guid"] === this.props.reportInProgress;
        const shouldFetchSavedReport = assertRequiredParams && !isAnalysisStarted;
        const shouldRedirectToAnalysis = !assertRequiredParams && !this.props.isGeneratingReport;

        if (shouldFetchSavedReport) {
            this.props.fetchSavedReportForAnalysisReport(this.props.queryParams["guid"], this.props.queryParams["url"]);
        }
        if (shouldRedirectToAnalysis) {
            //console.log("redirecting to analysis");
            this.props.push("/analysis");
        }
    }

    render() {
        const initialStateWhenFetchingSavedReport =
            !this.props.savedReportFetchedError && !this.props.isFetchingSavedReport && !this.props.report;
        return (
            <SeoReportLayout
                mainUrl={this.props.queryParams["url"]}
                reportGuid={this.props.queryParams["guid"]}
                isLoading={
                    this.props.isGeneratingReport ||
                    this.props.isFetchingSavedReport ||
                    initialStateWhenFetchingSavedReport
                }
                savedReportFetchedError={this.props.savedReportFetchedError}
                isFullReport={this.props.isFullReport}
                selectedSectionsCount={this.props.selectedSectionsCount}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const guidQueryParam = state.router.location.query.guid;
    const urlQueryParam = state.router.location.query.url;

    return {
        isGeneratingReport: isGeneratingReport(state),
        isFetchingSavedReport: isFetchingSavedReport(state, guidQueryParam, urlQueryParam),
        reportInProgress: getReportInProgress(state),
        queryParams: state.router.location.query,
        savedReportFetchedError: getSavedReportFetchError(state, guidQueryParam),
        report: getReport(state, guidQueryParam),
        isFullReport: isFullReport(state, guidQueryParam),
        selectedSectionsCount: getSelectedSectionsCount(state, guidQueryParam)
    };
};

export default connect(mapStateToProps, {
    getAnalysisReportGeneralStructure,
    fetchSavedReportForAnalysisReport,
    push
})(AnalysisReport);
