import { WHITE_LABEL_API_URL, DOWNLOAD_PDF_API_URL } from "../../settings/config";
import { push } from "connected-react-router";
import { authRequest } from "../authorizedRequests";
import { getTemplates } from "./selectors";
//import createLinkAndTriggerDownload from "../sitemap/utils";
import { saveAs } from "file-saver";
import {
  fetchTemplatesInProgress,
  fetchTemplatesSuccessful,
  fetchTemplatesFailed,
  addTemplateInProgress,
  addTemplateSuccessful,
  addTemplateInFailed,
  editTemplateInProgress,
  editTemplateSuccessful,
  deleteTemplateInProgress,
  deleteTemplateSuccessful,
  deleteTemplateFailed,
  editTemplateFailed,
  changeSelectedTemplate,
  downloadPdfReportInProgress,
  downloadPdfReportSuccessful,
  downloadPdfReportFailed
} from "./creators";

import { addNotificationWithTimeout } from "../notifications/operations";

export const fetchTemplates = () => {
  return (dispatch, getState) => {
    const apiUrl = `${WHITE_LABEL_API_URL}/white-label/template`;

    if (getTemplates(getState()).length > 0) {
      return Promise.resolve();
    }

    dispatch(fetchTemplatesInProgress());

    authRequest(apiUrl, dispatch, getState)
      .then((response) => {
        dispatch(fetchTemplatesSuccessful(response));
      })
      .catch((_) => {
        dispatch(fetchTemplatesFailed());
        dispatch(addNotificationWithTimeout("error", "Something went wrong"));
      });
  };
};

export const addTemplate = (payload) => {
  return (dispatch, getState) => {
    const apiUrl = `${WHITE_LABEL_API_URL}/white-label/template`;

    dispatch(addTemplateInProgress());

    authRequest(apiUrl, dispatch, getState, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        dispatch(addTemplateSuccessful(response));
        dispatch(changeSelectedTemplate(response["guid"]));
        dispatch(push(`/white-label/templates/${response["guid"]}`));
        dispatch(addNotificationWithTimeout("success", "Successfully added your template"));
      })
      .catch((_) => {
        dispatch(addTemplateInFailed());
      });
  };
};

export const editTemplate = (guid, payload) => {
  return (dispatch, getState) => {
    const apiUrl = `${WHITE_LABEL_API_URL}/white-label/template/${guid}`;

    dispatch(editTemplateInProgress(guid));

    authRequest(apiUrl, dispatch, getState, {
      method: "PATCH",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        dispatch(editTemplateSuccessful(guid, response));
        dispatch(addNotificationWithTimeout("success", "Successfully saved your template"));
      })
      .catch((_) => {
        dispatch(editTemplateFailed(guid));
      });
  };
};

export const deleteTemplate = (guid) => {
  return (dispatch, getState) => {
    const apiUrl = `${WHITE_LABEL_API_URL}/white-label/template/${guid}`;

    dispatch(deleteTemplateInProgress(guid));

    authRequest(apiUrl, dispatch, getState, { method: "DELETE" })
      .then((_) => {
        dispatch(deleteTemplateSuccessful(guid));
      })
      .catch((_) => {
        dispatch(deleteTemplateFailed(guid));
      });
  };
};

export const downloadPdfReport = (report_url, report_guid, template_guid) => {
  return (dispatch, getState) => {
    const reportRenderApiUrl = `${DOWNLOAD_PDF_API_URL}?report_url=${report_url}&report_guid=${report_guid}&template_guid=${template_guid}`;

    dispatch(downloadPdfReportInProgress(report_url, report_guid, template_guid));

    authRequest(reportRenderApiUrl, dispatch, getState)
      .then((response) => {
        const newBlob = new Blob([_base64ToArrayBuffer(response["pdf_content"])], { type: "application/pdf" });

        saveAs(newBlob, response["file_name"]);
        dispatch(downloadPdfReportSuccessful(report_url, report_guid, template_guid, "report_url"));
      })
      .catch((_) => {
        dispatch(downloadPdfReportFailed(report_url, report_guid, template_guid));
      });
  };
};

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
