import React, { useState } from 'react';
import { RegionEventGroup } from './types';
import { Row, Col, Modal } from 'antd';
import OutageIssueDetails from './OutageIssueDetails';

type Props = {
    url: string;
    location: string;
    events: RegionEventGroup;
};

export default function OutageTooltip({ url, location, events }: Props) {
    const [state, setState] = useState({ pingModalVisible: false, httpModalVisible: false });

    const setModalVisible = (type: 'ping' | 'http', value: boolean) => {
        if (type === 'ping') {
            setState({ ...state, pingModalVisible: value });
        }
        if (type === 'http') {
            setState({ ...state, httpModalVisible: value });
        }
    };

    return events.ping.status === 'down' || events.loading_time.status === 'down' ? (
        <Row
            className="outage-tooltip-event"
            gutter={16}
        >
            <Col
                className="left"
                span={12}
            >
                {events.ping.status === 'down' ? (
                    <React.Fragment>
                        <Modal
                            className="issue-details-modal"
                            title="Ping issue"
                            centered
                            visible={state.pingModalVisible}
                            footer={null}
                            onCancel={() => setModalVisible('ping', false)}
                        >
                            <OutageIssueDetails
                                url={url}
                                createdAt={events.ping.created_at}
                                location={location}
                            />
                        </Modal>
                        <div
                            className="event-status danger"
                            onClick={() => setModalVisible('ping', true)}
                        >
                            PING ISSUE
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="event-status success">NO PING ISSUES</div>
                )}
            </Col>
            <Col
                className="right"
                span={12}
            >
                {events.loading_time.status === 'down' ? (
                    <React.Fragment>
                        <Modal
                            className="issue-details-modal"
                            title="HTTP issue"
                            centered
                            visible={state.httpModalVisible}
                            footer={null}
                            onCancel={() => setModalVisible('http', false)}
                        >
                            <OutageIssueDetails
                                url={url}
                                createdAt={events.loading_time.created_at}
                                location={location}
                            />
                        </Modal>
                        <div
                            className="event-status danger"
                            onClick={() => setModalVisible('http', true)}
                        >
                            HTTP ISSUE
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="event-status success">NO HTTP ISSUES</div>
                )}
            </Col>
        </Row>
    ) : (
        <div className="outage-tooltip-event">
            <span className="event-status success">No issues found</span>
        </div>
    );
}
