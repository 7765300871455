import React from "react";
import "./WebVitalChart.less";

function WebVitalChart(props) {
  let scaleMax = props.maxGoodVal + props.minPoorVal;
  let markerAt = props.markerAt;

  if (markerAt < props.maxGoodVal / 4) {
    markerAt = props.maxGoodVal / 4;
  }

  if (markerAt > scaleMax - (scaleMax - props.minPoorVal) / 4) {
    markerAt = scaleMax - (scaleMax - props.minPoorVal) / 4;
  }

  let scaleFactor = 100 / scaleMax;

  let goodValStyles = {
    width: `${props.maxGoodVal * scaleFactor}%`
  };

  let needsImprovementValStyles = {
    left: goodValStyles.width,
    width: `${(props.minPoorVal - props.maxGoodVal) * scaleFactor}%`
  };

  let poorValStyles = {
    left: `${props.minPoorVal * scaleFactor}%`,
    width: `${(scaleMax - props.minPoorVal) * scaleFactor}%`
  };

  let markerLeft = `${markerAt * scaleFactor}%`;
  const maxGoodValTickLeft = `${props.maxGoodVal * scaleFactor}%`;
  const minPoorValTickLeft = `${props.minPoorVal * scaleFactor}%`;

  return (
    <div className="web-vital-chart-container">
      <div className="web-vital-chart">
        <div className="good" style={goodValStyles}></div>
        <div className="needs-improvement" style={needsImprovementValStyles}></div>
        <div className="poor" style={poorValStyles}></div>
        <div className="marker" style={{ left: markerLeft }}></div>
        <p className="marker-value" style={{ left: markerLeft }}>
          {props.markerAt}
          {props.unitsSuffix || ""}
        </p>
        <div className="ticks">
          <div className="tick" style={{ left: maxGoodValTickLeft }}></div>
          <div className="tick" style={{ left: minPoorValTickLeft }}></div>
          <p className="tick-value" style={{ left: maxGoodValTickLeft }}>
            {props.maxGoodVal}
            {props.unitsSuffix || ""}
          </p>
          <p className="tick-value" style={{ left: minPoorValTickLeft }}>
            {props.minPoorVal}
            {props.unitsSuffix || ""}
          </p>
        </div>
      </div>
    </div>
  );
}

export default WebVitalChart;
