import { createApi } from '@reduxjs/toolkit/query/react';
import { SSC_SEM_API_URL } from '../settings/config';
import { authRequest } from '../store/authorizedRequests';
import { FetchKeywordResearchParams, ResearchedKeyword } from './types';

const keywordResearchBaseUrl = `${SSC_SEM_API_URL}/dfs/keywords-research`;
const KEYWORD_RESEARCH_REFETCH_TIME = 7200;

export const keywordResearchApi = createApi({
    reducerPath: 'api.keywordResearch',
    keepUnusedDataFor: KEYWORD_RESEARCH_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;

        try {
            const data = await authRequest(keywordResearchBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchKeywordResearch: builder.query<ResearchedKeyword[], FetchKeywordResearchParams>({
            query: (args) => {
                const { research_type, phrase, location, language } = args;

                return {
                    url: `?research_type=${research_type}&phrase=${encodeURIComponent(phrase)}&location=${encodeURIComponent(location)}&language=${language}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useFetchKeywordResearchQuery } = keywordResearchApi;
