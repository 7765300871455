import React from "react";
import { Card, Icon } from "antd";
import SeoScoreProgressChart from "../Dashboard/SeoScoreProgress/SeoScoreProgressChart";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";

function SeoScoreProgressForArchivedReports(props) {
  return (
    <div className="dashboard-seo-score-progress-box">
      <Card
        data-cy="seo-score-progress-for-archived-reports"
        title={
          <div className="ant-card-meta-detail">
            <div className="ant-card-meta-title">SEO Score Progress</div>
            <div className="ant-card-meta-description">{props.customInterval}</div>
          </div>
        }
        extra={
          <div className="seo-score-progress-links flexbox">
            <div
              className="card-link flexbox align-center"
              onClick={() => props.push("/dashboard/settings/competitors")}>
              <Icon type={props.competitors.length > 0 ? "edit" : "plus-circle"} />
              <span>{props.competitors.length > 0 ? "Edit" : "Add"} competitors</span>
            </div>
            {props.lastSavedReportGuid && (
              <div
                className="card-link flexbox align-center"
                onClick={() => props.push(`/analysis/report?guid=${props.lastSavedReportGuid}&url=${props.url}`)}>
                <span>View report</span>
                <Icon type="right" />
              </div>
            )}
          </div>
        }>
        {props.isLoading ? (
          <LoadingIndicator />
        ) : (
          <SeoScoreProgressChart mainUrl={props.url} competitors={props.competitors} chartData={props.chartData} />
        )}
      </Card>
    </div>
  );
}

export default SeoScoreProgressForArchivedReports;
