import React from "react";
import { Icon, Tooltip } from "antd";
import PropTypes from "prop-types";

const SectionTitle = ({ title, status, passRate }) => {
  const iconForStatus = status => {
    if (status === "passed") return "check-circle";
    if (status === "warning") return "exclamation-circle";
    return "close-circle";
  };

  const passRateTooltipText =
    "This value indicates the percent of top 100 most visited websites in the US that pass this test (in the past 12 months). " +
    "Failing a test that a high percentage of top websites are passing may indicate an industry best practice that you should be fixing.";

  const titleWithStatus = () => (
    <React.Fragment>
      <Icon type={iconForStatus(status)} theme="filled" className="status-icon" />
      <strong>{title}</strong>
      {passRate && passRate > 0 ? (
        <Tooltip title={passRateTooltipText}>
          <small>{`${passRate}% of top 100 sites passed`}</small>
        </Tooltip>
      ) : null}
    </React.Fragment>
  );

  const titleWithoutStatus = () => (
    <React.Fragment>
      <span className="status-icon default-icon" />
      <strong>{title}</strong>
    </React.Fragment>
  );

  return <div className="report-element-title">{status ? titleWithStatus() : titleWithoutStatus()}</div>;
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string
};

export default SectionTitle;
