import React from "react";
import "./customIcons.less";

export default function CustomIcon(props) {
  var componentStyle = {
    color: `${props.color}`,
    fontSize: `${props.size}px`
  };
  return (
      <span
        className={"icon custom-icon custom-icon-" + props.name}
        style={componentStyle}
        color={props.color}
        size={props.size}>
      </span>
    
  );
}
