import {
  FETCH_TEMPLATES_IN_PROGRESS,
  FETCH_TEMPLATES_SUCCESSFUL,
  FETCH_TEMPLATES_FAILED,
  ADD_TEMPLATE_IN_PROGRESS,
  ADD_TEMPLATE_SUCCESSFUL,
  ADD_TEMPLATE_FAILED,
  EDIT_TEMPLATE_IN_PROGRESS,
  EDIT_TEMPLATE_SUCCESSFUL,
  EDIT_TEMPLATE_FAILED,
  DELETE_TEMPLATE_IN_PROGRESS,
  DELETE_TEMPLATE_SUCCESSFUL,
  DELETE_TEMPLATE_FAILED,
  CHANGE_SELECTED_REPORT_GUID,
  CHANGE_SELECTED_TEMPLATE,
  CHANGE_SELECTED_REPORT_URL,
  DOWNLOAD_PDF_REPORT_IN_PROGRESS,
  DOWNLOAD_PDF_REPORT_SUCCESSFUL,
  DOWNLOAD_PDF_REPORT_FAILED
} from "./types";

import { LOGOUT } from "../iam/types";

const initialState = {
  templates: [],
  default_seo_score_section: {
    url: "https://your-url-goes-here.com",
    seo_score: 66,
    sections_status_counter: { passed: 39, warning: 0, failed: 12 },
    sections_status_progress: { passed: 77, warning: 0, failed: 23 }
  },
  network_activity: [],
  selected_template: null,
  selected_report_guid: null,
  selected_report_url: null,
  pdf_url: null
};

const networkActivityMessages = {
  fetch_templates: "fetching_templates",
  add_template: "adding_template",
  edit_template: "editing_template_",
  delete_template: "deleting_template_",
  download_pdf_report: "download_pdf_report"
};

const getNewSelectedTemplateGuid = (oldGuid, templates) => {
  return templates.filter((template) => template["guid"] !== oldGuid)[0]["guid"];
};

const whiteLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATES_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, networkActivityMessages["fetch_templates"]]
      };
    case FETCH_TEMPLATES_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter((item) => item !== networkActivityMessages["fetch_templates"]),
        templates: [...action["templates"]],
        selected_template: action["templates"].length ? action["templates"][0]["guid"] : null
      };
    case FETCH_TEMPLATES_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter((item) => item !== networkActivityMessages["fetch_templates"])
      };
    case ADD_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, networkActivityMessages["add_template"]]
      };
    case ADD_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter((item) => item !== networkActivityMessages["add_template"]),
        templates: [...state.templates, action["template"]]
      };
    case ADD_TEMPLATE_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter((item) => item !== networkActivityMessages["add_template"])
      };
    case EDIT_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, `${networkActivityMessages["edit_template"]}${action["guid"]}`]
      };
    case EDIT_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${networkActivityMessages["edit_template"]}${action["guid"]}`
        ),
        templates: state.templates.map((template) =>
          template["guid"] === action["guid"]
            ? {
                ...template,
                ...action["template"]
              }
            : template
        )
      };
    case EDIT_TEMPLATE_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${networkActivityMessages["edit_template"]}${action["guid"]}`
        )
      };
    case DELETE_TEMPLATE_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, `${networkActivityMessages["delete_template"]}${action["guid"]}`]
      };
    case DELETE_TEMPLATE_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${networkActivityMessages["delete_template"]}${action["guid"]}`
        ),
        templates: state.templates.filter((template) => template["guid"] !== action["guid"]),
        selected_template:
          state.selected_template === action["guid"]
            ? getNewSelectedTemplateGuid(state.selected_template, state.templates)
            : state.selected_template
      };
    case DELETE_TEMPLATE_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${networkActivityMessages["delete_template"]}${action["guid"]}`
        )
      };
    case CHANGE_SELECTED_REPORT_GUID:
      return {
        ...state,
        selected_report_guid: action["guid"]
      };
    case CHANGE_SELECTED_TEMPLATE:
      return {
        ...state,
        selected_template: action["guid"]
      };
    case CHANGE_SELECTED_REPORT_URL:
      return {
        ...state,
        selected_report_url: action["url"]
      };
    case DOWNLOAD_PDF_REPORT_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${networkActivityMessages["download_pdf_report"]}_${action["report_url"]}_${action["report_guid"]}_${action["template_guid"]}`
        ]
      };
    case DOWNLOAD_PDF_REPORT_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) =>
            item !==
            `${networkActivityMessages["download_pdf_report"]}_${action["report_url"]}_${action["report_guid"]}_${action["template_guid"]}`
        ),
        pdf_url: action.pdf_url
      };
    case DOWNLOAD_PDF_REPORT_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) =>
            item !==
            `${networkActivityMessages["download_pdf_report"]}_${action["report_url"]}_${action["report_guid"]}_${action["template_guid"]}`
        )
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default whiteLabelReducer;
