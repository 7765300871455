import React from "react";
import { Row, Col } from "antd";
import CustomReportForm from "./CustomReportForm";
import Template from "../templates/Template";
import "./CustomReportLayout.less";

function CustomReportLayout(props) {
  return (
    <Row gutter={[16, 16]} className="custom-report-templates">
      <Col span={12} className="form-col">
        <CustomReportForm
          queryParams={props.queryParams}
          templates={props.templates}
          selectedTemplateGuid={props.selectedTemplateGuid}
          selectedTemplate={props.selectedTemplate}
          selectedReport={props.selectedReport}
          selectedReportGuid={props.selectedReportGuid}
          selectedReportUrl={props.selectedReportUrl}
          changeSelectedReportGuid={props.changeSelectedReportGuid}
          changeSelectedTemplate={props.changeSelectedTemplate}
          isDownloadingPdfReport={props.isDownloadingPdfReport}
          downloadPdfReport={props.downloadPdfReport}
          push={props.push}
        />
      </Col>
      <Col span={12} className="preview-col">
        <Template
          logo={props.logo}
          header={props.header}
          footer={props.footer}
          url={props.url}
          seoScore={props.seoScore}
          sectionsStatusCounter={props.sectionsStatusCounter}
          sectionsStatusProgress={props.sectionsStatusProgress}
          selectedReportUrl={props.selectedReportUrl}
          selectedReportGuid={props.selectedReportGuid}
          selectedTemplateGuid={props.selectedTemplateGuid}
          userGuid={props.userGuid}
        />
      </Col>
    </Row>
  );
}

export default CustomReportLayout;
