import React, { useState } from "react";
import { Input, Button, Form } from "antd";
import { validate } from "validate.js";

const InputGroup = Input.Group;
const emailValidationRules = {
    email: {
        email: {
            message: "^Please enter a valid email"
        }
    }
};

const SendTeamInvitationForm = ({ onSendInvitation, refetchOrganization }) => {
    const [memberEmail, setMemberEmail] = useState("");
    const [isSendingInvitation, setIsSendingInvitation] = useState(false);
    const [inviteMemberError, setInviteMemberError] = useState(null);

    const handleMemberEmailChange = (event) => {
        const { value } = event.target;
        setMemberEmail(value);
        inviteMemberError && setInviteMemberError(null);
    };

    const inviteTeamMember = (event) => {
        event.preventDefault();

        const validationErrors = validate({ email: memberEmail }, emailValidationRules);

        if (validationErrors) {
            setInviteMemberError(validationErrors.email[0]);
            return;
        }

        setIsSendingInvitation(true);

        onSendInvitation(memberEmail)
            .then((_) => {
                setIsSendingInvitation(false);
                setMemberEmail("");
                refetchOrganization();
            })
            .catch(async (response) => {
                const message = await response.json();
                console.log(`Error inviting team member: ${message.error}`);
                setIsSendingInvitation(false);
                setInviteMemberError(message.error);
            });
    };

    return (
        <Form layout="vertical" className="analysis-form invite-member" onSubmit={inviteTeamMember}>
            <Form.Item
                label="Invite new member"
                validateStatus={inviteMemberError ? "error" : "validating"}
                help={inviteMemberError ? inviteMemberError : ""}
            >
                <InputGroup compact size="large">
                    <div className="compact-input-group">
                        <Input
                            placeholder="Enter email"
                            name="memberEmail"
                            size="large"
                            value={memberEmail}
                            onChange={(event) => handleMemberEmailChange(event)}
                        />

                        <Button htmlType="submit" type="primary" size="large" loading={isSendingInvitation}>
                            Invite
                        </Button>
                    </div>
                </InputGroup>
            </Form.Item>
        </Form>
    );
};

export default SendTeamInvitationForm;
