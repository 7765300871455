import React, { useState } from 'react';
import { Modal } from 'antd';
import KeywordResearchSuggestionsTabs from './KeywordResearchSuggestionsTabs';
import ModalTitle from '../../components/shared/ModalTitle/ModalTitle';

type Props = {
    keyword: string;
    location: string;
    languageCode: string;
};

export default function KeywordResearchSuggestionsButton({ keyword, location, languageCode }: Props) {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    return (
        <>
            <span
                className="view-keyword-research"
                onClick={handleToggleModal}
            >
                Show
            </span>

            <Modal
                className="keyword-research-modal"
                title={
                    <ModalTitle
                        title="Keywords suggestions for: "
                        meta={keyword}
                    />
                }
                visible={isModalVisible}
                onCancel={handleToggleModal}
                footer={null}
                width={1024}
                destroyOnClose
            >
                <KeywordResearchSuggestionsTabs
                    keyword={keyword}
                    location={location}
                    languageCode={languageCode}
                />
            </Modal>
        </>
    );
}
