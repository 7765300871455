import React from 'react';
import { Button, Alert } from 'antd';
import { TrackedWebsite } from '../../../trackedWebsites/types';
import { useDeleteTrackedWebsiteMutation } from '../../../trackedWebsites/service';
import { useFetchTrackedWebsitesQuery } from '../../../trackedWebsites/service';
import { useLazyFetchTrackedKeywordsCountQuery } from '../../../trackedKeywords/service';
import { useNavigate } from '../../../router/hooks/useNavigate';

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void;
};

export default function RemoveUrlTab({ selectedWebsite, setSelectedWebsite }: Props) {
    const navigate = useNavigate();
    const [deleteTrackedWebsite, { isLoading: isDeletingTrackedWebsite }] = useDeleteTrackedWebsiteMutation();
    const [fetchTrackedKeywordsCount] = useLazyFetchTrackedKeywordsCountQuery();
    const { data } = useFetchTrackedWebsitesQuery();

    const handleDeleteTrackedWebsite = async () => {
        try {
            await deleteTrackedWebsite({ guid: selectedWebsite.guid, url: selectedWebsite.url }).unwrap();
            await fetchTrackedKeywordsCount(undefined, false).unwrap();

            if (data) {
                const newSelectedTrackedWebsites = data.tracked_websites.find((trackedWebsite) => trackedWebsite.guid !== selectedWebsite.guid);
                if (newSelectedTrackedWebsites) {
                    setSelectedWebsite(newSelectedTrackedWebsites);
                } else {
                    setSelectedWebsite(null);
                }
            } else {
                setSelectedWebsite(null);
            }

            navigate('/dashboard');
        } catch (error) {}
    };

    return (
        <div className="remove-url-tab">
            <Alert
                message="Please note!"
                description={
                    <React.Fragment>
                        <div>By deleting this URL you will not be able to:</div>
                        <ul>
                            <li>Receive weekly emails regarding this website's performance</li>
                            <li>Monitor the loading time of your website</li>
                            <li>Monitor the uptime of your website</li>
                            <li>Monitor the backlinks of your website</li>
                            <li>Check the top keywords of your website</li>
                        </ul>
                    </React.Fragment>
                }
                type="error"
                showIcon
            />

            <Button
                type="danger"
                onClick={handleDeleteTrackedWebsite}
                loading={isDeletingTrackedWebsite}
            >
                Remove {selectedWebsite.url}
            </Button>
        </div>
    );
}
