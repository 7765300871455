import React, { Component } from 'react';
import HtmlContent from '../HtmlContent/HtmlContent';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

class HowToFix extends Component {
    constructor(props){
        super(props);
        this.state = {visible: false};
        this.toggleModal = this.toggleModal.bind(this);
    };

    toggleModal() {
        this.setState(prevState => ({
            visible: !prevState.visible
        }));
    };
    
    render() {
        if(!this.props.how_to_fix){
            return null;
        }
        
        return (
            <div className="description-meta">
                <Button type="danger" onClick={this.toggleModal}>
                    How to fix
                </Button>
                <Modal
                centered
                className="wide-modal"
                title={this.props.title}
                visible={this.state.visible}
                onCancel={this.toggleModal}
                footer={null}
                >
                <div className="modal-content no-padding">
                    {this.props.how_to_fix_video ?
                        <iframe
                            title="video"
                            width="100%"
                            height="320"
                            src={this.props.how_to_fix_video}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        /> : null}
                    <div className="default-padding how-to-fix-content" >
                        <h3>How to pass this test?</h3>
                        <HtmlContent content={this.props.how_to_fix} />
                    </div>
                </div>
                <div className="button-footer">
                    <Button type="secondary" onClick={this.toggleModal}>
                    Close
                    </Button>
                    </div> 
                </Modal>
            </div>
        )
    }
}

HowToFix.propTypes = {
    title: PropTypes.string,
    how_to_fix: PropTypes.string,
    how_to_fix_video: PropTypes.string,
};

export default HowToFix;





