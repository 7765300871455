import React from 'react';
import { Table } from 'antd';
import CountryTableCell from './CountryTableCell';
import { TopCountry } from '../../types';

const { Column } = Table;

type Props = {
    topCountries: TopCountry[];
};

export default function TopCountriesTable({ topCountries }: Props) {
    const dataSource = topCountries.map((topCountry, index) => ({ ...topCountry, key: index + 1 }));

    return (
        <Table
            dataSource={dataSource}
            pagination={false}
            size="small"
            data-cy="top-countries-table"
        >
            <Column
                title="#"
                dataIndex="key"
                align="left"
                width={100}
            />
            <Column
                className="no-wrap"
                title="Country"
                dataIndex="country"
                render={(text, record: TopCountry, index) => (
                    <CountryTableCell
                        code={record.country_code}
                        name={record.country}
                    />
                )}
            />
            <Column
                className="no-wrap al-right"
                title="Backlinks"
                dataIndex="backlinks_num"
                render={(text, record: TopCountry, index) => record.backlinks_num.toLocaleString()}
            />
        </Table>
    );
}
