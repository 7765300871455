import React from "react";
import { Tooltip, Icon } from "antd";

function TopKeywordsSummary({ topKeywordsAvgPos }) {
  return (
    <div className="dashboard-summary-section">
      <div className="dashboard-summary-section-title">
        Avg. position{" "}
        <Tooltip title={"Average position of your domain in Top 100 keywords."}>
          <Icon type="question-circle" />
        </Tooltip>
      </div>
      <div className="dashboard-summary-section-result">
        {topKeywordsAvgPos ? (
          <React.Fragment>
            <Icon type="vertical-align-middle" /> {topKeywordsAvgPos.toFixed(2)}
          </React.Fragment>
        ) : (
          <span>No data</span>
        )}
      </div>
    </div>
  );
}

export default TopKeywordsSummary;
