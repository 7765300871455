import formatter from "xml-formatter";

const formatted_protocol_standard = `<urlset 
    xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
    xmlns:xhtml="http://www.w3.org/1999/xhtml" 
    xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9">`;
const protocol_standard = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xhtml="http://www.w3.org/1999/xhtml" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9">`;

export const createSitemapStructure = urls => {
  let url_string_list = "";
  urls.forEach(link_details => {
    url_string_list += `<url><loc>${link_details.url}</loc></url>`;
  });
  let sitemap_structure = `<?xml version="1.0" encoding="UTF-8"?>\
${protocol_standard}${url_string_list}</urlset>`;
  var options = { indentation: "    ", collapseContent: true, lineSeparator: "\n" };
  var formatted_structure = formatter(sitemap_structure, options);
  const structure_without_spaces = formatted_structure.replace(/^ {0}/gm, "");
  const structure_with_formatted_protocol_standard = structure_without_spaces.replace(
    protocol_standard,
    formatted_protocol_standard
  );
  return structure_with_formatted_protocol_standard;
};
