import { push, getLocation } from "connected-react-router";
import moment from "moment";
import { authRequest } from "../authorizedRequests";
import { TRACKED_WEBSITES_API_URL } from "../../settings/config";
import { trackedWebsites, getSelectedWebsiteGuid, getTrackedWebsiteGuid, getSelectedWebsiteUrl } from "./selectors";
import { reloadTopKeywords } from "../top_keywords/operations";
import { addTrackedKeywords } from "../tracked_keywords/operations";
import { startBackgroundAnalysis, fetchOverviews } from "../analysis/operations";
import { getOverviewsForUrl } from "../analysis/selectors";
import {
  getTrackedWebsitesInProgress,
  getTrackedWebsitesSuccess,
  getTrackedWebsitesFailed,
  addTrackedWebsiteInProgress,
  addTrackedWebsiteFailed,
  addTrackedWebsiteSuccess,
  changeCurrentWebsite,
  changeTrackedWebsiteSettingsInProgress,
  changeTrackedWebsiteSettingsSuccessful,
  changeTrackedWebsiteSettingsFailed,
  deleteTrackedWebsiteInProgress,
  deleteTrackedWebsiteSuccessful,
  deleteTrackedWebsiteFailed
} from "./creators";

import { trackedWebsitesApi } from "../../trackedWebsites/service";

let abortController;

export const getTrackedWebsites = () => {
  return (dispatch, getState) => {
    let websites = trackedWebsites(getState());

    if (websites.length) {
      return Promise.resolve(trackedWebsites);
    }

    dispatch(getTrackedWebsitesInProgress());

    authRequest(TRACKED_WEBSITES_API_URL, dispatch, getState)
      .then(response => {
        dispatch(getTrackedWebsitesSuccess(response["tracked_websites"]));

        // Logic for new RTK Query
        // Insert fetched websites into rtk query cache
        dispatch(trackedWebsitesApi.util.upsertQueryData('fetchTrackedWebsites', undefined, response));

        websites = trackedWebsites(getState());

        let addPreCrawlingUrlPromises = websites.map(tw => {
          if (!tw["last_crawled"]) {
            return dispatch(addUrlToPreCrawlingQueue(tw["url"]));
          } else {
            return Promise.resolve();
          }
        });

        Promise.all(addPreCrawlingUrlPromises);

        let currentDate = moment().utc();
        let endDate = currentDate.format("YYYY-MM-DDTHH:mm:ss");
        let startDate = currentDate.subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss");

        let overviewsPromises = websites.map(tw => {
          let overviews = getOverviewsForUrl(getState(), tw["url"]);
          if (overviews.length > 0) {
            return Promise.resolve();
          }
          return dispatch(fetchOverviews(tw["url"], startDate, endDate));
        });

        Promise.all(overviewsPromises);
      })
      .catch(_ => {
        dispatch(getTrackedWebsitesFailed());
      });
  };
};

export const addTrackedWebsite = (mainUrl, seoDb, competitorsUrls, regions, keywords, fromOnboardingWizard) => {
  return (dispatch, getState) => {
    const pathname = getLocation(getState()).pathname;
    const payload = {
      url: mainUrl,
      settings: {
        disabled_by_system: false,
        seo_db: seoDb || "us",
        competitors: competitorsUrls,
        regions: regions.length > 0 ? regions : ["us-east-1"]
      }
    };

    dispatch(addTrackedWebsiteInProgress());

    return authRequest(TRACKED_WEBSITES_API_URL, dispatch, getState, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then(response => {
        dispatch(addTrackedWebsiteSuccess(response));
        dispatch(startBackgroundAnalysis(mainUrl, competitorsUrls, fromOnboardingWizard)).then(res => {
          // send finish_onboarding event only if background analysis was initiated from the onboarding wizard
          if (fromOnboardingWizard) {
            const event_meta = {
              main_url: mainUrl,
              report_guid: res["guid"],
              seo_db: seoDb
            };
            dispatch(sendOnboardingEvent("finish_onboarding", event_meta));
          }
        });

        return { guid: response.guid, domain: response.domain };
      })
      .then(params => {
        dispatch(changeCurrentWebsite(params.guid));
        return params.domain;
      })
      .then(domain => {
        const startMonitor = true;
        dispatch(addTrackedKeywords(domain, seoDb, keywords, startMonitor)).catch(_ => {});

        if (pathname === "/user/account/setup") {
          dispatch(push("/dashboard"));
        }

        return;
      })
      .catch(_ => {
        dispatch(addTrackedWebsiteFailed());
      });
  };
};

export const sendOnboardingEvent = (event_type, meta) => {
  return (dispatch, getState) => {
    return authRequest(`${TRACKED_WEBSITES_API_URL}/onboarding`, dispatch, getState, {
      method: "POST",
      body: JSON.stringify({ event_type: event_type, meta: meta })
    })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(_ => {
        return Promise.resolve({ error: "Cannot send onboarding event" });
      });
  };
};

export const changeSelectedWebsite = guid => {
  return dispatch => {
    dispatch(changeCurrentWebsite(guid));
  };
};

/*
actionName can be one of the following:
  * "addCompetitor"
  * `removeCompetitor_${}`
  * "changeSeoDb"
  * "changeLocations"
  
postAction can be one of the following:
  * "reload_top_keywords"
  * "reload_tracked_keywords"
*/
export const updateSettings = (domain, settings, actionName, postAction) => {
  return (dispatch, getState) => {
    const websiteGuid = getSelectedWebsiteGuid(getState());
    const websiteUrl = getSelectedWebsiteUrl(getState());
    const payload = {
      url: websiteUrl,
      ...settings
    };

    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();

    dispatch(changeTrackedWebsiteSettingsInProgress(websiteGuid, actionName));

    authRequest(TRACKED_WEBSITES_API_URL, dispatch, getState, {
      method: "PATCH",
      body: JSON.stringify(payload),
      signal: abortController.signal
    })
      .then(response => {
        dispatch(changeTrackedWebsiteSettingsSuccessful(websiteGuid, actionName, response));

        // New RTK Query Logic
        // Get a promise containing the new tracked websites (as if we invalidated the cache and we re-fetch)
        const promise = dispatch(trackedWebsitesApi.endpoints.fetchTrackedWebsites.initiate(undefined, {forceRefetch: true, subscribe: false}));
        // This component should not be attached to rtk query subscription updates
        promise.unsubscribe();

        return response;
      })
      .then(response => {
        if (postAction === "reload_top_keywords") {
          dispatch(reloadTopKeywords(domain, response["seo_db"]));
        }
      })
      .catch(err => {
        //if (err.name !== "AbortError") {
        dispatch(changeTrackedWebsiteSettingsFailed(websiteGuid, actionName));
        //}
      });
  };
};

export const deleteTrackedWebsite = url => {
  return (dispatch, getState) => {
    const websiteGuid = getTrackedWebsiteGuid(getState(), url);
    dispatch(deleteTrackedWebsiteInProgress(url));

    return authRequest(TRACKED_WEBSITES_API_URL, dispatch, getState, {
      method: "DELETE",
      body: JSON.stringify({ url: url })
    })
      .then(_ => {
        dispatch(deleteTrackedWebsiteSuccessful(url, websiteGuid));
      })
      .then(_ => dispatch(push("/dashboard")))
      .catch(_ => {
        dispatch(deleteTrackedWebsiteFailed(url));
      });
  };
};

export const checkDomainsBlackList = url => {
  return (dispatch, getState) => {
    return authRequest(`${TRACKED_WEBSITES_API_URL}/black-list?url=${url}`, dispatch, getState)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(_ => {
        return Promise.resolve({ error: "Cannot validate domain" });
      });
  };
};

export const addUrlToPreCrawlingQueue = url => {
  return (dispatch, getState) => {
    return authRequest(`${TRACKED_WEBSITES_API_URL}/precrawling-queue`, dispatch, getState, {
      method: "POST",
      body: JSON.stringify({ url: url })
    })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(_ => {
        return Promise.resolve({ error: "Cannot add url to pre-crawling queue!" });
      });
  };
};
