import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import { getUrl } from "../../utils";

const displayResult = (result) => {
    if (result.competitor_domains && result.competitor_domains.length > 0) {
        return (
            <div className="description-meta">
                <ul>
                    {result.competitor_domains.map((domain) => (
                        <li key={domain}>
                            <a href={getUrl(domain)} target="_blank" rel="noopener noreferrer">
                                {domain}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    if (result["competitors"] && result["competitors"].length > 0) {
        return (
            <div className="stats-table-container">
                <header>
                    <div className="col">Competitor domain</div>
                    <div className="col">Domain rank</div>
                </header>

                {result["competitors"].map((competitor, idx) => {
                    return (
                        <div className="row" key={idx}>
                            <div className="col">
                                <a href={getUrl(competitor["domain"])} target="_blank" rel="noopener noreferrer">
                                    {competitor["domain"]}
                                </a>
                            </div>
                            <div className="col">{competitor["rank"]}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
    return null;
};

const CompetitorDomainsSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
    const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

    return (
        <div id={sectionKey} className="report-element default">
            <SectionTitle title={title} />

            <div className="report-element-description">
                <SectionMessage
                    url={url}
                    status={result.status}
                    relevance={relevance}
                    message={result.message}
                    hasCompetitors={hasCompetitors}
                />
                {displayResult(result)}
                <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayResult} />
            </div>

            <SectionTooltip tooltip={tooltip} />
        </div>
    );
};

export default CompetitorDomainsSection;
