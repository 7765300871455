import {
  CREATE_SUBSCRIPTION_IN_PROGRESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILED,
  START_3D_SECURE,
  FETCH_SUBSCRIPTION_PLANS_IN_PROGRESS,
  FETCH_SUBSCRIPTION_PLANS_SUCCESS,
  FETCH_SUBSCRIPTION_PLANS_FAILED,
  FETCH_SUBSCRIPTION_FAILED,
  FETCH_SUBSCRIPTION_IN_PROGRESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_IN_PROGRESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  FETCH_BILLING_INFO_FAILED,
  FETCH_BILLING_INFO_IN_PROGRESS,
  FETCH_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_INFO_FAILED,
  UPDATE_BILLING_INFO_IN_PROGRESS,
  UPDATE_BILLING_INFO_SUCCESS,
  FETCH_INVOICES_FAILED,
  FETCH_INVOICES_IN_PROGRESS,
  FETCH_INVOICES_SUCCESS,
  FETCH_PDF_INVOICE_FAILED,
  FETCH_PDF_INVOICE_IN_PROGRESS,
  FETCH_PDF_INVOICE_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION_IN_PROGRESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_3DSECURE_ERROR_MESSAGE
} from "./types";

export const createSubscriptionInProgress = _ => {
  return { type: CREATE_SUBSCRIPTION_IN_PROGRESS };
};

export const createSubscriptionSuccess = subscription => {
  return { type: CREATE_SUBSCRIPTION_SUCCESS, subscription };
};

export const createSubscriptionFailed = error => {
  return { type: CREATE_SUBSCRIPTION_FAILED, error };
};

export const updateSubscriptionInProgress = _ => {
  return { type: UPDATE_SUBSCRIPTION_IN_PROGRESS };
};

export const updateSubscriptionSuccess = subscription => {
  return { type: UPDATE_SUBSCRIPTION_SUCCESS, subscription };
};

export const updateSubscriptionFailed = error => {
  return { type: UPDATE_SUBSCRIPTION_FAILED, error };
};

export const cancelSubscriptionInProgress = _ => {
  return { type: CANCEL_SUBSCRIPTION_IN_PROGRESS };
};

export const cancelSubscriptionSuccess = subscription => {
  return { type: CANCEL_SUBSCRIPTION_SUCCESS, subscription };
};

export const cancelSubscriptionFailed = _ => {
  return { type: CANCEL_SUBSCRIPTION_FAILED };
};

export const start3DSecure = subscription => {
  return { type: START_3D_SECURE, subscription };
};

export const fetchPlansInProgress = _ => {
  return { type: FETCH_SUBSCRIPTION_PLANS_IN_PROGRESS };
};

export const fetchPlansSuccess = plans => {
  return { type: FETCH_SUBSCRIPTION_PLANS_SUCCESS, plans };
};

export const fetchPlansFailed = _ => {
  return { type: FETCH_SUBSCRIPTION_PLANS_FAILED };
};

export const fetchSubscriptionInProgress = _ => {
  return { type: FETCH_SUBSCRIPTION_IN_PROGRESS };
};

export const fetchSubscriptionSuccess = subscription => {
  return { type: FETCH_SUBSCRIPTION_SUCCESS, subscription };
};

export const fetchSubscriptionFailed = _ => {
  return { type: FETCH_SUBSCRIPTION_FAILED };
};

export const fetchBillingInfoInProgress = _ => {
  return { type: FETCH_BILLING_INFO_IN_PROGRESS };
};

export const fetchBillingInfoSuccess = billing_info => {
  return { type: FETCH_BILLING_INFO_SUCCESS, billing_info };
};

export const fetchBillingInfoFailed = _ => {
  return { type: FETCH_BILLING_INFO_FAILED };
};

export const updateBillingInfoInProgress = _ => {
  return { type: UPDATE_BILLING_INFO_IN_PROGRESS };
};

export const updateBillingInfoSuccess = billing_info => {
  return { type: UPDATE_BILLING_INFO_SUCCESS, billing_info };
};

export const updateBillingInfoFailed = _ => {
  return { type: UPDATE_BILLING_INFO_FAILED };
};

export const fetchInvoicesInProgress = _ => {
  return { type: FETCH_INVOICES_IN_PROGRESS };
};

export const fetchInvoicesSuccess = invoices => {
  return { type: FETCH_INVOICES_SUCCESS, invoices };
};

export const fetchInvoicesFailed = _ => {
  return { type: FETCH_INVOICES_FAILED };
};

export const fetchPdfInvoiceInProgress = _ => {
  return { type: FETCH_PDF_INVOICE_IN_PROGRESS };
};

export const fetchPdfInvoiceSuccess = invoice => {
  return { type: FETCH_PDF_INVOICE_SUCCESS, invoice };
};

export const fetchPdfInvoiceFailed = _ => {
  return { type: FETCH_PDF_INVOICE_FAILED };
};

export const update3DSecureErrorMessage = msg => {
  return { type: UPDATE_3DSECURE_ERROR_MESSAGE, msg };
};
