import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import throttle from 'lodash/throttle';
import App from './App';
import { IAM_COOKIE_NAME } from './settings/config';
import './index.css';
import { setCookie, defaultIamCookieAttributes, removeCookie } from './utils/cookie';
import { store, history } from './store';

const saveIamToCookie = (state) => {
	const iam = {
		access_token: state.iam.access_token,
		refresh_token: state.iam.refresh_token,
	};

	const serializedIAM = JSON.stringify(iam);

	if (iam.access_token && iam.refresh_token) {
		setCookie(IAM_COOKIE_NAME, serializedIAM, defaultIamCookieAttributes);
	} else {
		removeCookie(IAM_COOKIE_NAME, defaultIamCookieAttributes);
	}
};

store.subscribe(throttle(() => saveIamToCookie(store.getState()), 1000));

ReactDOM.render(
	<Provider store={store}>
		<App history={history} />
	</Provider>,
	document.getElementById('root')
);
