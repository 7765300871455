import React from "react";
import { Table, Button, Result, Icon, Alert } from "antd";
import moment from "moment";
import { AffectedPages } from "../../containers/BrokenLinks";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";

const formatDate = dateStr => {
  return moment(dateStr, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY, HH:mm");
};

const getBrokenLinksDataSource = brokenLinks => {
  if (brokenLinks.length) {
    return brokenLinks.map((link, idx) => ({
      key: idx + 1,
      checkedAt: formatDate(link["updated_at"]),
      brokenLink: link["url"]
    }));
  }
  return null;
};

const columns = [
  {
    title: "#",
    dataIndex: "key",
    width: "45px",
    className: "no-wrap"
  },
  {
    title: "Check date",
    className: "broken-links-check-date-column no-wrap",
    dataIndex: "checkedAt",
    key: "checkedAt",
    width: "170px"
  },
  {
    title: "Broken link",
    dataIndex: "brokenLink",
    key: "brokenLink",
    className: "broken-links-column no-wrap",
    render: (_, record) => (
      <a target="_blank" href={record["brokenLink"]} rel="noopener noreferrer">
        {record["brokenLink"]}
      </a>
    )
  },
  {
    title: "Referring pages",
    key: "affectedPages",
    className: "al-right no-wrap",
    width: "138px",
    render: (_, record) => <AffectedPages url={record["brokenLink"]} />
  }
];

function BrokenLinksTable({ type, isLoading, brokenLinks, hasMore, onLoadMore }) {
  const isMainLoaderVisible = isLoading && !brokenLinks.length;
  const hasBrokenLinks = brokenLinks.length > 0;
  const isNonIdealStateVisible = !isLoading && !brokenLinks.length;
  let alertMessage = (
    <>
      The internal URLs below were reported as <strong>broken links</strong>. These URLs are linked from other pages
      within the site, and should be fixed or removed.
    </>
  );
  if (type === "external") {
    alertMessage = (
      <>
        The external URLs below (pointing outside the website) were reported as <strong>broken links</strong>. These
        URLs are linked from other pages within the site, and should be fixed or removed.
      </>
    );
  }
  return (
    <React.Fragment>
      {isMainLoaderVisible && <LoadingIndicator />}
      {hasBrokenLinks && (
        <React.Fragment>
          <Alert
            type="info"
            description={<p style={{ marginBottom: 0 }}>{alertMessage}</p>}
            style={{ marginBottom: "2em" }}
          />

          <Table
            className="broken-links-table custom-scrollbar"
            dataSource={getBrokenLinksDataSource(brokenLinks)}
            columns={columns}
            pagination={false}
          />
          {hasMore && (
            <Button className="load-more" type="primary" loading={isLoading} onClick={onLoadMore}>
              Load More
            </Button>
          )}
        </React.Fragment>
      )}
      {isNonIdealStateVisible && (
        <Result
          icon={<Icon type="file-done" theme="outlined" />}
          title="Great job: everything looks perfect!"
          subTitle={`We didn't find ${type} broken links in any crawled page!`}
        />
      )}
    </React.Fragment>
  );
}

export default BrokenLinksTable;
