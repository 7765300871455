import React from "react";
import * as SeoReport from "../sections";
import { BasicSection } from "../sections";

const SeoReportSection = ({ url, sectionKey, title, tooltip, relevance, passRate, result, competitorResults }) => {
  const Map = {
    "google-analytics": BasicSection,
    "html-size": BasicSection,
    "html-compression": BasicSection,
    "loading-speed": BasicSection,
    "page-cache": BasicSection,
    "nested-tables": BasicSection,
    frameset: BasicSection,
    "url-canonicalization": BasicSection,
    "safe-browsing": BasicSection,
    "directory-browsing": BasicSection,
    "plaintext-emails": BasicSection,
    "media-queries": BasicSection,
    "custom-404": BasicSection,
    "title-tag": SeoReport.TitleTagSection,
    "description-tag": SeoReport.DescriptionTagSection,
    "google-preview": SeoReport.GooglePreviewSection,
    "common-keywords": SeoReport.CommonKeywordsSection,
    "common-keywords-usage": SeoReport.CommonKeywordsUsageSection,
    "keywords-cloud": SeoReport.KeywordsCloudSection,
    "related-keywords": SeoReport.RelatedKeywordsSection,
    "competitor-domains": SeoReport.CompetitorDomainsSection,
    "heading-tags": SeoReport.HeadingTagsSection,
    "robots-txt": SeoReport.RobotsTxtSection,
    sitemap: SeoReport.SitemapSection,
    "url-seo-friendly": SeoReport.UrlSeoFriendlySection,
    "img-alt": SeoReport.ImgAltSection,
    "inline-css": SeoReport.InlineCssSection,
    "deprecated-tags": SeoReport.DeprecatedTagsSection,
    favicon: SeoReport.FaviconSection,
    backlinks: SeoReport.BacklinksSection,
    "js-errors": SeoReport.JsErrorsSection,
    "social-media": SeoReport.SocialMediaSection,
    "page-objects": SeoReport.PageObjectsSection,
    flash: SeoReport.FlashSection,
    "cdn-usage": SeoReport.CdnUsageSection,
    "image-caching": SeoReport.ImageCachingSection,
    "js-caching": SeoReport.JsCachingSection,
    "css-caching": SeoReport.CssCachingSection,
    "js-minification": SeoReport.JsMinificationSection,
    "css-minification": SeoReport.CssMinificationSection,
    doctype: SeoReport.DoctypeSection,
    "http-redirects": SeoReport.HttpRedirectsSection,
    "https-encryption": SeoReport.HttpsEncryptionSection,
    "server-signature": SeoReport.ServerSignatureSection,
    "mobile-snapshot": SeoReport.MobileSnapshotSection,
    "structured-data": SeoReport.StructuredDataSection,
    "noindex-tags": SeoReport.NoindexTagsSection,
    "canonical-tags": SeoReport.CanonicalTagsSection,
    "nofollow-tags": SeoReport.NofollowTagsSection,
    "disallow-tags": SeoReport.DisallowTagsSection,
    "spf-record": SeoReport.SpfRecordSection,
    http2: SeoReport.Http2Section,
    "console-errors": SeoReport.ConsoleErrorsSection,
    viewport: SeoReport.MetaViewportSection,
    "mixed-content-type": SeoReport.MixedContentTypeSection,
    "image-size": SeoReport.ImageSizeSection,
    "image-aspect-ratio": SeoReport.ImageAspectRatioSection,
    "meta-refresh": SeoReport.MetaRefreshSection,
    "meta-charset": SeoReport.MetaCharsetSection,
    "dom-size": SeoReport.BasicSection,
    "js-execution-time": SeoReport.BasicSection,
    "cross-origin-links": SeoReport.CrossOriginLinksSection,
    "social-media-meta-tags": SeoReport.SocialMediaMetaTagsSection,
    "next-gen-image-format": SeoReport.NextGenImageFormatSection,
    "render-blocking-resources": SeoReport.RenderBlockingResourcesSection,
    "ads-txt": SeoReport.AdsTxtSection,
    "spell-checker": SeoReport.SpellCheckerSection,
    "img-metadata": SeoReport.ImgMetadataSection,
    "web-vitals-cls": SeoReport.CumulativeLayoutShiftSection,
    "web-vitals-lcp": SeoReport.LargestContentfulPaintSection,
    hsts: SeoReport.HstsSection,
    "web-vitals-fcp": SeoReport.FirstContentfulPaintSection,
    "web-vitals-ttfb": SeoReport.TimeToFirstByteSection
  };

  let Tagname = null;
  if (sectionKey in Map) {
    Tagname = Map[sectionKey];
  }

  return (
    Tagname && (
      <Tagname
        data-cy={sectionKey}
        url={url}
        key={sectionKey}
        sectionKey={sectionKey}
        title={title}
        tooltip={tooltip}
        relevance={relevance}
        passRate={passRate}
        result={result}
        competitorResults={competitorResults}
      />
    )
  );
};

export default SeoReportSection;
