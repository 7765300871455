import React from "react";
import { Row, Col } from "antd";
import TemplateForm from "./TemplateForm";
import Template from "./Template";
import UserTemplatesContainer from "../../../containers/WhiteLabel/UserTemplatesContainer";
import "./BrandedPdfReports.less";

function CustomTemplateLayout(props) {
  if (props.idUrlParam === undefined) {
    return <UserTemplatesContainer />;
  }

  return (
    <div className="white-label-template-layout">
      <Row className="pdf-template" gutter={[16, 16]} type="flex">
        <Col span={12} className="form-col">
          <TemplateForm
            isLoading={props.isLoading}
            sendTemplateData={props.sendTemplateData}
            selectedTemplateGuid={props.selectedTemplateGuid}
            selectedTemplate={props.selectedTemplate}
            addTemplate={props.addTemplate}
            editTemplate={props.editTemplate}
            changeSelectedTemplate={props.changeSelectedTemplate}
            push={props.push}
            idUrlParam={props.idUrlParam}
            deleteTemplate={props.deleteTemplate}
          />
        </Col>
        <Col span={12} className="preview-col">
          <Template
            logo={props.logo}
            header={props.header}
            footer={props.footer}
            url={props.url}
            seoScore={props.seoScore}
            sectionsStatusCounter={props.sectionsStatusCounter}
            sectionsStatusProgress={props.sectionsStatusProgress}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CustomTemplateLayout;
