import React from 'react';
import { Icon, Table, Alert } from 'antd';
import LoadingIndicator from '../../../components/shared/LoadingIndicator/LoadingIndicator';
import { TrackedWebsite } from '../../../trackedWebsites/types';
import { useDeleteTrackedKeywordMutation, useFetchTrackedKeywordsQuery } from '../../../trackedKeywords/service';
import { TrackedKeywordsTableRecord } from '../../../trackedKeywords/types';
import { getTableDataFromKeywords } from '../../../trackedKeywords/utils';

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function KeywordsTable({ selectedWebsite }: Props) {
    const { currentData: trackedKeywords, isLoading: isLoadingTrackedKeywords } = useFetchTrackedKeywordsQuery({ domain: selectedWebsite.domain });
    const [deleteKeyword, { isLoading, originalArgs }] = useDeleteTrackedKeywordMutation();

    const handleDelete = (keyword: string) => {
        deleteKeyword({ keyword: keyword, domain: selectedWebsite.domain });
    };

    const columns = [
        {
            dataIndex: 'key',
            width: '54px',
        },
        {
            dataIndex: 'keyword',
            key: 'keyword',
            className: 'keywords',
        },
        {
            dataIndex: 'deleteKeyword',
            key: 'deleteKeyword',
            align: 'right' as const,
            render: (_: any, record: TrackedKeywordsTableRecord) => (
                <span
                    className={'remove-item' + (isLoading && record.keyword.localeCompare(originalArgs!.keyword) === 0 ? ' in-progress' : '')}
                    onClick={() => handleDelete(record.keyword)}
                >
                    {isLoading && record.keyword.localeCompare(originalArgs!.keyword) === 0 ? <Icon type="loading" /> : <Icon type="delete" />}
                </span>
            ),
        },
    ];

    if (isLoadingTrackedKeywords || !trackedKeywords) {
        return <LoadingIndicator />;
    } else if (trackedKeywords.tracked_keywords.length === 0) {
        return <Alert message="You haven't set any keywords to track for this domain!" />;
    } else {
        return (
            <div className="dashboard-settings-tracked-keywords-table">
                <Table
                    columns={columns}
                    dataSource={getTableDataFromKeywords(trackedKeywords.tracked_keywords)}
                    showHeader={false}
                    pagination={false}
                />
            </div>
        );
    }
}
