import {
  GET_MONITOR_LOCATIONS_IN_PROGRESS,
  GET_MONITOR_LOCATIONS_SUCCESS,
  GET_MONITOR_LOCATIONS_FAILED
} from "./types";

export const getMonitorLocationsInProgress = _ => ({
  type: GET_MONITOR_LOCATIONS_IN_PROGRESS
});

export const getMonitorLocationsSuccess = (regions, order) => ({
  type: GET_MONITOR_LOCATIONS_SUCCESS,
  regions,
  order
});

export const getMonitorLocationsFailed = _ => ({
  type: GET_MONITOR_LOCATIONS_FAILED
});
