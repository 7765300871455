import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import { Icon } from "antd";

const iconForStatus = status => {
  if (status === "passed") return "check-circle";
  if (status === "warning") return "exclamation-circle";
  return "close-circle";
};

const displayResult = (url, title, result) => (
  <React.Fragment key={url}>
    <div className="description-content">
      {url ? (
        <div className="flexbox align-center">
          <Icon type={iconForStatus(result.status)} theme="filled" className="status-icon" />
          <span className="section-result-url">{url}</span>
        </div>
      ) : null}
      <ul>
        <li>
          {result.favicon_url ? (
            <div className="favicon-image">
              <img src={result.favicon_url} alt="favicon" />
            </div>
          ) : null}
          {result.message}
        </li>
      </ul>
    </div>

    <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
  </React.Fragment>
);

const displayCompetitorResult = result => (
  <div className="description-content">
    <ul>
      <li>
        {result.favicon_url && (
          <div className="favicon-image">
            <img src={result.favicon_url} alt="favicon" />
          </div>
        )}
        {result.message}
      </li>
    </ul>
  </div>
);

const FaviconSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        {hasCompetitors ? displayResult(url, title, result) : displayResult(null, title, result)}
        <SectionCompetitorResults
          results={competitorResults}
          relevance={relevance}
          render={displayCompetitorResult}
          hideMessage={true}
        />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default FaviconSection;
