import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";

const displayResult = (title, result) => {
  if (result.status === "passed") {
    return result.social_media_networks.map(socialMedia => (
      <a href={socialMedia.url} key={socialMedia.name} target="_blank" rel="noopener noreferrer">
        {socialMedia.name}&nbsp;
      </a>
    ));
  }
  return <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />;
};

const displayCompetitorResult = result => {
  if (result.status === "passed") {
    return result.social_media_networks.map(socialMedia => (
      <a href={socialMedia.url} key={socialMedia.name} target="_blank" rel="noopener noreferrer">
        {socialMedia.name}&nbsp;
      </a>
    ));
  }
  return null;
};

const SocialMediaSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayCompetitorResult} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default SocialMediaSection;
