import * as types from "./types";

export const authenticationInProgress = (_) => ({
    type: types.AUTHENTICATION_IN_PROGRESS
});

export const authenticationSuccessful = (access_token, refresh_token) => ({
    type: types.AUTHENTICATION_SUCCESSFUL,
    access_token: access_token,
    refresh_token: refresh_token
});

export const authenticationFailed = (_) => ({
    type: types.AUTHENTICATION_FAILED
});

export const registrationInProgress = (_) => ({
    type: types.REGISTRATION_IN_PROGRESS
});

export const registrationSuccessful = (_) => ({
    type: types.REGISTRATION_SUCCESSFUL
});

export const registrationFailed = (_) => ({
    type: types.REGISTRATION_FAILED
});

export const registrationAndLoginIsFinished = (_) => ({
    type: types.REGISTRATION_AND_LOGIN_IS_FINISHED
});

export const deleteUserInProgress = (_) => ({
    type: types.DELETE_USER_IN_PROGRESS
});

export const deleteUserSuccessful = (_) => ({
    type: types.DELETE_USER_SUCCESSFUL
});

export const deleteUserFailed = (_) => ({
    type: types.DELETE_USER_FAILED
});

export const passwordResetRequestInProgress = (_) => ({
    type: types.PASSWORD_RESET_REQUEST_IN_PROGRESS
});

export const passwordResetRequestSuccess = (_) => ({
    type: types.PASSWORD_RESET_REQUEST_SUCCESS
});

export const passwordResetRequestFailed = (_) => ({
    type: types.PASSWORD_RESET_REQUEST_FAILED
});

export const userIsLoading = (_) => ({
    type: types.USER_IS_LOADING
});

export const getUser = (user) => ({
    type: types.GET_USER,
    user: user
});

export const userIsNotAvailable = (_) => ({
    type: types.USER_IS_NOT_AVAILABLE
});

export const logout = (_) => ({
    type: types.LOGOUT
});
export const clearIamState = (_) => ({
    type: types.CLEAR_STATE
});
export const changeUserNameInProgress = (_) => ({
    type: types.CHANGE_USER_NAME_IN_PROGRESS
});

export const changeUserNameSuccessful = (_) => ({
    type: types.CHANGE_USER_NAME_SUCCESSFUL
});

export const changeUserNameFailed = (_) => ({
    type: types.CHANGE_USER_NAME_FAILED
});

export const changePasswordInProgress = (_) => ({
    type: types.CHANGE_PASSWORD_IN_PROGRESS
});

export const changePasswordSuccessful = (_) => ({
    type: types.CHANGE_PASSWORD_SUCCESSFUL
});

export const changePasswordFailed = (_) => ({
    type: types.CHANGE_PASSWORD_FAILED
});

export const confirmPasswordInProgress = (_) => ({
    type: types.CONFIRM_PASSWORD_IN_PROGRESS
});

export const confirmPasswordSuccessful = (_) => ({
    type: types.CONFIRM_PASSWORD_SUCCESSFUL
});

export const confirmPasswordFailed = (_) => ({
    type: types.CONFIRM_PASSWORD_FAILED
});

export const updateAccessTokenInProgress = (_) => ({
    type: types.UPDATE_ACCESS_TOKEN_IN_PROGRESS
});

export const updateAccessTokenSuccess = (accessToken) => ({
    type: types.UPDATE_ACCESS_TOKEN_SUCCESS,
    access_token: accessToken
});

export const updateAccessTokenFailed = (_) => ({
    type: types.UPDATE_ACCESS_TOKEN_FAILED
});

export const updateRequiredExperiencesInProgress = (_) => ({
    type: types.UPDATE_REQUIRED_EXPERIENCES_IN_PROGRESS
});

export const updateRequiredExperiencesSuccessful = (updatedValue) => ({
    type: types.UPDATE_REQUIRED_EXPERIENCES_SUCCESSFUL,
    updatedValue: updatedValue
});

export const updateRequiredExperiencesFailed = (_) => ({
    type: types.UPDATE_REQUIRED_EXPERIENCES_FAILED
});

export const createOrganizationInProgress = (_) => ({
    type: types.CREATE_ORGANIZATION_IN_PROGRESS
});

export const createOrganizationSuccessful = (response) => ({
    type: types.CREATE_ORGANIZATION_SUCCESSFUL,
    organization: response.organization,
    teamMembers: response.members,
    teamInvitations: response.invitations
});

export const createOrganizationFailed = (_) => ({
    type: types.CREATE_ORGANIZATION_FAILED
});

export const getOrganizationInProgress = (_) => ({
    type: types.GET_ORGANIZATION_IN_PROGRESS
});

export const getOrganizationSuccessful = (response) => ({
    type: types.GET_ORGANIZATION_SUCCESSFUL,
    organization: response.organization,
    teamMembers: response.members,
    teamInvitations: response.invitations
});

export const getOrganizationFailed = (_) => ({
    type: types.GET_ORGANIZATION_FAILED
});

// export const inviteTeamMemberInProgress = (_) => ({
//   type: types.INVITE_TEAM_MEMBER_IN_PROGRESS
// });

// export const inviteTeamMemberSuccessful = (_) => ({
//   type: types.INVITE_TEAM_MEMBER_SUCCESSFUL
// });

// export const inviteTeamMemberFailed = (_) => ({
//   type: types.INVITE_TEAM_MEMBER_FAILED
// });

export const updateOrganizationInProgress = (_) => ({
    type: types.UPDATE_ORGANIZATION_IN_PROGRESS
});

export const updateOrganizationSuccessful = (response) => ({
    type: types.UPDATE_ORGANIZATION_SUCCESSFUL,
    organization: response
});

export const updateOrganizationFailed = (_) => ({
    type: types.UPDATE_ORGANIZATION_FAILED
});

export const deleteTeamMemberInProgress = (token) => ({
    type: types.DELETE_TEAM_MEMBER_IN_PROGRESS,
    token: token
});

export const deleteTeamMemberSuccessful = (token) => ({
    type: types.DELETE_TEAM_MEMBER_SUCCESSFUL,
    token: token
});

export const deleteTeamMemberFailed = (token) => ({
    type: types.DELETE_TEAM_MEMBER_FAILED,
    token: token
});

// export const getTeamMembersInProgress = (_) => ({
//   type: types.GET_TEAM_MEMBERS_IN_PROGRESS
// });

// export const getTeamMembersSuccessful = (response) => ({
//   type: types.GET_TEAM_MEMBERS_SUCCESSFUL,
//   organization: response.organization,
//   teamMembers: response.members,
//   invitations: response.invitations
// });

// export const getTeamMembersFailed = (_) => ({
//   type: types.GET_TEAM_MEMBERS_FAILED
// });
