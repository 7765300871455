import React from "react";
import { Divider, Row, Col, Card, Skeleton, Icon } from "antd";
import SeoScore from "../../SeoReport/SeoScore";
import CategoryStats from "../../SeoReport/CategoryStats/CategoryStats";
import Header from "./Header";
import Footer from "./Footer";
import { REPORT_RENDER_ENGINE_URL } from "../../../settings/config";
import "./CustomPdf.less";

const { Meta } = Card;

function Template(props) {
  return (
    <div className="custom-pdf">
      <Header logo={props.logo} header={props.header} />
      {!props.selectedReportUrl ? (
        <div className="default-template">
          <Card title={<Meta title={props.url} description="SEO Report" />}>
            <SeoScore
              seoScore={props.seoScore}
              sectionsStatusCounter={props.sectionsStatusCounter}
              sectionsStatusProgress={props.sectionsStatusProgress}
            />
          </Card>

          <Card
            className="seo-issues"
            title="Common SEO issues"
            extra={<CategoryStats passed_status_total={13} warning_status_total={5} failed_status_total={2} />}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10} className="flexbox align-center">
                <Icon type="check-circle" theme="filled" className="passed status-icon" />
                <Skeleton title={true} paragraph={false} />
              </Col>
              <Col span={14}>
                <Skeleton title={false} paragraph={{ rows: 2 }} />
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10} className="flexbox align-center">
                <Icon type={"close-circle"} theme="filled" className="failed status-icon" />
                <Skeleton title={true} paragraph={false} />
              </Col>
              <Col span={14}>
                <Skeleton title={false} paragraph={{ rows: 2 }} />
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10} className="flexbox align-center">
                <Icon type="exclamation-circle" theme="filled" className="warning status-icon" />
                <Skeleton title={true} paragraph={false} />
              </Col>
              <Col span={14}>
                <Skeleton title={false} paragraph={{ rows: 2 }} />
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10} className="flexbox align-center">
                <div className="status-icon default-icon"></div>
                <Skeleton title={true} paragraph={false} />
              </Col>
              <Col span={14}>
                <Skeleton title={false} paragraph={{ rows: 2 }} />
              </Col>
            </Row>
          </Card>
        </div>
      ) : (
        <div className="iframe-wrapper">
          <iframe
            id="test"
            height="450px"
            width="605px"
            frameBorder="0"
            title="unique-title-to-be-added"
            src={`${REPORT_RENDER_ENGINE_URL}?report_guid=${props.selectedReportGuid || "latest"}&report_url=${
              props.selectedReportUrl
            }&template_guid=${props.selectedTemplateGuid}&user_guid=${props.userGuid}&output_type=pdf`}></iframe>
        </div>
      )}
      <Divider />
      <Footer footerMessage={props.footer} />
    </div>
  );
}

export default Template;
