import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  getDefaultPlan,
  getSubscriptionPlans,
  userSubscription,
  isUpdatingSubscription,
  isCancelingSubscription
} from "../../store/sales/selectors";
import { fetchSubscriptionPlans, cancelSubscription, updateSubscription } from "../../store/sales/operations";
import { SubscriptionPlans as PlansLayout } from "../../components/Sales/SubscriptionPlans";

class SubscriptionPlans extends Component {
  componentDidMount() {
    this.props.fetchSubscriptionPlans();
  }

  render() {
    return (
      <PlansLayout
        includeHeader={true}
        defaultPlan={this.props.defaultPlan}
        plans={this.props.plans}
        subscription={this.props.subscription}
        onCancelSubscription={this.props.cancelSubscription}
        onUpdateSubscription={this.props.updateSubscription}
        isCancelingSubscription={this.props.isCancelingSubscription}
        isUpdatingSubscription={this.props.isUpdatingSubscription}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = state => ({
  defaultPlan: getDefaultPlan(state),
  plans: getSubscriptionPlans(state),
  subscription: userSubscription(state),
  isCancelingSubscription: isCancelingSubscription(state),
  isUpdatingSubscription: isUpdatingSubscription(state)
});

export default connect(mapStateToProps, {
  fetchSubscriptionPlans,
  cancelSubscription,
  updateSubscription,
  push
})(SubscriptionPlans);
