import React from 'react';
import { Icon, Spin } from 'antd';

const KeywordPosition = ({ status, position, previousPosition }) => {
	if (status) {
		return <Spin indicator={<Icon type="loading" spin />} />;
	}

	if (position === null || typeof position === 'undefined') {
		return <span className="keyword-position"> - </span>;
	}

	if (position > 100) {
		return <span className="keyword-position">100</span>;
	}

	if (previousPosition > position) {
		return (
			<span className="keyword-position">
				<Icon type="caret-up" style={{ color: '#0A901B' }} /> {position}
				<span className="trend">{` (+${previousPosition - position})`}</span>
			</span>
		);
	}

	if (previousPosition < position && previousPosition > 0) {
		return (
			<span className="keyword-position">
				<Icon type="caret-down" style={{ color: '#B9160D' }} /> {position}
				<span className="trend">{` (-${position - previousPosition})`}</span>
			</span>
		);
	}

	return (
		<span className="keyword-position">
			<Icon type="swap" /> {position}
		</span>
	);
};

export default KeywordPosition;
