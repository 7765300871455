import { getOverviewsForUrl, isLastSavedReportFetched } from "../analysis/selectors";
import moment from "moment";

export const isGettingTrackedWebsites = (state) =>
    state.tracked_websites.network_activity.includes("get_tracked_websites");

export const isFetchingTrackedWebsitesAndOverviews = (state) =>
    state.tracked_websites.network_activity.includes("get_tracked_websites_and_overviews");

export const isAddingTrackedWebsite = (state) =>
    state.tracked_websites.network_activity.includes("add_tracked_website");

export const getSelectedWebsiteGuid = (state) => {
    if (state.tracked_websites && state.tracked_websites.selected_website) {
        return state.tracked_websites.selected_website;
    }
    return "";
};

export const trackedWebsites = (state) => state.tracked_websites.data;

export const getSelectedWebsiteData = (state) => {
    let selectedWebsiteGuid = getSelectedWebsiteGuid(state);
    return state.tracked_websites.data.filter((website) => website.guid === selectedWebsiteGuid);
};

export const getSelectedWebsiteDomain = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (selectedWebsiteData.length) {
        return selectedWebsiteData[0]["domain"];
    }
};

export const getSelectedWebsiteRegionalDatabase = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return null;
    } else {
        return selectedWebsiteData[0].settings.seo_db;
    }
};

export const getSelectedWebsiteLocation = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return null;
    } else {
        return selectedWebsiteData[0].settings.location_name;
    }
};

export const getSelectedWebsiteLanguage = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return null;
    } else {
        return selectedWebsiteData[0].settings.language_code;
    }
};

export const getSelectedWebsiteRegions = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return [];
    } else {
        return selectedWebsiteData[0].settings.regions;
    }
};

export const getSelectedWebsiteUptimeNotifications = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);

    if (!selectedWebsiteData.length) {
        return [];
    } else {
        if (!("notifications" in selectedWebsiteData[0].settings)) {
            //console.log("not set!");
            return ["ping_uptime", "http_uptime", "page_load_timeout"];
        }
        return selectedWebsiteData[0].settings.notifications;
    }
};

export const getSelectedWebsiteMainLocation = (state) => {
    return getSelectedWebsiteRegions(state)[0];
};

export const getSelectedWebsiteUrl = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return null;
    } else {
        return selectedWebsiteData[0].url;
    }
};

export const getTrackedWebsiteGuid = (state, url) => {
    let websites = trackedWebsites(state);
    let found = websites.find((site) => site["url"] === url);
    return "guid" in found ? found["guid"] : null;
};

export const getTrackedWebsiteCompetitors = (state) => {
    let selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return [];
    } else {
        return selectedWebsiteData[0].settings.competitors;
    }
};

export const getTrackedWebsitesDomains = (state) => {
    let data = state.tracked_websites.data;
    return data.map((trackedWebsite) => trackedWebsite["domain"]);
};

export const getTrackedWebsitesUrls = (state) => {
    let data = state.tracked_websites.data;
    return data.map((trackedWebsite) => trackedWebsite["url"]);
};

export const isDeletingTrackedWebsite = (state, url) => {
    return state.tracked_websites.network_activity.includes(`delete_website_${url}`);
};

export const isChangingTrackedWebsiteSettings = (state) => {
    let selectedWebsiteGuid = getSelectedWebsiteGuid(state);
    return state.tracked_websites.network_activity.includes(`change_tracked_website_settings_${selectedWebsiteGuid}`);
};

export const isAddingCompetitor = (state) => {
    let selectedWebsiteGuid = getSelectedWebsiteGuid(state);
    return state.tracked_websites.network_activity.includes(
        `change_tracked_website_settings_${selectedWebsiteGuid}_addCompetitor`
    );
};

export const getCompetitorBeingDeleted = (state) => {
    const competitors = getTrackedWebsiteCompetitors(state);
    let selectedWebsiteGuid = getSelectedWebsiteGuid(state);
    return competitors.reduce((acc, competitor) => {
        if (
            state.tracked_websites.network_activity.includes(
                `change_tracked_website_settings_${selectedWebsiteGuid}_removeCompetitor_${competitor}`
            )
        ) {
            acc.push(competitor);
        }
        return acc;
    }, []);
};

export const isUpdatingLocations = (state) => {
    let selectedWebsiteGuid = getSelectedWebsiteGuid(state);
    return state.tracked_websites.network_activity.includes(
        `change_tracked_website_settings_${selectedWebsiteGuid}_changeLocations`
    );
};

export const getTrackedWebsitesNumber = (state) => {
    return trackedWebsites(state).length;
};

export const getLastAddedWebsite = (state) => {
    return state["tracked_websites"]["last_added_website"];
};

export const isWebsiteLastAddedAndHasNoSeoReportData = (state) => {
    const lastAddedWebsite = getLastAddedWebsite(state);
    const currentWebsite = getSelectedWebsiteGuid(state);
    const isLastAddedWebsite = lastAddedWebsite && currentWebsite === lastAddedWebsite["guid"];
    const currentWebsiteUrl = getSelectedWebsiteUrl(state);
    const overviews = getOverviewsForUrl(state, currentWebsiteUrl);
    const isLastSavedReportAvailable = isLastSavedReportFetched(state, currentWebsiteUrl);
    const hasSeoReportData = overviews.length > 0 && isLastSavedReportAvailable;
    if (isLastAddedWebsite && !hasSeoReportData) {
        return true;
    }
    return false;
};

export const getFaviconsUrls = (state) => {
    const urls = getTrackedWebsitesUrls(state);
    let faviconsUrls = {};
    urls.forEach((url) => {
        const overviews = getOverviewsForUrl(state, url);
        const reportGuid = overviews.length > 0 ? overviews[overviews.length - 1]["guid"] : null;

        faviconsUrls[url] = reportGuid ? `https://d1r7943vfkqpts.cloudfront.net/${reportGuid}.png` : null;
    });
    return faviconsUrls;
};

export const getSelectedWebsiteCreatedAt = (state) => {
    const selectedWebsiteData = getSelectedWebsiteData(state);
    if (!selectedWebsiteData.length) {
        return null;
    } else {
        return selectedWebsiteData[0].c_at;
    }
};

export const isWebsiteAddedInTheLastHour = (state) => {
    const selectedWebsiteCreatedAt = getSelectedWebsiteCreatedAt(state);
    let hours = moment.utc().diff(moment.utc(selectedWebsiteCreatedAt), "hours");
    return hours < 1;
};
