const resetPasswordRules = {
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: "^Minimum 6 characters"
    }
  },
  repeatPassword: {
    equality: {
      attribute: "password",
      message: "^Passwords need to match"
    }
  }
};

export default resetPasswordRules;
