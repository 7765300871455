import {
  FETCH_OUTAGE_LIST_IN_PROGRESS,
  FETCH_OUTAGE_LIST_SUCCESS,
  FETCH_OUTAGE_LIST_FAILED,
  FETCH_OUTAGE_CONTEXT_IN_PROGRESS,
  FETCH_OUTAGE_CONTEXT_SUCCESS,
  FETCH_OUTAGE_CONTEXT_FAILED,
  RESET_SELECTED_OUTAGE
} from "./types";

export const fetchOutageListInProgress = url => {
  return { type: FETCH_OUTAGE_LIST_IN_PROGRESS, url };
};

export const fetchOutageListSuccess = (url, outages) => {
  return { type: FETCH_OUTAGE_LIST_SUCCESS, url, outages };
};

export const fetchOutageListFailed = url => {
  return { type: FETCH_OUTAGE_LIST_FAILED, url };
};

export const fetchOutageContextInProgress = (guid, selectedUrl) => {
  return { type: FETCH_OUTAGE_CONTEXT_IN_PROGRESS, guid, selectedUrl };
};

export const fetchOutageContextSuccess = (guid, context) => {
  return { type: FETCH_OUTAGE_CONTEXT_SUCCESS, guid, context };
};

export const fetchOutageContextFailed = (guid, selectedUrl) => {
  return { type: FETCH_OUTAGE_CONTEXT_FAILED, guid, selectedUrl };
};

export const resetSelectedOutage = url => {
  return { type: RESET_SELECTED_OUTAGE, url };
};
