import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { validate } from "validate.js";
import { passwordValidationRules } from "./validators";
import PasswordConfirmation from "../../containers/UserAccount/PasswordConfirmation";
import "./UserAccountForm.less";

export class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
      repeatPassword: "",
      validationErrors: {},
      showForm: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handlePasswordConfirmed = this.handlePasswordConfirmed.bind(this);
  }

  validateForm() {
    return validate(
      {
        newPassword: this.state.newPassword,
        repeatPassword: this.state.repeatPassword
      },
      passwordValidationRules
    );
  }

  handleInputChange(event) {
    const newState = {};
    const value = event.target.value;
    newState[event.target.name] = value;
    this.setState(newState);
  }

  handleInputFocus(event) {
    if (this.state.validationErrors[event.target.name]) {
      const newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
      this.setState(newState);
    }
  }

  handlePasswordConfirmed() {
    this.setState({ showForm: true });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    let validationErrors = this.validateForm();

    if (!validationErrors) {
      this.setState({ validationErrors: {} });
      this.props.onSubmit(this.state.newPassword);
      event.currentTarget.getElementsByTagName("button")[0].blur();
    } else {
      this.setState({ validationErrors });
      event.currentTarget.getElementsByTagName("button")[0].blur();
    }
  }

  render() {
    const errors = this.state.validationErrors;
    return (
      <div className="user-account-card security">
        {this.state.showForm ? <h3>Change your password</h3> : <h3>First, we need to check your current password</h3>}
        {this.state.showForm ? (
          <Form data-cy="change-password-form" onSubmit={this.handleFormSubmit}>
            <Form.Item
              validateStatus={errors.newPassword && errors.newPassword[0] ? "error" : "validating"}
              help={errors.newPassword && errors.newPassword[0]}
              label="New Password">
              <Input
                placeholder="Password"
                name="newPassword"
                value={this.state.newPassword}
                type="password"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Form.Item>

            <Form.Item
              validateStatus={errors.repeatPassword && errors.repeatPassword[0] ? "error" : "validating"}
              help={errors.repeatPassword && errors.repeatPassword[0]}
              label="Repeat New Password">
              <Input
                placeholder="Password"
                name="repeatPassword"
                value={this.state.repeatPassword}
                type="password"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.props.isLoadingUser}
                loading={this.props.isLoading}>
                Change password
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <PasswordConfirmation buttonLabel={"Verify password"} onPasswordConfirmed={this.handlePasswordConfirmed} />
        )}
      </div>
    );
  }
}

export default Security;
