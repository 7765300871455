import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { validate } from "validate.js";
import { userNameValidationRules } from "./validators";
import "./UserAccountForm.less";

export class UserName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      validationErrors: {}
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.firstName !== prevProps.firstName ||
      this.props.lastName !== prevProps.lastName
    ) {
      this.setState({ email: this.props.email, firstName: this.props.firstName, lastName: this.props.lastName });
    }
  }

  validateForm() {
    return validate(
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName
      },
      userNameValidationRules
    );
  }

  handleInputChange(event) {
    const newState = {};
    const value = event.target.value;
    newState[event.target.name] = value;
    this.setState(newState);
  }

  handleInputFocus(event) {
    if (this.state.validationErrors[event.target.name]) {
      const newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
      this.setState(newState);
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();
    let validationErrors = this.validateForm();

    if (!validationErrors) {
      this.setState({ validationErrors: {} });
      this.props.onSubmit(this.state.firstName, this.state.lastName);
    } else {
      this.setState({ validationErrors });
    }
  }

  render() {
    const errors = this.state.validationErrors;
    return (
      <div className="user-account-card edit-user-info">
        <Form onSubmit={this.handleFormSubmit}>
          <Form.Item label="Email Address">
            <Input name="email" value={this.state.email} disabled={true} />
          </Form.Item>
          <Form.Item
            validateStatus={errors.firstName && errors.firstName[0] ? "error" : "validating"}
            help={errors.firstName && errors.firstName[0]}
            label="First Name">
            <Input
              placeholder="First Name here"
              name="firstName"
              value={this.state.firstName}
              type="text"
              onFocus={this.handleInputFocus}
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.lastName && errors.lastName[0] ? "error" : "validating"}
            help={errors.lastName && errors.lastName[0]}
            label="Last Name">
            <Input
              placeholder="Last Name here"
              name="lastName"
              value={this.state.lastName}
              type="text"
              onFocus={this.handleInputFocus}
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={this.props.isLoadingUser}
              loading={this.props.isLoading}>
              Save Information
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default UserName;
