import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { v4 as uuidv4 } from "uuid";
import SummaryBox from "../../components/Dashboard/Summary/SummaryBox";
import {
  getSelectedWebsiteUrl,
  getSelectedWebsiteDomain,
  getTrackedWebsiteCompetitors,
  getSelectedWebsiteMainLocation
} from "../../store/tracked_websites/selectors";
import {
  isFetchingOverviews,
  getSeoScoresForUrl,
  getLastSavedReportCreatedAt,
  getLastSavedReportGuid
} from "../../store/analysis/selectors";
import { isFetchingBacklinksOverviewRTQ, getTotalBacklinksRTQ } from "../../store/backlinks/selectors";
import {
  isFetchingTopKeywordsOverviewRTQ,
  getTopKeywordsOverviewRTQ,
  hasTopKeywordsOverviewRTQ
} from "../../store/top_keywords/selectors";
import { isFetchingLoadingData, getLoadingTimePerRegion } from "../../store/loading_time/selectors";
import { isLoadingList, getContext, hasOutageData } from "../../store/outage/selectors";
import { initAnalysis } from "../../store/analysis/operations";

export class SummaryContainer extends Component {
  generateNewReport = () => {
    const tempGuid = uuidv4();
    this.props.push("/analysis/report");
    this.props.initAnalysis(tempGuid, this.props.url, this.props.competitors, this.props.sections);
  };

  render() {
    return (
      <SummaryBox
        url={this.props.url}
        isFetching={
          this.props.isFetchingOverviews ||
          this.props.isFetchingBacklinksOverview ||
          this.props.isFetchingTopKeywordsOverview ||
          this.props.isFetchingLoadingData ||
          this.props.isFetchingSiteOutage
        }
        seoScores={this.props.seoScores}
        hasSeoScores={this.props.seoScores > 0}
        totalBacklinks={this.props.totalBacklinks}
        hasBacklinks={this.props.totalBacklinks.length > 0}
        topKeywordsAvgPos={this.props.topKeywordsOverview["avg_pos"]}
        hasTopKeywordsOverview={this.props.hasTopKeywordsOverview}
        loadingTimeData={this.props.loadingTimeDataForMainLocation}
        hasLoadingTime={this.props.loadingTimeDataForMainLocation.length > 0}
        currentContext={this.props.currentContext}
        hasOutageData={this.props.hasOutageData}
        lastUpdatedAt={this.props.lastUpdatedAt}
        lastSavedReportGuid={this.props.lastSavedReportGuid}
        generateNewReport={this.generateNewReport}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const url = getSelectedWebsiteUrl(state);
  const domain = getSelectedWebsiteDomain(state);
  const { startDate, endDate } = ownProps;

  return {
    url: url,
    domain: domain,
    competitors: getTrackedWebsiteCompetitors(state),
    isFetchingOverviews: isFetchingOverviews(state, url),
    isFetchingBacklinksOverview: isFetchingBacklinksOverviewRTQ(state, domain),
    isFetchingTopKeywordsOverview: isFetchingTopKeywordsOverviewRTQ(state),
    isFetchingLoadingData: isFetchingLoadingData(state),
    isFetchingSiteOutage: isLoadingList(state),
    seoScores: getSeoScoresForUrl(state, url),
    totalBacklinks: getTotalBacklinksRTQ(state, domain, startDate, endDate),
    topKeywordsOverview: getTopKeywordsOverviewRTQ(state),
    hasTopKeywordsOverview: hasTopKeywordsOverviewRTQ(state),
    loadingTimeDataForMainLocation: getLoadingTimePerRegion(state, getSelectedWebsiteMainLocation(state)),
    lastUpdatedAt: getLastSavedReportCreatedAt(state, url),
    lastSavedReportGuid: getLastSavedReportGuid(state, url),
    currentContext: getContext(state, url),
    hasOutageData: hasOutageData(state, url)
  };
};

export default connect(mapStateToProps, { initAnalysis, push })(SummaryContainer);
