import React from "react";
import { Row, Col, Alert } from "antd";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
import EditTeamForm from "./EditTeamForm";
import TeamMembersTable from "./TeamMembersTable";
import SendTeamInvitationForm from "./SendTeamInvitationForm";
import "./TeamMembers.less";

const TeamMembers = (props) => {
    const totalMembers = props.teamMembers.length + props.teamInvitations.length - 1; // Subtract the owner
    const redirectToPricing = (e) => {
        e.preventDefault();
        props.push("/subscription/plans");
    };

    return (
        <div className="team-members">
            {props.isLoading && <LoadingIndicator />}

            {!props.isLoading && (
                <Row gutter={16}>
                    <Col span={12}>
                        {totalMembers > 0 && (
                            <Alert
                                className="team-members-count"
                                type="info"
                                showIcon
                                message="Please note!"
                                description={
                                    <>
                                        <p>
                                            Your team currently has {totalMembers} member(s) out of a maximum limit of{" "}
                                            {props.maxTeamMembers} allowed by your subscription plan.
                                        </p>
                                        {props.userSubscription &&
                                            props.userSubscription["plan_code"] &&
                                            !props.userSubscription["plan_code"].includes("professional") && (
                                                <p>
                                                    To add more members, consider{" "}
                                                    <span
                                                        className="fake-link"
                                                        style={{ color: "#fe6100" }}
                                                        onClick={(e) => redirectToPricing(e)}>
                                                        upgrading
                                                    </span>{" "}
                                                    to a higher plan.
                                                </p>
                                            )}
                                    </>
                                }
                            />
                        )}

                        {props.maxTeamMembers > 0 && (
                            <div className="team">
                                <EditTeamForm
                                    name={props.teamName}
                                    onUpdateOrganization={props.onUpdateOrganization}
                                    isUpdatingOrganization={props.isUpdatingOrganization}
                                />
                                {totalMembers > 0 && (
                                    <TeamMembersTable
                                        members={props.teamMembers}
                                        invitations={props.teamInvitations}
                                        onRemoveInvitation={props.onRemoveInvitation}
                                        onRemoveTeamMember={props.onRemoveTeamMember}
                                    />
                                )}
                                {props.teamMembers.length + props.teamInvitations.length < props.maxTeamMembers && (
                                    <SendTeamInvitationForm
                                        onSendInvitation={props.onSendInvitation}
                                        refetchOrganization={props.refetchOrganization}
                                    />
                                )}
                            </div>
                        )}
                        {props.maxTeamMembers === 0 && (
                            <Alert
                                message="This feature is disabled for your current plan!"
                                description={
                                    <>
                                        In order to use this feature, you must{" "}
                                        <span
                                            className="fake-link"
                                            onClick={(e) => redirectToPricing(e)}
                                            style={{ color: "#fe6100" }}>
                                            upgrade
                                        </span>{" "}
                                        your plan.
                                    </>
                                }
                                type="warning"
                                showIcon
                            />
                        )}
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default TeamMembers;
