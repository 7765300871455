import React from 'react';
import { TopKeyword, TopKeywordsTableRecord } from '../topKeywords/types';
import { getTableDataFromKeywords } from '../topKeywords/utils';
import { Alert, Table } from 'antd';
import TableColumnWithTooltip from '../components/shared/TableColumnWithTooltip/TableColumnWithTooltip';
import { semTableColumns } from '../components/shared/TableColumnWithTooltip/semTableColumns';
import KeywordPosition from '../components/shared/KeywordPosition';

const { Column } = Table;

type Props = {
    keywords: TopKeyword[];
};

export default function DashboardTopKeywordsTable({ keywords }: Props) {
    const dataSource = getTableDataFromKeywords(keywords);

    const isKeywordsDataExistent = keywords.length > 0;
    return (
        <>
            {!isKeywordsDataExistent && <Alert message="Your domain has no indexed keywords!" />}
            {isKeywordsDataExistent && (
                <div className="keywords-table">
                    <Table
                        dataSource={dataSource}
                        pagination={false}
                        size="small"
                    >
                        <Column
                            title="#"
                            dataIndex="key"
                            align="left"
                        />
                        <Column
                            className="no-wrap"
                            title="Keyword"
                            dataIndex="keyword"
                        />
                        <Column
                            title={
                                <TableColumnWithTooltip
                                    noFill={false}
                                    {...semTableColumns.position}
                                />
                            }
                            dataIndex="position"
                            render={(text, record: TopKeywordsTableRecord, index) => (
                                <KeywordPosition
                                    status={false}
                                    position={record.position}
                                    previousPosition={record.previousPosition}
                                />
                            )}
                        />
                        <Column
                            className="al-right"
                            title={
                                <TableColumnWithTooltip
                                    noFill={false}
                                    {...semTableColumns.traffic}
                                />
                            }
                            dataIndex="traffic"
                        />
                    </Table>
                </div>
            )}
        </>
    );
}
