import React from "react";
import { Layout } from "antd";
import TrackedWebsites from "../trackedWebsites/TrackedWebsites";
import Sitemap from "../containers/Sitemap/Sitemap";

const { Content } = Layout;

const SitemapGeneratorPage = () => {
  return (
    <Content className="general-layout-content">
      <TrackedWebsites tabContent={<Sitemap />} showDomains />
    </Content>
  );
};

export default SitemapGeneratorPage;
