import { createApi } from '@reduxjs/toolkit/query/react';
import { authRequest } from '../store/authorizedRequests';
import { OUTAGE_API_URL } from '../settings/config';
import { FetchOutageContextQueryParams, FetchOutageListQueryParams, FetchOutageListResult, Outage } from './types';

const SITE_OUTAGE_REFETCH_TIME = 7200;
const siteOutageBaseUrl = `${OUTAGE_API_URL}/outage`;

export const siteOutageApi = createApi({
    reducerPath: 'api.siteOutage',
    keepUnusedDataFor: SITE_OUTAGE_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;
        try {
            const data = await authRequest(siteOutageBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchOutageList: builder.query<FetchOutageListResult, FetchOutageListQueryParams>({
            query: (args) => {
                const { url, startDate, endDate } = args;

                return {
                    url: `/list?url=${url}&start_date=${startDate}&end_date=${endDate}`,
                    method: 'GET',
                };
            },

            serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
                const { url } = queryArgs;
                return url;
            },
        }),

        fetchOutageContext: builder.query<Outage, FetchOutageContextQueryParams>({
            query: (args) => {
                const { guid } = args;

                return {
                    url: `/context?guid=${guid}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useFetchOutageContextQuery, useFetchOutageListQuery, useLazyFetchOutageContextQuery } = siteOutageApi;
