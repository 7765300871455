import React, { Component } from "react";
import { connect } from "react-redux";
import SeoReportCategory from "../../components/SeoReport/SeoReportCategory";
import {
  getSectionsResults,
  getCategorizedSelectedSections,
  getCategoriesSectionsStatusCounter,
  getCompetitorSectionResults,
  getCategoryScores
} from "../../store/analysis/selectors";

export class SeoReportCategories extends Component {
  render() {
    return (
      <React.Fragment>
        {Object.keys(this.props.categorizedSelectedSections).map(categoryName => {
          return (
            <div data-cy={categoryName} key={categoryName}>
              <SeoReportCategory
                categoryName={categoryName}
                sectionsResults={this.props.sectionsResults}
                competitorsSectionsResults={this.props.competitorsSectionsResults}
                categorizedSelectedSections={this.props.categorizedSelectedSections}
                categorySectionsStatusCounter={this.props.categoriesSectionsStatusCounter[categoryName]}
                main_url={this.props.mainUrl}
                categoryScore={this.props.categoryScores[categoryName]}
              />
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { reportGuid } = ownProps;
  return {
    categorizedSelectedSections: getCategorizedSelectedSections(state, reportGuid),
    sectionsResults: getSectionsResults(state, reportGuid),
    competitorsSectionsResults: getCompetitorSectionResults(state, reportGuid),
    categoriesSectionsStatusCounter: getCategoriesSectionsStatusCounter(state, reportGuid),
    categoryScores: getCategoryScores(state, reportGuid)
  };
};

export default connect(mapStateToProps, {})(SeoReportCategories);
