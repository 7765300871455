export const CREATE_SUBSCRIPTION_IN_PROGRESS = "CREATE_SUBSCRIPTION_IN_PROGRESS";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILED = "CREATE_SUBSCRIPTION_FAILED";

export const UPDATE_SUBSCRIPTION_IN_PROGRESS = "UPDATE_SUBSCRIPTION_IN_PROGRESS";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILED = "UPDATE_SUBSCRIPTION_FAILED";

export const CANCEL_SUBSCRIPTION_IN_PROGRESS = "CANCEL_SUBSCRIPTION_IN_PROGRESS";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILED = "CANCEL_SUBSCRIPTION_FAILED";

export const START_3D_SECURE = "START_3D_SECURE"; //TODO: create types for progress, success and failed

export const FETCH_SUBSCRIPTION_PLANS_IN_PROGRESS = "FETCH_SUBSCRIPTION_PLANS_IN_PROGRESS";
export const FETCH_SUBSCRIPTION_PLANS_SUCCESS = "FETCH_SUBSCRIPTION_PLANS_SUCCESS";
export const FETCH_SUBSCRIPTION_PLANS_FAILED = "FETCH_SUBSCRIPTION_PLANS_FAILED";

export const FETCH_SUBSCRIPTION_IN_PROGRESS = "FETCH_SUBSCRIPTION_IN_PROGRESS";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_FAILED = "FETCH_SUBSCRIPTION_FAILED";

export const FETCH_BILLING_INFO_IN_PROGRESS = "FETCH_BILLING_INFO_IN_PROGRESS";
export const FETCH_BILLING_INFO_SUCCESS = "FETCH_BILLING_INFO_SUCCESS";
export const FETCH_BILLING_INFO_FAILED = "FETCH_BILLING_INFO_FAILED";

export const UPDATE_BILLING_INFO_IN_PROGRESS = "UPDATE_BILLING_INFO_IN_PROGRESS";
export const UPDATE_BILLING_INFO_SUCCESS = "UPDATE_BILLING_INFO_SUCCESS";
export const UPDATE_BILLING_INFO_FAILED = "UPDATE_BILLING_INFO_FAILED";

export const FETCH_INVOICES_IN_PROGRESS = "FETCH_INVOICES_IN_PROGRESS";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILED = "FETCH_INVOICES_FAILED";

export const FETCH_PDF_INVOICE_IN_PROGRESS = "FETCH_PDF_INVOICE_IN_PROGRESS";
export const FETCH_PDF_INVOICE_SUCCESS = "FETCH_PDF_INVOICE_SUCCESS";
export const FETCH_PDF_INVOICE_FAILED = "FETCH_PDF_INVOICE_FAILED";

export const UPDATE_3DSECURE_ERROR_MESSAGE = "UPDATE_3DSECURE_ERROR_MESSAGE";
