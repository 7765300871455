import React, { Component } from "react";
import moment from "moment";
import { Card, Divider, Empty, Icon } from "antd";
import DashboardBox from "../DashboardBox";
import DashboardCardTitle from "../DashboardCardTitle";
import ImprovementsList from "../../SeoReport/ImprovementsList";
import DashboardSeoScore from "./DashboardSeoScore";
import LoadingIndicator from "../../shared/LoadingIndicator/LoadingIndicator";
import "./ImportantFixes.less";

const formatDate = dateStr => {
  return moment(dateStr, "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY");
};

export class ImportantFixesBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const firstFetchIsInProgress =
      !prevProps.isFetching && this.props.isFetching && prevState.loading === null && this.state.loading === null;
    const fetchIsFinished = prevProps.isFetching && !this.props.isFetching;

    if (firstFetchIsInProgress && !this.props.hasData) {
      this.setState({ loading: true });
    }
    if ((firstFetchIsInProgress && this.props.hasData) || fetchIsFinished) {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading === null) {
      return null;
    }
    return (
      <div className={`dashboard-important-fixes-box ${this.state.loading === true ? "is-loading" : ""}`}>
        {this.state.loading === true && <DashboardBox />}

        {this.state.loading === false && (
          <Card
            data-cy="important-fixes-box"
            title={
              <DashboardCardTitle
                title="Issues to fix"
                description={this.props.lastUpdatedAt ? `Last found on ${formatDate(this.props.lastUpdatedAt)}` : ""}
              />
            }
            extra={
              this.props.hasData ? (
                <div
                  className="card-link flexbox align-center"
                  onClick={() =>
                    this.props.push(`/analysis/report?guid=${this.props.lastSavedReportGuid}&url=${this.props.url}`)
                  }>
                  <span>View report</span>
                  <Icon type="right" />
                </div>
              ) : (
                ""
              )
            }>
            {this.props.isWebsiteLastAddedAndHasNoSeoReportData ? (
              <LoadingIndicator
                detailed
                text="Currently running the report..."
                subTitle="Please check back for data later"
              />
            ) : this.props.hasData ? (
              <React.Fragment>
                <DashboardSeoScore
                  seoScore={this.props.seoScore}
                  sectionsStatusCounter={this.props.sectionsStatusCounter}
                  sectionsStatusProgress={this.props.sectionsStatusProgress}
                />
                <Divider className="important-fixes-divider" />
                <ImprovementsList importantFixes={this.props.importantFixes} addLinksToIssues={false} />
              </React.Fragment>
            ) : (
              <Empty className="empty-state" image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data available yet" />
            )}
          </Card>
        )}
      </div>
    );
  }
}

export default ImportantFixesBox;
