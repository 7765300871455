import React from 'react';
import { Form, Select, TreeSelect } from 'antd';
import { MonitorRegions } from '../../types';

const { Option } = Select;

type Props = {
    selectedMainRegion: string;
    selectedAdditionalRegions: string[];
    maxRegions: number;
    monitorRegions: MonitorRegions;
    monitorRegionsOrder: string[];
    onMainRegionChange: (newMainRegion: string) => void;
    onAdditionalRegionsChange: (newAdditionalRegions: string[]) => void;
};

export default function MonitorRegionsFormItem({
    selectedMainRegion,
    selectedAdditionalRegions,
    monitorRegions,
    monitorRegionsOrder,
    maxRegions,
    onMainRegionChange,
    onAdditionalRegionsChange,
}: Props) {
    const getAdditionalMonitorLocations = () => {
        const additionalRegionsOrder = monitorRegionsOrder.filter((location) => location !== selectedMainRegion);
        return additionalRegionsOrder.map((regionCode) => ({
            title: monitorRegions[regionCode],
            value: regionCode,
            key: regionCode,
            selectable: false,
            disabled: selectedAdditionalRegions.length === maxRegions - 1 && !selectedAdditionalRegions.includes(regionCode),
            isLeaf: true,
        }));
    };

    return (
        <>
            <Form.Item>
                <div>
                    <h3>Primary monitor location</h3>
                    <p>Please select the primary location from where we'll monitor your site's loading speed. </p>
                </div>
                <Select
                    value={selectedMainRegion}
                    onChange={onMainRegionChange}
                    placeholder="Select primary location"
                    showSearch
                    onInputKeyDown={(e) => e.stopPropagation()}
                >
                    {monitorRegionsOrder.map((regionCode: any) => (
                        <Option
                            value={regionCode}
                            key={regionCode}
                        >
                            {monitorRegions[regionCode]}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {selectedMainRegion && maxRegions > 1 ? (
                <Form.Item>
                    <div>
                        <h3>Additional monitor locations</h3>
                        <p>Please select {maxRegions - 1} additional regions from where we'll monitor your site's loading speed</p>
                    </div>
                    <TreeSelect
                        allowClear
                        treeData={getAdditionalMonitorLocations()}
                        value={selectedAdditionalRegions}
                        onChange={onAdditionalRegionsChange}
                        treeCheckable
                        multiple
                        treeDefaultExpandAll
                        searchPlaceholder="Select additional regions"
                        dropdownStyle={{
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                        maxTagCount={maxRegions - 1}
                        filterTreeNode={true as any}
                        treeNodeFilterProp="title"
                    />
                </Form.Item>
            ) : null}
        </>
    );
}
