import React from 'react';
import LoadingIndicator from '../../../components/shared/LoadingIndicator/LoadingIndicator';
import { TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';
import { useUpdateTrackedWebsiteSettingsMutation } from '../../../trackedWebsites/service';
import { useGetMaxValuesForPlan } from '../../../sales/hooks/useGetMaxValuesForPlan';
import { useFetchMonitorRegionsQuery } from '../../../trackedWebsites/service';
import LocationsInput from './LocationsInput';

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void;
};

export default function LocationTab({ selectedWebsite, setSelectedWebsite }: Props) {
    const { maxRegions } = useGetMaxValuesForPlan();
    const [updateTrackedWebsiteSettings, { isLoading: isUpdatingTrackedWebsiteSettings }] = useUpdateTrackedWebsiteSettingsMutation();
    const { data: monitorRegionsData, isLoading: isLoadingMonitorRegions } = useFetchMonitorRegionsQuery();

    const handleUpdateLocations = async (args: UpdateTrackedWebsitesParams) => {
        try {
            const updatedSettings = await updateTrackedWebsiteSettings(args).unwrap();
            setSelectedWebsite({ ...selectedWebsite, settings: { ...selectedWebsite.settings, ...updatedSettings } });
        } catch (error) {}
    };

    if (isLoadingMonitorRegions || !monitorRegionsData) {
        return <LoadingIndicator />;
    }

    return (
        <div className="location-tab">
            <LocationsInput
                key={selectedWebsite.guid}
                selectedWebsite={selectedWebsite}
                monitorRegions={monitorRegionsData.regions}
                monitorRegionsOrder={monitorRegionsData.order}
                maxRegions={maxRegions}
                isLoading={isUpdatingTrackedWebsiteSettings}
                onUpdateLocations={handleUpdateLocations}
            />
        </div>
    );
}
