import React from 'react';
import { Alert, Button } from 'antd';
import { useFetchTopKeywordsQuery } from './service';
import { TrackedWebsite } from '../trackedWebsites/types';
import IconLabel from '../components/shared/IconLabel/IconLabel';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import ExportAsFile from '../components/shared/ExportAsFile/ExportAsFile';
import { getKeywordsToExportAsCsv, getKeywordsToExportAsJson, getTableDataFromKeywords } from './utils';
import TopKeywordsTable from './TopKeywordsTable';

type Props = {
    selectedWebsite: TrackedWebsite;
    orderBy: string;
    orderDir: string;
    extraColumn?: JSX.Element;
};

export default function TopKeywordsResults({ selectedWebsite, orderBy, orderDir, extraColumn }: Props) {
    const { data: topKeywords, isFetching: isLoadingTopKeywords } = useFetchTopKeywordsQuery({
        domain: selectedWebsite.domain,
        locationName: selectedWebsite.settings.location_name,
        languageCode: selectedWebsite.settings.language_code,
        orderBy,
        orderDir,
    });

    const isTopKeywordsEmpty = !isLoadingTopKeywords && (!topKeywords || topKeywords.length === 0);
    const isTopKeywordsExistent = !isLoadingTopKeywords && topKeywords && topKeywords.length > 0;

    return (
        <>
            {isLoadingTopKeywords && <LoadingIndicator />}

            {isTopKeywordsEmpty && <Alert message="Your domain has no indexed keywords!" />}

            {isTopKeywordsExistent && (
                <div className="keywords-table">
                    <TopKeywordsTable
                        tableData={getTableDataFromKeywords(topKeywords)}
                        extraColumn={extraColumn}
                    />

                    <div className="export-buttons">
                        <ExportAsFile
                            fileContent={getKeywordsToExportAsCsv(topKeywords)}
                            fileName="top_keywords.csv"
                            fileMime="text/csv"
                        >
                            <Button className="light-text">
                                <IconLabel
                                    icon="download"
                                    label="Export CSV"
                                />
                            </Button>
                        </ExportAsFile>

                        <ExportAsFile
                            fileContent={getKeywordsToExportAsJson(topKeywords)}
                            fileName="top_keywords.json"
                            fileMime="application/json"
                        >
                            <Button className="light-text">
                                <IconLabel
                                    icon="download"
                                    label="Export JSON"
                                />
                            </Button>
                        </ExportAsFile>
                    </div>
                </div>
            )}
        </>
    );
}
