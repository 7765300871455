import React, { Component } from "react";
import { Login } from "../containers";

export default class LoginPage extends Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}
