import React from 'react';
import { Layout } from 'antd';
import DashboardSettings from '../settings/DashboardSettings';

const { Content } = Layout;

type RouteMatch = {
    params: {
        [key: string]: string;
    };
    isExact: boolean;
    path: string;
    url: string;
};

type Props = {
    match: RouteMatch;
};

export default function DashboardSettingsPage({ match }: Props) {
    return (
        <Content className="general-layout-content">
            <DashboardSettings selectedOption={match.params.selectedOption} />
        </Content>
    );
}
