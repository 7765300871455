import React from "react";

function DashboardCardTitle({ title, description }) {
  return (
    <div className="ant-card-meta-detail">
      <div className="ant-card-meta-title">{title}</div>
      <div className="ant-card-meta-description">{description}</div>
    </div>
  );
}

export default DashboardCardTitle;
