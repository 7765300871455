
const createLinkAndTriggerDownload=(url)=>{
    if(window.Cypress){ //only for testing purpose
        return
    }
    
    var linkElement = document.createElement('a');
    linkElement.href = url;
    document.body.appendChild(linkElement);
    linkElement.click();
    linkElement.remove();
}

export default createLinkAndTriggerDownload;