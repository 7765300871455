import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import AnchorLabeledList from "../../../shared/List/AnchorLabeledList";
import { countOfListElements, anchorAttributesForUrls } from "../../utils";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import PageObjectsStatsTable from "./PageObjectsStatsTable";
import "./DataVisualization.less";

const displayBrokenResourcesTable = (title, broken_resources) => {
  return (
    <div className="image-table-container broken-resources">
      <span className="title">{title}</span>
      <div className="image-table">
        <div className="image-table-row header">
          <div className="image-table-cell url">Url</div>
          <div className="image-table-cell type">Type</div>
          <div className="image-table-cell status">Status</div>
        </div>

        {broken_resources.map((broken_resource, idx) => {
          // const url =
          //   broken_resource["url"].length > 63
          //     ? broken_resource["url"].slice(0, 30) + "..." + broken_resource["url"].slice(-30)
          //     : broken_resource["url"];
          return (
            <div className="image-table-row" key={idx}>
              <div className="image-table-cell url">
                <div className="truncated">
                  <a href={broken_resource["url"]} target="_blank" rel="noopener noreferrer">
                    {broken_resource["url"]}
                  </a>
                </div>
              </div>
              <div className="image-table-cell">{broken_resource["resource_type"]}</div>
              <div className="image-table-cell">{broken_resource["status"]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const displayResult = result => {
  const titles = {
    size_by_type: "Content size by content type",
    requests_by_type: "Requests by content type",
    size_by_domain: "Content size by domain",
    requests_by_domain: "Requests by domain"
  };
  const columns = {
    size_by_type: ["Content type", "Percent", "Size"],
    requests_by_type: ["Content type", "Percent", "Requests"],
    size_by_domain: ["Domain", "Percent", "Size"],
    requests_by_domain: ["Domain", "Percent", "Requests"]
  };

  let brokenResourcesFromSameDomainCount = 0;
  if ("broken_resources" in result["page_objects"] && "same_domain" in result["page_objects"]["broken_resources"]) {
    brokenResourcesFromSameDomainCount = result["page_objects"]["broken_resources"]["same_domain"].length;
  }

  let brokenResourcesFromOtherDomainCount = 0;
  if ("broken_resources" in result["page_objects"] && "other_domain" in result["page_objects"]["broken_resources"]) {
    brokenResourcesFromOtherDomainCount = result["page_objects"]["broken_resources"]["other_domain"].length;
  }

  const brokenResourcesCount = brokenResourcesFromSameDomainCount + brokenResourcesFromOtherDomainCount;

  return (
    <div className="description-meta">
      {brokenResourcesCount > 0 && (
        <InlineModal
          className="broken-resources"
          openLabel={`We've found ${brokenResourcesCount} broken resource ${
            brokenResourcesCount > 1 ? "URLs" : "URL"
          }!`}
          modalTitle="Full list of broken resource Urls"
          closeLabel="Close">
          {brokenResourcesFromSameDomainCount > 0 &&
            displayBrokenResourcesTable("From same domain:", result["page_objects"]["broken_resources"]["same_domain"])}
          {brokenResourcesFromOtherDomainCount > 0 &&
            displayBrokenResourcesTable(
              "From other domains:",
              result["page_objects"]["broken_resources"]["other_domain"]
            )}
        </InlineModal>
      )}

      <div className="stats-table">
        <div className="stats-table-row">
          <PageObjectsStatsTable
            title={titles["size_by_type"]}
            columns={columns["size_by_type"]}
            result={result["page_objects"]["size_by_type"]}
          />
          <PageObjectsStatsTable
            title={titles["requests_by_type"]}
            columns={columns["requests_by_type"]}
            result={result["page_objects"]["requests_by_type"]}
          />
        </div>
        <div className="stats-table-row">
          <PageObjectsStatsTable
            maxRows={10}
            title={titles["size_by_domain"]}
            columns={columns["size_by_domain"]}
            result={result["page_objects"]["size_by_domain"]}
          />
          <PageObjectsStatsTable
            maxRows={10}
            title={titles["requests_by_domain"]}
            columns={columns["requests_by_domain"]}
            result={result["page_objects"]["requests_by_domain"]}
          />
        </div>
      </div>
    </div>
  );
};

const displayLegacyResult = result => {
  const showModal = (objectList, objectType) => (
    <React.Fragment>
      <InlineModal
        openLabel={`${objectList.length} ${objectType}`}
        modalTitle={`${objectType} (${objectList.length})`}
        closeLabel="Close">
        <AnchorLabeledList list={anchorAttributesForUrls(objectList)} />
      </InlineModal>
    </React.Fragment>
  );

  const getChartData = obj => Object.entries(obj).map(item => ({ objectType: item[0], objectCount: item[1].length }));
  const data = getChartData(result.page_objects);
  const cols = {
    objectType: { alias: "objectType" },
    objectCount: { alias: "objectCount" }
  };

  return (
    <div className="description-meta">
      <div className="data-visualization">
        <div className="chart-legend">
          <div className="totalPageObjects">Total Objects: {countOfListElements(result.page_objects)}</div>
          <ul>
            <li>
              <span className="legend-dot blue" />
              {showModal(result.page_objects.html, "HTML Pages")}
            </li>
            <li>
              <span className="legend-dot cyan" />
              {showModal(result.page_objects.css, "CSS Files")}
            </li>
            <li>
              <span className="legend-dot green" />
              {showModal(result.page_objects.javascript, "JS Files")}
            </li>
            <li>
              <span className="legend-dot yellow" />
              {showModal(result.page_objects.images, "Images")}
            </li>
            <li>
              <span className="legend-dot magenta" />
              {showModal(result.page_objects.flash, "Flash Files")}
            </li>
          </ul>
        </div>

        <div className="chart-container">
          <Chart width={320} height={260} padding="auto" data={data} scale={cols} className="chart" forceFit>
            <Axis name="objectType" title={null} />
            <Axis name="objectCount" title={null} position="left" />
            <Tooltip />
            <Geom
              type="interval"
              position="objectType*objectCount"
              color={["objectType", ["#3ba0ff", "#36cbcb", "#4dcb73", "#fad337", "#8543e0"]]}
            />
          </Chart>
        </div>
      </div>
    </div>
  );
};

const PageObjectsSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={1}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {result["page_objects"]["size_by_type"] ? displayResult(result) : displayLegacyResult(result)}
        <SectionCompetitorResults results={competitorResults} relevance={1} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default PageObjectsSection;
