import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import AnchorLabeledList from "../../../shared/List/AnchorLabeledList";
import { anchorAttributesForUrls } from "../../utils";

const displayResult = (title, result) => {
  if (result.status === "failed") {
    return (
      <>
        <div className="description-meta">
          <InlineModal
            openLabel="See results list"
            modalTitle="Full list of render blocking resources"
            closeLabel="Close">
            {result.render_blocking_resources.same_domain.length > 0 && (
              <div className="list-title">Render blocking resources from same domain:</div>
            )}
            <AnchorLabeledList list={anchorAttributesForUrls(result.render_blocking_resources.same_domain)} />

            {result.render_blocking_resources.other_domains.length > 0 && (
              <div className="list-title">Render blocking resources from other domains:</div>
            )}
            <AnchorLabeledList list={anchorAttributesForUrls(result.render_blocking_resources.other_domains)} />
          </InlineModal>
        </div>

        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </>
    );
  }
  return null;
};

const RenderBlockingResourcesSection = ({
  url,
  sectionKey,
  title,
  result,
  tooltip,
  relevance,
  passRate,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};
export default RenderBlockingResourcesSection;
