import { createReducer } from "../reducer_utils";

const initialState = {
  network_activity: [],
  intervals: {
    "24_hours": "last_24_hours",
    "7_days": "last_7_days",
    "30_days": "last_30_days",
    custom_interval: "custom_interval"
  },
  default_interval: "last_24_hours",
  data: {}
};

const getFetchingMessage = action => {
  const { url, region, interval } = action;
  const messages = {
    last_24_hours: `fetch_${interval}_${url}_${region}`,
    last_7_days: `fetch_${interval}_${url}_${region}`,
    last_30_days: `fetch_${interval}_${url}_${region}`,
    custom_interval: `fetch_${interval}_${url}_${region}`
  };

  return messages[interval] || "";
};

const addNetworkActivityMessage = (networkActivity, action) => [...networkActivity, getFetchingMessage(action)];

const removeNetworkActivityMessage = (networkActivity, msgType, action) => {
  return networkActivity.filter(msg => msg !== getFetchingMessage(msgType, action));
};

const fetchLoadingTimeInProgress = (state, action) => ({
  ...state,
  network_activity: addNetworkActivityMessage(state.network_activity, action)
});

const fetchFetchLoadingTimeSuccess = (state, action) => {
  const { url, region, interval, reports } = action;
  let result = reports;

  return {
    ...state,
    network_activity: removeNetworkActivityMessage(state.network_activity, action),
    data: {
      ...state["data"],
      [url]: {
        ...state["data"][url],
        [region]: {
          ...state["data"][url][region],
          [interval]: result
        }
      }
    }
  };
};

const fetchLoadingTimeFailed = (state, action) => ({
  ...state,
  network_activity: removeNetworkActivityMessage(state.network_activity, action)
});

const changeUrlSettings = (state, action) => {
  let settingType = action.interval ? "interval" : "region";
  let settingValue = action.interval || action.region;

  return {
    ...state,
    data: {
      ...state["data"],
      [action.url]: {
        ...state["data"][action.url],
        [`selected_${settingType}`]: settingValue
      }
    }
  };
};

const fetchLoadingTimeForDashboardInProgress = (state, action) => {
  return {
    ...state,
    network_activity: [...state.network_activity, `fetch_loading_time_for_dashboard_${action.url}`]
  };
};

const fetchLoadingTimeForDashboardFinished = (state, action) => {
  return {
    ...state,
    network_activity: state.network_activity.filter(item => item !== `fetch_loading_time_for_dashboard_${action.url}`)
  };
};

const sendLoadingTimeRequestInProgress = state => {
  return {
    ...state,
    network_activity: [...state.network_activity, `loading_time_request`]
  };
};

const sendLoadingTimeRequestSuccessful = state => {
  return {
    ...state,
    network_activity: state.network_activity.filter(item => item !== `loading_time_request`)
  };
};

const sendLoadingTimeRequestFailed = state => {
  return {
    ...state,
    network_activity: state.network_activity.filter(item => item !== `loading_time_request`)
  };
};

const logout = (state, _) => ({ ...state, ...initialState });

const loadingTimeReducer = createReducer(initialState, {
  FETCH_LOADING_TIME_IN_PROGRESS: fetchLoadingTimeInProgress,
  FETCH_LOADING_TIME_SUCCESSFUL: fetchFetchLoadingTimeSuccess,
  FETCH_LOADING_TIME_FAILED: fetchLoadingTimeFailed,

  CHANGE_SELECTED_INTERVAL: changeUrlSettings,
  CHANGE_SELECTED_REGION: changeUrlSettings,
  FETCH_LOADING_TIME_FOR_DASHBOARD_IN_PROGRESS: fetchLoadingTimeForDashboardInProgress,
  FETCH_LOADING_TIME_FOR_DASHBOARD_FINISHED: fetchLoadingTimeForDashboardFinished,

  SEND_LOADING_TIME_REQUEST_IN_PROGRESS: sendLoadingTimeRequestInProgress,
  SEND_LOADING_TIME_REQUEST_SUCCESSFUL: sendLoadingTimeRequestSuccessful,
  SEND_LOADING_TIME_REQUEST_FAILED: sendLoadingTimeRequestFailed,

  LOGOUT: logout
});

export default loadingTimeReducer;
