import React from "react";
import { Card, Table, Icon, Empty, Button } from "antd";
import moment from "moment";
import "./UserTemplates.less";

const { Column } = Table;

function UserTemplates(props) {
  const tableDataSource = props.templates.map((template, idx) => ({
    key: idx + 1,
    guid: template["guid"],
    updatedAt: template["last_modified"],
    name: template["name"]
  }));

  function handleTemplateDelete(event, guid) {
    event.stopPropagation();
    props.deleteTemplate(guid);
  }

  function handleTemplateSelection(guid) {
    props.push(`/white-label/templates/${guid}`);
    props.changeSelectedTemplate(guid);
  }

  function handleNewTemplateBtnClick(event) {
    event.currentTarget.blur();
    props.push("/white-label/templates/new");
  }

  return (
    <div>
      <Card
        title={
          <div className="ant-card-meta-detail">
            <div className="ant-card-meta-title">Saved Templates</div>
          </div>
        }>
        {props.templates.length > 0 ? (
          <div className="template-table-container custom-scrollbar">
            <Table
              className="templates-table"
              dataSource={tableDataSource}
              size="small"
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    handleTemplateSelection(record["guid"]);
                  }
                };
              }}>
              <Column title="#" dataIndex="key" align="left" width="5%" />
              <Column
                className="no-wrap"
                title="Updated at"
                dataIndex="updatedAt"
                width="20%"
                align="left"
                render={(_, record) => moment.unix(record["updatedAt"]).format("MMM DD, YYYY")}
              />
              <Column
                className="no-wrap template-name"
                title="Template name"
                dataIndex="name"
                align="left"
                render={(_, record) => <span>{record["name"]}</span>}
              />
              <Column
                className="no-wrap"
                title=""
                align="right"
                render={(_, record) => (
                  <span
                    className={`remove-item${
                      props.templatesBeingDeleted.includes(record["guid"]) ? " in-progress" : ""
                    }`}
                    onClick={e => handleTemplateDelete(e, record["guid"])}>
                    {props.templatesBeingDeleted.includes(record["guid"]) ? (
                      <Icon type="loading" />
                    ) : (
                      <Icon type="delete" />
                    )}
                  </span>
                )}
              />
            </Table>
          </div>
        ) : (
          <Empty
            className="empty-state"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="You have no saved templates"
          />
        )}
      </Card>
      <Button className="new-template-btn" type="primary" onClick={handleNewTemplateBtnClick}>
        Create new template
      </Button>
    </div>
  );
}

export default UserTemplates;
