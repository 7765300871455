import { push } from "connected-react-router";
import { SALES_API_URL } from "../../settings/config";

//import { removeCookie, defaultIamCookieAttributes } from "../../utils/cookie";
import { sendPurchaseEvent, sendFreeTrialStartedEvent } from "../../utils/analytics";
//import { logout } from "../iam/creators";
import { authRequest } from "../authorizedRequests";
import { getUserEmail } from "../iam/selectors";
import { addNotificationWithTimeout } from "../notifications/operations";
import { refreshAccessToken, createOrganizationIfNotExists } from "../iam/operations";
import { getSubscriptionPlans, getBillingInfo, getInvoices, getSubscription, getPlan } from "./selectors";
import {
  createSubscriptionInProgress,
  createSubscriptionSuccess,
  createSubscriptionFailed,
  start3DSecure,
  fetchPlansFailed,
  fetchPlansInProgress,
  fetchPlansSuccess,
  fetchSubscriptionFailed,
  fetchSubscriptionInProgress,
  fetchSubscriptionSuccess,
  cancelSubscriptionFailed,
  cancelSubscriptionInProgress,
  cancelSubscriptionSuccess,
  fetchBillingInfoFailed,
  fetchBillingInfoInProgress,
  fetchBillingInfoSuccess,
  updateBillingInfoFailed,
  updateBillingInfoInProgress,
  updateBillingInfoSuccess,
  fetchInvoicesFailed,
  fetchInvoicesInProgress,
  fetchInvoicesSuccess,
  fetchPdfInvoiceFailed,
  fetchPdfInvoiceInProgress,
  fetchPdfInvoiceSuccess,
  updateSubscriptionFailed,
  updateSubscriptionInProgress,
  updateSubscriptionSuccess,
  update3DSecureErrorMessage
} from "./creators";

export const set3DSecureError = (msg) => {
  return (dispatch, getState) => {
    dispatch(update3DSecureErrorMessage(msg));
  };
};

export const createSubscription = (token, paymentMethod, planCode, redirectTo, threeDSecureToken = null) => {
  return (dispatch, getState) => {
    const planDetails = getPlan(getState(), planCode);
    const apiUrl = `${SALES_API_URL}/subscription`;
    const userEmail = getUserEmail(getState());
    const payload = {
      user_email: userEmail,
      token: token,
      payment_method: paymentMethod,
      plan_code: planCode
    };

    if (threeDSecureToken) {
      payload["three_d_secure_action_result_token_id"] = threeDSecureToken;
    }

    dispatch(createSubscriptionInProgress());

    authRequest(apiUrl, dispatch, getState, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((body) => {
        if (body.action_token_id) {
          dispatch(start3DSecure(body));
        } else {
          if (body.error) {
            dispatch(createSubscriptionFailed(body));
          } else {
            dispatch(createSubscriptionSuccess(body));

            if (redirectTo !== "/subscription/plans") {
              try {
                sendFreeTrialStartedEvent(planCode);
                sendPurchaseEvent(planDetails["code"], planDetails["name"], planDetails["price"] / 100);
              } catch (_) {}
            }

            dispatch(createOrganizationIfNotExists()).then((_) => {
              dispatch(refreshAccessToken()).then((_) => {
                dispatch(push(redirectTo));
              });
            });
          }
        }
      })
      .catch((_) => {
        dispatch(
          createSubscriptionFailed({ error: "Cannot create subscription. Please try again or contact support!" })
        );
      });
  };
};

export const updateSubscription = (planCode) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/subscription`;
    const payload = {
      plan_code: planCode
    };

    dispatch(updateSubscriptionInProgress());

    authRequest(apiUrl, dispatch, getState, {
      method: "PATCH",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        if (response.error) {
          dispatch(updateSubscriptionFailed(response));
        } else {
          dispatch(refreshAccessToken()).then((_) => {
            dispatch(updateSubscriptionSuccess(response));
          });
        }
      })
      .catch((error) => {
        dispatch(
          addNotificationWithTimeout(
            "error",
            "We cannot update your subscription. Please try again or contact support!"
          )
        );
        dispatch(updateSubscriptionFailed(error));
      });
  };
};

export const updatePaymentMethod = (token, threeDSecureToken = null) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/billing_info/payment_method`;
    const billingInfo = getBillingInfo(getState());

    const payload = {
      token_id: token
    };

    if (billingInfo["company"]) {
      payload["company"] = billingInfo["company"];
    }

    if (billingInfo["vat_number"]) {
      payload["vat_number"] = billingInfo["vat_number"];
    }

    if (threeDSecureToken) {
      payload["three_d_secure_action_result_token_id"] = threeDSecureToken;
    }

    dispatch(updateBillingInfoInProgress());

    authRequest(apiUrl, dispatch, getState, {
      method: "POST", //TODO: change to patch
      body: JSON.stringify(payload)
    })
      .then((body) => {
        if (body.error) {
          throw new Error();
        }

        if (body.action_token_id) {
          dispatch(start3DSecure(body));
        } else {
          dispatch(updateBillingInfoSuccess(body));
          dispatch(addNotificationWithTimeout("success", "Your payment method was successfully updated!"));
        }
      })
      .catch((error) => {
        dispatch(
          addNotificationWithTimeout(
            "error",
            "We cannot update your payment method. Please try again or contact support!"
          )
        );
        dispatch(updateBillingInfoFailed());
      });
  };
};

export const fetchSubscriptionPlans = (_) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/plans`;
    const plans = getSubscriptionPlans(getState());

    if (Object.keys(plans).length) {
      return Promise.resolve(plans);
    }

    dispatch(fetchPlansInProgress());

    authRequest(apiUrl, dispatch, getState)
      .then((response) => dispatch(fetchPlansSuccess(response)))
      .catch((_) => {
        dispatch(fetchPlansFailed());
      });
  };
};

export const fetchSubscription = (_) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/subscription`;

    dispatch(fetchSubscriptionInProgress());

    return authRequest(apiUrl, dispatch, getState)
      .then((response) => dispatch(fetchSubscriptionSuccess(response)))
      .catch((_) => {
        dispatch(fetchSubscriptionFailed());
      });
  };
};

export const cancelSubscription = (reason, comment) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/subscription`;
    const subscription = getSubscription(getState());
    const planCode = subscription["plan_code"];
    const payload = {
      reason: reason,
      comment: comment,
      plan_code: planCode
    };
    dispatch(cancelSubscriptionInProgress());
    return authRequest(apiUrl, dispatch, getState, {
      method: "DELETE",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        dispatch(refreshAccessToken()).then((_) => {
          dispatch(cancelSubscriptionSuccess(response));
          return Promise.resolve(response);
        });
      })
      .catch((_) => {
        dispatch(
          addNotificationWithTimeout("error", "Cannot cancel subscription. Please try again or contact support!")
        );
        dispatch(cancelSubscriptionFailed());
        return Promise.resolve({ error: "Cannot cancel subscription. Please try again or contact support!" });
      });
  };
};

export const sendCancellationReason = (reason, comment) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/subscription/reason`;
    const subscription = getSubscription(getState());
    const planCode = subscription["plan_code"];
    const payload = {
      reason: reason,
      comment: comment,
      plan_code: planCode
    };

    //dispatch(sendCancellationReasonInProgress());
    return authRequest(apiUrl, dispatch, getState, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        //dispatch(sendCancellationReasonSuccessful(response));
      })
      .catch((_) => {
        //dispatch(sendCancellationReasonFailed());
      });
  };
};

// export const deleteUserAccount = (reason, comment) => {
//   return (dispatch, getState) => {
//     const apiUrl = `${SALES_API_URL}/account`;
//     const subscription = getSubscription(getState());
//     const planCode = subscription["plan_code"];
//     const payload = {
//       reason: reason,
//       comment: comment,
//       plan_code: planCode
//     };
//     dispatch(cancelSubscriptionInProgress());

//     authRequest(apiUrl, dispatch, getState, {
//       method: "DELETE",
//       body: JSON.stringify(payload)
//     })
//       .then((response) => {
//         dispatch(addNotificationWithTimeout("success", "Your account was successfully deleted!"));
//         dispatch(cancelSubscriptionSuccess(response));
//         dispatch(logout());
//         dispatch(push("/auth/login"));

//         removeCookie(IAM_COOKIE_NAME, defaultIamCookieAttributes);
//       })
//       .catch((_) => {
//         dispatch(
//           addNotificationWithTimeout("error", "Cannot delete your account. Please try again or contact support!")
//         );
//         dispatch(cancelSubscriptionFailed());
//       });
//   };
// };

export const fetchBillingInfo = (_) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/billing_info`;

    dispatch(fetchBillingInfoInProgress());

    return authRequest(apiUrl, dispatch, getState)
      .then((response) => dispatch(fetchBillingInfoSuccess(response)))
      .catch((_) => {
        dispatch(
          addNotificationWithTimeout("error", "Cannot fetch billing information. Please try again or contact support!")
        );
        dispatch(fetchBillingInfoFailed());
      });
  };
};

export const updateBillingInfo = (firstName, lastName, companyName, vatNumber) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/billing_info`;
    const payload = {
      first_name: firstName,
      last_name: lastName,
      company: companyName,
      vat_number: vatNumber
    };

    dispatch(updateBillingInfoInProgress());

    authRequest(apiUrl, dispatch, getState, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => {
        dispatch(updateBillingInfoSuccess(response));
      })
      .catch((_) => {
        dispatch(
          addNotificationWithTimeout("error", "Cannot update billing information. Please try again or contact support!")
        );
        dispatch(updateBillingInfoFailed());
      });
  };
};

export const fetchInvoices = (_) => {
  return (dispatch, getState) => {
    const invoices = getInvoices(getState());
    if (invoices) {
      return Promise.resolve();
    }

    const apiUrl = `${SALES_API_URL}/invoices`;
    dispatch(fetchInvoicesInProgress());

    return authRequest(apiUrl, dispatch, getState)
      .then((response) => dispatch(fetchInvoicesSuccess(response.invoices)))
      .catch((_) => {
        dispatch(fetchInvoicesFailed());
      });
  };
};

export const fetchPdfInvoice = (invoiceNumber) => {
  return (dispatch, getState) => {
    const apiUrl = `${SALES_API_URL}/invoices/pdf/${invoiceNumber}`;
    dispatch(fetchPdfInvoiceInProgress());

    return authRequest(apiUrl, dispatch, getState)
      .then((response) => {
        const newBlob = new Blob([_base64ToArrayBuffer(response["pdf"])], { type: "application/pdf" });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);

          let link = document.createElement("a");
          link.href = objUrl;
          link.download = `invoice_${invoiceNumber}.pdf`;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);

          dispatch(fetchPdfInvoiceSuccess());
        }
      })
      .catch((_) => {
        dispatch(fetchPdfInvoiceFailed());
      });
  };
};

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
