import { SeoLocationData } from './types';

export const urlValidationRule = {
    url: {
        url: {
            message: '^Provide a valid URL',
        },
    },
};

export function normalizeUrl(url: string, urlSchema: string) {
    let normalizedUrl = url.toLowerCase().trim();
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        normalizedUrl = urlSchema.concat(url);
    }
    return normalizedUrl;
}

export function getAvailableLanguagesForLocation(location: string, seoLocations: SeoLocationData[]) {
    const foundLocation = seoLocations.find((seoLocation) => seoLocation.location_name === location);

    return foundLocation ? foundLocation.available_languages : [];
}

export function getLanguageFromCode(location: string, languageCode: string, seoLocations: SeoLocationData[]) {
    const languages = getAvailableLanguagesForLocation(location, seoLocations);
    const foundLanguage = languages.find((language) => language.language_code === languageCode);

    return foundLanguage ? foundLanguage.language_name : '';
}
