import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";
import "./SocialMediaMetaTagsSection.less";

const displayResult = (title, result) => {
  return (
    <>
      <div className="description-meta">
        {result["open_graph"] && Object.keys(result["open_graph"]).length > 0 && (
          <div className="stats-table-container open-graph">
            <header>
              <div className="col">Open Graph Meta Tags</div>
              <div className="col"></div>
            </header>

            {Object.keys(result["open_graph"]).map((ogKey, idx) => {
              return (
                <div className="row" key={idx}>
                  <div className="col">{ogKey}</div>
                  <div className="col">{result["open_graph"][ogKey]}</div>
                </div>
              );
            })}
          </div>
        )}

        {result["twitter"] && Object.keys(result["twitter"]).length > 0 && (
          <div className="stats-table-container twitter-card">
            <header>
              <div className="col">Twitter Card Meta Tags</div>
              <div className="col"></div>
            </header>

            {Object.keys(result["twitter"]).map((ogKey, idx) => {
              return (
                <div className="row" key={idx}>
                  <div className="col">{ogKey}</div>
                  <div className="col">{result["twitter"][ogKey]}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    </>
  );
};

const SocialMediaMetaTagsSection = ({
  url,
  sectionKey,
  title,
  result,
  tooltip,
  relevance,
  passRate,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />
      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default SocialMediaMetaTagsSection;
