export const semTableColumns = {
  position: {
    title: "Position",
    tooltip: "Position of your domain for a particular keyword in Top 100 organic search results."
  },
  top_domains: {
    title: "Top domains",
    tooltip: "Top 100 organic search results for a particular keyword."
  },
  search_volume: {
    title: "Search volume",
    tooltip: "The average number of search queries for a particular keyword per month."
  },
  cpc: {
    title: "CPC",
    tooltip:
      "The average price (in US dollars) advertisers pay for a user's click on an AdWords ad containing a particular keyword."
  },
  results_no: {
    title: "Number of results",
    tooltip: "The number of URLs displayed in organic search results for a particular keyword."
  },
  url: {
    title: "URL",
    tooltip:
      "The URL for which your domain have the best position in Top 100 organic search results for a particular keyword."
  },
  traffic: {
    title: "Traffic (%)",
    tooltip:
      "The proportion of visitors coming to your domain from a particular keyword as compared to all visitors of your domain."
  },
  difficulty: {
    title: "Difficulty (%)",
    tooltip:
      "An estimate of how difficult it would be to rank well in organic search results for a particular keyword. The higher the percentage, the harder it is to achieve high rankings for the given keyword."
  },
  trends: {
    title: "Trends",
    tooltip:
      "The interest of searchers in a given keyword during the period of 12 months. This metric helps you understand the seasonality of your target audience and predict the seasonal spikes."
  },
  cmp: {
    title: "Competition",
    tooltip:
      "Competition in paid search represents the density of advertisers using a particular keyword for their ads. The higher this number gets to 1.00, the harder it is to rank for in paid searches."
  }
};
