import React, { useState } from "react";
import moment from "moment";
import { Table, Modal } from "antd";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
import { SubscriptionPlans } from "./SubscriptionPlans";

const { Column } = Table;
const tableDataSource = (plan, hasFreeTrial) => {
  return [
    {
      key: "1",
      name: "Account type",
      value: plan["name"]
    },
    {
      key: "2",
      name: "Price",
      value: `$${plan["price"] / 100}`
    },
    {
      key: "3",
      name: "Billing period",
      value: plan["billing_period"].charAt(0).toUpperCase() + plan["billing_period"].slice(1)
    },
    {
      key: "4",
      name: "Starting date",
      value: hasFreeTrial
        ? moment()
            .add(7, "d")
            .format("MMM DD, YYYY")
        : moment().format("MMM DD, YYYY")
    }
  ];
};

function SelectedPlanDetails(props) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedPlanCode, setPreferredPlan] = useState(
    props.selectedPlan && props.selectedPlan["code"] ? props.selectedPlan["code"] : null
  );

  const toggleModal = event => {
    event.currentTarget.blur();
    setPreferredPlan(props.selectedPlan["code"]);
    setModalVisible(!isModalVisible);
  };

  const renderDefaultPlanSettings = (text, record) => {
    return record["name"] === "Account type" ? (
      <span>
        {text}{" "}
        <span className="change-plan" onClick={e => toggleModal(e)}>
          (change plan)
        </span>
      </span>
    ) : (
      record["value"]
    );
  };

  const onSelectPlan = planCode => {
    setPreferredPlan(planCode);
    props.onSelectPlan(planCode);
    setModalVisible(!isModalVisible);
  };
  const onChangeIntervalUnit = intervalUnit => {
    props.onChangeIntervalUnit(intervalUnit);
  };
  const tableTitle = (plan, freeTrial) => {
    if (!freeTrial) {
      return false;
    }
    return `Free for 7 days then $${plan["price"] / 100} for each ${plan["interval_length"] === 1 ? "month" : "year"}`;
  };

  return props.selectedPlan ? (
    <React.Fragment>
      <Table
        className={`selected-plan-table ${props.freeTrial ? "free-trial" : ""}`}
        dataSource={tableDataSource(props.selectedPlan, props.freeTrial)}
        size="small"
        pagination={false}
        title={() => tableTitle(props.selectedPlan, props.freeTrial)}>
        <Column className="plan-prop-name" dataIndex="name" key="name" />
        {props.allowChangePlan ? (
          <Column
            className="plan-prop-value"
            dataIndex="value"
            key="value"
            render={(text, record) => renderDefaultPlanSettings(text, record)}
          />
        ) : (
          <Column className="plan-prop-value" dataIndex="value" key="value" />
        )}
      </Table>
      <Modal
        className="subscription-plans-modal"
        title={"Choose your free trial plan"}
        visible={isModalVisible}
        onCancel={toggleModal}
        footer={null}
        destroyOnClose
        width="1000px">
        <SubscriptionPlans
          plans={props.plans}
          selectedPlanCode={selectedPlanCode}
          onSelectPlan={plan => onSelectPlan(plan)}
          onChangeIntervalUnit={intervalUnit => onChangeIntervalUnit(intervalUnit)}
          freeTrial={true}
        />
      </Modal>
    </React.Fragment>
  ) : (
    <LoadingIndicator />
  );
}
export default SelectedPlanDetails;
