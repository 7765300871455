import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { resetPasswordRequest } from "../../store/iam/operations";
import { loadingResetPasswordRequest } from "../../store/iam/selectors";
import { ForgotPasswordForm } from "../../components/auth";

class ForgotPassword extends Component {
  submitForm = email => {
    this.props.resetPasswordRequest(email);
  };

  render() {
    return <ForgotPasswordForm onSubmit={this.submitForm} isLoading={this.props.isLoading} push={this.props.push} />;
  }
}

const mapStateToProps = state => ({
  isLoading: loadingResetPasswordRequest(state)
});

export default connect(mapStateToProps, { resetPasswordRequest, push })(ForgotPassword);
