import React from 'react';
import PropTypes from 'prop-types';

const HtmlContent = ({content}) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: content}} />
    )
};

HtmlContent.propTypes = {
    content: PropTypes.string.isRequired,
};

export default HtmlContent;
