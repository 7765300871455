//import Cookies from "js-cookie";
import { getCookieJson } from "../../utils/cookie";
import { IAM_COOKIE_NAME } from "../../settings/config";
import * as types from "./types";

// const getIamFromPublicSiteCookie = _ => {
//   return Cookies.get("ssc.iam");
// };

const getIamFromCookie = (_) => {
    try {
        //let iam = Cookies.get("app.iam");

        // if (!iam) {
        //   iam = getIamFromPublicSiteCookie();
        // }

        const iam = getCookieJson(IAM_COOKIE_NAME);
        if (iam) {
            //return JSON.parse(iam);
            return iam;
        } else {
            return {};
        }
    } catch (e) {
        return {};
    }
};

const initialState = {
    ...getIamFromCookie(),
    user: null,
    organization: null,
    team_members: [],
    team_invitations: [],
    network_activity: []
};

const iamReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTHENTICATION_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "authenticate"]
            };
        case types.AUTHENTICATION_SUCCESSFUL:
            return {
                ...state,
                access_token: action.access_token,
                refresh_token: action.refresh_token,
                network_activity: state.network_activity.filter((item) => item !== "authenticate")
            };
        case types.AUTHENTICATION_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "authenticate")
            };
        case types.USER_IS_LOADING:
            return {
                ...state,
                network_activity: [...state.network_activity, "getCurrentUser"]
            };
        case types.GET_USER:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "getCurrentUser"),
                user: action.user
            };
        case types.USER_IS_NOT_AVAILABLE:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "getCurrentUser")
            };
        case types.REGISTRATION_IN_PROGRESS:
            return {
                ...state,
                network_activity: [
                    ...state.network_activity,
                    "registration",
                    `registration_and_authentication_in_progress`
                ]
            };
        case types.REGISTRATION_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "registration")
            };
        case types.REGISTRATION_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "registration")
            };
        case types.REGISTRATION_AND_LOGIN_IS_FINISHED:
            return {
                ...state,
                network_activity: state.network_activity.filter(
                    (item) => item !== `registration_and_authentication_in_progress`
                )
            };
        case types.DELETE_USER_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "delete_user"]
            };
        case types.DELETE_USER_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "delete_user")
            };
        case types.DELETE_USER_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "delete_user")
            };
        case types.PASSWORD_RESET_REQUEST_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "forgot-password"]
            };
        case types.PASSWORD_RESET_REQUEST_SUCCESS:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "forgot-password")
            };
        case types.PASSWORD_RESET_REQUEST_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "forgot-password")
            };
        case types.CHANGE_USER_NAME_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "change_user_name"]
            };
        case types.CHANGE_USER_NAME_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "change_user_name")
            };
        case types.CHANGE_USER_NAME_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "change_user_name")
            };
        case types.CHANGE_PASSWORD_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "change_password"]
            };
        case types.CHANGE_PASSWORD_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "change_password")
            };
        case types.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "change_password")
            };
        case types.CONFIRM_PASSWORD_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "confirm_password"]
            };
        case types.CONFIRM_PASSWORD_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "confirm_password")
            };
        case types.CONFIRM_PASSWORD_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "confirm_password")
            };
        case types.UPDATE_ACCESS_TOKEN_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "update_access_token"]
            };
        case types.UPDATE_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                access_token: action["access_token"],
                network_activity: state.network_activity.filter((item) => item !== "update_access_token")
            };
        case types.UPDATE_ACCESS_TOKEN_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "update_access_token")
            };
        case types.UPDATE_REQUIRED_EXPERIENCES_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "update_required_experiences_tag"]
            };
        case types.UPDATE_REQUIRED_EXPERIENCES_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "update_required_experiences_tag"),
                user: {
                    ...state["user"],
                    required_experiences: action["updatedValue"]
                }
            };
        case types.UPDATE_REQUIRED_EXPERIENCES_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "update_required_experiences_tag")
            };
        case types.GET_ORGANIZATION_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "get_organization"]
            };
        case types.GET_ORGANIZATION_SUCCESSFUL:
            return {
                ...state,
                organization: action.organization,
                team_members: action.teamMembers,
                team_invitations: action.teamInvitations,
                network_activity: state.network_activity.filter((item) => item !== "get_organization")
            };
        case types.GET_ORGANIZATION_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "get_organization")
            };
        // case types.INVITE_TEAM_MEMBER_IN_PROGRESS:
        //   return {
        //     ...state,
        //     network_activity: [...state.network_activity, "invite_team_member"]
        //   };
        // case types.INVITE_TEAM_MEMBER_SUCCESSFUL:
        //   return {
        //     ...state,
        //     network_activity: state.network_activity.filter((item) => item !== "invite_team_member")
        //   };
        // case types.INVITE_TEAM_MEMBER_FAILED:
        //   return {
        //     ...state,
        //     network_activity: state.network_activity.filter((item) => item !== "invite_team_member")
        //   };
        case types.UPDATE_ORGANIZATION_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, "update_organization"]
            };
        case types.UPDATE_ORGANIZATION_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "update_organization")
            };
        case types.UPDATE_ORGANIZATION_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== "update_organization")
            };
        case types.DELETE_TEAM_MEMBER_IN_PROGRESS:
            return {
                ...state,
                network_activity: [...state.network_activity, `delete_team_member_${action.token}`]
            };
        case types.DELETE_TEAM_MEMBER_SUCCESSFUL:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== `delete_team_member_${action.token}`)
            };
        case types.DELETE_TEAM_MEMBER_FAILED:
            return {
                ...state,
                network_activity: state.network_activity.filter((item) => item !== `delete_team_member_${action.token}`)
            };
        case types.LOGOUT:
            return {
                ...state,
                access_token: null,
                refresh_token: null,
                user: null,
                network_activity: [],
                organization: null,
                team_members: [],
                team_invitations: []
            };
        case types.CLEAR_STATE:
            return {
                ...state,
                access_token: null,
                refresh_token: null,
                user: null,
                network_activity: [],
                organization: null,
                team_members: [],
                team_invitations: []
            };
        default:
            return state;
    }
};

export default iamReducer;
