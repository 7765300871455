import { Component } from "react";
import { connect } from "react-redux";
import { ENVIRONMENT } from "../../settings/config";
import { isGtagRegistrationEventSent } from "../../store/trackers/selectors";
import { sendGtagRegistration } from "../../store/trackers/creators";

class GtagRegistration extends Component {
  componentDidMount() {
    if (ENVIRONMENT === "prod") {
      if (!this.props.isGtagRegistrationEventSent) {
        window.gtag("event", "conversion", { send_to: "AW-1071933170/qYnSCMjMr2sQ8s2R_wM" });
        this.props.sendGtagRegistration();
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  isGtagRegistrationEventSent: isGtagRegistrationEventSent(state)
});

export default connect(mapStateToProps, {
  sendGtagRegistration
})(GtagRegistration);
