import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SiteSpeedBox from "../../components/Dashboard/SiteSpeed/SiteSpeedBox";
import { fetchLoadingTimeForDashboard } from "../../store/loading_time/operations";
import { fetchMonitorLocations } from "../../store/monitor_locations/operations";
import { changeSelectedRegion } from "../../store/loading_time/creators";
import {
  isFetchingLoadingTimeForAllRegions,
  getLoadingTimeForUrl,
  hasLoadingTimeDataForDashboard
} from "../../store/loading_time/selectors";
import { changeSelectedInterval } from "../../store/loading_time/creators";
import { getSelectedWebsiteUrl, getSelectedWebsiteRegions } from "../../store/tracked_websites/selectors";
import { getMonitorLocations } from "../../store/monitor_locations/selectors";
import { DASHBOARD_LONG_POLLING_INTERVAL_IN_MS } from "../../settings/config";

class SiteSpeedBoxContainer extends Component {
  constructor(props) {
    super(props);

    this.refreshInterval = null;
  }

  componentDidMount() {
    this.props.fetchMonitorLocations();
    this.props.changeSelectedRegion(this.props.url, this.props.regions[0]);
    this.props.changeSelectedInterval(this.props.url, "custom_interval");

    this.props.fetchLoadingTimeForDashboard(this.props.url, this.props.startDate, this.props.endDate);
    if (this.props.shouldLongPolling) {
      this.refreshInterval = setInterval(_ => {
        if (this.props.hasData || !this.props.shouldLongPolling) {
          clearInterval(this.refreshInterval);
        } else {
          this.props.fetchLoadingTimeForDashboard(this.props.url, this.props.startDate, this.props.endDate);
        }
      }, DASHBOARD_LONG_POLLING_INTERVAL_IN_MS);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  render() {
    return (
      <SiteSpeedBox
        isFetching={this.props.isFetching}
        push={this.props.push}
        loadingTimeData={this.props.loadingTimeData}
        hasData={this.props.hasData}
        regions={this.props.regions}
        monitorLocations={this.props.monitorLocations}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetching: isFetchingLoadingTimeForAllRegions(state),
  url: getSelectedWebsiteUrl(state),
  regions: getSelectedWebsiteRegions(state),
  loadingTimeData: getLoadingTimeForUrl(state),
  hasData: hasLoadingTimeDataForDashboard(state),
  monitorLocations: getMonitorLocations(state)
});

export default connect(mapStateToProps, {
  fetchLoadingTimeForDashboard,
  fetchMonitorLocations,
  changeSelectedRegion,
  changeSelectedInterval,
  push
})(SiteSpeedBoxContainer);
