import { TrackedKeyword, TopDomain, TrackedKeywordOverviewData, KeywordsOverviewChartDataRecord, TrackedKeywordsTableRecord } from './types';

const numberFormatter = new Intl.NumberFormat('en-US');

export function getChartDataFromKeywordsOverview(overview: TrackedKeywordOverviewData[]): KeywordsOverviewChartDataRecord[] {
    return overview.map((overviewData, index) => ({
        graphPos: overviewData.pos !== null ? overviewData.pos : 100,
        tooltipPos: overviewData.pos !== null ? overviewData.pos : 101,
        crawledAt: overviewData.c_at,
    }));
}

export function getTableDataFromKeywords(keywords: TrackedKeyword[]): TrackedKeywordsTableRecord[] {
    return [...keywords]
        .sort((first, second) => (first.c_at < second.c_at ? -1 : first.c_at > second.c_at ? 1 : 0))
        .map((keyword, idx) => ({
            key: idx + 1,
            keyword: keyword.phrase,
            location: keyword.location_name,
            languageCode: keyword.language_code,
            position: keyword.pos,
            url: keyword.url,
            previousPosition: keyword.prev_pos !== null && typeof keyword.prev_pos !== 'undefined' ? keyword.prev_pos : 0,
            searchVolume: keyword.vol !== null && typeof keyword.vol !== 'undefined' ? numberFormatter.format(keyword.vol) : '-',
            cpc: keyword.cpc !== null && typeof keyword.cpc !== 'undefined' ? numberFormatter.format(keyword.cpc) : '-',
            resultsNo: keyword.r_no !== null && typeof keyword.r_no !== 'undefined' ? numberFormatter.format(keyword.r_no) : '-',
            domain: keyword.domain,
            status: keyword.status,
            topDomainsCount: keyword.td_count
        }));
}

export function getTableDataFromTopDomains(topDomains: TopDomain[]) {
    return topDomains.map((topDomain, index) => ({
        key: index + 1,
        domain: topDomain.domain,
        url: topDomain.url,
    }));
}
