import React from "react";
import { Progress } from "antd";

const DashboardSeoScore = ({ seoScore, sectionsStatusCounter }) => {
  const getColorByScore = score => {
    if (score < 50) return "#f63842";
    if (score >= 50 && score < 75) return "#ffab00";
    if (score >= 75) return "#34bc6e";
  };

  const getSectionsStatusProgress = _ => {   
    let totalCount = sectionsStatusCounter.passed + sectionsStatusCounter.failed + sectionsStatusCounter.warning;
    return {
      passed: (sectionsStatusCounter.passed * 100) / totalCount,
      warning: (sectionsStatusCounter.warning * 100) / totalCount,
      failed: (sectionsStatusCounter.failed * 100) / totalCount
    };
  };

  return (
    <div className="dashboard-summary-graph">
      <div className="dashboard-summary-graph-split">
        <div className="progress-item failed" data-cy="main-url-failed-counter">
          <span className="progress-label">
            {sectionsStatusCounter.failed} Failed
          </span>
          <Progress percent={getSectionsStatusProgress().failed} showInfo={false} />
        </div>

        <div className="progress-item warning">
          <span className="progress-label" data-cy="main-url-warning-counter">
            {sectionsStatusCounter.warning} Warnings
          </span>
          <Progress percent={getSectionsStatusProgress().warning} showInfo={false} />
        </div>

        <div className="progress-item passed">
          <span className="progress-label" data-cy="main-url-passed-counter">
            {sectionsStatusCounter.passed} Passed
          </span>
          <Progress percent={getSectionsStatusProgress().passed} showInfo={false} />
        </div>
      </div>

      <div className="dashboard-summary-graph-total">
        <Progress
          width={100}
          type="circle"
          percent={seoScore}
          strokeWidth={8}
          strokeColor={getColorByScore(seoScore)}
          format={percent => `${percent}/100`}
          data-cy="main-url-score"
        />
        <span className="dashboard-progress-label">Score</span>
      </div>
    </div>
  );
};

export default DashboardSeoScore;
