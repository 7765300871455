export const FETCH_TOP_KEYWORDS_OVERVIEW_IN_PROGRESS = "FETCH_TOP_KEYWORDS_OVERVIEW_IN_PROGRESS";
export const FETCH_TOP_KEYWORDS_OVERVIEW_SUCCESSFUL = "FETCH_TOP_KEYWORDS_OVERVIEW_SUCCESSFUL";
export const FETCH_TOP_KEYWORDS_OVERVIEW_FAILED = "FETCH_TOP_KEYWORDS_OVERVIEW_FAILED";

export const FETCH_TOP_KEYWORDS_IN_PROGRESS = "FETCH_TOP_KEYWORDS_IN_PROGRESS";
export const FETCH_TOP_KEYWORDS_SUCCESSFUL = "FETCH_TOP_KEYWORDS_SUCCESSFUL";
export const FETCH_TOP_KEYWORDS_FAILED = "FETCH_TOP_KEYWORDS_FAILED";

export const FETCH_TOP_KEYWORDS_FOR_DASHBOARD_IN_PROGRESS = "FETCH_TOP_KEYWORDS_FOR_DASHBOARD_IN_PROGRESS";
export const FETCH_TOP_KEYWORDS_FOR_DASHBOARD_FINISHED = "FETCH_TOP_KEYWORDS_FOR_DASHBOARD_FINISHED";
