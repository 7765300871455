import React from 'react'

function Footer({footerMessage}) {
    return (
        <div className="template-footer">            
            <div className="step-counter flexbox align-center justify-center">3</div>
            <span>{ footerMessage ? footerMessage : 'Sample text in the footer' }</span>
        </div>
    )
}

export default Footer
