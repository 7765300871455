import React, { Component } from "react";
import { connect } from "react-redux";
import {
  isWebsiteLastAddedAndHasNoSeoReportData,
  isWebsiteAddedInTheLastHour
} from "../../store/tracked_websites/selectors";
import DashboardTabContent from "../../components/Dashboard/DashboardTabContent";

export class Dashboard extends Component {
  render() {
    return (
      <DashboardTabContent showAlert={this.props.showAlert} shouldLongPolling={this.props.shouldWebsiteBeLongPolled} />
    );
  }
}

const mapStateToProps = state => ({
  showAlert: isWebsiteLastAddedAndHasNoSeoReportData(state),
  shouldWebsiteBeLongPolled: isWebsiteAddedInTheLastHour(state)
});

export default connect(mapStateToProps, {})(Dashboard);
