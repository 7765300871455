import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  isFetchingDomainUrls,
  getDomainUrls,
  getDomainUrlsNextToken,
  getSelectedDomain,
} from "../../store/analysis/selectors";
import { fetchDomainUrls } from "../../store/analysis/operations";
import { getTrackedWebsitesDomains } from "../../store/tracked_websites/selectors";
import { changeSelectedDomain } from "../../store/analysis/creators";
import { changeSelectedReportUrl, changeSelectedReportGuid } from "../../store/white_label/creators";
import { getSelectedReportUrl } from "../../store/white_label/selectors";
import SavedReportsSelector from "../../components/WhiteLabel/report/SavedReportsSelector";

class SavedReportsSelectorContainer extends Component {
  render() {
    return (
      <SavedReportsSelector
        selectedReportGuid={this.props.selectedReportGuid}
        sendSelectedUrl={this.props.sendSelectedUrl}
        domains={this.props.domains}
        isFetchingDomainUrls={this.props.isFetchingDomainUrls}
        domainUrls={this.props.domainUrls}
        hasMoreUrls={this.props.hasMoreUrls}
        fetchDomainUrls={this.props.fetchDomainUrls}
        changeSelectedDomain={this.props.changeSelectedDomain}
        changeSelectedReportUrl={this.props.changeSelectedReportUrl}
        changeSelectedReportGuid={this.props.changeSelectedReportGuid}
        selectedDomain={this.props.selectedDomain}
        queryParams={this.props.queryParams}
        selectedReportUrl={this.props.selectedReportUrl}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  domains: getTrackedWebsitesDomains(state),
  isFetchingDomainUrls: isFetchingDomainUrls(state),
  domainUrls: getDomainUrls(state),
  hasMoreUrls: getDomainUrlsNextToken(state),
  selectedDomain: getSelectedDomain(state),
  queryParams: state.router.location.query,
  selectedReportUrl: getSelectedReportUrl(state),
});
export default connect(mapStateToProps, {
  fetchDomainUrls,
  changeSelectedDomain,
  changeSelectedReportUrl,
  changeSelectedReportGuid,
  push,
})(SavedReportsSelectorContainer);
