export const FETCH_LOADING_TIME_IN_PROGRESS = "FETCH_LOADING_TIME_IN_PROGRESS";
export const FETCH_LOADING_TIME_SUCCESSFUL = "FETCH_LOADING_TIME_SUCCESSFUL";
export const FETCH_LOADING_TIME_FAILED = "FETCH_LOADING_TIME_FAILED";
export const CHANGE_SELECTED_INTERVAL = "CHANGE_SELECTED_INTERVAL";
export const CHANGE_SELECTED_REGION = "CHANGE_SELECTED_REGION";

export const FETCH_LOADING_TIME_FOR_DASHBOARD_IN_PROGRESS = "FETCH_LOADING_TIME_FOR_DASHBOARD_IN_PROGRESS";
export const FETCH_LOADING_TIME_FOR_DASHBOARD_FINISHED = "FETCH_LOADING_TIME_FOR_DASHBOARD_FINISHED";

export const SEND_LOADING_TIME_REQUEST_IN_PROGRESS = "SEND_LOADING_TIME_REQUEST_IN_PROGRESS";
export const SEND_LOADING_TIME_REQUEST_SUCCESSFUL = "SEND_LOADING_TIME_REQUEST_SUCCESSFUL";
export const SEND_LOADING_TIME_REQUEST_FAILED = "SEND_LOADING_TIME_REQUEST_FAILED";
