import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import "./GoogleSearchPreview.less";

const displayResult = (url, title, description) => (
  <div>
    <span className="stats-title">Desktop version</span>
    <div className="google-search-preview desktop">
      <span className="url-format">{url}</span>
      <span className="title-format line-clamp lines1">{title}</span>
      <span className="description-format line-clamp lines2">{description}</span>
    </div>

    <span className="stats-title">Mobile version</span>
    <div className="google-search-preview mobile">
      <span className="url-format">{url}</span>
      <span className="title-format line-clamp lines2">{title}</span>
      <span className="description-format line-clamp lines3">{description}</span>
    </div>
  </div>
);

const displayCompetitorResult = result => displayResult(result.url, result.title, result.description);

const GooglePreviewSection = ({ sectionKey, title, result, tooltip, relevance, competitorResults }) => {
  return (
    <div id={sectionKey} className="report-element default">
      <SectionTitle title={title} />

      <div className="report-element-description">
        {displayResult(result.url, result.title, result.description)}
        <SectionCompetitorResults
          results={competitorResults}
          relevance={relevance}
          render={displayCompetitorResult}
          hideMessage={true}
          hideUrl={true}
        />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default GooglePreviewSection;
