import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import { getGoogleSearchUrl } from "../../utils";

const displayResult = (result) => {
  if(result.related_keywords && result.related_keywords.length > 0){
    return (
      <div className="description-meta">
        <ul>
        {result.related_keywords.map((tag, idx) => (
          <li key={idx}>
            <a href={getGoogleSearchUrl(tag)} target="_blank" rel="noopener noreferrer">{tag}</a>
          </li>
        ))}
        </ul>
      </div>
    )
  }
  return null;  
}

const RelatedKeywordsSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0; 

  return (
    <div id={sectionKey} className="report-element default">
      <SectionTitle title={title} />

      <div className="report-element-description">
        <SectionMessage url={url} status={result.status} relevance={relevance} message={result.message} hasCompetitors={hasCompetitors} />        
        {displayResult(result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} render={displayResult} />        
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default RelatedKeywordsSection;
