import moment from "moment";

import {
  INIT_ANALYSIS_IN_PROGRESS,
  INIT_ANALYSIS_SUCCESSFUL,
  CREATE_REPORT_SUCCESSFUL,
  CREATE_REPORT_FAILED,
  CREATE_COMPETITOR_REPORT_SUCCESSFUL,
  CREATE_COMPETITOR_REPORT_FAILED,
  GET_REPORT_SECTION_IN_PROGRESS,
  GET_REPORT_SECTION_SUCCESSFUL,
  GET_REPORT_SECTION_FAILED,
  GET_COMPETITOR_REPORT_SECTION_IN_PROGRESS,
  GET_COMPETITOR_REPORT_SECTION_SUCCESSFUL,
  GET_COMPETITOR_REPORT_SECTION_FAILED,
  GET_ANALYSIS_REPORT_STRUCTURE_IN_PROGRESS,
  GET_ANALYSIS_REPORT_STRUCTURE_SUCCESSFUL,
  GET_ANALYSIS_REPORT_STRUCTURE_FAILED,
  CLEAR_REPORT_IN_PROGRESS,
  SAVE_REPORT_IN_PROGRESS,
  SAVE_REPORT_SUCCESS,
  SAVE_REPORT_FAILED,
  FETCH_SAVED_REPORT_IN_PROGRESS,
  FETCH_SAVED_REPORT_SUCCESSFUL,
  FETCH_SAVED_REPORT_FAILED,
  FETCH_OVERVIEWS_IN_PROGRESS,
  FETCH_OVERVIEWS_SUCCESSFUL,
  FETCH_OVERVIEWS_FAILED,
  FETCH_LAST_SAVED_REPORT_IN_PROGRESS,
  FETCH_LAST_SAVED_REPORT_FINISHED,
  FETCH_DOMAIN_URLS_IN_PROGRESS,
  FETCH_DOMAIN_URLS_SUCCESSFUL,
  FETCH_DOMAIN_URLS_FAILED,
  CHANGE_SELECTED_DOMAIN,
  START_BACKGROUND_ANALYSIS_FAILED,
  START_BACKGROUND_ANALYSIS_IN_PROGRESS,
  START_BACKGROUND_ANALYSIS_SUCCESS,
  FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_IN_PROGRESS,
  FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_FINISHED,
  CHANGE_ARCHIVED_REPORTS_INTERVAL
} from "./types";

import { LOGOUT } from "../iam/types";

const initial_state = {
  sections: {},
  network_activity: [],
  report_in_progress: null,
  reports: {},
  overviews: {},
  urls: {},
  background: {},
  archived_reports_interval: {
    start_date: moment()
      .startOf("month")
      .format("YYYY-MM-DDT00:00:00"),
    end_date: moment().format("YYYY-MM-DDTHH:mm:ss")
  }
};

const network_activity_messages = {
  categorized_sections: "fetching_categorized_sections",
  create_report: "generating_report_",
  fetch_section_result: "fetching_section_",
  fetch_saved_report: "fetching_saved_report",
  fetch_overviews: "fetching_overviews_for_url",
  fetch_last_saved_report: "fetching_last_saved_report_for_url_",
  fetch_domain_urls: "fetching_domain_urls_for_",
  start_background_analysis: "start_background_analysis_",
  fetch_overviews_for_main_url_and_competitors: "fetching_overviews_for_main_url_and_competitors"
};

const analysisReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_ANALYSIS_REPORT_STRUCTURE_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, network_activity_messages["categorized_sections"]]
      };
    case GET_ANALYSIS_REPORT_STRUCTURE_SUCCESSFUL:
      return {
        ...state,
        sections: action.analysis_general_structure,
        network_activity: state.network_activity.filter(
          (item) => item !== network_activity_messages["categorized_sections"]
        )
      };
    case GET_ANALYSIS_REPORT_STRUCTURE_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== network_activity_messages["categorized_sections"]
        )
      };
    // case CREATE_REPORT_IN_PROGRESS:
    //   return {
    //     ...state,
    //     network_activity: [...state.network_activity, "generating report"]
    //   };
    case INIT_ANALYSIS_IN_PROGRESS:
      return {
        ...state,
        report_in_progress: action.guid,
        reports: {
          ...state.reports,
          [action.guid]: {
            url: action.url,
            status: "running",
            sections: action.sections,
            section_results: {},
            section_errors: [],
            network_activity: [
              `generating report for ${action.url}`,
              ...action.competitor_urls.map((competitor_url) => `generating report for ${competitor_url}`)
            ],
            competitor_urls: action.competitor_urls,
            competitors: {}
          }
        }
      };
    case INIT_ANALYSIS_SUCCESSFUL:
      let newReports = Object.keys(state.reports).reduce((result, key) => {
        if (key !== action.temp_guid) {
          result[key] = state.reports[key];
        }
        return result;
      }, {});
      newReports[action.guid] = {
        ...state.reports[action.temp_guid],
        created_at: action.created_at
      };

      return {
        ...state,
        report_in_progress: action.guid,
        reports: newReports
      };
    case CREATE_REPORT_SUCCESSFUL:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            sections: action.sections,
            network_activity: state.reports[action.guid]["network_activity"].filter(
              (item) => item !== `generating report for ${action.url}`
            )
          }
        }
      };
    case CREATE_REPORT_FAILED: {
      const propertyToRemove = action.guid;
      const { [propertyToRemove]: reportToBeRemoved, ...updatedReports } = state.reports;

      return {
        ...state,
        report_in_progress: null,
        reports: {
          ...updatedReports
        }
      };
    }
    // case CREATE_COMPETITOR_REPORT_IN_PROGRESS:
    //   return {
    //     ...state,
    //     reports: {
    //       ...state.reports,
    //       [action.main_url_guid]: {
    //         ...state.reports[action.main_url_guid],
    //         network_activity: [
    //           ...state.reports[action.main_url_guid].network_activity,
    //           `generating report for ${action.competitor_url}`
    //         ]
    //       }
    //     }
    //   };
    case SAVE_REPORT_IN_PROGRESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            network_activity: [...state.reports[action.guid]["network_activity"], `save report ${action.guid}`]
          }
        }
      };
    case SAVE_REPORT_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            network_activity: state.reports[action.guid]["network_activity"].filter(
              (item) => item !== `save report ${action.guid}`
            )
          }
        }
      };
    case SAVE_REPORT_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            network_activity: state.reports[action.guid]["network_activity"].filter(
              (item) => item !== `save report ${action.guid}`
            )
          }
        }
      };
    case CREATE_COMPETITOR_REPORT_SUCCESSFUL:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.main_url_guid]: {
            ...state.reports[action.main_url_guid],
            network_activity: state.reports[action.main_url_guid]["network_activity"].filter(
              (item) => item !== `generating report for ${action.competitor_url}`
            ),
            competitors: {
              ...state.reports[action.main_url_guid].competitors,
              [action.competitor_guid]: {
                url: action.competitor_url,
                section_results: {},
                section_errors: [],
                network_activity: []
              }
            }
          }
        }
      };
    case CREATE_COMPETITOR_REPORT_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.main_url_guid]: {
            ...state.reports[action.main_url_guid],
            network_activity: state.reports[action.main_url_guid].network_activity.filter(
              (item) => item !== `generating report for ${action.competitor_url}`
            ),
            competitor_urls: state.reports[action.main_url_guid].competitor_urls.filter(
              (competitor_url) => competitor_url !== action.competitor_url
            )
          }
        }
      };
    case GET_REPORT_SECTION_IN_PROGRESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            network_activity: [
              ...state.reports[action.guid]["network_activity"],
              `fetching ${action.section_key} for ${action.guid}`
            ]
          }
        }
      };
    case GET_REPORT_SECTION_SUCCESSFUL:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            section_results: {
              ...state.reports[action.guid].section_results,
              [action.section_key]: action.section_result
            },
            network_activity: state.reports[action.guid]["network_activity"].filter(
              (item) => item !== `fetching ${action.section_key} for ${action.guid}`
            )
          }
        }
      };
    case GET_REPORT_SECTION_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            section_errors: [...state.reports[action.guid].section_errors, action.section_key], //***
            network_activity: state.reports[action.guid]["network_activity"].filter(
              (item) => item !== `fetching ${action.section_key} for ${action.guid}`
            )
          }
        }
      };
    case GET_COMPETITOR_REPORT_SECTION_IN_PROGRESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.main_url_guid]: {
            ...state.reports[action.main_url_guid],
            competitors: {
              ...state.reports[action.main_url_guid].competitors,
              [action.competitor_guid]: {
                ...state.reports[action.main_url_guid].competitors[action.competitor_guid],
                network_activity: [
                  ...state.reports[action.main_url_guid].competitors[action.competitor_guid].network_activity,
                  `fetching ${action.section_key} for ${action.competitor_url}`
                ]
              }
            }
          }
        }
      };
    case GET_COMPETITOR_REPORT_SECTION_SUCCESSFUL:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.main_url_guid]: {
            ...state.reports[action.main_url_guid],
            competitors: {
              ...state.reports[action.main_url_guid].competitors,
              [action.competitor_guid]: {
                ...state.reports[action.main_url_guid].competitors[action.competitor_guid],
                section_results: {
                  ...state.reports[action.main_url_guid].competitors[action.competitor_guid].section_results,
                  [action.section_key]: action.section_result
                },
                network_activity: state.reports[action.main_url_guid].competitors[
                  action.competitor_guid
                ].network_activity.filter(
                  (item) => item !== `fetching ${action.section_key} for ${action.competitor_url}`
                )
              }
            }
          }
        }
      };
    case GET_COMPETITOR_REPORT_SECTION_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.main_url_guid]: {
            ...state.reports[action.main_url_guid],
            competitors: {
              ...state.reports[action.main_url_guid].competitors,
              [action.competitor_guid]: {
                ...state.reports[action.main_url_guid].competitors[action.competitor_guid],
                section_errors: [
                  ...state.reports[action.main_url_guid].competitors[action.competitor_guid].section_errors,
                  action.section_key
                ],
                network_activity: state.reports[action.main_url_guid].competitors[
                  action.competitor_guid
                ].network_activity.filter(
                  (item) => item !== `fetching ${action.section_key} for ${action.competitor_url}`
                )
              }
            }
          }
        }
      };
    case CLEAR_REPORT_IN_PROGRESS:
      return {
        ...state,
        report_in_progress: null,
        reports: {
          ...state.reports,
          [action.guid]: {
            ...state.reports[action.guid],
            status: "finished"
          }
        }
      };
    case FETCH_SAVED_REPORT_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${network_activity_messages["fetch_saved_report"]}_${action["guid"]}_${action["url"]}`
        ]
      };
    case FETCH_SAVED_REPORT_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_saved_report"]}_${action["guid"]}_${action["url"]}`
        ),
        reports: {
          ...state.reports,
          [action["guid"]]: action["report"]
        }
      };
    case FETCH_SAVED_REPORT_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_saved_report"]}_${action["guid"]}_${action["url"]}`
        ),
        reports: {
          ...state.reports,
          [action["guid"]]: { error: action["errorMessage"] }
        }
      };
    case FETCH_OVERVIEWS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [...state.network_activity, `${network_activity_messages["fetch_overviews"]}_${action.url}`]
      };
    case FETCH_OVERVIEWS_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_overviews"]}_${action.url}`
        ),
        overviews: {
          ...state["overviews"],
          [action.url]: action.overviews
        }
      };
    case FETCH_OVERVIEWS_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_overviews"]}_${action.url}`
        ),
        overviews: {
          ...state["overviews"],
          [action.url]: []
        }
      };
    case FETCH_LAST_SAVED_REPORT_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${network_activity_messages["fetch_last_saved_report"]}${action.url}`
        ]
      };
    case FETCH_LAST_SAVED_REPORT_FINISHED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_last_saved_report"]}${action.url}`
        )
      };
    case FETCH_DOMAIN_URLS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${network_activity_messages["fetch_domain_urls"]}${action.domain}`
        ]
      };
    case FETCH_DOMAIN_URLS_SUCCESSFUL:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_domain_urls"]}${action.domain}`
        ),
        urls: {
          ...state.urls,
          selected_domain: action.domain,
          [action.domain]: state.urls[action.domain]
            ? {
                ...state["urls"][action.domain],
                next_token: action.next_token,
                urls: [...state["urls"][action.domain]["urls"], ...action.urls]
              }
            : {
                next_token: action.next_token,
                urls: action.urls
              }
        }
      };
    case FETCH_DOMAIN_URLS_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["fetch_domain_urls"]}${action.domain}`
        ),
        urls: {
          ...state.urls,
          selected_domain: action.domain
        }
      };
    case CHANGE_SELECTED_DOMAIN:
      return {
        ...state,
        urls: {
          ...state.urls,
          selected_domain: action.domain
        }
      };
    case START_BACKGROUND_ANALYSIS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${network_activity_messages["start_background_analysis"]}_${action.url}`
        ]
      };
    case START_BACKGROUND_ANALYSIS_SUCCESS:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["start_background_analysis"]}_${action.url}`
        ),
        background: {
          ...state.background,
          [action.url]: action.guid
        }
      };
    case START_BACKGROUND_ANALYSIS_FAILED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) => item !== `${network_activity_messages["start_background_analysis"]}_${action.url}`
        )
      };
    case FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_IN_PROGRESS:
      return {
        ...state,
        network_activity: [
          ...state.network_activity,
          `${network_activity_messages["fetch_overviews_for_main_url_and_competitors"]}_${action.url}`
        ]
      };
    case FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_FINISHED:
      return {
        ...state,
        network_activity: state.network_activity.filter(
          (item) =>
            item !== `${network_activity_messages["fetch_overviews_for_main_url_and_competitors"]}_${action.url}`
        )
      };
    case CHANGE_ARCHIVED_REPORTS_INTERVAL:
      return {
        ...state,
        archived_reports_interval: {
          ...state["archived_reports_interval"],
          start_date: action["startDate"],
          end_date: action["endDate"]
        }
      };
    case LOGOUT:
      return {
        ...state,
        ...initial_state
      };
    default:
      return state;
  }
};

export default analysisReducer;
