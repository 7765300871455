import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";

const displayResult = (title, result) => {
  if (result.status === "passed" && result.structured_data) {
    return (
      <div className="description-meta">
        <InlineModal
          openLabel="See all microdata objects"
          modalTitle="Full list of structured data objects"
          closeLabel="Close">
          <ul className="bulleted-list">
            {result.structured_data.map((item, index) => (
              <React.Fragment key={index}>
                <li>
                  <strong>type: </strong>
                  {item.type}
                </li>
                <li>
                  <strong>properties: </strong>
                </li>
                {Object.entries(item.properties).map(entry => {
                  return (
                    <li style={{ marginLeft: "40px" }} key={`${entry[0]}-${index}`}>
                      <strong>{entry[0]}: </strong>
                      {entry[1]}
                    </li>
                  );
                })}
              </React.Fragment>
            ))}
          </ul>
        </InlineModal>
      </div>
    );
  }
  return <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />;
};

const StructuredDataSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default StructuredDataSection;
