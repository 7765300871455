export const CREATE_REPORT_IN_PROGRESS = "CREATE_REPORT_IN_PROGRESS";
export const CREATE_REPORT_SUCCESSFUL = "CREATE_REPORT_SUCCESSFUL";
export const CREATE_REPORT_FAILED = "CREATE_REPORT_FAILED";

export const CREATE_COMPETITOR_REPORT_IN_PROGRESS = "CREATE_COMPETITOR_REPORT_IN_PROGRESS";
export const CREATE_COMPETITOR_REPORT_SUCCESSFUL = "CREATE_COMPETITOR_REPORT_SUCCESSFUL";
export const CREATE_COMPETITOR_REPORT_FAILED = "CREATE_COMPETITOR_REPORT_FAILED";

export const GET_REPORT_SECTION_IN_PROGRESS = "GET_REPORT_SECTION_IN_PROGRESS";
export const GET_REPORT_SECTION_SUCCESSFUL = "GET_REPORT_SECTION_SUCCESSFUL";
export const GET_REPORT_SECTION_FAILED = "GET_REPORT_SECTION_FAILED";

export const GET_COMPETITOR_REPORT_SECTION_IN_PROGRESS = "GET_COMPETITOR_REPORT_SECTION_IN_PROGRESS";
export const GET_COMPETITOR_REPORT_SECTION_SUCCESSFUL = "GET_COMPETITOR_REPORT_SECTION_SUCCESSFUL";
export const GET_COMPETITOR_REPORT_SECTION_FAILED = "GET_COMPETITOR_REPORT_SECTION_FAILED";

export const GET_ANALYSIS_REPORT_STRUCTURE = "GET_ANALYSIS_REPORT_STRUCTURE";
export const GET_ANALYSIS_REPORT_STRUCTURE_IN_PROGRESS = "GET_ANALYSIS_REPORT_STRUCTURE_IN_PROGRESS";
export const GET_ANALYSIS_REPORT_STRUCTURE_SUCCESSFUL = "GET_ANALYSIS_REPORT_STRUCTURE_SUCCESSFUL";
export const GET_ANALYSIS_REPORT_STRUCTURE_FAILED = "GET_ANALYSIS_REPORT_STRUCTURE_FAILED";

export const GET_COMPETITORS_URLS = "GET_COMPETITORS_URLS";

export const INIT_ANALYSIS_IN_PROGRESS = "INIT_ANALYSIS_IN_PROGRESS";
export const INIT_ANALYSIS_SUCCESSFUL = "INIT_ANALYSIS_SUCCESSFUL";
export const INIT_ANALYSIS_FAILED = "INIT_ANALYSIS_FAILED";

export const CLEAR_REPORT_IN_PROGRESS = "CLEAR_REPORT_IN_PROGRESS";

export const SAVE_REPORT_IN_PROGRESS = "SAVE_REPORT_IN_PROGRESS";
export const SAVE_REPORT_SUCCESS = "SAVE_REPORT_SUCCESS";
export const SAVE_REPORT_FAILED = "SAVE_REPORT_FAILED";

export const FETCH_SAVED_REPORT_IN_PROGRESS = "FETCH_SAVED_REPORT_IN_PROGRESS";
export const FETCH_SAVED_REPORT_SUCCESSFUL = "FETCH_SAVED_REPORT_SUCCESSFUL";
export const FETCH_SAVED_REPORT_FAILED = "FETCH_SAVED_REPORT_FAILED";

export const FETCH_OVERVIEWS_IN_PROGRESS = "FETCH_OVERVIEWS_IN_PROGRESS";
export const FETCH_OVERVIEWS_SUCCESSFUL = "FETCH_OVERVIEWS_SUCCESSFUL";
export const FETCH_OVERVIEWS_FAILED = "FETCH_OVERVIEWS_FAILED";

export const FETCH_LAST_SAVED_REPORT_IN_PROGRESS = "FETCH_LAST_SAVED_REPORT_IN_PROGRESS";
export const FETCH_LAST_SAVED_REPORT_FINISHED = "FETCH_LAST_SAVED_REPORT_FINISHED";

export const FETCH_DOMAIN_URLS_IN_PROGRESS = "FETCH_DOMAIN_URLS_IN_PROGRESS";
export const FETCH_DOMAIN_URLS_SUCCESSFUL = "FETCH_DOMAIN_URLS_SUCCESSFUL";
export const FETCH_DOMAIN_URLS_FAILED = "FETCH_DOMAIN_URLS_FAILED";

export const CHANGE_SELECTED_DOMAIN = "CHANGE_SELECTED_DOMAIN";

export const START_BACKGROUND_ANALYSIS_IN_PROGRESS = "START_BACKGROUND_ANALYSIS_IN_PROGRESS";
export const START_BACKGROUND_ANALYSIS_SUCCESS = "START_BACKGROUND_ANALYSIS_SUCCESS";
export const START_BACKGROUND_ANALYSIS_FAILED = "START_BACKGROUND_ANALYSIS_FAILED";

export const FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_IN_PROGRESS =
  "FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_IN_PROGRESS";
export const FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_FINISHED =
  "FETCH_OVERVIEWS_FOR_MAIN_URL_AND_COMPETITORS_FINISHED";

export const CHANGE_ARCHIVED_REPORTS_INTERVAL = "CHANGE_ARCHIVED_REPORTS_INTERVAL";
