import { Component } from "react";
import { connect } from "react-redux";
import { ENVIRONMENT } from "../../settings/config";
import { userSubscription } from "../../store/sales/selectors";
import { isGtagPaymentMethodEventSent } from "../../store/trackers/selectors";
import { sendGtagPaymentMethod } from "../../store/trackers/creators";

class GtagPaymentMethod extends Component {
  componentDidMount() {
    if (ENVIRONMENT === "prod") {
      if (
        !this.props.isGtagPaymentMethodEventSent &&
        this.props.subscription &&
        this.props.subscription["plan_settings"]
      ) {
        window.gtag("event", "conversion", {
          send_to: "AW-1071933170/7d47CLiAgV0Q8s2R_wM",
          value: this.props.subscription["plan_settings"]["price"] / 100,
          currency: "USD",
          transaction_id: ""
        });

        this.props.sendGtagPaymentMethod();
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  isGtagPaymentMethodEventSent: isGtagPaymentMethodEventSent(state),
  subscription: userSubscription(state)
});

export default connect(mapStateToProps, {
  sendGtagPaymentMethod
})(GtagPaymentMethod);
