export const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

export const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) {
    return false;
  }

  if (rest.length === 0 && obj.hasOwnProperty(level)) {
    return true;
  }

  return checkNested(obj[level], ...rest);
};
