import { createApi } from '@reduxjs/toolkit/query/react';
import { SSC_SEM_API_URL, TRACKED_WEBSITES_API_URL } from '../settings/config';
import { authRequest } from '../store/authorizedRequests';
import {
    AddTrackedWebsitesParams,
    AddUrlToPreCrawlingQueueParams,
    BlacklistResponse,
    CheckDomainsBlacklistParams,
    DeleteTrackedWebsiteParams,
    MonitorRegionsData,
    SeoLocationData,
    SendOnboardingEventParams,
    TrackedWebsite,
    TrackedWebsiteSettings,
    TrackedWebsitesResult,
    UpdateTrackedWebsitesParams,
    SendOnboardingEventResponse,
} from './types';

const trackedWebsitesBaseUrl = `${TRACKED_WEBSITES_API_URL}`;
const TRACKED_WEBSITES_REFETCH_TIME = 7200;

const locationsAndLanguagesBaseUrl = `${SSC_SEM_API_URL}/dfs/locations`;
const LOCATIONS_AND_LANGUAGES_REFETCH_TIME = 7200;

export const trackedWebsitesApi = createApi({
    reducerPath: 'api.trackedWebsites',
    keepUnusedDataFor: TRACKED_WEBSITES_REFETCH_TIME,
    tagTypes: ['TrackedWebsites'],

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;
        try {
            const data = await authRequest(trackedWebsitesBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            if (typeof data === 'undefined') {
                return { data: null };
            }

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchTrackedWebsites: builder.query<TrackedWebsitesResult, void>({
            query: () => ({
                url: '',
                method: 'GET',
            }),

            // Logic for old redux
            // Needed for dashboard page (the page uses old tracked websites redux store)
            onQueryStarted: async (arg, api) => {
                const { dispatch, queryFulfilled } = api;

                try {
                    const { data } = await queryFulfilled;
                    dispatch({
                        type: 'GET_TRACKED_WEBSITES_SUCCESS',
                        tracked_websites: data.tracked_websites,
                    });
                } catch (error) {}
            },

            providesTags: ['TrackedWebsites'],
        }),

        addTrackedWebsite: builder.mutation<TrackedWebsite, AddTrackedWebsitesParams>({
            query: (arg) => ({
                url: '',
                method: 'POST',
                body: {
                    url: arg.url,
                    settings: {
                        disabled_by_system: false,
                        location_name: arg.locationName || 'United States',
                        language_code: arg.languageCode || 'en',
                        competitors: arg.competitors,
                        regions: arg.regions.length > 0 ? arg.regions : ['us-east-1'],
                    },
                },
            }),

            // Logic for old redux
            // Needed for dashboard page (the page uses old tracked websites redux store)
            onQueryStarted: async (arg, api) => {
                const { dispatch, queryFulfilled } = api;

                try {
                    const { data: trackedWebsite } = await queryFulfilled;
                    dispatch({
                        type: 'ADD_TRACKED_WEBSITE_SUCCESS',
                        trackedWebsite: trackedWebsite,
                    });
                } catch (errror) {}
            },

            invalidatesTags: ['TrackedWebsites'],
        }),

        updateTrackedWebsiteSettings: builder.mutation<TrackedWebsiteSettings, UpdateTrackedWebsitesParams>({
            query: (arg) => {
                const { guid, ...rest } = arg;
                return {
                    url: '',
                    method: 'PATCH',
                    body: rest,
                };
            },

            // Logic for old redux
            // Needed for dashboard page (the page uses old tracked websites redux store)
            onQueryStarted: async (arg, api) => {
                const { guid } = arg;
                const { dispatch, queryFulfilled } = api;

                try {
                    const { data } = await queryFulfilled;
                    dispatch({
                        type: 'CHANGE_TRACKED_WEBSITE_SETTINGS_SUCCESSFUL',
                        guid,
                        actionName: 'undefined',
                        updatedSettings: data,
                    });
                } catch (error) {}
            },

            invalidatesTags: ['TrackedWebsites'],
        }),

        deleteTrackedWebsite: builder.mutation<void, DeleteTrackedWebsiteParams>({
            query: (arg) => {
                const { guid, ...rest } = arg;
                return {
                    url: '',
                    method: 'DELETE',
                    body: rest,
                };
            },

            // Logic for old redux
            // Needed for dashboard page (the page uses old tracked websites redux store)
            onQueryStarted: async (arg, api) => {
                const { url, guid } = arg;
                const { dispatch, queryFulfilled } = api;

                try {
                    await queryFulfilled;
                    dispatch({ type: 'DELETE_TRACKED_WEBSITE_SUCCESSFUL', url, guid });
                } catch (error) {}
            },

            invalidatesTags: ['TrackedWebsites'],
        }),

        checkDomainsBlacklist: builder.query<BlacklistResponse, CheckDomainsBlacklistParams>({
            query: (arg) => ({
                url: `/black-list?url=${arg.url}`,
                method: 'GET',
            }),
        }),

        sendOnboardingEvent: builder.mutation<SendOnboardingEventResponse, SendOnboardingEventParams>({
            query: (arg) => ({
                url: '/onboarding',
                method: 'POST',
                body: arg,
            }),
        }),

        addUrlToPreCrawlingQueue: builder.mutation<any, AddUrlToPreCrawlingQueueParams>({
            query: (arg) => ({
                url: '/precrawling-queue',
                method: 'POST',
                body: arg,
            }),
        }),

        fetchMonitorRegions: builder.query<MonitorRegionsData, void>({
            query: () => ({
                url: '/regions',
                method: 'GET',
            }),
        }),
    }),
});

export const locationsAndLanguagesApi = createApi({
    reducerPath: 'api.locationsAndLanguages',
    keepUnusedDataFor: LOCATIONS_AND_LANGUAGES_REFETCH_TIME,

    baseQuery: async (args, api, extraOptions) => {
        const { url, method, body, signal } = args;
        const { dispatch, getState } = api;
        try {
            const data = await authRequest(locationsAndLanguagesBaseUrl.concat(url), dispatch, getState, {
                method: method,
                body: JSON.stringify(body),
                signal: signal,
            });

            return { data };
        } catch (error) {
            return { error };
        }
    },

    endpoints: (builder) => ({
        fetchLocationsAndLanguages: builder.query<SeoLocationData[], void>({
            query: () => {
                return {
                    method: 'GET',
                    url: '',
                };
            },
        }),
    }),
});

export const {
    useFetchTrackedWebsitesQuery,
    useAddTrackedWebsiteMutation,
    useUpdateTrackedWebsiteSettingsMutation,
    useDeleteTrackedWebsiteMutation,
    useCheckDomainsBlacklistQuery,
    useAddUrlToPreCrawlingQueueMutation,
    useSendOnboardingEventMutation,
    useLazyCheckDomainsBlacklistQuery,
    useFetchMonitorRegionsQuery,
} = trackedWebsitesApi;

export const { useFetchLocationsAndLanguagesQuery } = locationsAndLanguagesApi;
