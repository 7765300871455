import './AddTrackedWebsiteWizard.less';
import React, { useState } from 'react';
import { Card, Steps } from 'antd';
import { AddTrackedWebsitesParams } from '../types';
import { getAvailableLanguagesForLocation, normalizeUrl } from '../utils';
import { extractKeywords } from '../../utils/keywords';
import { useNavigate } from '../../router/hooks/useNavigate';
import { useFetchLocationsAndLanguagesQuery, useFetchMonitorRegionsQuery, useSendOnboardingEventMutation } from '../service';
import { useGetMaxValuesForPlan } from '../../sales/hooks/useGetMaxValuesForPlan';
import { useFetchTrackedKeywordsCountQuery } from '../../trackedKeywords/service';
import OnboardingStartScreen from './steps/OnboardingStartScreen';
import SetWebsiteStep from './steps/SetWebsiteStep';
import SetLocationsStep from './steps/SetLocationsStep';
import SetCompetitorsStep, { Competitor } from './steps/SetCompetitorsStep';
import FinishedScreen from './steps/FinishedScreen';
import SetKeywordsStep from './steps/SetKeywordsStep';

const { Step } = Steps;

const wizardSteps = ['Website', 'Location', 'Competitors', 'Keywords'];

type Props = {
    fromOnboarding: boolean;
    onAddTrackedWebsite: (args: AddTrackedWebsitesParams) => void;
};

const initialState = {
    showStartScreen: false,
    currentStep: 0,
    selectedDomain: '',
    selectedUrlSchema: 'https://',
    selectedLocationName: 'United States',
    selectedLanguageCode: 'en',
    selectedMainRegion: 'us-east-1',
    selectedAdditionalRegions: [] as string[],
    selectedCompetitors: [] as Competitor[],
    selectedKeywords: '',
};

export default function AddTrackedWebsiteWizard({ fromOnboarding, onAddTrackedWebsite }: Props) {
    const [state, setState] = useState({ ...initialState, showStartScreen: fromOnboarding });
    const navigate = useNavigate();
    const [sendOnboardingEvent] = useSendOnboardingEventMutation();

    const { maxCompetitors, maxKeywords, maxRegions } = useGetMaxValuesForPlan();

    const { data: trackedKeywordsData } = useFetchTrackedKeywordsCountQuery();

    const { data: monitorRegionsData } = useFetchMonitorRegionsQuery();

    const { data: locationsAndLanguages } = useFetchLocationsAndLanguagesQuery();

    const handleSubmit = () => {
        const normalizedUrl = normalizeUrl(state.selectedDomain, state.selectedUrlSchema);

        const normalizedCompetitors = state.selectedCompetitors
            .filter((competitor) => competitor.domain && !['https://', 'http://'].includes(competitor.domain))
            .map((competitor) => normalizeUrl(competitor.domain, competitor.urlSchema));

        const uniqueCompetitors = normalizedCompetitors.filter(
            (competitorUrl, index) => normalizedCompetitors.indexOf(competitorUrl) === index && competitorUrl !== normalizedUrl
        );

        const allRegions = state.selectedMainRegion ? [state.selectedMainRegion, ...state.selectedAdditionalRegions] : [];

        const keywords = extractKeywords(state.selectedKeywords);

        setState({ ...state, currentStep: state.currentStep + 1 });

        onAddTrackedWebsite({
            url: normalizedUrl,
            locationName: state.selectedLocationName,
            languageCode: state.selectedLanguageCode,
            competitors: uniqueCompetitors,
            regions: allRegions,
            keywords,
            fromOnboardingWizard: fromOnboarding,
        });
    };

    const redirectToDashboard = async () => {
        try {
            sendOnboardingEvent({ event_type: 'skip_onboarding' }).unwrap();

            navigate('/dashboard');
        } catch (error) {}
    };

    const handleStartOnboarding = () => {
        setState({ ...state, showStartScreen: false });
    };

    const handlePreviousStep = () => {
        setState({ ...state, currentStep: state.currentStep - 1 });
    };

    const handleNextStep = () => {
        setState({ ...state, currentStep: state.currentStep + 1 });
    };

    const handleUrlChange = (newDomain: string, newUrlSchema: string) => {
        setState({ ...state, selectedDomain: newDomain, selectedUrlSchema: newUrlSchema });
    };

    const handleMainRegionChange = (newMainRegion: string) => {
        setState({ ...state, selectedMainRegion: newMainRegion, selectedAdditionalRegions: [] });
    };

    const handleAdditionalRegionsChange = (newAdditionalRegions: string[]) => {
        setState({ ...state, selectedAdditionalRegions: newAdditionalRegions });
    };

    const handleLocationChange = (newLocation: string) => {
        let newLanguageCode = '';

        if (locationsAndLanguages) {
            newLanguageCode = getAvailableLanguagesForLocation(newLocation, locationsAndLanguages)[0].language_code;
        }

        setState({ ...state, selectedLocationName: newLocation, selectedLanguageCode: newLanguageCode });
    };

    const handleLanguageChange = (newLanguageCode: string) => {
        setState({ ...state, selectedLanguageCode: newLanguageCode });
    };

    const handleCompetitorsChange = (newCompetitors: Competitor[]) => {
        setState({ ...state, selectedCompetitors: newCompetitors });
    };

    const handleKeywordsChange = (newKeywords: string) => {
        setState({ ...state, selectedKeywords: newKeywords });
    };

    return (
        <div className="add-tracked-website-wizard">
            <Card
                bordered={false}
                className="wizard-card"
            >
                {state.showStartScreen ? (
                    <OnboardingStartScreen onStartOnboarding={handleStartOnboarding} />
                ) : (
                    <>
                        <Steps current={state.currentStep}>
                            {wizardSteps.map((step) => (
                                <Step
                                    key={step}
                                    title={step}
                                />
                            ))}
                        </Steps>
                        {state.currentStep === 0 && (
                            <SetWebsiteStep
                                domain={state.selectedDomain}
                                urlSchema={state.selectedUrlSchema}
                                fromOnboarding={fromOnboarding}
                                onUrlChange={handleUrlChange}
                                onSkipOnboarding={redirectToDashboard}
                                onFinishThisStep={handleNextStep}
                            />
                        )}

                        {state.currentStep === 1 && (
                            <SetLocationsStep
                                selectedLocationName={state.selectedLocationName}
                                selectedLanguageCode={state.selectedLanguageCode}
                                selectedMainRegion={state.selectedMainRegion}
                                selectedAdditionalRegions={state.selectedAdditionalRegions}
                                selectedUrl={normalizeUrl(state.selectedDomain, state.selectedUrlSchema)}
                                locationsAndLanguages={locationsAndLanguages ? locationsAndLanguages : []}
                                maxRegions={maxRegions}
                                monitorRegions={monitorRegionsData ? monitorRegionsData.regions : {}}
                                monitorRegionsOrder={monitorRegionsData ? monitorRegionsData.order : []}
                                onPreviousStep={handlePreviousStep}
                                onMainRegionChange={handleMainRegionChange}
                                onAdditionalRegionsChange={handleAdditionalRegionsChange}
                                onLocationChange={handleLocationChange}
                                onLanguageChange={handleLanguageChange}
                                onFinishThisStep={handleNextStep}
                            />
                        )}

                        {state.currentStep === 2 && (
                            <SetCompetitorsStep
                                domain={state.selectedDomain}
                                competitors={state.selectedCompetitors}
                                maxCompetitors={maxCompetitors}
                                onPreviousStep={handlePreviousStep}
                                onCompetitorsChange={handleCompetitorsChange}
                                onFinishThisStep={handleNextStep}
                            />
                        )}

                        {state.currentStep === 3 && (
                            <SetKeywordsStep
                                domain={state.selectedDomain}
                                keywords={state.selectedKeywords}
                                maxKeywords={maxKeywords}
                                trackedKeywordsCount={trackedKeywordsData ? trackedKeywordsData.keywords_no : 0}
                                onPreviousStep={handlePreviousStep}
                                onKeywordsChange={handleKeywordsChange}
                                onFinishThisStep={() => {
                                    handleNextStep();
                                    handleSubmit();
                                }}
                            />
                        )}

                        {state.currentStep === 4 && <FinishedScreen />}
                    </>
                )}
            </Card>
        </div>
    );
}
