import { TopKeyword } from './types';

const numberFormatter = new Intl.NumberFormat('en-US');

export function getTableDataFromKeywords(keywords: TopKeyword[]) {
    return keywords.map((keyword, idx) => ({
        key: idx + 1,
        keyword: keyword.phrase,
        position: keyword.pos ? keyword.pos : null,
        previousPosition: keyword.prev_pos ? keyword.prev_pos : 0,
        traffic: keyword.traffic ? numberFormatter.format(keyword.traffic) : '-',
        difficulty: keyword.difficulty ? numberFormatter.format(keyword.difficulty) : '-',
        searchVolume: keyword.vol ? numberFormatter.format(keyword.vol) : '-',
        cpc: keyword.cpc ? numberFormatter.format(keyword.cpc) : '-',
        resultsNo: keyword.r_no ? numberFormatter.format(keyword.r_no) : '-',
        url: keyword.url,
        trends: keyword.trends,
    }));
}

export function getKeywordsToExportAsJson(keywords: TopKeyword[]) {
    const keysMap: {
        [key: string]: keyof TopKeyword;
    } = {
        search_volume: 'vol',
        results_count: 'r_no',
        competition: 'cmp',
        difficulty: 'difficulty',
        position: 'pos',
        previous_position: 'prev_pos',
        keyword: 'phrase',
        cpc: 'cpc',
        traffic: 'traffic',
        trends: 'trends',
        url: 'url',
    };

    let newKeywords = keywords.map((keyword) => {
        let newKeyword: { [key: string]: string | number | number[] } = {};
        Object.keys(keysMap).forEach((key) => {
            newKeyword[key] = keyword[keysMap[key]];
        });
        return newKeyword;
    });

    return JSON.stringify(newKeywords);
}

export function getKeywordsToExportAsCsv(keywords: TopKeyword[]) {
    let csv = '';
    const keysMap = {
        phrase: 'Keyword',
        pos: 'Position',
        prev_pos: 'Previous Position',
        traffic: 'Traffic (%)',
        difficulty: 'Keyword Difficulty',
        vol: 'Search Volume',
        url: 'URL',
        cpc: 'CPC',
        r_no: 'Number of Results',
        cmp: 'Competition',
        trends: 'Trends',
    };
    try {
        let csvColumns = Object.values(keysMap);
        let keywordColumns = Object.keys(keysMap);
        csvColumns.forEach((column) => {
            csv = `${csv}${column};`;
        });
        csv = csv.replace(/.$/, '\n');

        keywords.forEach((keyword) => {
            keywordColumns.forEach((column) => {
                csv = `${csv}${keyword[column as keyof TopKeyword]};`;
            });
            csv = csv.replace(/.$/, '\n');
        });
    } catch (e) {}
    return csv;
}
