import React from "react";
import { Layout } from "antd";
import { AnalysisReport } from "../containers";

const { Content } = Layout;

const AnalysisReportPage = () => {
  return (
    <Content className="general-layout-content">
      <AnalysisReport />
    </Content>
  );
};

export default AnalysisReportPage;
