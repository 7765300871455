import './Dashboard.less';
import React, { useContext } from 'react';
import { Card, Icon } from 'antd';
import { useFetchBacklinksOverviewQuery } from '../backlinks/service';
import { SelectedWebsiteContext } from '../trackedWebsites/ContextWrapper';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from '../router/hooks/useNavigate';
import BacklinksOverview from '../backlinks/overview/BacklinksOverview';
import DashboardCardTitle from '../components/Dashboard/DashboardCardTitle';
import DashboardBox from '../components/Dashboard/DashboardBox';
import { backlinksOverviewDashboardDisplayOrder } from '../backlinks/settings';
import { getBacklinksOverviewForInterval, getVisibleOverviewDataForDashboard } from '../backlinks/utils';
import DashboardBacklinksChart from './DashboardBacklinksChart';

type Props = {
    startDate: string;
    endDate: string;
};

export default function DashboardBacklinksBox({ startDate, endDate }: Props) {
    const { selectedWebsite } = useContext(SelectedWebsiteContext);
    const navigate = useNavigate();

    const { data: backlinksOverview, isLoading: isLoadingOverview } = useFetchBacklinksOverviewQuery(
        selectedWebsite ? { domain: selectedWebsite.domain } : skipToken
    );

    const handleViewAllBacklinksClicked = () => {
        navigate('/backlinks');
    };

    const overviewForDashboard = backlinksOverview ? getBacklinksOverviewForInterval(backlinksOverview, startDate, endDate) : undefined;
    const overviewChartData = overviewForDashboard ? getVisibleOverviewDataForDashboard(overviewForDashboard) : [];

    const isDataForDashboardOverview = !isLoadingOverview && overviewForDashboard;

    return (
        <div className="dashboard-backlinks-box">
            {isLoadingOverview && <DashboardBox />}

            {!isLoadingOverview && (
                <Card
                    data-cy="backlinks-box"
                    title={
                        <DashboardCardTitle
                            title="Backlinks overview"
                            description="Over the last 7 days"
                        />
                    }
                    extra={
                        <div
                            className="card-link flexbox align-center"
                            onClick={handleViewAllBacklinksClicked}
                        >
                            <span>View all backlinks</span>
                            <Icon type="right" />
                        </div>
                    }
                >
                    {isDataForDashboardOverview && (
                        <BacklinksOverview
                            overview={overviewForDashboard}
                            displayOrder={backlinksOverviewDashboardDisplayOrder}
                            showChart={false}
                        />
                    )}

                    <DashboardBacklinksChart chartData={overviewChartData} />
                </Card>
            )}
        </div>
    );
}
