import {
  FETCH_TEMPLATES_IN_PROGRESS,
  FETCH_TEMPLATES_SUCCESSFUL,
  FETCH_TEMPLATES_FAILED,
  ADD_TEMPLATE_IN_PROGRESS,
  ADD_TEMPLATE_SUCCESSFUL,
  ADD_TEMPLATE_FAILED,
  EDIT_TEMPLATE_IN_PROGRESS,
  EDIT_TEMPLATE_SUCCESSFUL,
  EDIT_TEMPLATE_FAILED,
  DELETE_TEMPLATE_IN_PROGRESS,
  DELETE_TEMPLATE_SUCCESSFUL,
  DELETE_TEMPLATE_FAILED,
  CHANGE_SELECTED_REPORT_GUID,
  CHANGE_SELECTED_TEMPLATE,
  CHANGE_SELECTED_REPORT_URL,
  DOWNLOAD_PDF_REPORT_IN_PROGRESS,
  DOWNLOAD_PDF_REPORT_SUCCESSFUL,
  DOWNLOAD_PDF_REPORT_FAILED
} from "./types";

export const fetchTemplatesInProgress = (_) => ({
  type: FETCH_TEMPLATES_IN_PROGRESS
});

export const fetchTemplatesSuccessful = (templates) => ({
  type: FETCH_TEMPLATES_SUCCESSFUL,
  templates
});

export const fetchTemplatesFailed = (_) => ({
  type: FETCH_TEMPLATES_FAILED
});

export const addTemplateInProgress = (_) => ({
  type: ADD_TEMPLATE_IN_PROGRESS
});

export const addTemplateSuccessful = (template) => ({
  type: ADD_TEMPLATE_SUCCESSFUL,
  template
});

export const addTemplateInFailed = (_) => ({
  type: ADD_TEMPLATE_FAILED
});

export const editTemplateInProgress = (guid) => ({
  type: EDIT_TEMPLATE_IN_PROGRESS,
  guid
});

export const editTemplateSuccessful = (guid, template) => ({
  type: EDIT_TEMPLATE_SUCCESSFUL,
  guid,
  template
});

export const editTemplateFailed = (guid) => ({
  type: EDIT_TEMPLATE_FAILED,
  guid
});

export const deleteTemplateInProgress = (guid) => ({
  type: DELETE_TEMPLATE_IN_PROGRESS,
  guid
});

export const deleteTemplateSuccessful = (guid) => ({
  type: DELETE_TEMPLATE_SUCCESSFUL,
  guid
});

export const deleteTemplateFailed = (guid) => ({
  type: DELETE_TEMPLATE_FAILED,
  guid
});

export const changeSelectedReportGuid = (guid) => ({
  type: CHANGE_SELECTED_REPORT_GUID,
  guid
});

export const changeSelectedTemplate = (guid) => ({
  type: CHANGE_SELECTED_TEMPLATE,
  guid
});

export const changeSelectedReportUrl = (url) => ({
  type: CHANGE_SELECTED_REPORT_URL,
  url
});

export const downloadPdfReportInProgress = (report_url, report_guid, template_guid) => ({
  type: DOWNLOAD_PDF_REPORT_IN_PROGRESS,
  report_url,
  report_guid,
  template_guid
});

export const downloadPdfReportSuccessful = (report_url, report_guid, template_guid, pdf_url) => ({
  type: DOWNLOAD_PDF_REPORT_SUCCESSFUL,
  report_url,
  report_guid,
  template_guid,
  pdf_url
});

export const downloadPdfReportFailed = (report_url, report_guid, template_guid) => ({
  type: DOWNLOAD_PDF_REPORT_FAILED,
  report_url,
  report_guid,
  template_guid
});
