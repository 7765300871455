import React, { Component } from "react";
import { saveAs } from "file-saver";
import "./ExportAsFile.less";

class ExportAsFile extends Component {
  handleExport = (event, fileContent, fileName, fileMimeType) => {
    event.preventDefault();
    event.currentTarget.getElementsByTagName("button")[0].blur();

    let blob = new Blob([fileContent], {
      type: fileMimeType
    });

    saveAs(blob, fileName);
  };

  render() {
    const { fileContent, fileName, fileMimeType } = this.props;

    return (
      <div className="export-as" onClick={event => this.handleExport(event, fileContent, fileName, fileMimeType)}>
        {this.props.children}
      </div>
    );
  }
}
export default ExportAsFile;
