import React, { useState } from "react";
import { List, Button, Tag } from "antd";
import moment from "moment";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";
import "./InvoiceHistoryLayout.less";

function InvoiceHistoryLayout({ isLoading, invoices, onDownloadInvoice, isDownloadingInvoice }) {
  const [downloadingInvoiceNumber, setDownloadingInvoiceNumber] = useState(null);

  const downloadInvoice = (event, invoiceNumber) => {
    event.currentTarget.blur();
    setDownloadingInvoiceNumber(invoiceNumber);
    onDownloadInvoice(invoiceNumber);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator />
      ) : invoices ? (
        <List
          className="invoice-list"
          size="large"
          header={<div>Invoice history</div>}
          bordered
          dataSource={invoices}
          locale={{ emptyText: "No invoices for your account" }}
          renderItem={item => (
            <List.Item className="invoice-list-item">
              <div>
                <div className="date">
                  <span>{moment(item["created_at"], "YYYY-MM-DDTHH:mm:ss").format("MMM DD, YYYY")}</span>
                  {item["status"] === "paid" ? <Tag color="green">paid</Tag> : <Tag color={"red"}>unpaid</Tag>}
                </div>
                <div className="price">
                  {item["total_in_cents"] > 0
                    ? `$${item["total_in_cents"] / 100}`
                    : `-$${(item["total_in_cents"] / 100) * -1}`}
                </div>
              </div>
              <Button
                icon="download"
                loading={isDownloadingInvoice && downloadingInvoiceNumber === item["invoice_number"]}
                onClick={e => downloadInvoice(e, item["invoice_number"])}
              />
            </List.Item>
          )}
        />
      ) : null}
    </div>
  );
}

export default InvoiceHistoryLayout;
