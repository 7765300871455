import React from 'react'
import { Layout } from "antd";

const { Content } = Layout;

const KnowledgebasePage = () => {
    return (
        <Content className="general-layout-content">
            <h1>Knowledgebase page</h1>
        </Content>
    )
}

export default KnowledgebasePage
