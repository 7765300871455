import { Component } from "react";
import { connect } from "react-redux";
import { ENVIRONMENT } from "../../settings/config";
import {isProfitWellStarted} from "../../store/trackers/selectors"
import {startProfitWell} from "../../store/trackers/creators"

class ProfitWell extends Component {
    componentDidMount() {             
        if(ENVIRONMENT === 'prod'){
            if (this.props.userEmail && !this.props.isProfitWellStarted && window.profitwell) {                            
                window.profitwell('user_email', this.props.userEmail);
                this.props.startProfitWell();                  
            }        
        }        
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    isProfitWellStarted: isProfitWellStarted(state),
});

export default connect(mapStateToProps, {
  startProfitWell,
})(ProfitWell);
