import {START_PROFIT_WELL, LOGOUT, SEND_GTAG_PAYMENT_METHOD, SEND_GTAG_REGISTRATION} from "./types"

const initialState = {    
    'profitWell': false,
    'gtag': {
        'registration': false,
        'payment_method': false
    }        
};
  
const trackersReducer = (state = initialState, action) => {    
    switch (action.type) {
        case START_PROFIT_WELL:
            return {
                ...state,
                'profitWell': true                
            }
        case SEND_GTAG_REGISTRATION:
            return {
                ...state,
                'gtag': {
                    ...state.gtag,
                    'registration': true
                }                        
            }
        case SEND_GTAG_PAYMENT_METHOD:
            return {
                ...state,
                'gtag': {
                    ...state.gtag,
                    'payment_method': true
                }                        
            }    
        case LOGOUT:            
            return {
                ...state,
                'profitWell': false,
                'gtag': {
                    'registration': false,
                    'payment_method': false
                }
            };
        default:
            return state;
    }
}

export default trackersReducer;
