import React from "react";
import { IS_MENU_COLLAPSED } from "../../../settings/config";

export const getMenuItems = (selectedMenuItem = "") => [
  {
    type: "menu-item",
    key: "dashboard",
    icon: "dashboard",
    path: "/dashboard",
    label: "Dashboard"
    //isSelected: ["/", "/dashboard"].includes(selectedMenuItem)
  },
  {
    type: "submenu-item",
    key: "seo-analysis",
    icon: "clipboard",
    title: "Analysis",
    isSelected:
      [
        "/analysis",
        "/analysis/report",
        "/backlinks",
        "/top-keywords",
        "/keyword-position",
        "/keyword-research",
        "/broken-links"
      ].includes(selectedMenuItem) || !IS_MENU_COLLAPSED,
    sub_items: [
      {
        type: "menu-item",
        key: "seo-analysis",
        icon: "seo-check",
        path: "/analysis",
        label: "SEO Checkup"
      },
      {
        type: "menu-item",
        key: "backlinks",
        icon: "backlinks",
        path: "/backlinks",
        label: "Backlinks"
      },
      {
        type: "menu-item",
        key: "top-keywords",
        icon: "top-keywords",
        path: "/top-keywords",
        label: "Top Keywords"
      },
      {
        type: "menu-item",
        key: "keyword-position",
        icon: "position-tracker",
        path: "/keyword-position",
        label: "Keyword Position Tracker"
      },
      {
        type: "menu-item",
        key: "keyword-research",
        icon: "research",
        path: "/keyword-research",
        label: "Keyword Research"
      },
      {
        type: "menu-item",
        key: "broken-links",
        icon: "broken-link",
        path: "/broken-links",
        label: "Broken Links"
      }
    ]
  },
  {
    type: "submenu-item",
    key: "monitoring",
    icon: "monitor",
    title: "Monitoring",
    isSelected: ["/site-speed", "/site-outage"].includes(selectedMenuItem) || !IS_MENU_COLLAPSED,
    sub_items: [
      {
        type: "menu-item",
        key: "site-speed",
        icon: "speed",
        path: "/site-speed",
        label: "Site Speed"
      },
      {
        type: "menu-item",
        key: "site-outage",
        icon: "uptime",
        path: "/site-outage",
        label: "Site Outage"
      }
    ]
  },
  {
    type: "submenu-item",
    key: "agency-tools",
    icon: "webmaster",
    title: "Webmaster Tools",
    isSelected:
      ["/white-label/reports", "/white-label/templates", "/sitemap-generator"].includes(selectedMenuItem) ||
      selectedMenuItem.includes("white-label/templates") ||
      !IS_MENU_COLLAPSED,
    sub_items: [
      {
        type: "menu-item",
        key: "white-label",
        icon: "brand",
        path: "/white-label/reports",
        label: "White Label Reports"
      },
      {
        type: "menu-item",
        key: "sitemap-generator",
        icon: "sitemap",
        path: "/sitemap-generator",
        label: "Sitemap Generator"
      }
    ]
  },
  {
    type: "menu-item",
    key: "contact",
    icon: "contact",
    label: (
      <a className="contact-anchor" href="mailto:information@seositecheckup.com">
        Contact
      </a>
    )
  }
  // {
  //   type: "submenu-item",
  //   key: "support",
  //   icon: "support",
  //   title: "Support",
  //   isSelected: ["/knowledgebase", "/contact"].includes(selectedMenuItem),
  //   sub_items: [
  //     {
  //       type: "menu-item",
  //       key: "knowledgebase",
  //       icon: "knowledgebase",
  //       path: "/knowledgebase",
  //       label: "Knowledgebase"
  //     },
  //     {
  //       type: "menu-item",
  //       key: "contact",
  //       icon: "contact",
  //       path: "/contact",
  //       label: "Contact"
  //     }
  //   ]
  // }
];

export const getSelectedSubmenuKey = (items) => {
  return items
    .filter((item) => item.type === "submenu-item" && item.isSelected)
    .map((selectedSubmenu) => selectedSubmenu.key);
};

export const getAllMenuItems = (items) => {
  //returns a flattened array of items with "menu-item" type
  return items
    .map((item) => {
      if (item.type === "menu-item") {
        return item;
      } else {
        return getAllMenuItems(item.sub_items);
      }
    })
    .reduce((acc, curVal) => acc.concat(curVal), []);
};

export const rootSubmenuKeys = (_) => {
  const menuItems = getMenuItems();
  return menuItems.filter((item) => item.type === "submenu-item").map((submenuItem) => submenuItem.key);
  //return items.filter(item => item.type === "submenu-item").map(selectedSubmenu => selectedSubmenu.key);
};

export const getSelectedMenuItemKey = (items, path) => {
  let selectedKeyList = getAllMenuItems(items)
    .filter((item) => item.path === path)
    .map((selectedMenuItem) => selectedMenuItem.key);
  if (path && path.startsWith("/analysis/")) {
    selectedKeyList.push("seo-analysis");
  }
  if (path === "/white-label" || (path && path.startsWith("/white-label/templates"))) {
    selectedKeyList.push("white-label");
  }
  if (path === "/") {
    return ["dashboard"];
  }
  return selectedKeyList;
};

export const getPageTitle = (path) => {
  if (!path) {
    return "Page not found";
  }
  let items = getAllMenuItems(getMenuItems("/")).filter((item) => item.path === path);
  if (items.length && "label" in items[0]) {
    return items[0]["label"];
  }
  if (path === "/") {
    return "Dashboard";
  }
  if (path.startsWith("/analysis/")) {
    return "SEO Checkup";
  }
  if (path === "/white-label" || path.startsWith("/white-label/templates")) {
    return "White Label Reports";
  }
  if (path === "/user/account/profile") {
    return "My account";
  }
  if (path.startsWith("/dashboard/settings")) {
    return "Dashboard Settings";
  }
  if (path === "/subscription/plans") {
    return "Subscription plans";
  }
  if (path.startsWith("/subscription/checkout")) {
    return "Checkout";
  }
  if (path === "/user/account/billing") {
    return "Billing";
  }
  if (path === "/user/account/setup") {
    return "Account setup";
  }
  if (path === "/user/team-members") {
    return "Team members";
  }
  return "Page not found";
};
