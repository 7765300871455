import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  getSelectedTemplateGuid,
  getSelectedTemplate,
  getSortedTemplates,
  getTemplatesGuid,
  getDefaultSeoScoreSection,
  getSelectedReportGuid,
  getSelectedReportUrl
} from "../../store/white_label/selectors";
import { downloadPdfReport } from "../../store/white_label/operations";
import { isDownloadingPdfReport } from "../../store/white_label/selectors";
import {
  changeSelectedReportGuid,
  changeSelectedReportUrl,
  changeSelectedTemplate
} from "../../store/white_label/creators";
import { getLoggedInUserGuid } from "../../store/iam/selectors";
import CustomReportLayout from "../../components/WhiteLabel/report/CustomReportLayout";

class CustomReport extends Component {
  constructor(props) {
    super(props);

    this.isValidUrl = this.isValidUrl.bind(this);
  }

  componentDidMount() {
    const encodedReportUrl = this.props.queryParams["report_url"];
    const decodedReportUrl = decodeURIComponent(encodedReportUrl);

    if (this.props.queryParams["report_guid"]) {
      this.props.changeSelectedReportGuid(this.props.queryParams["report_guid"]);
    }
    if (encodedReportUrl && this.isValidUrl(decodedReportUrl)) {
      this.props.changeSelectedReportUrl(decodedReportUrl);
    }
    if (
      this.props.queryParams["template_guid"] &&
      this.props.templatesGuid.includes(this.props.queryParams["template_guid"])
    ) {
      this.props.changeSelectedTemplate(this.props.queryParams["template_guid"]);
    }
  }

  isValidUrl(string) {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <CustomReportLayout
        queryParams={this.props.queryParams}
        selectedReportGuid={this.props.selectedReportGuid}
        templates={this.props.templates}
        selectedTemplate={this.props.template}
        selectedTemplateGuid={this.props.selectedTemplateGuid}
        logo={this.props.template["logo"]}
        header={this.props.template["content_blocks"]["header"]}
        footer={this.props.template["content_blocks"]["footer"]}
        url={this.props.seoScoreSection["url"]}
        selectedReportUrl={this.props.selectedReportUrl}
        seoScore={this.props.seoScoreSection["seo_score"]}
        sectionsStatusCounter={this.props.seoScoreSection["sections_status_counter"]}
        sectionsStatusProgress={this.props.seoScoreSection["sections_status_progress"]}
        changeSelectedTemplate={this.props.changeSelectedTemplate}
        changeSelectedReportGuid={this.props.changeSelectedReportGuid}
        changeSelectedReportUrl={this.props.changeSelectedReportUrl}
        isDownloadingPdfReport={this.props.isDownloadingPdfReport}
        downloadPdfReport={this.props.downloadPdfReport}
        userGuid={this.props.userGuid}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTemplateGuid: getSelectedTemplateGuid(state),
  selectedReportGuid: getSelectedReportGuid(state),
  selectedReportUrl: getSelectedReportUrl(state),
  templatesGuid: getTemplatesGuid(state),
  template: getSelectedTemplate(state),
  templates: getSortedTemplates(state),
  seoScoreSection: getDefaultSeoScoreSection(state),
  queryParams: state.router.location.query, // queryParams is an object,
  isDownloadingPdfReport: isDownloadingPdfReport(
    state,
    getSelectedReportUrl(state),
    "latest",
    getSelectedTemplateGuid(state)
  ),
  userGuid: getLoggedInUserGuid(state)
});

export default connect(mapStateToProps, {
  changeSelectedReportGuid,
  changeSelectedTemplate,
  changeSelectedReportUrl,
  downloadPdfReport,
  push
})(CustomReport);
