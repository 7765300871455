const registerValidationRules = {
  email: {
    email: {
      message: "^Please enter a valid email"
    }
  },
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      message: "^This field is required"
    }
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      message: "^This field is required"
    }
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      maximum: 128,
      message: "^Between 6 and 128 characters"
    }
  },
  repeatPassword: {
    presence: true,
    equality: {
      attribute: "password",
      message: "^Passwords need to match"
    }
  },
  agreementChecked: {
    inclusion: {
      within: [true],
      message: "^You must agree with our terms and conditions!"
    }
  }
};

export default registerValidationRules;
