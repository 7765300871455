import React from 'react';
// @ts-ignore
import FlagIconFactory from 'react-flag-icon-css';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

type Props = {
    code: string | null;
    name: string;
};

export default function CountryTableCell({ code, name }: Props) {
    return (
        <>
            {code && code !== 'ww' && <FlagIcon code={code} />}
            <span className="country-name">{name}</span>
        </>
    );
}
