import React from 'react';
import { Icon } from "antd";

function TrendIcon({resourceType, first, last}) {
   
    let iconTypes = {
        "SEO score": {
            "decreased": <Icon type="caret-down" style={{ color: "#f63842" }} />,
            "increased": <Icon type="caret-up" style={{ color: "#34bc6e" }} />,
            "equal": <Icon type="swap" />
        },
        "backlinks": {
            "decreased": <Icon type="caret-down" style={{ color: "#f63842" }} />,
            "increased": <Icon type="caret-up" style={{ color: "#34bc6e" }} />,
            "equal": <Icon type="swap" />
        },
        "loadingTime" : {
            "decreased": <Icon type="caret-up" style={{ color: "#34bc6e" }} />,
            "increased": <Icon type="caret-down" style={{ color: "#f63842" }} />,
            "equal": <Icon type="swap" />
        }
    };

    let condition = (first, last) => {
        if (last > first) {
            return "increased";
        };
        if (last < first) {
            return "decreased";
        };
        if (last === first) {
            return "equal";
        };
    };

    return iconTypes[resourceType][condition(first, last)]
}

export default TrendIcon
