import './SiteOutage.less';
import React, { useState } from 'react';
import { Card } from 'antd';
import { TrackedWebsite } from '../trackedWebsites/types';
import moment from 'moment';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import OutageTimelines from './timeline/OutageTimelines';
import { useFetchMonitorRegionsQuery } from '../trackedWebsites/service';
import OutageIssues from './OutageIssues';
import useOutageIntervalState from './hooks/useOutageIntervalState';
import OutageWorldMap from './OutageWorldMap';
import { getIntervalsForDay, getLastIntervalIndex } from './utils';

const outagesStartDate = moment.utc().subtract(30, 'd').format('YYYY-MM-DDTHH:mm:ss');
const outagesEndDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function SiteOutage({ selectedWebsite }: Props) {
    const { data: monitoringRegionsData } = useFetchMonitorRegionsQuery();

    const [selectedDay, setSelectedDay] = useState<string>(moment(outagesEndDate).format('YYYY-MM-DD'));

    const {
        outagesGroupedByDay,
        selectedOutageContext,
        selectedOutageInterval,
        outageIntervals,
        isLoadingOutageContext,
        isLoadingOutageList,
        handleOutageIntervalChange,
    } = useOutageIntervalState(selectedDay, selectedWebsite, outagesStartDate, outagesEndDate);

    const handleSelectedDayChange = (newSelectedDay: string) => {
        setSelectedDay(newSelectedDay);

        const newOutageIntervals = getIntervalsForDay(newSelectedDay, outagesGroupedByDay[newSelectedDay]);
        handleOutageIntervalChange(newOutageIntervals[getLastIntervalIndex(newOutageIntervals)]);
    };

    return (
        <Card className="outage-container">
            {isLoadingOutageList ? (
                <LoadingIndicator />
            ) : (
                <>
                    <OutageTimelines
                        outagesGroupedByDays={outagesGroupedByDay}
                        selectedDay={selectedDay}
                        selectedInterval={selectedOutageInterval}
                        outageIntervals={outageIntervals}
                        startDate={outagesStartDate}
                        endDate={outagesEndDate}
                        onOutageIntervalChange={handleOutageIntervalChange}
                        onSelectedDayChange={handleSelectedDayChange}
                    />

                    <div className="outage-map flexbox flex-wrap">
                        <div className="outage-map-container custom-scrollbar">
                            <OutageWorldMap
                                isLoading={isLoadingOutageContext}
                                width={800}
                                height={420}
                                scale={100}
                                outageContext={selectedOutageContext}
                                monitoringRegions={monitoringRegionsData ? monitoringRegionsData.regions : {}}
                                url={selectedWebsite.url}
                            />
                        </div>

                        <div className="outage-issues-container custom-scrollbar">
                            <div className="outage-issues">
                                <OutageIssues
                                    key={selectedOutageContext ? selectedOutageContext.guid : ''}
                                    monitoringRegions={monitoringRegionsData ? monitoringRegionsData.regions : {}}
                                    outageContext={selectedOutageContext}
                                    outageStart={moment(selectedOutageInterval.start_at).format('HH:mm A')}
                                    outageEnd={moment(selectedOutageInterval.end_at).format('HH:mm A')}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Card>
    );
}
