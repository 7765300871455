import React from 'react';
import { Tabs, Card } from 'antd';
import { TrackedWebsite } from '../../../trackedWebsites/types';
import { useNavigate } from '../../../router/hooks/useNavigate';
import CompetitorsTab from './CompetitorsTab';
import KeywordsTab from './KeywordsTab';
import LocationTab from './LocationTab';
import RemoveUrlTab from './RemoveUrlTab';
import NotificationsTab from './NotificationsTab';

const { TabPane } = Tabs;

type Props = {
    selectedWebsite: TrackedWebsite | null;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void;
    selectedOption: string;
};

export default function DashboardSettingsTabs({ selectedWebsite, setSelectedWebsite, selectedOption }: Props) {
    const navigate = useNavigate();

    const handleTabChange = (newSelectedOption: string) => {
        navigate(`/dashboard/settings/${newSelectedOption}`);
    };

    return (
        <Card className="dashboard-settings-tabs">
            {selectedWebsite && (
                <Tabs
                    destroyInactiveTabPane={true}
                    activeKey={selectedOption}
                    onChange={handleTabChange}
                >
                    <TabPane
                        tab="Competitors"
                        key="competitors"
                    >
                        <CompetitorsTab
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    </TabPane>
                    <TabPane
                        tab="Keywords"
                        key="keywords"
                    >
                        <KeywordsTab
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    </TabPane>
                    <TabPane
                        tab="Monitor Locations"
                        key="monitor-locations"
                    >
                        <LocationTab
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    </TabPane>
                    <TabPane
                        tab="Notifications"
                        key="notifications"
                    >
                        <NotificationsTab
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    </TabPane>
                    <TabPane
                        tab="Remove URL"
                        key="remove-url"
                    >
                        <RemoveUrlTab
                            selectedWebsite={selectedWebsite}
                            setSelectedWebsite={setSelectedWebsite}
                        />
                    </TabPane>
                </Tabs>
            )}
        </Card>
    );
}
