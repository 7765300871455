import React, { Component } from "react";
import { Menu, Icon } from "antd";
import "./ReportSidebar.less";

const SubMenu = Menu.SubMenu;

class SeoReportSidebar extends Component {
  state = {
    openKeys: ["general"]
  };

  onOpenChange = openKeys => {
    const rootSubmenuKeys = ["general", ...Object.keys(this.props.categorizedSelectedSections)];
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };

  render() {
    return (
      <Menu
        mode="inline"
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        className="report-sidebar custom-scrollbar">
        <Menu.Item key="1" data-cy={"general"} className="single-item">
          <a href="#header-seo-summary" className="submenu-container">
            <span className="submenu-title">
              <Icon type="check-circle" />
              <span>General</span>
            </span>
            <div className="submenu-result-data">
              <div className="submenu-result failed">
                {this.props.sectionsStatusCounter.failed} <span className="result-data-indicator" />
              </div>

              <div className="submenu-result warning">
                {this.props.sectionsStatusCounter.warning} <span className="result-data-indicator" />
              </div>

              <div className="submenu-result passed">
                {this.props.sectionsStatusCounter.passed} <span className="result-data-indicator" />
              </div>
            </div>
          </a>
        </Menu.Item>

        {Object.keys(this.props.categorizedSelectedSections).map(category => (
          <Menu.ItemGroup key={category}>
            <Menu.Divider />
            <SubMenu
              data-cy={category}
              key={category}
              className="submenu-item"
              title={
                <div className="submenu-container">
                  <span className="submenu-title">
                    <Icon type="check-circle" />
                    <span>{category}</span>
                  </span>
                  <div className="submenu-result-data">
                    <div className="submenu-result failed">
                      {this.props.categoriesSectionsStatusCounter[category].failed}{" "}
                      <span className="result-data-indicator" />
                    </div>

                    <div className="submenu-result warning">
                      {this.props.categoriesSectionsStatusCounter[category].warning}{" "}
                      <span className="result-data-indicator" />
                    </div>

                    <div className="submenu-result passed">
                      {this.props.categoriesSectionsStatusCounter[category].passed}{" "}
                      <span className="result-data-indicator" />
                    </div>
                  </div>
                </div>
              }>
              {this.props.categorizedSelectedSections[category].map(section_key => {
                let section_result = this.props.sectionsResults[section_key];

                return section_result && section_result.title ? (
                  <Menu.Item key={section_key} data-cy={category} className={section_result.result.status}>
                    <a href={`#${section_key}`}>
                      <span className="result-data-indicator" />
                      {section_result.title}
                    </a>
                  </Menu.Item>
                ) : null;
              })}
            </SubMenu>
          </Menu.ItemGroup>
        ))}
      </Menu>
    );
  }
}

export default SeoReportSidebar;
