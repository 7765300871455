import { push } from 'connected-react-router';
import { useAppDispatch } from '../../useAppDispatch';
import { useCallback } from 'react';

export function useNavigate() {
    const dispatch = useAppDispatch();

    const navigate = useCallback((url: string) => dispatch(push(url)), [dispatch]);

    return navigate;
}
