import React from "react";

const formatSize = sizeInBytes => {
  if (sizeInBytes < 0) {
    return "";
  }

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`;
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} Kb`;
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / 1024 / 1024).toFixed(2)} Mb`;
  }

  return "> 1 Gb";
};

const formatPercent = (size, total) => {
  return `${((size * 100) / total).toFixed(1)} %`;
};

const PageObjectsStatsTable = ({ title, columns, result, maxRows }) => {
  const rows = [...result.data];
  if (maxRows && result.data.length > maxRows) {
    let sum = 0;

    for (let idx = maxRows; idx < result.data.length; idx++) {
      rows.splice(maxRows, 1);

      if ("size" in result.data[idx]) {
        sum += result.data[idx]["size"];
      }

      if ("requests_count" in result.data[idx]) {
        sum += result.data[idx]["requests_count"];
      }
    }

    if ("resource_type" in rows[0]) {
      rows[maxRows] = { resource_type: "Other" };
    }

    if ("domain" in rows[0]) {
      rows[maxRows] = { domain: "Other" };
    }

    if ("size" in rows[0]) {
      rows[maxRows]["size"] = sum;
    }

    if ("requests_count" in rows[0]) {
      rows[maxRows]["requests_count"] = sum;
    }
  }

  return (
    <div className="stats-table-col">
      <div className="stats-title">{title}</div>
      <div className="stats-table-container">
        <header>
          {columns.map((col, idx) => {
            return (
              <div key={idx} className={`col ${idx > 0 ? "right" : ""}`}>
                {col}
              </div>
            );
          })}
        </header>
        {rows.map((item, idx) => {
          return (
            <div className="row" key={idx}>
              <div className={`col ${item["resource_type"] ? "capitalize" : ""} ${item["domain"] ? "domain" : ""}`}>
                {"resource_type" in item && item["resource_type"]}
                {"domain" in item && item["domain"]}
              </div>
              <div className="col right">
                {"size" in item && `${formatPercent(item["size"], result.total)}`}
                {"requests_count" in item && `${formatPercent(item["requests_count"], result.total)}`}
              </div>
              <div className="col right">
                {"size" in item && `${formatSize(item["size"])}`}
                {"requests_count" && item["requests_count"]}
              </div>
            </div>
          );
        })}
        <footer>
          <div className="col">TOTAL</div>
          <div className="col right">100%</div>
          <div className="col right">
            {"size" in rows[0] && `${formatSize(rows.reduce((sum, item) => (sum += item["size"]), 0))}`}
            {"requests_count" in rows[0] && rows.reduce((sum, item) => (sum += item["requests_count"]), 0)}
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PageObjectsStatsTable;
