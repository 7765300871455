import React, { Component } from "react";
import { Tabs, Card, Row, Col } from "antd";
import { BrokenLinks } from "../../containers/BrokenLinks";
import { BROKEN_LINKS_VIDEO_URL } from "../../settings/config";
import "./BrokenLinks.less";

const { TabPane } = Tabs;

const linksTypes = [
  { title: "Internal", key: "internal" },
  { title: "External", key: "external" }
];

class BrokenLinksTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: "internal"
    };

    this.onTabChange = this.onTabChange.bind(this);
  }

  onTabChange(activeKey) {
    this.setState({ selectedTab: activeKey });
  }

  render() {
    return (
      <div className="broken-links">
        <Row gutter={20}>
          <Col span={16}>
            <Card>
              <Tabs activeKey={this.state.selectedTab} onChange={this.onTabChange} type="card" tabBarGutter={10}>
                {linksTypes.map(type => (
                  <TabPane
                    tab={type["title"]}
                    key={type["key"]}
                    data-cy={type["key"]}
                    className="broken-links-container">
                    <BrokenLinks type={this.state.selectedTab} />
                  </TabPane>
                ))}
              </Tabs>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="broken-links-how-to-card" title="How to find and fix broken links">
              <iframe
                title="video"
                width="100%"
                height="320"
                src={BROKEN_LINKS_VIDEO_URL}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BrokenLinksTabs;
