import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import AnchorLabeledList from "../../../shared/List/AnchorLabeledList";
import HowToFix from "../../shared/HowToFix/HowToFix";
import { anchorAttributesForUrls, anchorAttributesAndLabelForUrls } from "../../utils";

const resourceModal = (label, resourceObject, modalTitle, resourceNotFromCdnListName, resourceFromCdnListName) => (
  <InlineModal openLabel={label} modalTitle={modalTitle} closeLabel="Close">
    {resourceObject.not_from_cdn.length > 0 && <div className="list-title">{resourceNotFromCdnListName}</div>}
    <AnchorLabeledList list={anchorAttributesForUrls(resourceObject.not_from_cdn)} />

    {resourceObject.from_cdn.length > 0 && <div className="list-title">{resourceFromCdnListName}</div>}
    <AnchorLabeledList list={anchorAttributesAndLabelForUrls(resourceObject.from_cdn)} className="labeled" />
  </InlineModal>
);

const displayResult = (title, result) => (
  <>
    <div className="description-meta">
      {result.cdn_usage &&
        result.cdn_usage.image &&
        resourceModal(
          "Images",
          result.cdn_usage.image,
          "CDN usage for image resources",
          "Images not served from CDNs:",
          "Images served from CDNs:"
        )}
    </div>

    <div className="description-meta">
      {result.cdn_usage &&
        result.cdn_usage.javascript &&
        resourceModal(
          "JavaScript",
          result.cdn_usage.javascript,
          "CDN usage for javascript resources",
          "JavaScript resources not served from CDNs:",
          "JavaScript resources served from CDNs:"
        )}
    </div>

    <div className="description-meta">
      {result.cdn_usage &&
        result.cdn_usage.css &&
        resourceModal(
          "CSS",
          result.cdn_usage.css,
          "CDN usage for css resources",
          "CSS resources not served from CDNs:",
          "CSS resources served from CDNs:"
        )}
    </div>

    <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
  </>
);

const CdnUsageSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default CdnUsageSection;
