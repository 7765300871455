import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import SiteOutageBox from "../../components/Dashboard/SiteOutage/SiteOutageBox";
import { fetchOutageList } from "../../store/outage/operations";
import { getSelectedWebsiteUrl } from "../../store/tracked_websites/selectors";
import { isLoadingList, getContext, hasOutageData } from "../../store/outage/selectors";
import { DASHBOARD_LONG_POLLING_INTERVAL_IN_MS } from "../../settings/config";

class SiteOutage extends Component {
  constructor(props) {
    super(props);

    this.refreshInterval = null;
  }

  componentDidMount() {
    this.props.fetchOutageList(this.props.url, this.props.startDate, this.props.endDate);
    if (this.props.shouldLongPolling) {
      this.refreshInterval = setInterval(_ => {
        if (this.props.hasData || !this.props.shouldLongPolling) {
          clearInterval(this.refreshInterval);
        } else {
          this.props.fetchOutageList(this.props.url, this.props.startDate, this.props.endDate);
        }
      }, DASHBOARD_LONG_POLLING_INTERVAL_IN_MS);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  render() {
    return (
      <SiteOutageBox
        url={this.props.url}
        isFetching={this.props.isFetching}
        currentContext={this.props.currentContext}
        hasData={this.props.hasData}
        push={this.props.push}
      />
    );
  }
}

const mapStateToProps = state => {
  let url = getSelectedWebsiteUrl(state);

  return {
    isFetching: isLoadingList(state),
    url: getSelectedWebsiteUrl(state),
    currentContext: getContext(state, url),
    hasData: hasOutageData(state, url)
  };
};

export default connect(mapStateToProps, { fetchOutageList, push })(SiteOutage);
