import React from 'react';
import { Divider, Icon } from "antd"; 

function Header(props) {

    function isDataURI (imgSrc) {
        return imgSrc.split(":")[0] === "data"
    };
    
    return (
        <div className="header-container flexbox align-center">
            
            <div className="template-logo">
                <div className="step-counter flexbox align-center justify-center">1</div>
                {
                props.logo ?
                    <img className="logo-img"
                        src={isDataURI(props.logo) ? props.logo : `${props.logo}?${new Date().getTime()}`} 
                        alt="logo"
                    /> : <Icon type="picture" />
                }
            </div>            
            <Divider type="vertical" style={{ width: "2px"}}/>            
            <div className="template-header">
                <div className="step-counter flexbox align-center justify-center">2</div>
                <span>{props.header ? props.header : 'Sample text in the header'}</span>
            </div>            
        </div>
    )
}

export default Header
