import React from "react";
import HowToFix from "../HowToFix/HowToFix";
import SectionMessage from "../SectionMessage/SectionMessage";
import SectionCompetitorResults from "../SectionCompetitorResults/SectionCompetitorResults";

const BasicSectionResult = ({ url, title, relevance, result, competitorResults}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0; 

  return (
    <div className="report-element-description">      
      <SectionMessage url={url} status={result.status} relevance={relevance} message={result.message} hasCompetitors={hasCompetitors} />   
      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      <SectionCompetitorResults results={competitorResults} relevance={relevance} />       
    </div>
  );
};

export default BasicSectionResult;
