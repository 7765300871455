import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import CustomTemplateLayout from "../../components/WhiteLabel/templates/CustomTemplateLayout";
import { changeSelectedTemplate, deleteTemplateSuccessful } from "../../store/white_label/creators";
import {
  isAddingTemplate,
  isEditingTemplate,
  getSelectedTemplateGuid,
  getSelectedTemplate,
  getDefaultSeoScoreSection,
  getTemplatesGuid,
} from "../../store/white_label/selectors";
import { addTemplate, editTemplate } from "../../store/white_label/operations";

class CustomTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: this.props.template["logo"],
      header: this.props.template["content_blocks"]["header"],
      footer: this.props.template["content_blocks"]["footer"],
    };

    this.getTemplateData = this.getTemplateData.bind(this);
  }

  componentDidMount() {
    if (this.props.idUrlParam === "new") {
      this.setState({
        logo: "",
        header: "",
        footer: "",
      });
    }
    if (this.props.idUrlParam === "default") {
      this.props.push("/white-label/templates");
    }
    if (
      this.props.idUrlParam &&
      this.props.idUrlParam !== "new" &&
      !this.props.templatesGuids.includes(this.props.idUrlParam)
    ) {
      this.props.push("/white-label/templates");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idUrlParam !== this.props.idUrlParam && this.props.idUrlParam === "new") {
      this.setState({
        logo: "",
        header: "",
        footer: "",
      });
    }
    if (prevProps.template !== this.props.template) {
      this.setState({
        logo: this.props.template["logo"],
        header: this.props.template["content_blocks"]["header"],
        footer: this.props.template["content_blocks"]["footer"],
      });
    }
  }

  getTemplateData(logo, header, footer) {
    this.setState({ logo, header, footer });
  }

  render() {
    return (
      <CustomTemplateLayout
        isLoading={this.props.isAddingTemplate || this.props.isEditingTemplate}
        sendTemplateData={this.getTemplateData}
        selectedTemplateGuid={this.props.templateGuid}
        selectedTemplate={this.props.template}
        logo={this.state.logo}
        header={this.state.header}
        footer={this.state.footer}
        url={this.props.seoScoreSection["url"]}
        seoScore={this.props.seoScoreSection["seo_score"]}
        sectionsStatusCounter={this.props.seoScoreSection["sections_status_counter"]}
        sectionsStatusProgress={this.props.seoScoreSection["sections_status_progress"]}
        addTemplate={this.props.addTemplate}
        editTemplate={this.props.editTemplate}
        changeSelectedTemplate={this.props.changeSelectedTemplate}
        push={this.props.push}
        idUrlParam={this.props.idUrlParam}
        deleteTemplate={this.props.deleteTemplateSuccessful}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isAddingTemplate: isAddingTemplate(state),
  isEditingTemplate: isEditingTemplate(state),
  templateGuid: getSelectedTemplateGuid(state),
  template: getSelectedTemplate(state),
  templatesGuids: getTemplatesGuid(state),
  seoScoreSection: getDefaultSeoScoreSection(state),
});

export default connect(mapStateToProps, {
  push,
  addTemplate,
  editTemplate,
  changeSelectedTemplate,
  deleteTemplateSuccessful,
})(CustomTemplate);
