import React, { Component } from "react";
import { Row, Col } from "antd";
import { Modal } from "antd";
import OutageIssueDetails from "./OutageIssueDetails";

class OutageTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: {
        ping: false,
        http: false
      }
    };
  }

  setModalVisible(eventType, visible) {
    this.setState({
      modalVisible: {
        [eventType]: visible
      }
    });
  }

  render() {
    const { events, url, location } = this.props;
    return events["ping"]["status"] === "down" || events["loading_time"]["status"] === "down" ? (
      <Row className="outage-tooltip-event" gutter={16}>
        <Col className="left" span={12}>
          {events["ping"]["status"] === "down" ? (
            <React.Fragment>
              <Modal
                className="issue-details-modal"
                title="Ping issue"
                centered
                visible={this.state.modalVisible["ping"]}
                footer={null}
                onCancel={() => this.setModalVisible("ping", false)}>
                <OutageIssueDetails url={url} createdAt={events["ping"]["created_at"]} location={location} />
              </Modal>
              <div className="event-status danger" onClick={() => this.setModalVisible("ping", true)}>
                PING ISSUE
              </div>
            </React.Fragment>
          ) : (
            <div className="event-status success">NO PING ISSUES</div>
          )}
        </Col>
        <Col className="right" span={12}>
          {events["loading_time"]["status"] === "down" ? (
            <React.Fragment>
              <Modal
                className="issue-details-modal"
                title="HTTP issue"
                centered
                visible={this.state.modalVisible["http"]}
                footer={null}
                onCancel={() => this.setModalVisible("http", false)}>
                <OutageIssueDetails url={url} createdAt={events["loading_time"]["created_at"]} location={location} />
              </Modal>
              <div className="event-status danger" onClick={() => this.setModalVisible("http", true)}>
                HTTP ISSUE
              </div>
            </React.Fragment>
          ) : (
            <div className="event-status success">NO HTTP ISSUES</div>
          )}
        </Col>
      </Row>
    ) : (
      <div className="outage-tooltip-event">
        <span className="event-status success">No issues found</span>
      </div>
    );
  }
}
export default OutageTooltip;
