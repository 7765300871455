import React, { Component } from "react";
import { connect } from "react-redux";
import ImprovementsListLayout from "../../components/SeoReport/ImprovementsListLayout";
import { getImportantFixes, getReportCreatedAt } from "../../store/analysis/selectors";

export class ImprovementsListWrapper extends Component {
  render() {    
    return <ImprovementsListLayout importantFixes={this.props.importantFixes} createdAt={this.props.createdAt} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  let { reportGuid } = ownProps;
  return {
    importantFixes: getImportantFixes(state, reportGuid),
    createdAt: getReportCreatedAt(state, reportGuid)
  };
};

export default connect(mapStateToProps, {})(ImprovementsListWrapper);
