import { createReducer } from "../reducer_utils";

const initialState = {
  network_activity: [],
  data: {},
  by_guid: {}
};

const getNetworkActivityMessage = action => {
  const { url, guid } = action;
  const messages = {
    list: `fetch_${url}`,
    context: `fetch_${guid}`
  };

  return url ? messages["list"] : messages["context"];
};

const addNetworkActivityMessage = (networkActivity, action) => [...networkActivity, getNetworkActivityMessage(action)];

const removeNetworkActivityMessage = (networkActivity, action) => {
  return networkActivity.filter(msg => msg !== getNetworkActivityMessage(action));
};

const fetchOutageListInProgress = (state, action) => ({
  ...state,
  network_activity: addNetworkActivityMessage(state.network_activity, action)
});

const fetchOutageListSuccess = (state, action) => {
  const { url, outages } = action;
  outages["selected_outage"] = null;

  const result = {
    ...state,
    network_activity: removeNetworkActivityMessage(state.network_activity, action),
    data: {
      ...state["data"],
      [url]: outages
    }
  };

  if (Object.keys(outages["active"]).length) {
    let context = outages["active"];
    result["by_guid"] = {
      ...state["by_guid"],
      [context["guid"]]: context
    };
    result["data"][url]["selected_outage"] = context["guid"];
  }

  return result;
};

const fetchOutageListFailed = (state, action) => ({
  ...state,
  network_activity: removeNetworkActivityMessage(state.network_activity, action)
});

const fetchOutageContextInProgress = (state, action) => ({
  ...state,
  network_activity: addNetworkActivityMessage(state.network_activity, action),
  data: {
    ...state["data"],
    [action.selectedUrl]: {
      ...state["data"][action.selectedUrl],
      selected_outage: action.guid
    }
  }
});

const fetchOutageContextSuccess = (state, action) => {
  const { guid, context } = action;
  if (context && context["url"]) {
    const url = context["url"];
    return {
      ...state,
      network_activity: removeNetworkActivityMessage(state.network_activity, action),
      data: {
        ...state["data"],
        [url]: {
          ...state["data"][url],
          selected_outage: guid
        }
      },
      by_guid: {
        ...state["by_guid"],
        [guid]: context
      }
    };
  } else {
    return {
      ...state,
      network_activity: removeNetworkActivityMessage(state.network_activity, action)
    };
  }
};

const fetchOutageContextFailed = (state, action) => ({
  ...state,
  network_activity: removeNetworkActivityMessage(state.network_activity, action),
  data: {
    ...state["data"],
    [action.selectedUrl]: {
      ...state["data"][action.selectedUrl],
      selected_outage: null
    }
  }
});

const resetSelectedOutage = (state, action) => {
  return {
    ...state,
    network_activity: removeNetworkActivityMessage(state.network_activity, action),
    data: {
      ...state["data"],
      [action.url]: {
        ...state["data"][action.url],
        selected_outage: null
      }
    }
  };
};

const logout = (state, _) => ({ ...state, ...initialState });

const outageReducer = createReducer(initialState, {
  FETCH_OUTAGE_LIST_IN_PROGRESS: fetchOutageListInProgress,
  FETCH_OUTAGE_LIST_SUCCESS: fetchOutageListSuccess,
  FETCH_OUTAGE_LIST_FAILED: fetchOutageListFailed,
  FETCH_OUTAGE_CONTEXT_IN_PROGRESS: fetchOutageContextInProgress,
  FETCH_OUTAGE_CONTEXT_SUCCESS: fetchOutageContextSuccess,
  FETCH_OUTAGE_CONTEXT_FAILED: fetchOutageContextFailed,
  RESET_SELECTED_OUTAGE: resetSelectedOutage,
  LOGOUT: logout
});

export default outageReducer;
