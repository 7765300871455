import { OUTAGE_API_URL } from "../../settings/config";
import {
  fetchOutageListInProgress,
  fetchOutageListSuccess,
  fetchOutageListFailed,
  fetchOutageContextInProgress,
  fetchOutageContextSuccess,
  fetchOutageContextFailed,
  resetSelectedOutage
} from "./creators";
import { getOutageContext } from "./selectors";
import { getSelectedWebsiteUrl } from "../tracked_websites/selectors";
import { authRequest } from "../authorizedRequests";

export const fetchOutageList = (url, startDate, endDate) => {
  return (dispatch, getState) => {
    const apiUrl = `${OUTAGE_API_URL}/outage/list?url=${url}&start_date=${startDate}&end_date=${endDate}`;

    //TODO: check if we already have data in redux
    // if (getSelectedIntervalLoadingTimeData(getState()).length > 0) {
    //   return Promise.resolve();
    // }

    dispatch(fetchOutageListInProgress(url));

    return authRequest(apiUrl, dispatch, getState)
      .then(response => {
        dispatch(fetchOutageListSuccess(url, response.outages));
      })
      .catch(_ => {
        dispatch(fetchOutageListFailed(url));
      });
  };
};

export const fetchOutageContext = guid => {
  return (dispatch, getState) => {
    let url = getSelectedWebsiteUrl(getState());
    if (guid) {
      const apiUrl = `${OUTAGE_API_URL}/outage/context?guid=${guid}`;
      let context = getOutageContext(getState(), guid);

      if (context && Object.keys(context).length) {
        dispatch(fetchOutageContextSuccess(guid, context));
        return Promise.resolve(context);
      }

      dispatch(fetchOutageContextInProgress(guid, url));

      authRequest(apiUrl, dispatch, getState)
        .then(response => {
          dispatch(fetchOutageContextSuccess(guid, response));
        })
        .catch(_ => {
          dispatch(fetchOutageContextFailed(guid, url));
        });
    } else {
      dispatch(resetSelectedOutage(url));
    }
  };
};
