import React from 'react';
import { Layout } from 'antd';
import GtagPaymentMethod from '../containers/Trackers/GtagPaymentMethod';
import { useNavigate } from '../router/hooks/useNavigate';
import AddNewTrackedWebsite from '../trackedWebsites/AddNewTrackedWebsite';
const { Content } = Layout;

export default function AccountSetupPage() {
    const navigate = useNavigate();

    return (
        <Content className="general-layout-content no-padding account-setup">
            <GtagPaymentMethod />
            <AddNewTrackedWebsite
                fromOnboarding={true}
                onFinishAddedWebsite={() => navigate('/dashboard')}
            />
        </Content>
    );
}
