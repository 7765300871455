import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { register } from "../../store/iam/operations";
import { isRegistrationAndAuthenticationFinished } from "../../store/iam/selectors";
import { RegisterForm } from "../../components/auth";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldResetCaptcha: false
    };
  }

  onError = () => {
    this.setState({ shouldResetCaptcha: true });
  };

  submitForm = (email, first_name, last_name, password, token) => {
    this.setState({ shouldResetCaptcha: false });
    this.props.register(email, first_name, last_name, password, token, this.onError);
  };

  render() {
    return (
      <RegisterForm
        onSubmit={this.submitForm}
        isLoading={this.props.isLoading}
        push={this.props.push}
        shouldResetCaptcha={this.state.shouldResetCaptcha}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: isRegistrationAndAuthenticationFinished(state)
});

export default connect(mapStateToProps, { register, push })(Register);
