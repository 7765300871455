import moment from 'moment';
import React from 'react';

const TIMEZONE: 'UTC' | 'LOCAL' = 'UTC';

type Props = {
    utcTime: number | string;
    format: 'timeOnly' | 'dateTime';
};

export default function DateTimeDisplay({ utcTime, format }: Props) {
    let momentFormat;
    let timeDisplay;

    if (typeof utcTime === 'number') {
        timeDisplay = moment.unix(utcTime).utc();
    } else {
        timeDisplay = moment.utc(utcTime);
    }

    if (format === 'timeOnly') {
        momentFormat = 'HH:mm A';
    }
    if (format === 'dateTime') {
        momentFormat = `MMM DD, YYYY - HH:mm ${TIMEZONE === 'UTC' ? '[UTC]' : ''}`;
    }

    if (TIMEZONE === 'LOCAL') {
        timeDisplay = timeDisplay.local();
    }

    return <>{timeDisplay.format(momentFormat)}</>;
}
