import { Form } from 'antd';
import React from 'react';
import { useSendLoadingTimeRequestMutation } from '../../../siteSpeed/service';
import IntermediateStepButtons from '../IntermediateStepButtons';
import { MonitorRegions, SeoLocationData } from '../../types';
import MonitorRegionsFormItem from './MonitorRegionsFormItem';
import LocationAndLanguageFormItem from './LocationAndLanguageFormItem';

type Props = {
    selectedLocationName: string;
    selectedLanguageCode: string;
    selectedMainRegion: string;
    selectedAdditionalRegions: string[];
    selectedUrl: string;
    maxRegions: number;
    monitorRegions: MonitorRegions;
    monitorRegionsOrder: string[];
    locationsAndLanguages: SeoLocationData[];
    onMainRegionChange: (newMainRegion: string) => void;
    onAdditionalRegionsChange: (newAdditionalRegions: string[]) => void;
    onLocationChange: (newLocation: string) => void;
    onLanguageChange: (newLanguage: string) => void;
    onPreviousStep: () => void;
    onFinishThisStep: () => void;
};

export default function SetLocationsStep({
    selectedLocationName,
    selectedLanguageCode,
    selectedMainRegion,
    selectedAdditionalRegions,
    selectedUrl,
    maxRegions,
    monitorRegions,
    monitorRegionsOrder,
    locationsAndLanguages,
    onMainRegionChange,
    onAdditionalRegionsChange,
    onLocationChange,
    onLanguageChange,
    onPreviousStep,
    onFinishThisStep,
}: Props) {
    const [sendLoadingTimeRequest, { isLoading: isSendingLoadingTimeRequest }] = useSendLoadingTimeRequestMutation();

    const validateSeoDb = () => {
        const appendedRegions = selectedMainRegion ? [selectedMainRegion, ...selectedAdditionalRegions] : [];

        sendLoadingTimeRequest({ url: selectedUrl, regions: appendedRegions })
            .unwrap()
            .finally(() => {
                onFinishThisStep();
            });
    };

    return (
        <div className="steps-content">
            <Form className="step-form">
                <LocationAndLanguageFormItem
                    locationsAndLanguages={locationsAndLanguages}
                    selectedLocationName={selectedLocationName}
                    selectedLanguageCode={selectedLanguageCode}
                    onLocationChange={onLocationChange}
                    onLanguageChange={onLanguageChange}
                />
                <MonitorRegionsFormItem
                    selectedMainRegion={selectedMainRegion}
                    selectedAdditionalRegions={selectedAdditionalRegions}
                    maxRegions={maxRegions}
                    monitorRegions={monitorRegions}
                    monitorRegionsOrder={monitorRegionsOrder}
                    onMainRegionChange={onMainRegionChange}
                    onAdditionalRegionsChange={onAdditionalRegionsChange}
                />
            </Form>
            <div className="steps-action">
                <IntermediateStepButtons
                    onPreviousClicked={onPreviousStep}
                    onNextClicked={validateSeoDb}
                    isLoading={isSendingLoadingTimeRequest}
                />
            </div>
        </div>
    );
}
