import React, { useState } from "react";
import ConfirmCancellation from "../Sales/ConfirmCancellation";

const SubscriptionPlansHeader = props => {
  const [cancellationReasonModalVisible, setCancellationReasonModalVisible] = useState(false);
  const toggleCancellationReasonModal = _ => {
    setCancellationReasonModalVisible(!cancellationReasonModalVisible);
  };
  const cancelSubscription = (reason, comment) => {
    props.onCancelSubscription(reason, comment).then(_ => {
      setCancellationReasonModalVisible(false);
    });
  };
  const confirmCancellation = _ => {
    setCancellationReasonModalVisible(true);
  };
  return (
    <div className="subscription-status">
      {props.subscriptionStatus === "expired" ? (
        <h3>Your subscription for the {props.planName} has expired!</h3>
      ) : (
        <h3>
          You're currently using the {props.planName} - ${props.planPrice}/
          {props.planIntervalUnit === "month" && props.planIntervalLength === 1 ? "mo" : "year"}
        </h3>
      )}

      {props.subscriptionStatus === "canceled" && (
        <h5>
          Your subscription was canceled but it will remain active until the end of the period:&nbsp;
          {props.currentPeriodEnd}
        </h5>
      )}

      {props.subscriptionStatus === "active" && (
        <div>
          <h5>Your next payment will be on {props.nextPaymentDate}</h5>
          <span className="cancel-subscription" onClick={_ => confirmCancellation()}>
            Cancel subscription
          </span>
          <ConfirmCancellation
            title="Confirm subscription cancellation"
            visible={cancellationReasonModalVisible}
            showInfo={true}
            onClose={_ => toggleCancellationReasonModal()}
            onConfirmCancellation={(reason, comment) => cancelSubscription(reason, comment)}
            loading={props.isCancelingSubscription}
          />
        </div>
      )}
    </div>
  );
};
export default SubscriptionPlansHeader;
