import React from "react";
import { Card } from "antd";
import SeoReportSection from "../../components/SeoReport/SeoReportSection/SeoReportSection";
import CategoryStats from "../../components/SeoReport/CategoryStats/CategoryStats";

const SeoReportCategory = props => {
  return (
    <Card
      className="report-card"
      title={props.categoryName}
      key={props.categoryName}
      extra={
        <CategoryStats
          score={props.categoryScore || 0}
          passed_status_total={props.categorySectionsStatusCounter.passed}
          warning_status_total={props.categorySectionsStatusCounter.warning}
          failed_status_total={props.categorySectionsStatusCounter.failed}
        />
      }>
      {props.categorizedSelectedSections[props.categoryName].map(sectionKey => {
        let sectionResult = props.sectionsResults[sectionKey];
        let competitorResults = props.competitorsSectionsResults[sectionKey];

        return (
          sectionResult && (
            <SeoReportSection
              key={sectionResult.section}
              sectionKey={sectionResult.section}
              title={sectionResult.title}
              tooltip={sectionResult.description}
              result={sectionResult.result}
              relevance={sectionResult.relevance}
              passRate={sectionResult["top_100_pass_rate"]}
              competitorResults={competitorResults}
              url={props.main_url}
            />
          )
        );
      })}
    </Card>
  );
};

export default SeoReportCategory;
