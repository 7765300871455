import React from "react";
import { Layout } from "antd";

import TeamMembersLayout from "../containers/TeamMembers/TeamMembersLayout";

const { Content } = Layout;

const TeamMembersPage = () => {
  return (
    <Content className="general-layout-content">
      <TeamMembersLayout />
    </Content>
  );
};

export default TeamMembersPage;
