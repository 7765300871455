import React, { useMemo, useState } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';
import KeywordResearchSuggestionsTabs from './suggestions/KeywordResearchSuggestionsTabs';
import { defaultResearchLocation, defaultResearchLanguageCode } from './settings';
import { useFetchLocationsAndLanguagesQuery } from '../trackedWebsites/service';
import { getAvailableLanguagesForLocation } from '../trackedWebsites/utils';

const { Option } = Select;

const initialState = {
    isModalVisible: false,
    keyword: '',
    location: defaultResearchLocation,
    languageCode: defaultResearchLanguageCode,
    validationError: '',
};

export default function KeywordResearchForm() {
    const [state, setState] = useState(initialState);

    const { data: locationsAndLanguages, isLoading: isLoadingLocationsAndLanguages } = useFetchLocationsAndLanguagesQuery();

    const availableLanguages = useMemo(
        () => (locationsAndLanguages ? getAvailableLanguagesForLocation(state.location, locationsAndLanguages) : []),
        [locationsAndLanguages, state.location]
    );

    const handleCloseModal = () => {
        setState({ ...state, isModalVisible: false, keyword: '' });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const keyword = state.keyword;

        if (!keyword) {
            setState({ ...state, validationError: 'This field is required!' });
        } else {
            setState({ ...state, isModalVisible: true });
        }
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.value;

        setState({ ...state, keyword, validationError: '' });
    };

    const handleLocationChange = (newLocation: string) => {
        if (locationsAndLanguages) {
            let newLanguageCode = '';
            const availableLanguages = getAvailableLanguagesForLocation(newLocation, locationsAndLanguages);

            if (availableLanguages.length > 0) {
                newLanguageCode = availableLanguages[0].language_code;
            }

            setState({ ...state, location: newLocation, languageCode: newLanguageCode });
        }
    };

    const handleLanguageChange = (newLanguageCode: string) => {
        setState({ ...state, languageCode: newLanguageCode });
    };

    return (
        <>
            <Form
                className="keyword-research-form"
                onSubmit={handleSubmit}
            >
                <Form.Item
                    validateStatus={state.validationError ? 'error' : 'success'}
                    help={state.validationError}
                >
                    <Input.Group
                        compact
                        size="large"
                    >
                        <div className="compact-input-group flex-wrap">
                            <div className="double-selector">
                                <label htmlFor="keywordInput">Keyword</label>
                                <Input
                                    id="keywordInput"
                                    placeholder="Enter a keyword"
                                    name="keyword"
                                    size="large"
                                    value={state.keyword}
                                    onChange={handleKeywordChange}
                                />
                                <label
                                    className="database-label"
                                    htmlFor="location"
                                >
                                    Location
                                </label>
                                <Select
                                    id="location"
                                    size="large"
                                    className="no-radius"
                                    value={state.location}
                                    onChange={handleLocationChange}
                                    disabled={isLoadingLocationsAndLanguages}
                                >
                                    {locationsAndLanguages &&
                                        locationsAndLanguages.map((location) => (
                                            <Option
                                                key={location.location_name}
                                                value={location.location_name}
                                            >
                                                {location.location_name}
                                            </Option>
                                        ))}
                                </Select>
                                <label
                                    className="database-label"
                                    htmlFor="language"
                                >
                                    Language
                                </label>
                                <Select
                                    id="language"
                                    size="large"
                                    className="no-radius"
                                    value={state.languageCode}
                                    onChange={handleLanguageChange}
                                    disabled={isLoadingLocationsAndLanguages}
                                >
                                    {availableLanguages.map((language) => (
                                        <Option
                                            key={language.language_code}
                                            value={language.language_code}
                                        >
                                            {language.language_name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                disabled={isLoadingLocationsAndLanguages}
                            >
                                Check for Keywords
                            </Button>
                        </div>
                    </Input.Group>
                </Form.Item>
            </Form>

            <Modal
                className="keyword-research-modal"
                title={
                    <ModalTitle
                        title="Keywords suggestions for: "
                        meta={state.keyword}
                    />
                }
                visible={state.isModalVisible}
                onCancel={handleCloseModal}
                footer={null}
                destroyOnClose
            >
                <KeywordResearchSuggestionsTabs
                    keyword={state.keyword}
                    location={state.location}
                    languageCode={state.languageCode}
                />
            </Modal>
        </>
    );
}
