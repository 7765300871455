import React from 'react';
import { Tabs } from 'antd';
import KeywordResearchSuggestionsResult from './KeywordResearchSuggestionsResult';
import { defaultSelectedResearchTab, researchSections, researchTabsDisplayOrder } from '../settings';

const { TabPane } = Tabs;

type Props = {
    keyword: string;
    location: string;
    languageCode: string;
};

export default function KeywordResearchSuggestionsTabs({ keyword, location, languageCode }: Props) {
    return (
        <Tabs
            defaultActiveKey={defaultSelectedResearchTab}
            type="card"
            tabBarGutter={10}
            data-cy="keyword-research-tabs"
            destroyInactiveTabPane={true}
        >
            {researchTabsDisplayOrder.map((section) => (
                <TabPane
                    tab={researchSections[section].title}
                    key={researchSections[section].researchType}
                >
                    <KeywordResearchSuggestionsResult
                        researchType={researchSections[section].researchType}
                        keyword={keyword}
                        location={location}
                        languageCode={languageCode}
                    />
                </TabPane>
            ))}
        </Tabs>
    );
}
