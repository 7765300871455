import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { ReferringDomain } from '../../types';
import AnchorTableCell from './AnchorTableCell';

const { Column } = Table;

type Props = {
    referringDomains: ReferringDomain[];
};

export default function ReferringDomainsTable({ referringDomains }: Props) {
    const dataSource = referringDomains.map((referringDomain, index) => ({ ...referringDomain, key: index + 1 }));

    return (
        <Table
            dataSource={dataSource}
            pagination={false}
            size="small"
            data-cy="referring-domains-table"
        >
            <Column
                title="#"
                dataIndex="key"
                align="left"
            />
            <Column
                className="no-wrap"
                title="Domain"
                dataIndex="domain"
                render={(text, record: ReferringDomain, index) => (
                    <AnchorTableCell
                        url={`http://${record.domain}`}
                        anchor={record.domain}
                    />
                )}
            />
            <Column
                className="no-wrap al-right"
                title="Backlinks Number"
                dataIndex="backlinks"
                render={(text, record: ReferringDomain, index) => record.backlinks.toLocaleString()}
            />
            <Column
                className="no-wrap al-right"
                title="Domain Rank"
                dataIndex="rank"
            />
            <Column
                className="no-wrap al-right"
                title="First Seen"
                dataIndex="first_seen"
                render={(text, record: ReferringDomain, index) => moment(record.first_seen).format('MMM DD, YYYY')}
            />
        </Table>
    );
}
