import React, { Component } from "react";
import { Layout } from "antd";
import { FreeTrialSetup } from "../containers";
import GtagRegistration from "../containers/Trackers/GtagRegistration"
const { Content } = Layout;
export default class FreeTrialSetupPage extends Component {
  render() {
    return (
      <Content className="general-layout-content no-padding">
        <GtagRegistration />
        <FreeTrialSetup />
      </Content>
    );
  }
}
