import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { validate } from "validate.js";
import { v4 as uuidv4 } from "uuid";
import { isValidUrl, AnalysisForm } from "../components/Analysis";
import { getAnalysisReportGeneralStructure, initAnalysis } from "../store/analysis/operations";
import { getMaxCompetitorsNo } from "../store/sales/selectors";
import { isReportInProgress, getReportInProgress, getUrlForReportInProgress } from "../store/analysis/selectors";
import ArchivedReportsOverviewContainer from "./ArchivedReportsOverviewContainer";
import TrackedWebsites from "../trackedWebsites/TrackedWebsites";
class Analysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainUrlValidation: "",
      competitorsValidation: []
    };
  }

  componentDidMount = (_) => {
    if (this.props.error) {
      this.props.push("/analysis");
    }
    this.props.getAnalysisReportGeneralStructure();
  };

  normalizeUrl = (url, urlSchema) => {
    let normalizedUrl = url.toLowerCase().trim();
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      normalizedUrl = urlSchema.concat(url);
    }
    return normalizedUrl;
  };

  handleCheckup = (mainWebsite, competitors, sections) => {
    let mainUrl = this.normalizeUrl(mainWebsite.url, mainWebsite.urlSchema);
    let mainUrlValidation = validate({ url: mainUrl }, isValidUrl);

    let competitorUrls = [];
    let competitorsValidation = [];
    competitors.forEach((competitor) => {
      if (competitor.url.length) {
        let competitorUrl = this.normalizeUrl(competitor.url, competitor.urlSchema);
        let competitorValidation = validate({ url: competitorUrl }, isValidUrl);
        competitorsValidation.push({ url: competitorUrl, validation: competitorValidation });
        competitorUrls.push(competitorUrl);
      }
    });

    if (
      typeof mainUrlValidation === "undefined" &&
      competitorsValidation.every((competitor) => typeof competitor.validation === "undefined")
    ) {
      const tempGuid = uuidv4();
      this.setState({
        mainUrlValidation: "",
        competitorsValidation: []
      });
      this.props.push("/analysis/report");

      let uniqueCompetitorsUrls = competitorUrls.filter(
        (item, index) => competitorUrls.indexOf(item) === index && item !== mainUrl
      );
      this.props.initAnalysis(tempGuid, mainUrl, uniqueCompetitorsUrls, sections);
    } else {
      this.setState({
        mainUrlValidation: mainUrlValidation ? mainUrlValidation["url"][0] : "",
        competitorsValidation: competitorsValidation.map((competitor) => ({
          url: competitor.url,
          msg: competitor.validation ? competitor.validation["url"][0] : ""
        }))
      });
    }
  };

  render() {
    if (this.props.isReportInProgress) {
      this.props.push(`/analysis/report?guid=${this.props.reportInProgressGuid}&url=${this.props.reportInProgressUrl}`);
    }

    return (
      <React.Fragment>
        <AnalysisForm
          maxCompetitors={this.props.maxCompetitorsNo}
          onSubmit={this.handleCheckup}
          mainUrlValidation={this.state.mainUrlValidation}
          competitorsValidation={this.state.competitorsValidation}
        />
        <TrackedWebsites destroyInactiveTabs={true} tabContent={<ArchivedReportsOverviewContainer />} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  maxCompetitorsNo: getMaxCompetitorsNo(state),
  isReportInProgress: isReportInProgress(state),
  reportInProgressGuid: getReportInProgress(state),
  reportInProgressUrl: getUrlForReportInProgress(state)
});

export default connect(mapStateToProps, {
  push,
  getAnalysisReportGeneralStructure,
  initAnalysis
})(Analysis);
