import React from 'react';
import { Card, Statistic } from 'antd';
import { ChartDataPoint } from '../types';
import BacklinksOverviewChart from './BacklinksOverviewChart';

type Props = {
    title: JSX.Element;
    value: string;
    trend: React.JSX.Element;
    chartData: ChartDataPoint[];
    showChart: boolean;
};

export default function BacklinksOverviewBox({ title, value, trend, chartData, showChart }: Props) {
    return (
        <div>
            {showChart && (
                <Card
                    title={
                        <Statistic
                            title={title}
                            value={value}
                            prefix={trend}
                        />
                    }
                >
                    <BacklinksOverviewChart chartData={chartData} />
                </Card>
            )}

            {!showChart && (
                <Card>
                    <Statistic
                        title={title}
                        value={value}
                        prefix={trend}
                    />
                </Card>
            )}
        </div>
    );
}
