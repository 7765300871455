import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import WhiteLabelTabs from "../../components/WhiteLabel/report/WhiteLabelTabs";
import { isFetchingTemplates, getSelectedTemplateGuid, getTemplatesGuid } from "../../store/white_label/selectors";
import { changeSelectedTemplate } from "../../store/white_label/creators";
import { fetchTemplates } from "../../store/white_label/operations";
import { getTrackedWebsites } from "../../store/tracked_websites/operations";
import { isGettingTrackedWebsites } from "../../store/tracked_websites/selectors";
import { isWhiteLabelAllowed } from "../../store/sales/selectors";

class WhiteLabel extends Component {
  constructor(props) {
    super(props);

    this.getSelectedTab = this.getSelectedTab.bind(this);
  }

  componentDidMount() {
    this.props.getTrackedWebsites();
    this.props.fetchTemplates();
  }

  getSelectedTab() {
    let selectedTabKey;
    if (this.props.pathname === "/white-label/reports") {
      selectedTabKey = "reports";
    }
    if (this.props.pathname.startsWith("/white-label/templates")) {
      selectedTabKey = "templates";
    }

    return selectedTabKey;
  }

  render() {
    return (
      <WhiteLabelTabs
        push={this.props.push}
        idUrlParam={this.props.idUrlParam}
        isLoading={this.props.isFetchingTemplates || this.props.isFetchingTrackedWebsites}
        templateGuid={this.props.guid}
        templatesGuid={this.props.templatesGuid}
        changeSelectedTemplate={this.props.changeSelectedTemplate}
        queryParams={this.props.queryParams}
        isWhiteLabelAllowed={this.props.isWhiteLabelAllowed}
        activeKey={this.getSelectedTab()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isFetchingTemplates: isFetchingTemplates(state),
  isFetchingTrackedWebsites: isGettingTrackedWebsites(state),
  guid: getSelectedTemplateGuid(state),
  templatesGuid: getTemplatesGuid(state),
  queryParams: state.router.location.query, // queryParams is an object
  pathname: state.router.location.pathname,
  isWhiteLabelAllowed: isWhiteLabelAllowed(state),
});

export default connect(mapStateToProps, { push, fetchTemplates, changeSelectedTemplate, getTrackedWebsites })(
  WhiteLabel
);
