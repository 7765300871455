import React, { Component } from "react";
import "./Timeline.less";

class TimelineTicks extends Component {
  render() {
    return (
      <ul className="stick-container">
        <li className="stick" style={{ flexGrow: 24 }}>
          <span className="stick-value">0AM</span>
          <ul className="minor-stick-container">
            <li className="minor-stick"></li>
            {[...Array(23)].map((v, idx) => (
              <li className="minor-stick" key={`tick-${idx}`}>
                <span className="stick-value">
                  {String(idx + 1)}
                  {idx + 1 < 12 ? "AM" : "PM"}
                </span>
              </li>
            ))}
            <li className="minor-stick"></li>
          </ul>
        </li>
        <li className="stick" style={{ flexGrow: 0 }}>
          <span className="stick-value">24PM</span>
        </li>
      </ul>
    );
  }
}
export default TimelineTicks;
