export const AUTHENTICATION_IN_PROGRESS = "AUTHENTICATION_IN_PROGRESS";
export const AUTHENTICATION_SUCCESSFUL = "AUTHENTICATION_SUCCESSFUL";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
export const REGISTRATION_IN_PROGRESS = "REGISTRATION_IN_PROGRESS";
export const REGISTRATION_SUCCESSFUL = "REGISTRATION_SUCCESSFUL";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";
export const REGISTRATION_AND_LOGIN_IS_FINISHED = "REGISTRATION_AND_LOGIN_IS_FINISHED";

export const DELETE_USER_IN_PROGRESS = "DELETE_USER_IN_PROGRESS";
export const DELETE_USER_SUCCESSFUL = "DELETE_USER_SUCCESSFUL";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const PASSWORD_RESET_REQUEST_IN_PROGRESS = "PASSWORD_RESET_REQUEST_IN_PROGRESS";
export const PASSWORD_RESET_REQUEST_SUCCESS = "PASSWORD_RESET_REQUEST_SUCCESS";
export const PASSWORD_RESET_REQUEST_FAILED = "PASSWORD_RESET_REQUEST_FAILED";

export const USER_IS_LOADING = "USER_IS_LOADING";
export const GET_USER = "GET_USER";
export const USER_IS_NOT_AVAILABLE = "USER_IS_NOT_AVAILABLE";

export const CHANGE_USER_NAME_IN_PROGRESS = "CHANGE_USER_NAME_IN_PROGRESS";
export const CHANGE_USER_NAME_SUCCESSFUL = "CHANGE_USER_NAME_SUCCESSFUL";
export const CHANGE_USER_NAME_FAILED = "CHANGE_USER_NAME_FAILED";

export const CHANGE_PASSWORD_IN_PROGRESS = "CHANGE_PASSWORD_IN_PROGRESS";
export const CHANGE_PASSWORD_SUCCESSFUL = "CHANGE_PASSWORD_SUCCESSFUL";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const CONFIRM_PASSWORD_IN_PROGRESS = "CONFIRM_PASSWORD_IN_PROGRESS";
export const CONFIRM_PASSWORD_SUCCESSFUL = "CONFIRM_PASSWORD_SUCCESSFUL";
export const CONFIRM_PASSWORD_FAILED = "CONFIRM_PASSWORD_FAILED";

export const LOGOUT = "LOGOUT";
export const CLEAR_STATE = "CLEAR_IAM_STATE";

export const UPDATE_ACCESS_TOKEN_IN_PROGRESS = "UPDATE_ACCESS_TOKEN_IN_PROGRESS";
export const UPDATE_ACCESS_TOKEN_SUCCESS = "UPDATE_ACCESS_TOKEN_SUCCESS";
export const UPDATE_ACCESS_TOKEN_FAILED = "UPDATE_ACCESS_TOKEN_FAILED";

export const UPDATE_REQUIRED_EXPERIENCES_IN_PROGRESS = "UPDATE_REQUIRED_EXPERIENCES_IN_PROGRESS";
export const UPDATE_REQUIRED_EXPERIENCES_SUCCESSFUL = "UPDATE_REQUIRED_EXPERIENCES_SUCCESSFUL";
export const UPDATE_REQUIRED_EXPERIENCES_FAILED = "UPDATE_REQUIRED_EXPERIENCES_FAILED";

export const CREATE_ORGANIZATION_IN_PROGRESS = "CREATE_ORGANIZATION_IN_PROGRESS";
export const CREATE_ORGANIZATION_SUCCESSFUL = "CREATE_ORGANIZATION_SUCCESSFUL";
export const CREATE_ORGANIZATION_FAILED = "CREATE_ORGANIZATION_FAILED";

export const GET_ORGANIZATION_IN_PROGRESS = "GET_ORGANIZATION_IN_PROGRESS";
export const GET_ORGANIZATION_SUCCESSFUL = "GET_ORGANIZATION_SUCCESSFUL";
export const GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_FAILED";

export const UPDATE_ORGANIZATION_IN_PROGRESS = "UPDATE_ORGANIZATION_IN_PROGRESS";
export const UPDATE_ORGANIZATION_SUCCESSFUL = "UPDATE_ORGANIZATION_SUCCESSFUL";
export const UPDATE_ORGANIZATION_FAILED = "UPDATE_ORGANIZATION_FAILED";

// export const INVITE_TEAM_MEMBER_IN_PROGRESS = "INVITE_TEAM_MEMBER_IN_PROGRESS";
// export const INVITE_TEAM_MEMBER_SUCCESSFUL = "INVITE_TEAM_MEMBER_SUCCESSFUL";
// export const INVITE_TEAM_MEMBER_FAILED = "INVITE_TEAM_MEMBER_FAILED";

export const DELETE_TEAM_MEMBER_IN_PROGRESS = "DELETE_TEAM_MEMBER_IN_PROGRESS";
export const DELETE_TEAM_MEMBER_SUCCESSFUL = "DELETE_TEAM_MEMBER_SUCCESSFUL";
export const DELETE_TEAM_MEMBER_FAILED = "DELETE_TEAM_MEMBER_FAILED";

// export const UPDATE_TEAM_MEMBER_IN_PROGRESS = "UPDATE_TEAM_MEMBER_IN_PROGRESS";
// export const UPDATE_TEAM_MEMBER_SUCCESSFUL = "UPDATE_TEAM_MEMBER_SUCCESSFUL";
// export const UPDATE_TEAM_MEMBER_FAILED = "UPDATE_TEAM_MEMBER_FAILED";

// export const GET_TEAM_MEMBER_INVITATIONS_IN_PROGRESS = "GET_TEAM_MEMBER_INVITATIONS_IN_PROGRESS";
// export const GET_TEAM_MEMBER_INVITATIONS_SUCCESSFUL = "GET_TEAM_MEMBER_INVITATIONS_SUCCESSFUL";
// export const GET_TEAM_MEMBER_INVITATIONS_FAILED = "GET_TEAM_MEMBER_INVITATIONS_FAILED";

// export const ACCEPT_TEAM_MEMBER_INVITATION_IN_PROGRESS = "ACCEPT_TEAM_MEMBER_INVITATION_IN_PROGRESS";
// export const ACCEPT_TEAM_MEMBER_INVITATION_SUCCESSFUL = "ACCEPT_TEAM_MEMBER_INVITATION_SUCCESSFUL";
// export const ACCEPT_TEAM_MEMBER_INVITATION_FAILED = "ACCEPT_TEAM_MEMBER_INVITATION_FAILED";

// export const DECLINE_TEAM_MEMBER_INVITATION_IN_PROGRESS = "DECLINE_TEAM_MEMBER_INVITATION_IN_PROGRESS";
// export const DECLINE_TEAM_MEMBER_INVITATION_SUCCESSFUL = "DECLINE_TEAM_MEMBER_INVITATION_SUCCESSFUL";
// export const DECLINE_TEAM_MEMBER_INVITATION_FAILED = "DECLINE_TEAM_MEMBER_INVITATION_FAILED";

// export const GET_TEAM_MEMBER_IN_PROGRESS = "GET_TEAM_MEMBER_IN_PROGRESS";
// export const GET_TEAM_MEMBER_SUCCESSFUL = "GET_TEAM_MEMBER_SUCCESSFUL";
// export const GET_TEAM_MEMBER_FAILED = "GET_TEAM_MEMBER_FAILED";
