import { Form } from 'antd';
import React, { useState } from 'react';
import IntermediateStepButtons from '../IntermediateStepButtons';
import UrlInput from '../UrlInput';
import { normalizeUrl, urlValidationRule } from '../../utils';
import validate from 'validate.js';

const initialState = {
    competitorsError: [] as CompetitorError[],
};

function competitorCompare(first: Competitor, second: Competitor) {
    if (first.id < second.id) {
        return -1;
    }
    if (first.id > second.id) {
        return 1;
    }
    return 0;
}

type CompetitorError = {
    id: number;
    error: string;
};

export type Competitor = {
    id: number;
    domain: string;
    urlSchema: string;
};

type Props = {
    domain: string;
    competitors: Competitor[];
    maxCompetitors: number;
    onPreviousStep: () => void;
    onCompetitorsChange: (newCompetitors: Competitor[]) => void;
    onFinishThisStep: () => void;
};

export default function SetCompetitorsStep({ domain, competitors, maxCompetitors, onPreviousStep, onCompetitorsChange, onFinishThisStep }: Props) {
    const [state, setState] = useState({ ...initialState });

    const handleCompetitorChanged = (index: number) => (newDomain: string, newUrlSchema: string) => {
        const competitor: Competitor = { domain: newDomain, urlSchema: newUrlSchema, id: index };

        const newCompetitors = [...competitors];

        let modifiedCompetitorIdx = competitors.findIndex((comp) => comp.id === competitor.id);
        if (modifiedCompetitorIdx > -1) {
            newCompetitors[modifiedCompetitorIdx] = competitor;
        } else {
            newCompetitors.push(competitor);
        }
        newCompetitors.sort(competitorCompare);

        onCompetitorsChange(newCompetitors);
    };

    const getErrorMessage = (index: number) => {
        let competitorError = state.competitorsError.find((error) => error.id === index);
        if (!competitorError) {
            return '';
        }
        return competitorError.error;
    };

    const validateCompetitors = () => {
        const competitorsError = [] as CompetitorError[];
        competitors.forEach((competitor) => {
            if (!competitor.domain) {
                return;
            }
            const normalizedCompetitorUrl = normalizeUrl(competitor.domain, competitor.urlSchema);
            const competitorValidation = validate({ url: normalizedCompetitorUrl }, urlValidationRule);
            if (competitorValidation) {
                competitorsError.push({ id: competitor.id, error: competitorValidation.url[0] });
            }
        });

        setState({ competitorsError: competitorsError });

        const valid = competitorsError.every((competitorError) => competitorError.error.length === 0);
        if (valid) {
            onFinishThisStep();
        }
    };

    const competitorInputs = [...Array(maxCompetitors)].map((_, index) => {
        const competitor = competitors.find((comp) => comp.id === index);
        const validationError = getErrorMessage(index);

        return (
            <Form.Item
                key={index}
                validateStatus={validationError ? 'error' : 'validating'}
                help={validationError ? validationError : ''}
            >
                <UrlInput
                    placeholder={`competitor${index + 1}.com`}
                    id={`competitor${index}`}
                    domain={competitor ? competitor.domain : ''}
                    urlSchema={competitor ? competitor.urlSchema : 'https://'}
                    onUrlChange={handleCompetitorChanged(index)}
                />
            </Form.Item>
        );
    });

    return (
        <div className="steps-content">
            <Form className="step-form">
                <Form.Item>
                    <div>
                        <h3>Add URLs for competitors of {domain}</h3>
                        <p>Add up to {maxCompetitors} competitors for side-by-side SEO comparison</p>
                    </div>
                </Form.Item>
                <div className="wizard-competitors-form">{competitorInputs}</div>
            </Form>
            <div className="steps-action">
                <IntermediateStepButtons
                    onPreviousClicked={onPreviousStep}
                    onNextClicked={validateCompetitors}
                />
            </div>
        </div>
    );
}
