import './SiteSpeed.less';
import React, { useRef, useState } from 'react';
import { Card, Button, Select, Alert } from 'antd';
import { TrackedWebsite } from '../trackedWebsites/types';
import ReportCardTitle from '../components/shared/ReportCardTitle/ReportCardTitle';
import LoadingIndicator from '../components/shared/LoadingIndicator/LoadingIndicator';
import { useFetchLoadingTimeQuery } from './service';
import moment from 'moment';
import { useFetchMonitorRegionsQuery } from '../trackedWebsites/service';
import SiteSpeedChart from './SiteSpeedChart';
import { getSiteSpeedChartData } from './utils';

const { Option } = Select;

type Props = {
    selectedWebsite: TrackedWebsite;
};

type Interval = 'last_24_hours' | 'last_7_days' | 'last_30_days';

function getGroupBy(interval: Interval) {
    if (interval === 'last_24_hours') {
        return 'hour';
    }
    return 'day';
}

function getEndDate(interval: Interval) {
    const groupBy = getGroupBy(interval);
    const currentDate = moment().utc();
    let endDate = currentDate.format('YYYY-MM-DDT00:00:00');

    if (groupBy === 'hour') {
        endDate = currentDate.format('YYYY-MM-DDTHH:mm:ss');
    }

    return endDate;
}

function getStartDate(interval: Interval, endDateStr: string) {
    const endDate = moment(endDateStr);
    let startDate;

    if (interval === 'last_24_hours') {
        startDate = endDate.subtract(24, 'hours');
    } else if (interval === 'last_7_days') {
        startDate = endDate.subtract(7, 'days');
    } else {
        startDate = endDate.subtract(30, 'days');
    }

    return startDate.format('YYYY-MM-DDTHH:mm:ss');
}

export default function SiteSpeed({ selectedWebsite }: Props) {
    const selectRegionsElement = useRef<Select<string>>(null);
    const [selectedRegion, setSelectedRegion] = useState<string>(selectedWebsite.settings.regions[0]);
    const [selectedInterval, setSelectedInterval] = useState<Interval>('last_24_hours');

    const endDateStr = getEndDate(selectedInterval);
    const startDateStr = getStartDate(selectedInterval, endDateStr);
    const groupBy = getGroupBy(selectedInterval);

    const { data: siteSpeedData, isFetching: isLoadingSiteSpeedData } = useFetchLoadingTimeQuery({
        url: selectedWebsite.url,
        region: selectedRegion,
        interval: selectedInterval,
        startDate: startDateStr,
        endDate: endDateStr,
        groupBy: groupBy,
    });

    const { data: monitorRegions } = useFetchMonitorRegionsQuery();

    const handleChangeRegion = (newRegion: string) => {
        setSelectedRegion(newRegion);
        if (selectRegionsElement.current) {
            selectRegionsElement.current.blur();
        }
    };

    const handleSetSelectedInterval = (newInterval: Interval) => {
        setSelectedInterval(newInterval);
    };

    return (
        <Card
            title={
                <ReportCardTitle
                    title={'Site Speed overview'}
                    domain={selectedWebsite.url}
                />
            }
            extra={
                <div className="regions-select">
                    <span>From region: </span>
                    <Select
                        className="monitor-locations"
                        style={{ width: 200 }}
                        defaultValue={selectedWebsite.settings.regions[0]}
                        onChange={handleChangeRegion}
                        ref={selectRegionsElement}
                    >
                        {monitorRegions &&
                            selectedWebsite.settings.regions.map((region) => (
                                <Option
                                    value={region}
                                    key={region}
                                >
                                    {monitorRegions.regions[region]}
                                </Option>
                            ))}
                    </Select>
                </div>
            }
            data-cy="site-speed"
        >
            {isLoadingSiteSpeedData || !siteSpeedData ? (
                <LoadingIndicator />
            ) : (
                <>
                    {siteSpeedData.reports.length === 0 ? (
                        <Alert message="No data for this url" />
                    ) : (
                        <SiteSpeedChart
                            chartData={getSiteSpeedChartData(siteSpeedData.reports)}
                            selectedInterval={selectedInterval}
                        />
                    )}

                    <div className="site-speed-buttons">
                        <Button
                            className={selectedInterval === 'last_24_hours' ? 'active' : ''}
                            onClick={() => handleSetSelectedInterval('last_24_hours')}
                        >
                            Last 24 hours
                        </Button>
                        <Button
                            className={selectedInterval === 'last_7_days' ? 'active' : ''}
                            onClick={() => handleSetSelectedInterval('last_7_days')}
                        >
                            Last 7 days
                        </Button>
                        <Button
                            className={selectedInterval === 'last_30_days' ? 'active' : ''}
                            onClick={() => handleSetSelectedInterval('last_30_days')}
                        >
                            Last 30 days
                        </Button>
                    </div>
                </>
            )}
        </Card>
    );
}
