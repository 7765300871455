import React from 'react';
import { Progress, Statistic, Icon, Row, Col, Card } from 'antd';
import { TopKeywordsOverview } from '../topKeywords/types';

type Props = {
    overview: TopKeywordsOverview;
};

export default function DashboardTopKeywordsOverview({ overview }: Props) {
    const total = overview.dropped + overview.increased + overview.unchanged;

    return (
        <Row
            data-cy="top-keywords-overview"
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginBottom: 20 }}
        >
            <Col span={16}>
                <div>
                    <span>
                        <strong>{overview.increased}</strong> Increased
                    </span>
                    <Progress
                        percent={(overview.increased * 100) / total}
                        showInfo={false}
                        strokeColor={'rgb(52, 188, 110)'}
                    />
                </div>

                <div>
                    <span>
                        <strong>{overview.dropped}</strong> Dropped
                    </span>
                    <Progress
                        percent={(overview.dropped * 100) / total}
                        showInfo={false}
                        strokeColor={'rgb(246, 56, 66)'}
                    />
                </div>

                <div>
                    <span>
                        <strong>{overview.unchanged}</strong> Unchanged
                    </span>
                    <Progress
                        percent={(overview.unchanged * 100) / total}
                        showInfo={false}
                        strokeColor={'rgb(0, 0, 0)'}
                    />
                </div>
            </Col>

            <Col
                span={7}
                offset={1}
            >
                <Card className="average-position">
                    <Statistic
                        title="Avg. position"
                        value={overview.avg_pos && overview.avg_pos}
                        precision={2}
                        prefix={<Icon type="vertical-align-middle" />}
                    />
                </Card>
            </Col>
        </Row>
    );
}
