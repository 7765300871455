import {
    getSelectedWebsiteGuid,
    getSelectedWebsiteRegionalDatabase,
    getSelectedWebsiteLocation,
    getSelectedWebsiteLanguage,
    getSelectedWebsiteDomain
} from "../tracked_websites/selectors";

export const getOverview = (state) => {
    const selectedDomainGuid = getSelectedWebsiteGuid(state);
    const location = getSelectedWebsiteLocation(state);
    const language = getSelectedWebsiteLanguage(state);

    const overview = state["top_keywords"].overview;
    if (overview && overview[selectedDomainGuid] && overview[selectedDomainGuid][`${location}_${language}`]) {
        return overview[selectedDomainGuid][`${location}_${language}`];
    }
    return {};
};

export const isFetchingOverview = (state) => {
    const selectedDomainGuid = getSelectedWebsiteGuid(state);
    const seoDb = getSelectedWebsiteRegionalDatabase(state);
    return state["top_keywords"]["network_activity"].includes(`fetch_overview_${selectedDomainGuid}${seoDb}`);
};

export const isFetchingKeywords = (state, sortBy) => {
    const selectedDomainGuid = getSelectedWebsiteGuid(state);
    return state["top_keywords"]["network_activity"].includes(`fetch_top_keywords_${selectedDomainGuid}${sortBy}`);
};

export const isFetchingTopKeywordsForDashboard = (state, domain) => {
    const location = getSelectedWebsiteLocation(state);
    const language = getSelectedWebsiteLanguage(state);
    return state["top_keywords"]["network_activity"].includes(
        `fetch_top_keywords_for_dashboard_${domain}${location}${language}`
    );
};

export const isFirstKeywordsFetch = (state, sortBy) => {
    return isFetchingKeywords(state, sortBy) && getOffset(state, sortBy) === 0;
};

export const getTopKeywords = (state, sortBy) => {
    const selectedDomainGuid = getSelectedWebsiteGuid(state);
    const topKeywords = state["top_keywords"]["by_website_guid"];

    if (topKeywords && topKeywords[selectedDomainGuid] && topKeywords[selectedDomainGuid][sortBy]) {
        return topKeywords[selectedDomainGuid][sortBy];
    }
    return {
        keywords: [],
        has_more: false
    };
};

export const getHasMore = (state, sortBy) => {
    return getTopKeywords(state, sortBy)["has_more"];
};

export const getOffset = (state, sortBy) => {
    return getTopKeywords(state, sortBy)["keywords"].length;
};

export const getKeywords = (state, sortBy) => {
    return getTopKeywords(state, sortBy)["keywords"];
};

export const getDefaultOrderBy = (state) => state["top_keywords"]["default_order_by"];
export const getDefaultOrderDir = (state) => state["top_keywords"]["default_order_dir"];

export const getKeywordsToExportAsJson = (state, sortBy) => {
    let keywords = getKeywords(state, sortBy);
    const keysMap = {
        search_volume: "vol",
        results_count: "r_no",
        competition: "cmp",
        difficulty: "difficulty",
        position: "pos",
        previous_position: "prev_pos",
        position_difference: "pos_diff",
        keyword: "phrase",
        cpc: "cpc",
        traffic: "traffic",
        trends: "trends",
        url: "url"
    };
    let newKeywords = keywords.map((keyword) => {
        let newKeyword = {};
        Object.keys(keysMap).forEach((key) => {
            newKeyword[key] = keyword[keysMap[key]];
        });
        return newKeyword;
    });

    return JSON.stringify(newKeywords);
};

export const getKeywordsToExportAsCsv = (state, sortBy) => {
    let keywords = getKeywords(state, sortBy);
    let csv = "";
    const keysMap = {
        phrase: "Keyword",
        pos: "Position",
        prev_pos: "Previous Position",
        pos_diff: "Position Difference",
        traffic: "Traffic (%)",
        difficulty: "Keyword Difficulty",
        vol: "Search Volume",
        url: "URL",
        cpc: "CPC",
        r_no: "Number of Results",
        cmp: "Competition",
        trends: "Trends"
    };
    try {
        let csvColumns = Object.values(keysMap);
        let keywordColumns = Object.keys(keysMap);
        csvColumns.forEach((column) => {
            csv = `${csv}${column};`;
        });
        csv = csv.replace(/.$/, "\n");

        keywords.forEach((keyword) => {
            keywordColumns.forEach((column) => {
                csv = `${csv}${keyword[column]};`;
            });
            csv = csv.replace(/.$/, "\n");
        });
    } catch (e) {}
    return csv;
};

export const hasTopKeywordsOverview = (state) => {
    const overview = getOverview(state);
    let hasOverview = false;
    if (Object.keys(overview).length > 0) {
        hasOverview = true;
    }
    return hasOverview;
};

export const hasTopKeywords = (state, sortBy) => {
    const topKeywords = getKeywords(state, sortBy);
    let hasKeywords = false;
    if (topKeywords.length > 0) {
        hasKeywords = true;
    }
    return hasKeywords;
};

export const hasTopKeywordsAndOverview = (state, sortBy) => {
    return hasTopKeywordsOverview(state) && hasTopKeywords(state, sortBy);
};


const getFetchTopKeywordsOverviewQuery = (state) => {
    const locationName = getSelectedWebsiteLocation(state);
    const languageCode = getSelectedWebsiteLanguage(state);
    const domain = getSelectedWebsiteDomain(state);

    const queryKey = `fetchTopKeywordsOverview({"domain":"${domain}","languageCode":"${languageCode}","locationName":"${locationName}"})`;
    
    return state['api.topKeywords']['queries'][queryKey];
}

export const isFetchingTopKeywordsOverviewRTQ = (state) => {
    try{
        const query = getFetchTopKeywordsOverviewQuery(state);

        if(query) {
            return query['status'] === 'pending';
        }
    }
    catch(error){};

    return false;
};

export const getTopKeywordsOverviewRTQ = (state) => {
    try{
        const query = getFetchTopKeywordsOverviewQuery(state);

        if(query) {
            const overview = query['data'];

            if(overview) {
                return overview;
            }
        }
    }
    catch(error){};

    return {};
};

export const hasTopKeywordsOverviewRTQ = (state) => {
    const overview = getTopKeywordsOverviewRTQ(state);

    if(Object.keys(overview).length > 0) {
        return true;
    }
    return false;
};
