import React from 'react';
import { Button } from 'antd';

type Props = {
	onPreviousClicked: () => void;
	onSubmit: () => void;
	isLoading?: boolean;
};

export default function LastStepButtons({ onPreviousClicked, onSubmit, isLoading }: Props) {
	return (
		<>
			<div className="button-wrapper">
				<Button
					className="back-btn"
					onClick={onPreviousClicked}
				>
					Back
				</Button>
			</div>
			<div className="button-wrapper next-button">
				<Button
					className="finish-setup-btn"
					type="primary"
					onClick={onSubmit}
					loading={isLoading}
				>
					Finish Setup
				</Button>
			</div>
		</>
	);
}
