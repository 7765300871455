import React, { Component } from "react";
import { Card } from "antd";
import TimelineContainer from "../../containers/SiteOutage/TimelineContainer";
import WorldMap from "./WorldMap";
import OutageIssues from "./OutageIssues";
import LoadingIndicator from "../shared/LoadingIndicator/LoadingIndicator";

import "./SiteOutage.less";

class SiteOutageLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInterval: null
    };

    this.getCurrentInterval = this.getCurrentInterval.bind(this);
  }

  getCurrentInterval(currentInterval) {
    this.setState({ currentInterval });
  }

  render() {
    return (
      <Card className="outage-container">
        {!this.props.isLoadingList ? (
          <React.Fragment>
            <TimelineContainer
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              sendCurrentInterval={this.getCurrentInterval}
            />

            <div className="outage-map flexbox flex-wrap">
              <div className="outage-map-container custom-scrollbar">
                <WorldMap
                  isLoading={this.props.isLoadingContext}
                  width={800}
                  height={420}
                  scale={100}
                  events={this.props.currentContext["events"]}
                  url={this.props.url}
                />
              </div>

              <div className="outage-issues-container custom-scrollbar">
                <div className="outage-issues">
                  <OutageIssues
                    url={this.props.url}
                    currentContext={this.props.currentContext}
                    start={this.state.currentInterval ? this.state.currentInterval["start_at"] : null}
                    end={this.state.currentInterval ? this.state.currentInterval["end_at"] : null}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <LoadingIndicator />
        )}
      </Card>
    );
  }
}

export default SiteOutageLayout;
