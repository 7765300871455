import { getSelectedWebsiteUrl, getTrackedWebsiteCompetitors } from "../tracked_websites/selectors";
import moment from "moment";

export const getCategorizedSections = (state) => {
  return state.analysis.sections;
};

export const getFlatSectionsList = (state) => {
  const sections = [];
  const categorized_sections = getCategorizedSections(state);
  Object.keys(categorized_sections).forEach((key) => {
    sections.push(...categorized_sections[key]);
  });
  return sections;
};

export const getReport = (state, report_guid) => {
  try {
    return state.analysis.reports[report_guid];
  } catch (err) {
    return {};
  }
};

export const getSectionsTitlesList = (state) => {
  return getFlatSectionsList(state).map((section) => section.title);
};

export const getSectionsKeysList = (state) => {
  return getFlatSectionsList(state).map((section) => section.key);
};

export const getReportInProgress = (state) => {
  return state.analysis.report_in_progress;
};

export const getMaxRelevancePoints = (state, report_guid) => {
  try {
    const report = getReport(state, report_guid);
    const weighted_sections = getFlatSectionsList(state).filter((section) => {
      return report["sections"].indexOf(section["key"]) !== -1;
    });
    return weighted_sections.reduce((acc, section) => {
      return acc + section["relevance"];
    }, 0);
  } catch (err) {
    return 0;
  }
};

export const getSeoScoreForMainUrl = (state, main_url_guid, max_relevance_points) => {
  try {
    //const main_url_guid = state.analysis.report_in_progress;
    const report = getReport(state, main_url_guid);
    const sectionsInReport = Object.keys(report["section_results"]);
    const relevances_sum_by_status = sectionsInReport.reduce((acc, section_key) => {
      const relevance = report["section_results"][section_key]["relevance"];
      const section_status = report["section_results"][section_key]["result"]["status"];
      const modifiers = {
        passed: 1,
        warning: 0.5,
        failed: 0
      };
      return acc + relevance * modifiers[section_status];
    }, 0);
    const section_errors_max_relevance_points = getSectionErrorsMaxRelevancePointsForMainUrl(state, main_url_guid);
    const max_points = max_relevance_points - section_errors_max_relevance_points;
    let score = max_points !== 0 ? Math.round((relevances_sum_by_status * 100) / max_points) : 0;

    // if the report has just 1 tool, the tool has relevance 0 and the status is passed, then the score should be 100
    if (sectionsInReport.length === 1) {
      const sectionKey = sectionsInReport[0];
      const sectionStatus = report["section_results"][sectionKey]["result"]["status"];
      if (max_points === 0 && sectionStatus === "passed") {
        score = 100;
      }
    }

    return score;
  } catch (err) {
    console.log("Error", err);
    return 0;
  }
};

export const getCategoryScores = (state, report_guid) => {
  let categoriesScores = {};
  let analysisStructure = {};

  const modifiers = {
    passed: 1,
    warning: 0.5,
    failed: 0
  };

  try {
    analysisStructure = getCategorizedSelectedSections(state, report_guid);
    let sectionResults = state.analysis.reports[report_guid]["section_results"];

    Object.keys(analysisStructure).forEach((category) => {
      let maxPoints = 0;
      let relevances_sum_by_status = 0;
      analysisStructure[category].forEach((section_key) => {
        if (section_key in sectionResults && "result" in sectionResults[section_key]) {
          const relevance = sectionResults[section_key]["relevance"];
          const status = sectionResults[section_key].result.status;
          maxPoints += relevance;
          relevances_sum_by_status += relevance * modifiers[status];
        }

        categoriesScores[category] = maxPoints !== 0 ? Math.round((relevances_sum_by_status * 100) / maxPoints) : 0;
      });
    });
  } catch (err) {}

  return categoriesScores;
};

const getSectionErrorsMaxRelevancePoints = (state, report) => {
  let section_errors_max_relevance_points = 0;
  const sections = getFlatSectionsList(state);
  report["section_errors"].forEach((section_key) => {
    sections.forEach((section) => {
      if (section.key === section_key) {
        section_errors_max_relevance_points += section.relevance;
      }
    });
  });
  return section_errors_max_relevance_points;
};

const getSectionErrorsMaxRelevancePointsForMainUrl = (state, report_guid) => {
  let report = state.analysis.reports[report_guid];
  return getSectionErrorsMaxRelevancePoints(state, report);
};

const getSectionErrorsMaxRelevancePointsForCompetitor = (state, main_url_guid, competitor_report_guid) => {
  let report = state.analysis.reports[main_url_guid].competitors[competitor_report_guid];
  return getSectionErrorsMaxRelevancePoints(state, report);
};

export const getCompetitorsUrls = (state, report_guid) => {
  try {
    return state.analysis.reports[report_guid].competitor_urls;
  } catch (err) {
    return [];
  }
};

export const isGeneratingReport = (state) => {
  const report_guid = state.analysis.report_in_progress;

  if (!report_guid) {
    return false;
  }

  const network_activity = state.analysis.reports[report_guid].network_activity;
  return network_activity.some((loader) => loader.indexOf("generating report") > -1);
};

export const getSectionsResults = (state, report_guid) => {
  try {
    return state.analysis.reports[report_guid].section_results;
  } catch (err) {
    return {};
  }
};

export const getReportUrl = (state, report_guid) => {
  try {
    return state.analysis.reports[report_guid].url;
  } catch (err) {
    return null;
  }
};

export const getSeoScoreForCompetitors = (state, report_guid) => {
  const max_relevance_points = getMaxRelevancePoints(state, report_guid);
  const report = state.analysis.reports[report_guid];
  let competitors_seo_score = {};

  try {
    Object.keys(report.competitors).forEach((competitor_guid) => {
      const competitor_url = report.competitors[competitor_guid].url;
      const competitor_section_results = report.competitors[competitor_guid].section_results;
      const relevances_sum_by_status = Object.keys(competitor_section_results).reduce((acc, section_key) => {
        const relevance = competitor_section_results[section_key]["relevance"];
        const section_status = competitor_section_results[section_key]["result"]["status"];
        const modifiers = {
          passed: 1,
          warning: 0.5,
          failed: 0
        };
        return acc + relevance * modifiers[section_status];
      }, 0);
      const section_errors_max_relevance_points = getSectionErrorsMaxRelevancePointsForCompetitor(
        state,
        report_guid,
        competitor_guid
      );
      const max_points = max_relevance_points - section_errors_max_relevance_points;
      competitors_seo_score[competitor_url] =
        max_points !== 0 ? Math.round((relevances_sum_by_status * 100) / max_points) : 0;
    });
  } catch (err) {
    console.log("getSeoScoreForCompetitors error", err);
    competitors_seo_score = {};
  }

  return competitors_seo_score;
};

export const getSectionsStatusCounter = (state, report_guid) => {
  let status_counter = {
    passed: 0,
    warning: 0,
    failed: 0
  };

  try {
    let section_results = state.analysis.reports[report_guid].section_results;
    return Object.keys(section_results).reduce((status_counter, section_key) => {
      if (section_results[section_key].result) {
        let section_status = section_results[section_key].result.status;
        status_counter[section_status]++;
        return status_counter;
      }
      return status_counter;
    }, status_counter);
  } catch (err) {
    return status_counter;
  }
};

export const getSectionsStatusProgress = (state, report_guid) => {
  // TODO: check if antd Progress can receive floats
  try {
    let sections_status_counter = getSectionsStatusCounter(state, report_guid);
    let section_results = state.analysis.reports[report_guid].section_results;
    let section_results_count = Object.keys(section_results).length;

    return {
      passed: (sections_status_counter.passed * 100) / section_results_count,
      warning: (sections_status_counter.warning * 100) / section_results_count,
      failed: (sections_status_counter.failed * 100) / section_results_count
    };
  } catch (err) {
    return {
      passed: 0,
      warning: 0,
      failed: 0
    };
  }
};

// export const getImportantFixes = (state, report_guid) => {
//   const section_results = getSectionsResults(state, report_guid);

//   try {
//     return Object.keys(section_results)
//       .filter(
//         section_key =>
//           ["failed"].includes(section_results[section_key].result.status) && section_results[section_key].relevance > 0
//       )
//       .map(section_key => ({
//         section_key: section_key,
//         helper_message: section_results[section_key].title,
//         result_message: section_results[section_key].result.message,
//         relevance: section_results[section_key].relevance
//       }))
//       .sort((a, b) => (a.relevance < b.relevance ? 1 : -1));
//   } catch (err) {
//     return [];
//   }
// };

export const getImportantFixes = (state, report_guid) => {
  const section_results = getSectionsResults(state, report_guid);

  try {
    return Object.keys(section_results)
      .filter(
        (section_key) =>
          section_results[section_key].result.status === "failed" &&
          section_results[section_key].relevance > 0 &&
          section_results[section_key].result["recommendation"]
      )
      .map((section_key) => ({
        section_key: section_key,
        //helper_message: section_results[section_key].title,
        recommendation: section_results[section_key].result["recommendation"],
        relevance: section_results[section_key].relevance
      }))
      .sort((a, b) => (a.relevance < b.relevance ? 1 : -1));
  } catch (err) {
    return [];
  }
};

export const getCategorizedSelectedSections = (state, report_guid) => {
  try {
    let categorized_sections = getCategorizedSections(state);
    let report = getReport(state, report_guid);
    let selected_sections = report.sections;

    let result = Object.keys(categorized_sections).reduce((acc, category) => {
      let categorized_selected_sections = categorized_sections[category]
        .filter((section) => selected_sections.includes(section.key))
        .map((section) => section.key);
      if (categorized_selected_sections.length) {
        acc[category] = categorized_selected_sections;
      }
      return acc;
    }, {});

    return result;
  } catch (err) {
    return {};
  }
};

export const getCategoriesSectionsStatusCounter = (state, report_guid) => {
  let categoriesSectionsStatusCounter = {};
  //TODO: need to refactor this selector
  // we use now 2 try/catch blocks

  try {
    let analysis_structure = getCategorizedSelectedSections(state, report_guid);
    Object.keys(analysis_structure).forEach((category) => {
      categoriesSectionsStatusCounter[category] = {
        passed: 0,
        warning: 0,
        failed: 0
      };
    });
  } catch (err) {
    console.log("first try", err);
  }

  try {
    let analysis_structure = getCategorizedSelectedSections(state, report_guid);
    let section_results = state.analysis.reports[report_guid].section_results;

    Object.keys(analysis_structure).forEach((category) => {
      analysis_structure[category].forEach((section_key) => {
        if (section_key in section_results && "result" in section_results[section_key]) {
          categoriesSectionsStatusCounter[category][section_results[section_key].result.status]++;
        }
      });
    });
    return categoriesSectionsStatusCounter;
  } catch (err) {
    console.log("second try", err);
    return categoriesSectionsStatusCounter;
  }
};

export const getCompetitorSectionResults = (state, report_guid) => {
  try {
    let report = getReport(state, report_guid);
    let competitor_urls = getCompetitorsUrls(state, report_guid);
    let competitor_guids = Object.keys(report.competitors); //list of competitor_guids
    let selected_sections = report.sections;

    let competitor_urls_and_guids = [];

    competitor_urls.forEach((competitor_url) => {
      let guid = competitor_guids.filter(
        (competitor_guid) => report.competitors[competitor_guid].url === competitor_url
      );

      competitor_urls_and_guids.push({ url: competitor_url, guid: guid[0] });
    });

    let competitor_section_results = {};

    selected_sections.forEach((section_key) => {
      competitor_section_results[section_key] = competitor_urls_and_guids.reduce((acc, competitor) => {
        let section_results = report.competitors[competitor.guid].section_results;
        if (section_key in section_results && "result" in section_results[section_key]) {
          acc.push({
            url: competitor.url,
            result: report.competitors[competitor.guid].section_results[section_key].result
          });
        }
        return acc;
      }, []);
    });

    return competitor_section_results;
  } catch (err) {
    console.log("getCompetitorSectionResults error", err);
    return {};
  }
};

export const getCompetitorsSectionsStatusCounter = (state, report_guid) => {
  let result = {};
  try {
    let report = getReport(state, report_guid);
    let competitors = report.competitors;

    getCompetitorsUrls(state, report_guid).forEach((url) => {
      result[url] = { passed: 0, failed: 0, warning: 0 };
    });

    try {
      Object.keys(competitors).forEach((competitor_guid) => {
        let section_results = competitors[competitor_guid].section_results;
        let competitorSectionsStatusCounter = Object.keys(section_results).reduce(
          (acc, section_key) => {
            if (section_results[section_key].result) {
              let section_status = section_results[section_key].result.status;
              acc[section_status]++;
              return acc;
            }
            return acc;
          },
          { passed: 0, warning: 0, failed: 0 }
        );
        result[competitors[competitor_guid].url] = competitorSectionsStatusCounter;
      });
      return result;
    } catch (err) {
      return result;
    }
  } catch (err) {
    return result;
  }

  // return {
  //   "https://bbc.com": { passed: 0, failed: 0, warning: 0 },
  //   "https://nytimes.com": { passed: 0, failed: 0, warning: 0 }
  // };
};

export const getSectionsTotalNumber = (state, report_guid) => {
  if (!report_guid) return 0;

  let competitors_urls = getCompetitorsUrls(state, report_guid);
  let sections = getReport(state, report_guid).sections;
  return sections.length * (competitors_urls.length + 1);
};

export const getAnalysisProgress = (state, report_guid) => {
  if (!report_guid) return 0;

  let report_status = getReport(state, report_guid).status;
  if (report_status === "finished") return 100;

  let sectionsTotalNumber = getSectionsTotalNumber(state, report_guid);

  let mainUrlSectionResults = getReport(state, report_guid).section_results;
  let mainUrlSectionErrors = getReport(state, report_guid).section_errors;

  let currentNumberOfFetchedSectionsForMainUrl =
    Object.keys(mainUrlSectionResults).length + mainUrlSectionErrors.length;

  let currentNumberOfFetchedSectionsForCompetitors = 0;
  let competitors = getReport(state, report_guid).competitors;

  Object.keys(competitors).forEach((competitor_guid) => {
    let competitorTotal =
      Object.keys(competitors[competitor_guid].section_results).length +
      competitors[competitor_guid].section_errors.length;
    currentNumberOfFetchedSectionsForCompetitors += competitorTotal;
  });

  return sectionsTotalNumber
    ? Math.floor(
        ((currentNumberOfFetchedSectionsForMainUrl + currentNumberOfFetchedSectionsForCompetitors) * 100) /
          sectionsTotalNumber
      )
    : 0;
};

export const isFetchingSavedReport = (state, guid, url) => {
  return state.analysis.network_activity.includes(`fetching_saved_report_${guid}_${url}`);
};

export const isFetchingOverviews = (state, url) => {
  return state.analysis.network_activity.includes(`fetching_overviews_for_url_${url}`);
};

export const isFetchingOverviewsForMainUrlAndCompetitors = (state) => {
  let url = getSelectedWebsiteUrl(state);
  return state.analysis.network_activity.includes(`fetching_overviews_for_main_url_and_competitors_${url}`);
};

export const getOverviewsForUrl = (state, url) => {
  if (state.analysis.overviews[url]) {
    return state.analysis.overviews[url];
  }
  return [];
};

export const hasOverviewsForMainUrl = (state) => {
  let hasOverviews = false;
  const mainUrl = getSelectedWebsiteUrl(state);
  const overviews = getOverviewsForUrl(state, mainUrl);

  if (overviews.length > 0) {
    hasOverviews = true;
  }

  return hasOverviews;
};

export const getSeoScoreProgressChartData = (state) => {
  let chartData = [];
  let urls = [getSelectedWebsiteUrl(state), ...getTrackedWebsiteCompetitors(state)];

  let overviews = [];
  urls.forEach((url) => {
    let overviewWithUrl = getOverviewsForUrl(state, url).map((overview) => ({ ...overview, url: url }));
    overviews.push(...overviewWithUrl);
  });

  if (overviews.length > 0) {
    chartData = overviews.map((overview) => ({
      score: overview["score"],
      created_at: moment(overview["created_at"], "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
      url: overview["url"]
    }));
  }
  return chartData;
};

export const getLastSavedReportGuid = (state, url) => {
  let overviews = getOverviewsForUrl(state, url);
  if (overviews.length > 0) {
    return overviews[overviews.length - 1]["guid"];
  }
};

export const getLastSavedReportCreatedAt = (state, url) => {
  let overviews = getOverviewsForUrl(state, url);
  if (overviews.length > 0) {
    return overviews[overviews.length - 1]["created_at"];
  }
};

export const getLastSavedReportScore = (state, url) => {
  let overviews = getOverviewsForUrl(state, url);
  if (overviews.length > 0) {
    return overviews[overviews.length - 1]["score"];
  }
  return 0;
};

export const getLastSavedReportStatusCounter = (state, url) => {
  let overviews = getOverviewsForUrl(state, url);
  let statusCounter = {
    passed: 0,
    warning: 0,
    failed: 0
  };

  if (overviews.length > 0) {
    statusCounter = {
      passed: overviews[overviews.length - 1]["passed"],
      warning: overviews[overviews.length - 1]["warning"],
      failed: overviews[overviews.length - 1]["failed"]
    };
  }
  return statusCounter;
};

export const isLastSavedReportFetched = (state, url) => {
  let lastSavedReportGuid = getLastSavedReportGuid(state, url);
  let report = state.analysis.reports[lastSavedReportGuid];
  if (report) {
    return true;
  }
  return false;
};

export const getSeoScoresForUrl = (state, url) => {
  let overviews = getOverviewsForUrl(state, url);
  return overviews.map((overview) => overview["score"]);
};

export const isFetchingLastSavedReportForUrl = (state, url) => {
  return state.analysis.network_activity.includes(`fetching_last_saved_report_for_url_${url}`);
};

export const isReportInProgress = (state) => {
  let isReportProgress = false;
  let reports = state.analysis.reports;
  let report_in_progress = getReportInProgress(state);

  if (report_in_progress && reports[report_in_progress]["status"] === "running") {
    isReportProgress = true;
  }

  return isReportProgress;
};

export const getUrlForReportInProgress = (state) => {
  let report_in_progress = getReportInProgress(state);
  if (report_in_progress) {
    return state.analysis.reports[report_in_progress]["url"];
  }
};

export const isFetchingDomainUrls = (state) => {
  let selectedDomain = getSelectedDomain(state);
  return state.analysis.network_activity.includes(`fetching_domain_urls_for_${selectedDomain}`);
};

export const getSelectedDomain = (state) => {
  if (state.analysis.urls && state.analysis.urls.selected_domain) {
    return state.analysis.urls.selected_domain;
  }
};

export const getDomainUrlsData = (state) => {
  let domainUrlsData = {
    urls: [],
    next_token: null
  };

  let selectedDomain = getSelectedDomain(state);

  if (state.analysis.urls && state.analysis.urls[selectedDomain]) {
    return state.analysis.urls[selectedDomain];
  }

  return domainUrlsData;
};

export const getDomainUrlsNextToken = (state) => {
  return getDomainUrlsData(state)["next_token"];
};

export const getDomainUrls = (state) => {
  return getDomainUrlsData(state)["urls"];
};

export const getReportCreatedAt = (state, guid) => {
  return getReport(state, guid)["created_at"];
};

export const getSavedReportFetchError = (state, reportGuid) => {
  let report = getReport(state, reportGuid);
  if (!report) {
    return null;
  }
  return report["error"];
};

export const getArchivedReportsInterval = (state) => {
  return state.analysis.archived_reports_interval;
};

export const isFullReport = (state, report_guid) => {
  let result = false;

  try {
    const report = getReport(state, report_guid);
    if (report) {
      const selected_sections = report.sections;
      const allSectionsCount = getFlatSectionsList(state).length;
      //console.log(`selected sections: ${selected_sections.length} allSectionsCount: ${allSectionsCount}`);
      result = selected_sections.length === allSectionsCount;
    }
  } catch (e) {
    console.log(e);
  }
  return result;
};

export const getSelectedSectionsCount = (state, report_guid) => {
  let selectedSectionsCount = 0;
  try {
    const report = getReport(state, report_guid);
    selectedSectionsCount = report.sections.length;
  } catch (_) {}
  return selectedSectionsCount;
};
