import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import HowToFix from "../../shared/HowToFix/HowToFix";
import InlineModal from "../../../shared/InlineModal/InlineModal";

const displayResult = (title, result) => {
  if (result.status === "failed" && result["images_with_large_metadata"]) {
    return (
      <React.Fragment>
        <div className="description-meta">
          <InlineModal
            openLabel="See results list"
            modalTitle="Full list of images with large metadata"
            closeLabel="Close">
            <div className="image-table-container large-metadata">
              <div className="image-table" style={{ width: "100%" }}>
                <div className="image-table-row">
                  <div className="image-table-cell image">Image </div>
                  <div className="image-table-cell url">Url </div>
                  <div className="image-table-cell">Image size</div>
                  <div className="image-table-cell">Metadata size</div>
                  <div className="image-table-cell">Percent</div>
                </div>

                {result["images_with_large_metadata"].map((image, idx) => {
                  return (
                    <div className="image-table-row" key={idx}>
                      <div className="image-table-cell image">
                        <img src={image.url} alt="" />
                      </div>
                      <div className="image-table-cell url">
                        <div className="truncated">
                          <a href={image.url} target="_blank" rel="noopener noreferrer" title={image.url}>
                            {image.url}
                          </a>
                        </div>
                      </div>
                      <div className="image-table-cell right">{`${(image.size / 1024).toFixed(1)} Kb`}</div>
                      <div className="image-table-cell right">{`${(image.metadata_size / 1024).toFixed(1)} Kb`}</div>
                      <div className="image-table-cell right">{`${((image.metadata_size * 100) / image.size).toFixed(
                        2
                      )}%`}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </InlineModal>
        </div>

        <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
      </React.Fragment>
    );
  }
  return null;
};

const ImgMetadataSection = ({ url, sectionKey, title, result, tooltip, relevance, passRate, competitorResults }) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default ImgMetadataSection;
