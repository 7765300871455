export const isFetchingMonitorLocations = state => {
  return state["monitor_locations"]["network_activity"].includes("fetch_monitor_locations");
};

export const getOrderOfMonitorLocation = state => {
  if (state["monitor_locations"]["order"]) {
    return state["monitor_locations"]["order"];
  }
  return [];
};
export const getMonitorLocations = state => {
  if (!state["monitor_locations"]["data"]) {
    return {};
  }
  return state["monitor_locations"]["data"];
};

export const getAllMonitorLocationsValues = state => {
  if (state["monitor_locations"]["data"]) {
    return Object.values(getMonitorLocations(state));
  }
};

// receives a location and returns the location value
export const getMonitorLocationValue = (state, location) => {
  if (state["monitor_locations"]["data"] && state["monitor_locations"]["data"][location]) {
    return state["monitor_locations"]["data"][location];
  }
};
