export const userNameValidationRules = {
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      message: "^This field is required"
    }
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      message: "^This field is required"
    }
  }
};

export const passwordValidationRules = {
  newPassword: {
    presence: true,
    length: {
      minimum: 6,
      message: "^Minimum 6 characters"
    }
  },
  repeatPassword: {
    equality: {
      attribute: "newPassword",
      message: "^Passwords need to match"
    }
  }
};
