import React, { Component } from "react";
import { connect } from "react-redux";
import BillingInfoLayout from "../../components/Sales/BillingInfoLayout";
import {
  fetchBillingInfo,
  updateBillingInfo,
  updatePaymentMethod,
  fetchSubscription,
  cancelSubscription,
  set3DSecureError
} from "../../store/sales/operations";
import {
  getBillingInfo,
  isFetchingBillingInfo,
  isUpdatingBillingInfo,
  getBillingInfoError,
  threeDSecure,
  userSubscription,
  isFetchingSubscription,
  isFetchingInvoices,
  isCancelingSubscription
} from "../../store/sales/selectors";

class BillingInfo extends Component {
  componentDidMount() {
    this.props.fetchBillingInfo();
    this.props.fetchSubscription();
  }

  render() {
    return (
      <BillingInfoLayout
        isFetchingBillingInfo={this.props.isFetchingBillingInfo}
        isUpdatingBillingInfo={this.props.isUpdatingBillingInfo}
        isFetchingSubscription={this.props.isFetchingSubscription}
        isFetchingInvoices={this.props.isFetchingInvoices}
        billingInfo={this.props.billingInfo}
        onUpdateBillingInfo={this.props.updateBillingInfo}
        onUpdatePaymentMethod={this.props.updatePaymentMethod}
        on3DSecureError={this.props.set3DSecureError}
        errorMessage={this.props.errorMessage}
        threeDSecure={this.props.threeDSecure}
        subscription={this.props.subscription}
        onCancelSubscription={this.props.cancelSubscription}
        isCancelingSubscription={this.props.isCancelingSubscription}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFetchingBillingInfo: isFetchingBillingInfo(state),
  isUpdatingBillingInfo: isUpdatingBillingInfo(state),
  isFetchingSubscription: isFetchingSubscription(state),
  billingInfo: getBillingInfo(state),
  errorMessage: getBillingInfoError(state),
  threeDSecure: threeDSecure(state),
  subscription: userSubscription(state),
  isFetchingInvoices: isFetchingInvoices(state),
  isCancelingSubscription: isCancelingSubscription(state)
});

export default connect(mapStateToProps, {
  fetchBillingInfo,
  updateBillingInfo,
  updatePaymentMethod,
  fetchSubscription,
  cancelSubscription,
  set3DSecureError
})(BillingInfo);
