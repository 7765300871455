import React from 'react';
import { TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';
import { useGetMaxValuesForPlan } from '../../../sales/hooks/useGetMaxValuesForPlan';
import { useUpdateTrackedWebsiteSettingsMutation } from '../../../trackedWebsites/service';
import CompetitorsInput from './CompetitorsInput';
import CompetitorsTable from './CompetitorsTable';

type Props = {
    selectedWebsite: TrackedWebsite;
    setSelectedWebsite: (newSelectedWebsite: TrackedWebsite | null) => void;
};

export default function CompetitorsTab({ selectedWebsite, setSelectedWebsite }: Props) {
    const { maxCompetitors } = useGetMaxValuesForPlan();
    const [updateTrackedWebsiteSettings, { originalArgs, isLoading: isUpdatingTrackedWebsiteSettings }] = useUpdateTrackedWebsiteSettingsMutation();

    const newCompetitors = originalArgs ? originalArgs.competitors! : [];

    const handleCompetitorsChange = async (args: UpdateTrackedWebsitesParams) => {
        try {
            const updatedSettings = await updateTrackedWebsiteSettings(args).unwrap();
            setSelectedWebsite({ ...selectedWebsite, settings: { ...selectedWebsite.settings, ...updatedSettings } });
        } catch (error) {}
    };

    return (
        <div className="competitors-tab">
            <CompetitorsInput
                key={selectedWebsite.guid}
                selectedWebsite={selectedWebsite}
                isDisabled={selectedWebsite.settings.competitors.length >= maxCompetitors}
                isAddingCompetitor={isUpdatingTrackedWebsiteSettings}
                onAddedCompetitor={handleCompetitorsChange}
            />

            {selectedWebsite.settings.competitors.length > 0 ? (
                <CompetitorsTable
                    selectedWebsite={selectedWebsite}
                    remainingCompetitors={newCompetitors}
                    isRemovingCompetitor={isUpdatingTrackedWebsiteSettings}
                    onRemovedCompetitor={handleCompetitorsChange}
                />
            ) : null}
        </div>
    );
}
