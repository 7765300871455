import React, { useContext } from 'react';
import { Layout } from 'antd';
import TrackedWebsites from '../../trackedWebsites/TrackedWebsites';
import { SelectedWebsiteContext } from '../../trackedWebsites/ContextWrapper';
import SiteSpeed from '../SiteSpeed';

const { Content } = Layout;

export default function SiteSpeedPage() {
    const { selectedWebsite } = useContext(SelectedWebsiteContext);

    return (
        <Content className="general-layout-content">
            <TrackedWebsites
                tabContent={<SiteSpeed selectedWebsite={selectedWebsite!} />}
                destroyInactiveTabs={true}
            />
        </Content>
    );
}
