import { getSelectedWebsiteDomain } from "../tracked_websites/selectors";

export const isFetchingBrokenLinks = (state, type) => {
  let domain = getSelectedWebsiteDomain(state);
  return state["broken_links"]["network_activity"].includes(`fetch_broken_links_${domain}_${type}`);
};

export const isFetchingAffectedPages = (state, url) => {
  let domain = getSelectedWebsiteDomain(state);
  return state["broken_links"]["network_activity"].includes(`fetch_affected_pages_${domain}_${url}`);
};

const getBrokenLinksType = (state, type) => {
  let domain = getSelectedWebsiteDomain(state);
  if (
    state["broken_links"]["data"] &&
    state["broken_links"]["data"][domain] &&
    state["broken_links"]["data"][domain][type]
  ) {
    return state["broken_links"]["data"][domain][type];
  }
  return { links: [], last_url_hash: null };
};

export const getBrokenLinks = (state, type) => {
  return getBrokenLinksType(state, type)["links"];
};

export const getBrokenLinksLastUrlHash = (state, type) => {
  return getBrokenLinksType(state, type)["last_url_hash"];
};

export const hasMoreBrokenLinks = (state, type) => {
  return !!getBrokenLinksLastUrlHash(state, type);
};

const getAffectedPagesObject = (state, url) => {
  let domain = getSelectedWebsiteDomain(state);

  if (
    state["broken_links"]["data"] &&
    state["broken_links"]["data"][domain] &&
    state["broken_links"]["data"][domain]["affected_pages"] &&
    state["broken_links"]["data"][domain]["affected_pages"][url]
  ) {
    return state["broken_links"]["data"][domain]["affected_pages"][url];
  }
  return { pages: [], last_uat: null };
};

export const getAffectedPages = (state, url) => {
  return getAffectedPagesObject(state, url)["pages"];
};

export const getAffectedPagesLastUap = (state, url) => {
  return getAffectedPagesObject(state, url)["last_uat"];
};

export const hasMoreAffectedPages = (state, url) => {
  return !!getAffectedPagesLastUap(state, url);
};
