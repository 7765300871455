import React from 'react';
import PropTypes from 'prop-types';

const AnchorLabeledList = ({className, list}) => {

    const htmlList = () => (
        <ul className={`bulleted-list ${className ? className : ''}`}>
            {
                list.map((item, index) => (
                    <li key={index}>
                        <a href={item.anchorLabel || item} target={item.target || null}>{item.anchorLabel || item}</a>
                        { item.label ? <span className="item-label">{item.label}</span> : null }
                    </li>
                ))
            }
        </ul>
    );

    return (
        <div>
            {
                Array.isArray(list) && list.length ? htmlList() : null
            }
        </div>
    )
};

AnchorLabeledList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                anchorLabel: PropTypes.string.isRequired,
                label: PropTypes.string,
                target: PropTypes.string,
            }),
        ])
    ).isRequired
};

export default AnchorLabeledList;
