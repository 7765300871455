import React from "react";
import { Card, Skeleton } from "antd";

const DashboardBox = props => {
  return (
    <Card>
      <Skeleton paragraph={{ rows: 10 }} active />
    </Card>
  );
};

export default DashboardBox;
