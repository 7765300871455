import React, { Component } from "react";
import { connect } from "react-redux";
import { BrokenLinksTable } from "../../components/BrokenLinks";
import { getSelectedWebsiteDomain } from "../../store/tracked_websites/selectors";
import {
  isFetchingBrokenLinks,
  getBrokenLinks,
  hasMoreBrokenLinks,
  getBrokenLinksLastUrlHash
} from "../../store/broken_links/selectors";
import { fetchBrokenLinks } from "../../store/broken_links/operations";

export class BrokenLinks extends Component {
  constructor(props) {
    super(props);
    this.loadMoreBrokenLinks = this.loadMoreBrokenLinks.bind(this);
  }

  componentDidMount() {
    if (this.props.brokenLinks.length === 0 && !this.props.isLoading) {
      this.props.fetchBrokenLinks(this.props.domain, this.props.type);
    }
  }

  loadMoreBrokenLinks() {
    this.props.fetchBrokenLinks(this.props.domain, this.props.type, this.props.lastUrlHash);
  }

  render() {
    return (
      <BrokenLinksTable
        isLoading={this.props.isLoading}
        brokenLinks={this.props.brokenLinks}
        hasMore={this.props.hasMore}
        onLoadMore={this.loadMoreBrokenLinks}
        type={this.props.type}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { type } = ownProps;
  return {
    domain: getSelectedWebsiteDomain(state),
    isLoading: isFetchingBrokenLinks(state, type),
    brokenLinks: getBrokenLinks(state, type),
    hasMore: hasMoreBrokenLinks(state, type),
    lastUrlHash: getBrokenLinksLastUrlHash(state, type)
  };
};

export default connect(mapStateToProps, { fetchBrokenLinks })(BrokenLinks);
