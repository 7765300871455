import './KeywordResearch.less';
import React from 'react';
import { Card, Table } from 'antd';
import KeywordResearchSuggestionsButton from './suggestions/KeywordResearchSuggestionsButton';
import { TrackedWebsite } from '../trackedWebsites/types';
import ReportCardTitle from '../components/shared/ReportCardTitle/ReportCardTitle';
import TopKeywordsResults from '../topKeywords/TopKeywordsResults';
import { defaultOrderBy, defaultOrderDir } from '../topKeywords/settings';
import { TopKeywordsTableRecord } from '../topKeywords/types';

const { Column } = Table;

type Props = {
    selectedWebsite: TrackedWebsite;
};

export default function KeywordResearch({ selectedWebsite }: Props) {
    return (
        <Card
            data-cy="keywords-research-table"
            title={
                <ReportCardTitle
                    title="Keyword Research"
                    domain={selectedWebsite.domain}
                />
            }
        >
            <TopKeywordsResults
                key={`${selectedWebsite.guid}_${selectedWebsite.settings.location_name}_${selectedWebsite.settings.language_code}`}
                selectedWebsite={selectedWebsite}
                orderBy={defaultOrderBy}
                orderDir={defaultOrderDir}
                extraColumn={
                    <Column
                        className="al-center no-wrap"
                        title="Suggestions"
                        dataIndex="suggestions"
                        render={(text, record: TopKeywordsTableRecord, index) => (
                            <KeywordResearchSuggestionsButton
                                keyword={record.keyword}
                                location={selectedWebsite.settings.location_name}
                                languageCode={selectedWebsite.settings.language_code}
                            />
                        )}
                    />
                }
            />
        </Card>
    );
}
