import React, { Component } from "react";
import { Card, Form, Icon, Input, Button } from "antd";
import { validate } from "validate.js";
import resetPasswordRules from "./validators";

const { Meta } = Card;

export class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      repeatPassword: "",
      validationErrors: {}
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
  }

  validateForm() {
    return validate(
      {
        password: this.state.password,
        repeatPassword: this.state.repeatPassword
      },
      resetPasswordRules
    );
  }

  onFormSubmit(event) {
    event.preventDefault();

    const validationErrors = this.validateForm();
    if (!validationErrors) {
      this.setState({ validationErrors: {} });
      this.props.onSubmit(this.props.token, this.state.password);
    } else {
      this.setState({ validationErrors: validationErrors });
    }
  }

  onInputChange(event) {
    const newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }
  onInputFocus(event) {
    if (this.state.validationErrors[event.target.name]) {
      const newState = { ...this.state, validationErrors: { ...this.state.validationErrors, [event.target.name]: "" } };
      this.setState(newState);
    }
  }
  render() {
    const errors = this.state.validationErrors;

    return (
      <div className="reset-password-container">
        <div className="authentication-form-wrapper">
          <div className="auth-logo" />

          <Card className="auth-card" bordered={false}>
            <div className="card-header">
              <Meta title="Reset password" />
            </div>
            <Form className="reset-password-form" onSubmit={this.onFormSubmit}>
              <Form.Item
                validateStatus={errors.password && errors.password[0] ? "error" : "validating"}
                help={errors.password && errors.password[0]}>
                <Input
                  name="password"
                  prefix={<Icon type="lock" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.repeatPassword && errors.repeatPassword[0] ? "error" : "validating"}
                help={errors.repeatPassword && errors.repeatPassword[0]}>
                <Input
                  name="repeatPassword"
                  prefix={<Icon type="lock" style={{ fontSize: "16px", color: "rgba(66,71,71,.54)" }} />}
                  type="password"
                  value={this.state.repeatPassword}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  onFocus={this.onInputFocus}
                  placeholder="Repeat password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="reset-password-form-button"
                  loading={this.props.isLoading}>
                  {this.props.isLoading ? "Loading" : "Change password"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <div className="under-card-info">
            <span className="fake-link" onClick={() => this.props.push("/auth/login")}>
              <strong>Log in to your account</strong>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordForm;
