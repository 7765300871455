import React, { useState } from 'react';
import { Modal, Spin, Table, Icon } from 'antd';
import { useLazyFetchTopDomainsQuery } from './service';
import { getTableDataFromTopDomains } from './utils';
import ModalTitle from '../components/shared/ModalTitle/ModalTitle';

const columns = [
    { title: '#', dataIndex: 'key' },
    { title: 'Domain', dataIndex: 'domain', className: 'domain' },
    {
        title: 'URL',
        dataIndex: 'url',
        className: 'top-domain-url',
        render: (url: string) => (
            <div>
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Visit URL
                </a>
            </div>
        ),
    },
];

type Props = {
    keyword: string;
    position: number | null | undefined;
    topDomainsCount: number;
    location: string;
    languageCode: string;
};

export default function TopDomainsModal({ keyword, position, topDomainsCount, location, languageCode }: Props) {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [fetchTopDomains, { data: topDomains, isLoading: isLoadingTopDomains }] = useLazyFetchTopDomainsQuery();

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleOpenModal = () => {
        fetchTopDomains({ phrase: keyword, location: location, languageCode: languageCode }, true);
        toggleModal();
    };

    const shouldShowModalTrigger = (position || topDomainsCount) && !isLoadingTopDomains;
    const shouldHideModalTrigger = !position && topDomainsCount === 0 && !isLoadingTopDomains;
    const shouldShowModal = !isLoadingTopDomains && topDomains;

    // const isKeywordDataExistent = !isLoadingTopDomains && position;
    // const isKeywordDataEmpty = !isLoadingTopDomains && !position;
    // const isTopDomainsExistent = !isLoadingTopDomains && topDomains;

    return (
        <>
            {isLoadingTopDomains && (
                <Spin
                    style={{ lineHeight: '12px' }}
                    indicator={
                        <Icon
                            type="loading"
                            spin
                            style={{ fontSize: 12 }}
                        />
                    }
                />
            )}

            {shouldShowModalTrigger ? (
                <span
                    className="view-top-domains"
                    onClick={handleOpenModal}
                >
                    View list
                </span>
            ) : null}

            {shouldHideModalTrigger ? <>-</> : null}

            {shouldShowModal && (
                <Modal
                    centered
                    className="top-domains-modal"
                    title={
                        <ModalTitle
                            title="Top domains for: "
                            meta={keyword}
                        />
                    }
                    visible={isModalVisible}
                    onCancel={toggleModal}
                    footer={null}
                >
                    <Table
                        className="top-domains"
                        dataSource={getTableDataFromTopDomains(topDomains)}
                        columns={columns}
                        pagination={false}
                        size="small"
                    />
                </Modal>
            )}
        </>
    );
}
