import React from 'react';
import { Table, Icon } from 'antd';
import { TrackedWebsite, UpdateTrackedWebsitesParams } from '../../../trackedWebsites/types';

const { Column } = Table;

type TableRecord = {
    index: number;
    competitor: string;
};

type Props = {
    selectedWebsite: TrackedWebsite;
    remainingCompetitors: string[];
    isRemovingCompetitor: boolean;
    onRemovedCompetitor: (args: UpdateTrackedWebsitesParams) => void;
};

export default function CompetitorsTable({ selectedWebsite, remainingCompetitors, isRemovingCompetitor, onRemovedCompetitor }: Props) {
    const handleRemoveCompetitorClicked = (removedCompetitor: string) => {
        const updatedCompetitors = selectedWebsite.settings.competitors.filter((competitor) => competitor !== removedCompetitor);

        onRemovedCompetitor({
            guid: selectedWebsite.guid,
            url: selectedWebsite.url,
            competitors: updatedCompetitors,
        });
    };

    const competitorsTableData = selectedWebsite.settings.competitors.map((competitor, index) => ({
        index: index + 1,
        competitor: competitor,
    }));

    return (
        <Table
            className="custom-scrollbar"
            dataSource={competitorsTableData}
            showHeader={false}
            pagination={false}
        >
            <Column
                key="index"
                width="5%"
                dataIndex="index"
            />
            <Column
                key="competitor"
                dataIndex="competitor"
                render={(text, record: TableRecord, index) => (
                    <a
                        href={record.competitor}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {record.competitor}
                    </a>
                )}
            />
            <Column
                key="removeCompetitorAction"
                align="right"
                render={(text, record: TableRecord, index) => (
                    <span
                        className={`remove-item ${isRemovingCompetitor && !remainingCompetitors.includes(record.competitor) ? 'in-progress' : ''}`}
                        onClick={() => handleRemoveCompetitorClicked(record.competitor)}
                    >
                        {isRemovingCompetitor && !remainingCompetitors.includes(record.competitor) ? <Icon type="loading" /> : <Icon type="delete" />}
                    </span>
                )}
            />
        </Table>
    );
}
