import React, { useState } from "react";
import { Alert, Button, Icon } from "antd";
import ConfirmCancellation from "../Sales/ConfirmCancellation";
import "./UserAccountForm.less";

export const DeleteAccount = (props) => {
  const [cancellationReasonModalVisible, setCancellationReasonModalVisible] = useState(false);
  const toggleCancellationReasonModal = (_) => {
    setCancellationReasonModalVisible(!cancellationReasonModalVisible);
  };

  const deleteAccount = (reason, comment) => {
    props.onSendCancellationReason(reason, comment);
    props.onDeleteUserAccount();
  };

  const openCancellationReasonModal = (_) => {
    setCancellationReasonModalVisible(true);
  };

  return (
    <div className="user-account-card delete-user">
      <Alert
        message="Please note!"
        description="After deleting your account, you will not be able to use our services: your subscription will be canceled and your
          content, saved reports and other personal information will be erased."
        type="error"
        icon={<Icon type="warning" />}
        showIcon
      />

      <ConfirmCancellation
        title="Confirm delete account"
        visible={cancellationReasonModalVisible}
        showInfo={false}
        onClose={(_) => toggleCancellationReasonModal()}
        onConfirmCancellation={(reason, comment) => deleteAccount(reason, comment)}
        loading={props.isDeletingUserAccount}
      />

      <Button type="primary" onClick={(_) => openCancellationReasonModal()}>
        Delete Account
      </Button>
    </div>
  );
};

export default DeleteAccount;
