import React from "react";
import { Layout } from "antd";
import TrackedWebsites from "../trackedWebsites/TrackedWebsites";
import Dashboard from "../containers/Dashboard/Dashboard";
import AnalysisFormContainer from "../containers/Dashboard/AnalysisFormContainer";

const { Content } = Layout;

const DashboardPage = () => {
  return (
    <Content className="general-layout-content">
      <AnalysisFormContainer />
      <TrackedWebsites tabContent={<Dashboard />} showStatus={false} destroyInactiveTabs={true} />
    </Content>
  );
};

export default DashboardPage;
