import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import SimpleList from "../../../shared/List/SimpleList";

const displayResult = (result) => {
  if(!result || !result.disallow_tags){
    return null;
  }

  return (
    <div className="description-meta">      
      <InlineModal openLabel="See results list" modalTitle="Full list of disallow directives" closeLabel="Close">
        <SimpleList list={result.disallow_tags} />
      </InlineModal>      
    </div>
  );
};

const DisallowTagsSection = ({ url, sectionKey, title, result, tooltip, relevance, competitorResults }) => {  
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0; 

  return (
    <div id={sectionKey} className="report-element default">
      <SectionTitle title={title} />

      <div className="report-element-description">
        <SectionMessage url={url} status={result.status} relevance={relevance} message={result.message} hasCompetitors={hasCompetitors} />        
        {displayResult(result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance}/>
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default DisallowTagsSection;
