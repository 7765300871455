import React from 'react';
import { Button } from 'antd';

type Props = {
	onPreviousClicked: () => void;
	onNextClicked: () => void;
	isLoading?: boolean;
};

export default function IntermediateStepButtons({ onPreviousClicked, onNextClicked, isLoading }: Props) {
	return (
		<>
			<div className="button-wrapper">
				<Button
					className="back-btn"
					onClick={onPreviousClicked}
				>
					Back
				</Button>
			</div>
			<div className="button-wrapper next-button">
				<Button
					className="next-btn right"
					type="primary"
					onClick={onNextClicked}
					loading={isLoading}
				>
					Next
				</Button>
			</div>
		</>
	);
}
