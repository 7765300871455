import React from "react";
import SectionTitle from "../../shared/SectionTitle/SectionTitle";
import SectionMessage from "../../shared/SectionMessage/SectionMessage";
import SectionCompetitorResults from "../../shared/SectionCompetitorResults/SectionCompetitorResults";
import SectionTooltip from "../../shared/SectionTooltip/SectionTooltip";
import InlineModal from "../../../shared/InlineModal/InlineModal";
import AnchorLabeledList from "../../../shared/List/AnchorLabeledList";
import HowToFix from "../../shared/HowToFix/HowToFix";
import { anchorAttributesForUrls } from "../../utils";

const displayResult = (title, result) => {
  return (
    <React.Fragment>
      <div className="description-meta">
        <InlineModal openLabel="See results list" modalTitle="Full list of CSS files" closeLabel="Close">
          {result.minified.same_domain.length > 0 && (
            <div className="list-title">Minified CSS files from same domain:</div>
          )}
          <AnchorLabeledList list={anchorAttributesForUrls(result.minified.same_domain)} />

          {result.minified.other_domain.length > 0 && (
            <div className="list-title">Minified CSS files from other domains:</div>
          )}
          <AnchorLabeledList list={anchorAttributesForUrls(result.minified.other_domain)} />

          {result.not_minified.same_domain.length > 0 && (
            <div className="list-title">Not-minified CSS files from same domain:</div>
          )}
          <AnchorLabeledList list={anchorAttributesForUrls(result.not_minified.same_domain)} />

          {result.not_minified.other_domain.length > 0 && (
            <div className="list-title">Not-minified CSS files from other domains:</div>
          )}
          <AnchorLabeledList list={anchorAttributesForUrls(result.not_minified.other_domain)} />
        </InlineModal>
      </div>

      <HowToFix title={title} how_to_fix={result.how_to_fix} how_to_fix_video={result.how_to_fix_video} />
    </React.Fragment>
  );
};

const CssMinificationSection = ({
  url,
  sectionKey,
  title,
  result,
  tooltip,
  relevance,
  passRate,
  competitorResults
}) => {
  const hasCompetitors = Array.isArray(competitorResults) && competitorResults.length > 0;

  return (
    <div id={sectionKey} className={`report-element ${result.status}`}>
      <SectionTitle title={title} status={result.status} passRate={passRate} />

      <div className="report-element-description">
        <SectionMessage
          url={url}
          status={result.status}
          relevance={relevance}
          message={result.message}
          hasCompetitors={hasCompetitors}
        />
        {displayResult(title, result)}
        <SectionCompetitorResults results={competitorResults} relevance={relevance} />
      </div>

      <SectionTooltip tooltip={tooltip} />
    </div>
  );
};

export default CssMinificationSection;
