import React from "react";
import { Layout } from "antd";
import WhiteLabel from "../containers/WhiteLabel/WhiteLabel";

const { Content } = Layout;

const WhiteLabelPage = (props) => {
  return (
    <Content className="general-layout-content">
      <WhiteLabel idUrlParam={props.match.params.id} />
    </Content>
  );
};

export default WhiteLabelPage;
