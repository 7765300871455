import { getLoggedInUserSubscription, getUser } from "../iam/selectors";

export const isWhiteLabelAllowed = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    try {
        const planCode = subscription["plan_code"];
        const plan = getPlan(state, planCode);

        const settings = plan["settings"];
        const user = getUser(state);
        if (user["override_plan"] && user["override_plan"]["allow_white_label_reports"]) {
            settings["allow_white_label_reports"] = user["override_plan"]["allow_white_label_reports"];
            // const overrides = user['override_plan'];
            // Object.keys(overrides).forEach(attribute => {
            //   settings[attribute] = overrides[attribute]
            // });
        }
        return settings["allow_white_label_reports"];
    } catch (e) {
        return null;
    }
};

export const getMaxKeywordsNo = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    try {
        const planCode = subscription["plan_code"];
        const plan = getPlan(state, planCode);

        const settings = plan["settings"];
        return settings["max_keywords"];
    } catch (e) {
        return 1;
    }
    // try {
    //   const planCode = state["sales"]["subscription"]["plan_code"];
    //   const plan = getPlan(state, planCode);
    //   const settings = plan["settings"];
    //   return settings["max_keywords"];
    // } catch (e) {}
    // return 1;
};

export const getMaxCompetitorsNo = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    try {
        const planCode = subscription["plan_code"];
        const plan = getPlan(state, planCode);

        const settings = plan["settings"];
        return settings["max_competitors"];
    } catch (e) {
        return 1;
    }
};

export const getMaxRegionsNo = (state) => {
    // try {
    //   const planCode = state["sales"]["subscription"]["plan_code"];
    //   const plan = getPlan(state, planCode);
    //   const settings = plan["settings"];
    //   return settings["max_monitored_regions"];
    // } catch (e) {}
    // return 1;

    const subscription = getLoggedInUserSubscription(state);
    try {
        const planCode = subscription["plan_code"];
        const plan = getPlan(state, planCode);

        const settings = plan["settings"];
        return settings["max_monitored_regions"];
    } catch (e) {
        return 1;
    }
};

export const getMaxTrackedWebsitesNo = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    try {
        const planCode = subscription["plan_code"];
        const plan = getPlan(state, planCode);

        const settings = plan["settings"];
        return settings["max_tracked_websites"];
    } catch (e) {
        return 1;
    }
    // try {
    //   const planCode = state["sales"]["subscription"]["plan_code"];
    //   const plan = getPlan(state, planCode);
    //   const settings = plan["settings"];
    //   return settings["max_tracked_websites"];
    // } catch (e) {}
    // return 1;
};

export const getMaxTeamMembers = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    try {
        const planCode = subscription["plan_code"];
        const plan = getPlan(state, planCode);

        const settings = plan["settings"];
        return settings["max_team_members"];
    } catch (e) {
        return -1;
    }
};

export const threeDSecure = (state) => {
    return state["sales"]["three_d_secure"];
};

export const getDefaultPlan = (state) => {
    try {
        const monthly_plans = state["sales"]["plans"]["monthly"];
        return monthly_plans.find((plan) => plan["settings"]["default"] === true);
    } catch (e) {
        return null;
    }
};

export const getSubscriptionPlans = (state) => {
    return state["sales"]["plans"];
};

export const getPlan = (state, planCode) => {
    try {
        let flatPlans = [];
        Object.keys(state["sales"]["plans"]).forEach((unitInterval) => {
            flatPlans = flatPlans.concat(state["sales"]["plans"][unitInterval]);
        });
        return flatPlans.find((plan) => plan["code"] === planCode);
    } catch (e) {
        return null;
    }
};

export const getSubscription = (state) => {
    return state["sales"]["subscription"];
};

export const getSubscriptionError = (state) => {
    let subscriptionError = null;
    try {
        subscriptionError = state["sales"]["subscription"]["error"] || state["sales"]["three_d_secure"]["error"];
    } catch (e) {
        subscriptionError = null;
    }

    return subscriptionError;
};

export const getBillingInfoError = (state) => {
    let billingInfoError = null;
    try {
        billingInfoError = state["sales"]["billing_info"]["error"];
    } catch (e) {
        billingInfoError = null;
    }

    return billingInfoError;
};

export const userSubscription = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    if (subscription && subscription["plan_code"]) {
        const plan = getPlan(state, subscription["plan_code"]);
        subscription["plan_settings"] = plan;
        subscription["current_period_ends_at"] = state["sales"]["subscription"]["current_period_ends_at"];
        return subscription;
    }
    return null;
};

export const isInFreeTrial = (state) => {
    const subscription = getLoggedInUserSubscription(state);
    if (subscription && subscription["plan_code"]) {
        try {
            const trialEndsAt = state["sales"]["subscription"]["trial_ends_at"];
            if (trialEndsAt) {
                const now = new Date();
                const trialEndsAtDate = new Date(trialEndsAt);
                return trialEndsAtDate > now;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
};

export const getBillingInfo = (state) => {
    return state["sales"]["billing_info"];
};

export const isCreatingSubscription = (state) => {
    return state["sales"]["network_activity"].includes("create_subscription");
};

export const isCancelingSubscription = (state) => {
    return state["sales"]["network_activity"].includes("cancel_subscription");
};

export const isUpdatingSubscription = (state) => {
    return state["sales"]["network_activity"].includes("update_subscription");
};

export const isFetchingBillingInfo = (state) => {
    return state["sales"]["network_activity"].includes("fetch_billing_info");
};

export const isUpdatingBillingInfo = (state) => {
    return state["sales"]["network_activity"].includes("update_billing_info");
};

export const isDownloadingInvoice = (state) => {
    return state["sales"]["network_activity"].includes("fetch_pdf_invoice");
};

export const isFetchingInvoices = (state) => {
    return state["sales"]["network_activity"].includes("fetch_invoices");
};

export const isFetchingSubscription = (state) => {
    return state["sales"]["network_activity"].includes("fetch_subscription");
};

export const getInvoices = (state) => {
    return state["sales"]["invoices"];
};
